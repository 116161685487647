import { FontSizeType, sizeLookup } from '@styles/fontSizes';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledLink = styled.a<{ $size: FontSizeType; $underlined: boolean }>`
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  ${({ $size }) => sizeLookup[$size]};
  text-underline-offset: ${rem(2)};
  ${(props) => (props.$underlined ? `text-decoration: underline;` : `text-decoration: none;`)}

  @media (hover: hover) {
    &:hover {
      font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
    }
  }
`;
