import { ContentSlotWsDTOWithComponent } from '@api/interfaces/collectSlots';
import SanitizedHtml from '@components/atoms/SanitizedHtml';
import GLOBALMESSAGE from '@constants/globalmessage';
import gtmTrack from '@helpers/gtmTrack';
import useGlobalMessages from '@hooks/useGlobalMessages/useGlobalMessages';
import useHideCmsComponent from '@hooks/useHideCmsComponent';
import useUserAgent from '@hooks/useUserAgent/useUserAgent';
import { useState } from 'react';
import GlobalMessage from '../GlobalMessage';

const GlobalMessages = ({ messageBoxSlot }: { messageBoxSlot: ContentSlotWsDTOWithComponent | undefined }): any => {
  const hideComponent = useHideCmsComponent();
  const messageBoxSlotComponents = messageBoxSlot?.component || [];
  const componentsToShowInWeb = messageBoxSlotComponents.filter((component) => !hideComponent(component));
  const { currentMessages, setMessageAsChecked } = useGlobalMessages(componentsToShowInWeb);
  const [cookieEventSent, setCookieEventSent] = useState(false);
  const { isReactNative } = useUserAgent();

  return (
    !isReactNative &&
    currentMessages.map((message: GlobalMessage) => {
      if (message.uid === GLOBALMESSAGE.COOKIE_ID && !cookieEventSent) {
        gtmTrack({
          event: 'Track',
          category: 'cookiebar',
          action: 'cookiebar_shown',
          nonInteraction: true,
          label: '',
          value: 0,
        });
        setCookieEventSent(true);
      }

      return (
        <GlobalMessage
          key={message.uid}
          uid={message.uid}
          content={message.content}
          onClick={() => setMessageAsChecked(message)}
          backgroundColor={message.background}
          textColor={message.fontcolor}
        >
          <SanitizedHtml content={message.content} />
        </GlobalMessage>
      );
    })
  );
};

export default GlobalMessages;
