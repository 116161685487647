import { mapHeadingSize } from '@components/atoms/Heading/Heading.styles';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledCmsHtml = styled.div`
  strong,
  b {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  }

  i {
    font-style: italic;
  }

  div {
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.colorAcadia};

    strong,
    b {
      font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
    }
  }

  h1 {
    ${mapHeadingSize['large']}
  }

  h2 {
    ${mapHeadingSize['medium']}
  }

  h3 {
    ${mapHeadingSize['small']}
  }
  h4 {
    ${mapHeadingSize['xSmall']}
  }
  p,
  li {
    font-size: ${rem(14)};
    line-height: 1.5;
    margin: ${rem(18)} 0;
    ${media.tabletPortrait} {
      font-size: ${rem(16)};
    }
  }

  a {
    display: inline;
    color: ${({ theme }) => theme.colors.colorTextPrimary};
    &:visited {
      color: ${({ theme }) => theme.colors.colorTextPrimary};
    }
    &:hover {
      color: ${({ theme }) => theme.colors.colorTextSecondary};
    }
  }

  table {
    border-collapse: collapse;
    line-height: ${rem(36)};
  }

  button,
  a.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: ${rem(16)};
    font-weight: ${({ theme }) => theme.fonts.fontWeight.normal};
    font-family: ${({ theme }) => theme.typography.fontSans};
    border-radius: ${rem(40)};
    height: ${rem(40)};
    line-height: ${rem(20)};
    min-width: ${rem(176)};
    padding: 0 ${rem(30)};
    outline: 0;
    position: relative;
    white-space: nowrap;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    cursor: pointer;
    border: 0;

    &:disabled {
      background: ${({ theme }) => theme.colors.colorDesertStorm};
      color: ${({ theme }) => theme.colors.colorSilver};
      cursor: default;
      pointer-events: none;
    }
    transition: background-color 0.5s ease;
    background: ${({ theme }) => theme.colors.colorPrimary};
    color: ${({ theme }) => theme.colors.colorWhite};
    &:hover {
      background: ${({ theme }) => theme.colors.colorCardinal};
    }
    &:focus {
      background: ${({ theme }) => theme.colors.colorThunderbird};
    }
  }
  a.btn {
    width: ${rem(176)};
  }
  ${({ theme }) => theme.globalClasses.cmsContentText};
`;
