import React from 'react';
import makeIntersectionObserverComponent, { IntersectionObserverComponentProps } from '@helpers/componentTracking';
import MiniCartProductRow from '@components/molecules/MiniCartProductRow';
import type { AxfoodCartProductViewModel } from '@occ/api-client';

interface CartConnectedMinicartProductRowProps extends IntersectionObserverComponentProps {
  product: AxfoodCartProductViewModel;
  setIsConfirmModalVisible: (isVisible: boolean) => void;
}

const CartConnectedMinicartProductRow: React.FunctionComponent<CartConnectedMinicartProductRowProps> = ({
  product,
  setIsConfirmModalVisible,
  intersectionRef,
}: CartConnectedMinicartProductRowProps) => {
  return (
    <MiniCartProductRow product={product} setIsConfirmModalVisible={setIsConfirmModalVisible} ref={intersectionRef} />
  );
};

export default CartConnectedMinicartProductRow;

export const IntersectionObservedMiniCartProductRow = makeIntersectionObserverComponent(CartConnectedMinicartProductRow);
