import { getCustomerServiceReasons } from '@api/CustomerServiceApi';
import { CustomerContactReason } from '@api/generated/storefront';
import { FORTY_MINUTES_IN_MS } from '@constants/timers';
import useCustomer from '@hooks/useCustomer';
import useSWR, { SWRConfiguration } from 'swr';

const options: SWRConfiguration = {
  revalidateIfStale: false,
  refreshInterval: FORTY_MINUTES_IN_MS,
};

const useCustomerServiceReasons = () => {
  const { customerFetchIsRequired } = useCustomer();
  return useSWR<CustomerContactReason[]>(
    customerFetchIsRequired ? '/customer-service/reasons' : null,
    getCustomerServiceReasons,
    options
  );
};

export default useCustomerServiceReasons;
