import Link from 'next/link';
import { ReactNode } from 'react';
import { StyledLink } from './LinkArea.styles';

interface LinkAreaProps {
  children: ReactNode;
  title?: string;
  'aria-label'?: string;
  className?: string;
  target?: '_blank' | '_self';
  href: UrlType;
  nextLinkAs?: UrlType;
  replace?: boolean;
  scroll?: boolean;
  shallow?: boolean;
  onClick?: () => void;
}

const LinkArea = ({
  children,
  title,
  'aria-label': ariaLabel,
  className,
  target,
  nextLinkAs,
  href,
  shallow,
  replace,
  scroll,
  onClick,
}: LinkAreaProps) => {
  return (
    <Link legacyBehavior passHref as={nextLinkAs} href={href} shallow={shallow} replace={replace} scroll={scroll}>
      <StyledLink title={title} aria-label={ariaLabel} className={className} target={target} onClick={onClick}>
        {children}
      </StyledLink>
    </Link>
  );
};

export default LinkArea;
