import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

interface SideNavState {
  isOpen: boolean;
}

const initialState: SideNavState = {
  isOpen: false,
};

const sideNavSlice = createSlice({
  name: 'sideNav',
  initialState,
  reducers: {
    sideNavToggled(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload;
    },
  },
});

export const { sideNavToggled } = sideNavSlice.actions;
export const selectSideNavIsOpen = (state: RootState) => state.sideNav.isOpen;
export default sideNavSlice.reducer;
