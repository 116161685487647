import IconSpinner from '@public/icons/regularIcons/icon-spinner.svg';
import { StyledSpinner, SvgWrapper } from './Spinner.styles';

interface Props {
  color?: ColorType;
  className?: string;
  scale?: number;
}

const Spinner = ({ color = 'black', className, scale }: Props) => {
  return (
    <SvgWrapper $scale={scale} data-testid="spinner">
      <StyledSpinner color={color} className={className} icon={IconSpinner} data-testid="icon-spinner" />
    </SvgWrapper>
  );
};

export default Spinner;
