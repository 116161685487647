import { getPage, PageWithSlots } from '@api';
import collectSlots from '@api/interfaces/collectSlots';
import { FORTY_MINUTES_IN_MS } from '@constants/timers';
import { useAppSelector } from '@hooks/redux';
import useAppRouter from '@hooks/useAppRouter';
import useCustomer from '@hooks/useCustomer';
import { selectCartDeliveryModeCode } from '@selectors/cart';
import { selectActiveStoreId } from '@selectors/delivery';
import useSWR, { SWRConfiguration } from 'swr';

export const getSlots = (data?: PageWithSlots) => {
  const {
    HeaderLeft: headerLeftSlot,
    HeaderCenter: headerCenterSlot,
    FooterCenter: footerSlots,
    MessageBox: messageBoxSlot,
  } = collectSlots(data?.slots, ['HeaderLeft', 'HeaderCenter', 'FooterCenter', 'MessageBox']);
  return {
    headerLeftSlot,
    headerCenterSlot,
    footerSlots,
    messageBoxSlot,
  };
};

export const LABEL = 'homePage';
type keyType = {
  endpoint: string;
  label: string;
  cmsTicketId?: string;
  customerId: string;
  activeStoreId?: string;
  deliveryModeCode: string;
};

const fetcher = ({ label, cmsTicketId }: keyType) => getPage({ label, cmsTicketId });

const options: SWRConfiguration = {
  refreshInterval: FORTY_MINUTES_IN_MS,
  keepPreviousData: true,
};

/**
 * @summary hook for storing the data from a cms home page in SWR.
 * cms home page include cms components for header, footer and global messages.
 * It also includes title and description used for SEO
 */
const useCmsLayout = () => {
  const { customerId } = useCustomer();
  const activeStoreId = useAppSelector(selectActiveStoreId);
  const deliveryModeCode = useAppSelector(selectCartDeliveryModeCode);
  const router = useAppRouter();
  const cmsTicketId = typeof router?.query?.cmsTicketId === 'string' ? router?.query?.cmsTicketId : undefined;

  const res = useSWR<PageWithSlots>(
    {
      endpoint: 'cms/page',
      label: LABEL,
      cmsTicketId, // only used in cmscockpit preview mode
      customerId,
      activeStoreId,
      deliveryModeCode,
    },
    fetcher,
    options
  );
  const slots = getSlots(res?.data);
  return {
    ...res,
    ...slots,
  };
};

export default useCmsLayout;
