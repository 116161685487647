import LinkArea from '@components/atoms/__DEPRECATED__/LinkArea/LinkArea';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../Header.styles';

export const Menu = styled.menu`
  display: flex;
  list-style-type: none;
  margin: 0 0 0 ${rem(16)};
  justify-content: space-between;
  min-width: ${rem(520)};

  ${media.desktop} {
    gap: ${rem(32)};
    margin: 0 ${rem(32)};
  }
`;

export const StyledLink = styled(LinkArea)<{ active: boolean }>`
  height: ${rem(HEADER_HEIGHT - 1)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid transparent;
  text-decoration: none;

  &:hover {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
    ${({ theme }) => `border-bottom: 2px solid ${theme.colors.colorCardinal}`}
  }

  ${({ active, theme }) => active && `border-bottom: 2px solid ${theme.colors.colorPrimary}; font-weight: 700;`}

  // hidden pseudo element with bold text to prevent layout displacement on hover
  &::after {
    content: attr(data-text);
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    height: 0;
    font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};

    @media speech {
      display: none;
    }
  }
`;
