import Paragraph from '@components/atoms/Paragraph';
import Skeleton from '@components/atoms/Skeleton';
import { Stack } from '@components/atoms/Stack';
import media from '@constants/responsive';
import { fontSizes } from '@styles/fontSizes';
import { rem } from 'polished';
import styled from 'styled-components';

const mobileSidePadding = 8;
const mobileBottomPadding = 8;

const desktopSidePadding = 16;
const desktopBottomPadding = 12;

const buttonPadding = 8;
const buttonHeight = 40;

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const SuggestionsContentWrapper = styled(Stack).attrs({ $space: 'small' })`
  height: 100%;
  overflow-y: auto;
  padding: ${rem(16)} ${rem(mobileSidePadding)} ${rem(2 * mobileBottomPadding + buttonHeight)};

  ${media.tabletPortrait} {
    padding: ${rem(24)} ${rem(desktopSidePadding)} ${rem(2 * (desktopBottomPadding + buttonPadding) + buttonHeight)};
  }
`;

export const SearchButtonWrapper = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 99;
  padding: ${rem(buttonPadding)};

  ${media.tabletPortrait} {
    padding: ${rem(desktopBottomPadding + buttonPadding)} ${rem(desktopSidePadding + buttonPadding)};
  }
`;

export const ShowAllButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${rem(buttonHeight)};
  padding: ${rem(8)};
  border: none;
  background-color: ${({ theme }) => theme.colors.colorAcadia};
  border-radius: ${rem(12)};
  cursor: pointer;
  appearance: none;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.colorStack};
    cursor: default;
  }
`;

export const TotalNumberOfResults = styled.div`
  margin-left: ${rem(12)};
`;

export const ProductsTitle = styled(Paragraph).attrs(() => ({ weight: 'medium' }))`
  font-size: ${rem(fontSizes.small.mobile)};
`;

export const ProductSkeleton = styled(Skeleton)`
  height: ${rem(89)};
  width: calc(100% - ${rem(8)});
  margin: ${rem(8)} 0 0 0;
  ${media.tabletLandscape} {
    height: ${rem(72)};
  }
`;
