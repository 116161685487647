import { rem } from 'polished';
import styled from 'styled-components';

interface IBadgeStyledProps {
  $color: 'red' | 'black';
}

export const Container = styled.div<IBadgeStyledProps>`
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
`;

interface ICountProps {
  $color: 'red' | 'black';
  $offsetHorizontal: number;
  $offsetVertical: number;
}
export const Count = styled.div<ICountProps>`
  position: absolute;
  top: ${({ $offsetVertical }) => rem($offsetVertical)};
  right: ${({ $offsetHorizontal }) => rem(-$offsetHorizontal)};

  transform: translate(50%, -50%);
  transform-origin: 100% 0%;

  min-width: ${rem(20)};
  min-height: ${rem(20)};
  font-size: ${rem(11)};
  vertical-align: sub;
  background-color: ${({ $color, theme }) => ($color === 'red' ? theme.colors.colorAlizarin : theme.colors.colorAcadia)};
  color: ${({ theme }) => theme.colors.colorWhite};

  border-radius: ${rem(25)};
  padding: ${rem(0)} ${rem(2)};
  display: flex;
  justify-content: center;
  align-items: center;
`;
