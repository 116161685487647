import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import DELIVERY_METHODS from '@constants/deliveryMethods';
import { useAppSelector } from '@hooks/redux';
import useCustomer from '@hooks/useCustomer';
import { selectCart, selectCartDeliveryModeCode, selectCartHasSlot } from '@selectors/cart';
import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';
import { Box } from './CartThresholds.styles';

interface MessageBoxProps {
  title: string;
  description?: string;
  centerTextOnFreeThreshold?: boolean;
}

const MessageBox = memo(({ title, description, centerTextOnFreeThreshold = false }: MessageBoxProps) => (
  <Box $centerTextOnFreeThreshold={centerTextOnFreeThreshold}>
    <Paragraph size="body-text" bold="bold">
      {title}
    </Paragraph>
    {description && <Paragraph size="body-text">{description}</Paragraph>}
  </Box>
));
MessageBox.displayName = 'MessageBox';

interface CarThresholdsProps {
  centerTextOnFreeThreshold?: boolean;
}

const CartThresholds = ({ centerTextOnFreeThreshold }: CarThresholdsProps) => {
  const { t } = useTranslation('cartpage');
  const deliveryModeCode = useAppSelector(selectCartDeliveryModeCode);
  const cartHasSlot = useAppSelector(selectCartHasSlot);
  const { isAnyB2BCustomer } = useCustomer();
  const {
    freeShippingVoucher,
    freeDeliveryOrPickupThresholdStatus: { eligible = false, applied = false, amountToShopFor = undefined } = {},
  } = useAppSelector(selectCart);
  const isHomeDelivery = deliveryModeCode === DELIVERY_METHODS.HOME_CODE;
  const isPickupInStore = deliveryModeCode === DELIVERY_METHODS.PICKUP_CODE;

  if (!cartHasSlot) {
    return null;
  }

  if (isHomeDelivery) {
    if (eligible && !applied && !freeShippingVoucher) {
      return (
        <MessageBox
          title={t(`thresholds->${isAnyB2BCustomer ? 'b2b' : ''}getFreeHomeDelivery`)}
          description={t(`thresholds->${isAnyB2BCustomer ? 'b2b' : ''}amountToShopForFreeHomeDelivery`, {
            amount: amountToShopFor?.formattedValue,
          })}
        />
      );
    }
    if ((eligible && applied) || freeShippingVoucher) {
      return (
        <MessageBox
          title={t(`thresholds->${isAnyB2BCustomer ? 'b2b' : ''}freeHomeDelivery`)}
          centerTextOnFreeThreshold={centerTextOnFreeThreshold}
        />
      );
    }
  }
  if (isPickupInStore) {
    if (eligible && !applied) {
      return (
        <MessageBox
          title={t(`thresholds->${isAnyB2BCustomer ? 'b2b' : ''}getFreePickup`)}
          description={t(`thresholds->${isAnyB2BCustomer ? 'b2b' : ''}amountToShopForFreePickup`, {
            amount: amountToShopFor?.formattedValue,
          })}
        />
      );
    }
    if (eligible && applied) {
      return (
        <MessageBox
          title={t(`thresholds->${isAnyB2BCustomer ? 'b2b' : ''}freePickup`)}
          centerTextOnFreeThreshold={centerTextOnFreeThreshold}
        />
      );
    }
  }

  return null;
};

export default memo(CartThresholds);
