import useAppRouter from '@hooks/useAppRouter';

const useCurrentRoute = () => {
  const { asPath } = useAppRouter();
  const isCurrentRoute = (url: string): boolean => {
    return asPath === url;
  };

  return {
    isCurrentRoute,
  };
};

export default useCurrentRoute;
