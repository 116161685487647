import { getCurrentDate, getCurrentTime } from '@helpers/getCurrentDateAndTime';
import TagManager from 'react-gtm-module';
import { linkTrackingName } from '@helpers/gtmTrackEvents';

export const clickOnLink = (link: string, title = 'No tile') => {
  const linkCategory = `${linkTrackingName(link)} | ${title}`;

  // GA3 - TO BE REMOVED - when GA3 is no longer active
  TagManager.dataLayer({
    dataLayer: {
      event: 'track',
      category: linkCategory,
      action: 'blurb_clicked',
      label: `vertical | ${title}`,
    },
  });
  // GA4 tracking verticalBannerTracker
  TagManager.dataLayer({
    dataLayer: {
      event: 'element_clicked',
      date: getCurrentDate(),
      element_type: 'vertical_banner',
      slide_pstn: null,
      sphere_name: 'content',
      target: link,
      time: getCurrentTime(),
    },
  });
};
