// z-index for layers
const Layer = {
  content: 0,
  below_header: 98,
  header: 99,
  dropdown: 150,
};

export type Layers = typeof Layer;

export default Layer;
