import TagManager from 'react-gtm-module';

export const qrScanned = () => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      action: 'qr_scanned',
    },
  });
};

export const loginMethodChosen = (method: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'Login B2C',
      action: 'login_method_chosen',
      label: method,
    },
  });
};
