import { AxfoodProductDetailsViewModel, FeedingData, FeedingFrequencyData } from '@api/generated/storefront';
import EnergyDeclarationImage from '@components/atoms/EnergyDeclarationImage';
import Table from '@components/atoms/Table';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import useTranslation from 'next-translate/useTranslation';
import { useMemo } from 'react';
import { InfoBlock, ProductInfoWrapper } from './ProductOtherListing.styles';

interface ProductOtherListingProps {
  product: AxfoodProductDetailsViewModel;
}

type RequiredFeedingFrequencyData = Required<FeedingFrequencyData>;

const ProductOtherListing = ({ product }: ProductOtherListingProps) => {
  const { t } = useTranslation('productDetails');
  const { animalData, energyDeclaration } = product;

  const rowData = useMemo(
    () =>
      animalData?.feedingData
        ? animalData.feedingData.map((item: FeedingData) => {
            const data = [
              { key: 'weight', value: item.weight || '' },
              { key: 'lifeStage', value: item.lifeStage || '' },
            ];
            if (animalData.feedingFrequencies?.length) {
              animalData.feedingFrequencies
                .filter((freq): freq is RequiredFeedingFrequencyData => 'id' in freq && !!freq.value)
                .sort((a, b) => a.id - b.id)
                .forEach((freq) => {
                  const value =
                    item.feedingDetails?.length && item.feedingDetails[0]?.feedingFrequencyId !== freq.id
                      ? ''
                      : (item.feedingDetails?.length && item.feedingDetails[0]?.amount?.join(' / ')) || '';
                  const row = {
                    key: `amount-${freq.id}`,
                    value,
                  };
                  data.push(row);
                });
            }
            return { data };
          })
        : [],
    [animalData]
  );

  const feedingFrequencies = animalData?.feedingFrequencies?.length
    ? animalData.feedingFrequencies.map((item, i: number) => ({
        id: `amount-${item.id}`,
        label: item.value || '',
        colspan: 1,
      }))
    : [];

  const feedingdataTableHeader = [
    { id: 'weight', label: t('productDetails->animalData->weight'), colspan: 1 },
    { id: 'lifeStage', label: t('productDetails->animalData->age'), colspan: 1 },
    ...feedingFrequencies,
  ];

  return (
    <ProductInfoWrapper>
      {energyDeclaration && energyDeclaration.image && (
        <InfoBlock>
          <EnergyDeclarationImage src={energyDeclaration.image} alt={t('energydeclaration:title')} />
        </InfoBlock>
      )}
      {animalData?.feedingInstructions && (
        <InfoBlock>
          <Paragraph size="body-text" bold="bold">
            {t('productDetails->animalData->feedingInstructions')}
          </Paragraph>
          <Paragraph size="body-text">{animalData.feedingInstructions}</Paragraph>
        </InfoBlock>
      )}
      {animalData?.feedingData && (
        <Table fullWidth rows={rowData} data-testid="animal-feeding-instructions" columns={feedingdataTableHeader} />
      )}
    </ProductInfoWrapper>
  );
};

export default ProductOtherListing;
