import { rem } from 'polished';
import styled, { css } from 'styled-components';
import { ButtonSize, ButtonVariant, SupportedRadius } from './HeaderButton';

const primary = css`
  background-color: ${({ theme }) => theme.colors.colorPrimary};
  color: ${({ theme }) => theme.colors.colorWhite};

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.colorCardinal};
    }
  }
  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.colorCardinal};
  }
`;

const secondary = css`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  color: ${({ theme }) => theme.colors.colorTextPrimary};

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.colorDesertStorm};
    }
  }
  &:focus-visible {
    background-color: ${({ theme }) => theme.colors.colorDesertStorm};
  }
`;

const transparent = css`
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  background-color: transparent;
  text-decoration: none;

  @media (hover: hover) {
    &:hover,
    &:hover svg {
      color: ${({ theme }) => theme.colors.colorCardinal};
    }
  }
  &:focus-visible,
  &:focus-visible svg {
    color: ${({ theme }) => theme.colors.colorCardinal};
  }
`;

const lookupVariant = {
  primary,
  secondary,
  transparent,
};

export const StyledButton = styled.button<{
  $variant: ButtonVariant;
  $borderRadius: SupportedRadius;
  $buttonSize: ButtonSize;
  $hasMultipleChildren: boolean;
}>`
  flex: none;
  ${({ $variant }) => lookupVariant[$variant]}
  border-radius: ${({ $borderRadius }) => rem($borderRadius)};
  height: ${({ $buttonSize }) => ($buttonSize === 'md' ? rem(40) : rem(32))};
  font-size: ${({ $buttonSize }) => ($buttonSize === 'md' ? rem(14) : rem(12))};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ $hasMultipleChildren }) => $hasMultipleChildren && `${rem(4)}`};
  padding: ${({ $hasMultipleChildren }) =>
    $hasMultipleChildren ? `${rem(8)} ${rem(24)} ${rem(8)} ${rem(16)}` : rem(8)};
  text-decoration: none;
  cursor: pointer;
`;

export const StyledLink = styled(StyledButton).attrs({ as: 'a' })``;
