import { rem } from 'polished';
import styled, { CSSObject, css, keyframes } from 'styled-components';

const BACKGROUND_COLOR = '#eee';
const HIGHLIGHT_COLOR = '#f5f5f5';

export const skeletonKeyframes = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;
export const SkeletonRow = styled.span<{
  $width: number | string;
  $height: number | string;
  $animation: boolean;
  $style?: CSSObject;
}>`
  display: inline-block;
  width: ${(props) =>
    Number.isInteger(props.$width)
      ? css`
          ${rem(props.$width)}
        `
      : props.$width};
  height: ${(props) =>
    Number.isInteger(props.$height)
      ? css`
          ${rem(props.$height)}
        `
      : props.$height};
  background-color: ${BACKGROUND_COLOR};
  background-image: linear-gradient(90deg, ${BACKGROUND_COLOR}, ${HIGHLIGHT_COLOR}, ${BACKGROUND_COLOR});
  background-repeat: no-repeat;
  margin: ${rem('5px')} 0;
  border-radius: ${rem('3px')};
  animation: ${(props) => (props.$animation ? css`1.2s ${skeletonKeyframes} ease-in-out infinite` : 'none')};
  ${(props) => props.$style ?? {}}
`;
export const SkeletonCircle = styled(SkeletonRow)`
  border-radius: 50%;
`;
export const SkeletonHalfcircle = styled(SkeletonRow)<{ $width: number | string; $style?: CSSObject }>`
  ${(props) => props.$style ?? {}}
  border-top-left-radius: ${(props) =>
    Number.isInteger(props.$width)
      ? css`
          ${rem(props.$width)}
        `
      : props.$width};
  border-top-right-radius: ${(props) =>
    Number.isInteger(props.$width)
      ? css`
          ${rem(props.$width)}
        `
      : props.$width};
`;

export const SkeletonRect = styled(SkeletonRow)`
  border-radius: 0;
`;
export const SkeletonButton = styled(SkeletonRow)`
  border-radius: ${rem(40)};
`;
