import styled from 'styled-components';

export const AddToListPopper = styled.div`
  z-index: 60;
  position: absolute;
  left: 0;
`;

export const AddToListWrapper = styled.div`
  position: relative;
`;
