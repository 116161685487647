import { rem } from 'polished';
import styled from 'styled-components';

export const MenuContainer = styled.div`
  border-radius: ${rem(16)};
  background-color: ${({ theme }) => theme.colors.colorMenuBackground};
  overflow: hidden;
`;

export const List = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li`
  &:last-child {
    border-bottom: none;
  }
`;

export const Divider = styled.div`
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.colorSilver};
  margin: 0 ${rem(16)};
`;
