import styled from 'styled-components';
import { rem } from 'polished';

export const AppLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${rem(16)};
  padding: ${rem(16)} 0 0 0;
`;

export const AppLink = styled.div`
  width: 100%;
  max-width: ${rem(118)};
`;
