import { rem } from 'polished';
import styled from 'styled-components';

export const StyledUsp = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${rem(8)};
  white-space: nowrap;
`;
