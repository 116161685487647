import { ReactNode } from 'react';
import { Container, Count } from './Badge.styles';

export interface Props {
  value?: string | number;
  color?: 'red' | 'black';
  children?: ReactNode;
  offset?: [number, number];
  showZero?: boolean;
}

const Badge = ({ color = 'red', value, children, offset = [0, 0], showZero = false }: Props) => {
  if (!value && !(showZero && value === 0)) return <>{children}</>;

  const [offsetHorizontal, offsetVertical] = offset;
  return (
    <Container $color={color} data-testid="badge">
      {children}
      <Count
        data-testid="count-badge"
        $color={color}
        $offsetHorizontal={offsetHorizontal}
        $offsetVertical={offsetVertical}
      >
        {value}
      </Count>
    </Container>
  );
};

export default Badge;
