import HeaderButton from '@components/atoms/HeaderButton/HeaderButton';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const HEADER_HEIGHT = 64;

export const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  z-index: ${({ theme }) => theme.layers.header};
  background-color: ${({ theme }) => theme.colors.colorPrimaryPink};
`;

export const HeaderBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderContentWrapper = styled(HeaderBar)`
  position: relative;
  flex-shrink: 0;
  min-height: ${rem(HEADER_HEIGHT)};
  display: flex;
  flex-wrap: wrap;
  column-gap: ${rem(8)};

  ${media.mobile} {
    column-gap: ${rem(16)};
  }
`;

export const ContinueShoppingButton = styled(HeaderButton)`
  margin: 0 ${rem(8)};
  ${media.mobile} {
    margin: 0 ${rem(16)};
  }
`;

export const SearchContainer = styled(HeaderBar)`
  justify-content: center;
  padding: 0 ${rem(16)} ${rem(8)} ${rem(16)};
  gap: ${rem(8)};

  ${media.tabletPortrait} {
    padding: ${rem(16)} ${rem(32)};
  }
`;

export const SearchSideContainer = styled.div`
  flex: 1 1 0;
  display: flex;
  position: relative;
`;

export const SearchWrapper = styled.div`
  flex: 5 0;
  display: flex;
  justify-content: center;
  max-width: ${rem(984)};

  ${media.desktop} {
    width: ${rem(712)};
    max-width: ${rem(712)};
  }
`;

export const TopLeftContainer = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  order: 1;
  padding-left: ${rem(16)};
  ${media.desktop} {
    padding-left: ${rem(32)};
  }
`;

export const Hamburger = styled(HeaderButton)`
  margin-right: ${rem(8)};
  ${media.mobile} {
    margin-right: ${rem(16)};
  }
`;

export const TopRightContainer = styled(TopLeftContainer)`
  order: 3;
  padding: 0 ${rem(16)} 0 0;
  margin: 0 0 0 auto;
  ${media.desktop} {
    padding: 0 ${rem(32)} 0 0;
  }
`;

export const MobileButtonBar = styled(HeaderBar)`
  align-items: stretch;
  order: 5;
  flex: 0 0 100%;
`;

export const MobileButtonContainer = styled(HeaderBar)`
  flex: 1 0 0;
  display: flex;
  flex-direction: row;
`;

export const VerticalBar = styled.div`
  border-left: ${rem(1)} solid ${({ theme }) => theme.colors.colorCararra};
`;

export const SearchDeliveryWrapper = styled.div<{ $isScrollingDown: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: ${rem(8)};
  order: 4;
  border-top: 1px solid ${({ theme }) => theme.colors.colorBeautyBush};

  padding: ${rem(8)} ${rem(16)};
  ${media.tabletPortrait} {
    padding: ${rem(16)} ${rem(32)};
  }

  ${({ $isScrollingDown }) =>
    $isScrollingDown &&
    css`
      flex: 1;
      order: 2;
      padding: 0;
      border: none;
    `}
`;
