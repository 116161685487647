/**
 * Statuses - is used for coupling copy keys in invoice signing.
 * Diverges from BankID documentation since BE implementation for invoice signing alters these.
 */

export const ACCESS_DENIED_RP = 'ACCESS_DENIED_RP';
export const ALREADY_IN_PROGRESS = 'ALREADY_IN_PROGRESS';
export const CANCELLED = 'CANCELLED';
export const CERTIFICATE_ERR = 'CERTIFICATE_ERR';
export const CLIENT_ERR = 'CLIENT_ERR';
export const COMPLETE = 'COMPLETE';
export const CONNECTION_ERROR = 'CONNECTION_ERROR';
export const EXPIRED_TRANSACTION = 'EXPIRED_TRANSACTION';
export const FAILED = 'FAILED';
export const INTERNAL_ERROR = 'INTERNAL_ERROR';
export const INVALID_PARAMETERS = 'INVALID_PARAMETERS';
export const NO_CLIENT = 'NO_CLIENT';
export const OUTOFSERVICE_ERROR = 'OUTOFSERVICE_ERROR';
export const OUTSTANDING_TRANSACTION = 'OUTSTANDING_TRANSACTION';
export const PENDING = 'PENDING';
export const RETRY = 'RETRY';
export const SELECT_SIGN_IN_METHOD = 'SELECT_SIGN_IN_METHOD';
export const START_FAILED = 'START_FAILED';
export const UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR';
export const UNDEFINED = 'UNDEFINED';
export const USER_SIGN = 'USER_SIGN';

export type BANK_ID_SIGN_STATUS =
  | typeof ACCESS_DENIED_RP
  | typeof ALREADY_IN_PROGRESS
  | typeof CANCELLED
  | typeof CERTIFICATE_ERR
  | typeof CLIENT_ERR
  | typeof COMPLETE
  | typeof CONNECTION_ERROR
  | typeof EXPIRED_TRANSACTION
  | typeof FAILED
  | typeof INTERNAL_ERROR
  | typeof INVALID_PARAMETERS
  | typeof NO_CLIENT
  | typeof OUTOFSERVICE_ERROR
  | typeof OUTSTANDING_TRANSACTION
  | typeof PENDING
  | typeof RETRY
  | typeof SELECT_SIGN_IN_METHOD
  | typeof START_FAILED
  | typeof UNAUTHORIZED_ERROR
  | typeof UNDEFINED
  | typeof USER_SIGN;

interface BANK_ID_SIGN_STATUSES {
  ACCESS_DENIED_RP: typeof ACCESS_DENIED_RP;
  ALREADY_IN_PROGRESS: typeof ALREADY_IN_PROGRESS;
  CANCELLED: typeof CANCELLED;
  CERTIFICATE_ERR: typeof CERTIFICATE_ERR;
  CLIENT_ERR: typeof CLIENT_ERR;
  COMPLETE: typeof COMPLETE;
  CONNECTION_ERROR: typeof CONNECTION_ERROR;
  EXPIRED_TRANSACTION: typeof EXPIRED_TRANSACTION;
  FAILED: typeof FAILED;
  INTERNAL_ERROR: typeof INTERNAL_ERROR;
  INVALID_PARAMETERS: typeof INVALID_PARAMETERS;
  NO_CLIENT: typeof NO_CLIENT;
  OUTOFSERVICE_ERROR: typeof OUTOFSERVICE_ERROR;
  OUTSTANDING_TRANSACTION: typeof OUTSTANDING_TRANSACTION;
  PENDING: typeof PENDING;
  RETRY: typeof RETRY;
  SELECT_SIGN_IN_METHOD: typeof SELECT_SIGN_IN_METHOD;
  START_FAILED: typeof START_FAILED;
  UNAUTHORIZED_ERROR: typeof UNAUTHORIZED_ERROR;
  UNDEFINED: typeof UNDEFINED;
  USER_SIGN: typeof USER_SIGN;
}

export const BANK_ID_SIGN_STATUSES: BANK_ID_SIGN_STATUSES = {
  ACCESS_DENIED_RP,
  ALREADY_IN_PROGRESS,
  CANCELLED,
  CERTIFICATE_ERR,
  CLIENT_ERR,
  COMPLETE,
  CONNECTION_ERROR,
  EXPIRED_TRANSACTION,
  FAILED,
  INTERNAL_ERROR,
  INVALID_PARAMETERS,
  NO_CLIENT,
  OUTOFSERVICE_ERROR,
  OUTSTANDING_TRANSACTION,
  PENDING,
  RETRY,
  SELECT_SIGN_IN_METHOD,
  START_FAILED,
  UNAUTHORIZED_ERROR,
  UNDEFINED,
  USER_SIGN,
};

/**
 * hintCodes & translationCodes - Should primarily be used for coupling copy keys related to BankID sign in
 */

export const LOGIN_ALREADY_IN_PROGRESS = 'alreadyInProgress';
export const LOGIN_CANCELLED = 'cancelled';
export const LOGIN_CERTIFICATE_ERROR = 'certificateError';
export const LOGIN_CUSTOMER_NOT_FOUND = 'customerNotFound';
export const LOGIN_EXPIRED_TRANSACTION = 'expiredTransaction';
export const LOGIN_INTERNAL_ERROR = 'internalError';
export const LOGIN_MAINTENANCE = 'maintenance';
export const LOGIN_NO_CLIENT = 'noClient';
export const LOGIN_OUTSTANDING_TRANSACTION = 'outstandingTransaction';
export const LOGIN_REQUEST_TIMEOUT = 'requestTimeout';
export const LOGIN_START_FAILED = 'startFailed';
export const LOGIN_STARTED = 'started';
export const LOGIN_USER_CANCEL = 'userCancel';
export const LOGIN_USER_SIGN = 'userSign';

export type BankIdHintCodeType =
  | typeof LOGIN_ALREADY_IN_PROGRESS
  | typeof LOGIN_CANCELLED
  | typeof LOGIN_CERTIFICATE_ERROR
  | typeof LOGIN_CUSTOMER_NOT_FOUND
  | typeof LOGIN_EXPIRED_TRANSACTION
  | typeof LOGIN_INTERNAL_ERROR
  | typeof LOGIN_MAINTENANCE
  | typeof LOGIN_NO_CLIENT
  | typeof LOGIN_OUTSTANDING_TRANSACTION
  | typeof LOGIN_REQUEST_TIMEOUT
  | typeof LOGIN_START_FAILED
  | typeof LOGIN_STARTED
  | typeof UNDEFINED
  | typeof LOGIN_USER_CANCEL
  | typeof LOGIN_USER_SIGN;

interface BankIdHintCodesType {
  LOGIN_ALREADY_IN_PROGRESS: typeof LOGIN_ALREADY_IN_PROGRESS;
  LOGIN_CANCELLED: typeof LOGIN_CANCELLED;
  LOGIN_CERTIFICATE_ERROR: typeof LOGIN_CERTIFICATE_ERROR;
  LOGIN_CUSTOMER_NOT_FOUND: typeof LOGIN_CUSTOMER_NOT_FOUND;
  LOGIN_EXPIRED_TRANSACTION: typeof LOGIN_EXPIRED_TRANSACTION;
  LOGIN_INTERNAL_ERROR: typeof LOGIN_INTERNAL_ERROR;
  LOGIN_MAINTENANCE: typeof LOGIN_MAINTENANCE;
  LOGIN_NO_CLIENT: typeof LOGIN_NO_CLIENT;
  LOGIN_OUTSTANDING_TRANSACTION: typeof LOGIN_OUTSTANDING_TRANSACTION;
  LOGIN_REQUEST_TIMEOUT: typeof LOGIN_REQUEST_TIMEOUT;
  LOGIN_START_FAILED: typeof LOGIN_START_FAILED;
  LOGIN_STARTED: typeof LOGIN_STARTED;
  UNDEFINED: typeof UNDEFINED;
  LOGIN_USER_CANCEL: typeof LOGIN_USER_CANCEL;
  LOGIN_USER_SIGN: typeof LOGIN_USER_SIGN;
}

export const BANK_ID_HINT_CODES_ARRAY = [
  LOGIN_ALREADY_IN_PROGRESS,
  LOGIN_CANCELLED,
  LOGIN_CERTIFICATE_ERROR,
  LOGIN_CUSTOMER_NOT_FOUND,
  LOGIN_EXPIRED_TRANSACTION,
  LOGIN_INTERNAL_ERROR,
  LOGIN_MAINTENANCE,
  LOGIN_NO_CLIENT,
  LOGIN_OUTSTANDING_TRANSACTION,
  LOGIN_REQUEST_TIMEOUT,
  LOGIN_START_FAILED,
  LOGIN_STARTED,
  LOGIN_USER_CANCEL,
  LOGIN_USER_SIGN,
];

export const BANK_ID_SIGN_HINT_CODES: BankIdHintCodesType = {
  LOGIN_ALREADY_IN_PROGRESS,
  LOGIN_CANCELLED,
  LOGIN_CERTIFICATE_ERROR,
  LOGIN_CUSTOMER_NOT_FOUND,
  LOGIN_EXPIRED_TRANSACTION,
  LOGIN_INTERNAL_ERROR,
  LOGIN_MAINTENANCE,
  LOGIN_NO_CLIENT,
  LOGIN_OUTSTANDING_TRANSACTION,
  LOGIN_REQUEST_TIMEOUT,
  LOGIN_START_FAILED,
  LOGIN_STARTED,
  UNDEFINED, // client-side only hintCode
  LOGIN_USER_CANCEL,
  LOGIN_USER_SIGN,
};

/**
 * Statuses for auth - keep these as actual statuses and do not mix with hintCodes
 */
export const CUSTOMERNOTFOUND = 'CUSTOMERNOTFOUND'; // BE specific status to signal customer not registred upon sucessful BankID auth

export type BankIdAuthStatusType =
  | typeof COMPLETE
  | typeof CUSTOMERNOTFOUND
  | typeof FAILED
  | typeof PENDING
  | typeof SELECT_SIGN_IN_METHOD
  | typeof UNDEFINED;

interface BankIdAuthStatusesType {
  COMPLETE: typeof COMPLETE;
  CUSTOMERNOTFOUND: typeof CUSTOMERNOTFOUND;
  FAILED: typeof FAILED;
  PENDING: typeof PENDING;
  SELECT_SIGN_IN_METHOD: typeof SELECT_SIGN_IN_METHOD;
  UNDEFINED: typeof UNDEFINED;
}

export const BANK_ID_AUTH_STATUSES: BankIdAuthStatusesType = {
  COMPLETE,
  CUSTOMERNOTFOUND,
  FAILED,
  PENDING,
  // client-side only statuses:
  SELECT_SIGN_IN_METHOD,
  UNDEFINED,
};

/**
 * BankIdTranslationCodes - derived from BankID documentation
 * Implementation details: https://axfood.atlassian.net/wiki/spaces/DA/pages/2391867602/Login+flow+-+Hemk+p
 */

export const RFA1 = 'RFA1';
export const RFA2 = 'RFA2';
export const RFA3 = 'RFA3';
export const RFA4 = 'RFA4';
export const RFA5 = 'RFA5';
export const RFA6 = 'RFA6';
export const RFA8 = 'RFA8';
export const RFA9 = 'RFA9';
export const RFA13 = 'RFA13';
export const RFA14A = 'RFA14A';
export const RFA14B = 'RFA14B';
export const RFA15 = 'RFA15';
export const RFA16 = 'RFA16';
export const RFA17A = 'RFA17A';
export const RFA17B = 'RFA17B';
export const RFA18 = 'RFA18';
export const RFA21 = 'RFA21';
export const RFA22 = 'RFA22';
export const customerNotFound = LOGIN_CUSTOMER_NOT_FOUND;

export type BankIdTranslationCodeType =
  | typeof RFA1
  | typeof RFA2
  | typeof RFA3
  | typeof RFA4
  | typeof RFA5
  | typeof RFA6
  | typeof RFA8
  | typeof RFA9
  | typeof RFA13
  | typeof RFA14A
  | typeof RFA14B
  | typeof RFA15
  | typeof RFA16
  | typeof RFA17A
  | typeof RFA17B
  | typeof RFA18
  | typeof RFA21
  | typeof RFA22
  | typeof customerNotFound;

interface BankIdTranslationCodesType {
  RFA1: typeof RFA1;
  RFA2: typeof RFA2;
  RFA3: typeof RFA3;
  RFA4: typeof RFA4;
  RFA5: typeof RFA5;
  RFA6: typeof RFA6;
  RFA8: typeof RFA8;
  RFA9: typeof RFA9;
  RFA13: typeof RFA13;
  RFA14A: typeof RFA14A;
  RFA14B: typeof RFA14B;
  RFA15: typeof RFA15;
  RFA16: typeof RFA16;
  RFA17A: typeof RFA17A;
  RFA17B: typeof RFA17B;
  RFA18: typeof RFA18;
  RFA21: typeof RFA21;
  RFA22: typeof RFA22;
  customerNotFound: typeof LOGIN_CUSTOMER_NOT_FOUND;
}

export const BANK_ID_TRANSLATION_CODES: BankIdTranslationCodesType = {
  RFA1,
  RFA2,
  RFA3,
  RFA4,
  RFA5,
  RFA6,
  RFA8,
  RFA9,
  RFA13,
  RFA14A,
  RFA14B,
  RFA15,
  RFA16,
  RFA17A,
  RFA17B,
  RFA18,
  RFA21,
  RFA22,
  customerNotFound,
};
