import VideoIframe from '@components/molecules/VideoModal/VideoIframe';
import useVideoBanner from '../useVideoBanner';
import VideoThumbnail from './VideoThumbnail';

export interface VideoFullWidthProps {
  videoLink: string;
  title?: string;
}

export const VideoFullWidth = ({ videoLink, title = 'Fullwidth video no title' }: VideoFullWidthProps) => {
  const { showIframe, thumbnail, onPlay } = useVideoBanner(title, videoLink);

  return showIframe ? (
    <VideoIframe videoLink={videoLink} />
  ) : (
    <VideoThumbnail data-testid="video-full-width-thumbnail" thumbnail={thumbnail} onPlay={onPlay} />
  );
};

export default VideoFullWidth;
