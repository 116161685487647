import { ActiveCustomerType } from '@constants/customerType';
import { LoginTabType } from '@features/login/constants';
import { OnboardingEntryPoint } from '@features/onboarding/types';
import { getCurrentDate, getCurrentTime } from '@helpers/getCurrentDateAndTime';
import TagManager from 'react-gtm-module';

export const CUSTOMER_LOGIN_TYPE = {
  INDIVIDUAL: 'individual',
  COMPANY: 'company',
  ANONYMOUS: 'anonymous',
  PREVIOUS_LOGIN: 'previous_login',
  USER_LOGIN: 'user_login',
};

const getCustomerType = (isB2BCustomer?: boolean) => {
  return isB2BCustomer ? CUSTOMER_LOGIN_TYPE.COMPANY : CUSTOMER_LOGIN_TYPE.INDIVIDUAL;
};

const isUserLoggedIn = (customerId?: string) => {
  return customerId !== CUSTOMER_LOGIN_TYPE.ANONYMOUS;
};

const getLoginSource = (latestLoginIsAutoLogin?: boolean) => {
  return latestLoginIsAutoLogin ? CUSTOMER_LOGIN_TYPE.PREVIOUS_LOGIN : CUSTOMER_LOGIN_TYPE.USER_LOGIN;
};

export const loginError = (message: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'login_modal',
      action: 'error_message_shown',
      label: message,
      value: 0,
    },
  });
};

export const loggedInUser = (type: string, userLogin: string, userid?: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'identify',
      userId: userid,
      customerType: type,
      loginSource: userLogin,
    },
  });
};

export const notLoggedInUser = () => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'identify',
      userId: 0,
    },
  });
};

export const userLogout = () => {
  // GA3 - TO BE REMOVED - when GA3 is no longer active
  TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'user',
      action: 'logged_out',
      label: '',
      value: 0,
    },
  });

  // GA4 Tracking logout
  TagManager.dataLayer({
    dataLayer: {
      event: 'logout',
      date: getCurrentDate(),
      identification_pnt: 'main_menu',
      sphere_name: 'identification',
      time: getCurrentTime(),
    },
  });
};

const userLoginInternal = (isB2b: boolean, isBankId: boolean) =>
  TagManager.dataLayer({
    dataLayer: {
      event: 'track',
      category: isB2b ? 'B2B' : 'B2C',
      action: 'logged_in',
      label: isBankId ? 'bankid' : 'pwd',
    },
  });

export const userLoginForB2bWithPassword = () => {
  return userLoginInternal(true, false);
};

export const userLoginForB2cWithPassword = () => {
  return userLoginInternal(false, false);
};

export const userLoginForB2cWithBankId = () => {
  return userLoginInternal(false, true);
};

interface trackUserParams {
  customerId?: string;
  isB2BCustomer?: boolean;
  latestLoginIsAutoLogin?: boolean;
  idInSourceSystem?: string;
  userLogin?: string;
}
export const trackUser = ({
  customerId,
  isB2BCustomer,
  latestLoginIsAutoLogin,
  idInSourceSystem,
  userLogin,
}: trackUserParams) => {
  if (isUserLoggedIn(customerId)) {
    loggedInUser(
      getCustomerType(isB2BCustomer),
      userLogin ? CUSTOMER_LOGIN_TYPE.USER_LOGIN : getLoginSource(latestLoginIsAutoLogin),
      idInSourceSystem
    );
  } else notLoggedInUser();
};

export const trackCustomerTypeChange = (customerType: 'individual' | 'company') => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'login_modal',
      action: `toggled_to_${customerType}`,
      label: '',
      value: 0,
    },
  });
};

export const passwordForgotten = (customerType: ActiveCustomerType) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'Login',
      action: customerType,
      label: 'password_forgotten',
    },
  });
};

export const trackLogin = (entryPoint: OnboardingEntryPoint, entryIdMethod: '' | LoginTabType) => {
  const formattedMethod = entryIdMethod === 'bankId' ? 'bankID' : entryIdMethod;
  return TagManager.dataLayer({
    dataLayer: {
      event: 'login',
      date: getCurrentDate(),
      identification_method: formattedMethod,
      identification_pnt: entryPoint,
      sphere_name: 'identification',
      time: getCurrentTime(),
    },
  });
};
