import { Impression } from '@components/molecules/Product/ProductDataMapper/types';
import { ProductViewModel } from '@components/molecules/__DEPRECATED__/Product/Product';
import { URLPREFIXES } from '@constants/links';
import gtmTrack from '@helpers/gtmTrack';
import { splitEventListName } from '@helpers/gtmTrackEvents';
import { productToImpressionObject } from '@helpers/productHelper';

export interface TrackItem extends Impression {
  position: number;
  list: string;
}
export const trackProductImpressionsWithPosition = (items: ImpressionProduct[], eventListName: string) => {
  const isArticlePage = eventListName.startsWith(URLPREFIXES.ARTIKEL);

  const impressions = items.map((item) => {
    return {
      ...productToImpressionObject(item.product),
      list: isArticlePage ? `article | ${splitEventListName(eventListName)}` : eventListName,
      position: item.position,
    };
  });

  trackEcommerceImpressionsPushed(impressions);
};

export const trackProductImpressionsPushed = (impressionObjects: Impression[], eventListName: string) => {
  const impressions = impressionObjects.map((item, index) => {
    return {
      ...item,
      list: eventListName,
      position: index + 1,
    };
  });
  trackEcommerceImpressionsPushed(impressions);
};

export const trackEcommerceImpressionsPushed = (trackItems: TrackItem[]) => {
  gtmTrack({
    event: 'impressionsPushed',
    ecommerce: {
      currencyCode: 'SEK',
      impressions: trackItems,
    },
  });
};

export const productMixAndMatchClicked = (item: string) => {
  gtmTrack({
    event: 'Track',
    category: 'multi_product_offer | V&B',
    action: 'products_shown',
    label: item,
    value: 0,
  });
};

export const trackProductClicked = (item: ProductViewModel, eventListName: string) => {
  trackProductClickedImpression(productToImpressionObject(item), eventListName);
};

export const trackProductClickedImpression = (impressionObject: Impression, eventListName: string) => {
  gtmTrack({
    event: 'productClick',
    ecommerce: {
      currencyCode: 'SEK',
      click: {
        actionField: { list: eventListName },
        products: [impressionObject],
      },
    },
  });
};
