import { locationIsExternal, resolveUrlString } from '@helpers/link';
import preserveNativeAppQueries from '@hooks/useLinkUtils/preserveNativeAppQueries';
import useRouteByReplaceInNativeApp from '@hooks/useLinkUtils/useRouteByReplaceInNativeApp';
import useConfig from '@hooks/useConfig';
import { useRouter } from 'next/router';

/* Duplicated from type NextRouter['push'] options which is not exported by next/router :( */
interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}

/**
 * @description Custom instance of next/router should be kept as close to next/router as possible.
 * Be sure to consult next/router documentation before making any changes/addditions in behaviour here ..chances are you're thing is already covered
 */
//TODO B2C-30358:Hopefull we can remove this hook and just use next/router directly.

const useAppRouter = () => {
  const nextRouter = useRouter();
  const { config } = useConfig();
  const domainRegex = new RegExp(`${config?.PUBLIC_HOST}[/]?`);
  const { checkRouteByReplaceInNativeApp } = useRouteByReplaceInNativeApp();

  /**
   * @description call with url: string prepended with Config.API including your params for urls considered legacy in any environment,
   * they will resolve as internal if allowlisted.
   * calls with url: UrlObject will always perform an internal transition
   */
  //TODO B2C-30358: Remove: The only thing still needed here is the preservation of query params for native app, everything else should be handled by next/link
  const push = (url: UrlType, as?: UrlType, options?: TransitionOptions) => {
    const urlWithPreservedQueries = preserveNativeAppQueries(url, nextRouter.query);
    // some paths should be replaced, not pushed, when in native app
    const pushByReplace = checkRouteByReplaceInNativeApp(urlWithPreservedQueries, as);
    const pushByReplaceOnGoingBack = checkRouteByReplaceInNativeApp(nextRouter.pathname, nextRouter.asPath);
    const urlString = resolveUrlString(urlWithPreservedQueries);
    const isExternalUrl = locationIsExternal(urlString);
    const location = urlWithPreservedQueries;
    const sameDomain = domainRegex.test(urlString);

    if (isExternalUrl && !sameDomain) {
      window.location.href = urlString;
      return;
    }
    if (pushByReplace || pushByReplaceOnGoingBack) {
      nextRouter.replace(location, as, options);
      return;
    }
    if (sameDomain && typeof urlWithPreservedQueries === 'string') {
      const sameDomainLocation = urlString.replace(domainRegex, '/');
      nextRouter.push(sameDomainLocation, as, options);
      return;
    }
    nextRouter.push(location, as, options);
  };

  return {
    ...nextRouter,
    push,
  };
};

export default useAppRouter;
