import media from '@constants/responsive';
import theme from '@styles/theme';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

const backgroundColor = {
  YELLOW: theme.colors.colorOldLace,
  LIGHT_PINK: theme.colors.colorLinen,
  KLUBB_PINK: theme.colors.colorRemy,
};

interface SectionProps {
  $bgColor: 'LIGHT_PINK' | 'KLUBB_PINK' | 'YELLOW';
}

export const mobileSplash = '/images/splash_mobile.svg';
export const tabletSplash = '/images/splash_tablet.svg';
export const desktopSplash = '/images/splash_desktop.svg';

export const Container = styled.div<SectionProps>`
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  display: grid;
  grid-template-areas:
    'contentArea'
    'productArea';
  justify-items: initial;
  grid-template-columns: 1fr;
  min-height: ${rem(400)};
  background-color: ${({ $bgColor }) => backgroundColor[$bgColor]};

  ${media.largeMobile} {
    width: unset;
    margin: unset;
    border-radius: ${rem(32)};
  }

  ${media.tabletLandscape} {
    grid-template-areas: 'contentArea productArea';
    grid-template-columns: 42% 1fr;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: contentArea;
  padding: ${rem(32)} ${rem(24)} 0 ${rem(24)};
  gap: ${rem(16)};
  max-height: ${rem(410)};

  ${media.tabletLandscape} {
    padding: ${rem(88)} ${rem(32)} ${rem(88)} ${rem(56)};
    gap: ${rem(24)};
  }
  ${media.desktopXXL} {
    padding: ${rem(88)} ${rem(80)} ${rem(88)} ${rem(56)};
  }
`;

export const ProductWrapper = styled.div<{ $showSplash: boolean }>`
  grid-area: productArea;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: ${rem(40)} 0 ${rem(24)} 0;
  gap: ${rem(8)};

  ${media.mobile} {
    gap: ${rem(16)};
  }

  ${media.tabletLandscape} {
    padding: ${rem(32)} 0 ${rem(32)} ${rem(40)};
  }
  ${({ $showSplash }) =>
    $showSplash &&
    css`
      background-image: url(${mobileSplash});
      background-repeat: no-repeat;
      background-position: center 0;
      background-size: cover;

      ${media.largeMobile} {
        background-image: url(${tabletSplash});
        background-position: ${rem(-120)} 0;
      }
      ${media.tabletPortrait} {
        border-bottom-right-radius: ${rem(32)};
        border-bottom-left-radius: ${rem(32)};
        background-size: 120%;
      }
      ${media.tabletLandscape} {
        background-image: url(${desktopSplash});
        background-position: left ${rem(-20)};
        border-top-right-radius: ${rem(32)};
        border-bottom-left-radius: 0;
        background-repeat: no-repeat;
        background-size: 120%;
      }
      ${media.desktopXL} {
        background-position: left center;
        background-size: 140%;
      }
    `}
`;

export const ProductContainer = styled.div`
  width: ${rem(150)};
  height: 100%;
  ${media.mobile} {
    width: ${rem(168)};
  }
  ${media.tabletPortrait} {
    width: ${rem(218)};
  }
`;
