import LinkButton from '@components/atoms/Button/LinkButton';
import Icon from '@components/atoms/Icon/Icon';
import IconArrowRight from '@public/icons/regularIcons/icon-arrow-right.svg';
import { ReactNode } from 'react';
import styled from 'styled-components';

export type ArrowButtonVariant = 'primary' | 'strokeBlack' | 'secondaryPlain' | 'pink';

interface Props {
  children: ReactNode;
  variant: ArrowButtonVariant;
  url: string;
  onClick: () => void;
  target?: string;
}

const ButtonIcon = styled(Icon)<{ variant: ArrowButtonVariant }>`
  color: inherit;
`;

const ArrowLinkButton = ({ children, variant, url, onClick, target }: Props) => {
  const color = variant === 'strokeBlack' ? 'black' : 'white';

  return (
    <LinkButton variant={variant} href={url} onClick={onClick} linkTarget={target}>
      {children} <ButtonIcon variant={variant} icon={IconArrowRight} color={color} />
    </LinkButton>
  );
};

export default ArrowLinkButton;
