import { getProductsForProductBanner, getPromotedProducts } from '@api';
import Button from '@components/atoms/Button';
import Paragraph from '@components/atoms/Paragraph';
import ProductGrid from '@components/atoms/ProductGrid';
import Tooltip from '@components/atoms/Tooltip';
import Link from '@components/atoms/__DEPRECATED__/Link';
import ProductsWithTracking from '@components/organisms/ProductsWithTracking';
import LINKS from '@constants/links';
import logError from '@helpers/logHelpers';
import { getNumberOfCardsFitsInRef } from '@helpers/productHelper';
import { usePromotionStore } from '@hooks/localStorage';
import useCustomer from '@hooks/useCustomer';
import useEventListName from '@hooks/useEventListName';
import useResponsive from '@hooks/useResponsive';
import useStoreChange from '@hooks/useStoreChange';
import IconInformation from '@public/icons/regularIcons/icon-information.svg';
import axios from 'axios';
import Heading from 'components/atoms/Heading';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import {
  FINISHED_FETCHING,
  IS_FETCHING,
  RESET,
  SUCCESS,
  initialState,
  reducer,
} from './AxfoodProductBannerComponent.reducer';
import {
  BodyTextWrapper,
  HeaderTitleWrapper,
  HeaderWrapper,
  ShowMoreButtonWrapper,
} from './AxfoodProductBannerComponent.styles';

const DISPLAY_TYPES = {
  ALL_WEEKLY_OFFERS: 'ALL_WEEKLY_OFFERS',
  PERSONAL_OFFER_USE_PROMOTIONSTORE: 'PERSONAL_OFFER_USE_PROMOTIONSTORE',
  PERSONAL_OFFER: 'PERSONAL_OFFER',
};

export interface AxfoodProductBannerComponentProps {
  component: AxfoodProductBannerComponentType;
}

const fetchData = async (componentId: string, size?: string, page?: string, promotionStoreUid?: string) => {
  const source = axios.CancelToken.source();
  try {
    const data = await getProductsForProductBanner(
      { componentId, size, page, promotionStoreUid },
      { cancelToken: source.token }
    );

    return data;
  } catch (error) {
    return null;
  }
};

const AxfoodProductBannerComponent = ({ component }: AxfoodProductBannerComponentProps) => {
  const { t } = useTranslation('common');
  const { customerId } = useCustomer();
  const containerRef = useRef<HTMLDivElement>(null);
  const [hasFetchedInitialData, setHasFetchedInitialData] = useState(false);
  const [showAllInitialProducts, setShowAllInitialProducts] = useState(false);
  const [{ promotionStoreId }] = usePromotionStore();
  const [gridRef, setGridRef] = useState<HTMLDivElement | null>(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { isTabletPortraitOrGreater, isDesktopOrGreater, isClient } = useResponsive();

  const {
    title,
    numberOfRows = '2',
    numberOfRowsMobile = '',
    numberOfRowsTablet = '',
    emptyProductListTitle = '',
    emptyProductListBody = '',
    displayType,
  } = component;

  const {
    isFetching,
    data: { items = [], page = 0, totalPages = 0 },
  } = state;

  const getNumberOfItemsPerRow = useCallback(() => {
    return isTabletPortraitOrGreater ? getNumberOfCardsFitsInRef(containerRef) : 2;
  }, [isTabletPortraitOrGreater]);

  const getNumberOfRows = useCallback(() => {
    if (isDesktopOrGreater) {
      return numberOfRows;
    }
    if (isTabletPortraitOrGreater) {
      return numberOfRowsTablet || numberOfRows;
    }

    return numberOfRowsMobile || numberOfRows;
  }, [numberOfRows, numberOfRowsMobile, numberOfRowsTablet, isDesktopOrGreater, isTabletPortraitOrGreater]);

  const getNumberOfItems = useCallback(() => {
    const itemsPerRow = getNumberOfItemsPerRow();
    const rows = 5;
    return itemsPerRow * rows;
  }, [getNumberOfItemsPerRow]);

  const isPersonalOffer = component.displayType === DISPLAY_TYPES.PERSONAL_OFFER;
  const tooltipCopy = t('promotions:promotionBanner->aboutPromotionsContent');
  const personalOfferHeader =
    isPersonalOffer && isDesktopOrGreater ? title : t('promotions:promotionBanner->personalOfferMobileHeader');

  const isWeeklyOffers = displayType === DISPLAY_TYPES.ALL_WEEKLY_OFFERS;
  const isWithPromotionStoreId = displayType === DISPLAY_TYPES.PERSONAL_OFFER_USE_PROMOTIONSTORE;

  const getPaginatedData = useCallback(
    async (pageToFetch = 0) => {
      try {
        dispatch({ type: IS_FETCHING });
        const numberOfItems = getNumberOfItems();

        if (isWeeklyOffers) {
          const promotions = await getPromotedProducts(pageToFetch, numberOfItems);
          const { formattedProducts = [], pagination } = promotions;
          const { currentPage = 0, pageSize = 0, numberOfPages = 0 } = pagination || {};
          const data = {
            paginationData: {
              items: formattedProducts,
              page: currentPage,
              pageSize: pageSize,
              totalPages: numberOfPages,
            },
          };
          dispatch({ type: SUCCESS, data });
        } else {
          const response = await fetchData(
            component.uid,
            numberOfItems ? numberOfItems.toString() : undefined,
            pageToFetch ? pageToFetch.toString() : undefined,
            isWithPromotionStoreId ? promotionStoreId : undefined
          );

          if (response !== null && !!response.paginationData) {
            const data = {
              paginationData: {
                items: response.formattedProducts,
                page: response.paginationData.page,
                pageSize: response.paginationData.pageSize,
                totalPages: response.paginationData.totalPages,
              },
            };
            dispatch({ type: SUCCESS, data });
          }
        }
      } catch (e) {
        logError('Failed to load more AxfoodProductBannerComponent products.', e);
      } finally {
        setHasFetchedInitialData(true);
        dispatch({ type: FINISHED_FETCHING });
      }
    },
    [component.uid, getNumberOfItems]
  );

  useStoreChange(() => getPaginatedData(0));

  useEffect(() => {
    if (isClient && !hasFetchedInitialData) {
      getPaginatedData(0);
    }
  }, [isClient, hasFetchedInitialData, getPaginatedData]);

  // if the user logs in or resizes the window after fetching initial data
  // reset the data, since the paginated data will be fetched in different
  // page sizes
  useEffect(() => {
    dispatch({ type: RESET });
    setHasFetchedInitialData(false);
    setShowAllInitialProducts(isWeeklyOffers);
  }, [isDesktopOrGreater, isTabletPortraitOrGreater, customerId]);

  const loadMore = useCallback(() => {
    getPaginatedData(page + 1);
  }, [getPaginatedData, page]);

  // first time show more button is clicked no more items will be fetched
  // just show the entire initial array of items
  const handleClickShowMore = () => (showAllInitialProducts ? loadMore() : setShowAllInitialProducts(true));

  const handleRef = (ref: HTMLDivElement) => setGridRef(ref);
  const { EVENT_LIST_NAME } = useEventListName(title);

  const hasProducts = items && items?.length > 0;
  const hasMorePages = typeof page === 'number' && page + 1 < totalPages;

  if (hasFetchedInitialData && !hasProducts) {
    if (emptyProductListTitle && emptyProductListBody) {
      return (
        <>
          <HeaderWrapper>
            <Heading size="small" type="h2">
              {emptyProductListTitle}
            </Heading>
          </HeaderWrapper>
          <BodyTextWrapper>
            <Paragraph>{emptyProductListBody}</Paragraph>
          </BodyTextWrapper>
        </>
      );
    }
    return null;
  }

  const endIndex = getNumberOfItemsPerRow() * parseInt(getNumberOfRows(), 10);
  const slicedItems = items?.slice(0, endIndex);
  return (
    <div ref={containerRef}>
      <HeaderWrapper>
        <HeaderTitleWrapper>
          <Heading size="small" type="h2">
            {isPersonalOffer ? personalOfferHeader : title}
          </Heading>
        </HeaderTitleWrapper>
        {isPersonalOffer && (
          <Tooltip
            iconName={IconInformation}
            toolTipText={t('promotions:promotionBanner->aboutPromotions')}
            offset={[-90, 0]}
          >
            <Paragraph size="medium">
              {tooltipCopy}
              <Link href={LINKS.PERSONALIZATION}>{t('common:readMore->text')}</Link>
            </Paragraph>
          </Tooltip>
        )}
      </HeaderWrapper>
      <ProductGrid passRef={handleRef}>
        <ProductsWithTracking
          products={(showAllInitialProducts ? items : slicedItems) || []}
          eventListName={EVENT_LIST_NAME}
          containerRef={gridRef}
          isLoading={!hasFetchedInitialData}
        />
      </ProductGrid>
      {(hasMorePages || !!(!showAllInitialProducts && items.length > endIndex)) && (
        <ShowMoreButtonWrapper>
          <Button onClick={handleClickShowMore} isLoading={isFetching} variant="stroke">
            {t('showMore')}
          </Button>
        </ShowMoreButtonWrapper>
      )}
    </div>
  );
};

export default AxfoodProductBannerComponent;
