import { keyframes, css } from 'styled-components';

const offset = 187;
const duration = '1.4s';
const fastDuration = '0.3s';

const rotator = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
`;

const dash = keyframes`
  0%{
    stroke-dashoffset: ${offset};
  }
  50%{
    stroke-dashoffset: ${offset / 3};
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: ${offset};
    transform: rotate(450deg);
  }
`;

const rotatorDown90 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
`;

const rotatorUp90 = keyframes`
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

export const rotatorUp90Animation = css`
  animation: ${rotatorUp90} ${fastDuration} ease-in-out forwards;
`;
export const rotatorDown90Animation = css`
  animation: ${rotatorDown90} ${fastDuration} ease-in-out forwards;
`;

export const rotatorAnimation = css`
  animation: ${rotator} ${duration} linear infinite;
`;

export const dashAnimation = css`
  stroke-dasharray: ${offset};
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${dash} ${duration} ease-in-out infinite;
`;

const animations = {
  rotatorUp90Animation,
  rotatorDown90Animation,
  rotatorAnimation,
  dashAnimation,
};

export type ThemeAnimations = typeof animations;
export default animations;
