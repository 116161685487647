import { useRef, useEffect, useState } from 'react';

function useOnHover() {
  const [value, setValue] = useState(false);

  const ref = useRef<HTMLInputElement>(null);

  const handleMouseEnter = (event: any) => {
    setValue(true);
  };

  const handleMouseLeave = (event: any) => {
    setValue(false);
  };

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener('mouseenter', handleMouseEnter);
      ref.current.addEventListener('mouseleave', handleMouseLeave);
    }
  });

  return [ref, value];
}

export default useOnHover;
