import NOTIFICATIONS from '@constants/notifications';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import getState from './getState';

export const selectNotifications = createSelector(getState, (state: RootState) => state.notifications || []);
export const selectShowVoucherNotification = createSelector(selectNotifications, (state) => {
  const notification: NotificationType = state[NOTIFICATIONS.VOUCHER_NOTIFICATION];
  return notification?.show && notification.maxShowings >= notification.timesShown;
});
export const selectShowLogInNotification = createSelector(selectNotifications, (state) => {
  const notification: NotificationType = state[NOTIFICATIONS.LOYALTY_LOGIN_NOTIFICATION];
  return notification?.show && notification.maxShowings >= notification.timesShown;
});
