import SanitizedHtml from '@components/atoms/SanitizedHtml';
import { StyledCmsHtml } from '../StyledCmsHtml.styles';
import { Caption, Content, MediaContainer, Wrapper } from './AxfoodResponsiveImageAndParagraphComponent.styles';
import ArrowLinkButton from '@components/molecules/ArrowLinkButton';

interface Props {
  component: AxfoodResponsiveImageAndParagraphComponent;
}
const AxfoodResponsiveImageAndParagraphComponent = ({
  component: {
    media,
    bodyContent,
    imageMargin = '50',
    imageAlignment = 'LEFT',
    showButton,
    buttonText,
    buttonColor,
    imageCaption,
    link,
  },
}: Props) => {
  if (!bodyContent) return null;

  const buttonVariant = buttonColor == 'pink' ? buttonColor : 'primary';
  const gap = parseInt(imageMargin, 10);

  return (
    <Wrapper $gap={gap} $alignment={imageAlignment}>
      <MediaContainer $alignment={imageAlignment} $media={media} data-testid="media-container">
        {!!imageCaption && <Caption>{imageCaption}</Caption>}
      </MediaContainer>
      <Content $alignment={imageAlignment}>
        <StyledCmsHtml>
          <SanitizedHtml content={bodyContent} />
        </StyledCmsHtml>

        {showButton === 'true' && !!link && (
          <ArrowLinkButton url={link} variant={buttonVariant} onClick={() => {}}>
            {buttonText}
          </ArrowLinkButton>
        )}
      </Content>
    </Wrapper>
  );
};
export default AxfoodResponsiveImageAndParagraphComponent;
