import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div<{ $fullWidth: boolean }>`
  width: 100%;
  max-width: ${({ $fullWidth }) => !$fullWidth && rem(360)};
  background-color: ${({ theme }) => theme.colors.colorCararra};
  border-radius: ${rem(15)};
  text-align: center;
  padding: ${rem(20)};
`;

export const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TextRow = styled.div`
  display: flex;
  margin: ${rem(3)} 0 ${rem(15)} 0;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const IconRow = styled.div`
  display: flex;
  justify-content: center;
  margin: ${rem(3)} 0;
`;
