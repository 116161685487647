import FooterLink from '@components/cmsComponents/AxfoodFooterComponent/FooterLink';
import FooterHeading from '@components/molecules/FooterHeading';
import AppStore from '@public/images/app-store.svg';
import GooglePlay from '@public/images/google-play.svg';
import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';
import { HEMKOP_APPLE_APPSTORE, HEMKOP_GOOGLE_PLAYSTORE } from '../externalLinks';
import { AppLink, AppLinks } from './FooterAppLinks.styles';

const FooterAppLinks = () => {
  const { t } = useTranslation('footer');

  return (
    <div>
      <FooterHeading text={t('footer->headers->downloadOurAppAt')} />
      <AppLinks>
        <AppLink>
          <FooterLink linkId={HEMKOP_APPLE_APPSTORE} aria-label={t('footer->ariaLabels->appStore')}>
            <AppStore width={118} height={42} />
          </FooterLink>
        </AppLink>
        <AppLink>
          <FooterLink linkId={HEMKOP_GOOGLE_PLAYSTORE} aria-label={t('footer->ariaLabels->googlePlay')}>
            <GooglePlay width={118} height={42} />
          </FooterLink>
        </AppLink>
      </AppLinks>
    </div>
  );
};

export default memo(FooterAppLinks);
