const SHOW_CONFLICTING_MODAL = 'SHOW_CONFLICTING_MODAL';
const SET_ITEMS_CONFLICTING_MODAL = 'SET_ITEMS_CONFLICTING_MODAL';
const RESOLVE_CONFLICTING_MODAL = 'RESOLVE_CONFLICTING_MODAL';
const REJECT_CONFLICTING_MODAL = 'REJECT_CONFLICTING_MODAL';
const RESET_STATE_CONFLICTING_MODAL = 'RESET_STATE_CONFLICTING_MODAL';
const SET_CONLFICT_REASON_TRACKER = 'SET_CONLFICT_REASON_TRACKER';
const SET_CONFLICTING_MODAL_STORE_ID = 'SET_CONFLICTING_MODAL_STORE_ID';

export {
  SHOW_CONFLICTING_MODAL,
  REJECT_CONFLICTING_MODAL,
  SET_ITEMS_CONFLICTING_MODAL,
  RESOLVE_CONFLICTING_MODAL,
  RESET_STATE_CONFLICTING_MODAL,
  SET_CONLFICT_REASON_TRACKER,
  SET_CONFLICTING_MODAL_STORE_ID,
};
