import useBufferedAnalyticsEvent from '@hooks/useBufferedAnalyticsEvent/useBufferedAnalyticsEvent';
import { productTracker } from '@trackers';
import { Props } from './ProductsWithTracking';

type TrackedProductsParams = Pick<Props, 'extendedEventListName' | 'eventListName' | 'products'>;

const useTrackedProducts = (productsWithTrackingProps: TrackedProductsParams) => {
  const { extendedEventListName, eventListName, products } = productsWithTrackingProps;
  const currentEventListName = extendedEventListName ? `${eventListName}${extendedEventListName}` : eventListName;
  const track = (impressionProducts: ImpressionProduct[]) =>
    productTracker.trackProductImpressionsWithPosition(impressionProducts, currentEventListName);
  const { debouncedTrack, queueTrackingOfItem, trackableRefs } = useBufferedAnalyticsEvent(products, track);

  const onProductShown = (product: ProductVariantType, pos: number) => {
    const position = (product as CarouselProductType).positionInList || pos;
    queueTrackingOfItem({ product, position });
    debouncedTrack();
  };

  return { track, onProductShown, trackableRefs };
};

export default useTrackedProducts;
