import media from '@constants/responsive';
import { CmsPanel } from '@styles/cms';
import { rem } from 'polished';
import styled from 'styled-components';
import { ColumnPanel } from './HeroPanel/HeroPanel.styles';

export const Container = styled(CmsPanel)`
  height: ${rem(400)};

  ${media.desktopXXL} {
    height: ${rem(512)};
  }
`;

export const NoPanel = styled(ColumnPanel)`
  position: relative;
  padding: ${rem(24)};

  ${media.tabletPortrait} {
    width: 40%;
    min-width: ${rem(428)};
    padding: ${rem(32)};
  }
`;
