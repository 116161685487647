import Link from '@components/atoms/__DEPRECATED__/Link';
import { getExternalLink, getTrackerLinkName } from '@components/cmsComponents/AxfoodFooterComponent/externalLinks';
import { navigationClickTracker } from '@trackers';
import { ReactElement } from 'react';

const FooterLink = ({
  linkId,
  children,
  'aria-label': ariaLabel,
}: {
  linkId: string;
  children: ReactElement;
  'aria-label'?: string;
}) => (
  <Link
    href={getExternalLink(linkId)}
    block
    external
    target="_blank"
    margin={false}
    onClick={() => navigationClickTracker.linkClickTracker('footer', getTrackerLinkName(linkId))}
    aria-label={ariaLabel}
  >
    {children}
  </Link>
);

export default FooterLink;
