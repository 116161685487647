import Icon from '@components/atoms/Icon';
import Link from '@components/atoms/Link';
import Paragraph from '@components/atoms/Paragraph';
import LINKS from '@constants/links';
import IconBuyRecipe from '@public/icons/colorIcons/icon-buy-recipe.svg';
import Heading from 'components/atoms/Heading';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import {
  BannerDescription,
  BannerLink,
  BannerListWrapper,
  BannerTextWrapper,
  BannerTextWrapperInner,
  BannerThemeTextWrapper,
  BannerTitle,
  ItemImage,
  ItemImageSmall,
  RecipieBadgeWrapper,
} from './CmsBannerListItem.styles';

export interface CmsBannerListItemProps {
  item: ListBannerLinkComponentType;
  trackingFn: (bannerListItemType: string, bannerListItemLabel: string) => void;
}

const retrieveUrl = (linkData?: AxfoodCMSContentLinkComponent) => {
  if (linkData?.recipeLink) return LINKS.RECIPE + linkData?.recipeLink;
  if (linkData?.url) return linkData?.url;
  return '';
};

const ThemeBannerItem = ({ item, trackingFn }: CmsBannerListItemProps) => (
  <BannerListWrapper data-testid="cms-banner-item">
    <Link
      href={retrieveUrl(item.link)}
      onClick={() => trackingFn(item.showBuyRecipeSplash, `${item.bannerTitle} ${item.bannerDescription}`)}
      data-testid="banner-list-link"
    >
      <ItemImage $imgUrl={item.bannerImage?.url as string} />
      <BannerThemeTextWrapper>
        <BannerTextWrapperInner $themeBanner>
          <BannerTitle $themeBanner>
            <Paragraph color="white">{item.bannerTitle}</Paragraph>
          </BannerTitle>
          <BannerDescription>
            <Heading type="h3" color="white">
              {item.bannerDescription}
            </Heading>
          </BannerDescription>
        </BannerTextWrapperInner>
      </BannerThemeTextWrapper>
    </Link>
  </BannerListWrapper>
);

const RecipeBannerItem = ({ item, trackingFn }: CmsBannerListItemProps) => {
  const { t } = useTranslation('listbannercomponent');

  return (
    <BannerListWrapper data-testid="cms-banner-item">
      <Link
        href={retrieveUrl(item.link)}
        key={item.uid}
        onClick={() => trackingFn(item.showBuyRecipeSplash, item.bannerTitle)}
        data-testid="banner-list-link"
        underlined={false}
      >
        {item.showBuyRecipeSplash === 'true' && (
          <RecipieBadgeWrapper data-testid="recipe-splash-icon">
            <Icon size={64} icon={IconBuyRecipe} data-testid="icon-buy-recipe" />
          </RecipieBadgeWrapper>
        )}
        <ItemImageSmall $imgUrl={item.bannerImage?.url as string} />
        <BannerTextWrapper>
          <BannerTextWrapperInner>
            <BannerTitle>{item.bannerTitle}</BannerTitle>
            <BannerLink>
              <Paragraph color="black">{t('listbannercomponent->readmore')}</Paragraph>
            </BannerLink>
          </BannerTextWrapperInner>
        </BannerTextWrapper>
      </Link>
    </BannerListWrapper>
  );
};

const CmsBannerListItem = ({ item, trackingFn }: CmsBannerListItemProps) => {
  if (!item.bannerImage) return null;
  if (item.themePage === 'true') {
    return <ThemeBannerItem item={item} trackingFn={trackingFn} key={item.uid} />;
  }
  return <RecipeBannerItem item={item} trackingFn={trackingFn} key={item.uid} />;
};

// eslint-disable-next-line fp/no-mutation
CmsBannerListItem.displayName = 'CmsBannerListItem';

export default React.memo(CmsBannerListItem);
