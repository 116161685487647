import Skeleton from '@components/atoms/Skeleton';
import React, { memo } from 'react';

const AxfoodPressReleaseListComponentSkeleton = () => (
  <>
    {Array.from(Array(40), (e, i) => {
      return <Skeleton key={i} type="rect" $style={{ margin: '0' }} data-testid="skeleton" height="100%" />;
    })}
  </>
);

export default memo(AxfoodPressReleaseListComponentSkeleton);
