import Skeleton from '@components/atoms/Skeleton';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const ScrollableList = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  list-style-type: none;
  gap: ${rem(8)};
  text-transform: capitalize;
  padding: 0;
  margin: 0;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
  }

  ${media.tabletPortrait} {
    flex-shrink: 0;
    flex-wrap: wrap;
    overflow-x: initial;
  }
`;

export const CategoryList = styled.ul`
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  list-style-type: none;
  gap: ${rem(8)};
  text-transform: capitalize;
  padding: 0;
  margin: 0;
`;

interface CategoryListItemProps {
  $hideOnMobile?: boolean;
  $hideOnTablet?: boolean;
}
export const CategoryListItem = styled.li<CategoryListItemProps>`
  white-space: nowrap;
  display: ${({ $hideOnMobile }) => ($hideOnMobile ? 'none' : 'block')};

  ${media.tabletPortrait} {
    display: ${({ $hideOnTablet }) => ($hideOnTablet ? 'none' : 'block')};
  }
`;

export const CategorySkeleton = styled(Skeleton)`
  margin: 0;
  border-radius: ${rem(16)};
`;

export const ShowOnMobileListItem = styled.li`
  display: block;

  ${media.tabletPortrait} {
    display: none;
  }
`;
