import { SectionWrapper } from '@components/atoms/__DEPRECATED__/AccordionSection/AccordionSection.styles';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

interface FAQAccordionProps {
  $active?: boolean;
  $childHeight?: number;
}

interface IconWrapperProps {
  $active: boolean;
  $isClosed: boolean;
}

const Padding = css`
  padding-left: ${rem(12)};
  padding-right: ${rem(12)};
  ${media.tabletPortrait} {
    padding-left: ${rem(85)};
    padding-right: ${rem(85)};
  }
  ${media.desktop} {
    padding-left: ${rem(166)};
    padding-right: ${rem(166)};
  }
`;

interface ReactNativeProps {
  $isReactNative: boolean;
}

export const Container = styled.div<ReactNativeProps>`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  ${(props) =>
    props.$isReactNative &&
    css`
      min-height: ${rem(500)};
    `}
`;

export const HeaderSection = styled.div`
  ${Padding};
  padding-top: ${rem(30)};
  margin-bottom: ${rem(24)};
  ${media.tabletPortrait} {
    padding-top: ${rem(50)};
  }
`;

export const DropdownWrapper = styled.div`
  ${media.tabletPortrait} {
    max-width: ${rem(276)};
  }
`;

export const InfoTextWrapper = styled.div`
  margin: ${rem(16)} 0;
`;

export const AccordionHeader = styled.div`
  display: flex;
  height: ${rem(58)};
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-top: ${rem(2)} solid ${({ theme }) => theme.colors.colorDesertStorm};
`;

export const AccordionWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  ${media.tabletPortrait} {
    padding-bottom: ${rem(50)};
  }
  ${SectionWrapper} {
    &:last-child {
      ${AccordionHeader} {
        border-bottom: ${rem(2)} solid ${({ theme }) => theme.colors.colorDesertStorm};
      }
    }
  }
`;

export const AccordionHeaderWrapper = styled.div<{ $active: boolean }>`
  ${Padding};
  cursor: initial;
  background-color: ${({ $active, theme }) =>
    $active ? theme.colors.colorDesertStorm : theme.colors.colorMenuBackground};
  font-weight: ${({ $active }) => ($active ? 700 : 400)};
`;

export const AccordionContentWrapper = styled.div<FAQAccordionProps>`
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  height: 0;
  ${({ $active, $childHeight }) => $active && $childHeight && `height: ${rem($childHeight)};`}
  background-color: ${({ $active, theme }) =>
    $active ? theme.colors.colorDesertStorm : theme.colors.colorMenuBackground};
`;

export const AccordionContent = styled.div`
  height: auto;
  ${Padding};
  padding-top: ${rem(16)};
  padding-bottom: ${rem(16)};
  line-height: normal;
`;

export const IconWrapper = styled.div<IconWrapperProps>`
  ${(props) =>
    props.$isClosed &&
    css`
      svg {
        ${props.theme.animations.rotatorUp90Animation};
      }
    `}
  ${(props) =>
    props.$active &&
    css`
      svg {
        ${props.theme.animations.rotatorDown90Animation}
      }
    `}
`;
