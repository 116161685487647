import { MAX_QUANTITY_VALUE } from '@components/molecules/Quantity/Quantity';
import { roundDecimals } from './numberHelpers';

export const roundToNearestIncrement = (quantity: number, incrementValue: number) => {
  const nearestIncrement =
    quantity % incrementValue >= incrementValue / 2
      ? Math.ceil(quantity / incrementValue) * incrementValue
      : Math.floor(quantity / incrementValue) * incrementValue;
  return roundDecimals(nearestIncrement);
};

export const clampQuantity = (quantity: number, incrementValue: number, minValue: number = 0) => {
  return roundToNearestIncrement(Math.min(Math.max(quantity, minValue), MAX_QUANTITY_VALUE), incrementValue);
};
