import PromoComponent, { ImageData, PromoButtonTypes, PromoComponentData } from '@components/molecules/PromoComponent';
import CenteredPromoComponent from '@components/molecules/PromoComponent/CenteredPromoComponent';
import { Color, HeadingSize } from '@components/organisms/ContentComponent';
import { trackPromoBannerView } from '@features/promotions/tracking';
import useTrackComponentShown from '@hooks/useTrackComponentShowed';
import { useCallback } from 'react';
import { NarrowContainer, QueryContainer, WideContainer } from './AxfoodPromoBannerComponent.styles';

const componentHeaderSize: { [key: string]: HeadingSize } = {
  MEDIUM: 'medium',
  LARGE: 'large',
  XLARGE: 'xLarge',
};

const componentTextColor: { [key: string]: Color } = {
  WHITE: 'white',
  BLACK: 'black',
};

const componentButtonType: { [key: string]: PromoButtonTypes } = {
  WHITE: 'white',
  TRANSPARENT: 'transparent',
};

const componentBackgroundColor = {
  CLUB_PINK: 'clubPink',
  LIGHT_PINK: 'lightPink',
  LIGHT_YELLOW: 'lightYellow',
  LIGHT_ORANGE: 'lightOrange',
};

interface Media {
  url?: string;
  altText?: string;
}

const mapImage = (media: Media): ImageData => {
  return {
    url: media.url as string,
    altText: media.altText,
  };
};

export const mapFromCms = (cmsData: AxfoodPromoBannerComponentData, slotPosition?: number): PromoComponentData => {
  return {
    textColor: cmsData.headerColor && componentTextColor[cmsData.headerColor],
    header: { text: cmsData.header, size: cmsData.headerSize && componentHeaderSize[cmsData.headerSize] },
    body: { text: cmsData.preamble },
    button: {
      text: cmsData.buttonText,
      color: cmsData.buttonColor && componentButtonType[cmsData.buttonColor],
      link: cmsData.urlLink,
    },
    background: {
      color: cmsData.backgroundColor && componentBackgroundColor[cmsData.backgroundColor],
      image: {
        mobile: !!cmsData.image?.mobile?.url ? mapImage(cmsData.image.mobile) : undefined,
        desktop: !!cmsData.image?.desktop?.url ? mapImage(cmsData.image.desktop) : undefined,
      },
    },
    icon: undefined, // TODO map when format is decided. cmsData.icon,
    tracking: {
      shouldTrackClick: !!cmsData.urlLink,
      link: cmsData.urlLink || '',
      name: cmsData.buttonText || '',
      slotPosition: (slotPosition && (slotPosition + 1).toString()) || '1',
    },
  };
};

export interface AxfoodPromoBannerComponentProps {
  component: AxfoodPromoBannerComponentData;
  slotPosition?: number;
}

const AxfoodPromoBannerComponent = ({ component, slotPosition }: AxfoodPromoBannerComponentProps) => {
  const data = mapFromCms(component, slotPosition);

  const trackVisible = useCallback(() => {
    trackPromoBannerView(data.tracking.link, data.tracking.name, data.tracking.slotPosition, 'promo_banner');
  }, [data.tracking.link, data.tracking.name, data.tracking.slotPosition]);

  const { componentRef } = useTrackComponentShown(trackVisible);

  const compact = !(data.header.text || data.body.text);

  return (
    <QueryContainer ref={componentRef} $compact={compact}>
      <NarrowContainer>
        <CenteredPromoComponent data={data} />
      </NarrowContainer>
      <WideContainer>
        <PromoComponent data={data} />
      </WideContainer>
    </QueryContainer>
  );
};

export default AxfoodPromoBannerComponent;
