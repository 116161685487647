import Paragraph from '@components/atoms/Paragraph';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import ProductPrice, { mapProductPriceProps } from '@components/molecules/ProductPrice';
import PRODUCT_LABELS from '@constants/productLabels';
import useTranslation from 'next-translate/useTranslation';
import ProductTitle from '../ProductTitle';
import PromotionLabel from '../PromotionLabel';
import {
  Bottom,
  Discount,
  ProductComparePrices,
  ProductInformationGrid,
  RedeemLimit,
  Wrapper,
} from './ProductInformation.styles';

interface ProductInformationProps {
  product: Product;
  url: UrlType;
  as: UrlType;
  priceUnit: string;
  disableLinks?: boolean;
  handleProductLinkClick?: () => void;
  linkAriaLabel: string;
}

const ProductInformation = ({
  product,
  url,
  as,
  disableLinks,
  handleProductLinkClick,
  linkAriaLabel,
}: ProductInformationProps) => {
  const { t } = useTranslation('product');

  const {
    promotion,
    hasPromotion,
    offline,
    isDrugProduct,
    nicotineMedicalProduct,
    online,
    price,
    labels,
    isBargainProduct,
  } = product;

  const {
    isLoyalty,
    qualifyingCount = 0,
    isAlwaysGoodPrice = false,
    textLabel,
    textLabelGenerated,
    isSingelProductAlwaysGoodPricePromotionProduct,
    showOrdinaryPrice,
    isPromotionComparePriceSame,
    promotionComparePrice,
    lowestHistoricalPrice,
    redeemLimitLabel,
    endDate,
  } = promotion || {};

  const shouldUseTextLabelGenerated = hasPromotion && isLoyalty && qualifyingCount > 1;
  // what label that is used differs between product card and product detail
  const promotionLabel = hasPromotion && shouldUseTextLabelGenerated ? textLabelGenerated : textLabel;
  const promotionComparePriceColor = hasPromotion && offline ? 'black' : 'primary';

  const showIsDrugProduct = isDrugProduct || nicotineMedicalProduct;

  const hasOrdinaryPrice = showOrdinaryPrice && !isSingelProductAlwaysGoodPricePromotionProduct;

  const showPromotionComparePrice =
    hasPromotion &&
    !isPromotionComparePriceSame &&
    !isSingelProductAlwaysGoodPricePromotionProduct &&
    promotionComparePrice?.value;

  const hasComparePriceValues = !!price.comparePrice.value && !!price.comparePrice.unit;
  const showProductComparePrice =
    !!price?.comparePrice?.value && hasComparePriceValues && (!hasPromotion || isAlwaysGoodPrice);

  return (
    <ProductInformationGrid>
      <PromotionLabel
        isAlwaysGoodPrice={isAlwaysGoodPrice}
        promotionLabel={promotionLabel}
        hasPromotion={hasPromotion}
        showIsDrugProduct={showIsDrugProduct}
        showLocalBargain={isBargainProduct}
      />

      <Wrapper>
        <ProductTitle
          href={online && !disableLinks ? url : undefined}
          as={as}
          handleProductLinkClick={handleProductLinkClick}
          product={product}
          linkAriaLabel={linkAriaLabel}
        />

        <Bottom>
          <ProductComparePrices>
            {!!lowestHistoricalPrice ? (
              <Paragraph size="xsmall">{`${t('product:price->lowestHistorical')} ${
                lowestHistoricalPrice.valueWithUnit
              }`}</Paragraph>
            ) : (
              hasOrdinaryPrice && (
                <Paragraph size="xsmall" data-testid="ordinary-price">
                  {`${t('product:price->ordinary')} ${price.formattedPrice.valueWithUnit}`}
                </Paragraph>
              )
            )}
            {showProductComparePrice && (
              <Paragraph size="xsmall" data-testid="compare-price">
                {`${t('product:price->compare')} ${price.comparePrice.valueWithUnit}`}
                {!!price.depositPrice && <span>{t('product:price->deposit')}</span>}
              </Paragraph>
            )}
            {showPromotionComparePrice && (
              <Discount>
                <Paragraph size="xsmall" data-testid="promotion-compare-price" color={promotionComparePriceColor}>
                  <>
                    {`${t('product:price->compare')} ${promotionComparePrice.valueWithUnit}`}
                    {!isAlwaysGoodPrice && !!price.depositPrice && <span>{t('product:price->deposit')}</span>}
                  </>
                </Paragraph>
              </Discount>
            )}
            {hasPromotion && endDate && (
              <Paragraph size="xsmall" data-testid="end-date">
                {`${t('product:availableTill')} ${endDate}`}
              </Paragraph>
            )}
            {labels?.includes(PRODUCT_LABELS['age_restricted_18']) && (
              <Paragraph size="xsmall">{t('product:AgeRestriction18')}</Paragraph>
            )}
          </ProductComparePrices>
        </Bottom>
      </Wrapper>
      <ProductPrice product={mapProductPriceProps(product)} position="left" />
      {!!redeemLimitLabel && (
        <RedeemLimit>
          <Paragraph size="xsmall" data-testid="redeem-limit" weight="bold">
            {redeemLimitLabel}
          </Paragraph>
        </RedeemLimit>
      )}
    </ProductInformationGrid>
  );
};

export default ProductInformation;
