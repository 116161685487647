import IconCheckbox from '@public/icons/regularIcons/icon-checkbox.svg';
import { ChangeEvent, ForwardedRef, forwardRef, ReactNode } from 'react';
import Icon from '../Icon';
import { CheckBoxContainer, CheckBoxLabel, CheckBoxStyled, HiddenCheckbox } from './CheckBox.styles';

export interface CheckboxProps {
  label?: string | ReactNode;
  onChange?: (checked: boolean) => void;
  checked: boolean;
  margin?: 'top' | 'right' | 'bottom' | 'left';
  disabled?: boolean;
  'data-testid'?: string;
  alignLabel?: 'center' | 'top';
  'aria-label'?: string;
}

const CheckBox = forwardRef(
  (
    {
      checked,
      onChange,
      label,
      margin,
      disabled = false,
      alignLabel = 'center',
      'data-testid': dataTestId = 'checkbox',
      'aria-label': ariaLabel,
    }: CheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
      !disabled && onChange && onChange(event.target.checked);
    };

    return (
      <label>
        <CheckBoxContainer
          $margin={margin}
          $alignLabel={alignLabel}
          $disabled={disabled}
          data-testid="checkbox-container"
        >
          <HiddenCheckbox
            checked={checked}
            onChange={handleCheckboxChange}
            data-testid="hidden-checkbox"
            disabled={disabled}
            aria-label={ariaLabel}
            ref={ref}
          />
          <CheckBoxStyled data-testid={dataTestId} $checked={checked}>
            {checked && <Icon icon={IconCheckbox} size={12} color="white" data-testid="icon-check" />}
          </CheckBoxStyled>
          {label && <CheckBoxLabel data-testid="checkbox-label">{label}</CheckBoxLabel>}
        </CheckBoxContainer>
      </label>
    );
  }
);
CheckBox.displayName = 'CheckBox';

export default CheckBox;
