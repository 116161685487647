import { FacetDataOfSearchStateData, FacetValueDataOfSearchStateData } from '@api/generated/storefront';
import FACET_CODES from '@constants/productListFilters';
import { Translate } from 'next-translate';

export const getPromotionsAndLabels = (facets: FacetDataOfSearchStateData[], t: Translate) => {
  const zeroOrOnePromotionFacet = facets.filter((f) => f.code === FACET_CODES.PROMOTION && f.visible);
  const zeroOrOneLabelsFacet = facets.filter((f) => f.code === FACET_CODES.LABELS && f.visible);

  const flatPromotions = zeroOrOnePromotionFacet
    .filter((f) => Array.isArray(f.values) && f.values.length > 0)
    .flatMap((promotion, index) => ({
      ...(Array.isArray(promotion.values) && promotion.values[index]),
      name: t('offersLabel'),
    }));

  const flatLabels = zeroOrOneLabelsFacet
    .filter((label) => Array.isArray(label.values))
    .flatMap((label) => label.values as FacetValueDataOfSearchStateData[]);

  return [...flatPromotions, ...flatLabels];
};

export const getBrands = (facets: FacetDataOfSearchStateData[]) => {
  const brands = facets.filter((f) => f.code === FACET_CODES.BRANDS && f.visible);

  return brands
    .filter((item) => Array.isArray(item.values))
    .flatMap((item) => item.values as FacetValueDataOfSearchStateData[]);
};
