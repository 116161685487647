import HeroContentComponent from '@components/molecules/HeroComponent/HeroContentComponent';
import { ContentComponentData } from '@components/organisms/ContentComponent';
import Image from 'next/image';
import { ImageWrapper, PanelWrapper } from './HeroPanel.styles';

export interface HeroPanelData extends ContentComponentData {
  mobileImageUrl: string;
  mobileImageAltText?: string;
}

const HeroPanel = ({ data, isMobile }: { data: HeroPanelData; isMobile: boolean }) => (
  <>
    {isMobile && data.mobileImageUrl && (
      <ImageWrapper>
        <Image
          src={data.mobileImageUrl}
          alt={data.mobileImageAltText || 'Hero image'}
          style={{ objectFit: 'cover', objectPosition: 'top' }}
          fill
        />
      </ImageWrapper>
    )}
    <PanelWrapper $isMobile={isMobile}>
      <HeroContentComponent data={data} color="black" isMobile={isMobile} />
    </PanelWrapper>
  </>
);

export default HeroPanel;
