import HeaderButton from '@components/atoms/HeaderButton';
import { rem } from 'polished';
import styled from 'styled-components';

export const AccordionContainer = styled.div`
  width: 100%;
`;

export const AccordionItemBox = styled.div`
  border-bottom: ${({ theme }) => theme.colors.colorStack} ${rem(1)} solid;
  padding: 0 ${rem(16)};
`;

export const TitleBox = styled.div`
  font-size: ${rem(16)};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
`;

export const ItemList = styled.ul`
  list-style: none;
  margin: 0;
  padding: ${rem(8)} 0;
  background-color: ${({ theme }) => theme.colors.colorWhite};
`;

export const ItemBox = styled.li`
  width: 100%;
`;

export const MenuItemButton = styled(HeaderButton)`
  width: 100%;
  height: ${rem(32)};
  padding: 0;
  margin: 0;
  justify-content: start;
`;

export const ItemTextBox = styled.div`
  padding-left: ${rem(32)};
  font-size: ${rem(14)};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.normal};
`;
