import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';


export const DeliveryInformationWrapper = styled.div`
  padding: ${rem(16)};
  z-index: 100;
  background:  ${({ theme}) => theme.colors.colorWhite};
  width: 100%;
  ${media.desktop} {
    padding: ${rem(24)};
    width: ${rem(517)};
    border-radius: ${rem(24)};
  }
`;

export const SlotDeliveryInformation = styled.div`
border-bottom: ${rem(2)} dotted ${({ theme}) => theme.colors.colorStack};
border-width: ${rem(2)};
padding-bottom: ${rem(24)};
display: flex;
justify-content: space-between;
align-items: center;
margin-top: ${rem(4)};
`;

export const InformationWrapper = styled.div`

`;
export const InformationHeader = styled.div`
  display: flex;
  align-items: center;
`;
export const IconWrapper = styled.div`
  margin-right: ${rem(4)};
  width: ${rem(18)};
  ${media.desktop} {
    width: ${rem(24)};
  }
`;

export const ButtonWrapper = styled.div`
  align-self: end;
`;

export const ReservationInformation = styled.div`
  text-align: center;
  margin-top: ${rem(16)};
`;
