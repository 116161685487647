import { ProductViewModel } from '@components/molecules/__DEPRECATED__/Product/Product';
import CAMPAIGN_TYPE from '@constants/campaignType';
import PROMOTION_THEME from '@constants/promotion';
import IconPricebomb from '@public/icons/colorIcons/icon-pricebomb.svg';
import IconSplashOrange from '@public/icons/colorIcons/icon-splash-orange.svg';
import IconSplashRed from '@public/icons/colorIcons/icon-splash-red.svg';
import IconSplashYellow from '@public/icons/colorIcons/icon-splash-yellow.svg';
import useTranslation from 'next-translate/useTranslation';
import {
  PriceBombPromotionSplashWrapper,
  PromotionPriceBombIconWrapper,
  PromotionSplashIconWrapper,
  PromotionSplashWrapper,
  StyledIcon,
} from './PromotionSplash.styles';
import PromotionSplashPrice from './PromotionSplashPrice';

interface Props<Type> {
  product: Type;
  variant: 'card' | 'detail' | 'horizontal';
  forceReverseIcon?: boolean;
}

const PromotionSplash = <Type extends ProductViewModel>({
  product,
  variant = 'card',
  forceReverseIcon = false,
}: Props<Type>) => {
  const { t } = useTranslation('product');

  const { campaignType, promotionTheme: { code } = {} } = product.potentialPromotions[0];
  const hasLoyaltyPromotion = campaignType === CAMPAIGN_TYPE.LOYALTY;
  const hasSegmentedPromotion = campaignType === CAMPAIGN_TYPE.SEGMENTED;
  const isPriceBomb = !hasLoyaltyPromotion && !hasSegmentedPromotion && code === PROMOTION_THEME.PRICE_BOMB;
  const isAlwaysGoodPriceTheme =
    !hasLoyaltyPromotion && !hasSegmentedPromotion && code === PROMOTION_THEME.ALWAYS_GOOD_PRICE;

  const iconSize = variant === 'horizontal' ? 60 : 88;

  if (isPriceBomb) {
    return (
      <PriceBombPromotionSplashWrapper $variant={variant} title={t('product:promotionSplash->general')}>
        <PromotionPriceBombIconWrapper>
          <StyledIcon size={108} color="red" icon={IconPricebomb} data-testid="pricebomb-splash" />
        </PromotionPriceBombIconWrapper>
        <PromotionSplashPrice product={product} />
      </PriceBombPromotionSplashWrapper>
    );
  }

  if (isAlwaysGoodPriceTheme) {
    return (
      <PromotionSplashWrapper $variant={variant} title={t('product:promotionSplash->always_good_price')}>
        <PromotionSplashIconWrapper $variant={variant} $forceReverseIcon={forceReverseIcon}>
          <StyledIcon size={iconSize} icon={IconSplashOrange} color="orange" data-testid="splash-orange" />
        </PromotionSplashIconWrapper>
        <PromotionSplashPrice product={product} />
      </PromotionSplashWrapper>
    );
  }

  if (hasSegmentedPromotion) {
    return (
      <PromotionSplashWrapper
        $variant={variant}
        title={t('product:promotionSplash->segmented')}
        $redText={hasSegmentedPromotion}
      >
        <PromotionSplashIconWrapper $variant={variant} $forceReverseIcon={forceReverseIcon}>
          <StyledIcon size={iconSize} icon={IconSplashYellow} color="yellow" data-testid="splash-yellow" />
        </PromotionSplashIconWrapper>
        <PromotionSplashPrice product={product} />
      </PromotionSplashWrapper>
    );
  }

  if (hasLoyaltyPromotion) {
    return (
      <PromotionSplashWrapper
        $variant={variant}
        title={t('product:promotionSplash->loyalty')}
        $redText={hasLoyaltyPromotion}
      >
        <PromotionSplashIconWrapper $variant={variant} $forceReverseIcon={forceReverseIcon}>
          <StyledIcon size={iconSize} icon={IconSplashYellow} color="yellow" data-testid="splash-yellow" />
        </PromotionSplashIconWrapper>
        <PromotionSplashPrice product={product} />
      </PromotionSplashWrapper>
    );
  }

  return (
    <PromotionSplashWrapper $variant={variant} title={t('product:promotionSplash->general')}>
      <PromotionSplashIconWrapper $variant={variant} $forceReverseIcon={forceReverseIcon}>
        <StyledIcon size={iconSize} icon={IconSplashRed} color="red" data-testid="splash-red" />
      </PromotionSplashIconWrapper>
      <PromotionSplashPrice product={product} />
    </PromotionSplashWrapper>
  );
};
export default PromotionSplash;
