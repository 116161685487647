import { setEmailAndPhoneAndDigitalReceipt } from '@api/AccountApi';
import CheckBox from '@components/atoms/CheckBox';
import InputField from '@components/atoms/InputField';
import Button from '@components/atoms/__DEPRECATED__/Button';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import { DEFAULT_REQUIRED, EMAIL_VALIDATION, VALIDATE_PHONE_NUMBER } from '@constants/formValidation';
import isFrontend from '@helpers/isFrontend';
import logError from '@helpers/logHelpers';
import { removeSpacesAndDashes, removeSwedishCountryCode } from '@helpers/string';
import { isAxiosError } from 'axios';
import Heading from 'components/atoms/__DEPRECATED__/Heading';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';

import useCustomer from '@hooks/useCustomer';
import {
  AddMissingCustomerInformationWrapper,
  ButtonWrapper,
  ErrorMessageWrapper,
  FormRow,
  FormWrapper,
  InfoWrapper,
  TextWrapper,
} from './AddMissingCustomerInformation.styles';

export interface SetEmailAndPhoneAndDigitalReceipt {
  email: string;
  chkEmail: string;
  phone: string;
  useDigitalReceipt: boolean;
  sendDigitalReceiptByEmail: boolean;
}

interface FormValues {
  email: string;
  phone: string;
  sendDigitalReceiptByEmail: boolean;
  useDigitalReceipt: boolean;
  password: string;
}

interface Props {
  onClose: () => void;
}

const AddMissingCustomerInformation = ({ onClose }: Props) => {
  const { t } = useTranslation('common');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { email, defaultShippingAddress, sendDigitalReceipt, digitalReceiptEmailCommChannel } = useCustomer();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { register, control, handleSubmit, watch, setValue, formState } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      email: email || '',
      phone: defaultShippingAddress?.cellphone || '',
      useDigitalReceipt: sendDigitalReceipt || false,
      sendDigitalReceiptByEmail: (sendDigitalReceipt && digitalReceiptEmailCommChannel) || false,
    },
  });

  const useDigitalReceipt = watch('useDigitalReceipt');
  const { errors } = useFormState<FormValues>({ control });

  const onSubmit = async (formData: FormValues) => {
    setIsLoading(true);
    setErrorMessage(null);
    const data: SetEmailAndPhoneAndDigitalReceipt = {
      ...formData,
      chkEmail: formData.email,
    };
    try {
      await setEmailAndPhoneAndDigitalReceipt(data);
      onClose();
    } catch (e: unknown) {
      if (isAxiosError(e)) {
        e.response?.data?.error === 'axcrm.service.unavailable.exception'
          ? setErrorMessage(t(`addMissingCustomerInformation:error->${e.response?.data?.error}`))
          : setErrorMessage(t('addMissingCustomerInformation:error->default'));
      }
      logError('Could not upate user information', e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (useDigitalReceipt === false) {
      setValue('sendDigitalReceiptByEmail', false, { shouldValidate: true });
    }
  }, [setValue, useDigitalReceipt]);

  if (!isFrontend()) {
    return null;
  }

  return (
    <AddMissingCustomerInformationWrapper>
      <Heading type="h2" size="md" color="red">
        {t('addMissingCustomerInformation:header')}
      </Heading>
      <TextWrapper>
        <Paragraph>{t('addMissingCustomerInformation:subHeader')}</Paragraph>
      </TextWrapper>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <FormRow>
          <InputField
            id="email"
            type="email"
            name="email"
            label={t('addMissingCustomerInformation:email')}
            isActive={!!watch('email')}
            register={register}
            error={errors?.email?.message && t(`formValidation:${errors.email.message}`)}
            rules={{ required: DEFAULT_REQUIRED, pattern: EMAIL_VALIDATION }}
          />
        </FormRow>
        <FormRow>
          <InputField
            id="phoneNumber"
            type="tel"
            name="phone"
            label={t('addMissingCustomerInformation:cellphone')}
            isActive={!!watch('phone')}
            register={register}
            error={errors?.phone?.message && t(`formValidation:${errors.phone.message}`)}
            rules={{ required: DEFAULT_REQUIRED, pattern: VALIDATE_PHONE_NUMBER }}
            onBlur={(e) => setValue('phone', removeSpacesAndDashes(removeSwedishCountryCode(e.currentTarget.value)))}
            setValueAs={(number) => removeSpacesAndDashes(removeSwedishCountryCode(number))}
          />
        </FormRow>
        <FormRow $isCheckbox>
          <Controller
            name="useDigitalReceipt"
            control={control}
            render={({ field }) => (
              <CheckBox
                label={t('addMissingCustomerInformation:digitalReceipts')}
                checked={field.value}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
              />
            )}
          />
        </FormRow>
        <FormRow $isCheckbox>
          <Controller
            name="sendDigitalReceiptByEmail"
            control={control}
            render={({ field }) => (
              <CheckBox
                disabled={!useDigitalReceipt}
                label={t('addMissingCustomerInformation:digitalReceiptsViaEmail')}
                checked={field.value}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
              />
            )}
          />
        </FormRow>
        <InfoWrapper>
          <Paragraph size="body-text">{t('addMissingCustomerInformation:updateEmailOnMyPages')}</Paragraph>
        </InfoWrapper>
        {errorMessage && (
          <ErrorMessageWrapper>
            <Paragraph textColor="primary">{errorMessage}</Paragraph>
          </ErrorMessageWrapper>
        )}
        <ButtonWrapper>
          <Button type="submit" variant="highlight" disabled={!formState.isValid} isLoading={isLoading}>
            {t('common:defaultActions->save')}
          </Button>
        </ButtonWrapper>
      </FormWrapper>
    </AddMissingCustomerInformationWrapper>
  );
};

export default AddMissingCustomerInformation;
