import { DefaultTheme } from 'styled-components';
import animations from './animations';
import breakpoints from './breakpoints';
import colors from './colors';
import fonts from './fonts';
import globalClasses from './globalClasses';
import layers from './layers';
import shadows from './shadows';
import spacing from './spacing';
import typography from './typography';

const theme: DefaultTheme = {
  colors,
  breakpoints,
  typography,
  animations,
  globalClasses,
  fonts,
  shadows,
  spacing,
  layers,
};

export default theme;
