import styled, { css } from 'styled-components';
import { rem } from 'polished';

interface HeaderSlotContainerProps {
  $active?: boolean;
  $disabled?: boolean;
  $isClosed?: boolean;
  $childHeight?: number;
}

export const AccordionWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.colorWhite};
`;

export const HeaderWrapper = styled.div<HeaderSlotContainerProps>`
  display: flex;
  height: ${rem(60)};
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${rem(20)};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.colorMenuBackground};
  outline: none;
  border-top: ${rem(1)} solid ${({ theme }) => theme.colors.colorBorder};
  font-weight: bold;

  ${(props) =>
    props.$disabled &&
    css`
      color: ${({ theme }) => theme.colors.colorSilver};
      cursor: default;
    `}
`;

export const IconWrapper = styled.div<HeaderSlotContainerProps>`
  ${(props) =>
    props.$isClosed &&
    css`
      svg {
        ${props.theme.animations.rotatorUp90Animation};
      }
    `}
  ${(props) =>
    props.$active &&
    css`
      svg {
        ${props.theme.animations.rotatorDown90Animation}
      }
    `}
`;
export const ContentSectionWrapper = styled.div<HeaderSlotContainerProps>`
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  height: 0;
  ${(props) =>
    props.$active &&
    props.$childHeight &&
    css`
      height: ${rem(props.$childHeight + 20)};

      transition: all 0.3s ease-in-out;
    `}
`;

export const AccordionText = styled.div``;

export const ContentList = styled.div`
  height: auto;
  border-top: ${rem(1)} solid ${({ theme }) => theme.colors.colorBorder};
  padding: ${rem(20)} ${rem(15)};
`;
