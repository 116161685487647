import Link from '@components/atoms/Link';
import Paragraph from '@components/atoms/Paragraph';
import ProductGrid from '@components/atoms/ProductGrid';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import ProductsWithTracking from '@components/organisms/ProductsWithTracking';
import { getNumberOfCardsFitsInRef } from '@helpers/productHelper';
import useResponsive from '@hooks/useResponsive';
import Heading from 'components/atoms/Heading';
import { useCallback, useEffect, useRef, useState } from 'react';
import { GridHeading, HeadingLeftWrapper, SubtitleWrapper } from './FixedRowsProductGrid.styles';

export interface Props {
  products: Product[];
  eventListName: string;
  title: string;
  subtitle?: string;
  href: string;
  linkText: string;
  isLoading: boolean;
  rows?: number;
}

const FixedRowsProductGrid = ({
  products,
  eventListName,
  title,
  subtitle,
  href,
  linkText,
  isLoading,
  rows = 2,
}: Props) => {
  const [productsToRender, setProductsToRender] = useState<Product[]>([]);

  const { isTabletPortraitOrGreater } = useResponsive();

  const [gridRef, setGridRef] = useState<HTMLDivElement | null>(null);

  const handleRef = (ref: HTMLDivElement) => setGridRef(ref);

  const containerRef = useRef<HTMLDivElement>(null);

  const getAmountOfCards = useCallback(() => {
    return isTabletPortraitOrGreater ? getNumberOfCardsFitsInRef(containerRef) : 2;
  }, [isTabletPortraitOrGreater, containerRef]);

  const sliceIndex = getAmountOfCards() * rows;

  useEffect(() => {
    setProductsToRender(products.slice(0, sliceIndex));
  }, [sliceIndex, products]);

  return (
    <div ref={containerRef}>
      <GridHeading>
        <HeadingLeftWrapper>
          <Heading size="small" type="h2">
            {title}
          </Heading>
          {subtitle && (
            <SubtitleWrapper>
              <Paragraph size="medium" data-testid="subtitle">
                {subtitle}
              </Paragraph>
            </SubtitleWrapper>
          )}
        </HeadingLeftWrapper>
        {href && (
          <Link href={href} aria-label={`${linkText} ${title}`}>
            <Paragraph size="medium">{linkText}</Paragraph>
          </Link>
        )}
      </GridHeading>
      <ProductGrid passRef={handleRef}>
        <ProductsWithTracking
          products={productsToRender}
          eventListName={eventListName}
          containerRef={gridRef}
          isLoading={isLoading}
          skeletonCount={sliceIndex / rows}
        />
      </ProductGrid>
    </div>
  );
};

export default FixedRowsProductGrid;
