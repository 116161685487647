// web 2.0 colors
const colors = {
  // new colors (not yet in the UX design files)
  colorLightOrange: '#FDECDB',

  // primary
  colorAlizarin: '#EA182A',
  colorRemy: '#FADBD9',
  colorAcadia: '#2E2D2C',
  colorDesertStorm: '#F8F7F6',
  colorOldLace: '#FDF5E6',

  // secondary
  colorAmber: '#FFDD53', // remove when changing klubb color
  colorBayleaf: '#7DBB88',
  colorEcstasy: '#F78515',

  // red tones
  colorCardinal: '#CF1B2B',
  colorThunderbird: '#B91523',
  colorPetiteOrchid: '#E3A59F',
  colorBeautyBush: '#ECBFBB',

  // grey tones
  colorSilver: '#C9C5BE',
  colorIron: '#D9D5D0',
  colorTundora: '#4d4d4d',

  // yellow tones
  colorSupernova: '#FDD225',
  colorOasis: '#FAEFC5',

  // green tones
  colorFruitSalad: '#48A74D',
  colorAquaSqueeze: '#D3E5D5',

  // system colors
  colorWhite: '#FFFFFF',
  colorPampas: '#EFECE9',
  colorCararra: '#E9E5E0',
  colorStack: '#9C9994',
  colorLinen: '#FCEBE9',
  colorFroly: '#FA7883',
  colorGainsboro: '#D9D9D9',
};

const themeColors = {
  ...colors,
  // USAGE
  colorPrimary: colors.colorAlizarin,
  colorPrimaryPink: colors.colorRemy,
  colorSecondary: colors.colorCararra,
  colorTextPrimary: colors.colorAcadia,
  colorTextSecondary: colors.colorStack,
  colorBorder: colors.colorPampas,
  colorBackground: colors.colorDesertStorm,
  colorHighlight: colors.colorRemy,
  colorDropShadow: colors.colorIron,
  colorMenuBorder: colors.colorSilver,
  colorMenuBackground: colors.colorWhite,
  colorBoxShadow: 'rgba(0, 0, 0, 0.07)',
  colorFocus: '#005FCC',
};

export type ThemeColors = typeof themeColors;
export default themeColors;
