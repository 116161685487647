import makeIntersectionObserverComponent from '@helpers/componentTracking';
import { ReactNode, RefObject } from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div``;

interface Props {
  children: ReactNode;
  intersectionRef: RefObject<any>;
}
const IntersectionWrapper = ({ intersectionRef, children }: Props) => {
  return (
    <Wrapper data-testid="intersection-wrapper" ref={intersectionRef}>
      {children}
    </Wrapper>
  );
};
const IntersectionObservedWrapper = makeIntersectionObserverComponent(IntersectionWrapper);

export default IntersectionObservedWrapper;
