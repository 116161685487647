type MapType = {
  [id: string]: string;
};

export const HEMKOP_APPLE_APPSTORE = 'HEMKOP_APPLE_APPSTORE';
export const HEMKOP_GOOGLE_PLAYSTORE = 'HEMKOP_GOOGLE_PLAYSTORE';
export const PHARMACY_LINK = 'PHARMACY_LINK';

const externalLinks: MapType = {
  [HEMKOP_APPLE_APPSTORE]: 'https://apps.apple.com/se/app/hemk%C3%B6p/id690495271',
  [HEMKOP_GOOGLE_PLAYSTORE]: 'https://play.google.com/store/apps/details?id=se.axfood.hemkop&hl=sv_SE',
  [PHARMACY_LINK]: 'https://lakemedelsverket.se/ovriga-sidor/eu-logotyp-vidimering/#Z00259',
};

const trackerLinkNames: MapType = {
  [HEMKOP_APPLE_APPSTORE]: 'Appstore',
  [HEMKOP_GOOGLE_PLAYSTORE]: 'Google Play',
  [PHARMACY_LINK]: 'Läkemedelsverket',
};

export const getExternalLink = (link: string): string => externalLinks[link];
export const getTrackerLinkName = (link: string): string => trackerLinkNames[link];
