import { getFAQCategoryEntries } from '@api/SiteApi';
import { FAQEntryData } from '@api/generated/storefront';
import Skeleton from '@components/atoms/Skeleton';
import Dropdown from '@components/atoms/__DEPRECATED__/Dropdown';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import useCmsComponents from '@hooks/useCmsComponentGroup';
import Heading from 'components/atoms/__DEPRECATED__/Heading';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';

import useUserAgent from '@hooks/useUserAgent';
import { Container, DropdownWrapper, HeaderSection, InfoTextWrapper } from './AxfoodFAQComponent.styles';
import FAQAccordion from './FAQAccordion';

export interface AxfoodFAQComponentProps {
  component: AxfoodFAQComponent;
}

export interface AxfoodFAQCategoryListItem extends AxfoodFAQCategory {
  code: string;
}

export interface FAQEntryDataWithId extends FAQEntryData {
  id: string;
  title: string;
  content: string;
}

const AxfoodFAQComponent = ({ component }: AxfoodFAQComponentProps) => {
  const { t } = useTranslation('customerservice');
  const { data: categories = [] } = useCmsComponents(component.categories);
  const [currentCategory, setCurrentCategory] = useState<AxfoodFAQCategory | null>(null);
  const [activeItem, setActiveItem] = useState<any | null>(null);

  const [listItems, setListItems] = useState<AxfoodFAQCategoryListItem[]>([]);
  const [entries, setEntries] = useState<FAQEntryDataWithId[]>([]);

  const { isReactNative } = useUserAgent();

  useEffect(() => {
    if (!currentCategory && categories.length) {
      const items = categories.map((c) => ({
        ...c,
        code: c.uid,
      }));
      setListItems(items as AxfoodFAQCategoryListItem[]);
    }
  }, [categories, currentCategory]);

  const fetchEntries = async (uid: string) => {
    try {
      const { data } = await getFAQCategoryEntries(uid);
      if (data.length) {
        const entriesData = data.map((e, i) => ({
          title: e.title || '',
          id: `${e.categoryUid || ''}-${i}`,
          content: e.content || '',
        }));
        setEntries(entriesData);
      }
    } catch (ex) {
      // no component side-effects
    }
  };

  const updateActiveItem = (item: any) => {
    setActiveItem(item);
    setCurrentCategory(item);
  };

  useEffect(() => {
    if (currentCategory?.uid) {
      fetchEntries(currentCategory.uid);
    }
  }, [currentCategory]);

  return (
    <Container $isReactNative={isReactNative}>
      <HeaderSection>
        <Heading type="h2">{t('faq->title')}</Heading>
        <InfoTextWrapper>
          <Paragraph>{t('faq->info')}</Paragraph>
        </InfoTextWrapper>
        <DropdownWrapper>
          {!listItems.length ? (
            <Skeleton type="rect" width="100%" height="44px" $style={{ margin: 0 }} />
          ) : (
            <Dropdown
              list={listItems}
              activeItem={activeItem}
              updateActiveItem={updateActiveItem}
              initiallySelected={listItems[0]}
            />
          )}
        </DropdownWrapper>
      </HeaderSection>
      {!!entries.length && <FAQAccordion sections={entries} />}
    </Container>
  );
};

export default AxfoodFAQComponent;
