import { createSelector } from 'reselect';
import { RootState } from 'store';
import getState from './getState';

const selectLoginState = createSelector(getState, (state: RootState) => state.login || {});

export const selectLoginModalIsVisible = createSelector(selectLoginState, (state) => state.visible || false);
export const selectLoginModalIsB2b = createSelector(selectLoginState, (state) => state.b2b || false);
export const selectIsLoginConfirm = createSelector(selectLoginState, (state) => state.isLoginConfirm || false);
export const selectLoginModalIsWithGuest = createSelector(selectLoginState, (state) => state.withGuest || false);

export const selectLoginStatus = createSelector(selectLoginState, (state) => state.currentStatus);
export const selectLoginHintCode = createSelector(selectLoginState, (state) => state.currentHintCode);
export const selectLoginQrData = createSelector(selectLoginState, (state) => state.qrData);
export const selectBankIdDevice = createSelector(selectLoginState, (state) => state.bankIdDevice);

export const selectLoginAlert = createSelector(selectLoginState, (state) => state.alert);
export const selectShowOnboardingInfo = createSelector(selectLoginState, (state) => state.showOnboardingInfo);

export default selectLoginState;
