import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const CarouselHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 0 ${rem(15)} 0;
  ${media.tabletPortrait} {
    font-size: ${rem(20)};
    padding: 0 0 ${rem(15)};
  }
`;

export const HeaderWrapper = styled.div`
  order: 2;
  ${media.tabletPortrait} {
    order: 1;
  }
`;

export const CarouselWrapper = styled.div`
  display: flex;
`;

export const CarouselHeader = styled.div`
  order: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
  border: none;
  text-align: center;

  ${media.tabletPortrait} {
    order: 1;
  }
`;

export const ShowMoreWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${rem(16)} 0;
`;
