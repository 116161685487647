/**
 * Splits a given array "arr" into a given number of chunks "numChunks" as evenly as possible,
 * in a sequential manner, meaning if array is not evenly divisible with given number of chunks,
 * the first chunk/s will contain the overflow.
 *
 * Example #1: arr = [1, 2, 3, 4, 5], numChunks = 2
 * returns [[1, 2, 3], [4, 5]]
 *
 * Example #2: arr = [1, 2, 3, 4, 5], numChunks = 3
 * returns [[1, 2], [3, 4], [5]]
 *
 * @param arr
 * @param numChunks
 */
export const sequentialEvenSplit = (arr: Array<any>, numChunks: number): Array<any> => {
  if (!arr || numChunks < 1) {
    return [];
  }
  if (numChunks > 0) {
    // calculate the length of the chunk
    const chunkLength = Math.ceil(arr.length / numChunks);
    // splice out the chunk, arr is left with the rest
    const chunk = arr.splice(0, chunkLength);
    // do the next chunk
    return [chunk, ...sequentialEvenSplit(arr, numChunks - 1)];
  }
  return arr.length ? [arr] : [];
};

/**
 * Splits a given array "arr" into chunks with the length "chunkLength".
 * If the array is not evenly divisible with the chunk length, the
 * last chunk simply contains the rest.
 *
 * Example #1: arr = [1, 2, 3, 4, 5], chunkLength = 2
 * returns [[1, 2], [3, 4], [5]]
 *
 * Example #2: arr = [1, 2, 3], chunkLength = undefined
 * returns [[1], [2], [3]]
 *
 * @param arr
 * @param chunkLength?
 */
export const chunk = (arr?: Array<any>, chunkLength = 1): Array<any> => {
  if (!arr || chunkLength < 1) {
    return [];
  }

  const arrCopy = [...arr];

  if (arrCopy.length) {
    // splice out the chunk, arrCopy is left with the rest
    const piece = arrCopy.splice(0, chunkLength);
    // do the next chunk
    return [piece, ...chunk(arrCopy, chunkLength)];
  }
  return arrCopy.length ? [arrCopy] : [];
};

const array = { sequentialEvenSplit, chunk };

export default array;
