import * as notificationActions from '@actions/notification';
import IconButton from '@components/molecules/IconButton';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import useOnClickOutside from '@hooks/useOnClickOutside';
import useOnHover from '@hooks/useOnHover';
import useTimeout from '@hooks/useTimeout';
import IconClose from '@public/icons/regularIcons/icon-close-round.svg';
import { NotificationStoreState } from '@reducers/notifications';
import React, { useEffect, useState } from 'react';
import { CloseButtonWrapper, Container } from './Notifications.styles';
interface Props {
  children: React.ReactNode;
  closeable?: boolean;
  notificationName: string;
  timer?: number | null;
}

const Notification = ({ children, closeable = false, notificationName, timer = null }: Props) => {
  const dispatch = useAppDispatch();
  const notification: NotificationType = useAppSelector(
    (state: { notifications: NotificationStoreState }) => state.notifications[notificationName]
  );

  const onTimeoutSuccess = () => {
    dispatch(
      notificationActions.setNotificationVisibility({
        name: notificationName,
        number: notification.number,
        show: false,
      })
    );
  };

  const onTimeoutCancelled = () => {
    return undefined;
  };

  const notificationTimeout = useTimeout(onTimeoutSuccess, onTimeoutCancelled, timer || 0);

  const [hoverRef, isHovered] = useOnHover();
  const [hasTriggeredHover, setHasTriggeredHover] = useState(false);

  const handleOnclick = () => {
    dispatch(
      notificationActions.setNotificationVisibility({ name: notificationName, number: notification.number, show: false })
    );
  };

  useEffect(() => {
    if (notification.show && timer) {
      notificationTimeout.startTimeout();
    }
  }, [notification.show]);

  useEffect(() => {
    if (timer) {
      if (isHovered) {
        notificationTimeout.cancelTimeout();
        setHasTriggeredHover(true);
      } else if (!isHovered && hasTriggeredHover) {
        notificationTimeout.startTimeout();
      }
    }
  }, [isHovered]);

  const onClickOutside = () => {
    notificationTimeout.cancelTimeout();
    onTimeoutSuccess();
  };

  useOnClickOutside(hoverRef, onClickOutside);

  return (
    <Container role="status" data-testid="notification" $isVisible={notification.show} ref={hoverRef as any}>
      <CloseButtonWrapper>
        {closeable && <IconButton data-testid="notification-close-icon" icon={IconClose} onClick={handleOnclick} />}
      </CloseButtonWrapper>
      {children}
    </Container>
  );
};

export default Notification;
