import { StyledLinkArea } from '@components/atoms/__DEPRECATED__/Link/Link.styles';
import {
  LabelsPosition,
  ProductHeaderWrapper,
  ProductQuantityWrapper,
  ProductWrapper,
} from '@components/molecules/__DEPRECATED__/Product/Product.styles';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const ConflictModalWrapper = styled.div`
  padding: ${rem(10)} 0;
`;
export const HeadingWrapper = styled.div`
  margin-bottom: ${rem(10)};
  margin-top: ${rem(25)};
  ${media.desktop} {
    margin-top: 0;
  }
`;

export const ConflictModalReplaceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  align-items: center;
  margin-bottom: ${rem(45)};
`;

export const ConflictedProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ConflictedProductButtonWrapper = styled.div`
  display: inline-block;
`;

export const ConflictedProductReplaceCancelButton = styled.div`
  display: flex;
  width: 100%;
  padding: ${rem(15)} 0;
  justify-content: center;
  ${media.tabletPortrait} {
    padding: ${rem(25)} 0;
    justify-content: flex-start;
  }
`;

export const ConflictedProductWrapperHeader = styled.div<{ $childrenRightAligned?: boolean }>`
  height: ${rem(40)};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.colorTextPrimary};

  ${(props) =>
    props.$childrenRightAligned &&
    css`
      justify-content: flex-end;
    `}
`;

export const Triangle = styled.span`
  width: 0;
  height: 0;
  border-top: ${rem(8)} solid transparent;
  border-bottom: ${rem(8)} solid transparent;
  border-left: ${rem(13)} solid ${({ theme }) => theme.colors.colorTextPrimary};
  margin: 0 0 0 ${rem(10)};

  ${media.mobile} {
    margin: 0 auto;
  }

  ${media.tabletPortrait} {
    margin: 0 ${rem(20)};
  }

  ${media.desktop} {
    margin: 0 ${rem(18.5)};
    border-top: ${rem(10)} solid transparent;
    border-bottom: ${rem(10)} solid transparent;
    border-left: ${rem(15)} solid ${({ theme }) => theme.colors.colorTextPrimary};
  }
`;

interface TextWrapperProps {
  $noTopPadding?: boolean;
}

export const TextWrapper = styled.div<TextWrapperProps>`
  text-align: center;
  padding-bottom: ${rem(30)};
  padding: ${rem(45)} 0 ${rem(30)};
  margin: 0 ${rem(15)};

  ${media.desktop} {
    text-align: left;
    margin: 0;
    padding: 0 0 ${rem(30)};
  }

  ${(props) =>
    props.$noTopPadding &&
    css`
      padding-top: 0;
    `}
`;

export const TopTextWrapper = styled(TextWrapper)`
  padding: 0 0 ${rem(15)};
`;

export const FlexEndButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${rem(30)} ${rem(0)};

  svg {
    width: 32px;
    height: 32px;
  }
  ${media.tabletPortrait} {
    padding: ${rem(25)} ${rem(5)} ${rem(5)};
    flex-direction: row;
    justify-content: flex-end;
    margin: 0;
  }
`;

export const CloseButton = styled.div`
  margin-bottom: ${rem(15)};

  ${media.tabletPortrait} {
    margin-bottom: 0;
    margin-right: ${rem(10)};
  }
`;

interface ConflictedProductProps {
  $disabled?: boolean | string; // string if product should have a text banner overlay
  $selected?: boolean;
}

export const ConflictedProductStyled = styled.div<ConflictedProductProps>`
  border-radius: ${rem(9)};
  text-align: initial;
  position: relative;
  display: flex;
  width: ${rem(140)};
  justify-content: center;
  user-select: none;

  ${media.mobile} {
    width: ${rem(160)};
  }

  ${media.tabletPortrait} {
    width: ${rem(220)};
  }

  ${(props) =>
    !props.$disabled &&
    css`
      cursor: pointer;
    `}

  > * {
    border-radius: ${rem(9)};
    ${(props) =>
      props.$disabled &&
      css`
        opacity: 0.3;
      `}
  }

  ${LabelsPosition} {
    top: ${rem(44)};

    button {
      display: none;
    }
  }
  ${ProductWrapper} {
    padding: 0;
  }
  ${ProductHeaderWrapper} {
    padding: 0 ${rem(11)};
    ${media.tabletPortrait} {
      padding: ${rem(15)} ${rem(11)} 0 ${rem(11)};
    }
    ${media.desktop} {
      padding: ${rem(19)} ${rem(11)} 0 ${rem(11)};
    }
  }
  ${StyledLinkArea} {
    margin: 0;
    opacity: 1;
  }
  ${ProductQuantityWrapper} {
    ${media.tabletLandscape} {
      width: ${rem(176)};
    }
  }

  ${(props) =>
    typeof props.$disabled === 'string' &&
    css`
      &::before {
        content: '${props.$disabled}';
        font-size: ${rem(14)};
        line-height: 1.3;
        letter-spacing: 0.35px;
        text-align: center;
        position: absolute;
        top: ${rem(120)};
        z-index: 1;
        width: calc(100% - ${rem(16)});
        padding: ${rem(8)};
        background: ${props.theme.colors.colorBorder};
      }
    `}

  ${(props) =>
    props.$selected
      ? css`
          border: 2px solid ${props.theme.colors.colorHighlight};
          padding: ${rem(1)};
          &::before {
            content: ' ';
            position: absolute;
            z-index: 1;
            border-radius: ${rem(8)};
            height: ${rem(22)};
            width: ${rem(22)};
            background: url('/icons/colorIcons/icon-check-conflict-dialog.svg') center center;
            left: ${rem(4)};
            top: ${rem(12)};
            ${media.tabletPortrait} {
              left: ${rem(14)};
            }
          }
        `
      : css`
          border: ${rem(1)} solid ${props.theme.colors.colorBorder};
          padding: ${rem(2)};
        `}
`;

export const ConflictedProductReplaceWithWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex-wrap: nowrap;
  flex: 1;
  width: ${rem(140)};
  ${media.mobile} {
    flex: inherit;
    width: ${rem(160)};
  }
  ${media.tabletPortrait} {
    flex: 1;
    width: 100%;
  }
`;
export const ConflictedProductsWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  overflow: hidden;
`;

export const Slide = styled.div`
  min-width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;

  ${media.tabletPortrait} {
    grid-template-columns: repeat(3, ${rem(220)});
  }

  ${media.tabletLandscape} {
    grid-template-columns: repeat(4, ${rem(220)});
  }
`;

export const ReplacementProductsSlide = styled.div`
  min-width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;

  ${media.tabletPortrait} {
    justify-content: space-between;
    grid-template-columns: repeat(2, ${rem(220)});
  }

  ${media.tabletLandscape} {
    justify-content: space-between;
    grid-template-columns: repeat(3, ${rem(220)});
  }
`;

export const ConflictedProductHeaderButtons = styled.div`
  margin-left: auto;
`;

export const ConflictModalTextWrapper = styled.div`
  margin: 0 ${rem(15)};
  ${media.tabletPortrait} {
    margin: 0 auto;
  }
`;

export const PartialOutOfStockWrapper = styled.div`
  width: 100%;
  display: block;
  margin-top: ${rem(15)};
  text-align: center;
  ${media.desktop} {
    text-align: left;
  }
`;
