import * as types from './types';

function setNotificationVisibility(notification: NotificationActionType) {
  return {
    type: types.SET_NOTIFICATION_VISIBILITY,
    notification,
  };
}

export { setNotificationVisibility };
