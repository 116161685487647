import { PriceStyled, PriceText, PriceUnit } from './Price.styles';

declare type Position = 'left' | 'center' | 'right';

interface Props {
  position?: Position;
  price: number;
  hasPromotion: boolean;
  unit: string;
  size?: 'large' | 'small';
}

const Price = ({ position = 'left', price, hasPromotion, unit, size = 'small' }: Props) => (
  <PriceStyled $position={position} data-testid="price">
    <PriceText $size={size} $hasPromotion={hasPromotion} data-testid="price-text">
      <span data-testid="price-container">{price.toFixed(2).replace('.', ',')}</span>
      <PriceUnit>{`/${unit}`}</PriceUnit>
    </PriceText>
  </PriceStyled>
);

export default Price;
