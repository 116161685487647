import LinkArea from '@components/atoms/LinkArea';
import Paragraph from '@components/atoms/Paragraph';
import { Product } from '../ProductDataMapper/types';
import { ProductManufacturer, ProductManufacturerVolume } from '../ProductInformation/ProductInformation.styles';

interface Props {
  product: Product;
  href?: UrlType;
  as?: UrlType;
  disableLinks?: boolean;
  handleProductLinkClick?: () => void;
  linkAriaLabel: string;
}
const ProductTitle = ({ href, as, handleProductLinkClick, product, linkAriaLabel }: Props) => {
  const { manufacturer, displayVolume, name } = product;
  return (
    <>
      <Paragraph size="medium" weight="bold" color="black" truncateText data-testid="product-title">
        {!!href ? (
          <LinkArea
            href={href}
            nextLinkAs={as}
            title={name}
            shallow
            onClick={handleProductLinkClick}
            scroll={false}
            aria-label={linkAriaLabel}
          >
            {name}
          </LinkArea>
        ) : (
          name
        )}
      </Paragraph>
      <ProductManufacturerVolume>
        <Paragraph size="xsmall" weight="medium">
          {manufacturer && (
            <ProductManufacturer data-testid="display-manufacturer">
              {`${manufacturer}${displayVolume ? ', ' : ''}`}
            </ProductManufacturer>
          )}
          {!!displayVolume && <span data-testid="display-volume">{`${displayVolume}`}</span>}
        </Paragraph>
      </ProductManufacturerVolume>
    </>
  );
};

export default ProductTitle;
