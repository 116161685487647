const sekFormatter = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
});

// The whitespace in the formatted string will be &nbsp; (0xA0)
export const formatAmount = (amount: number) => sekFormatter.format(amount);

export const hasPrice = (value?: string) => !!value && value !== '0,00 kr';
export const validPriceOrFallback = (value?: string, fallback?: string) =>
  hasPrice(value) ? value : fallback || undefined;
