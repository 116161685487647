import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

interface Props {
  $textAlign?: string;
}

export const Row = styled.div<Props>`
  display: flex;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  border-radius: ${rem(32)};
  padding: ${rem(16)};
  ${(props) =>
    props.$textAlign === 'RIGHT' &&
    css`
      flex-direction: row-reverse;
    `}

  ${media.tabletPortrait} {
    min-height: ${rem(400)};
  }
`;

export const VideoColumn = styled.div`
  flex: 100%;
  max-width: 100%;
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0;
  ${media.tabletPortrait} {
    flex: 50%;
    max-width: 50%;
  }
`;

export const DescriptionColumn = styled.div`
  max-width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: ${rem(16)};
  gap: ${rem(16)};
  ${media.tabletPortrait} {
    flex: 50%;
    max-width: 50%;
    padding: ${rem(60)} ${rem(64)};
  }
`;

export const VideoThumbnail = styled.div<{ $thumbnail: string }>`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(${({ $thumbnail }) => $thumbnail});
  background-size: cover;
  background-position: center;
  border-radius: ${rem(32)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: ${rem(240)};
  border: none;
  overflow: hidden;
  aspect-ratio: 16/9;
  height: 100%;
`;
