import Heading from 'components/atoms/Heading';
import IconCloseThin from '@public/icons/regularIcons/icon-close-thin.svg';
import Takeover from '@components/atoms/Takeover';
import IconButton from '@components/molecules/IconButton';
import React, { ReactNode } from 'react';
import { MenuContent, MenuHeader } from './TakeoverMenu.styles';

interface TakeoverMenuProps {
  title?: string;
  onClose: () => void;
  children?: ReactNode;
}

const TakeoverMenu = ({ title = 'Meny', onClose, children = undefined }: TakeoverMenuProps) => {
  return (
    <Takeover>
      <MenuHeader>
        <Heading type="h2" color="primary">
          {title}
        </Heading>
        <IconButton icon={IconCloseThin} onClick={() => onClose()} data-testid="icon-close-thin" />
      </MenuHeader>
      <MenuContent>{children}</MenuContent>
    </Takeover>
  );
};

export default TakeoverMenu;
