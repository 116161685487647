import { ProductWrapper } from '@components/molecules/Product/Product.styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: inherit;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const Slider = styled.div`
  display: inherit;
  width: 100%;
  transition: transform 350ms;
  // TODO B2C-28638: remove box shadow
  ${ProductWrapper} {
    box-shadow: none;
  }
`;
