import Heading from '@components/atoms/Heading';
import React from 'react';
import { HeadingWrapper } from '../ProductDetailsRelatedBeams.styles';

interface HeadingProps {
  title: string;
}

const BeamsHeading = ({ title }: HeadingProps) => (
  <HeadingWrapper>
    <Heading color="primary" type="h2">
      {title}
    </Heading>
  </HeadingWrapper>
);

export default React.memo(BeamsHeading);
