import PICK_UNIT from '@constants/pickUnit';
import { productIsCartProductType } from '@helpers/productTypeHelpers';
import {
  AddToCartForm,
  AxfoodBasicProductViewModel,
  AxfoodCartProductViewModel,
  AxfoodProductDetailsViewModel, AxfoodReplacementProductViewModel
} from '@occ/api-client';

// Functionality for parsing the conflicting product updates into server format.
export const formatConflictProductPostData = (product?: AxfoodReplacementProductViewModel, quantity?: number) => {
  let pickUnit;
  if (product?.productBasketType?.code === 'ST') {
    pickUnit = PICK_UNIT.PIECES;
  } else {
    pickUnit = PICK_UNIT.KILOGRAM;
  }
  return {
    productCodePost: product?.code ?? '',
    qty: quantity ?? 0,
    pickUnit,
  };
};

// Functionality for parsing the product updates into server format.
export const formatPostData = (
  product: AxfoodBasicProductViewModel | AxfoodProductDetailsViewModel | AxfoodCartProductViewModel,
  quantity: number
): AddToCartForm => {
  let pickUnit: string;
  if (productIsCartProductType(product) && product.pickUnit && product.pickUnit.code) {
    pickUnit = product.pickUnit.code;
  } else if (product.productBasketType?.code === 'ST') {
    pickUnit = PICK_UNIT.PIECES;
  } else {
    pickUnit = PICK_UNIT.KILOGRAM;
  }
  return {
    productCodePost: product.code,
    qty: quantity,
    pickUnit,
  };
};
