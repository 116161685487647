import { FontSizeType } from '@styles/fontSizes';
import { FontWeights } from '@styles/fonts';
import { ReactNode } from 'react';
import { ParagraphStyled } from './Paragraph.styles';

// TODO this should be broken out to a common font color for all text components (Heading etc)
export type ParagraphColor = 'black' | 'white' | 'orange' | 'primary' | 'secondary';

interface Props extends React.HTMLAttributes<HTMLParagraphElement> {
  children: ReactNode;
  size?: FontSizeType;
  weight?: FontWeights;
  italics?: boolean;
  color?: ParagraphColor;
  truncateText?: boolean;
  className?: string;
  'data-testid'?: string;
  truncateRows?: number;
}

const Paragraph = ({
  children,
  size = 'medium',
  weight = 'normal',
  italics = false,
  color = 'black',
  className,
  truncateText = false,
  truncateRows = 0,
  'data-testid': dataTestId,
  role,
}: Props) => {
  return (
    <ParagraphStyled
      $size={size}
      $weight={weight}
      $italics={italics}
      $color={color}
      className={className}
      $truncateText={truncateText}
      data-testid={dataTestId}
      $truncateRows={truncateRows}
      role={role}
    >
      {children}
    </ParagraphStyled>
  );
};

type PropsWithoutWeight = Omit<Props, 'weight'>;

export const Label = ({ children, ...rest }: PropsWithoutWeight) => (
  <Paragraph weight="medium" {...rest}>
    {children}
  </Paragraph>
);

export const Title = ({ children, ...rest }: PropsWithoutWeight) => (
  <Paragraph weight="bold" {...rest}>
    {children}
  </Paragraph>
);

export default Paragraph;
