const PRODUCTINFORMATION = 'productInformation';
const NUTRITIONVALUE = 'nutritionValue';
const MISC = 'misc';

export type TabType = typeof PRODUCTINFORMATION | typeof NUTRITIONVALUE | typeof MISC;

const TAB_TYPES: {
  PRODUCTINFORMATION: typeof PRODUCTINFORMATION;
  NUTRITIONVALUE: typeof NUTRITIONVALUE;
  MISC: typeof MISC;
} = {
  PRODUCTINFORMATION,
  NUTRITIONVALUE,
  MISC,
};

export default TAB_TYPES;
