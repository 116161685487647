import Icon from '@components/atoms/Icon';
import GLOBALMESSAGE from '@constants/globalmessage';
import useAppRouter from '@hooks/useAppRouter';
import IconCloseCircle from '@public/icons/regularIcons/icon-close-circle.svg';
import { generalTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { ReactElement, ReactNode, useEffect } from 'react';
import { Container, GlobalMessageButton, TextContent } from './GlobalMessage.styles';

interface GlobalMessageProps {
  children: ReactNode;
  textColor?: string;
  backgroundColor?: string;
  hasCloseButton?: boolean;
  onClick: () => void;
  uid: string;
  content: string;
}

const GlobalMessage = ({
  children,
  textColor = '#000',
  backgroundColor = 'grey',
  hasCloseButton = true,
  onClick,
  content = '',
  uid = '',
}: GlobalMessageProps): ReactElement => {
  const { t } = useTranslation('globalmessage');
  const { pathname } = useAppRouter();

  useEffect(() => {
    if (uid !== GLOBALMESSAGE.COOKIE_ID) {
      generalTracker.showGlobalMessage(content);
    }
  }, [uid, content, pathname]);

  const onClose = () => {
    if (uid === GLOBALMESSAGE.COOKIE_ID) {
      generalTracker.closeCookieBar();
    } else {
      generalTracker.closeGlobalMessage(content);
    }
    onClick();
  };

  return (
    <Container style={{ color: `${textColor}`, backgroundColor: `${backgroundColor}` }} data-testid="message-container">
      <TextContent data-testid="message-text-content">{children}</TextContent>
      <div>
        {hasCloseButton && (
          <GlobalMessageButton variant="none" onClick={onClose} data-testid="message-dismiss-button">
            <span>{t('globalmessage->confirm')}</span>
            <Icon icon={IconCloseCircle} margin="left" color="white" data-testid="icon-close-circle" />
          </GlobalMessageButton>
        )}
      </div>
    </Container>
  );
};

export default GlobalMessage;
