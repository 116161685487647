import { OnboardingActionTypes } from '@actions/onboarding';
import * as types from '@actions/onboarding/types';
import { CorrectedAxfoodCheckCustomerDTO } from '@api/PasswordResetApi';
import { LoginTabType } from '@features/login/constants';
import { OnboardingEntryPoint, PendingCompanyInfo } from '@features/onboarding/types';

export interface OnboardingState {
  pendingUsername: string;
  pendingMaskedCustomer: CorrectedAxfoodCheckCustomerDTO | null;
  pendingCompanyInfo: PendingCompanyInfo | null;
  pendingCreatePassword: boolean;
  didApplyForCredit: boolean;
  entryPoint: OnboardingEntryPoint;
  entryIdMethod: '' | LoginTabType;
}

export const initialState: OnboardingState = {
  pendingUsername: '',
  pendingMaskedCustomer: null,
  pendingCompanyInfo: null,
  pendingCreatePassword: false,
  didApplyForCredit: false,
  // values stored for GA4 tracking. used in events create_account & login
  entryPoint: '',
  entryIdMethod: '',
};

const onboardingReducer = (state = initialState, action: OnboardingActionTypes): OnboardingState => {
  switch (action.type) {
    case types.SET_PENDING_USERNAME:
      return {
        ...state,
        pendingUsername: action.payload,
      };
    case types.SET_PENDING_MASKED_CUSTOMER:
      return {
        ...state,
        pendingMaskedCustomer: action.payload,
      };
    case types.SET_PENDING_COMPANY_INFO:
      return {
        ...state,
        pendingCompanyInfo: action.payload,
      };
    case types.SET_PENDING_COMPANY_CREATE_PASSWORD:
      return {
        ...state,
        pendingCreatePassword: action.payload,
      };
    case types.ON_CREATE_B2B_SUCCESS:
      return {
        ...state,
        didApplyForCredit: action.payload,
      };
    case types.SET_ONBOARDING_ENTRY_POINT:
      return {
        ...state,
        entryPoint: action.payload,
      };
    case types.SET_ONBOARDING_ENTRY_ID_METHOD:
      return {
        ...state,
        entryIdMethod: action.payload,
      };

    case types.RESET_ONBOARDING_STATE:
      return initialState;
    default:
      return state;
  }
};

export default onboardingReducer;
