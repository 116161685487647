import { Box } from '@components/molecules/CartThresholds/CartThresholds.styles';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

interface MiniCartPaneHeight {
  $top: number;
  $right: number;
  $height: number;
}

export const Container = styled.div<MiniCartPaneHeight>`
  position: absolute;
  top: ${({ $top }) => rem($top)};
  right: ${({ $right }) => rem($right)};
  height: calc(100vh - ${({ $height }) => $height}px);

  min-width: ${rem(409)};
  max-width: ${rem(409)};
  background-color: ${({ theme }) => theme.colors.colorWhite};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 500;
`;

export const ScrollWrapper = styled.div`
  overflow-y: auto;
  min-height: ${rem(200)};
`;

export const LoginSection = styled.div`
  flex: 0 1 auto;
  background-color: ${({ theme }) => theme.colors.colorSecondary};
  box-sizing: border-box;
  padding: ${rem(5)} 0;
`;

export const RecipesWrapper = styled.div`
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionHeader = styled.div`
  flex: 0 1 auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: ${rem(10)} ${rem(15)};
`;

export const ProductsWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  box-sizing: border-box;
  overflow: auto;
  flex-direction: column;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

interface ArrowIconWrapperProps {
  $flipped: boolean;
}
export const ArrowIconWrapper = styled.div<ArrowIconWrapperProps>`
  align-self: flex-end;
  cursor: pointer;
  transition: 0.3s ease;
  ${(props) =>
    props.$flipped &&
    css`
      transform: rotate(-180deg);
    `}
`;

export const EmptyCartMessageWrapper = styled.div`
  margin: ${rem(20)};
`;

export const CartThresholdsWrapper = styled.div`
  ${Box} {
    margin: ${rem(10)};
    padding: ${rem(15)};
  }
`;
