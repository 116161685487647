import Button from '@components/atoms/Button';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const ProductListFiltersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: ${rem(16)};

  ${media.tabletPortrait} {
    margin-bottom: 0;
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  padding: ${rem(24)} ${rem(16)};

  ${media.tabletPortrait} {
    padding: ${rem(26)} ${rem(24)};
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  &:first-child {
    margin-right: ${rem(5)};
  }
  &:last-child {
    margin-left: ${rem(5)};
  }
  ${media.largeMobile} {
    max-width: 49%;
  }
  ${media.tabletLandscape} {
    max-width: ${rem(220)};
  }
`;

export const ClearButtonWrapper = styled.div`
  margin-top: ${rem(16)};
  margin-bottom: ${rem(8)};
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${media.tabletPortrait} {
    margin-bottom: 0;
  }
  ${media.desktop} {
    flex-direction: column;
  }
`;

export const FilterButton = styled(Button)`
  height: ${rem(36)};
  font-size: ${({ theme }) => theme.fonts.fontSize.small};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-height: ${rem(60)};
`;

export const Close = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  cursor: pointer;
`;

export const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
