import { isAxiosError } from 'axios';

export const errorHasMessage = (value: unknown): value is { message: string } => {
  return typeof value === 'object' && value !== null && 'message' in value;
};

export const isCsrfBadOrmissing = (error: unknown) => {
  if (!isAxiosError(error)) return false;
  return error.response?.data.error === 'csrf.badormissing';
};
