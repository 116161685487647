import { Iframe } from './VideoModal.styles';

interface Props {
  videoLink: string;
  autoplay?: boolean;
  isModal?: boolean;
}

export const formatYoutubeSrc = (videoLink: string) =>
  `https://www.youtube-nocookie.com/embed/${videoLink}?autoplay=1&rel=0&controls=1`;

const VideoIframe = ({ videoLink, isModal = false }: Props) => {
  return (
    <Iframe
      src={formatYoutubeSrc(videoLink)}
      title="youtube-player"
      allowFullScreen
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      $isModal={isModal}
    />
  );
};

export default VideoIframe;
