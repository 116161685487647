import * as types from '@actions/notification/types';
import NOTIFICATIONS from '@constants/notifications';

export interface NotificationStoreState {
  [name: string]: NotificationType;
}

export const initialState = {
  [NOTIFICATIONS.VOUCHER_NOTIFICATION]: {
    show: false,
    timesShown: 0,
    maxShowings: 1,
    number: 0,
  },
  [NOTIFICATIONS.LOYALTY_LOGIN_NOTIFICATION]: {
    show: false,
    timesShown: 0,
    maxShowings: 2,
  },
  [NOTIFICATIONS.STORYBOOK_NOTIFICATION]: {
    show: true,
    timesShown: 0,
    maxShowings: 100,
  },
};

const notificationReducer = (state: NotificationStoreState = initialState, action: any) => {
  switch (action.type) {
    case types.SET_NOTIFICATION_VISIBILITY:
      if (state[action.notification.name].timesShown >= state[action.notification.name].maxShowings) {
        return {
          ...state,
          [action.notification.name]: {
            ...state[action.notification.name],
            show: false,
            timesShown: state[action.notification.name].timesShown,
            maxShowings: state[action.notification.name].maxShowings,
          },
        };
      }
      return {
        ...state,
        [action.notification.name]: {
          show: action.notification.show,
          number: action.notification.number,
          timesShown: action.notification.count
            ? state[action.notification.name].timesShown + 1
            : state[action.notification.name].timesShown,
          maxShowings: state[action.notification.name].maxShowings,
        },
      };
    default:
      return state;
  }
};

export default notificationReducer;
