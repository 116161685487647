import { visuallyHidden } from '@styles/utils';
import { rem } from 'polished';
import styled from 'styled-components';

interface CheckBoxContainerProps {
  $margin?: 'top' | 'right' | 'bottom' | 'left';
  $alignLabel: 'center' | 'top';
  $disabled: boolean;
}

export const CheckBoxStyled = styled.div<{ $checked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(18)};
  flex-shrink: 0;
  height: ${rem(18)};
  border: ${rem(2)} solid ${({ theme }) => theme.colors.colorAcadia};
  transition: all 150ms;
  cursor: pointer;
  background-color: ${({ theme, $checked }) => ($checked ? theme.colors.colorAcadia : theme.colors.colorWhite)};
  border-radius: ${rem(4)};
`;

export const CheckBoxContainer = styled.div<CheckBoxContainerProps>`
  display: flex;
  vertical-align: middle;
  align-items: ${({ $alignLabel }) => ($alignLabel === 'top' ? 'flex-start' : 'center')};
  position: relative;
  ${({ $margin }) => $margin && `margin-${$margin}: ${rem(10)};`}
  ${({ $disabled }) =>
    $disabled &&
    `
    > * {
      opacity: .5;
    }
  `}

  &:has(input:focus-visible) {
    outline: ${rem(2)} solid ${({ theme }) => theme.colors.colorFocus};
    outline-offset: ${rem(2)};
  }
`;

// Hide checkbox visually but remain accessible to screen readers.
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  ${visuallyHidden}
`;

export const CheckBoxLabel = styled.span`
  margin-left: ${rem(10)};
  cursor: pointer;
`;
