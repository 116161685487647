import Paragraph from '@components/atoms/Paragraph';
import { PromotionLabelWrapper } from '@components/organisms/ProductDetails/ProductDetailsInformation/ProductDetailsInformation.styles';
import IconAlltidBraPris from '@public/icons/colorIcons/icon-alltid-bra-pris.svg';
import useTranslation from 'next-translate/useTranslation';
import { FlexParagraph, PromotionLabelContainer } from '../ProductInformation/ProductInformation.styles';
import { ProductLabel } from '../ProductLabels/ProductLabels.styles';

interface Props {
  isAlwaysGoodPrice: boolean;
  promotionLabel?: string;
  showIsDrugProduct: boolean;
  hasPromotion: boolean;
  showLocalBargain?: boolean;
}

const PromotionLabel = ({
  isAlwaysGoodPrice,
  promotionLabel,
  showIsDrugProduct,
  hasPromotion,
  showLocalBargain = false,
}: Props) => {
  const { t } = useTranslation('product');

  return (
    <>
      {showLocalBargain && (
        <PromotionLabelContainer>
          <PromotionLabelWrapper data-testid="bargain-label">
            <FlexParagraph truncateText size="small" weight="bold" color={'primary'}>
              {t('localBargainText')}
            </FlexParagraph>
          </PromotionLabelWrapper>
        </PromotionLabelContainer>
      )}
      {!showLocalBargain && hasPromotion && !!promotionLabel && (
        <PromotionLabelContainer>
          <PromotionLabelWrapper data-testid="promotion-label">
            <FlexParagraph truncateText size="small" weight="bold" color={isAlwaysGoodPrice ? 'orange' : 'primary'}>
              {isAlwaysGoodPrice && (
                <ProductLabel
                  icon={IconAlltidBraPris}
                  mouseoverText={t('product:promotionSplash->always_good_price')}
                  data-testid="promotion-theme-label"
                  size={16}
                />
              )}
              {promotionLabel}
            </FlexParagraph>
          </PromotionLabelWrapper>
        </PromotionLabelContainer>
      )}
      {showIsDrugProduct && (
        <PromotionLabelContainer>
          <PromotionLabelWrapper>
            <Paragraph size="xsmall" color="black" weight="bold" data-testid="is-drug-product">
              {t('product:isDrugProduct')}
            </Paragraph>
          </PromotionLabelWrapper>
        </PromotionLabelContainer>
      )}
    </>
  );
};

export default PromotionLabel;
