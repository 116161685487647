import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

interface HorizontalBannerSectionProps {
  $paddingTop?: string;
  $paddingBottom?: string;
  $paddingLeft?: string;
  $paddingRight?: string;
}

interface HorizontalBannerBackgroundProps {
  $backgroundColor: string;
  $hideOverlay: boolean;
}

export const HorizontalBannerSection = styled.section<HorizontalBannerSectionProps>`
  flex: 0 0 100%;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-top: ${({ $paddingTop }) => `${$paddingTop}px`};
  padding-bottom: ${({ $paddingBottom }) => `${$paddingBottom}px`};
  padding-left: ${({ $paddingLeft }) => `${$paddingLeft}px`};
  padding-right: ${({ $paddingRight }) => `${$paddingRight}px`};
`;

export const HorizontalBannerInner = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const HorizontalBannerBackground = styled.div<HorizontalBannerBackgroundProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: ${({ $backgroundColor }) => $backgroundColor};

  ${(props) =>
    !props.$hideOverlay &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.3;
        width: 100%;
        height: 100%;
        background-color: black;
      }
    `}
`;

export const HorizontalBannerBackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: 50% 50%;
    ${media.tabletPortrait} {
      object-position: 0 0;
    }
  }
`;

export const HorizontalBannerContent = styled.div`
  width: 100%;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-height: ${rem(260)};
  text-align: center;
  position: relative;
  z-index: 1;
  ${media.tabletPortrait} {
    padding: 0 ${rem(100)};
    min-height: ${rem(368)};
    align-items: flex-start;
    text-align: left;
  }
`;

export const HorizontalBannerContentInner = styled.div`
  width: 100%;
  padding: 0 ${rem(10)};
  text-align: center;
  ${media.tabletPortrait} {
    max-width: ${rem(600)};
    text-align: left;
  }
`;

export const HorizontalBannerTitle = styled.div`
  font-size: ${rem(26)};
  margin: 0;
  ${media.tabletPortrait} {
    margin: 0 0 ${rem(20)} 0;
    font-size: ${rem(53)};
  }
`;

interface HorizontalBannerPreambleProps {
  $color: string;
}

export const HorizontalBannerPreamble = styled.div<HorizontalBannerPreambleProps>`
  font-size: ${rem(16)};
  line-height: ${rem(20)};
  margin: ${rem(15)} 0 ${rem(15)};
  color: ${({ $color }) => $color};
  ${({ theme }) => theme.globalClasses.cmsContentText};
`;

export const HorizontalBannerButtonWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: ${rem(20)};

  a {
    display: inline-block;
  }

  ${media.tabletPortrait} {
    text-align: left;
  }
`;
