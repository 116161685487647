import { SearchSuggestionData } from '@api/generated/storefront';
import Heading from '@components/atoms/Heading';
import Icon from '@components/atoms/Icon';
import Paragraph from '@components/atoms/Paragraph';
import { Stack } from '@components/atoms/Stack';
import ProductBasketIcon from '@public/icons/colorIcons/icon-product-basket.svg';
import useTranslation from 'next-translate/useTranslation';
import { SearchTextWrapper } from './common.styles';
import CustomSuggestion from './CustomSuggestion';
import { IconWrapper, ListItem, SearchHintList, SuggestionsPanel } from './NoResults.styles';

interface Props {
  customSuggestion?: SearchSuggestionData;
  searchTerm?: string;
  noHitsCopy?: string;
  modal?: boolean;
}

const NoResults = ({ searchTerm = '', customSuggestion, noHitsCopy = '', modal = false }: Props) => {
  const { t } = useTranslation('common');
  return (
    <SuggestionsPanel $modal={modal} data-testid="no-results-container">
      <IconWrapper>
        <Icon icon={ProductBasketIcon} size={80} />
      </IconWrapper>
      {customSuggestion && customSuggestion.text ? (
        <CustomSuggestion customSuggestion={customSuggestion} searchTerm={searchTerm} />
      ) : (
        <SearchTextWrapper>
          <Heading type="h3" size="xSmall">
            {noHitsCopy ? noHitsCopy : t('search->noProductsFound')}
          </Heading>
          {!noHitsCopy && (
            <Stack $space={'xsmall'}>
              <Paragraph>&quot;{searchTerm}&quot;</Paragraph>
              <SearchHintList>
                <ListItem>{t('search->firstSearchHint')}</ListItem>
                <ListItem>{t('search->secondSearchHint')}</ListItem>
              </SearchHintList>
            </Stack>
          )}
        </SearchTextWrapper>
      )}
    </SuggestionsPanel>
  );
};

export default NoResults;
