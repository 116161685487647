import { getCurrentDate, getCurrentTime } from '@helpers/getCurrentDateAndTime';
import TagManager from 'react-gtm-module';

export const trackFaqOpened = (text: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'faq_opened',
      date: getCurrentDate(),
      sphere_name: 'customer_service',
      target: text,
      time: getCurrentTime(),
    },
  });
};
