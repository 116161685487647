import React, { ReactNode, useMemo } from 'react';

type ApplicationContextType = {
  userAgent: string;
  isAndroid: boolean;
  isIos: boolean;
  isOpera: boolean;
  isWindows: boolean;
  isSSR: boolean;
  isNativeApp: boolean;
  isReactNative: boolean;
  isMobile: boolean;
  isDesktop: boolean;
};

/**
This is a global context that can store global variables that are NOT subject to change often
but needs to be passed down to multiple components. 
For global state management that updates frequently we use redux which is a more powerful tool.
 */
const ApplicationContext = React.createContext<ApplicationContextType>({
  userAgent: '',
  isAndroid: false,
  isIos: false,
  isOpera: false,
  isWindows: false,
  isSSR: false,
  isNativeApp: false,
  isReactNative: false,
  isMobile: false,
  isDesktop: false,
});

export const ApplicationContextProvider = ({ userAgent, children }: { userAgent: string; children: ReactNode }) => {
  const isAndroid = Boolean(userAgent.match(/Android/i));
  const isIos = Boolean(userAgent.match(/iPhone|iPad|iPod/i));
  const isOpera = Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = Boolean(userAgent.match(/IEMobile/i));
  const isSSR = Boolean(userAgent.match(/SSR/i));
  const isNativeApp = Boolean(userAgent.match(/AxfoodMobileApp/i));
  const isReactNative = Boolean(userAgent.match(/AxfoodMobileApp-ReactNative/i));
  const isMobile = Boolean(isAndroid || isIos || isOpera || isWindows || isNativeApp);
  const isDesktop = Boolean(!isMobile && !isSSR && !isNativeApp);

  const value = useMemo(
    () => ({
      userAgent,
      isAndroid,
      isIos,
      isOpera,
      isWindows,
      isSSR,
      isNativeApp,
      isReactNative,
      isMobile,
      isDesktop,
    }),
    [userAgent]
  );
  return <ApplicationContext.Provider value={value}>{children}</ApplicationContext.Provider>;
};

export default ApplicationContext;
