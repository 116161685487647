import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';
import Icon from '@components/atoms/Icon';
import Paragraph from '@components/atoms/Paragraph';
import IconFrozen from '@public/icons/regularIcons/icon-frozen.svg';
import useTranslation from 'next-translate/useTranslation';
import {
  InfoBoxBlock,
  InfoBoxDetailsWrapper,
  InfoBoxIconWrapper,
  InfoBoxInnerWrapper,
  InformationBox,
} from './ProductDescriptionListing.styles';

interface InfoBoxListingProps {
  product: AxfoodProductDetailsViewModel;
}

const InfoBoxListing = ({ product }: InfoBoxListingProps) => {
  const { t } = useTranslation('productDetails');

  const { minStorageTemperature, maxStorageTemperature, consumerStorageInstructions } = product;
  const hasTemp = minStorageTemperature || maxStorageTemperature || consumerStorageInstructions;
  if (!hasTemp) {
    return null;
  }
  return (
    <InformationBox>
      <InfoBoxInnerWrapper>
        {hasTemp && (
          <InfoBoxBlock>
            <InfoBoxIconWrapper>
              <Icon icon={IconFrozen} color="none" data-testid="icon-frozen" />
            </InfoBoxIconWrapper>
            <InfoBoxDetailsWrapper>
              <Paragraph size="small" weight="bold">
                {t('productDetails->storage')}
              </Paragraph>
              <Paragraph size="medium" data-testid="storageTemperature">
                {minStorageTemperature && `${t('productDetails->minStorageTemperature')} ${minStorageTemperature}, `}
                {maxStorageTemperature && `${t('productDetails->maxStorageTemperature')} ${maxStorageTemperature}`}
              </Paragraph>
              {consumerStorageInstructions && (
                <Paragraph size="medium" data-testid="consumerStorageInstructions">
                  {consumerStorageInstructions}
                </Paragraph>
              )}
            </InfoBoxDetailsWrapper>
          </InfoBoxBlock>
        )}
      </InfoBoxInnerWrapper>
    </InformationBox>
  );
};

export default InfoBoxListing;
