import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import media from '@constants/responsive';
import Heading from 'components/atoms/__DEPRECATED__/Heading';
import { rem } from 'polished';
import styled from 'styled-components';

interface PromotionLabelWrapperProps {
  $hasLongLabel?: boolean;
}
interface ProductInfoProps {
  $textTransform?: 'capitalize' | 'lowercase';
}

export const PromotionLabelWrapper = styled.div<PromotionLabelWrapperProps>`
  display: flex;
  height: ${rem(18)};
  align-items: baseline;
  margin: 0;
  color: ${({ theme }) => theme.colors.colorTextPrimary};

  ${(props) =>
    props.$hasLongLabel &&
    `
      ${media.tabletPortrait} {
        flex-direction: column;
        align-items: unset;
        margin-bottom: 20px;
      }
      ${media.desktop} {
        flex-direction: row;
        align-items: center;
        margin-bottom: unset;
      }
    }
  `}
`;

export const PromotionLabel = styled.div`
  margin-right: ${rem(15)};
`;

export const ProductTitle = styled(Heading)`
  font-family: ${({ theme }) => theme.typography.fontSans};
  margin-top: ${rem(6)};
`;

export const ProductManufacturer = styled.span`
  text-transform: uppercase;
  margin: ${rem(7)} 0;
  ${media.tabletPortrait} {
    margin: ${rem(4)} 0 ${rem(8)};
  }
`;

export const ProductInfo = styled.span<ProductInfoProps>`
  text-transform: ${({ $textTransform }) => $textTransform || 'none'};
`;

export const ProductComparePrices = styled.div`
  min-height: ${rem(54)};
`;

export const Discount = styled.div`
  color: ${({ theme }) => theme.colors.colorPrimary};
`;
export const PromotionValidDate = styled.span`
  display: block;
  font-size: ${rem(14)};
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const EnergyDeclarationLabelWrapper = styled.div`
  min-height: ${rem(36)};
`;

export const StyledParagraph = styled(Paragraph)`
  *:not(:last-child):after {
    content: ', ';
  }
`;
