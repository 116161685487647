import { rem } from 'polished';
import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  text-align: left;
  margin: ${rem(15)} 0;
  display: flex;
  justify-content: space-between;
`;

export const BodyTextWrapper = styled.div`
  margin: 0 ${rem(18)};
`;

export const ShowMoreButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${rem(15)};
`;

export const HeaderTitleWrapper = styled.div`
  flex: 1;
`;
