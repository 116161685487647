import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.fieldset`
  border: none;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
`;

export const DefaultLabel = styled.label<{ $required?: boolean }>`
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  display: flex;
  align-items: center;
  ${({ $required }) =>
    $required &&
    css`
      &:after {
        content: ' *';
        display: inline;
      }
    `}
`;

export const StyledTextArea = styled.textarea`
  resize: none;
  margin: ${rem(8)} 0;
  min-height: ${rem(156)};
  padding: ${rem(16)} ${rem(11)};
  border-radius: 0;
  border: ${rem(1)} solid ${({ theme }) => theme.colors.colorTextPrimary};
  &::placeholder {
    color: ${({ theme }) => theme.colors.colorTextPrimary};
    opacity: 0.75;
  }

  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.colors.colorPrimary};
    outline: none;
  }
`;

export const Error = styled.span`
  font-size: ${rem(12)};
  color: ${({ theme }) => theme.colors.colorPrimary};
  line-height: ${rem(15)};
`;
