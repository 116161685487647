import * as types from '@actions/delivery/types';
import shouldSetActiveStore from '@helpers/shouldSetActiveStore';

export interface DeliveryState {
  modal: {
    visible: boolean;
  };
  active?: StoreType;
  stores: Array<StoreState>;
  deliveryCosts: DeliveryCosts;
}

const getDeliveryCosts = (store?: StoreType): DeliveryCosts => {
  const {
    storeId = '',
    b2bPickingCostForDelivery = '',
    b2bDeliveryCost = '',
    freeB2BPickingCostThresholdForDeliveryFormatted = '',
    b2bFreeDeliveryThresholdFormatted = '',
    deliveryCost = '',
    pickingCostForDelivery = '',
    freeDeliveryThresholdFormatted = '',
    freePickingCostThresholdForDeliveryFormatted = '',
  } = store || {};
  return {
    storeId,
    b2bPickingCostForDelivery,
    b2bDeliveryCost,
    freeB2BPickingCostThresholdForDeliveryFormatted,
    b2bFreeDeliveryThresholdFormatted,
    deliveryCost,
    pickingCostForDelivery,
    freeDeliveryThresholdFormatted,
    freePickingCostThresholdForDeliveryFormatted,
  };
};

const initialState: DeliveryState = {
  modal: {
    visible: false,
  },
  active: undefined,
  stores: [],
  deliveryCosts: getDeliveryCosts(),
};

const deliveryReducer = (state = initialState, action: any): DeliveryState => {
  switch (action.type) {
    case types.OPEN_DELIVERY_MODAL:
      return { ...state, modal: { ...state.modal, visible: true } };
    case types.CLOSE_DELIVERY_MODAL:
      return { ...state, modal: { ...state.modal, visible: false } };
    case types.SET_STORES:
      return { ...state, stores: action.stores };
    case types.SET_ACTIVE_STORE:
      return {
        ...state,
        active: shouldSetActiveStore(action.active) ? action.active : initialState.active,
      };
    case types.SET_DELIVERY_COSTS:
      return {
        ...state,
        deliveryCosts: getDeliveryCosts(action.active),
      };
    default:
      return state;
  }
};

export default deliveryReducer;
