export const getCurrentTime = () => {
  const now = new Date();
  const currentTime = now.toLocaleTimeString('sv-SE', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  return currentTime;
};

export const getCurrentDate = () => {
  const today = new Date().toISOString().slice(0, 10);
  return today;
};

const getCurrentDateAndTime = { getCurrentTime, getCurrentDate };

export default getCurrentDateAndTime;
