import Icon, { IconType } from '@components/atoms/Icon/Icon';
import classnames from 'classnames';
import React, { ReactElement } from 'react';
import { RoundButton } from './RoundIconButton.styles';

export interface RoundIconButtonProps {
  icon: IconType;
  onClick: (e: React.SyntheticEvent) => void;
  disabled?: boolean;
  color?: ColorType;
  title?: string;
  className?: string;
  buttonVariant?: 'none' | 'grey' | 'white';
  'data-testid'?: string;
  iconSize?: number;
  'aria-label'?: string;
}

const RoundIconButton = ({
  icon,
  onClick,
  disabled = false,
  color = 'black',
  title,
  className = 'RoundIconButton',
  buttonVariant = 'none',
  'data-testid': dataTestId = 'round-icon-button',
  iconSize = 34,
  'aria-label': ariaLabel,
}: RoundIconButtonProps): ReactElement => {
  const baseClasses = classnames(className);
  return (
    <RoundButton
      type="button"
      className={baseClasses}
      onClick={onClick}
      disabled={disabled}
      title={title}
      aria-label={ariaLabel}
      $buttonVariant={buttonVariant}
      data-testid={dataTestId}
    >
      <Icon icon={icon} color={color} size={iconSize} data-testid={icon.name} />
    </RoundButton>
  );
};

export default RoundIconButton;
