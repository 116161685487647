import TagManager from 'react-gtm-module';

export const carouselShiftedEvent = () => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: `/`,
      action: 'carousel_shifted',
      label: 'herobanner',
      value: 0,
    },
  });
};
