import Paragraph from '@components/atoms/Paragraph';
import useTranslation from 'next-translate/useTranslation';
import { TextContainer } from './ProductQuantity.styles';

const OutOfStock = () => {
  const { t } = useTranslation('product');

  return (
    <TextContainer data-testid="out-of-stock">
      <Paragraph size="medium" weight="bold">
        {t('quantity->outOfStock')}
      </Paragraph>
    </TextContainer>
  );
};

export default OutOfStock;
