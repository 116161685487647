const CAMPAIGN_TYPE = {
  LOYALTY: 'LOYALTY',
  GENERAL: 'GENERAL',
  SEGMENTED: 'SEGMENTED',
  PERSONAL_GENERAL: 'PERSONAL_GENERAL',
  PERSONAL_SEGMENTED: 'PERSONAL_SEGMENTED',
};

export type CampaignType =
  | typeof CAMPAIGN_TYPE.GENERAL
  | typeof CAMPAIGN_TYPE.LOYALTY
  | typeof CAMPAIGN_TYPE.PERSONAL_GENERAL
  | typeof CAMPAIGN_TYPE.PERSONAL_SEGMENTED
  | typeof CAMPAIGN_TYPE.SEGMENTED;

export default CAMPAIGN_TYPE;
