import useSWRImmutable from 'swr/immutable';

export type Config = {
  PUBLIC_HOST: string;
  GTM_YYYYYY: string;
  GTM_XXXXXX: string;
  MAPBOX_ACCESS_TOKEN: string;
  RECAPTCHA_KEY: string;
  [key: string]: string;
};

const fetcher = () => fetch('/api/config').then((res): Promise<Config> => res.json());

const useConfig = () => {
  const { data } = useSWRImmutable('/api/config', fetcher);

  return {
    config: data,
  };
};
export default useConfig;
