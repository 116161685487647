export const ONBOARDING_ERROR_CONNECTION = 'createaccount.connection.error';
export const ONBOARDING_ERROR_CONNECTION_TRANS_KEY = 'createaccountConnection';
// if ax-crm is not responding
export const ONBOARDING_ERROR_CHECK_CUSTOMER = 'checkcustomer.ErrorMessage.error'; // also if ax-crm is not responding.. this response error is a plain string
export const ONBOARDING_ERROR_CHECK_CUSTOMER_TRANS_KEY = 'checkcustomerError';

export const ONBOARDING_ERROR_AGE = 'register.socialSecurityNumber.age';
export const ONBOARDING_ERROR_AGE_TRANS_KEY = 'registerSocialSecurityNumberAge';

export const ONBOARDING_ERROR_SMS_TOKEN_INVALID = 'register.smsToken.invalid';
export const ONBOARDING_ERROR_SMS_TOKEN_INVALID_TRANS_KEY = 'registerSmsTokenInvalid';

export const ONBOARDING_ERROR_CAPTCHA_INVALID = 'register.captcha.invalid';
export const ONBOARDING_ERROR_CAPTCHA_INVALID_TRANS_KEY = 'registerCaptchaInvalid';

export const ONBOARDING_ERROR_PHONE_NO_MATCH = 'register.phone.noMatch';
export const ONBOARDING_ERROR_PHONE_NO_MATCH_TRANS_KEY = 'registerPhoneNoMatch';

export const ONBOARDING_ERROR_BACKEND_DEFAULT = 'createaccount.ErrorMessage.error'; // default be error response
export const ONBOARDING_ERROR_BACKEND_DEFAULT_TRANS_KEY = 'createaccount';

export const ONBOARDING_ERROR_SSN_INVALID_FORMAT = 'register.socialSecurityNumber.invalidFormat';
export const ONBOARDING_ERROR_SSN_INVALID_FORMAT_TRANS_KEY = 'registerSocialSecurityNumberInvalidFormat';

export const ONBOARDING_ERROR_SSN_INVALID = 'register.ssn.invalid';
export const ONBOARDING_ERROR_SSN_INVALID_TRANS_KEY = 'registerSsnInvalid';

export const ONBOARDING_ERROR_ORG_NUMBER_INVALID = 'register.orgNumber.invalid';
export const ONBOARDING_ERROR_ORG_NUMBER_INVALID_TRANS_KEY = 'registerOrgNumberInvalid';

export const ONBOARDING_ERROR_SSN_NOT_FOUND = 'register.socialSecurityNumber.noMatch';
export const ONBOARDING_ERROR_SSN_NOT_FOUND_TRANS_KEY = 'registerSocialSecurityNumberNoMatch';

export const ONBOARDING_ERROR_ID_IN_SOURCE_SYSTEM_NOT_FOUND = 'register.idInSourceSystem.noMatch';
export const ONBOARDING_ERROR_ID_IN_SOURCE_SYSTEM_NOT_FOUND_TRANS_KEY = 'registerIdInSourceSystemNoMatch';

export const ONBOARDING_ERROR_ORG_NUMBER_NOT_EXIST = 'bisnode.organisationnumber.not.exists';
export const ONBOARDING_ERROR_ORG_NUMBER_NOT_EXIST_TRANS_KEY = 'bisnodeOrganisationnumberNotExists';

export const ONBOARDING_ERROR_ORG_NUMBER_TOO_MANY = 'bisnode.to.many.companies.found';
export const ONBOARDING_ERROR_ORG_NUMBER_TOO_MANY_TRANS_KEY = 'bisnodeToManyCompaniesFound';

export const ONBOARDING_ERROR_ORG_NUMBER_INVALID_BISNODE = 'bisnode.invalid.organisationnumber';
export const ONBOARDING_ERROR_ORG_NUMBER_INVALID_BISNODE_TRANS_KEY = 'bisnodeInvalidOrganisationnumber';

export const BISNODE_DENIAL = 'bisnode.denial';
export const BISNODE_DENIAL_TRANS_KEY = 'bisnodeDenial';

export const ONBOARDING_ERROR_CUSTOMER_NOT_FOUND = 'lostPassword.notFound';
export const ONBOARDING_ERROR_CUSTOMER_NOT_FOUND_TRANS_KEY = 'lostPasswordNotFound';

export const ONBOARDING_ERROR_CUSTOMER_NOT_FOUND_B2B = 'lostPassword.notFound.b2b';
export const ONBOARDING_ERROR_CUSTOMER_NOT_FOUND_B2B_TRANS_KEY = 'lostPasswordNotFoundB2B';

export const ONBOARDING_ERROR_EMAIL_NOT_FOUND = 'lostPassword.email.notFound';
export const ONBOARDING_ERROR_EMAIL_NOT_FOUND_TRANS_KEY = 'lostPasswordEmailNotFound';

export const ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_INVALID = 'updatePwd.smsToken.invalid';
export const ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_INVALID_TRANS_KEY = 'updatePwdSmsTokenInvalid';

export const ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_EXPIRED = 'updatePwd.smsToken.expired';
export const ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_EXPIRED_TRANS_KEY = 'updatePwdSmsTokenExpired';

export const ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALID = 'updatePwd.token.invalid';
export const ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALID_TRANS_KEY = 'updatePwdTokenInvalid';

export const ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_EXPIRED = 'updatePwd.token.expired';
export const ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_EXPIRED_TRANS_KEY = 'updatePwdTokenExpired';

export const ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALIDATED = 'updatePwd.token.invalidated';
export const ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALIDATED_TRANS_KEY = 'updatePwdTokenInvalidated';

export const ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_NOMATCH = 'register.email.noMatch';
export const ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_NOMATCH_TRANS_KEY = 'registerEmailNoMatch';

export const ONBOARDING_ERROR_UNKNOWN = 'unknown';
export const ONBOARDING_ERROR_UNKNOWN_TRANS_KEY = 'unknown';

export const VALID_ERROR_KEYS = [
  'connection',
  'socialSecurityNumber',
  'smsToken',
  'captcha',
  'phone',
  'ErrorMessage',
  'ssn',
  'orgNumber',
  'error',
  'idInSourceSystem',
  'email',
  'token',
];

export const ONBOARDING_ERRORS = [
  ONBOARDING_ERROR_CONNECTION,
  ONBOARDING_ERROR_AGE,
  ONBOARDING_ERROR_SMS_TOKEN_INVALID,
  ONBOARDING_ERROR_CAPTCHA_INVALID,
  ONBOARDING_ERROR_PHONE_NO_MATCH,
  ONBOARDING_ERROR_BACKEND_DEFAULT,
  ONBOARDING_ERROR_SSN_INVALID_FORMAT,
  ONBOARDING_ERROR_UNKNOWN,
  ONBOARDING_ERROR_ORG_NUMBER_INVALID,
  ONBOARDING_ERROR_SSN_INVALID,
  ONBOARDING_ERROR_ORG_NUMBER_TOO_MANY,
  ONBOARDING_ERROR_ORG_NUMBER_NOT_EXIST,
  ONBOARDING_ERROR_ORG_NUMBER_INVALID_BISNODE,
  ONBOARDING_ERROR_SSN_NOT_FOUND,
  ONBOARDING_ERROR_ID_IN_SOURCE_SYSTEM_NOT_FOUND,
  ONBOARDING_ERROR_CUSTOMER_NOT_FOUND,
  ONBOARDING_ERROR_CUSTOMER_NOT_FOUND_B2B,
  ONBOARDING_ERROR_EMAIL_NOT_FOUND,
  ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_INVALID,
  ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALID,
  ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_EXPIRED,
  ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALIDATED,
  ONBOARDING_ERROR_CHECK_CUSTOMER,
  BISNODE_DENIAL,
  ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_NOMATCH,
  ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_EXPIRED,
] as const;

export type OnboardingErrorType = typeof ONBOARDING_ERRORS[number];

export const ONBOARDING_ERRORS_TRANS_KEYS = [
  ONBOARDING_ERROR_CONNECTION_TRANS_KEY,
  ONBOARDING_ERROR_AGE_TRANS_KEY,
  ONBOARDING_ERROR_SMS_TOKEN_INVALID_TRANS_KEY,
  ONBOARDING_ERROR_CAPTCHA_INVALID_TRANS_KEY,
  ONBOARDING_ERROR_PHONE_NO_MATCH_TRANS_KEY,
  ONBOARDING_ERROR_BACKEND_DEFAULT_TRANS_KEY,
  ONBOARDING_ERROR_SSN_INVALID_FORMAT_TRANS_KEY,
  ONBOARDING_ERROR_UNKNOWN_TRANS_KEY,
  ONBOARDING_ERROR_ORG_NUMBER_INVALID_TRANS_KEY,
  ONBOARDING_ERROR_SSN_INVALID_TRANS_KEY,
  ONBOARDING_ERROR_ORG_NUMBER_TOO_MANY_TRANS_KEY,
  ONBOARDING_ERROR_ORG_NUMBER_NOT_EXIST_TRANS_KEY,
  ONBOARDING_ERROR_ORG_NUMBER_INVALID_BISNODE_TRANS_KEY,
  ONBOARDING_ERROR_SSN_NOT_FOUND_TRANS_KEY,
  ONBOARDING_ERROR_ID_IN_SOURCE_SYSTEM_NOT_FOUND_TRANS_KEY,
  ONBOARDING_ERROR_CUSTOMER_NOT_FOUND_TRANS_KEY,
  ONBOARDING_ERROR_CUSTOMER_NOT_FOUND_B2B_TRANS_KEY,
  ONBOARDING_ERROR_EMAIL_NOT_FOUND_TRANS_KEY,
  ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_INVALID_TRANS_KEY,
  ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALID_TRANS_KEY,
  ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_EXPIRED_TRANS_KEY,
  ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALIDATED_TRANS_KEY,
  ONBOARDING_ERROR_CHECK_CUSTOMER_TRANS_KEY,
  BISNODE_DENIAL_TRANS_KEY,
  ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_NOMATCH_TRANS_KEY,
  ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_EXPIRED_TRANS_KEY,
] as const;

export type OnboardingErrorTransKeyType = typeof ONBOARDING_ERRORS_TRANS_KEYS[number];

type BackendToTranslationKeyType = {
  [ONBOARDING_ERROR_CONNECTION]: typeof ONBOARDING_ERROR_CONNECTION_TRANS_KEY;
  [ONBOARDING_ERROR_AGE]: typeof ONBOARDING_ERROR_AGE_TRANS_KEY;
  [ONBOARDING_ERROR_SMS_TOKEN_INVALID]: typeof ONBOARDING_ERROR_SMS_TOKEN_INVALID_TRANS_KEY;
  [ONBOARDING_ERROR_CAPTCHA_INVALID]: typeof ONBOARDING_ERROR_CAPTCHA_INVALID_TRANS_KEY;
  [ONBOARDING_ERROR_PHONE_NO_MATCH]: typeof ONBOARDING_ERROR_PHONE_NO_MATCH_TRANS_KEY;
  [ONBOARDING_ERROR_BACKEND_DEFAULT]: typeof ONBOARDING_ERROR_BACKEND_DEFAULT_TRANS_KEY;
  [ONBOARDING_ERROR_SSN_INVALID_FORMAT]: typeof ONBOARDING_ERROR_SSN_INVALID_FORMAT_TRANS_KEY;
  [ONBOARDING_ERROR_UNKNOWN]: typeof ONBOARDING_ERROR_UNKNOWN_TRANS_KEY;
  [ONBOARDING_ERROR_ORG_NUMBER_INVALID]: typeof ONBOARDING_ERROR_ORG_NUMBER_INVALID_TRANS_KEY;
  [ONBOARDING_ERROR_SSN_INVALID]: typeof ONBOARDING_ERROR_SSN_INVALID_TRANS_KEY;
  [ONBOARDING_ERROR_ORG_NUMBER_TOO_MANY]: typeof ONBOARDING_ERROR_ORG_NUMBER_TOO_MANY_TRANS_KEY;
  [ONBOARDING_ERROR_ORG_NUMBER_NOT_EXIST]: typeof ONBOARDING_ERROR_ORG_NUMBER_NOT_EXIST_TRANS_KEY;
  [ONBOARDING_ERROR_ORG_NUMBER_INVALID_BISNODE]: typeof ONBOARDING_ERROR_ORG_NUMBER_INVALID_BISNODE_TRANS_KEY;
  [ONBOARDING_ERROR_SSN_NOT_FOUND]: typeof ONBOARDING_ERROR_SSN_NOT_FOUND_TRANS_KEY;
  [ONBOARDING_ERROR_ID_IN_SOURCE_SYSTEM_NOT_FOUND]: typeof ONBOARDING_ERROR_ID_IN_SOURCE_SYSTEM_NOT_FOUND_TRANS_KEY;
  [ONBOARDING_ERROR_CUSTOMER_NOT_FOUND]: typeof ONBOARDING_ERROR_CUSTOMER_NOT_FOUND_TRANS_KEY;
  [ONBOARDING_ERROR_CUSTOMER_NOT_FOUND_B2B]: typeof ONBOARDING_ERROR_CUSTOMER_NOT_FOUND_B2B_TRANS_KEY;
  [ONBOARDING_ERROR_EMAIL_NOT_FOUND]: typeof ONBOARDING_ERROR_EMAIL_NOT_FOUND_TRANS_KEY;
  [ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_INVALID]: typeof ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_INVALID_TRANS_KEY;
  [ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALID]: typeof ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALID_TRANS_KEY;
  [ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_EXPIRED]: typeof ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_EXPIRED_TRANS_KEY;
  [ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALIDATED]: typeof ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALIDATED_TRANS_KEY;
  [ONBOARDING_ERROR_CHECK_CUSTOMER]: typeof ONBOARDING_ERROR_CHECK_CUSTOMER_TRANS_KEY;
  [BISNODE_DENIAL]: typeof BISNODE_DENIAL_TRANS_KEY;
  [ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_NOMATCH]: typeof ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_NOMATCH_TRANS_KEY;
  [ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_EXPIRED]: typeof ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_EXPIRED_TRANS_KEY;
};

export const backendToTranslationKey: BackendToTranslationKeyType = {
  [ONBOARDING_ERROR_CONNECTION]: ONBOARDING_ERROR_CONNECTION_TRANS_KEY,
  [ONBOARDING_ERROR_AGE]: ONBOARDING_ERROR_AGE_TRANS_KEY,
  [ONBOARDING_ERROR_SMS_TOKEN_INVALID]: ONBOARDING_ERROR_SMS_TOKEN_INVALID_TRANS_KEY,
  [ONBOARDING_ERROR_CAPTCHA_INVALID]: ONBOARDING_ERROR_CAPTCHA_INVALID_TRANS_KEY,
  [ONBOARDING_ERROR_PHONE_NO_MATCH]: ONBOARDING_ERROR_PHONE_NO_MATCH_TRANS_KEY,
  [ONBOARDING_ERROR_BACKEND_DEFAULT]: ONBOARDING_ERROR_BACKEND_DEFAULT_TRANS_KEY,
  [ONBOARDING_ERROR_SSN_INVALID_FORMAT]: ONBOARDING_ERROR_SSN_INVALID_FORMAT_TRANS_KEY,
  [ONBOARDING_ERROR_UNKNOWN]: ONBOARDING_ERROR_UNKNOWN_TRANS_KEY,
  [ONBOARDING_ERROR_ORG_NUMBER_INVALID]: ONBOARDING_ERROR_ORG_NUMBER_INVALID_TRANS_KEY,
  [ONBOARDING_ERROR_SSN_INVALID]: ONBOARDING_ERROR_SSN_INVALID_TRANS_KEY,
  [ONBOARDING_ERROR_ORG_NUMBER_TOO_MANY]: ONBOARDING_ERROR_ORG_NUMBER_TOO_MANY_TRANS_KEY,
  [ONBOARDING_ERROR_ORG_NUMBER_NOT_EXIST]: ONBOARDING_ERROR_ORG_NUMBER_NOT_EXIST_TRANS_KEY,
  [ONBOARDING_ERROR_ORG_NUMBER_INVALID_BISNODE]: ONBOARDING_ERROR_ORG_NUMBER_INVALID_BISNODE_TRANS_KEY,
  [ONBOARDING_ERROR_SSN_NOT_FOUND]: ONBOARDING_ERROR_SSN_NOT_FOUND_TRANS_KEY,
  [ONBOARDING_ERROR_ID_IN_SOURCE_SYSTEM_NOT_FOUND]: ONBOARDING_ERROR_ID_IN_SOURCE_SYSTEM_NOT_FOUND_TRANS_KEY,
  [ONBOARDING_ERROR_CUSTOMER_NOT_FOUND]: ONBOARDING_ERROR_CUSTOMER_NOT_FOUND_TRANS_KEY,
  [ONBOARDING_ERROR_CUSTOMER_NOT_FOUND_B2B]: ONBOARDING_ERROR_CUSTOMER_NOT_FOUND_B2B_TRANS_KEY,
  [ONBOARDING_ERROR_EMAIL_NOT_FOUND]: ONBOARDING_ERROR_EMAIL_NOT_FOUND_TRANS_KEY,
  [ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_INVALID]: ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_INVALID_TRANS_KEY,
  [ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALID]: ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALID_TRANS_KEY,
  [ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_EXPIRED]: ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_EXPIRED_TRANS_KEY,
  [ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALIDATED]:
    ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_TOKEN_INVALIDATED_TRANS_KEY,
  [ONBOARDING_ERROR_CHECK_CUSTOMER]: ONBOARDING_ERROR_CHECK_CUSTOMER_TRANS_KEY,
  [BISNODE_DENIAL]: BISNODE_DENIAL_TRANS_KEY,
  [ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_NOMATCH]: ONBOARDING_ERROR_CHANGE_PASSWORD_EMAIL_NOMATCH_TRANS_KEY,
  [ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_EXPIRED]: ONBOARDING_ERROR_LOST_PASSWORD_SMS_TOKEN_EXPIRED_TRANS_KEY,
};

/** B2B create */
export const CARD_PAYMENT = 'cardPayment';
export const INVOICE_PAYMENT = 'invoicePayment';

export const INVOICE_TYPE_EMAIL = 'E-post';
export const INVOICE_TYPE_EINVOICE = 'E-faktura';
export const INVOICE_TYPE_PAPER_INVOICE = 'Papper';

// Used to track entry point in order to be able to send correct values to GA4
export const ENTRY_POINT_KLUBBVARA_POPUP = 'klubbvara_popup';
export const ENTRY_POINT_MAIN_MENU = 'main_menu';
export const ENTRY_POINT_CART = 'cart';
export const ENTRY_POINT_MINICART = 'minicart';
