import { useAppSelector } from '@hooks/redux';
import AssortmentIcon from '@public/icons/regularIcons/icon-assortment.svg';
import useTranslation from 'next-translate/useTranslation';
import { selectSideNavIsOpen } from 'redux/slices/sideNav';
import SecondaryHeaderButton, { RedIconWithTextContent } from '../SecondaryHeaderButton';

const MobileAssortmentButton = ({ onClick }: { onClick?: () => void }) => {
  const { t } = useTranslation('header');
  const sideNavIsOpen = useAppSelector(selectSideNavIsOpen);

  return (
    <SecondaryHeaderButton
      aria-expanded={sideNavIsOpen}
      onClick={onClick}
      mobile
      aria-label={t('assortment->assortment')}
    >
      <RedIconWithTextContent mobile icon={AssortmentIcon} text={t('assortment->assortment')} />
    </SecondaryHeaderButton>
  );
};

export default MobileAssortmentButton;
