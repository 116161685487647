import AxfoodFooterComponent from '@components/cmsComponents/AxfoodFooterComponent';
import { FooterContentWrapper } from '@components/organisms/Footer/Footer.styles';
import useCmsLayout from '@hooks/useCmsLayout';
import { memo } from 'react';

const Footer = () => {
  const { footerSlots } = useCmsLayout();
  const [footerComponent] = footerSlots?.component || [];
  return (
    <FooterContentWrapper>
      {footerComponent && <AxfoodFooterComponent component={footerComponent as AxfoodFooterComponentType} />}
    </FooterContentWrapper>
  );
};

export default memo(Footer);
