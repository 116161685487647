import type { ContentSlotWsDTO, PageAdaptedData } from '@occ/api-client';
import { slotsLiteral } from './constants';

interface ContentSlotWsDTOWithPosition extends ContentSlotWsDTO {
  position: string;
}

interface ActualContentSlotWsDTO {
  contentSlot: Array<{
    key: string;
    value: ContentSlotWsDTOWithPosition;
  }>;
}

const isSupportedCmsPageTemplate = (template: string | undefined): template is keyof typeof slotsLiteral => {
  return typeof template === 'string' && Object.keys(slotsLiteral).includes(template);
};
//TODO: Add all layout slots
const layoutSlotList = ['HeaderLeft', 'HeaderCenter', 'NavigationLeft', 'FooterCenter', 'MessageBox'];

const getSlotsForPage = (cmsPage: PageAdaptedData) => {
  const slotsForPage = isSupportedCmsPageTemplate(cmsPage.template) ? slotsLiteral[cmsPage.template] : [];

  return (cmsPage.contentSlots as unknown as ActualContentSlotWsDTO).contentSlot
    .map((s) => s.value)
    .filter((s) => [...slotsForPage, ...layoutSlotList].includes(s.position));
};

export default getSlotsForPage;
