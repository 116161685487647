import KEYS from '@constants/keys';
import { KeyboardEvent, useCallback } from 'react';

interface Args {
  onKeyEnter: (val: string) => void;
  onKeyEscape: () => void;
  onKeyDefault?: (val: string) => void;
  currentIndex: number;
  setCurrentIndex: (i: number) => void;
  items: any[];
  shouldPreventDefault?: boolean;
}

const useListNavigationKeyHandler = ({
  onKeyEnter,
  onKeyEscape,
  onKeyDefault,
  currentIndex,
  setCurrentIndex,
  items,
  shouldPreventDefault = true,
}: Args) => {
  const navigateThroughList = useCallback(
    (e: KeyboardEvent) => {
      e.preventDefault();
      const maxCount = items.length - 1;
      let count = 0;
      if (e.key === KEYS.ARROW_DOWN) {
        count = currentIndex >= maxCount ? 0 : currentIndex + 1;
      } else if (e.key === KEYS.ARROW_UP) {
        count = currentIndex > 0 ? currentIndex - 1 : maxCount;
      }
      setCurrentIndex(count);
    },
    [currentIndex, setCurrentIndex, items]
  );

  const listNavigationKeyHandler = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (shouldPreventDefault) e.preventDefault();

      const element = e.currentTarget;
      const val = element.value;

      switch (e.key) {
        case KEYS.ENTER:
          return onKeyEnter(val);
        case KEYS.ESCAPE:
          return onKeyEscape();
        case KEYS.ARROW_UP:
          return navigateThroughList(e);
        case KEYS.ARROW_DOWN:
          return navigateThroughList(e);
        default: {
          return onKeyDefault && onKeyDefault(val);
        }
      }
    },
    [onKeyEnter, onKeyEscape, onKeyDefault, navigateThroughList, shouldPreventDefault]
  );
  return {
    listNavigationKeyHandler,
  };
};

export default useListNavigationKeyHandler;
