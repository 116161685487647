import Skeleton from '@components/atoms/Skeleton';
import LINKS from '@constants/links';
import useAppRouter from '@hooks/useAppRouter';
import { rem } from 'polished';
import styled from 'styled-components';

export const StyledSkeleton = styled(Skeleton)`
  margin: 0;
  padding: 0;
  height: ${rem(50)};
  width: 100%;
  display: block;
`;

export const AxfoodContentUspCmsComponentSkeleton = () => {
  const { pathname } = useAppRouter();

  // TODO: do not display any skeletons for this component except on /handla
  // remove this when B2C-27121 is done
  if (pathname !== LINKS.SHOP) {
    return null;
  }
  return <StyledSkeleton data-testid="axfood-content-usp-skeleton" />;
};
