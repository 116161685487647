import ProductsBeamCarousel from '@components/molecules/ProductsBeamCarousel';
import useTranslation from 'next-translate/useTranslation';
import BeamsHeading from '../BeamsHeading';
import useMixAndMatchPromotion from './useMixAndMatchPromotion';

interface MixAndMatchPropsProps {
  eventListName: string;
  promotionCode: string;
  excludedProducts: string[];
}

const MixAndMatchBeam = ({ eventListName, promotionCode, excludedProducts }: MixAndMatchPropsProps) => {
  const { t } = useTranslation('productDetails');
  const title = t('productDetails->title->samePromotion');

  const { products: mixAndMatchProducts, isLoading: isLoadingMixAndMatch } = useMixAndMatchPromotion(
    promotionCode,
    excludedProducts
  );

  if (!mixAndMatchProducts.length && !isLoadingMixAndMatch) {
    return null;
  }

  return (
    <ProductsBeamCarousel
      title={title}
      headline="headline"
      carouselProducts={mixAndMatchProducts}
      eventListName={eventListName}
      noHeaderSidePadding
      isLoading={isLoadingMixAndMatch}
      headingComponent={<BeamsHeading title={title} />}
    />
  );
};
export default MixAndMatchBeam;
