import { rem } from 'polished';

const breakpoints = {
  xxs: rem(375),
  xs: rem(620),
  sm: rem(768),
  md: rem(1024),
  lg: rem(1280),
  xl: rem(1550),
  xxl: rem(1800),
};

export type ThemeBreakpoints = typeof breakpoints;
export default breakpoints;
