import { AxfoodStoreInfoViewModel } from '@api/generated/storefront';
import Heading from '@components/atoms/Heading';
import Paragraph from '@components/atoms/Paragraph';
import Skeleton from '@components/atoms/Skeleton';
import { FlyerBannerContent } from '@features/weeklyFlyer';
import { usePromotionStore } from '@hooks/localStorage';
import useStores from '@hooks/useStores';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { Container, Content, Label, OuterWrapper, StyledChangeStore, Vector } from './AxfoodWeeklyFlyerComponent.styles';

const StoreNameSkeleton = (
  <Skeleton
    type="rect"
    width="100%"
    height="44px"
    $style={{ margin: 0 }}
    data-testid="weekly-flyer-skeleton-store-name"
  />
);

const AxfoodWeeklyFlyerComponent = () => {
  const { t } = useTranslation('axfoodWeeklyFlyerComponent');
  const [{ promotionStoreId }] = usePromotionStore();
  const { storesWithFlyer, isLoading } = useStores();
  const [selectedStore, setSelectedStore] = useState<AxfoodStoreInfoViewModel | undefined>(undefined);

  const shouldRenderContent = !isLoading || !!storesWithFlyer;

  const getInitialStore = () => {
    const promotionStoreWithFlyer = storesWithFlyer?.find((store) => store.storeId === promotionStoreId);
    const firstStoreInListWithFlyer = storesWithFlyer?.[0];
    return promotionStoreWithFlyer || firstStoreInListWithFlyer;
  };

  const initialStore = getInitialStore();
  const currentStore = selectedStore || initialStore;

  return (
    <OuterWrapper>
      <Container>
        <StyledChangeStore
          stores={storesWithFlyer}
          currentStore={currentStore}
          isLoading={isLoading}
          handleChangeStore={setSelectedStore}
        />
        <Vector />
        <Content>
          <FlyerBannerContent
            flyerLink={currentStore?.flyerURL}
            header={
              <>
                <Label>{t('label')}</Label>
                {shouldRenderContent && !!currentStore?.name ? (
                  <Heading type="h2">{currentStore?.name}</Heading>
                ) : (
                  StoreNameSkeleton
                )}
              </>
            }
            body={<Paragraph size="medium">{t('body')}</Paragraph>}
          />
        </Content>
      </Container>
    </OuterWrapper>
  );
};

export default AxfoodWeeklyFlyerComponent;
