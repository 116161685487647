import Paragraph from '@components/atoms/Paragraph';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const InfoBlock = styled.div`
  margin-top: ${rem(30)};
  &:first-child {
    margin-top: 0;
  }
`;

export const InformationBox = styled.div`
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.colorPampas};
  padding-bottom: ${rem(20)};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${rem(10)} 0;
  &:not(:first-child) {
    margin-top: ${rem(10)};
  }
  ${media.tabletPortrait} {
    padding: ${rem(30)};
    max-width: ${rem(240)};
    min-width: ${rem(240)};
    border: ${rem(2)} solid ${({ theme }) => theme.colors.colorPampas};
    margin: 0 ${rem(20)} 0 0;
  }
`;
export const GHSIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  span {
    padding: ${rem(0)} ${rem(10)} ${rem(10)} ${rem(10)};
    &:not(:first-child) {
      margin-top: ${rem(20)};
    }
  }
  svg {
    height: ${rem(65)};
    width: ${rem(65)};
  }
`;
export const GHSTextWrapper = styled.div`
  p {
    margin-top: ${rem(20)};
  }
`;
export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${rem(40)};
  ${media.tabletPortrait} {
    flex-direction: row;
    justify-content: space-between;
  }
`;
export const PrecautionsWrapper = styled.div`
  margin-top: ${rem(20)};
  p {
    word-break: break-word;
    hyphens: auto;
    white-space: normal;
  }
`;

export const MainDescription = styled.div`
  padding: ${rem(20)} 0 0 0;
  order: 2;
  max-width: ${rem(640)};
  ${media.tabletPortrait} {
    order: 1;
    padding: 0 ${rem(20)} 0 0;
  }
`;

export const TooltipTitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CategoryGroupTextWrapper = styled.div`
  p {
    span {
      // overriding inline styles received in server response
      font-size: ${rem(16)} !important;
      line-height: 1.5 !important;
    }
  }
  a:hover {
    color: ${({ theme }) => theme.colors.colorTextSecondary};
  }
`;

export const InfoBoxInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  ${InfoBlock} {
    margin-top: 0;
  }
`;
export const InfoBoxIconWrapper = styled.div`
  max-width: ${rem(40)};
  min-width: ${rem(40)};
  span {
    justify-content: flex-start;
  }
`;

export const InfoBoxWrapper = styled.div`
  order: 1;
  ${media.tabletPortrait} {
    order: 2;
    padding-top: 0;
  }
`;

export const InfoBoxDetailsWrapper = styled.div``;

export const InfoBoxBlock = styled.div`
  display: flex;
  word-break: break-word;
  &:not(:first-child) {
    margin-top: ${rem(20)};
  }
`;

export const StyledParagraph = styled(Paragraph).attrs(() => ({
  size: 'large',
}))``;
