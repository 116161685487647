import { ButtonDropdown } from '@components/atoms/__DEPRECATED__/Button';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import useOnClickOutside from '@hooks/useOnClickOutside';
import useRoveFocus from '@hooks/useRoveFocus';
import { memo, useEffect, useRef, useState } from 'react';
import { Container, Option, Options, StyledParagraph } from './Dropdown.styles';

interface DropdownOptionProps {
  isActive: boolean;
  onClick: () => void;
  name: string;
}

const DropdownOption = ({ isActive, onClick, name }: DropdownOptionProps) => {
  const ref = useRef<HTMLLIElement>(null);
  useEffect(() => {
    if (isActive && ref.current) {
      ref.current.focus();
    }
  }, [isActive]);

  const onKeyDown = (event: { key: string }) => {
    if (event.key === 'Enter') {
      onClick();
    }
  };

  return (
    <Option
      ref={ref}
      onClick={onClick}
      onKeyPress={onKeyDown}
      $isActive={isActive}
      tabIndex={isActive ? 0 : -1}
      data-testid="option"
    >
      <StyledParagraph size="body-text">{name}</StyledParagraph>
    </Option>
  );
};

interface DropdownProps {
  list: Array<any>;
  initiallySelected?: { name?: string; code: unknown };
  placeholder?: string;
  reset?: boolean;
  setReset?: (arg0: boolean) => void;
  updateActiveItem: (arg0: any) => void;
  activeItem: any;
  scrollBarVisible?: boolean;
}
interface OptionType {
  code: string;
  name: string;
  selected: boolean;
}

/***
 * @deprecated
 *  use components/atoms/Dropdown instead
 */

const Dropdown = ({
  list,
  initiallySelected,
  placeholder,
  reset,
  setReset,
  activeItem,
  updateActiveItem,
  scrollBarVisible = true,
}: DropdownProps) => {
  const [isDropdownOpen, toggleDropdown] = useState(false);
  const containerRef = useRef(null);
  const { currentFocus, setCurrentFocus } = useRoveFocus(list.length || 0, containerRef.current);

  useEffect(() => {
    if (activeItem?.code !== initiallySelected?.code) {
      initiallySelected &&
        list.forEach((item) => {
          if (item.selected) updateActiveItem(item);
          else if (initiallySelected && item === initiallySelected) updateActiveItem(item);
        });
    }
  }, []); // only set initial item on mount

  useEffect(() => {
    if (reset && setReset) {
      updateActiveItem(initiallySelected || null);
      setCurrentFocus(initiallySelected ? list.indexOf(initiallySelected) : -1);
      setReset(false);
    }
  }, [reset, setReset, updateActiveItem, initiallySelected, setCurrentFocus, list]);

  const handleOptionClick = (option: OptionType, index: number) => {
    setCurrentFocus(index);
    updateActiveItem(option);
    toggleDropdown(false);
  };

  const handleDropdownOpen = () => {
    toggleDropdown(!isDropdownOpen);
  };

  useOnClickOutside(containerRef, () => {
    toggleDropdown(false);
  });

  return (
    <Container ref={containerRef}>
      <ButtonDropdown onClick={handleDropdownOpen} isExpanded={isDropdownOpen}>
        <Paragraph size="body-text">{activeItem?.name || placeholder}</Paragraph>
      </ButtonDropdown>
      <Options $visible={isDropdownOpen} data-testid="options" $scrollBarVisible={scrollBarVisible}>
        {list.map((item, i) => {
          return (
            <DropdownOption
              key={item.code}
              onClick={() => handleOptionClick(item, i)}
              isActive={activeItem?.code === item.code || currentFocus === i}
              name={item.name}
            />
          );
        })}
      </Options>
    </Container>
  );
};

export default memo(Dropdown);
