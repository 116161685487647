import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

interface IPriceStyledProps {
  $variant: 'default' | 'detail' | 'horizontal';
  $size?: '' | 'lg' | 'sm';
}

export const PriceContainer = styled.div<IPriceStyledProps>`
  grid-area: pricecontainer;

  min-height: ${rem(27)};
  ${({ $variant }) => $variant === 'detail' && `margin-top: ${rem(8)};`}
`;

export const PriceWrapper = styled.div<IPriceStyledProps>`
  ${({ $variant }) =>
    $variant === 'detail' &&
    `
    ${media.tabletPortrait} {
      border: none;
    }
  `}
`;
