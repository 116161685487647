import React from 'react';
import { HeadingStyled } from './Heading.styles';
import { HeadingColor, HeadingSize, HeadingType } from './headingTypes';

export interface Props {
  children: React.ReactNode;
  type?: HeadingType;
  size?: HeadingSize;
  color?: HeadingColor;
  truncateText?: boolean;
  className?: string;
}

const sizeMap: Map<HeadingType, HeadingSize> = new Map([
  ['h1', 'large'],
  ['h2', 'medium'],
  ['h3', 'small'],
]);

const Heading = ({ children, type = 'h1', size, color = 'black', truncateText, className }: Props) => {
  const computedSize: HeadingSize = size || sizeMap.get(type) || 'large';
  return (
    <HeadingStyled
      className={className}
      as={type}
      $type={type}
      $size={computedSize}
      $color={color}
      $truncate={truncateText}
    >
      {children}
    </HeadingStyled>
  );
};

export default Heading;
