import TagManager from 'react-gtm-module';
import PRODUCT_UNIT from '@constants/productUnit';
import { AxfoodCartProductViewModel } from '@api/generated/storefront';

export const formattedPriceForWeightProducts = (price: number, qty: number, pickQty: number) => {
  const totalPriceForWeight = price * (qty / 1000);
  const priceOnSingleQuantity = parseFloat((totalPriceForWeight / pickQty).toFixed(3));
  return priceOnSingleQuantity;
};

export const productsToCheckoutTracking = (prod: Array<AxfoodCartProductViewModel>) =>
  prod.map((product) => {
    return {
      id: product.code,
      name: product.name,
      price:
        product.unit?.name === PRODUCT_UNIT.KILOGRAM
          ? formattedPriceForWeightProducts(product.priceValue || 0, product.quantity, product.pickQuantity)
          : product.priceValue,
      quantity: product.unit?.name === PRODUCT_UNIT.KILOGRAM ? product.pickQuantity : product.quantity,
      brand: product.manufacturer ? product.manufacturer.toLowerCase() : '',
      category: product.googleAnalyticsCategory,
    };
  });

export const trackCheckoutStep = (step: 1 | 2 | 3 | 4, prod: Array<AxfoodCartProductViewModel>) => {
  const products = productsToCheckoutTracking(prod);
  return TagManager.dataLayer({
    dataLayer: {
      event: 'checkout',
      ecommerce: {
        currencyCode: 'SEK',
        checkout: {
          actionField: { step },
          products,
        },
      },
    },
  });
};
