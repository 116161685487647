import { ButtonSize } from '@components/atoms/Button/Button';
import { memo } from 'react';
import { CategorySkeleton } from './CategoryLinks.styles';

interface CategorySkeletonProps {
  buttonSize: ButtonSize;
}
export const CategorySkeletons = ({ buttonSize = 'sm' }: CategorySkeletonProps) => {
  const height = buttonSize === 'sm' ? 32 : 40;
  return (
    <>
      <li>
        <CategorySkeleton width={88} height={height} data-testid="category-skeleton" />
      </li>
      <li>
        <CategorySkeleton width={78} height={height} data-testid="category-skeleton" />
      </li>
      <li>
        <CategorySkeleton width={65} height={height} data-testid="category-skeleton" />
      </li>
    </>
  );
};

export default memo(CategorySkeletons);
