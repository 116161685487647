import styled from 'styled-components';
import { rem } from 'polished';
import media from '@constants/responsive';
import { TabButton } from '@components/atoms/Tab/Tab.styles';

export const TabsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  ${media.tabletPortrait} {
    padding: 0 ${rem(7)} ${rem(40)};
  }
  ${media.tabletLandscape} {
    padding: 0 ${rem(54)} ${rem(40)};
  }
  ${media.desktop} {
    padding: 0 ${rem(58)} ${rem(40)};
  }
  ${TabButton} {
    margin: ${rem(20)} ${rem(2)} 0;
    padding: 0;
  }
`;
export const TabsContentWrapper = styled.div`
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  ${media.tabletPortrait} {
    padding: 0 ${rem(7)} ${rem(40)};
  }
  ${media.tabletLandscape} {
    padding: 0 ${rem(54)} ${rem(40)};
  }
  ${media.desktop} {
    padding: 0 ${rem(58)} ${rem(40)};
  }
`;
