import { AxfoodPromotionViewModel } from '@api/generated/storefront';
import { getPromotionPriceFromRewardLabel } from '@helpers/promotions';
import { useEffect, useState } from 'react';
import {
  PromotionSplashPriceText,
  PromotionSplashPriceWrapper,
  PromotionSplashSavePrice,
  PromotionSplashSavePriceTail,
} from './PromotionSplash.styles';

interface Props<Type> {
  product: ProductVariantType;
}

interface ProductViewModel {
  potentialPromotions: Array<AxfoodPromotionViewModel>;
  priceNoUnit?: string;
  priceUnit?: string;
}

interface xForYOfferProps {
  conditionLabel: string;
  hasSavePriceLabel: boolean;
  promotionPrice: string;
  price: string;
  promotionTail: string;
}
interface percentageOfferProps {
  promotionPrice: string;
  promotionSymbol: string;
  promotionTail: string;
}
interface generalLowerPriceOfferProps {
  promotionCompactPrice: string | undefined;
  unit: string;
}
interface giftOfferProps {
  rewardLabel: AxfoodPromotionViewModel['rewardLabel'];
}

const XForYOffer = ({ conditionLabel, hasSavePriceLabel, promotionPrice, price, promotionTail }: xForYOfferProps) => {
  return (
    <PromotionSplashSavePrice>
      <PromotionSplashPriceText>{conditionLabel}</PromotionSplashPriceText>
      <span data-testid="price">{hasSavePriceLabel ? promotionPrice : price}</span>
      <PromotionSplashSavePriceTail>{promotionTail}</PromotionSplashSavePriceTail>
    </PromotionSplashSavePrice>
  );
};
const PercentageOffer = ({ promotionPrice, promotionSymbol, promotionTail }: percentageOfferProps) => {
  return (
    <PromotionSplashSavePrice>
      <PromotionSplashPriceText>{`${promotionPrice} ${promotionSymbol}`}</PromotionSplashPriceText>
      <span data-testid="price-tail">{promotionTail}</span>
    </PromotionSplashSavePrice>
  );
};
const GeneralLowerPriceOffer = ({ promotionCompactPrice, unit }: generalLowerPriceOfferProps) => {
  return (
    <PromotionSplashSavePrice>
      <PromotionSplashPriceText>{promotionCompactPrice}</PromotionSplashPriceText>
      <span data-testid="price-unit">{`/${unit}`}</span>
    </PromotionSplashSavePrice>
  );
};
const GiftOffer = ({ rewardLabel }: giftOfferProps) => {
  return (
    <PromotionSplashSavePrice>
      <span>{rewardLabel}</span>
    </PromotionSplashSavePrice>
  );
};

const PromotionSplashPrice = <Type extends ProductViewModel>({ product }: Props<Type>) => {
  const price = product.price;
  const unit = product?.priceUnit?.split('/')?.[1] || '';
  const promotion = product.potentialPromotions?.[0];
  const hasSavePriceLabel = !!promotion?.conditionLabel?.length;
  const [conditionLabel, setConditionLabel] = useState('');

  const { promotionPrice, promotionTail, promotionCompactPrice, promotionSymbol } = getPromotionPriceFromRewardLabel(
    promotion?.rewardLabel
  );

  useEffect(() => {
    if (promotion?.conditionLabelFormatted?.length) {
      setConditionLabel(promotion.conditionLabelFormatted);
    } else {
      setConditionLabel(promotion?.conditionLabel || '');
    }
  }, [promotion]);

  const isXForYOffer = hasSavePriceLabel;
  const isPercentageOffer = promotionSymbol === '%';
  const isGiftOffer = !hasSavePriceLabel && promotionSymbol !== '%' && !promotionPrice;
  const isGeneralLowerPriceOffer = !hasSavePriceLabel && promotionSymbol !== '%' && promotionPrice;

  return (
    <PromotionSplashPriceWrapper>
      {isXForYOffer && (
        <XForYOffer
          conditionLabel={conditionLabel}
          hasSavePriceLabel={hasSavePriceLabel}
          promotionPrice={promotionPrice}
          price={price || ''}
          promotionTail={promotionTail}
        />
      )}
      {isPercentageOffer && (
        <PercentageOffer
          promotionPrice={promotionPrice}
          promotionSymbol={promotionSymbol}
          promotionTail={promotionTail}
        />
      )}
      {isGeneralLowerPriceOffer && (
        <GeneralLowerPriceOffer promotionCompactPrice={promotionCompactPrice} unit={unit || ''} />
      )}
      {isGiftOffer && <GiftOffer rewardLabel={promotion?.rewardLabel} />}
    </PromotionSplashPriceWrapper>
  );
};
export default PromotionSplashPrice;
