import { useCallback, useEffect, useState } from 'react';

function useRoveFocus(size: number, element: HTMLDivElement | null) {
  const [currentFocus, setCurrentFocus] = useState<number>(-1);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown') {
        event.preventDefault();
        setCurrentFocus(currentFocus === size - 1 ? 0 : currentFocus + 1);
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        setCurrentFocus(currentFocus === 0 ? size - 1 : currentFocus - 1);
      }
    },
    [size, currentFocus, setCurrentFocus]
  );

  useEffect(() => {
    element && element.addEventListener('keydown', handleKeyDown, false);
    return () => {
      element && element.removeEventListener('keydown', handleKeyDown, false);
    };
  }, [handleKeyDown, element]);

  return { currentFocus, setCurrentFocus };
}

export default useRoveFocus;
