import Icon, { IconType } from '@components/atoms/Icon/Icon';
import { useEffect, useRef } from 'react';
import { HighlightedText, ListItem, ListItemInner, SuggestionLink } from './Autocomplete.styles';

export interface ItemProps {
  searchTerm: string;
  suggestionIndex?: number;
  onClick: (term: string, suggestion: Suggestion) => void;
  highlightOnHover: boolean;
  boldSearchTermSubstring: boolean;
  focus: boolean;
  index: number;
  searchableTerm: string;
  suggestion: Suggestion;
  setRefSuggestion: (element: HTMLLIElement) => void;
  shouldShowItemIcon?: boolean;
  iconName?: IconType;
  disabled?: boolean;
  scrollItem: boolean;
  alternativeDisplayTerm?: string | undefined;
}

const AutocompleteItem = ({
  focus,
  index,
  searchableTerm,
  suggestionIndex,
  highlightOnHover,
  suggestion,
  boldSearchTermSubstring,
  searchTerm,
  onClick,
  setRefSuggestion,
  shouldShowItemIcon,
  iconName,
  disabled = false,
  scrollItem,
  alternativeDisplayTerm,
}: ItemProps) => {
  const ref = useRef<HTMLLIElement>(null);

  const getHighlightedText = (suggestionText: string, searchWord: string) => {
    const regex = new RegExp(searchWord, 'i');
    const fragments = suggestionText.split(regex);
    return fragments.map((fragment, i) => {
      const isLastItem = i === fragments.length - 1;
      if (isLastItem) {
        return <span key={`${searchWord}-${i}`}>{fragment}</span>;
      }
      return (
        <span key={`${searchWord}-${i}`} style={{ textTransform: 'capitalize' }}>
          <span>{fragment}</span>
          <strong>{searchWord}</strong>
        </span>
      );
    });
  };

  useEffect(() => {
    if (ref.current && index === suggestionIndex) {
      setRefSuggestion(ref.current);
      scrollItem && ref.current.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }, [ref, index, setRefSuggestion, suggestionIndex, scrollItem]);

  const onClickHandler = () => {
    if (!disabled) {
      onClick(searchableTerm, suggestion);
    }
  };

  const showAlternativeTerm = alternativeDisplayTerm ? alternativeDisplayTerm : searchableTerm;
  return (
    <ListItem
      tabIndex={focus ? 0 : -1}
      key={searchTerm}
      data-value={searchableTerm}
      $highlight={index === suggestionIndex || focus}
      $highlightOnHover={highlightOnHover && !focus}
      ref={ref}
      $disabled={disabled}
      data-testid={`item-${disabled ? 'disabled' : 'enabled'}`}
    >
      <ListItemInner onClick={onClickHandler} data-testid="suggestion">
        <div>
          <SuggestionLink>
            {boldSearchTermSubstring ? (
              <HighlightedText>{getHighlightedText(showAlternativeTerm, searchTerm)}</HighlightedText>
            ) : (
              <HighlightedText>{showAlternativeTerm}</HighlightedText>
            )}
          </SuggestionLink>
        </div>
        {shouldShowItemIcon && iconName && (
          <div>
            <Icon icon={iconName} data-testid="itemIcon" />
          </div>
        )}
      </ListItemInner>
    </ListItem>
  );
};

export default AutocompleteItem;
