/**
 * Function used in AngularJS repo to encode URLs.
 *
 * @param string
 */

import { CreditCardPaymentInfoData } from '@api/generated/storefront';

export const slugify = (string: string): string =>
  string
    .replace(/[áàäâãå]/gi, 'a')
    .replace(/[éèëê]/gi, 'e')
    .replace(/[íìïî]/gi, 'i')
    .replace(/[óòöôõ]/gi, 'o')
    .replace(/[úùüû]/gi, 'u')
    .replace(/%/g, 'procent')
    .replace(/&/g, 'och')
    .replace(/[^a-zA-Z0-9]/g, '-')
    .replace(/\*-/g, '-');

export const capitalizeFirst = (string: string) =>
  typeof string === 'string' ? `${string.charAt(0).toUpperCase()}${string.slice(1)}` : '';

export const capitalizeFirstAndLowerCaseRest = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

/**
 * @summary flattens an array of strings with slashes
 * @param string
 */
export const flattenString = (string: string[] | string | undefined): string =>
  (Array.isArray(string) ? string.join('/') : string) || '';

/**
 * @summary replaces all spaces and dashes in a string
 * @param str string to replace
 */
export const removeSpacesAndDashes = (str: string) => str.replace(/-|\s/g, '');

export const removeSwedishCountryCode = (str: string) => str.replace(/^\+46/, '0');

/**
 * @summary returns a search term without filters
 */
export const getQueryWithoutFilters = (query?: string | string[]) => {
  return typeof query === 'string' ? query.split(':')[0] : '';
};

/**
 * @description Incoming value for this property looks awful. Adds some letter-spacing, making this more readable
 */
export const formatMaskedCardNumber = (maskedNumber?: CreditCardPaymentInfoData['maskedNumber']) => {
  if (!maskedNumber) return '';
  const str = `${maskedNumber.slice(0, 4)} **** **** ${maskedNumber.slice(-4)}`;
  return str.split('').join('\u200A'.repeat(2));
};

export const formatStoreName = (store: string | undefined) => store?.replace('Hemköp', '')?.trim();

export const negative = (price: string) => `-${price}`;

const stringHelpers = {
  slugify,
  capitalizeFirst,
  flattenString,
  removeSpacesAndDashes,
  removeSwedishCountryCode,
  capitalizeFirstAndLowerCaseRest,
  getQueryWithoutFilters,
  formatMaskedCardNumber,
  formatStoreName,
  negative,
};

export default stringHelpers;
