import ProductBreadcrumbs from '@components/molecules/Breadcrumbs/ProductBreadcrumbs';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(32)};
  ${media.tabletPortrait} {
    gap: ${rem(40)};
  }
`;
export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledProductBreadCrumbs = styled(ProductBreadcrumbs)`
  display: flex;
  flex-wrap: wrap;
`;

export const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.tabletPortrait} {
    flex-direction: row;
    gap: ${rem(16)};
    align-items: baseline;
  }
`;
// TODO: Should be made into a reusable atom component called Label.
export const AmountOfProducts = styled.span`
  display: block;
  font-size: ${rem(16)};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  font-family: ${({ theme }) => theme.typography.fontSans};
  color: ${({ theme }) => theme.colors.colorTextSecondary};
`;
