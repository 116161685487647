import React from 'react';
import Skeleton from '@components/atoms/Skeleton';
import {
  SkeletonWrapper,
  SkeletonImageWrapper,
  SkeletonMain,
  SkeletonFooter,
  SkeletonLabels,
  SkeletonInformationWrapper,
  SkeletonAccordionSection,
  SkeletonHead,
  SkeletonTabsSection,
  SkeletonBannerWrapper,
} from './ProductDetails.skeleton.styles';

const PruductDetailsSkeleton = () => {
  return (
    <SkeletonWrapper data-testid="skeleton">
      <SkeletonHead>
        <SkeletonImageWrapper>
          <SkeletonLabels>
            <Skeleton type="circle" width={25} height={25} />
            <Skeleton type="circle" width={25} height={25} />
            <Skeleton type="circle" width={25} height={25} />
          </SkeletonLabels>
          <Skeleton type="rect" width={200} height={200} />
        </SkeletonImageWrapper>
        <SkeletonInformationWrapper>
          <SkeletonMain>
            <Skeleton type="rect" width={351} height={25} />
            <Skeleton type="rect" width={176} height={15} />
            <Skeleton type="rect" width={176} height={15} />
            <Skeleton type="rect" width={176} height={15} />
          </SkeletonMain>
          <SkeletonFooter>
            <Skeleton type="rect" width={150} height={34} />
            <Skeleton type="button" width={176} height={46} />
          </SkeletonFooter>
        </SkeletonInformationWrapper>
      </SkeletonHead>
      <SkeletonAccordionSection>
        <Skeleton type="rect" width="100%" height={2} />
        <Skeleton type="rect" width="100%" height={2} />
        <Skeleton type="rect" width="100%" height={2} />
      </SkeletonAccordionSection>
      <SkeletonTabsSection>
        <Skeleton type="rect" width={241} height={51} />
        <Skeleton type="rect" width={241} height={51} />
        <Skeleton type="rect" width={241} height={51} />
      </SkeletonTabsSection>
      <SkeletonBannerWrapper />
    </SkeletonWrapper>
  );
};

export default PruductDetailsSkeleton;
