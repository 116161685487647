import { Section } from '@components/atoms/Section/Section.styles';
import Button from '@components/atoms/__DEPRECATED__/Button';
import useAppRouter from '@hooks/useAppRouter';
import Heading from 'components/atoms/__DEPRECATED__/Heading';
import useTranslation from 'next-translate/useTranslation';
import { ButtonWrapper, Container, HeadingWrapper, Subtitle } from './ErrorPages.styles';

const Error500 = () => {
  const { t } = useTranslation('errorPages');
  const router = useAppRouter();
  return (
    <Section $marginTop>
      <Container>
        <HeadingWrapper>
          <Heading type="h1">{t('message')}</Heading>
        </HeadingWrapper>
        <ButtonWrapper>
          <Button type="button" variant="highlight" onClick={() => router.reload()}>
            {t('button->text')}
          </Button>
        </ButtonWrapper>
        <Subtitle>
          <span>{t('button->subtitle')}</span>
        </Subtitle>
      </Container>
    </Section>
  );
};

export default Error500;
