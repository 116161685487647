/* tslint:disable */
/* eslint-disable */
/**
 * Commerce Webservices V2
 * Commerce Webservices Version 2
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PageAdaptedData } from '../cms-dto';
/**
 * PageApi - axios parameter creator
 * @export
 */
export const PageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Given a page identifier, return the page data with a list of cms content slots, each of which contains a list of cms component data.
         * @summary Get page data with list of cms content slots
         * @param {string} baseSiteId Base site identifier
         * @param {'ContentPage' | 'ProductPage' | 'CategoryPage' | 'CatalogPage'} [pageType] page type
         * @param {string} [pageLabelOrId] Page Label or Id; if no page has a label that matches the provided label exactly, try to find pages whose label starts with a section of the provided label. Otherwise, try to find the page by id. Note: URL encoding on the page label should be applied when the label contains special characters
         * @param {string} [code] If pageType is ProductPage, code should be product code; if pageType is CategoryPage, code should be category code; if pageType is CatalogPage, code should be catalog code
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {string} [cmsTicketId] Ticket ID of the CMS preview. Unused in the actual controller, just needed for the schema generator.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPage: async (baseSiteId: string, pageType?: 'ContentPage' | 'ProductPage' | 'CategoryPage' | 'CatalogPage', pageLabelOrId?: string, code?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', cmsTicketId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getPage', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/cms/pages`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (pageType !== undefined) {
                localVarQueryParameter['pageType'] = pageType;
            }

            if (pageLabelOrId !== undefined) {
                localVarQueryParameter['pageLabelOrId'] = pageLabelOrId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (cmsTicketId !== undefined) {
                localVarQueryParameter['cmsTicketId'] = cmsTicketId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Given a page identifier, return the page data with a list of cms content slots, each of which contains a list of cms component data.
         * @summary Get page data with list of cms content slots
         * @param {string} pageId Page Id
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageById: async (pageId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pageId' is not null or undefined
            assertParamExists('getPageById', 'pageId', pageId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getPageById', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/cms/pages/{pageId}`
                .replace(`{${"pageId"}}`, encodeURIComponent(String(pageId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PageApi - functional programming interface
 * @export
 */
export const PageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PageApiAxiosParamCreator(configuration)
    return {
        /**
         * Given a page identifier, return the page data with a list of cms content slots, each of which contains a list of cms component data.
         * @summary Get page data with list of cms content slots
         * @param {string} baseSiteId Base site identifier
         * @param {'ContentPage' | 'ProductPage' | 'CategoryPage' | 'CatalogPage'} [pageType] page type
         * @param {string} [pageLabelOrId] Page Label or Id; if no page has a label that matches the provided label exactly, try to find pages whose label starts with a section of the provided label. Otherwise, try to find the page by id. Note: URL encoding on the page label should be applied when the label contains special characters
         * @param {string} [code] If pageType is ProductPage, code should be product code; if pageType is CategoryPage, code should be category code; if pageType is CatalogPage, code should be catalog code
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {string} [cmsTicketId] Ticket ID of the CMS preview. Unused in the actual controller, just needed for the schema generator.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPage(baseSiteId: string, pageType?: 'ContentPage' | 'ProductPage' | 'CategoryPage' | 'CatalogPage', pageLabelOrId?: string, code?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', cmsTicketId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAdaptedData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPage(baseSiteId, pageType, pageLabelOrId, code, fields, cmsTicketId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Given a page identifier, return the page data with a list of cms content slots, each of which contains a list of cms component data.
         * @summary Get page data with list of cms content slots
         * @param {string} pageId Page Id
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPageById(pageId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageAdaptedData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPageById(pageId, baseSiteId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PageApi - factory interface
 * @export
 */
export const PageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PageApiFp(configuration)
    return {
        /**
         * Given a page identifier, return the page data with a list of cms content slots, each of which contains a list of cms component data.
         * @summary Get page data with list of cms content slots
         * @param {string} baseSiteId Base site identifier
         * @param {'ContentPage' | 'ProductPage' | 'CategoryPage' | 'CatalogPage'} [pageType] page type
         * @param {string} [pageLabelOrId] Page Label or Id; if no page has a label that matches the provided label exactly, try to find pages whose label starts with a section of the provided label. Otherwise, try to find the page by id. Note: URL encoding on the page label should be applied when the label contains special characters
         * @param {string} [code] If pageType is ProductPage, code should be product code; if pageType is CategoryPage, code should be category code; if pageType is CatalogPage, code should be catalog code
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {string} [cmsTicketId] Ticket ID of the CMS preview. Unused in the actual controller, just needed for the schema generator.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPage(baseSiteId: string, pageType?: 'ContentPage' | 'ProductPage' | 'CategoryPage' | 'CatalogPage', pageLabelOrId?: string, code?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', cmsTicketId?: string, options?: any): AxiosPromise<PageAdaptedData> {
            return localVarFp.getPage(baseSiteId, pageType, pageLabelOrId, code, fields, cmsTicketId, options).then((request) => request(axios, basePath));
        },
        /**
         * Given a page identifier, return the page data with a list of cms content slots, each of which contains a list of cms component data.
         * @summary Get page data with list of cms content slots
         * @param {string} pageId Page Id
         * @param {string} baseSiteId Base site identifier
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPageById(pageId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<PageAdaptedData> {
            return localVarFp.getPageById(pageId, baseSiteId, fields, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PageApi - object-oriented interface
 * @export
 * @class PageApi
 * @extends {BaseAPI}
 */
export class PageApi extends BaseAPI {
    /**
     * Given a page identifier, return the page data with a list of cms content slots, each of which contains a list of cms component data.
     * @summary Get page data with list of cms content slots
     * @param {string} baseSiteId Base site identifier
     * @param {'ContentPage' | 'ProductPage' | 'CategoryPage' | 'CatalogPage'} [pageType] page type
     * @param {string} [pageLabelOrId] Page Label or Id; if no page has a label that matches the provided label exactly, try to find pages whose label starts with a section of the provided label. Otherwise, try to find the page by id. Note: URL encoding on the page label should be applied when the label contains special characters
     * @param {string} [code] If pageType is ProductPage, code should be product code; if pageType is CategoryPage, code should be category code; if pageType is CatalogPage, code should be catalog code
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
     * @param {string} [cmsTicketId] Ticket ID of the CMS preview. Unused in the actual controller, just needed for the schema generator.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public getPage(baseSiteId: string, pageType?: 'ContentPage' | 'ProductPage' | 'CategoryPage' | 'CatalogPage', pageLabelOrId?: string, code?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', cmsTicketId?: string, options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).getPage(baseSiteId, pageType, pageLabelOrId, code, fields, cmsTicketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Given a page identifier, return the page data with a list of cms content slots, each of which contains a list of cms component data.
     * @summary Get page data with list of cms content slots
     * @param {string} pageId Page Id
     * @param {string} baseSiteId Base site identifier
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PageApi
     */
    public getPageById(pageId: string, baseSiteId: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return PageApiFp(this.configuration).getPageById(pageId, baseSiteId, fields, options).then((request) => request(this.axios, this.basePath));
    }
}
