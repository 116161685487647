import { AxfoodBasicProductViewModel, AxfoodReplacementProductViewModel } from '@occ/api-client';
import { MAX_QUANTITY_VALUE } from '@components/molecules/Quantity/Quantity';
import React, { ChangeEvent, useRef, useState } from 'react';

// This is hook is used to do quantity calculations on products.
// The hook is a "dumb"-hook that is not integrated with any
// external services, it can therefore be used on its own,
// without incurring any side-effects.

export const roundToNearestIncrement = (quantity: number, incrementValue: number) => {
  return quantity % incrementValue >= incrementValue / 2
    ? Math.ceil(quantity / incrementValue) * incrementValue
    : Math.floor(quantity / incrementValue) * incrementValue;
};

export const clampQuantity = (quantity: number, incrementValue: number) => {
  return roundToNearestIncrement(Math.min(Math.max(quantity, 0), MAX_QUANTITY_VALUE), incrementValue);
};

export interface UseProductQuantityProps {
  initialQuantity?: number;
  product: AxfoodBasicProductViewModel | AxfoodReplacementProductViewModel;
}

const useProductQuantity = ({ initialQuantity = 0, product }: UseProductQuantityProps) => {
  const quantityInputRef = useRef<HTMLInputElement>(null);
  const [productQuantity, setProductQuantity] = useState<number | string>(initialQuantity || 0);
  const [quantityFieldIsActive, setQuantityFieldIsActive] = useState(false);

  const onKeyPress = (key: React.KeyboardEvent<HTMLInputElement>) => {
    if (key.key === 'Enter' || key.key === 'Escape') {
      const input = quantityInputRef?.current;
      input?.blur();
    }
  };

  const changeQuantityValueBy = (quantityChange: number) => {
    const newValue = clampQuantity(+productQuantity + quantityChange, product.incrementValue || 0);
    if (newValue >= 0) {
      setProductQuantity(newValue);
    }
  };

  const onQuantityFieldChange = (quantityField: ChangeEvent<HTMLInputElement>) => {
    const inputValue = quantityField.target.value;
    let newValue: string | number = '';
    if (inputValue !== '') {
      newValue = clampQuantity(parseFloat(quantityField.target.value), product.incrementValue || 0);
    }
    setProductQuantity(newValue);
  };

  const onQuantityFieldBlur = () => {
    setQuantityFieldIsActive(false);
    if (!productQuantity) {
      setProductQuantity(0);
    }
  };

  const onQuantityFieldFocus = () => {
    setQuantityFieldIsActive(true);
    if (!productQuantity) {
      setProductQuantity('');
    }
    const input = quantityInputRef?.current;
    return input?.select();
  };

  return {
    changeQuantityValueBy,
    onQuantityFieldChange,
    onQuantityFieldFocus,
    onQuantityFieldBlur,
    onKeyPress,
    quantity: productQuantity as number,
    quantityInputRef,
    quantityFieldIsActive,
  };
};

export default useProductQuantity;
