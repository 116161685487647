import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(12)};
`;

export const SuggestionLinksWrapper = styled.div`
  font-size: ${rem(20)};

  ${media.tabletPortrait} {
    font-size: ${rem(22)};
  }
`;

export const CategoryLinksWrapper = styled.div`
  ${media.tabletPortrait} {
    padding: 0;
  }
`;

export const Divider = styled.div`
  padding: ${rem(8)} 0 0;
  border-bottom: ${rem(2)} dotted ${({ theme }) => theme.colors.colorStack};

  ${media.tabletPortrait} {
    padding: 0;
    border-bottom: none;
  }
`;
