import Button from '@components/atoms/Button';
import { fontSize } from '@components/molecules/Quantity/Quantity.styles';
import { rem } from 'polished';
import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${rem(40)};
`;

export const TextContainer = styled(Wrapper)`
  padding: 0 ${rem(0)};
`;

export const FlexButton = styled(Button)`
  flex: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 10;
  font-size: ${fontSize};
`;

const opacityTransition: Partial<Record<TransitionStatus, any>> = {
  entering: css`
    opacity: 1;
  `,
  entered: css`
    opacity: 1;
  `,
  exiting: css`
    opacity: 0;
  `,
  exited: css`
    opacity: 0;
  `,
};
export const BuyButtonWrapper = styled.div<{ $animationState: TransitionStatus }>`
  display: flex;
  transition: opacity 400ms ease-in-out;
  opacity: 0;
  ${({ $animationState }) => opacityTransition[$animationState]}
`;

export const QuantityWrapper = styled.div<{ $hasQuantity: boolean; $animationState: TransitionStatus }>`
  display: flex;
  width: 100%;
  transition: opacity 400ms ease-in-out;
  opacity: 0;

  ${({ $animationState }) => opacityTransition[$animationState]}
  .minus-button {
    transition: transform 300ms ease-in-out, background-color 500ms;
    transform: ${({ $hasQuantity }) => ($hasQuantity ? `translateX(0)` : `translateX(30px)`)};
  }

  .plus-button {
    transition: transform 300ms ease-in-out, background-color 500ms;
    transform: ${({ $hasQuantity }) => ($hasQuantity ? `translateX(0)` : `translateX(-30px)`)};
  }
`;
