import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const Item = styled.div<{ $imgUrl?: string }>`
  height: 100%;
  ${({ $imgUrl, theme }) =>
    $imgUrl
      ? css`
          background-image: url(${$imgUrl});
          background-size: cover;
        `
      : css`
          background-color: ${theme.colors.colorPrimary};
        `}
`;

export const Pane = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(transparent, transparent, ${({ theme }) => theme.colors.colorTextPrimary});
    opacity: 1;
  }
`;

export const Content = styled.div`
  padding: 0 ${rem(15)} ${rem(15)};
  display: flex;
  flex-direction: column;
  gap: ${rem(5)};
  z-index: 2;
  margin-top: auto;
`;
