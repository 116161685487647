import { LinkProps } from '../LinkArea/LinkArea';
import { StyledLinkArea } from './Link.styles';

declare type Variant = 'dark' | 'light' | 'primary';

export interface StyledLinkProps {
  underlined?: boolean;
  variant?: Variant;
  margin?: boolean;
  block?: boolean;
}

export type Props = StyledLinkProps & LinkProps;

/**
 * @deprecated use components/atoms/Link instead
 * @summary styled LinkArea component
 * this component only accepts props related to styling
 * and LinkArea is responsible of rendering the correct type of link
 */
export const Link = ({
  underlined = true,
  variant = 'dark',
  margin = true,
  block = false,
  // linkProps below
  className,
  'aria-label': ariaLabel = undefined,
  dynamicUrlPrefix,
  children,
  disabled = false,
  href,
  prefetch = false,
  replace = false,
  scroll = true,
  shallow = false,
  title,
  target = '_self',
  onClick,
  nextLinkAs,
  external = false,
  forceInternal = false,
}: Props) => {
  return (
    <StyledLinkArea
      underlined={underlined}
      variant={variant}
      margin={margin}
      block={block}
      // linkProps below
      dynamicUrlPrefix={dynamicUrlPrefix}
      className={className}
      nextLinkAs={nextLinkAs}
      aria-label={ariaLabel}
      href={href}
      scroll={scroll}
      prefetch={prefetch}
      replace={replace}
      shallow={shallow}
      title={title}
      target={target}
      onClick={onClick}
      external={external}
      forceInternal={forceInternal}
      disabled={disabled}
    >
      {children}
    </StyledLinkArea>
  );
};

export default Link;
