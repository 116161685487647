import LinkArea from '@components/atoms/LinkArea';
import { ReactNode } from 'react';
import { UrlObject } from 'url';

interface Props {
  imageIsClickable: boolean;
  children: ReactNode;
  href: UrlObject;
  as: { pathname: string };
  title: string;
  onClick: () => void;
  linkAriaLabel: string;
}

const ProductCardImage = ({ imageIsClickable, children, href, as, title, onClick, linkAriaLabel }: Props) => {
  return (
    <>
      {imageIsClickable ? (
        <LinkArea
          href={href}
          nextLinkAs={as}
          title={title}
          scroll={false}
          shallow
          onClick={onClick}
          aria-label={linkAriaLabel}
        >
          {children}
        </LinkArea>
      ) : (
        children
      )}
    </>
  );
};

export default ProductCardImage;
