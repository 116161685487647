import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';
import { MenuContainer } from '@components/organisms/Header/AccountMenuPanel/AccountMenuPanel.styles';

export const Voucher = styled.div`
  position: initial;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: ${rem(250)};
  width: ${rem(192)};
`;
export const VoucherPop = styled(MenuContainer)`
  margin: ${rem(8)} ${rem(16)};
  display: flex;
  justify-content: space-between;
  padding: ${rem(16)};

  ${media.tabletPortrait} {
    margin: ${rem(8)} 0;
  }
`;

export const LinkWrapper = styled.div`
  font-size: ${rem(14)};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  text-decoration: none;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
