/* eslint-disable react/jsx-props-no-spreading */
import withIntersectionObserver from '@components/hocs/WithIntersectionObserver';
import { InjectedIntersectionObserverProps } from '@components/hocs/WithIntersectionObserver/WithIntersectionObserver';
import { FC, forwardRef, RefObject } from 'react';

export interface IntersectionObserverComponentProps {
  intersectionCallback?: () => void;
  ref?: RefObject<any>;
  [x: string]: any;
}

const componentWithIntersectionObserver = <P extends IntersectionObserverComponentProps>(Component: FC<P>) => {
  return withIntersectionObserver((props) => {
    return <Component {...(props as InjectedIntersectionObserverProps & P)} />;
  });
};

const trackedComponent = <P extends IntersectionObserverComponentProps>(Component: FC<any>) => {
  const ForwardedComponent = forwardRef((props: P, ref) => {
    return <Component {...props} intersectionRef={ref} />;
  });
  ForwardedComponent.displayName = `Tracked(${Component.displayName || Component.name || 'Component'})`;
  return ForwardedComponent;
};

/**
 * Wraps the given Component with the HoC withIntersectionObserver, and injects the prop intersectionRef
 * with the given ref on the given Component.
 *
 * @param Component
 */
const makeIntersectionObserverComponent = <P extends IntersectionObserverComponentProps>(Component: FC<P>) => {
  return trackedComponent(componentWithIntersectionObserver(Component));
};

makeIntersectionObserverComponent.displayName = 'makeIntersectionObserverComponent';

export default makeIntersectionObserverComponent;
