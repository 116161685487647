import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const MOBILE_GRID_GAP = 8;
export const GRID_GAP = 16;
export const MIN_PRODUCT_CARD_WIDTH = 205;
export const CAROUSEL_MIN_PRODUCT_CARD_WIDTH = 169;

export const MIN_PRODUCT_CARD_WITH_GAP = MIN_PRODUCT_CARD_WIDTH + GRID_GAP;

interface Props {
  $gap?: string;
}

export const ProductGridContainer = styled.div<Props>`
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 3px));
  grid-column-gap: ${rem(MOBILE_GRID_GAP)};
  grid-row-gap: ${rem(MOBILE_GRID_GAP)};
  ${media.tabletPortrait} {
    grid-template-columns: repeat(auto-fill, minmax(${rem(MIN_PRODUCT_CARD_WIDTH)}, 1fr));
    grid-column-gap: ${(props) => (props.$gap ? rem(Number(props.$gap)) : rem(GRID_GAP))};
    grid-row-gap: ${rem(GRID_GAP)};
    grid-column-gap: ${rem(GRID_GAP)};
  }
`;
