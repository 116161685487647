import TagManager from 'react-gtm-module';
import gtmTrack from '@helpers/gtmTrack';
import { getImpressionsObjectPrice } from '@helpers/productHelper';
import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';

export const enteredProductDetail = (product: AxfoodProductDetailsViewModel) => {
  const productBrandLowercase = product.manufacturer ? product.manufacturer.toLowerCase() : '';
  const ecommerce = {
    currencyCode: 'SEK',
    detail: {
      products: [
        {
          id: product.code,
          name: product.name,
          category: product.googleAnalyticsCategory || '',
          brand: productBrandLowercase,
          price: getImpressionsObjectPrice(product),
        },
      ],
    },
  };
  gtmTrack({
    event: 'viewedProductDetail',
    ecommerce,
  });
};

export const productTabClickTracker = (tabName: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'product_detail',
      action: `toggled_to_${tabName}`,
      label: '',
      value: 0,
    },
  });
};

const productDetailTracker = { productTabClickTracker, enteredProductDetail };

export default productDetailTracker;
