import styled from 'styled-components';
import { rem } from 'polished';
import { StyledButton } from '@components/molecules/IconButton/IconButton.styles';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  background-color: ${({ theme }) => theme.colors.colorWhite};
`;

export const MenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${rem(16)};

  ${StyledButton} {
    padding: 0;
  }
`;

export const MenuContent = styled.div``;
