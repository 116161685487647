import Button from '@components/atoms/Button';
import Heading from '@components/atoms/Heading';
import ProductGrid from '@components/atoms/ProductGrid';
import Carousel from '@components/molecules/Carousel';
import CarouselListItem from '@components/molecules/Carousel/CarouselListItem';
import CmsBannerListItem from '@components/molecules/CmsBannerListItem';
import useCmsComponents from '@hooks/useCmsComponentGroup';
import { bannerListTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import {
  CarouselHeader,
  CarouselHeaderWrapper,
  CarouselWrapper,
  HeaderWrapper,
  ShowMoreWrapper,
} from './GenericPageBannerListComponent.styles';
import useItemCount from './useItemCount';

interface ItemsProps {
  items: ListBannerLinkComponentType[];
  trackingFn: (bannerListItemType: string, bannerListItemLabel: string) => void;
}

const CarouselItems = ({ items, trackingFn }: ItemsProps) => (
  <CarouselWrapper>
    <Carousel>
      {items?.map((item: ListBannerLinkComponentType) => (
        <CarouselListItem key={item.uid}>
          <CmsBannerListItem item={item} trackingFn={trackingFn} />
        </CarouselListItem>
      ))}
    </Carousel>
  </CarouselWrapper>
);

const BannerListItems = ({ items, trackingFn }: ItemsProps) => (
  <ProductGrid>
    {items?.map((item: ListBannerLinkComponentType) => (
      <CmsBannerListItem item={item} trackingFn={trackingFn} key={item.uid} />
    ))}
  </ProductGrid>
);

const BannerListShowAllItems = ({ items, trackingFn }: ItemsProps) => {
  const { t } = useTranslation('common');
  const [showAllItems, setShowAllItems] = useState(false);
  const { itemCount, widthRef: containerRef, itemWidthRef: bannerItemRef } = useItemCount(2);

  const endIndex = itemCount * 2;
  const bannerListShowAllItems = showAllItems ? items : items.slice(0, endIndex);

  return (
    <>
      <div ref={containerRef}>
        <ProductGrid>
          {bannerListShowAllItems?.map((item: ListBannerLinkComponentType) => (
            <div ref={bannerItemRef} key={item.uid}>
              <CmsBannerListItem item={item} trackingFn={trackingFn} />
            </div>
          ))}
        </ProductGrid>
      </div>
      {!showAllItems && items.length > endIndex && (
        <ShowMoreWrapper>
          <Button variant="pink" onClick={() => setShowAllItems(true)}>
            {t('showMore')}
          </Button>
        </ShowMoreWrapper>
      )}
    </>
  );
};

const createTrackingFn = (listTitle: string) => (bannerListItemType: string, bannerListItemLabel: string) => {
  const carouselRecipeItemType = bannerListItemType === 'true' ? `recipe | ${listTitle}` : `article | ${listTitle}`;
  bannerListTracker.clickBlurb(carouselRecipeItemType, bannerListItemLabel);
};

export interface GenericPageBannerListComponentProps {
  component: GenericPageBannerListComponentType;
  alwaysShowArrowButtons?: boolean;
}

const GenericPageBannerListComponent = (props: GenericPageBannerListComponentProps) => {
  const { component = {} as GenericPageBannerListComponentType } = props;
  const { title, displayType } = component || {};
  const { data: cmsComponentData = [] } = useCmsComponents(component?.pageLinks);
  const trackingFn = createTrackingFn(title);
  const items = cmsComponentData as ListBannerLinkComponentType[];

  return (
    <>
      <CarouselHeaderWrapper>
        <CarouselHeader>
          <HeaderWrapper>
            <Heading size="small" color="black" type="h2">
              {title}
            </Heading>
          </HeaderWrapper>
        </CarouselHeader>
      </CarouselHeaderWrapper>
      {displayType === 'CAROUSEL' && <CarouselItems items={items} trackingFn={trackingFn} />}
      {displayType === 'BANNER_LIST' && <BannerListItems items={items} trackingFn={trackingFn} />}
      {displayType === 'BANNER_LIST_SHOW_ALL' && <BannerListShowAllItems items={items} trackingFn={trackingFn} />}
    </>
  );
};

export default GenericPageBannerListComponent;
