import Paragraph from '@components/atoms/Paragraph';
import useResponsive from '@hooks/useResponsive';
import { ReactNode } from 'react';
import { Container, HeaderWrapper } from './SuggestionList.styles';

interface Props {
  title: string;
  children: ReactNode;
  withHeader?: boolean;
}

const SuggestionList = ({ title, children, withHeader = false }: Props) => {
  const { isTabletPortraitOrGreater } = useResponsive();

  return (
    <Container>
      {isTabletPortraitOrGreater && withHeader && (
        <HeaderWrapper>
          <Paragraph weight="medium" size="xsmall">
            {title}
          </Paragraph>
        </HeaderWrapper>
      )}
      {children}
    </Container>
  );
};

export default SuggestionList;
