import Picture from '@components/atoms/Picture';
import VideoModal from '@components/molecules/VideoModal';
import { mediaTracker, verticalBannerTracker } from '@trackers';
import { useState } from 'react';
import {
  BannerImage,
  ButtonWrapper,
  MinHeightWrapper,
  VerticalBannerContent,
  VerticalBannerWrapper,
} from './VerticalBannerComponent.styles';
import Paragraph from '@components/atoms/Paragraph';
import ArrowLinkButton from '@components/molecules/ArrowLinkButton';
import useResponsive from '@hooks/useResponsive';

export interface VerticalBannerComponentProps {
  component: AxfoodVerticalBannerComponent;
}
const VerticalBannerComponent = ({ component }: VerticalBannerComponentProps) => {
  const { isTabletPortraitOrGreater } = useResponsive();
  const [showModal, setShowModal] = useState(false);
  if (!component) return null;

  const { media, title, preamble, buttonEnabled, buttonText, urlLink, urlLinkInNewWindow, videoLink } = component;

  const desktopImage = media?.desktop?.url || '';
  const tabletImage = media?.tablet?.url;
  const mobileImage = media?.mobile?.url;
  const hasBackgroundImage = !!(desktopImage || tabletImage || mobileImage);
  const link = urlLink || '';
  const truncateRowsInMobile = isTabletPortraitOrGreater ? 0 : 2;
  const hasButton = buttonEnabled === 'true';
  const targetBlank = (urlLinkInNewWindow === 'true' && '_blank') || '_self';

  const toggleModal = () => {
    setShowModal(!showModal);
    mediaTracker.videoPlayedTracker(title || 'No video title', videoLink);
  };

  const trackLinkGA = () => {
    verticalBannerTracker.clickOnLink(link, title);
  };

  return (
    <VerticalBannerWrapper>
      {showModal && videoLink && <VideoModal videoLink={videoLink} open={showModal} onClose={toggleModal} />}
      {hasBackgroundImage && media && (
        <BannerImage>
          <Picture media={media} />
        </BannerImage>
      )}
      <VerticalBannerContent data-testid="banner-content">
        {title && (
          <Paragraph size="medium" weight="bold">
            {title}
          </Paragraph>
        )}
        {preamble && (
          <MinHeightWrapper>
            <Paragraph size="small" truncateRows={truncateRowsInMobile}>
              {preamble}
            </Paragraph>
          </MinHeightWrapper>
        )}
        {hasButton && (
          <ButtonWrapper>
            {videoLink ? (
              <ArrowLinkButton data-testid="toggle-video-button" onClick={toggleModal} url="#" variant="strokeBlack">
                {buttonText}
              </ArrowLinkButton>
            ) : (
              <ArrowLinkButton variant="strokeBlack" url={link} onClick={trackLinkGA} target={targetBlank}>
                {buttonText}
              </ArrowLinkButton>
            )}
          </ButtonWrapper>
        )}
      </VerticalBannerContent>
    </VerticalBannerWrapper>
  );
};

export default VerticalBannerComponent;
