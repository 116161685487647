import { AxfoodRecipeEntryViewModel } from '@api/generated/storefront';

export const imgSize = '200';

export const formatCloudinaryUrl = (url?: string) => url && url.replace('{w}', imgSize).replace('{h}', imgSize);

const getRecipeThumbnailUrl = (recipe: AxfoodRecipeEntryViewModel) =>
  formatCloudinaryUrl(recipe.recipeImages?.cloudinaryUrl) || recipe.recipeImages?.urls?.[0]?.url || '';

export default getRecipeThumbnailUrl;
