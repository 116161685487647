import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${rem(30)} ${rem(12)} ${rem(24)} ${rem(12)};
  ${media.tabletPortrait} {
    padding: ${rem(50)} ${rem(85)} ${rem(24)} ${rem(85)};
  }
  ${media.desktop} {
    padding: ${rem(50)} ${rem(166)} ${rem(24)} ${rem(166)};
  }
  background-color: ${({ theme }) => theme.colors.colorWhite};
  min-height: ${rem(500)};
  a {
    @media (hover: hover) {
      &:hover {
        color: ${({ theme }) => theme.colors.colorStack};
      }
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  ${media.tabletPortrait} {
    width: ${rem(440)};
  }
`;

export const FormRow = styled.div<{ $marginBottom?: string }>`
  display: flex;
  position: relative;
  justify-content: flex-start;
  margin: ${rem(40)} 0;
  ${({ $marginBottom }) =>
    $marginBottom &&
    `
    margin-bottom: ${$marginBottom};
    `}
`;

export const ButtonWrapper = styled.div`
  margin-top: ${rem(16)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${media.tabletPortrait} {
    flex-direction: row;
  }
`;

export const StyledParagraph = styled(Paragraph)`
  margin: ${rem(16)} 0;
`;

export const DropdownLabel = styled.label`
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  opacity: 0.75;
  font-size: ${rem(14)};
  margin-bottom: ${rem(3)};
`;

export const TextWrapper = styled.div`
  align-self: flex-start;
  margin-bottom: ${rem(16)};
  ${media.tabletPortrait} {
    align-self: center;
    margin-bottom: 0;
  }
`;

export const InfoPaneWrapper = styled.div`
  margin: 0 auto;
  margin-top: ${rem(32)};
  display: flex;
  justify-content: center;
`;
