import { OrderDataV2Response, RecipeEntryData } from '@api/generated/storefront';
import { RestrictedAge } from '@components/molecules/CartAgeRestrictionNotification/CartAgeRestrictionNotification';
import mapOrderDataToOrderSummary from '@components/molecules/OrderSummary/mapOrderDataToOrderSummary';
import { OrderProps } from '@components/molecules/OrderSummary/OrderSummary';
import DELIVERY_METHODS from '@constants/deliveryMethods';
import LINKS from '@constants/links';
import mapDeliveryChanges, {
  DeliveryChanges,
} from '@features/mypages/OrderDetailPage/components/DeliveryChangesSection/mapDeliveryChanges';
import {
  CategorizedOrderRows,
  mapCategorizedOrderRows,
} from '@features/mypages/OrderDetailPage/components/ProductListSection/mapCategorizedOrderRows';
import { formatAddress } from '@helpers/address';
import { formatCustomerName } from '@helpers/customer';
import { formatCloudinaryUrl } from '@helpers/getRecipeThumbnailUrl/getRecipeThumbnailUrl';

export interface RecipeItem {
  id: string;
  portions?: number;
  name: string;
  src?: string;
  altText: string;
  href: string;
}
export interface OrderData extends OrderDataV2Response {
  customerName: string;
  isPickUpInStore: boolean;
  storeNameWithAddress: string;
  formattedCustomerAddress: string;
  isAnyB2BCustomer: boolean;
  orderIsEditable: boolean;
  formattedRecipeEntries: Array<RecipeItem>;
  orderSummaryData: OrderProps;
  ageRestrictionNumber?: RestrictedAge;
  withLoyaltyPoints: boolean;
  deliveryChanges?: DeliveryChanges;
  categorizedOrderRows?: CategorizedOrderRows;
}

export const mapRecipeEntries = (recipe: RecipeEntryData) => {
  const { cloudinaryImageAltText, cloudinaryImageUrl, recipeImages, id, portions, name } = recipe;

  if (id && name) {
    const src = (cloudinaryImageUrl && formatCloudinaryUrl(cloudinaryImageUrl)) || (recipeImages && recipeImages[0].url);
    const altText = cloudinaryImageAltText || (recipeImages && recipeImages[0].altText) || recipe.name || '';
    const href = `${LINKS.RECIPE}${id}`;
    return { id, portions, name, src, altText, href };
  }
};

export const mapOrderData = (order: OrderDataV2Response): OrderData => {
  const {
    deliveryModeCode,
    deliveryAddress,
    storeName,
    storeDetails,
    b2bCustomer,
    localB2bCustomer,
    editable,
    slotCloseTimeFormatted,
    recipeEntries,
    restrictionAge,
  } = order;
  const {
    firstName,
    lastName,
    companyName,
    postalCode = '',
    town = '',
    line1: streetAddress = '',
  } = deliveryAddress || {};

  const isAnyB2BCustomer = !!(b2bCustomer || localB2bCustomer);

  const customerName = formatCustomerName(firstName, lastName, companyName, isAnyB2BCustomer);

  const isPickUpInStore = deliveryModeCode === DELIVERY_METHODS.PICKUP_CODE;

  const storeNameWithAddress = `${storeName} ${storeDetails?.pointOfService?.address?.formattedAddress}`;

  const formattedCustomerAddress = formatAddress(streetAddress, postalCode, town);

  const orderIsEditable = !!(editable && slotCloseTimeFormatted);

  const formattedRecipeEntries = recipeEntries?.map(mapRecipeEntries).filter((r) => !!r) || [];
  const orderSummaryData = mapOrderDataToOrderSummary(order);

  const ageRestrictionNumber =
    restrictionAge && (restrictionAge === 18 || restrictionAge === 15) ? restrictionAge : undefined;

  const withLoyaltyPoints = !!order.captured && !isAnyB2BCustomer;

  const isAnonymousOrder = !!order.anonymousOrder;

  const paymentCaptured = !!order.captured;

  return {
    ...order,
    customerName,
    isPickUpInStore,
    storeNameWithAddress,
    formattedCustomerAddress,
    isAnyB2BCustomer,
    orderIsEditable,
    formattedRecipeEntries,
    orderSummaryData,
    ageRestrictionNumber,
    withLoyaltyPoints,
    deliveryChanges: mapDeliveryChanges(order),
    categorizedOrderRows: order?.categoryOrderedDeliveredProducts
      ? mapCategorizedOrderRows(order.categoryOrderedDeliveredProducts, isAnonymousOrder, paymentCaptured)
      : undefined,
  };
};
