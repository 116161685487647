export const SET_CURRENT_CARD = 'SET_CURRENT_CARD';
export const TOGGLE_SHOULD_SAVE_NEW_CARD = 'TOGGLE_SHOULD_SAVE_NEW_CARD';
export const SET_CURRENTLY_CHECKED_PAYMENT = 'SET_CURRENTLY_CHECKED_PAYMENT';
export const SET_KLARNA_DATA = 'SET_KLARNA_DATA';
export const UNSET_KLARNA_IDENTIFIER = 'UNSET_KLARNA_IDENTIFIER';
export const RESET_PAYMENT_STATE = 'RESET_PAYMENT_STATE';
export const REFRESH_KLARNA_TOKEN_AND_UNSET_IDENTIFIER = 'REFRESH_KLARNA_TOKEN_AND_UNSET_IDENTIFIER';
export const SET_CURRENTLY_CHECKED_IDENTIFIER = 'SET_CURRENTLY_CHECKED_IDENTIFIER';
export const SET_KLARNA_LOADED = 'SET_KLARNA_LOADED';
export const SET_PAYMENT_AUTH_STATUS = 'SET_PAYMENT_AUTH_STATUS';
export const RESET_PAYMENT_AUTH_STATUS = 'RESET_PAYMENT_AUTH_STATUS';
