import styled, { css } from 'styled-components';
import { ArticleComponentPadding } from '@styles/cms';
import media from '@constants/responsive';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

interface ComponentProps {
  $width: number;
  $withPadding: boolean;
}

const widthTablet = (width: number) => {
  switch (width) {
    case 25:
      return `calc(50% - 5px)`;
    case 33:
      return `calc(33% - 5px)`;
    case 50:
      return `calc(50% - 5px)`;
    default:
      return `${width}%`;
  }
};

const widthDesktop = (width: number) => {
  switch (width) {
    case 25:
      return `calc(25% - 7.5px)`;
    case 33:
      return `calc(33% - 2.5px)`;
    case 50:
      return `calc(50% - 5px)`;
    default:
      return `${width}%`;
  }
};
export const ComponentWrapper = styled.div<ComponentProps>`
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  ${media.tabletPortrait} {
    width: ${({ $width }) => widthTablet($width)};
  }
  ${media.desktop} {
    width: ${({ $width }) => widthDesktop($width)};
  }
  ${({ $withPadding }) =>
    $withPadding &&
    css`
      background-color: ${({ theme }) => theme.colors.colorWhite};
      ${ArticleComponentPadding}
    `}
`;
