import { getAvailableStores } from '@api';
import useSWRImmutable from 'swr/immutable';

const useStores = () => {
  const { data, isLoading } = useSWRImmutable('/api/stores', () => getAvailableStores(false, false, false));
  const { storesWithFlyer, mapBounderies, storeMarkers } = data || {};
  return { storesWithFlyer, mapBounderies, storeMarkers, isLoading };
};

export default useStores;
