import TagManager from 'react-gtm-module';

export interface AddToShoppingListEvent {
  category: 'minicart' | 'product_blurb' | 'product_detail' | 'cart' | 'order_detail' | 'shopping_list_product_search';
  action: 'saved_to_list' | 'list_icon_clicked' | 'save_as_list_redirected_to_login';
  label?: 'existing' | 'new' | 'redirect_to_login' | '';
}

const addToShoppingListTracker = ({ category, action, label }: AddToShoppingListEvent) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: `shopping_list | ${category}`,
      action,
      label,
      value: 0,
    },
  });
};

export default addToShoppingListTracker;
export { addToShoppingListTracker };
