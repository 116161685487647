import { ArticleContentPageData } from '@api/generated/storefront';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import ArticleItem from '@components/molecules/ArticleItem/ArticleItem';
import { memo } from 'react';
import AxfoodPressReleaseListComponentSkeleton from './AxfoodPressReleaseListComponent.skeleton';
import { Grid } from './AxfoodPressReleaseListComponent.styles';
import usePressArticles from './usePressArticles';

type RequiredArticleContentPageData = ArticleContentPageData & Required<Pick<ArticleContentPageData, 'link'>>;

const AxfoodPressReleaseListComponent = () => {
  const { data: articleList, isValidating } = usePressArticles();
  return (
    <Grid>
      {isValidating || !articleList ? (
        <AxfoodPressReleaseListComponentSkeleton />
      ) : (
        articleList
          .filter((article): article is RequiredArticleContentPageData => !!article?.link)
          .map(({ link, image, description = '', publicationDate }) => (
            <ArticleItem
              key={link}
              link={link}
              imageUrl={image?.url || ''}
              description={description}
              subtitle={publicationDate ? <Paragraph textColor="white">{publicationDate}</Paragraph> : null}
            />
          ))
      )}
    </Grid>
  );
};

export default memo(AxfoodPressReleaseListComponent);
