import Icon from '@components/atoms/Icon';
import formatBytes from '@helpers/formatBytesHelper';
import IconPaperClip from '@public/icons/regularIcons/icon-paper-clip.svg';
import IconRemove from '@public/icons/regularIcons/icon-remove.svg';
import useTranslation from 'next-translate/useTranslation';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import {
  FileError,
  FileName,
  FileSize,
  FileUploadLabel,
  InputWrapper,
  List,
  ListItem,
  RemoveFileButton,
} from './FileUploadInputField.styles';

export interface Props {
  acceptedFileTypes: string;
  maxTotalFileSizeInMB: number;
  files: File[];
  setFiles: (arg0: any) => void;
  setDisabled: (arg0: boolean) => void;
}

const FileUploadInputField = ({ acceptedFileTypes, maxTotalFileSizeInMB = 1, setFiles, files, setDisabled }: Props) => {
  const { t } = useTranslation('customerservice');
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const oneMbInBytes = 1048576;
  const [filesTotalSize, setFilesTotalSize] = useState<number>(0);

  const fileSizesToLarge = filesTotalSize > maxTotalFileSizeInMB * oneMbInBytes;

  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      setFiles((oldArray: File[]) => [...oldArray, ...newFiles]);
    }
  };

  const handleOpenFileManager = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
    e.preventDefault();
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleRemoveFile = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, file: File) => {
    e.preventDefault();
    setFiles((oldArray: File[]) => oldArray.filter((item: File) => item !== file));
  };

  useEffect(() => {
    const size = files?.reduce((totalSize, file) => totalSize + (file.size || 0), 0);
    setFilesTotalSize(size);
    setDisabled(fileSizesToLarge);
  }, [fileSizesToLarge, files, setDisabled]);

  return (
    <>
      <FileUploadLabel htmlFor="file-uploader" onClick={handleOpenFileManager}>
        <Icon icon={IconPaperClip} data-testid="icon-paper-clip" />
        {files?.length > 0
          ? t('customerservice:contactForm->form->fileInput->attachMoreFiles')
          : t('customerservice:contactForm->form->fileInput->attachFile')}
      </FileUploadLabel>
      <InputWrapper>
        <input
          id="file-uploader"
          ref={hiddenFileInput}
          onChange={(e) => onFileInputChange(e)}
          accept={acceptedFileTypes}
          type="file"
          multiple
        />
      </InputWrapper>
      <List>
        {files?.map((file, i) => (
          <ListItem key={uuid()}>
            <FileName>
              {file.name} <FileSize>({formatBytes(file.size)})</FileSize>
            </FileName>
            <RemoveFileButton onClick={(e) => handleRemoveFile(e, file)}>
              <Icon icon={IconRemove} margin="left" data-testid="icon-remove" />
            </RemoveFileButton>
          </ListItem>
        ))}
      </List>
      {fileSizesToLarge && (
        <FileError>
          {t('customerservice:contactForm->form->fileInput->fileSizesToLarge', { maxTotalFileSizeInMB })}
          <strong>{formatBytes(filesTotalSize)}</strong>
        </FileError>
      )}
    </>
  );
};

export default FileUploadInputField;
