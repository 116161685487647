import Paragraph from '@components/atoms/Paragraph';
import { FontSizeType } from '@styles/fontSizes';
import useTranslation from 'next-translate/useTranslation';
import { BargainSplashBox, BargainText, NewSplashBox } from './SplashBox.styles';

const SplashBox = ({
  showNewsSplash,
  showBargainSplash = false,
  size = 'xsmall',
}: {
  showNewsSplash?: boolean;
  showBargainSplash?: boolean;
  size?: FontSizeType;
}) => {
  const { t } = useTranslation('product');

  return (
    <>
      {showBargainSplash && (
        <BargainSplashBox>
          <BargainText color="white" size={size} data-testid="bargain-splash">
            {t('bargain')}
          </BargainText>
        </BargainSplashBox>
      )}
      {showNewsSplash && !showBargainSplash && (
        <NewSplashBox>
          <Paragraph weight="bold" size={size} data-testid="news-splash">
            {t('new')}
          </Paragraph>
        </NewSplashBox>
      )}
    </>
  );
};

export default SplashBox;
