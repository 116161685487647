import { css } from 'styled-components';
import media from '@constants/responsive';

const GlobalClasses = {
  cmsContentText: css`
    span {
      &.mobile-content {
        ${media.tabletPortrait} {
          display: none;
        }
      }
      &.tablet-content {
        display: none;
        ${media.tabletPortrait} {
          display: initial;
        }
        ${media.desktop} {
          display: none;
        }
      }
      &.desktop-content {
        display: none;
        ${media.desktop} {
          display: initial;
        }
      }
    }
  `,
};

export type ThemeGlobalClasses = typeof GlobalClasses;
export default GlobalClasses;
