import { AxfoodStoreInfoViewModel, GeoPoint } from '@api/generated/storefront';
import { StoreMarker } from '@features/findStore/GoogleMap/Map';

interface UserLocationTypes {
  lat: number;
  lng: number;
}

export interface GeoPointTypes {
  latitude: number;
  longitude: number;
}

export const hasValidGeoPoints = (geoPoint?: GeoPoint): geoPoint is GeoPointTypes =>
  typeof geoPoint?.latitude === 'number' &&
  typeof geoPoint?.longitude === 'number' &&
  geoPoint.latitude > 0 &&
  geoPoint.longitude > 0;

const getStoreMarkers = (stores: AxfoodStoreInfoViewModel[]) => {
  const markers: StoreMarker[] = stores
    .map((store) => {
      if (hasValidGeoPoints(store.geoPoint)) {
        return {
          openingHours: store.openingStoreMessageValue,
          openingHoursMessageKey: store?.openingHoursMessageKey,
          id: store.storeId,
          address: store.address,
          storeName: store.name,
          position: { lat: store?.geoPoint?.latitude, lng: store?.geoPoint?.longitude },
        };
      } else {
        return undefined;
      }
    })
    .filter((m): m is StoreMarker => !!m);

  return markers;
};

const getMapBounderies = (storeMarkers: StoreMarker[]) => {
  const storesGeoPoints = storeMarkers.map((s) => s.position);
  const maxLng = Math.max(...storesGeoPoints?.map((position) => position.lng));
  const minLng = Math.min(...storesGeoPoints.map((position) => position.lng));
  const maxLat = Math.max(...storesGeoPoints.map((position) => position.lat));
  const minLat = Math.min(...storesGeoPoints.map((position) => position.lat));
  return { minLat, maxLat, minLng, maxLng };
};

const hasPermissionToLocate = async () => {
  const permissionStatus = await navigator?.permissions?.query({ name: 'geolocation' });
  const hasPermission = permissionStatus?.state;

  return hasPermission;
};

const toRadians = (x: number) => {
  return x * 0.017453292519943295; // x * Math.PI / 180
};

const getDistance = (currentLocation: UserLocationTypes, position: UserLocationTypes) => {
  const { lat: latitude, lng: longitude } = position || {};
  const radius = 6371000; // earth radius in meters
  const x1 = latitude - currentLocation.lat;
  const x2 = longitude - currentLocation.lng;
  const deltaLatitude = toRadians(x1);
  const deltaLongitude = toRadians(x2);
  const a =
    Math.sin(deltaLatitude / 2) * Math.sin(deltaLatitude / 2) +
    Math.cos(toRadians(currentLocation.lat)) *
      Math.cos(toRadians(latitude)) *
      Math.sin(deltaLongitude / 2) *
      Math.sin(deltaLongitude / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = radius * c;
  return distance;
};

const findClosestStore = (stores: StoreMarker[], userLocation: any) => {
  const pos = userLocation;
  const closest = stores
    ?.map((store) => ({ ...store, distance: getDistance(pos, store?.position) }))
    .reduce((acc, loc) => (!!acc?.distance && !!loc?.distance && acc?.distance < loc?.distance ? acc : loc));

  return closest;
};

export { findClosestStore, getMapBounderies, getStoreMarkers, hasPermissionToLocate };
