export const LOGIN_TAB_BANKID = 'bankId';
export const LOGIN_TAB_PASSWORD = 'password';
export type LoginTabType = typeof LOGIN_TAB_BANKID | typeof LOGIN_TAB_PASSWORD;

export const SAME_DEVICE = 'sameDevice';
export const OTHER_DEVICE = 'otherDevice';
export type BANK_ID_DEVICES = typeof SAME_DEVICE | typeof OTHER_DEVICE | '';

export const BANKID_METHOD_QR = 'qr';
export type BankIdMethodsType = typeof BANKID_METHOD_QR | '';

export const CUSTOMER_NOT_LOGGED_IN = 'CUSTOMER_NOT_LOGGED_IN';
export const LOGIN_CONFIRM = 'LOGIN_CONFIRM';
export type LoginAlertType = typeof CUSTOMER_NOT_LOGGED_IN | typeof LOGIN_CONFIRM | '';
