import SanitizedHtml from '@components/atoms/SanitizedHtml/SanitizedHtml';
import ColumnComponentContext from '@context/ColumnComponentContext';
import { useContext } from 'react';
import { StyledCmsHtml } from '../StyledCmsHtml.styles';
import { Container } from './CMSParagraphComponent.styles';

export interface CMSParagraphComponentProps {
  component: CMSParagraphComponent;
}
const CMSParagraphComponent = ({ component }: CMSParagraphComponentProps) => {
  const { isMultiRowComponent = false } = useContext(ColumnComponentContext);
  if (!component || !component.content) {
    return null;
  }

  return (
    <Container $withPadding={!isMultiRowComponent}>
      <StyledCmsHtml>
        <SanitizedHtml content={component.content} />
      </StyledCmsHtml>
    </Container>
  );
};
export default CMSParagraphComponent;
