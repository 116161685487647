import { DEFAULT_SANITIZE } from '@constants/formValidation';
import React, { ReactNode } from 'react';
import { FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { Container, DefaultLabel, Error, StyledTextArea } from './TextArea.styles';

export interface Props<FormData extends FieldValues> {
  label: string | ReactNode;
  name: Path<FormData>;
  id: string;
  register: UseFormRegister<FormData>;
  rules?: RegisterOptions;
  error?: string;
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  setValueAs?: (value: any) => void;
  disableAutoComplete?: boolean;
  placeholder?: string;
  rows?: number;
  cols?: number;
}

/**
 * @summary textarea component to be used with react-hook-form
 * FormData type passed to Props comes from the useForm in the parent component
 */

const TextArea = <FormData extends FieldValues>({
  label,
  name,
  error = '',
  maxLength,
  register,
  rules,
  id,
  onChange,
  onBlur,
  setValueAs,
  placeholder,
  rows = 5,
  cols = 5,
}: Props<FormData>) => {
  let options: RegisterOptions = rules ? rules : {};
  options.pattern = rules?.pattern ? rules.pattern : DEFAULT_SANITIZE;
  return (
    <Container>
      <DefaultLabel $required={!!rules?.required} htmlFor={name}>
        {label}
      </DefaultLabel>
      <StyledTextArea
        id={id}
        aria-invalid={!!error}
        maxLength={maxLength}
        rows={rows}
        cols={cols}
        required={!!rules?.required}
        placeholder={placeholder}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...register(name, { onChange, onBlur, setValueAs, ...options })}
      />
      {error && <Error data-testid="textarea-error">{error}</Error>}
    </Container>
  );
};

export default TextArea;
