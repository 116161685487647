import { ButtonSize } from '@components/atoms/Button/Button';
import LinkButton from '@components/atoms/Button/LinkButton';
import { MultiSearchHookReturn } from '@components/organisms/Header/Search/useMultiSearchAsYouType';
import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';
import SuggestionList from '../SuggestionList';
import { CategoryListItem, ScrollableList } from './CategoryLinks.styles';
import { CategorySkeletons } from './CategoryLinksSkeleton';

// TODO: This component could easily be refactored to be a more generic component for any array och link items
type Categories = Pick<MultiSearchHookReturn, 'categories'>;
interface Props extends Categories {
  showSkeletons: boolean;
  onCategoryClick?: (name: string) => void;
  withHeader?: boolean;
  buttonSize?: ButtonSize;
}

const CategoryLinksWithScroll = ({
  categories,
  showSkeletons,
  onCategoryClick,
  withHeader = false,
  buttonSize = 'sm',
}: Props) => {
  const { t } = useTranslation('common');
  const hasCategories = !!categories.length;
  const showCategories = hasCategories || showSkeletons;

  const handleCategoryClick = (name: string) => {
    if (onCategoryClick) {
      onCategoryClick(name);
    }
  };
  return (
    <>
      {showCategories && (
        <SuggestionList title={t('search->categories')} withHeader={withHeader}>
          <ScrollableList>
            {showSkeletons ? (
              <CategorySkeletons buttonSize={buttonSize} />
            ) : (
              categories.map(({ name, url }) => (
                <CategoryListItem key={name} data-testid="category-suggestion-link">
                  <LinkButton variant="secondary" size={buttonSize} href={url} onClick={() => handleCategoryClick(name)}>
                    {name}
                  </LinkButton>
                </CategoryListItem>
              ))
            )}
          </ScrollableList>
        </SuggestionList>
      )}
    </>
  );
};

export default memo(CategoryLinksWithScroll);
