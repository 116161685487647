import { MenuLink } from '@components/molecules/AccountMenuItem/AccountMenuItem';
import AccountMenuPanel from '../AccountMenuPanel';
import { DropDownMenuContainer } from './AccountDropdownMenu.styles';

interface AccountDropDownProps {
  links: MenuLink[];
  onLinkClicked?: () => void;
}

const AccountDropdownMenu = ({ links, onLinkClicked }: AccountDropDownProps) => {
  return (
    <DropDownMenuContainer>
      <AccountMenuPanel links={links} onLinkClicked={onLinkClicked} data-testid={'account-dropdown-menu'} />
    </DropDownMenuContainer>
  );
};

export default AccountDropdownMenu;
