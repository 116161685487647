import Tab from '@components/atoms/Tab';
import { TabType } from '@components/atoms/Tab/types';
import React, { useEffect, useState } from 'react';
import { InnerTabsContainer, TabsContainer } from './Tabs.styles';

export declare type Variant = 'default' | 'filled' | 'paged';

interface Props {
  tabs: Array<TabType>;
  onChange: (tabId: string) => void;
  initialActiveTabId?: string;
  variant?: Variant;
  canHaveDisabledTabs?: boolean;
  canHaveNoActiveTab?: boolean;
}

const Tabs = ({
  variant = 'default',
  tabs,
  onChange,
  initialActiveTabId,
  canHaveDisabledTabs = false,
  canHaveNoActiveTab = false,
}: Props) => {
  const [activeTab, setActiveTab] = useState(initialActiveTabId);

  const onClickTabItem = (tabId: string) => {
    if (canHaveDisabledTabs) {
      const currentlyActiveTab = tabs.find((tab) => tab.id === tabId);
      if (currentlyActiveTab?.hasData) {
        if (canHaveNoActiveTab) {
          setActiveTab(activeTab === tabId ? '' : tabId);
          onChange(activeTab === tabId ? '' : tabId);
        } else {
          setActiveTab(tabId);
          onChange(tabId);
        }
      }
    } else {
      setActiveTab(tabId);
      onChange(tabId);
    }
  };

  useEffect(() => {
    setActiveTab(initialActiveTabId);
  }, [initialActiveTabId]);

  return (
    <TabsContainer $variant={variant}>
      <InnerTabsContainer $variant={variant}>
        {tabs.map((tab: TabType) => {
          return (
            <Tab
              variant={variant}
              icon={tab.icon}
              id={tab.id}
              tabName={tab.translation}
              isActive={activeTab === tab.id}
              onClick={onClickTabItem}
              key={tab.id}
              disabled={canHaveDisabledTabs && !tab.hasData}
              tooltip={tab.tooltip}
              data-test-id={tab.testId}
            />
          );
        })}
      </InnerTabsContainer>
    </TabsContainer>
  );
};

export default Tabs;
