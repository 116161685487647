const B2C_REGISTER = '/registrera/privat/identifiera';
const MY_BENEFITS = '/mina-sidor/mina-formaner';
const LINKS = {
  B2B_CREATE_ACCOUNT: '/registrera/foretag/skapa',
  B2B_REGISTER: '/registrera/foretag/identifiera',
  B2C_CREATE_ACCOUNT: '/registrera/privat/skapa', // React specific path
  B2C_REGISTER,
  B2C_COMPLETE: '/registrera/privat/slutfor', // React specific path
  B2C_WELCOME: '/registrera/privat/valkommen',
  B2B_WELCOME: '/registrera/foretag/valkommen',
  BONUS_TERMS: '/artikel/bonusvillkor',
  CART: '/varukorg',
  CHECKOUT: '/kassa',
  CHECKOUT_CONFIRMATION: '/kassa/bekraftelse',
  CLUB_INFORMATION: '/mina-sidor/klubbinfo',
  CLUB_TERMS: '/artikel/klubbvillkor',
  CONDITIONS_ARTICLE_PAGE: '/artikel/anvandarvillkor',
  CONDITIONS_B2B_ARTICLE_PAGE: '/artikel/foretagvillkor',
  DELIVERY_METHOD_HOME: '/leverans/valj/hem',
  DELIVERY_METHOD_PICKUP: '/leverans/valj/hamta',
  FAQ: '/faq',
  CUSTOMER_SERVICE: '/artikel/kundtjanst',
  FIND_STORE: '/butik-sok',
  GDPR: '/artikel/gdpr',
  GDPR_B2B: '/artikel/gdpr_foretag',
  GOOGLE: 'https://google.com',
  GUEST_ORDER: '/min-order/',
  LOGIN: '/anvandare/inloggning',
  LOGOUT: '/logout',
  B2B_LOST_PASSWORD: '/glomt-losenord/foretag',
  LOST_PASSWORD: '/glomt-losenord/privat',
  LOST_PASSWORD_RESET_FROM_EMAIL: '/glomt-losenord/aterstall-losenord-fran-email',
  B2B_LOST_PASSWORD_DEPRECATED: '/anvandare/glomt-losenord',
  LOST_PASSWORD_DEPRECATED: '/anvandare/identifiera/glomt-losenord',
  B2B_PROFILE: '/mina-sidor/konto-uppgifter',
  PROFILE: '/mina-sidor',
  MY_PROFILE: '/mina-sidor/min-profil',
  MY_LISTS: '/mina-sidor/mina-listor',
  MY_ORDERS: '/mina-sidor/kop',
  MY_MEMBERSHIP: '/mina-sidor/mitt-medlemskap',
  MY_BENEFITS,
  OFFERS: '/erbjudanden',
  ORDER_CONFIRMATION: '/mina-sidor/kop/',
  PERSONALIZATION: '/artikel/personifiering',
  PRESS_ROOM: '/press',
  PRODUCT_DETAIL: '/produkt/:name',
  RECIPE: '/recept/',
  RECIPES_LANDING_PAGE: '/receptsok',
  REGISTER_B2C_DEPRECATED: B2C_REGISTER, // TODO: remove this deprecated path after release of onboarding
  SEARCH: '/sok',
  SHOP: '/handla',
  START: '/',
  STORE: '/butik',
  THIS_WEEKS_PROMOTIONS: '/veckans-erbjudanden',
  VOUCHERS: `${MY_BENEFITS}/#ax-personal-vouchers-section`,
};

export const URLPREFIXES: { [key: string]: string } = {
  CATEGORIES: '/sortiment/',
  DELIVERY_METHOD: '/leverans/valj/',
  PRODUCT_DETAIL: '/produkt/',
  ARTIKEL: '/artikel',
  MY_ORDERS: '/mina-sidor/kop/',
  MY_LISTS: '/mina-sidor/mina-listor/',
};

export const ROUTE_BY_REPLACE_ROUTES_IN_NATIVE_APP = [
  LINKS.DELIVERY_METHOD_HOME,
  LINKS.DELIVERY_METHOD_PICKUP,
  LINKS.LOGIN,
  URLPREFIXES.PRODUCT_DETAIL,
];

export default LINKS;
