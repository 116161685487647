import { ContentSlotWsDTOWithComponent } from '@api/interfaces/collectSlots';
import CmsComponent from '@components/cmsComponents/CmsComponent';
import GlobalMessages from '@components/molecules/MessageBox/GlobalMessages';
import { memo } from 'react';
import { Container } from './MessageBox.styles';

const MessageBox = ({ messageBoxSlot }: { messageBoxSlot: ContentSlotWsDTOWithComponent | undefined }) => {
  const messageBoxSlotComponents = messageBoxSlot?.component || [];
  return (
    <Container>
      <GlobalMessages messageBoxSlot={messageBoxSlot} />
      {messageBoxSlotComponents
        .filter((c: CmsComponent) => c?.typeCode !== 'AxfoodDeliveryClosableContainer')
        .map((c: CmsComponent) => (
          <CmsComponent key={c?.uid} component={c} excludeWrapper />
        ))}
    </Container>
  );
};

export default memo(MessageBox);
