import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
`;

export const PromotionLabel = styled.div`
  margin: 0;
  color: ${({ theme }) => theme.colors.colorPrimary};
  display: block;
  height: ${rem(14)};
  ${media.tabletPortrait} {
    height: ${rem(18)};
  }
  p {
    font-size: ${rem(10)};
    ${media.tabletPortrait} {
      font-size: ${rem(12)};
    }
  }
`;

export const ProductTitle = styled.div`
  margin: 0;
  a,
  p {
    margin: 0;
    opacity: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      color: ${({ theme }) => theme.colors.colorTextPrimary};
    }

    ${media.tabletPortrait} {
      font-size: ${rem(14)};
    }
  }
`;

export const ProductManufacturerVolume = styled.div`
  min-height: ${rem(14)};
  margin: ${rem(2)} 0 0;
`;

export const ProductManufacturer = styled.span`
  text-transform: uppercase;
`;

export const ProductComparePrices = styled.div`
  margin: ${rem(3)} 0;
  height: ${rem(38)};
  ${media.tabletPortrait} {
    height: ${rem(50)};
  }
`;

export const Discount = styled.div`
  color: ${({ theme }) => theme.colors.colorPrimary};
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`;
