import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const BaseFlyerContainer = styled.div`
  min-height: ${rem(244)};
  display: grid;
  grid-template-areas:
    'vector'
    'content';
  grid-template-rows: ${rem(190)} auto;
  border-radius: ${rem(24)};
  background-color: ${({ theme }) => theme.colors.colorLinen};
  overflow-y: hidden;

  ${media.tabletPortrait} {
    grid-template-areas: 'content vector';
    grid-template-rows: auto;
    border-radius: ${rem(32)};
    grid-template-columns: minmax(${rem(300)}, 1fr) minmax(${rem(200)}, 1fr);
    padding: 0 ${rem(48)} 0 ${rem(64)};
    gap: 0;
    height: ${rem(320)};
  }

  ${media.tabletLandscape} {
    grid-template-columns: minmax(${rem(417)}, 1fr) minmax(0, 1fr);
    height: ${rem(320)};
  }

  ${media.desktop} {
    grid-template-columns: auto minmax(0, ${rem(800)});
    column-gap: ${rem(16)};
  }
`;

export const BaseContent = styled.div`
  grid-area: content;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${rem(32)};
  padding: ${rem(24)} ${rem(16)};
  background-color: ${({ theme }) => theme.colors.colorWhite};
  border-radius: 0 0 ${rem(24)} ${rem(24)};

  ${media.tabletPortrait} {
    background-color: transparent;
    align-items: flex-start;
    justify-content: center;
    border-radius: 0 0 ${rem(32)} ${rem(32)};
    flex: 1;
    padding: 0;
    max-width: ${rem(450)};
    height: ${rem(320)};
  }
`;

export const BaseVectorContainer = styled.div`
  grid-area: vector;
  display: flex;
  width: 100%;
  height: ${rem(314)};

  ${media.tabletPortrait} {
    height: 100%;
    align-self: end;
    justify-content: end;
    padding: ${rem(65)} 0 0 0;
  }

  ${media.desktopXL} {
    padding: ${rem(16)} 0 0 0;
  }

  ${media.desktopXXL} {
    padding: ${rem(8)} 0 0 0;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;
