import cartActions from '@actions/cart';
import { setDeliveryActiveStoreAndCosts } from '@actions/delivery';
import getActiveStore from '@api/getActiveStore';
import setActiveStore from '@api/setActiveStore';
import setCartRestore from '@api/setCartRestore';
import { useAppDispatch } from '@hooks/redux';
import { loginTracker } from '@trackers';
import axios from 'axios';
import { usePromotionStore } from './localStorage';
import useCustomer from './useCustomer';

const useHandleCartRestore = () => {
  const dispatch = useAppDispatch();
  const { mutateCustomer } = useCustomer();
  const [, updatePromotionStore] = usePromotionStore();

  const handleCartRestore = async (actionTo: string, isThrowingExeption?: boolean) => {
    const source = axios.CancelToken.source();
    try {
      // restoreCart with chosen resolve type, sync returned cart to redux
      const cartResponse = await setCartRestore(actionTo);
      dispatch(cartActions.sortAndSetCart(cartResponse.data));
      dispatch(cartActions.setCartTotalProductAmount(cartResponse.data.totalUnitCount));

      // get the logged in customer and sync to redux
      /*
      Very important that this is not done until the restore cart is finished.
      Since a change of user in redux will trigger re-fetching to various backend endpoints.
      Such requests might trigger updating the session cart in backend, while in the middle of
      a possible merge between persistant cart and session cart
      */

      const customer = await mutateCustomer();
      const {
        customerId,
        isB2BCustomer,
        latestLoginIsAutoLogin,
        idInSourceSystem,
        isLocalB2BCustomer,
        localCustomerAgreement,
        storeId,
      } = customer || {};
      loginTracker.trackUser({
        customerId,
        isB2BCustomer,
        latestLoginIsAutoLogin,
        idInSourceSystem,
        userLogin: 'user_login',
      });

      // get activeStore and sync to redux
      if (isLocalB2BCustomer) {
        const activate = await setActiveStore(
          {
            storeId: localCustomerAgreement?.storeId || storeId,
            activelySelected: true,
            forceAsPickingStore: true,
          },
          source.token
        );
        dispatch(setDeliveryActiveStoreAndCosts(activate?.data));
        updatePromotionStore({
          promotionStoreId: activate.data.storeId,
        });
      } else {
        const storeResponse = await getActiveStore();
        dispatch(setDeliveryActiveStoreAndCosts(storeResponse.data));
      }
    } catch (error) {
      source.cancel('Cancel on login submit success');
      // Following prop can be used to throw an error forward:
      if (isThrowingExeption) {
        throw new Error('Cart restore error');
      }
    }
  };

  return handleCartRestore;
};

export default useHandleCartRestore;
