import media from '@constants/responsive';
import styled from 'styled-components';

// This component should be used to wrap items in a carousel

export const CarouselListItem = styled.li`
  scroll-snap-align: start;
  scroll-snap-stop: always;
  transform-origin: center center;
  position: relative;

  --width-factor: 1;
  width: calc((var(--width-factor) * 100cqw) / 2);

  padding: 0 0.25rem;

  @container carousel (min-width: 704px) {
    width: calc((var(--width-factor) * 100cqw) / 3);
    padding: 0 0.5rem;
  }
  @container carousel (min-width: 838px) {
    width: calc((var(--width-factor) * 100cqw) / 4);
  }
  @container carousel (min-width: 1089px) {
    width: calc((var(--width-factor) * 100cqw) / 5);
  }
  @container carousel (min-width: 1310px) {
    width: calc((var(--width-factor) * 100cqw) / 6);
  }
  @container carousel (min-width: 1531px) {
    width: calc((var(--width-factor) * 100cqw) / 7);
  }
  @container carousel (min-width: 1752px) {
    width: calc((var(--width-factor) * 100cqw) / 8);
  }
`;

export const PromoListItem = styled(CarouselListItem)`
  ${media.tabletLandscape} {
    --width-factor: 2;
  }
`;

export default CarouselListItem;
