import logError from '@helpers/logHelpers';
import { MutableRefObject, useEffect, useRef, useState } from 'react';

const useIntersection = <T extends HTMLElement>({
  threshold = 0.1,
  root = null,
  rootMargin = '0px',
}: IntersectionObserverInit = {}): [MutableRefObject<T | null>, IntersectionObserverEntry | undefined] => {
  const containerRef = useRef<T | null>(null);
  const [intersectionObject, setIntersectionObject] = useState<IntersectionObserverEntry>();

  const callbackFunction = (entries: IntersectionObserverEntry[], isMounted: boolean) => {
    const [entry] = entries;
    isMounted && setIntersectionObject(entry);
  };

  useEffect(() => {
    let isMounted = true;
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport) {
      return () => {
        logError('Browser does not support InterSection Observer API', { message: '', responseData: '' });
      };
    }

    const observer = new IntersectionObserver((entries) => callbackFunction(entries, isMounted), {
      threshold,
      root,
      rootMargin,
    });
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      observer.disconnect();
      isMounted = false;
    };
  }, [containerRef, threshold, root, rootMargin]);

  return [containerRef, intersectionObject];
};

export default useIntersection;
