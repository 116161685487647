import { CategoryLinksWithScroll } from '@components/organisms/CategoryLinks';
import { MultiSearchLink } from '@helpers/productMapper';
import useResponsive from '@hooks/useResponsive';
import { CategoryLinksWrapper, Container, Divider, SuggestionLinksWrapper } from './SearchLinks.styles';
import SuggestionLinks from './SuggestionLinks';

export interface Props {
  suggestions: MultiSearchLink[];
  categories: MultiSearchLink[];
  onSuggestionClick: (term: string) => void;
  onCategoryClick: (category: string) => void;
  showSkeletons?: boolean;
}

export const SearchLinks = ({
  suggestions,
  categories,
  onSuggestionClick,
  onCategoryClick,
  showSkeletons = false,
}: Props) => {
  const { isTabletPortraitOrGreater } = useResponsive();
  const isMobile = !isTabletPortraitOrGreater;

  const hasSuggestions: boolean = suggestions.length > 0;
  const hasCategories: boolean = categories.length > 0;
  const hasData = hasSuggestions || hasCategories;

  if (!(showSkeletons || hasData)) {
    return null;
  }

  const showDivider: boolean = isMobile && hasData;

  return (
    <Container>
      {(showSkeletons || hasSuggestions) && (
        <SuggestionLinksWrapper>
          <SuggestionLinks
            showSkeletons={showSkeletons}
            searchSuggestions={suggestions}
            onClickSearchTerm={onSuggestionClick}
          />
        </SuggestionLinksWrapper>
      )}
      {(showSkeletons || hasCategories) && (
        <CategoryLinksWrapper>
          <CategoryLinksWithScroll
            showSkeletons={showSkeletons}
            categories={categories}
            onCategoryClick={onCategoryClick}
            withHeader
          />
        </CategoryLinksWrapper>
      )}
      {showDivider && <Divider />}
    </Container>
  );
};

export default SearchLinks;
