import LinkArea from '@components/atoms/__DEPRECATED__/LinkArea';
import IconHemkop from '@public/icons/colorIcons/icon-hemkop.svg';
import { navigationClickTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { StyledLogo } from './Logo.styles';

interface LogoProps {
  imageData?: SimpleMedia;
}

const Logo = ({ imageData }: LogoProps) => {
  const { t } = useTranslation('common');
  const onLogoClick = () => {
    navigationClickTracker.linkClickTracker('header', 'Logo');
  };

  return (
    <LinkArea aria-label={t('ariaLabels->logo')} forceInternal href="/" onClick={onLogoClick}>
      {imageData ? (
        <Image src={imageData.url} alt={imageData.altText} width={128} height={55} priority />
      ) : (
        <StyledLogo icon={IconHemkop} data-testid="logo" />
      )}
    </LinkArea>
  );
};

export default Logo;
