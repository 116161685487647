import Icon from '@components/atoms/Icon';
import Link from '@components/atoms/__DEPRECATED__/Link';
import LINKS from '@constants/links';
import NOTIFICATIONS from '@constants/notifications';
import IconContinue from '@public/icons/regularIcons/icon-continue.svg';
import IconTag from '@public/icons/regularIcons/icon-tag.svg';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { usePopper } from 'react-popper';
import Notification from '../../organisms/Header/Notification/Notification';
import { LeftContainer, LinkWrapper, VoucherPop } from './VoucherNotifications.styles';

interface Props {
  popperReference?: HTMLElement | null;
}

const VoucherNotification = ({ popperReference }: Props) => {
  const { t } = useTranslation('toolbar');
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(popperReference, popperElement, {
    placement: 'bottom-end',
  });

  return (
    <div ref={setPopperElement as any} style={{ ...styles.popper, zIndex: 100 }} {...attributes.popper}>
      <Notification notificationName={NOTIFICATIONS.VOUCHER_NOTIFICATION} timer={5000}>
        <VoucherPop>
          <LeftContainer>
            <Icon icon={IconTag} data-testid="icon-tag" />
            <LinkWrapper>
              <Link href={LINKS.VOUCHERS} scroll={false}>
                {t('toolbar->voucherNotification')}
              </Link>
            </LinkWrapper>
          </LeftContainer>
          <Icon icon={IconContinue} />
        </VoucherPop>
      </Notification>
    </div>
  );
};

export default VoucherNotification;
