import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';

const productDetailStructuredData = (product: AxfoodProductDetailsViewModel) => {
  const productSchemaDescription = product?.description ? `${product?.name}. ${product?.description}` : '';
  const productInStock = product?.outOfStock ? 'OutOfStock' : 'InStock';

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    description: productSchemaDescription,
    name: product?.name,
    image: product?.image?.url,
    offers: {
      '@type': 'Offer',
      availability: `https://schema.org/${productInStock}`,
      price: product?.priceValue,
      priceCurrency: 'SEK',
    },
  };
  return structuredData;
};

export default productDetailStructuredData;
