import React from 'react';
import { HeadingStyled } from './Heading.styles';
import { HeadingSize, HeadingType } from './types';

export interface Props {
  children: React.ReactNode;
  type: HeadingType;
  size?: HeadingSize;
  bold?: boolean;
  color?: ColorType;
  className?: string;
  'data-testid'?: string;
  align?: 'left' | 'right' | 'center';
  truncateText?: boolean;
}

/**
 * @deprecated Use new Heading component
 */
const Heading = ({
  children,
  type,
  color = 'red',
  size = '',
  bold = false,
  className,
  align,
  'data-testid': testId = undefined,
  truncateText,
}: Props) => {
  return (
    <HeadingStyled
      $type={type}
      $color={color}
      $bold={bold}
      $size={size}
      className={className}
      data-testid={testId}
      $align={align}
      $truncate={truncateText}
    >
      {children}
    </HeadingStyled>
  );
};

export default Heading;
