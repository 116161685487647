/* eslint-disable react/jsx-props-no-spreading */
import Icon from '@components/atoms/Icon';
import Button from '@components/atoms/__DEPRECATED__/Button';
import CartAddToListPopOver from '@components/organisms/CartAddToListPopOver';
import { useAppSelector } from '@hooks/redux';
import useCustomer from '@hooks/useCustomer';
import useOnClickOutside from '@hooks/useOnClickOutside';
import useResponsive from '@hooks/useResponsive';
import useRouteToLogin from '@hooks/useRouteToLogin';
import IconAddToList from '@public/icons/colorIcons/icon-add-to-list.svg';
import { selectCartHasProducts } from '@selectors/cart';
import { addToShoppingListTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { AddToShoppingListEvent } from 'trackers/addToShoppingList';
import { AddToListPopper, AddToListWrapper } from './CartAddToList.styles';
interface CartAddToListProps {
  eventCategory: AddToShoppingListEvent['category'];
  popperPlacement?: 'top-end'; // for valid options, see Placement type of popperJS, which isn't exported :(
}

const CartAddToList = ({ eventCategory, popperPlacement }: CartAddToListProps) => {
  const { t } = useTranslation('minicart');
  const [addToListPopoverVisible, setAddToListPopoverVisible] = useState(false);
  const cartHasProducts = useAppSelector(selectCartHasProducts);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { isTabletPortraitOrGreater } = useResponsive();
  const { isAnonymous = false } = useCustomer();
  const clickOutsideRef = useRef(null);
  const { routeToLogin } = useRouteToLogin();

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: popperPlacement || 'bottom-start',
  });

  useOnClickOutside(clickOutsideRef, () => {
    setAddToListPopoverVisible(false);
  });

  const trackClickAddToList = () => {
    addToShoppingListTracker({ category: eventCategory, action: 'list_icon_clicked', label: '' });
  };

  const handleAddToList = () => {
    trackClickAddToList();
    if (isAnonymous) {
      routeToLogin({ b2b: false, isLoginConfirm: false });
    } else {
      setAddToListPopoverVisible(!addToListPopoverVisible);
    }
  };

  const hideAddToListPopover = () => {
    setAddToListPopoverVisible(false);
  };

  return (
    <AddToListWrapper ref={clickOutsideRef}>
      {cartHasProducts && (
        <div ref={setReferenceElement as any}>
          <Button onClick={handleAddToList} variant="none" data-testid="add-to-list-button">
            <Icon margin="right" icon={IconAddToList} data-testid="icon-add-to-list" />
            {t('minicart->buttons->addToList')}
          </Button>
        </div>
      )}
      {addToListPopoverVisible && (
        <>
          {isTabletPortraitOrGreater ? (
            <AddToListPopper ref={setPopperElement as any} style={styles.popper} {...attributes.popper}>
              <CartAddToListPopOver
                onClose={hideAddToListPopover}
                eventCategory={eventCategory}
                data-testid="add-to-list-popover"
              />
            </AddToListPopper>
          ) : (
            <CartAddToListPopOver
              onClose={hideAddToListPopover}
              eventCategory={eventCategory}
              data-testid="add-to-list-popover"
            />
          )}
        </>
      )}
    </AddToListWrapper>
  );
};

export default CartAddToList;
