import { formatAddress } from '@helpers/address';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import { selectCartSlotPickupStoreId } from './cart';
import getState from './getState';

const selectDeliveryState = createSelector(getState, (state: RootState) => state.delivery);

export const selectDeliveryModal = createSelector(selectDeliveryState, (state) => state.modal);
export const selectDeliveryModalIsVisible = createSelector(selectDeliveryModal, (state) => state?.visible);

export const selectActiveStore = createSelector(selectDeliveryState, (state) => state.active);
export const selectActiveStoreId = createSelector(selectActiveStore, (state) => state?.storeId);
export const selectActiveStoreName = createSelector(selectActiveStore, (state) => state?.name);

export const selectActiveStoreAddress = createSelector(selectActiveStore, (state) => state?.address);

export const selectActiveStoreFullAddress = createSelector(selectActiveStore, (state) => {
  const address = state?.address;

  if (!address) {
    return undefined;
  }
  const { line1 = '', postalCode = '', town = '' } = address;
  return formatAddress(line1, postalCode, town);
});

export const selectDeliveryStores = createSelector(selectDeliveryState, (state) => state.stores);

export const selectDeliveryCosts = createSelector(selectDeliveryState, (state) => state.deliveryCosts);

export default selectDeliveryState;

export const selectAssortmentStoreId = createSelector(
  selectActiveStoreId,
  selectCartSlotPickupStoreId,
  (storeId, pickupStoreId) => pickupStoreId || storeId
);
