const ImboxWidgetScript = `
var _sid = '2855';
 (function() {
 var se = document.createElement('script'); se.type = 'text/javascript';
se.async = true;
 se.src = ('https:' == document.location.protocol ? 'https://' : 'http://') +
'files.imbox.io/app/dist/initWidget.js';
 var s = document.getElementsByTagName('script')[0];
s.parentNode.insertBefore(se, s);
 })();`;

export default ImboxWidgetScript;
