import { useAppSelector } from '@hooks/redux';
import { selectCartOrderReference } from '@selectors/cart';
import OrderChangeBanner from '../OrderChangeBanner';

/**
 * A wrapper component for holidng all reminders and the logic for displaying them.
 */

const Reminders = () => {
  const orderReference = useAppSelector(selectCartOrderReference);
  return <>{orderReference && <OrderChangeBanner />}</>;
};

export default Reminders;
