import { MediaData } from '@api/generated/storefront';
import { BREAKPOINTS } from '@constants/responsive';
import useResponsive from '../../../hooks/useResponsive';
import { PictureImgStyled } from './Picture.styles';

type AltTextMediaData = MediaData & { altText?: string };
export type Media = {
  desktop?: AltTextMediaData;
  tablet?: AltTextMediaData;
  mobile?: AltTextMediaData;
};
type PictureProps = {
  media: Media;
  shouldLoadEager?: boolean;
  fallbackAlt?: string;
};

const MOBILE_MEDIA = `(max-width: ${BREAKPOINTS.sm - 1}px)`;
const TABLET_MEDIA = `(min-width: ${BREAKPOINTS.sm}px) and (max-width: ${BREAKPOINTS.md - 1}px)`;
const DESKTOP_MEDIA = `(min-width: ${BREAKPOINTS.lg}px)`;

const Picture = ({ media, shouldLoadEager = false, fallbackAlt = '' }: PictureProps) => {
  const { isTabletPortraitOrGreater, isDesktopOrGreater } = useResponsive();

  const getAlt = (): string => {
    if (isDesktopOrGreater && media?.desktop?.altText) {
      return media.desktop.altText;
    }
    if (isTabletPortraitOrGreater && media?.tablet?.altText) {
      return media.tablet.altText;
    }
    if (media?.mobile?.altText) {
      return media.mobile.altText;
    }
    return fallbackAlt;
  };
  return (
    <picture data-testid="picture">
      {media?.mobile?.url && <source media={MOBILE_MEDIA} srcSet={media?.mobile.url} data-testid="mobile-source" />}
      {media?.tablet?.url && (
        <source media={TABLET_MEDIA} srcSet={media?.tablet.url} data-testid="tablet-portrait-source" />
      )}

      {media?.desktop?.url && <source media={DESKTOP_MEDIA} srcSet={media?.desktop.url} data-testid="desktop-source" />}
      <PictureImgStyled
        src={media?.desktop?.url || media?.tablet?.url || media?.mobile?.url}
        alt={getAlt()}
        data-testid="image"
        loading={shouldLoadEager ? 'eager' : 'lazy'}
      />
    </picture>
  );
};

export default Picture;
