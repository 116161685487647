import SwipeableWrapper from '@components/atoms/SwipeableWrapper';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import Carousel from '@components/molecules/__DEPRECATED__/Carousel';
import { IntersectionObserverComponentProps } from '@helpers/componentTracking';
import { getNotAllowedAnonymousProducts, getNotAllowedB2bProducts } from '@helpers/conflictingProducts';
import Heading from 'components/atoms/__DEPRECATED__/Heading';
import useTranslation from 'next-translate/useTranslation';
import { SwipeEventData } from 'react-swipeable';
import { ConflictedProductWrapperHeader, ConflictedProductsWrapper, Slide, TextWrapper } from './ConflictModal.styles';
import NonReplaceableProductsWithTracking from './NonReplaceableProductsWithTracking';
import SlideButtons from './SlideButtons';
import useConflictModalSlides from './useConflictModalSlides';
import type { AxfoodCartEntryStatusViewModel } from '@occ/api-client';

export const TABLET_LANDSCAPE_PRODUCTS_PER_SLIDE = 4;
export const TABLET_PRODUCTS_PER_SLIDE = 3;
export const MOBILE_PRODUCTS_PER_SLIDE = 2;

interface ProductCarouselProps extends IntersectionObserverComponentProps {
  products: AxfoodCartEntryStatusViewModel[];
  notAllowedAnonymousProducts: AxfoodCartEntryStatusViewModel[];
}

const ProductCarousel = ({ products, intersectionRef, notAllowedAnonymousProducts }: ProductCarouselProps) => {
  const { productsForCarouselSlides, incrementOffset, decrementOffset, offset } = useConflictModalSlides({
    tabletSlides: TABLET_PRODUCTS_PER_SLIDE,
    tabletLandscapeSlides: TABLET_LANDSCAPE_PRODUCTS_PER_SLIDE,
    mobileSlides: MOBILE_PRODUCTS_PER_SLIDE,
    products,
  });

  const touchSlideHandler = (e: SwipeEventData) => {
    e.dir === 'Left' && incrementOffset();
    e.dir === 'Right' && decrementOffset();
  };
  return (
    <>
      {productsForCarouselSlides.length > 1 && (
        <ConflictedProductWrapperHeader $childrenRightAligned>
          <SlideButtons
            decrementOffset={decrementOffset}
            offset={offset}
            incrementOffset={incrementOffset}
            amountOfProductsForCarousel={productsForCarouselSlides.length}
          />
        </ConflictedProductWrapperHeader>
      )}
      <ConflictedProductsWrapper ref={intersectionRef}>
        <SwipeableWrapper onSwiped={touchSlideHandler}>
          <Carousel offset={offset}>
            {(productsForCarouselSlides)
              .filter((slide): slide is AxfoodCartEntryStatusViewModel[] => slide.every(item => 'entryProduct' in item))
              .map((slide, i) => (
              <Slide key={slide[0].entryProduct?.code} data-testid="slide">
                <NonReplaceableProductsWithTracking
                  conflictProducts={slide}
                  slideIndex={i + 1}
                  slideSize={productsForCarouselSlides[0].length}
                  notAllowedAnonymousProducts={notAllowedAnonymousProducts}
                />
              </Slide>
            ))}
          </Carousel>
        </SwipeableWrapper>
      </ConflictedProductsWrapper>
    </>
  );
};

interface NonReplaceableProductsProps {
  nonReplaceableProducts: AxfoodCartEntryStatusViewModel[];
}

const NonReplaceableProducts = ({ nonReplaceableProducts }: NonReplaceableProductsProps) => {
  const { t } = useTranslation('conflictModal');

  if (nonReplaceableProducts.length === 0) return null;

  const notAllowedAnonymousProducts = getNotAllowedAnonymousProducts(nonReplaceableProducts);
  const notAllowedB2bProducts = getNotAllowedB2bProducts(nonReplaceableProducts);
  const hasNotAllowedB2bProducts = !!notAllowedB2bProducts.length;
  const textCopyKey = hasNotAllowedB2bProducts
    ? 'conflictModal->nonReplaceableTextB2b'
    : 'conflictModal->nonReplaceableText';
  return (
    <>
      <TextWrapper>
        <Heading type="h2">{t('conflictModal->titles->nonReplaceable')}</Heading>
        <Paragraph>{t(textCopyKey)}</Paragraph>
      </TextWrapper>
      <ProductCarousel products={nonReplaceableProducts} notAllowedAnonymousProducts={notAllowedAnonymousProducts} />
    </>
  );
};

export default NonReplaceableProducts;
