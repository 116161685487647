import React from 'react';
import { SectionContentSlotWrapper, SectionHeaderSlotWrapper, SectionWrapper } from './AccordionSection.styles';

interface AccordionSectionProps {
  headerSlot: React.ReactNode;
  contentSlot: React.ReactNode;
  active?: boolean;
  onClick?: () => void;
  shouldAlwaysRenderContent?: boolean;
  'data-testid'?: string;
}

/**
 * @deprecated use the html details element instead
 * there is no reusable Accordion component in atoms right now
 * but implemented in /features/storepage/components/Accordion
 */

const AccordionSection = ({
  headerSlot,
  contentSlot,
  onClick,
  active = false,
  shouldAlwaysRenderContent = false,
  'data-testid': testId = 'accordion-section',
}: AccordionSectionProps) => {
  return (
    <SectionWrapper data-testid={testId}>
      <SectionHeaderSlotWrapper data-testid="accordion-header" type="button" onClick={onClick}>
        {headerSlot}
      </SectionHeaderSlotWrapper>
      {(active || shouldAlwaysRenderContent) && <SectionContentSlotWrapper>{contentSlot}</SectionContentSlotWrapper>}
    </SectionWrapper>
  );
};

export default AccordionSection;
