import media from '@constants/responsive';
import { rem } from 'polished';
import React from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { HeadingColor, HeadingSize, HeadingType } from './headingTypes';

export interface IHeadingStyleProps {
  $size: HeadingSize;
  $type: HeadingType;
  $color: HeadingColor;
  $truncate?: boolean;
  children: React.ReactNode;
}

// TODO properly type this mapping structure (causing problems in story)
export const fontSizes = {
  xLarge: {
    mobile: 32,
    other: 56,
  },
  large: {
    mobile: 24,
    other: 40,
  },
  medium: {
    mobile: 20,
    other: 32,
  },
  small: {
    mobile: 20,
    other: 24,
  },
  xSmall: {
    mobile: 16,
    other: 20,
  },
};

export const headingFontSizes = fontSizes;

export const mapHeadingSize = {
  xLarge: `
  font-size: ${rem(fontSizes.xLarge.mobile)};
  ${media.tabletPortrait} {
    font-size: ${rem(fontSizes.xLarge.other)};
  }
`,
  large: `
  font-size: ${rem(fontSizes.large.mobile)};
  ${media.tabletPortrait} {
    font-size: ${rem(fontSizes.large.other)};
  }
`,
  medium: `
  font-size: ${rem(fontSizes.medium.mobile)};
  ${media.tabletPortrait} {
    font-size: ${rem(fontSizes.medium.other)};
  }
`,
  small: `
  font-size: ${rem(fontSizes.small.mobile)};
  ${media.tabletPortrait} {
    font-size: ${rem(fontSizes.small.other)};
  }
`,
  xSmall: `
    font-size: ${rem(fontSizes.xSmall.mobile)};
    ${media.tabletPortrait} {
      font-size: ${rem(fontSizes.xSmall.other)};
    }
`,
};

const getColor = (textColor: HeadingColor, theme: DefaultTheme) => {
  switch (textColor) {
    case 'white':
      return `color: ${theme.colors.colorDesertStorm};`;
    case 'primary':
      return `color: ${theme.colors.colorPrimary};`;
    default:
      return `color: ${theme.colors.colorAcadia};`;
  }
};

export const HeadingStyled = styled.h1<IHeadingStyleProps>`
  margin: 0;
  padding: 0;
  line-height: 1.2;
  ${({ $color, theme }) => getColor($color, theme)};
  ${({ $truncate }) =>
    $truncate &&
    `
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `}
  ${({ $size }) => $size && mapHeadingSize[$size]};
`;
