/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AxfoodCustomerServiceContactForm
 */
export interface AxfoodCustomerServiceContactForm {
    /**
     * 
     * @type {any}
     * @memberof AxfoodCustomerServiceContactForm
     */
    'attachedImages'?: any;
    /**
     * 
     * @type {string}
     * @memberof AxfoodCustomerServiceContactForm
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodCustomerServiceContactForm
     */
    'mcn'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodCustomerServiceContactForm
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodCustomerServiceContactForm
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodCustomerServiceContactForm
     */
    'orderNo'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodCustomerServiceContactForm
     */
    'reasonCode': string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodCustomerServiceContactForm
     */
    'sapId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodCustomerServiceContactForm
     */
    'ssn'?: string;
    /**
     * 
     * @type {string}
     * @memberof AxfoodCustomerServiceContactForm
     */
    'store'?: string;
}
/**
 * 
 * @export
 * @interface HandleIncomingRequestUsingPOSTRequest
 */
export interface HandleIncomingRequestUsingPOSTRequest {
    /**
     * 
     * @type {AxfoodCustomerServiceContactForm}
     * @memberof HandleIncomingRequestUsingPOSTRequest
     */
    'model'?: AxfoodCustomerServiceContactForm;
    /**
     * 
     * @type {Array<File>}
     * @memberof HandleIncomingRequestUsingPOSTRequest
     */
    'file'?: Array<File>;
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'absolute'?: boolean;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'absoluteFile'?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'absolutePath'?: string;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'canonicalFile'?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'canonicalPath'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'directory'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'executable'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'file'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    'freeSpace'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'hidden'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    'lastModified'?: number;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'parent'?: string;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    'parentFile'?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    'path'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'readable'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    'totalSpace'?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    'usableSpace'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    'writable'?: boolean;
}

/**
 * CustomerServiceApi - axios parameter creator
 * @export
 */
export const CustomerServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Handle incoming request
         * @param {AxfoodCustomerServiceContactForm} [model] 
         * @param {Array<File>} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleIncomingRequestUsingPOST: async (model?: AxfoodCustomerServiceContactForm, file?: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/axfood/rest/customer-service/send`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (model !== undefined) { 
                localVarFormParams.append('model', new Blob([JSON.stringify(model)], { type: "application/json", }));
            }
                if (file) {
                file.forEach((element) => {
                    localVarFormParams.append('file', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CustomerServiceApi - functional programming interface
 * @export
 */
export const CustomerServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CustomerServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Handle incoming request
         * @param {AxfoodCustomerServiceContactForm} [model] 
         * @param {Array<File>} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async handleIncomingRequestUsingPOST(model?: AxfoodCustomerServiceContactForm, file?: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.handleIncomingRequestUsingPOST(model, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CustomerServiceApi - factory interface
 * @export
 */
export const CustomerServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CustomerServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary Handle incoming request
         * @param {AxfoodCustomerServiceContactForm} [model] 
         * @param {Array<File>} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleIncomingRequestUsingPOST(model?: AxfoodCustomerServiceContactForm, file?: Array<File>, options?: any): AxiosPromise<void> {
            return localVarFp.handleIncomingRequestUsingPOST(model, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CustomerServiceApi - object-oriented interface
 * @export
 * @class CustomerServiceApi
 * @extends {BaseAPI}
 */
export class CustomerServiceApi extends BaseAPI {
    /**
     * 
     * @summary Handle incoming request
     * @param {AxfoodCustomerServiceContactForm} [model] 
     * @param {Array<File>} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CustomerServiceApi
     */
    public handleIncomingRequestUsingPOST(model?: AxfoodCustomerServiceContactForm, file?: Array<File>, options?: AxiosRequestConfig) {
        return CustomerServiceApiFp(this.configuration).handleIncomingRequestUsingPOST(model, file, options).then((request) => request(this.axios, this.basePath));
    }
}


