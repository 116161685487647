import { carouselSize } from '@components/cmsComponents/AxfoodProductCarouselComponent/useCarouselComponent';
import CarouselListItem from '@components/molecules/Carousel/CarouselListItem';
import { ProductSkeleton } from '@components/molecules/Product/ProductSkeleton/Product.skeleton';
import { IntersectionObservedCartConnectedProduct } from '@components/organisms/CartConnectedProduct';
import { RefObject } from 'react';
import { Props } from '../ProductsWithTracking';
import useTrackedProducts from '../useTrackedProducts';

export const CarouselProductsSkeleton = () => {
  const skeletons = Array.from({ length: 7 }, (_, i) => (
    <CarouselListItem key={i}>
      <ProductSkeleton />
    </CarouselListItem>
  ));
  return <>{skeletons}</>;
};

const CarouselProductsWithTracking = ({
  products,
  eventListName,
  isLoading,
  containerRef,
  disableMixAndMatch = false,
  extendedEventListName,
  loadMore,
}: Props) => {
  const { onProductShown, trackableRefs } = useTrackedProducts({ eventListName, extendedEventListName, products });

  if (isLoading) return <CarouselProductsSkeleton />;

  return (
    <>
      {products.map((product, i) => (
        <CarouselListItem key={`${product.code}-${product.name}-${i}`}>
          <IntersectionObservedCartConnectedProduct
            data-testid="product-carousel"
            product={product}
            intersectionCallback={() => {
              onProductShown(product, i + 1);

              // load more when every 28th product is intersecting
              const shouldLoadMoreData = (i + 1) % carouselSize === 0;
              if (shouldLoadMoreData && loadMore) {
                loadMore();
              }
            }}
            ref={trackableRefs.current[i] as unknown as RefObject<any>}
            eventListName={eventListName}
            listPos={i}
            containerRef={containerRef}
            disableMixAndMatch={disableMixAndMatch}
            extendedEventListName={extendedEventListName}
            alwaysObserve={false}
          />
        </CarouselListItem>
      ))}
    </>
  );
};

export default CarouselProductsWithTracking;
