import useIntersection from '@hooks/useIntersection';
import { useEffect } from 'react';

const useInfiniteScroll = <T extends HTMLElement>(callback: () => void) => {
  const [containerRef, intersectionObject] = useIntersection<T>({
    root: null,
    rootMargin: '0px 0px 150px 0px',
    threshold: 0,
  });

  useEffect(() => {
    if (intersectionObject?.isIntersecting) {
      callback();
    }
  }, [intersectionObject?.isIntersecting]);

  return containerRef;
};

export default useInfiniteScroll;
