import { rem } from 'polished';
import styled, { css } from 'styled-components';

interface StyledInputProps {
  $isActive: boolean;
}
interface InputContainerProps {
  $disabled: boolean;
}

export const InputLabel = styled.span`
  position: absolute;
  left: ${rem(3)};
  top: 0;
  transition: all 0.15s ease-out;
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  opacity: 0.75;
  &:hover {
    cursor: text;
  }
`;

const FocusedLabel = css`
  & ~ ${InputLabel} {
    transform: translateY(-${rem(14)});
    font-size: ${rem(14)};
  }
`;

export const InputStyled = styled.input<StyledInputProps>`
  margin-top: 0;
  background: 0 0;
  padding: ${rem(2)} ${rem(2)} ${rem(1)};
  line-height: ${rem(26)};
  font-size: ${rem(16)};
  height: ${rem(30)};
  -ms-flex-preferred-size: ${rem(26)};
  appearance: none;
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.colorTextPrimary};

  background-clip: text;
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  -webkit-tap-highlight-color: transparent;

  &:focus,
  &:active {
    outline: none;
    border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.colorPrimary};
    ${FocusedLabel}
  }
  ${(props) => props.$isActive && FocusedLabel}

  &:required + ${InputLabel} {
    display: flex;
  }
  &:required + ${InputLabel}::after {
    content: ' *';
    display: inline;
  }
`;

export const InputContainer = styled.fieldset<InputContainerProps>`
  position: relative;
  width: 100%;
  padding: 0;
  border: none;
  ${({ $disabled }) =>
    $disabled &&
    `
      opacity: .5;
      pointer-events:none;
      &:hover {
       cursor: not-allowed;
      }
  `}
`;

export const ErrorLabel = styled.div`
  color: ${({ theme }) => theme.colors.colorPrimary};
  font-size: ${rem(12)};
  line-height: ${rem(15)};
  text-align: left;
`;

export const TooltipWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;
