import Paragraph from '@components/atoms/Paragraph';
import { CardHeight } from '@components/molecules/Product/Product.styles';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 48% 1fr;
  border-radius: ${rem(16)};
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  ${CardHeight};

  ${media.tabletLandscape} {
    grid-template-rows: 58% 1fr;
  }
`;

export const Content = styled.div`
  padding: ${rem(16)} ${rem(16)} ${rem(10)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${rem(8)};
`;

export const StyledParagraph = styled(Paragraph)`
  ${media.tabletLandscape} {
    -webkit-line-clamp: 3;
  }
`;

export const ImageWrapper = styled.div`
  img {
    object-fit: cover;
    height: 100%;
  }
`;
