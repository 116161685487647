import Button from '@components/atoms/__DEPRECATED__/Button';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import CartAddToList from '@components/molecules/CartAddToList';
import CartThresholds from '@components/molecules/CartThresholds';
import IconButton from '@components/molecules/IconButton';
import LoginOrSignupCallToAction from '@components/molecules/LoginOrSignupCallToAction';
import MiniCartRecipeRow from '@components/molecules/MiniCartRecipeRow';
import MiniCartProductsWithTracking from '@components/molecules/MinicartProductsWithTracking';
import { HEADER_HEIGHT } from '@components/organisms/Header/Header.styles';
import LINKS from '@constants/links';
import gtmTrack from '@helpers/gtmTrack';
import { useAppSelector } from '@hooks/redux';
import useAppRouter from '@hooks/useAppRouter';
import useCustomer from '@hooks/useCustomer';
import useTrackCheckoutStep from '@hooks/useTrackCheckoutStep';
import IconArrowDown from '@public/icons/regularIcons/icon-arrow-down.svg';
import {
  selectCartHasNicotineMedical,
  selectCartHasProducts,
  selectCartHasRecipes,
  selectCartRecipes,
} from '@selectors/cart';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useState } from 'react';
import {
  ArrowIconWrapper,
  CartThresholdsWrapper,
  Container,
  EmptyCartMessageWrapper,
  LoginSection,
  ProductsWrapper,
  RecipesWrapper,
  ScrollWrapper,
  SectionHeader,
} from './MiniCartPane.styles';
import MiniCartPaneFooter from './MiniCartPaneFooter';
import MiniCartPaneHeader from './MiniCartPaneHeader';

interface Props {
  setIsConfirmModalVisible: (isVisible: boolean) => void;
  toggleMenu: () => void;
}

const EVENT_CATEGORY_NAME = 'minicart';

const MiniCartPane = ({ setIsConfirmModalVisible, toggleMenu }: Props) => {
  const { isAnonymous } = useCustomer();
  const cartHasProducts = useAppSelector(selectCartHasProducts);
  const cartHasRecipes = useAppSelector(selectCartHasRecipes);
  const cartRecipes = useAppSelector(selectCartRecipes);
  const router = useAppRouter();
  const { t } = useTranslation('minicart');
  const [recipesOpen, setRecipesOpen] = useState(true);
  const cartHasNicotineProduct = useAppSelector(selectCartHasNicotineMedical);
  const [panePosition, setpanePosition] = useState<number>(0);

  const miniCartContainer = useCallback((domNode: HTMLDivElement) => {
    if (domNode) {
      setpanePosition(domNode.getBoundingClientRect().top);
    }
  }, []);

  useTrackCheckoutStep(1);

  const handleToCartClick = useCallback(
    (position: string) => {
      toggleMenu();
      gtmTrack({
        event: 'Track',
        category: EVENT_CATEGORY_NAME,
        action: 'to_cart_clicked',
        label: position,
        value: 0,
      });
    },
    [toggleMenu]
  );

  return (
    <Container
      $top={HEADER_HEIGHT}
      $right={0}
      $height={panePosition || 0}
      ref={miniCartContainer}
      data-testid="mini-cart-pane"
    >
      <ScrollWrapper>
        <MiniCartPaneHeader cartHasProducts={cartHasProducts} onToCartClick={handleToCartClick}>
          <CartAddToList eventCategory={EVENT_CATEGORY_NAME} />
        </MiniCartPaneHeader>
        {isAnonymous && (
          <LoginSection data-testid="pane-login-section">
            <LoginOrSignupCallToAction trackingCategoryName={EVENT_CATEGORY_NAME} onLoginClick={toggleMenu} />
          </LoginSection>
        )}
        <CartThresholdsWrapper>
          <CartThresholds centerTextOnFreeThreshold />
        </CartThresholdsWrapper>
        {cartHasRecipes && (
          <RecipesWrapper>
            <SectionHeader>
              <Paragraph size="lg" bold="bold">
                {t('minicart->recipes')}
              </Paragraph>
              <ArrowIconWrapper $flipped={!recipesOpen}>
                <IconButton
                  icon={IconArrowDown}
                  onClick={() => setRecipesOpen(!recipesOpen)}
                  data-testid="icon-arrow-down"
                />
              </ArrowIconWrapper>
            </SectionHeader>
            {recipesOpen && (
              <>
                {cartRecipes.map((recipe) => (
                  <MiniCartRecipeRow recipe={recipe} key={recipe.id} />
                ))}
              </>
            )}
          </RecipesWrapper>
        )}

        <ProductsWrapper>
          {cartHasRecipes && (
            <SectionHeader data-testid="mini-cart-title">
              <Paragraph size="lg" bold="bold">
                {t('minicart->goods')}
              </Paragraph>
            </SectionHeader>
          )}

          {cartHasProducts ? (
            <MiniCartProductsWithTracking setIsConfirmModalVisible={setIsConfirmModalVisible} />
          ) : (
            <>
              <EmptyCartMessageWrapper>
                <Paragraph data-testid="empty-cart-message">{t('minicart->emptyCartMessage')}</Paragraph>
              </EmptyCartMessageWrapper>
              <div>
                <Button
                  variant="primary"
                  onClick={() => {
                    router.push(LINKS.SHOP);
                  }}
                >
                  {t('minicart->buttons->startShopping')}
                </Button>
              </div>
            </>
          )}
        </ProductsWrapper>
      </ScrollWrapper>
      <MiniCartPaneFooter
        cartHasProducts={cartHasProducts}
        cartHasNicotineProduct={cartHasNicotineProduct}
        onClick={handleToCartClick}
      />
    </Container>
  );
};

export default MiniCartPane;
