import { ROUTE_BY_REPLACE_ROUTES_IN_NATIVE_APP } from '@constants/links';

export const resolveUrlString = (href: UrlType) => {
  let urlString = '';
  if (href instanceof Object && typeof href.pathname === 'string') {
    urlString = href.pathname;
  } else if (typeof href === 'string') {
    urlString = href;
  }

  return urlString;
};

export const urlMatchesRouteByReplaceInNativeApp = (href: UrlType) => {
  const url = resolveUrlString(href);
  const val = url?.toLowerCase()?.trim().split('?')[0];
  const prefix = val.split('/')[1];
  return (
    ROUTE_BY_REPLACE_ROUTES_IN_NATIVE_APP.includes(val) || ROUTE_BY_REPLACE_ROUTES_IN_NATIVE_APP.includes(`/${prefix}/`)
  );
};

export const locationIsExternal = (href: UrlType) => {
  let isExternal = false;
  if (href instanceof Object && typeof href.pathname === 'string') {
    isExternal = href.pathname.startsWith('http');
  } else if (typeof href === 'string') {
    isExternal = href.startsWith('http');
  }
  return isExternal;
};
