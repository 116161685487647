const isCmsLinkComponentList = (cmsSocialLinkList: unknown): cmsSocialLinkList is CMSLinkComponentType[] => {
  return (
    !!cmsSocialLinkList &&
    Array.isArray(cmsSocialLinkList) &&
    cmsSocialLinkList.every((socialLink) => {
      return !!(socialLink as CMSLinkComponentType).linkName !== undefined && !!(socialLink as CMSLinkComponentType).url;
    })
  );
};

export const getSocialLinks = (cmsSocialLinks: unknown) => {
  const links = cmsSocialLinks && typeof cmsSocialLinks === 'object' ? Object.values(cmsSocialLinks) : undefined;
  return isCmsLinkComponentList(links) ? links : undefined;
};
