import Paragraph from '@components/atoms/Paragraph';
import { rem } from 'polished';
import styled from 'styled-components';

export const ProductInformationGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: ${rem(20)} ${rem(96)} ${rem(12)} ${rem(30)};
  grid-gap: ${rem(2)};
  grid-template-areas:
    'promotionlabel'
    'productinfo'
    'pricecontainer'
    'redeemlimit';
`;

export const Wrapper = styled.div`
  position: relative;
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  grid-area: productinfo;
`;

export const PromotionLabelContainer = styled.div`
  grid-area: promotionlabel;
`;

export const FlexParagraph = styled(Paragraph)`
  display: flex;
  gap: ${rem(4)};
`;

export const ProductManufacturerVolume = styled.div`
  min-height: ${rem(14)};
  margin: ${rem(2)} 0 0;
`;

export const ProductManufacturer = styled.span`
  text-transform: uppercase;
`;

export const ProductComparePrices = styled.div`
  margin: ${rem(3)} 0;
`;

export const Discount = styled.div`
  color: ${({ theme }) => theme.colors.colorPrimary};
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const RedeemLimit = styled.div`
  margin-top: ${rem(8)};
  grid-area: redeemlimit;
`;
