import Button from '@components/atoms/Button';
import Icon from '@components/atoms/Icon';
import IconPlay from '@public/icons/regularIcons/icon-play.svg';
import useTranslation from 'next-translate/useTranslation';
import { VideoThumbnail } from '../AxfoodVideoBannerComponent.styles';

interface Props {
  thumbnail: string;
  onPlay: () => void;
  'data-testid'?: string;
}
const Thumbnail = ({ thumbnail, onPlay, 'data-testid': dataTestId }: Props) => {
  const { t } = useTranslation('common');
  return (
    <VideoThumbnail $thumbnail={thumbnail} data-testid={dataTestId}>
      <Button variant="secondary" onClick={onPlay}>
        <Icon icon={IconPlay} /> {t('defaultActions->playVideo')}
      </Button>
    </VideoThumbnail>
  );
};

export default Thumbnail;
