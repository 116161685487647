import { ProductBannerComponentPaginatedDataType } from '@api';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';

type ProductBannerWithoutItems = Omit<ProductBannerComponentPaginatedDataType, 'items'>;
interface ProductBannerData extends ProductBannerWithoutItems {
  items: Product[];
}

type State = {
  isFetching: boolean;
  data: ProductBannerData;
};

export const IS_FETCHING = 'IS_FETCHING';
export const SUCCESS = 'SUCCESS';
export const FINISHED_FETCHING = 'FINISHED_FETCHING';
export const RESET = 'RESET';

type Action =
  | { type: typeof IS_FETCHING }
  | { type: typeof SUCCESS; data: { paginationData: ProductBannerData } }
  | { type: typeof FINISHED_FETCHING }
  | { type: typeof RESET };

export const initialState: State = {
  isFetching: false,
  data: { items: [], page: -1, pageSize: 0, totalPages: 0, links: undefined },
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case IS_FETCHING:
      return {
        ...state,
        isFetching: true,
      };
    case SUCCESS: {
      const { paginationData } = action.data;
      const { page, items } = paginationData;
      const newItems = page === 0 ? items : [...state.data.items, ...items];

      return {
        ...state,
        data: { ...action.data.paginationData, items: newItems },
        isFetching: false,
      };
    }
    case FINISHED_FETCHING:
      return {
        ...state,
        isFetching: false,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};
