import React, { useRef, useEffect, useState, ReactNode } from 'react';
import { AccordionContentWrapper, AccordionContent } from '../AxfoodFAQComponent.styles';

interface AccordionSectionContentProps {
  active: boolean;
  children: ReactNode;
}

const AccordionSectionContent = ({ active, children }: AccordionSectionContentProps) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [childHeight, setChildHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setChildHeight(contentRef.current.clientHeight);
    }
  }, [active]);

  return (
    <AccordionContentWrapper
      aria-hidden={!active}
      aria-expanded={active}
      $active={active}
      $childHeight={childHeight}
      data-testid="faq-accordion-content"
    >
      <AccordionContent ref={contentRef}>{children}</AccordionContent>
    </AccordionContentWrapper>
  );
};

export default AccordionSectionContent;
