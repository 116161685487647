import { CategorizedProductList } from '@helpers/sortCartProductsByCategory/sortCartProductsByCategory';

const sortCategorizedProductListsByAlphabeticOrder = (categorizedProductLists: CategorizedProductList[]) => {
  return categorizedProductLists.sort((a, b) => {
    if ((a.name === undefined || typeof a.name !== 'string') && (b.name === undefined || typeof b.name !== 'string'))
      return 0;
    if (a.name === undefined || typeof a.name !== 'string') return 1;
    if (b.name === undefined || typeof b.name !== 'string') return -1;

    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};

export default sortCategorizedProductListsByAlphabeticOrder;
