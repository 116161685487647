import { CreditCardPaymentInfoData } from '@api/generated/storefront';
import { VALID_PAYMENT_MODE_TYPE } from '@features/checkout/constants';
import { KlarnaDataStateType, PaymentAuthStatusType } from '@reducers/checkout';
import * as types from './types';

type SetCurrentCard = {
  type: typeof types.SET_CURRENT_CARD;
  payload: CreditCardPaymentInfoData;
};

type ToggleShouldSaveNewCard = { type: typeof types.TOGGLE_SHOULD_SAVE_NEW_CARD };

type SetCurrentlyCheckedPayment = {
  type: typeof types.SET_CURRENTLY_CHECKED_PAYMENT;
  payload: VALID_PAYMENT_MODE_TYPE;
};

type SetKlarnaData = {
  type: typeof types.SET_KLARNA_DATA;
  payload: KlarnaDataStateType;
};

type UnsetKlarnaIdentifier = {
  type: typeof types.UNSET_KLARNA_IDENTIFIER;
};

type ResetPaymentState = {
  type: typeof types.RESET_PAYMENT_STATE;
};

type RefreshKlarnaTokenAndUnsetIdentifier = {
  type: typeof types.REFRESH_KLARNA_TOKEN_AND_UNSET_IDENTIFIER;
  payload: string;
};

type SetCurrentlyCheckedIdentifier = {
  type: typeof types.SET_CURRENTLY_CHECKED_IDENTIFIER;
  payload: IdentifierType | '';
};

type SetKlarnaLoaded = {
  type: typeof types.SET_KLARNA_LOADED;
  payload: boolean;
};

type setPaymentAuthStatus = {
  type: typeof types.SET_PAYMENT_AUTH_STATUS;
  payload: PaymentAuthStatusType;
};

type resetPaymentAuthStatus = {
  type: typeof types.RESET_PAYMENT_AUTH_STATUS;
};

export const setCurrentCard = (card: CreditCardPaymentInfoData): SetCurrentCard => ({
  type: types.SET_CURRENT_CARD,
  payload: card,
});

export const toggleShouldSaveNewCard = (): ToggleShouldSaveNewCard => ({
  type: types.TOGGLE_SHOULD_SAVE_NEW_CARD,
});

export const setCurrentlyCheckedPayment = (mode: VALID_PAYMENT_MODE_TYPE): SetCurrentlyCheckedPayment => ({
  type: types.SET_CURRENTLY_CHECKED_PAYMENT,
  payload: mode,
});

export const setKlarnaData = (klarnaData: KlarnaDataStateType): SetKlarnaData => ({
  type: types.SET_KLARNA_DATA,
  payload: klarnaData,
});

export const unsetKlarnaIdentifier = (): UnsetKlarnaIdentifier => ({
  type: types.UNSET_KLARNA_IDENTIFIER,
});

export const resetPaymentState = (): ResetPaymentState => ({
  type: types.RESET_PAYMENT_STATE,
});

export const setPaymentAuthStatus = (status: PaymentAuthStatusType): setPaymentAuthStatus => ({
  type: types.SET_PAYMENT_AUTH_STATUS,
  payload: status,
});
export const resetPaymentAuthStatus = (): resetPaymentAuthStatus => ({
  type: types.RESET_PAYMENT_AUTH_STATUS,
});

export const refreshKlarnaTokenAndUnsetIdentifier = (token: string): RefreshKlarnaTokenAndUnsetIdentifier => ({
  type: types.REFRESH_KLARNA_TOKEN_AND_UNSET_IDENTIFIER,
  payload: token,
});

export const setCurrentlyCheckedIdentifier = (indentifier: IdentifierType | ''): SetCurrentlyCheckedIdentifier => ({
  type: types.SET_CURRENTLY_CHECKED_IDENTIFIER,
  payload: indentifier,
});

export const setKlarnaLoaded = (loaded: boolean): SetKlarnaLoaded => ({
  type: types.SET_KLARNA_LOADED,
  payload: loaded,
});

export type CheckoutActionTypes =
  | SetCurrentCard
  | ToggleShouldSaveNewCard
  | SetCurrentlyCheckedPayment
  | SetKlarnaData
  | UnsetKlarnaIdentifier
  | ResetPaymentState
  | RefreshKlarnaTokenAndUnsetIdentifier
  | SetCurrentlyCheckedIdentifier
  | SetKlarnaLoaded
  | setPaymentAuthStatus
  | resetPaymentAuthStatus;
