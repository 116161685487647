import Icon from '@components/atoms/Icon';
import Link from '@components/atoms/__DEPRECATED__/Link';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import LINKS from '@constants/links';
import { removeSpacesAndDashes } from '@helpers/string';
import { useAppSelector } from '@hooks/redux';
import useCustomer from '@hooks/useCustomer';
import IconMail from '@public/icons/regularIcons/icon-mail.svg';
import IconPhone from '@public/icons/regularIcons/icon-phone.svg';
import { selectActiveStoreAddress, selectActiveStoreName } from '@selectors/delivery';
import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';
import { ContactContainer, Container, FaqContainer, IconRow, TextRow } from './InfoPane.styles';

interface ContactParagraphProps {
  isLocalB2BCustomer: boolean;
  routeIsProfilePageB2B: boolean;
  storeName?: string;
}
const ContactParagraph = ({ isLocalB2BCustomer, routeIsProfilePageB2B, storeName }: ContactParagraphProps) => {
  const { t } = useTranslation('infopane');
  if (isLocalB2BCustomer && routeIsProfilePageB2B) {
    return (
      <>
        <Paragraph size="content-text">{t('updateProfileLocalB2BInststructions')}</Paragraph>
        <Paragraph size="content-text">{storeName}</Paragraph>
      </>
    );
  }
  if (isLocalB2BCustomer) {
    return (
      <>
        <Paragraph size="content-text">{t('contact')}</Paragraph>
        <Paragraph size="content-text">{storeName}</Paragraph>
      </>
    );
  }
  return <Paragraph size="content-text">{t('contactCustomerSevice')}</Paragraph>;
};

interface InfoPaneProps {
  showQuestionsAboutOrderText?: boolean;
  isProfilePageB2B?: boolean;
  fullWidth?: boolean;
}

const InfoPane = ({
  showQuestionsAboutOrderText = true,
  isProfilePageB2B = false,
  fullWidth = false,
}: InfoPaneProps) => {
  const { t } = useTranslation('infopane');
  const { isLocalB2BCustomer } = useCustomer();
  const activeStoreAddress = useAppSelector(selectActiveStoreAddress);
  const activeStoreName = useAppSelector(selectActiveStoreName);

  const phoneNumber = isLocalB2BCustomer ? activeStoreAddress?.phone : t('phoneNumber');
  const linkPhoneNumber = phoneNumber ? removeSpacesAndDashes(phoneNumber) : '';
  const email = isLocalB2BCustomer ? activeStoreAddress?.email : t('email');

  return (
    <Container $fullWidth={fullWidth} data-testid="checkout-info-pane-container">
      <FaqContainer>
        {showQuestionsAboutOrderText && <Paragraph size="content-text">{t('questionsAboutOrder')}</Paragraph>}
        {!isLocalB2BCustomer && (
          <TextRow>
            <Link href={LINKS.FAQ}>
              <Paragraph>{t('seeCommonQuestionsAndAnswers')}</Paragraph>
            </Link>
          </TextRow>
        )}
      </FaqContainer>
      <ContactContainer>
        <ContactParagraph
          isLocalB2BCustomer={!!isLocalB2BCustomer}
          routeIsProfilePageB2B={isProfilePageB2B}
          storeName={activeStoreName}
        />
        <IconRow>
          <Icon icon={IconPhone} margin="right" data-testid="icon-phone" />
          <Link href={`tel:${linkPhoneNumber}`} margin={false} underlined={false} external>
            <Paragraph>{phoneNumber}</Paragraph>
          </Link>
        </IconRow>
        <IconRow>
          <Icon icon={IconMail} margin="right" data-testid="icon-mail" />
          <Link href={`mailto:${email}`} margin={false} underlined={false} external>
            <Paragraph>{email}</Paragraph>
          </Link>
        </IconRow>
      </ContactContainer>
    </Container>
  );
};

export default memo(InfoPane);
