import Button from '@components/atoms/__DEPRECATED__/Button';
import RoundIconButton from '@components/molecules/RoundIconButton';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

interface AddShoppingListButtonProps {
  $selected: boolean;
}

export const Popover = styled.div<{ $topPosition: number }>`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.colorWhite};
  z-index: 100;
  opacity: 1 !important;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${({ $topPosition }) => $topPosition && `${rem($topPosition)}`};
  left: 0;
  ${media.tabletPortrait} {
    border: ${rem(2)} solid ${({ theme }) => theme.colors.colorAcadia};
    width: ${rem(276)};
    height: auto;
    max-width: ${rem(276)};
    max-height: ${rem(278)};
    z-index: 2;
    position: initial;
  }
`;

export const Header = styled.div`
  display: flex;
  height: ${rem(57)};
  justify-content: space-between;
  border-bottom: ${rem(2)} solid ${({ theme }) => theme.colors.colorCararra};
  padding: 0 ${rem(10)};
  ${media.tabletPortrait} {
    padding: ${rem(16)} ${rem(10)};
    border-bottom: ${rem(2)} solid ${({ theme }) => theme.colors.colorAcadia};
  }
`;
export const AddToShoppingListText = styled.span`
  display: flex;
  align-items: center;
  font-size: ${rem(24)};
  ${media.tabletPortrait} {
    font-size: ${rem(16)};
  }
`;
export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: ${rem(2)} solid ${({ theme }) => theme.colors.colorCararra};
  padding: ${rem(20)} ${rem(10)};
  ${media.tabletPortrait} {
    padding: ${rem(10)};
    border: none;
  }
`;
export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 1 auto;
  overflow: auto;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: ${rem(8)};
`;

export const ShoppingLists = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
export const ShoppingListItem = styled.li`
  height: ${rem(52)};
  list-style: none;
  width: 100%;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.colorSecondary};
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${rem(40)};
  width: 100%;
  border: ${rem(1)} solid ${({ theme }) => theme.colors.colorSecondary};
  &:hover {
    border-color: ${({ theme }) => theme.colors.colorTextSecondary};
  }
  svg {
    height: ${rem(30)};
    width: ${rem(30)};

    path:last-of-type {
      fill: ${({ theme }) => theme.colors.colorWhite};
    }
  }
`;

export const Input = styled.input`
  outline: none;
  border: none;
  padding: ${rem(8)} 0 ${rem(8)} ${rem(10)};
  width: 100%;
`;

export const AddShoppingListButton = styled(Button)<AddShoppingListButtonProps>`
  border-radius: 0;
  width: 100%;
  height: 100%;
  height: ${rem(52)};
  justify-content: space-between;
  padding: ${rem(8)} ${rem(12)};
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    background: ${({ theme }) => theme.colors.colorHighlight};
  }
  &:disabled {
    background: transparent;
  }

  ${(props) =>
    props.$selected &&
    `
    background: ${props.theme.colors.colorHighlight}
    transition: background-color 0.3s ease-out;
    `}
`;

export const CloseButtonWrapper = styled.span`
  display: flex;
  align-items: center;
  font-size: ${rem(24)};
  ${media.tabletPortrait} {
    font-size: ${rem(16)};
  }
`;

export const CloseButton = styled(RoundIconButton)`
  background: ${({ theme }) => theme.colors.colorStack};
  width: ${rem(40)};
  height: ${rem(40)};
  ${media.tabletPortrait} {
    background: none;
  }
`;
