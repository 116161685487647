/* eslint-disable fp/no-mutation */
import Icon from '@components/atoms/Icon';
import useResponsive from '@hooks/useResponsive';
import RightIcon from '@public/icons/regularIcons/icon-chevron-right.svg';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode } from 'react';
import { CarouselList, CarouselWrapper, NextButton, PrevButton } from './Carousel.styles';
import useCarousel from './useCarousel';

interface CarouselProps {
  children: ReactNode;
  singlePagination?: boolean;
  hasMoreItems?: boolean;
  isFetchingMoreData?: boolean;
}

const Carousel = ({ singlePagination = false, children, hasMoreItems, isFetchingMoreData }: CarouselProps) => {
  const { slideAtStart, slideAtEnd, next, prev, containerRef, sliderRef } = useCarousel(singlePagination);
  const { t } = useTranslation('common');

  const { isTabletPortraitOrGreater } = useResponsive();
  const iconSize = isTabletPortraitOrGreater ? 24 : 16;

  const showNextButton = hasMoreItems || !slideAtEnd;

  return (
    <CarouselWrapper ref={containerRef} data-testid="carousel">
      <div>
        {!slideAtStart && (
          <PrevButton onClick={prev} variant="secondary" aria-label={t('defaultActions->previous')}>
            <Icon icon={RightIcon} size={iconSize} />
          </PrevButton>
        )}
        {showNextButton && (
          <NextButton
            disabled={isFetchingMoreData}
            onClick={next}
            variant="secondary"
            aria-label={t('defaultActions->next')}
          >
            <Icon icon={RightIcon} size={iconSize} />
          </NextButton>
        )}
      </div>
      <CarouselList ref={sliderRef} data-testid="carousel-list">
        {children}
      </CarouselList>
    </CarouselWrapper>
  );
};

export default Carousel;
