import useLinkUtils from '@hooks/useLinkUtils';
import NextLink from 'next/link';
import React, { ForwardedRef, ReactNode } from 'react';
import { UrlObject } from 'url';
import { StyledLink } from './LinkArea.styles';

declare type UrlType = string | UrlObject;

export interface LinkProps {
  'aria-label'?: string;
  nextLinkAs?: UrlType;
  children?: ReactNode;
  disabled?: boolean;
  external?: boolean;
  forceInternal?: boolean;
  href: UrlType;
  onClick?: (event: LinkClickEvent) => void;
  prefetch?: boolean;
  replace?: boolean;
  scroll?: boolean;
  shallow?: boolean;
  target?: string;
  title?: string;
  'data-testid'?: string;
  'data-cy'?: string;
  dynamicUrlPrefix?: string;
  className?: string;
  'data-text'?: string;
}

/**
 * @deprecated use components/atoms/linkarea instead
 * @summary link area component
 * props.className is needed for using this component as styled(LinkArea)
 * removing className will break <Link />
 * reference: https://styled-components.com/docs/basics#styling-any-component
 */
export const LinkArea = React.forwardRef(
  (
    {
      nextLinkAs,
      children,
      disabled = false,
      external = false,
      forceInternal = false,
      href,
      onClick,
      prefetch = false,
      replace = false,
      scroll = true,
      target = '_self',
      title,
      shallow = false,
      'aria-label': ariaLabel = undefined,
      'data-testid': dataTestid = 'link-area',
      'data-cy': dataCy,
      dynamicUrlPrefix,
      className,
      'data-text': dataText = undefined,
    }: LinkProps,
    ref: ForwardedRef<HTMLAnchorElement>
  ) => {
    const { isInternalLink, updatedHref, absoluteUrl } = useLinkUtils(href, external, forceInternal, dynamicUrlPrefix);

    if (disabled) {
      return (
        <div
          className={className}
          data-testid={`${dataTestid} disabled-link`}
          data-cy={dataCy}
          role="link"
          aria-disabled="true"
          aria-label={ariaLabel}
        >
          {children}
        </div>
      );
    }

    return isInternalLink ? (
      <NextLink
        legacyBehavior
        href={updatedHref}
        as={nextLinkAs}
        scroll={scroll}
        prefetch={prefetch}
        replace={replace}
        passHref
        shallow={shallow}
      >
        <StyledLink
          className={className}
          ref={ref}
          onClick={onClick}
          data-testid={`${dataTestid} next-link`}
          data-cy={dataCy}
          aria-label={ariaLabel}
          title={title}
          data-text={dataText}
          target={target}
        >
          {children}
        </StyledLink>
      </NextLink>
    ) : (
      <StyledLink
        ref={ref}
        onClick={onClick}
        data-testid={dataTestid}
        data-cy={dataCy}
        href={typeof absoluteUrl === 'string' ? absoluteUrl : ''}
        target={target}
        aria-label={ariaLabel}
        title={title}
        className={className}
        data-text={dataText}
      >
        {children}
      </StyledLink>
    );
  }
);
LinkArea.displayName = 'LinkArea';

export default LinkArea;
