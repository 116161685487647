import Badge from '@components/atoms/Badge';
import Icon, { IconType } from '@components/atoms/Icon/Icon';
import LINKS from '@constants/links';
import useAppRouter from '@hooks/useAppRouter';
import { BadgeWrapper, Container, StyledMenuLink, StyledParagraph } from './AccountMenuItem.styles';

export declare type MenuLink = {
  url: string;
  title: string;
  color?: ColorType;
  icon?: {
    svg: IconType;
    color: ColorType;
  };
  onClick: () => void;
  badgeValue?: number;
};

export interface AccountMenuListItemProps {
  link: MenuLink;
  onLinkClicked?: () => void;
  className?: string;
  'data-testid'?: string;
  'data-cy'?: string;
}

const AccountMenuItem = ({
  link,
  onLinkClicked,
  className,
  'data-testid': dataTestid,
  'data-cy': dataCy,
}: AccountMenuListItemProps) => {
  const { pathname, query } = useAppRouter();

  const isLinkActive = pathname === link.url;

  return (
    <StyledMenuLink
      className={className}
      key={link.title}
      onClick={() => {
        link.onClick();
        onLinkClicked && onLinkClicked();
      }}
      nextLinkAs={link.url === LINKS.LOGIN ? LINKS.LOGIN : undefined}
      href={link.url === LINKS.LOGIN ? { pathname, query } : link.url}
      data-testid={dataTestid}
      data-cy={dataCy}
    >
      <Container>
        {link.icon && <Icon icon={link.icon.svg} color={isLinkActive ? 'red' : 'black'} />}
        <StyledParagraph color={isLinkActive ? 'primary' : 'black'} weight="medium">
          {link.title}
        </StyledParagraph>
      </Container>
      <BadgeWrapper>
        <Badge value={link.badgeValue} color="red" />
      </BadgeWrapper>
    </StyledMenuLink>
  );
};

export default AccountMenuItem;
