import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import { media } from '@constants/responsive';
import { rem } from 'polished';
import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

interface OptionsProps {
  $visible: boolean;
  $scrollBarVisible: boolean;
}
export const Options = styled.ul<OptionsProps>`
  padding: 0;
  margin: 0;
  transition: opacity 0.3s ease-in-out;
  visibility: ${(props) => (props.$visible ? 'visible' : 'hidden')};
  animation: ${(props) => (props.$visible ? fadeIn : fadeOut)} 0.3s linear;
  border: ${rem(2)} solid;
  max-height: ${rem(250)};
  width: 100%;
  overflow-y: scroll;
  position: absolute;
  top: ${rem(42)};
  right: 0;
  z-index: 70;
  ${media.tabletPortrait} {
    right: 0;
    min-width: ${rem(220)};
  }

  ${({ $scrollBarVisible, theme }) => `
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      background-color: ${theme.colors.colorWhite};
      width: ${rem($scrollBarVisible ? 8 : 0)};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: ${rem(4)};
      background-color: rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 ${rem(1)} rgba(255, 255, 255, 0.5);
    }
  `}
`;

export const BrandsOptions = styled(Options)`
  top: ${rem(0)};
  left: ${rem(0)};
  background-color: ${({ theme }) => theme.colors.colorWhite};
  ${media.desktop} {
    top: ${rem(0)};
    right: ${rem(0)};
    left: unset;
  }
`;

interface OptionProps {
  $isActive: boolean;
  $visible?: boolean;
  $isBrand?: boolean;
}
export const Option = styled.li<OptionProps>`
  background-color: ${({ $isActive, theme }) => ($isActive ? theme.colors.colorPrimaryPink : 'white')};
  display: flex;
  align-items: center;
  padding: ${rem(12)} ${rem(10)};
  cursor: pointer;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.colorCararra};
  &:last-child {
    border-bottom: none;
  }
  ${({ $isBrand, theme }) =>
    $isBrand &&
    `
    padding: 0;
    a {
      padding: ${rem(12)} ${rem(10)};
      width: 100%;
      &:hover {
        color: ${theme.colors.colorTextPrimary};
      }
    }
  `}

  &:hover {
    background: ${({ theme }) => theme.colors.colorPrimaryPink};
  }
`;

export const StyledParagraph = styled(Paragraph)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
