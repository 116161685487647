/* eslint-disable react/jsx-props-no-spreading */
import Takeover from '@components/atoms/Takeover';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import CartAddToListPopOver from '@components/organisms/CartAddToListPopOver';
import isFrontend from '@helpers/isFrontend';
import useCustomer from '@hooks/useCustomer';
import useOnClickOutside from '@hooks/useOnClickOutside';
import useResponsive from '@hooks/useResponsive';
import useRouteToLogin from '@hooks/useRouteToLogin';
import IconAddToList from '@public/icons/colorIcons/icon-add-to-list.svg';
import { addToShoppingListTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { usePopper } from 'react-popper';
import { AddToShoppingListEvent } from 'trackers/addToShoppingList';
import { AddToListButton, AddToListPopper, AddToListWrapper } from './ProductAddToList.styles';

interface ProductAddToListProps {
  product: ProductVariantType | Product;
}

export const AnonymousTrackingEvent: AddToShoppingListEvent = {
  category: 'product_blurb',
  action: 'saved_to_list',
  label: 'redirect_to_login',
};

export const LoggedInTrackingEvent: AddToShoppingListEvent = {
  category: 'product_blurb',
  action: 'list_icon_clicked',
  label: '',
};

const ProductAddToList = ({ product }: ProductAddToListProps) => {
  const { t } = useTranslation('product');
  const [isAddToListPopOverVisible, setIsAddToListPopOverVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { isTabletPortraitOrGreater } = useResponsive();
  const { isAnonymous = false } = useCustomer();
  const clickOutsideRef = useRef(null);
  const { routeToLogin } = useRouteToLogin();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
  });

  useOnClickOutside(clickOutsideRef, () => {
    setIsAddToListPopOverVisible(false);
  });

  const onAddToList = () => {
    // check if customer is logged in, if not route them to login modal.
    if (isAnonymous) {
      routeToLogin({ b2b: false, isLoginConfirm: false });
      addToShoppingListTracker(AnonymousTrackingEvent);
    } else {
      addToShoppingListTracker(LoggedInTrackingEvent);
      setIsAddToListPopOverVisible(true);
    }
  };

  const onAddToListClose = () => {
    setIsAddToListPopOverVisible(false);
  };
  const modalContainer = isFrontend() ? document.getElementById('modal-container') : null;

  return (
    <AddToListWrapper>
      {isAddToListPopOverVisible && (
        <>
          {isTabletPortraitOrGreater ? (
            ReactDOM.createPortal(
              <div ref={clickOutsideRef}>
                <AddToListPopper
                  ref={setPopperElement as any}
                  style={styles.popper}
                  {...attributes.popper}
                  data-testid="add-to-list-popper"
                >
                  <CartAddToListPopOver onClose={onAddToListClose} eventCategory="product_blurb" product={product} />
                </AddToListPopper>
              </div>,
              modalContainer as Element
            )
          ) : (
            <Takeover>
              <CartAddToListPopOver onClose={onAddToListClose} eventCategory="product_blurb" product={product} />
            </Takeover>
          )}
        </>
      )}
      <div ref={setReferenceElement as any}>
        <AddToListButton
          data-testid="product-add-to-list-btn"
          icon={IconAddToList}
          onClick={onAddToList}
          color="none"
          title={t('mylists:mylists->addToList')}
        />
      </div>
    </AddToListWrapper>
  );
};

export default ProductAddToList;
