import { createWishlist } from '@api';
import addToShoppingList from '@api/addToShoppingList';
import useShoppingLists from '@components/organisms/AddToShoppingList/useShoppingLists';
import { addToShoppingListTracker } from '@trackers';
import { AddToShoppingListEvent } from 'trackers/addToShoppingList';

interface Props {
  listName: string;
  eventCategory?: AddToShoppingListEvent['category'];
  productsToAdd: ShoppingListProduct[];
}

const useAddToShoppingList = () => {
  const { refreshLists } = useShoppingLists();
  const addToExistingShoppingList = async ({ listName, eventCategory, productsToAdd }: Props) => {
    if (eventCategory) {
      addToShoppingListTracker({ category: eventCategory, action: 'saved_to_list', label: 'existing' });
    }
    const res = await addToShoppingList(listName, productsToAdd);
    await refreshLists();
    return res;
  };

  const addToNewShoppingList = async ({ listName, eventCategory, productsToAdd }: Props) => {
    if (eventCategory) {
      addToShoppingListTracker({ category: eventCategory, action: 'saved_to_list', label: 'new' });
    }
    const listId = await createWishlist({ name: listName, entries: productsToAdd });
    await refreshLists();
    return listId;
  };

  return { addToExistingShoppingList, addToNewShoppingList };
};

export default useAddToShoppingList;
