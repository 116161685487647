import styled from 'styled-components';
import { rem } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const EnergyDeclarationArrowButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;
export const EnergyDeclarationArrowImage = styled.img`
  height: ${rem(40)};
  width: auto;
`;
