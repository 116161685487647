import { rem } from 'polished';
import styled, { css } from 'styled-components';
import media from '@constants/responsive';
import { ArticleComponentPadding } from '@styles/cms';

interface ContainterProps {
  $withPadding: boolean;
}
export const Container = styled.div<ContainterProps>`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  display: flex;
  justify-content: center;
  margin-bottom: ${rem(30)};
  ${({ $withPadding }) =>
    $withPadding &&
    css`
      ${ArticleComponentPadding}
    `}
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${rem(220)};

  ${media.tabletPortrait} {
    height: ${rem(380)};
  }
  ${media.desktop} {
    height: ${rem(548)};
  }
`;
