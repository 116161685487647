import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';
import Link from '@components/atoms/__DEPRECATED__/Link';
import Paragraph from '@components/atoms/Paragraph';
import PRODUCT_LABELS from '@constants/productLabels';
import PROMOTION_THEME, { PROMOTION_TYPE } from '@constants/promotion';
import { dateToShortMonth, formatToLongDate } from '@helpers/date';
import { getLowestHistoricalPrice, promotionComparePriceIsSame, shouldShowOrdinaryPrice } from '@helpers/productHelper';
import useResponsive from '@hooks/useResponsive';
import useTranslation from 'next-translate/useTranslation';
import { ReactElement } from 'react';
import {
  Bottom,
  Discount,
  EnergyDeclarationLabelWrapper,
  ProductComparePrices,
  ProductInfo,
  ProductManufacturer,
  ProductTitle,
  PromotionLabel,
  PromotionLabelWrapper,
  PromotionValidDate,
  StyledParagraph,
} from './ProductDetailsInformation.styles';

interface ProductDetailsInformationProps {
  product: AxfoodProductDetailsViewModel;
  energyDeclarationLabel?: ReactElement;
}

const ProductDetailsInformation = ({ product, energyDeclarationLabel }: ProductDetailsInformationProps) => {
  const { t } = useTranslation('product');
  const { isTabletPortraitOrGreater } = useResponsive();
  const {
    manufacturer,
    price,
    comparePrice,
    comparePriceUnit,
    potentialPromotions: [promotion],
    originCountry,
    fruitAndVegetableVariety,
    labels,
    bargainProduct,
  } = product;

  const hasPromotion = !!promotion;
  const { comparePrice: promotionComparePrice, promotionType } = hasPromotion && promotion;
  const promotionTheme = promotion?.promotionTheme?.code;
  const alwaysGoodPrice = PROMOTION_THEME.ALWAYS_GOOD_PRICE;
  const isSingelProductAlwaysGoodPricePromotionProduct =
    promotionTheme === alwaysGoodPrice && promotionType !== PROMOTION_TYPE.MIX_MATCH_PRICE_PROMOTION;

  const showValidDate = hasPromotion && promotion.validUntil && promotionTheme !== alwaysGoodPrice;
  const isDrugProduct = product.isDrugProduct || product.nicotineMedicalProduct;
  const longLabelThreshold = 30;
  const promotionHasLongLabel = hasPromotion && promotion.textLabel && promotion?.textLabel?.length > longLabelThreshold;

  const showOrdinaryPrice = shouldShowOrdinaryPrice(product) && !isSingelProductAlwaysGoodPricePromotionProduct;
  const showPromotionComparePrice =
    hasPromotion && !promotionComparePriceIsSame(product) && !isSingelProductAlwaysGoodPricePromotionProduct;
  const showProductComparePrice = !!comparePrice;

  const lowestHistoricalPrice = getLowestHistoricalPrice(product);
  const hasLowestHistoricalPrice = !!lowestHistoricalPrice;
  const showBargainText = !!bargainProduct;

  return (
    <>
      <PromotionLabelWrapper $hasLongLabel={!!promotionHasLongLabel}>
        {showBargainText && (
          <PromotionLabel>
            <Paragraph color="primary" weight="bold">
              {t('localBargainText')}
            </Paragraph>
          </PromotionLabel>
        )}
        {!showBargainText && hasPromotion && promotion.textLabel && (
          <PromotionLabel>
            <Paragraph color={promotionTheme === alwaysGoodPrice ? 'orange' : 'primary'}>
              {promotion.textLabel}
            </Paragraph>
          </PromotionLabel>
        )}

        {isDrugProduct && (
          <Paragraph color="black" weight="bold" data-testid="is-drug-product">
            {t('productDetails:productDetails->isDrugProduct')}
          </Paragraph>
        )}
        {showValidDate && promotion.validUntil && (
          <PromotionValidDate>
            {`${t('productDetails:productDetails->validUntil')} ${dateToShortMonth(promotion.validUntil)}`}
          </PromotionValidDate>
        )}
      </PromotionLabelWrapper>
      <ProductTitle type="h1" size="sm" color="black">
        {product.name}
      </ProductTitle>
      <ProductManufacturer>
        <StyledParagraph size="md">
          {manufacturer && (
            <Link
              underlined={false}
              margin={false}
              data-testid="manufacturer"
              href={`/sok?q=${manufacturer}:topRated:commercialName2:${manufacturer}`}
            >
              {manufacturer}
            </Link>
          )}
          {fruitAndVegetableVariety && (
            <ProductInfo $textTransform="capitalize" data-testid="fruit-and-vegetable-variety">
              {fruitAndVegetableVariety}
            </ProductInfo>
          )}
          {product.displayVolume && (
            <ProductInfo $textTransform="lowercase" data-testid="display-volume">
              {product.displayVolume}
            </ProductInfo>
          )}
          {originCountry && (
            <ProductInfo $textTransform="capitalize" data-testid="origin-country">
              {originCountry}
            </ProductInfo>
          )}
        </StyledParagraph>
      </ProductManufacturer>
      <Bottom>
        <ProductComparePrices data-testid="Product-compare-price">
          {hasLowestHistoricalPrice ? (
            <Paragraph size="xsmall">{`${t('product:price->lowestHistorical')} ${lowestHistoricalPrice}`}</Paragraph>
          ) : (
            showOrdinaryPrice && (
              <Paragraph
                size="xsmall"
                data-testid="ordinary-price"
              >{`${t('product:price->ordinary')} ${price}`}</Paragraph>
            )
          )}
          {showProductComparePrice && (
            <Paragraph size="xsmall" data-testid="compare-price">
              {`${t('product:price->compare')} ${comparePrice}/${comparePriceUnit}`}
              {!!product.depositPrice?.length && <span>{t('product:price->deposit')}</span>}
            </Paragraph>
          )}
          {showPromotionComparePrice && !!promotionComparePrice && (
            <Discount>
              <Paragraph size="xsmall" data-testid="promotion-compare-price">
                {`${t('product:price->compare')}
              ${
                promotionComparePrice.includes('kg')
                  ? promotionComparePrice
                  : `${promotionComparePrice}/${comparePriceUnit}`
              }`}
              </Paragraph>
            </Discount>
          )}
          {hasPromotion && promotion.validUntil && (
            <Paragraph size="xsmall" data-testid="end-date">
              {`${t('product:availableTill')} ${formatToLongDate(promotion.validUntil)}`}
            </Paragraph>
          )}
          {labels?.includes(PRODUCT_LABELS['age_restricted_18']) && (
            <Paragraph size="xsmall">{t('product:AgeRestriction18')}</Paragraph>
          )}
        </ProductComparePrices>
        {!isTabletPortraitOrGreater && !!energyDeclarationLabel && (
          <EnergyDeclarationLabelWrapper data-testid="energy-declaration-label-wrapper">
            {energyDeclarationLabel}
          </EnergyDeclarationLabelWrapper>
        )}
      </Bottom>
    </>
  );
};

export default ProductDetailsInformation;
