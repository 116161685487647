import handleKeyDownForInteractiveElement from '@helpers/handleKeyDownForInteractiveElement';
import isFrontend from '@helpers/isFrontend';
import LockScrollStyle from '@styles/lockScroll';
import useTranslation from 'next-translate/useTranslation';
import { KeyboardEvent, useRef } from 'react';
import { LockScrollBarElement, OverlayStyled } from './Overlay.styles';

interface Props {
  zIndex?: number;
  opacity?: number;
  onClick?: () => void;
  lockScroll: boolean;
  position?: number;
  className?: string;
}

const Overlay = ({ zIndex = 100, opacity = 0.5, onClick, lockScroll, position = 0, className }: Props) => {
  const scrollBarWidth = useRef(isFrontend() ? window.innerWidth - document.body.offsetWidth : 0);
  const { t } = useTranslation('common');
  return (
    <>
      {lockScroll && (
        // Background for this part of the code:
        // In windows/PC devices, all the browsers have scrollbar visible at all time. The scrollbar in windows
        // take up some space from the view contant. When the Body get positioned fixed with an overflow:hidden
        // the scrollbar will no longer be visible and this will cause the content to jump to the right by xx-pixel
        // coresponsing to the scrollbars width. This solution will add xx-pixel as margin to compensate for the jumping content.
        <>
          <LockScrollStyle scrollBarWidth={scrollBarWidth.current} />
          {/* This code is placing an white dummy <div> with white background to make the appeara more clean
              and hiding any overflowing ovarlay because of the added margins.
              TBD: With new minicart - we will have an overlay that covers entire content again and probably the
              Div can be removed
          */}
          <LockScrollBarElement $scrollBarWidth={scrollBarWidth.current}></LockScrollBarElement>
        </>
      )}
      <OverlayStyled
        data-testid="overlay"
        className={className}
        role="button"
        tabIndex={0}
        $zIndex={zIndex}
        $opacity={opacity}
        onClick={onClick}
        $position={position}
        onKeyDown={(e: KeyboardEvent) => handleKeyDownForInteractiveElement(e, onClick || (() => {}))}
        aria-label={!!onClick ? t('defaultActions->cancel') : undefined}
      />
    </>
  );
};
export default Overlay;
