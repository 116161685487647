import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';

interface SizeProps {
  theme: DefaultTheme;
  $withPadding: boolean;
}

const Small = ({ $withPadding }: SizeProps) => css`
  ${$withPadding &&
  css`
    padding: ${rem(3)};
  `}
  ${media.tabletPortrait} {
    opacity: 0;
    top: 50vh;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: ${rem(572)};
    height: auto;

    ${$withPadding &&
    css`
      padding: ${rem(52)} ${rem(65)};
    `}
  }
  ${media.desktop} {
    width: ${rem(738)};
  }
`;

const Medium = ({ $withPadding }: SizeProps) => css`
  ${$withPadding &&
  css`
    padding: ${rem(5)};
  `}
  ${media.tabletLandscape} {
    opacity: 0;
    top: 50vh;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: ${rem(572)};
    height: auto;

    ${$withPadding &&
    css`
      padding: ${rem(52)} ${rem(65)};
    `}
  }
  ${media.desktop} {
    width: ${rem(630)};
  }
`;

const Large = ({ $withPadding }: SizeProps) => css`
  ${$withPadding &&
  css`
    padding: ${rem(5)};
  `}
  ${media.tabletPortrait} {
    ${$withPadding &&
    css`
      padding: ${rem(15)};
    `}
  }
  ${media.desktop} {
    opacity: 0;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: ${rem(1120)};
    height: auto;

    ${$withPadding &&
    css`
      padding: ${rem(52)} ${rem(65)};
    `}
  }
`;

const IsOpen = () => css`
  height: 100%;
  overflow-y: auto;
  ${media.tabletPortrait} {
    height: auto;
    max-height: 100vh;
    opacity: 1;
  }
`;

interface IContainer {
  $size: 'small' | 'medium' | 'large';
  $withPadding: boolean;
  $isOpen: boolean;
  $position: 'top' | 'center';
  $lockModalScroll: boolean;
  $zIndex: number;
  $hideBackground: boolean;
}

export const Container = styled.div<IContainer>`
  position: fixed;
  background: ${({ $hideBackground, theme }) => ($hideBackground ? 'none' : theme.colors.colorWhite)};
  transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translate(0, 0) scale(1);
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  scroll-behavior: smooth;
  padding: 0;
  z-index: ${({ $zIndex }) => $zIndex};

  ${(props) => props.$size === 'small' && Small}
  ${(props) => props.$size === 'medium' && Medium}
  ${(props) => props.$size === 'large' && Large}
  ${(props) => props.$isOpen && IsOpen}
  ${(props) =>
    props.$isOpen &&
    (props.$size === 'medium' || props.$size === 'large') &&
    css`
      height: 100%;
      ${media.tabletPortrait} {
        height: 100%;
      }
      ${media.tabletLandscape} {
        height: auto;
      }
      ${media.desktop} {
        height: auto;
        max-height: calc(100% - ${rem(100)});
      }
    `}
  ${(props) =>
    props.$position === 'top' &&
    css`
      ${media.desktop} {
        top: ${rem(50)};
        transform: translateX(-50%) translateY(0);
      }
    `}
  ${(props) =>
    props.$lockModalScroll &&
    css`
      overflow-y: hidden;
    `}
`;

interface IContent {
  $size: 'small' | 'medium' | 'large';
}
export const Content = styled.div<IContent>`
  ${(props) =>
    props.$size === 'small' &&
    css`
      display: flex;
      text-align: center;
      justify-content: center;
      height: 100%;
      align-items: center;
      ${media.tabletPortrait} {
        display: block;
        text-align: left;
        height: initial;
      }
    `}
  ${(props) =>
    props.$size === 'medium' &&
    css`
      display: flex;
      text-align: center;
      justify-content: center;
      height: 100%;
      align-items: flex-start;
      ${media.tabletLandscape} {
        display: block;
        text-align: left;
        height: initial;
      }
    `}
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  right: ${rem(10)};
  top: ${rem(10)};
  margin: ${rem(6)} ${rem(8)};
  z-index: 100;
`;
