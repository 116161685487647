/* eslint-disable react/jsx-props-no-spreading */
import { openLoginModal } from '@actions/login';
import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';
import Icon from '@components/atoms/Icon';
import Button from '@components/atoms/__DEPRECATED__/Button';
import CartAddToList from '@components/organisms/CartAddToListPopOver';
import LINKS from '@constants/links';
import { useAppDispatch } from '@hooks/redux';
import useAppRouter from '@hooks/useAppRouter';
import useCustomer from '@hooks/useCustomer';
import useResponsive from '@hooks/useResponsive';
import IconAddToList from '@public/icons/colorIcons/icon-add-to-list.svg';
import { addToShoppingListTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { usePopper } from 'react-popper';
import { AddToListPopper, AddToListWrapper, BackgroundOverlay } from './ProductDetailsAddToList.styles';

interface ProductDetailsAddToListProps {
  product: AxfoodProductDetailsViewModel;
  setLockModalScroll: (lockModal: boolean) => void;
  modalElement: HTMLElement | null;
}

const ProductDetailsAddToList = ({ product, setLockModalScroll, modalElement }: ProductDetailsAddToListProps) => {
  const { t } = useTranslation('productDetails');
  const dispatch = useAppDispatch();
  const { isAnonymous } = useCustomer();
  const [isAddToListPopOverVisible, setIsAddToListPopOverVisible] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
  const { isTabletPortraitOrGreater } = useResponsive();
  const { push, query, pathname } = useAppRouter();

  const ModalElementScrollPosition = modalElement ? Math.abs(modalElement.getBoundingClientRect().top) : 0;
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top-start',
  });

  const handleAddToList = () => {
    if (isAnonymous) {
      addToShoppingListTracker({ category: 'product_detail', action: 'save_as_list_redirected_to_login', label: '' });
      const { name, productCode, ...rest } = query;
      push({ pathname, query: rest }, LINKS.LOGIN, { shallow: true });
      dispatch(openLoginModal({ b2b: false, isLoginConfirm: false }));
    } else {
      setIsAddToListPopOverVisible(true);
    }
  };

  const onAddToListClose = () => {
    setIsAddToListPopOverVisible(false);
  };

  useEffect(() => {
    setLockModalScroll(isAddToListPopOverVisible);
  }, [isAddToListPopOverVisible, setLockModalScroll]);

  return (
    <>
      <AddToListWrapper>
        <div ref={setReferenceElement}>
          <Button onClick={handleAddToList} variant="none" data-testid="add-to-list-button">
            <Icon margin="right" color="none" icon={IconAddToList} data-testid="icon-add-to-list" />
            {t('productDetails->buttons->addToList')}
          </Button>
        </div>
        {isAddToListPopOverVisible && (
          <>
            {isTabletPortraitOrGreater ? (
              <AddToListPopper
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
                data-testid="add-to-list-popper"
              >
                <CartAddToList
                  onClose={onAddToListClose}
                  eventCategory="product_detail"
                  data-testid="add-to-list-popover"
                  product={product}
                />
              </AddToListPopper>
            ) : (
              <CartAddToList
                onClose={onAddToListClose}
                eventCategory="product_detail"
                data-testid="add-to-list-popover"
                product={product}
                topPosition={ModalElementScrollPosition}
              />
            )}
          </>
        )}
      </AddToListWrapper>
      {isAddToListPopOverVisible && <BackgroundOverlay onClick={onAddToListClose} data-testid="overlay" />}
    </>
  );
};

export default ProductDetailsAddToList;
