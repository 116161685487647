import { findComponentsByIds } from '@api';
import { useAppSelector } from '@hooks/redux';
import useCustomer from '@hooks/useCustomer';
import { selectCartDeliveryModeCode } from '@selectors/cart';
import { selectActiveStoreId } from '@selectors/delivery';
import useSWRImmutable from 'swr/immutable';

const useCmsComponents = (componentIds: string, storeId?: string) => {
  const { customerId } = useCustomer();
  const activeStoreId = useAppSelector(selectActiveStoreId);
  const deliveryModeCode = useAppSelector(selectCartDeliveryModeCode);

  const shouldFetch = Boolean(componentIds);
  // useSWRImmutable flags the resource as immutable. Only key changes will trigger re-fetch
  const { data, isLoading, error, isValidating } = useSWRImmutable(
    shouldFetch
      ? {
          endpoint: 'cms/components',
          storeId,
          componentIds,
          customerId,
          activeStoreId,
          deliveryModeCode,
        }
      : null,
    ({ componentIds, storeId }) => findComponentsByIds({ componentIds: JSON.parse(componentIds), storeId })
  );
  return {
    data: data?.component,
    isLoading,
    error,
    isValidating,
  };
};

export default useCmsComponents;
