import { mobileSearchPortalId } from '@constants/globalElementIds';
import useResponsive from '@hooks/useResponsive';
import { memo, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import getContentPositioningInfo from './getContentPositioningInfo';
import { DesktopSuggestionsWrapper, MobileSuggestionsWrapper } from './SearchSuggestions.styles';
import SuggestionsContent, { Props } from './SuggestionsContent';

const SearchSuggestionsContainer = ({ children }: { children: ReactNode }) => {
  const { isTabletPortraitOrGreater } = useResponsive();

  if (isTabletPortraitOrGreater) {
    return <DesktopSuggestionsWrapper>{children}</DesktopSuggestionsWrapper>;
  }

  const { contentTopInDocument, contentHeight } = getContentPositioningInfo();

  const portalContainer = document.getElementById(mobileSearchPortalId);
  return portalContainer
    ? ReactDOM.createPortal(
        <MobileSuggestionsWrapper $top={contentTopInDocument} $height={contentHeight}>
          {children}
        </MobileSuggestionsWrapper>,
        portalContainer
      )
    : null;
};

const SearchSuggestions = ({ onClickSearchTerm, onCategoryClick, searchOnEnter, multiSearchData }: Props) => {
  return (
    <SearchSuggestionsContainer>
      <SuggestionsContent
        onClickSearchTerm={onClickSearchTerm}
        onCategoryClick={onCategoryClick}
        searchOnEnter={searchOnEnter}
        multiSearchData={multiSearchData}
        data-testid="search-suggestions"
      />
    </SearchSuggestionsContainer>
  );
};

export default memo(SearchSuggestions);
