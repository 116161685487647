import styled from 'styled-components';
import media from '@constants/responsive';
import { rem } from 'polished';

export const InfoBlock = styled.div`
  margin-top: ${rem(30)};
`;

export const ProductInfoWrapper = styled.div`
  td:last-child {
    text-align: right;
    ${media.tabletPortrait} {
      text-align: left;
    }
  }
`;
