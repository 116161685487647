import Icon, { IconType } from '@components/atoms/Icon/Icon';
import React, { ReactElement } from 'react';
import { StyledButton } from './IconButton.styles';

interface IconButtonProps {
  icon: IconType;
  onClick: (e: React.SyntheticEvent) => void;
  disabled?: boolean;
  color?: ColorType;
  title?: string;
  'data-testid'?: string;
  className?: string;
  iconSize?: number;
  'aria-label'?: string;
}

const IconButton = ({
  icon,
  onClick,
  disabled = false,
  color = 'black',
  title,
  'data-testid': testId = 'icon-button',
  className,
  iconSize = 24,
  'aria-label': ariaLabel,
}: IconButtonProps): ReactElement => {
  return (
    <StyledButton
      type="button"
      onClick={onClick}
      disabled={disabled}
      title={title}
      aria-label={ariaLabel}
      data-testid={testId}
      className={className}
    >
      <Icon icon={icon} color={color} size={iconSize} data-testid={icon.name} />
    </StyledButton>
  );
};

export default IconButton;
