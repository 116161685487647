import Button from '@components/atoms/Button';
import media from '@constants/responsive';
import { BaseContent, BaseFlyerContainer } from '@features/weeklyFlyer';
import FlyerBannerVector from '@features/weeklyFlyer/components/FlyerBannerVector';
import { rem } from 'polished';
import styled from 'styled-components';
import ChangeStore from './ChangeStore';

export const OuterWrapper = styled.div`
  position: relative;
`;

export const Container = styled(BaseFlyerContainer)`
  grid-template-areas:
    'changeStore'
    'vector'
    'content';
  grid-template-rows: auto ${rem(190)} auto;

  ${media.tabletPortrait} {
    grid-template-areas:
      'content changeStore'
      'content vector';
    grid-template-columns: minmax(0, 1fr) minmax(${rem(350)}, 1fr);
    grid-template-rows: auto auto;
    height: ${rem(376)};
  }

  ${media.tabletLandscape} {
    grid-template-columns: minmax(${rem(417)}, 1fr) minmax(0, 1fr);
    height: ${rem(400)};
  }

  ${media.desktop} {
    grid-template-areas:
      'content vector changeStore'
      'content vector .';
    grid-template-columns: auto minmax(0, ${rem(900)}) auto;
    column-gap: ${rem(16)};
  }
`;

export const Content = styled(BaseContent)`
  ${media.tabletPortrait} {
    height: ${rem(400)};
  }
`;

export const Vector = styled(FlyerBannerVector)`
  ${media.tabletPortrait} {
    padding: ${rem(32)} 0 0 0;
  }
  ${media.desktop} {
    padding: ${rem(32)} 0 0 0;
  }
`;

export const StyledChangeStore = styled(ChangeStore)`
  grid-area: changeStore;
  display: flex;
  justify-content: flex-end;

  ${media.tabletPortrait} {
    padding: ${rem(32)} 0 0 0;
  }
`;

//TODO: Move to atoms
export const Label = styled.span`
  font-size: ${rem(12)};
  font-weight: 500;

  ${media.tabletPortrait} {
    font-size: ${rem(16)};
  }
`;

//Change Store
export const ChangeStoreButtonWrapper = styled.div`
  padding: ${rem(16)} ${rem(24)} 0;

  ${media.tabletPortrait} {
    padding: 0;
  }
`;

export const ChangeStoreButton = styled(Button)``;

export const StoreSearchPopover = styled.div`
  position: absolute;
  top: ${rem(40)};
  right: 0;
  z-index: 1000;
  padding: 0 ${rem(24)};
  width: 100%;

  ${media.tabletPortrait} {
    width: auto;
    padding: 0;
  }
`;
