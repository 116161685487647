import { rem } from 'polished';

const fontSize = {
  xsmall: rem(10),
  small: rem(12),
  normal: rem(14),
};

const fontWeight = {
  thin: 300,
  normal: 400,
  medium: 500,
  bold: 700,
};
export type FontWeights = keyof typeof fontWeight;

const fonts = {
  fontSize,
  fontWeight,
};

export type ThemeFont = typeof fonts;
export default fonts;
