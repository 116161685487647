import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const SortsOnText = styled.span`
  display: none;
  ${media.largeMobile} {
    display: block;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  gap: ${rem(2)};
  font-size: ${rem(14)};
  min-height: ${rem(24)};
  white-space: nowrap;
  line-height: 1.4;
`;

export const ExpandedWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  position: absolute;
  top: calc(100% + ${rem(8)});
  z-index: ${({ theme }) => theme.layers.dropdown};
  width: ${rem(280)};
  border-radius: ${rem(16)};
  padding: 0;
  box-shadow: ${({ theme }) => theme.shadows.dropdown};
`;

export const OptionName = styled.span`
  height: 100%;
  display: block;
  font-size: ${rem(14)};
  padding: ${rem(16)} 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.colorGainsboro};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const StyledOption = styled.div<{ $currentFocus: boolean }>`
  padding: 0 ${rem(16)};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  cursor: pointer;
  ${({ $currentFocus, theme }) =>
    $currentFocus &&
    css`
      background-color: ${theme.colors.colorDesertStorm};
      font-weight: ${theme.fonts.fontWeight.bold};
    `}

  &:first-of-type {
    border-top-left-radius: ${rem(16)};
    border-top-right-radius: ${rem(16)};
  }
  &:last-of-type {
    border-bottom-left-radius: ${rem(16)};
    border-bottom-right-radius: ${rem(16)};
  }

  &:last-of-type ${OptionName} {
    border-bottom: none;
  }
  &:hover {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
    background-color: ${({ theme }) => theme.colors.colorDesertStorm};
  }
`;

export const SelectedText = styled.span`
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  max-width: ${rem(150)};
  text-overflow: ellipsis;
  overflow: hidden;

  ${media.largeMobile} {
    max-width: unset;
    text-overflow: unset;
    overflow: unset;
  }
`;
