import { Stack } from '@components/atoms/Stack';
import FlyerLink from '../FlyerLink';
import { BodyWrapper } from './FlyerBannerContent.styles';

interface Props {
  flyerLink?: string;
  header?: React.ReactNode;
  body?: React.ReactNode;
}
const FlyerContent = ({ header, body, flyerLink }: Props) => (
  <>
    <Stack $space="small">
      {header && <Stack $space="xsmall">{header}</Stack>}
      {body && <BodyWrapper>{body}</BodyWrapper>}
    </Stack>
    <FlyerLink flyerLink={flyerLink} iconColor="white" variant="primary" />
  </>
);

export default FlyerContent;
