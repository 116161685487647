import { headerId } from '@constants/globalElementIds';
import LINKS from '@constants/links';
import useAppRouter from '@hooks/useAppRouter/useAppRouter';
import useShowMenu from '@hooks/useShowMenu';
import Head from 'next/head';
import { useState } from 'react';
import CheckoutHeader from './CheckoutHeader';
import DefaultHeader from './DefaultHeader';
import { StyledHeader } from './Header.styles';
import TakeoverMenuLinks from './TakeoverMenuLinks';
import useScrollDirection from './hooks/useScrollDirection';
import useTopBarData from './hooks/useTopBarData';

const Header = () => {
  const { showTakeoverMenu } = useShowMenu();
  const { asPath } = useAppRouter();
  const { logotype, menuLinks } = useTopBarData();
  const [showTakeoverMenuLinks, setShowTakeoverMenuLinks] = useState(false);
  const isFAQPage = asPath === LINKS.FAQ;

  const { isScrollingDown, triggerRef } = useScrollDirection();
  const toggleTakeoverMenu = () => setShowTakeoverMenuLinks(!showTakeoverMenuLinks);

  const isCheckout = asPath === LINKS.CHECKOUT;

  return (
    <>
      <Head>
        <meta name="theme-color" content="#FADBD9" />
      </Head>
      <StyledHeader id={headerId} ref={triggerRef}>
        {isCheckout ? (
          <CheckoutHeader logotype={logotype} />
        ) : (
          <DefaultHeader
            toggleTakeoverMenu={toggleTakeoverMenu}
            showMenuLinks={showTakeoverMenuLinks}
            logotype={logotype}
            menuLinks={menuLinks}
            isScrollingDown={isFAQPage ? false : isScrollingDown}
            searchEnabled={!isFAQPage}
          />
        )}
      </StyledHeader>

      {showTakeoverMenuLinks && showTakeoverMenu && (
        <TakeoverMenuLinks menuLinks={menuLinks} toggleTakeoverMenu={toggleTakeoverMenu} />
      )}
    </>
  );
};

export default Header;
