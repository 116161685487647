import Text from '@components/atoms/Text';
import NoResults from '@components/molecules/NoResults';
import { Product } from '@components/molecules/Product/ProductDataMapper';
import SearchLinks from '@components/organisms/SearchLinks';
import useBufferedAnalyticsEvent from '@hooks/useBufferedAnalyticsEvent/useBufferedAnalyticsEvent';
import { productTracker, searchTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { TrackItem } from '../../../../../../trackers/productTracker/productTracker';
import { MultiSearchHookReturn } from '../../useMultiSearchAsYouType';
import ProductWithDelayedTracking from './ProductWithDelayedTracking';
import {
  ProductSkeleton,
  ProductsTitle,
  SearchButtonWrapper,
  ShowAllButton,
  SuggestionsContentWrapper,
  TotalNumberOfResults,
  Wrapper,
} from './SuggestionsContent.styles';

export type MultiSearchData = Pick<
  MultiSearchHookReturn,
  | 'categories'
  | 'searchSuggestions'
  | 'totalNumberOfResults'
  | 'customSuggestion'
  | 'freeTextSearch'
  | 'productsFormatted'
  | 'isFetchingData'
  | 'showSkeletons'
>;

export interface Props {
  onClickSearchTerm: (term: string) => void;
  onCategoryClick: (category: string) => void;
  searchOnEnter: () => void;
  multiSearchData: MultiSearchData;
}

const ProductSkeletons = () => {
  return (
    <>
      <ProductSkeleton />
      <ProductSkeleton />
      <ProductSkeleton />
      <ProductSkeleton />
      <ProductSkeleton />
    </>
  );
};

const SuggestionsContent = ({ onClickSearchTerm, onCategoryClick, searchOnEnter, multiSearchData }: Props) => {
  const { t } = useTranslation('common');
  const {
    categories,
    searchSuggestions,
    totalNumberOfResults,
    customSuggestion,
    freeTextSearch = '',
    productsFormatted,
    showSkeletons,
  } = multiSearchData;
  const hasProducts = !!productsFormatted.length;
  const hasSuggestions = !!searchSuggestions.length;
  const hasCategories = !!categories.length;
  const hasNoData = !hasSuggestions && !hasCategories && !hasProducts && !showSkeletons;
  const showProducts = hasProducts || showSkeletons;

  const [trackedProducts, setTrackedProducts] = useState<string[]>([]);

  const eventListName = `Produktsökmodal | ${freeTextSearch}`;
  const track = (impressionProducts: { product: Product; position: number }[]) => {
    const impressions: TrackItem[] = impressionProducts.map(({ product, position }) => {
      return {
        ...product.analytics?.impressionObject,
        position,
        list: eventListName,
      };
    });

    productTracker.trackEcommerceImpressionsPushed(impressions);
  };

  const bufferSize = 5;
  const debounceTime = 500;
  const { debouncedTrack, queueTrackingOfItem } = useBufferedAnalyticsEvent(
    productsFormatted,
    track,
    bufferSize,
    debounceTime
  );

  const onProductShown = (product: Product, position: number) => {
    queueTrackingOfItem({ product, position });
    debouncedTrack();
  };

  const onProductClick = (productname: string, productCode: any) => {
    if (!trackedProducts.includes(productCode)) {
      searchTracker.trackProductClick(freeTextSearch, productname);
      setTrackedProducts([...trackedProducts, productCode]);
    }
  };

  const showAllResults = () => {
    searchTracker.trackShowAllProducts(freeTextSearch);
    searchOnEnter();
  };

  return (
    <Wrapper>
      <SuggestionsContentWrapper data-testid="search-suggestions">
        {hasNoData ? (
          <NoResults searchTerm={freeTextSearch} customSuggestion={customSuggestion} modal />
        ) : (
          <>
            <SearchLinks
              suggestions={searchSuggestions}
              categories={categories}
              onSuggestionClick={onClickSearchTerm}
              onCategoryClick={onCategoryClick}
              showSkeletons={showSkeletons}
            />
            {showProducts && (
              <div>
                <ProductsTitle>{t('search->products')}</ProductsTitle>
                {showSkeletons ? (
                  <ProductSkeletons />
                ) : (
                  productsFormatted.map((product, i: number) => (
                    <ProductWithDelayedTracking
                      onProductClick={onProductClick}
                      key={product.code + product.name}
                      onProductShown={onProductShown}
                      position={i + 1}
                      product={product}
                      eventListName={eventListName}
                    />
                  ))
                )}
              </div>
            )}
          </>
        )}
      </SuggestionsContentWrapper>
      <SearchButtonWrapper>
        <ShowAllButton type="button" onClick={showAllResults} disabled={!hasProducts}>
          <Text type="p" size="lg" color="white" bold="medium">
            {t('showAllResults')}
          </Text>
          {hasProducts && (
            <TotalNumberOfResults>
              <Text role="status" type="p" size="lg" color="secondary" bold="medium">
                {t('search->productCount', { count: totalNumberOfResults })}
              </Text>
            </TotalNumberOfResults>
          )}
        </ShowAllButton>
      </SearchButtonWrapper>
    </Wrapper>
  );
};

export default SuggestionsContent;
