import Autocomplete from '@components/molecules/Autocomplete';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${rem(10)};
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.07);
  border-radius: ${rem(16)};
  background-color: ${({ theme }) => theme.colors.colorWhite};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${rem(4)};
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  ${media.tabletPortrait} {
    width: ${rem(400)};
  }
`;

export const Input = styled.input`
  background-color: ${({ theme }) => theme.colors.colorDesertStorm};
  border: ${rem(2)} solid ${({ theme }) => theme.colors.colorCararra};
  height: ${rem(60)};
  width: 100%;
  padding: 0 ${rem(10)} 0 ${rem(24)};
  margin: ${rem(10)} 0;

  border-radius: ${rem(8)};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  ${media.tabletPortrait} {
    margin: 0;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.colorTextSecondary};
  }

  &:focus {
    outline: none;
  }
`;

export const StoreList = styled(Autocomplete)`
  position: unset;
  border-radius: ${rem(16)};
  border: ${rem(2)} solid ${({ theme }) => theme.colors.colorCararra};
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  box-shadow: none;
  text-align: left;
`;
