import { AnyAction, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from 'store';
import * as types from './types';

export const openDeliveryModal = () => ({
  type: types.OPEN_DELIVERY_MODAL,
});
export const closeDeliveryModal = () => ({
  type: types.CLOSE_DELIVERY_MODAL,
});

export const setDeliveryStores = (stores: Array<StoreState>) => ({
  type: types.SET_STORES,
  stores,
});
const setDeliveryActiveStore = (active: StoreType) => ({
  type: types.SET_ACTIVE_STORE,
  active,
});
const setDeliveryCosts = (active: StoreType) => ({
  type: types.SET_DELIVERY_COSTS,
  active,
});

interface SetDeliveryActiveStoreAndCosts {
  (store: StoreType): ThunkAction<void, RootState, unknown, AnyAction>;
}

export const setDeliveryActiveStoreAndCosts: SetDeliveryActiveStoreAndCosts = (store) => {
  return (dispatch: ThunkDispatch<RootState, unknown, AnyAction>) => {
    dispatch(setDeliveryActiveStore(store));
    dispatch(setDeliveryCosts(store));
  };
};
