import { rem } from 'polished';
import styled from 'styled-components';

interface IOverlayStyledProps {
  $zIndex: number;
  $opacity: number;
  $position: number;
}

export const OverlayStyled = styled.div<IOverlayStyledProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.colorAcadia};
  opacity: ${({ $opacity }) => $opacity};
  top: ${({ $position }) => rem($position)};
  transition: opacity 150ms ease-in;
  left: 0;
  z-index: ${({ $zIndex }) => $zIndex};
`;

export const LockScrollBarElement = styled.div<{ $scrollBarWidth: number }>`
  height: 100%;
  width: ${({ $scrollBarWidth }) => rem($scrollBarWidth)};
  position: fixed;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  z-index: 100;
`;
