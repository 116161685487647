import styled from 'styled-components';
import { rem } from 'polished';
import { BREAKPOINTS } from '@constants/responsive';

export const Container = styled.div<{ $withPadding: boolean }>`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  gap: ${rem(16)};
  background-color: ${({ theme }) => theme.colors.colorPrimaryPink};
  max-width: ${rem(BREAKPOINTS.xxl)};

  ${({ $withPadding }) =>
    $withPadding &&
    `
    padding: ${rem(44)} ${rem(64)} ${rem(32)};
  `}
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const BottomContainer = styled.div<{ $vertical?: boolean }>`
  display: flex;
  flex-direction: ${({ $vertical = false }) => ($vertical ? 'column' : 'row')};
  justify-content: space-between;
  gap: ${rem(16)};
  padding: ${({ $vertical = false }) => ($vertical ? `${rem(8)} 0 0 ${rem(16)}` : 0)};
`;

export const Column = styled.div`
  flex: 1;
`;

export const FooterSection = styled(Column)`
  display: flex;
  flex-direction: column;
  gap: ${rem(24)};
`;

export const LinksSection = styled.div<{ $horizontal?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};

  ${({ $horizontal = false }) =>
    $horizontal &&
    `
      flex-shrink: 0;
      flex-direction: row-reverse;
      gap: ${rem(32)};
    `}
`;
