import React from 'react';
import { AddToShoppingListEvent } from 'trackers/addToShoppingList';
import AddToShoppingList from '../AddToShoppingList';

import useAddCartProductsToList from './useAddCartProductsToList';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';

export interface Props {
  product?: AddToListProduct | Product | null;
  onClose: () => void;
  eventCategory: AddToShoppingListEvent['category'];
  topPosition?: number;
}

const CartAddToListPopOver = ({ onClose, eventCategory, product = null, topPosition = 0 }: Props) => {
  const { addCartProductsToExistingList, addCartProductsToNewList } = useAddCartProductsToList({
    eventCategory,
    product,
  });

  return (
    <AddToShoppingList
      onClose={onClose}
      topPosition={topPosition}
      addToList={addCartProductsToExistingList}
      addToNewList={addCartProductsToNewList}
    />
  );
};

export default CartAddToListPopOver;
