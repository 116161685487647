import styled from 'styled-components';
import { rem } from 'polished';
import media from '@constants/responsive';

export const SkeletonWrapper = styled.div``;

export const SkeletonHead = styled.div`
  padding: ${rem(37)} ${rem(12)} 0;
  display: flex;
  flex-direction: column;
  ${media.tabletPortrait} {
    flex-direction: row;
    padding: ${rem(70)} ${rem(20)} 0;
  }
  ${media.desktop} {
    padding: ${rem(100)} ${rem(32)} 0;
  }
`;

export const SkeletonImageWrapper = styled.div`
  display: flex;
  ${media.tabletPortrait} {
    min-width: 50%;
    > span {
      height: ${rem(305)};
      width: ${rem(305)};
    }
  }
`;

export const SkeletonInformationWrapper = styled.div`
  margin-top: ${rem(32)};
  ${media.tabletPortrait} {
    margin-top: 0;
  }
`;

export const SkeletonLabels = styled.div`
  display: flex;
  flex-direction: column;
  width: ${rem(76)};
  ${media.tabletPortrait} {
    width: ${rem(39)};
    > span {
      height: ${rem(30)};
      width: ${rem(30)};
    }
  }
  ${media.desktop} {
    width: ${rem(122)};
  }
`;

export const SkeletonMain = styled.div`
  display: flex;
  flex-direction: column;
  > span:first-child {
    max-width: 100%;
  }
  ${media.tabletPortrait} {
    > span:first-child {
      width: ${rem(300)};
      height: ${rem(34)};
    }
    > span:not(:first-child) {
      width: ${rem(150)};
      display: block;
    }
  }
`;

export const SkeletonFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > :first-child {
    align-self: flex-end;
    margin-top: ${rem(32)};
  }
  ${media.tabletPortrait} {
    > :first-child {
      align-self: flex-start;
      margin-top: ${rem(15)};
    }
    > :not(:first-child) {
      align-self: flex-start;
      width: ${rem(220)};
      height: ${rem(46)};
    }
  }
  ${media.desktop} {
    > :not(:first-child) {
      height: ${rem(40)};
    }
  }
`;

export const SkeletonAccordionSection = styled.div`
  margin-top: ${rem(76)};
  & > span {
    margin-top: 0;
    margin-bottom: ${rem(48)};
  }
  ${media.tabletPortrait} {
    display: none;
  }
`;

export const SkeletonTabsSection = styled.div`
  display: none;
  margin: ${rem(43)} 0 ${rem(50)};
  justify-content: center;
  ${media.tabletPortrait} {
    display: flex;
    & > span {
      margin: 0 ${rem(4)};
    }
  }
  ${media.desktop} {
    margin-top: ${rem(20)};
    & > span {
      width: ${rem(329)};
      height: ${rem(56)};
    }
  }
`;

export const SkeletonBannerWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.colorCararra};
  min-height: ${rem(360)};
`;
