import { ButtonVariant } from '@components/atoms/Button/Button';
import Icon from '@components/atoms/Icon';
import Paragraph from '@components/atoms/Paragraph';
import IconPdf from '@public/icons/regularIcons/icon-pdf.svg';
import useTranslation from 'next-translate/useTranslation';
import { LinkContainer, StyledLink } from '../../../promotions/StoreInfo/StoreInfo.styles';

interface FlyerProps {
  flyerLink: string | undefined;
  iconColor?: ColorType;
  variant?: ButtonVariant;
}
const FlyerLink = ({ flyerLink, iconColor = 'red', variant = 'stroke' }: FlyerProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      {flyerLink && (
        <LinkContainer $variant={variant}>
          <StyledLink href={flyerLink} linkTarget="_blank" variant={variant} size="md">
            <Icon icon={IconPdf} margin="right" color={iconColor} />
            <Paragraph size="small" weight="medium" color="primary">
              {t('weeklyFlyer')}
            </Paragraph>
          </StyledLink>
        </LinkContainer>
      )}
    </>
  );
};

export default FlyerLink;
