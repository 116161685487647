import Link from '@components/atoms/Link';
import SuggestionList from '@components/organisms/SuggestionList';
import { MultiSearchLink } from '@helpers/productMapper';
import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';
import { List, SuggestionSkeleton } from './SuggestionLinks.styles';

interface Props {
  searchSuggestions: MultiSearchLink[];
  showSkeletons: boolean;
  onClickSearchTerm: (name: string) => void;
}

export const SearchSuggestionSkeletons = () => {
  return (
    <>
      <li>
        <SuggestionSkeleton width={60} height={20} data-testid="suggestion-skeleton" />
      </li>
      <li>
        <SuggestionSkeleton width={55} height={20} data-testid="suggestion-skeleton" />
      </li>
      <li>
        <SuggestionSkeleton width={45} height={20} data-testid="suggestion-skeleton" />
      </li>
    </>
  );
};
const SuggestionLinks = ({ searchSuggestions, showSkeletons, onClickSearchTerm }: Props) => {
  const { t } = useTranslation('common');
  const hasSuggestions = !!searchSuggestions.length;
  const showSearchSuggestions = hasSuggestions || showSkeletons;
  return (
    <>
      {showSearchSuggestions && (
        <SuggestionList title={t('search->suggestions')} withHeader>
          <List>
            {showSkeletons ? (
              <SearchSuggestionSkeletons />
            ) : (
              searchSuggestions.map(({ name, url }) => (
                <li key={name} data-testid="search-suggestion-link">
                  <Link size="medium" href={url} onClick={() => onClickSearchTerm(name)}>
                    {name}
                  </Link>
                </li>
              ))
            )}
          </List>
        </SuggestionList>
      )}
    </>
  );
};

export default memo(SuggestionLinks);
