/* eslint-disable react/jsx-props-no-spreading */
import { IconType } from '@components/atoms/Icon/Icon';
import isFrontend from '@helpers/isFrontend';
import IconHelp from '@public/icons/regularIcons/icon-help.svg';
import dynamic from 'next/dynamic';
import { ReactNode, useState } from 'react';
import { usePopper } from 'react-popper';
import Paragraph from '../Paragraph';
import { ToolTipButton, ToolTipIcon, ToolTipText, TooltipReference } from './Tooltip.styles';

const TooltipContent = dynamic(() => import('@components/atoms/Tooltip/TooltipContent'), { ssr: false });

export interface TooltipProps {
  placement?: 'right' | 'bottom' | undefined;
  offset?: [number, number];
  iconColor?: ColorType;
  'data-testid'?: string;
  iconName?: IconType;
  disabled?: boolean;
  // toolTipText is required so when it's not visible it can be used as aria-label
  toolTipText: string;
  showToolTipText?: boolean;
  children: ReactNode;
}

const Tooltip = ({
  placement = 'bottom',
  offset = [3, 0],
  iconColor = 'black',
  'data-testid': testId = 'tooltip',
  iconName = IconHelp,
  disabled = false,
  toolTipText,
  showToolTipText = true,
  children,
}: TooltipProps) => {
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);

  const boundaryElement = isFrontend() ? document.body : undefined;

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          boundary: boundaryElement,
        },
      },
      {
        name: 'offset',
        options: {
          offset,
        },
      },
    ],
  });

  return (
    <>
      <TooltipReference
        role="tooltip"
        ref={setReferenceElement as any}
        data-testid={testId}
        $shouldShowContentOnHover={!disabled}
        tabIndex={0}
        aria-label={!showToolTipText ? toolTipText : undefined}
      >
        <ToolTipButton>
          {showToolTipText ? (
            <ToolTipText>
              <Paragraph size="small">{toolTipText}</Paragraph>
            </ToolTipText>
          ) : null}
          <ToolTipIcon icon={iconName} color={iconColor} data-testid="tooltip-icon" />
        </ToolTipButton>
        <TooltipContent
          boundaryElement={boundaryElement}
          popperElement={setPopperElement}
          styles={styles}
          attributes={attributes}
        >
          {children}
        </TooltipContent>
      </TooltipReference>
    </>
  );
};

export default Tooltip;
