import { headerId } from '@constants/globalElementIds';

const getContentPositioningInfo = () => {
  const contentObscuredHeight = document.getElementById(headerId)?.getBoundingClientRect()?.bottom || 0;
  const contentTopInDocument = (window?.scrollY || 0) + contentObscuredHeight;
  const contentHeight = (window?.innerHeight || contentObscuredHeight) - contentObscuredHeight;

  return { contentTopInDocument, contentHeight };
};

export default getContentPositioningInfo;
