import Button from '@components/atoms/__DEPRECATED__/Button';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const MergeCartModalStyled = styled.div`
  text-align: center;
  ${media.tabletPortrait} {
    text-align: left;
  }

  & > h2 {
    margin-bottom: ${rem(20)};
  }
  & > p {
    margin-bottom: ${rem(20)};
  }
`;

export const MergeCartModalButton = styled(Button)`
  margin-bottom: ${rem(15)};
`;

export const MergeCartModalButtonsContainer = styled.div`
  max-width: ${rem(464)};
  margin: auto;
  margin-top: ${rem(30)};
`;
