import styled from 'styled-components';
import { rem } from 'polished';

export const InputWrapper = styled.span`
  display: none;
`;

export const FileUploadLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const RemoveFileButton = styled.button`
  display: flex;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  border-width: 0;
  text-decoration: none;
  cursor: pointer;
  font-size: 100%;
  background-color: transparent;
`;

export const List = styled.ul`
  list-style: none;
  margin: ${rem(16)} 0;
  padding: 0;
`;

export const ListItem = styled.li`
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.colorSilver};
  padding-bottom: ${rem(6)};
  margin-bottom: ${rem(6)};
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  display: flex;
  align-items: center;
  &:last-child {
    border-bottom: none;
  }
`;

export const FileSize = styled.span`
  font-weight: normal;
`;

export const FileName = styled.span`
  display: inline-block;
  word-wrap: break-word;
  max-width: 90%;
`;

export const FileError = styled.div`
  color: ${({ theme }) => theme.colors.colorPrimary};
`;
