import { ReactNode } from 'react';
import { ParagraphStyled } from './Paragraph.styles';
import { ParagraphBoldType, ParagraphSizeType } from './types';

interface Props {
  children?: ReactNode;
  size?: ParagraphSizeType;
  bold?: ParagraphBoldType;
  textColor?: '' | Theme;
  className?: string;
  italics?: boolean;
  'data-testid'?: string;
  truncateText?: boolean;
  underlined?: boolean;
}

/**
 * @deprecated use new atoms/Paragraph instead
 */
const Paragraph = ({
  children,
  size = '',
  bold = '',
  textColor = '',
  className,
  italics = false,
  truncateText = false,
  underlined = false,
  'data-testid': testId = undefined,
}: Props) => {
  return (
    <ParagraphStyled
      $size={size}
      $bold={bold}
      $textColor={textColor}
      $italics={italics}
      $truncateText={truncateText}
      $underlined={underlined}
      className={className}
      data-testid={testId}
    >
      {children}
    </ParagraphStyled>
  );
};

export default Paragraph;
