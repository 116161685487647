import { IconType } from '@components/atoms/Icon/Icon';
import IconMscFish from '@public/icons/colorIcons/icon-msc-fish.svg';
import IconAscFish from '@public/icons/colorIcons/icon-asc-fish.svg';
import IconFairtradeCacao from '@public/icons/colorIcons/icon-fairtrade-cacao.svg';
import IconNicotineMedical from '@public/icons/colorIcons/icon-nicotine-medical.svg';
import IconSoilAssociationOrganic from '@public/icons/colorIcons/icon-soil-association-organic.svg';
import IconMilkFromSweden from '@public/icons/colorIcons/icon-milk-from-sweden.svg';
import IconAllergyApproved from '@public/icons/colorIcons/icon-allergy-approved.svg';
import IconSwedishFlag from '@public/icons/colorIcons/icon-swedish-flag.svg';
import IconKrav from '@public/icons/colorIcons/icon-krav.svg';
import IconEcological from '@public/icons/colorIcons/icon-ecological.svg';
import IconGlutenfree from '@public/icons/colorIcons/icon-glutenfree.svg';
import IconSwan from '@public/icons/colorIcons/icon-swan.svg';
import IconCrossedAx from '@public/icons/colorIcons/icon-crossed-ax.svg';
import IconEnvironmentalChoice from '@public/icons/colorIcons/icon-environmental-choice.svg';
import IconFromSweden from '@public/icons/colorIcons/icon-from-sweden.svg';
import IconMeatFromSweden from '@public/icons/colorIcons/icon-meat-from-sweden.svg';
import IconSwedishBird from '@public/icons/colorIcons/icon-swedish-bird.svg';
import IconAge18 from '@public/icons/colorIcons/icon-age-18.svg';
import IconSwedishMeat from '@public/icons/colorIcons/icon-swedish-meat.svg';
import IconAge15 from '@public/icons/colorIcons/icon-age-15.svg';
import IconRainforestAlliance from '@public/icons/colorIcons/icon-rainforest-alliance.svg';
import IconMedicineLabel from '@public/icons/colorIcons/icon-medicine-label.svg';
import IconKeyhole from '@public/icons/colorIcons/icon-keyhole.svg';
import IconLaktosfree from '@public/icons/colorIcons/icon-laktosfree.svg';
import IconEuFlower from '@public/icons/colorIcons/icon-eu-flower.svg';
import IconGots from '@public/icons/colorIcons/icon-gots.svg';
import IconFairtrade from '@public/icons/colorIcons/icon-fairtrade.svg';
import IconEuEcological from '@public/icons/colorIcons/icon-eu-ecological.svg';
import IconFrozen from '@public/icons/regularIcons/icon-frozen.svg';
import { ProductLabelsType } from '@constants/productLabels';

const useProductLabelIcons = () => {
  const iconLookup: Record<ProductLabelsType, IconType> = {
    age_restricted_15: IconAge15,
    age_restricted_18: IconAge18,
    allergy_approved: IconAllergyApproved,
    asc_fish: IconAscFish,
    crossed_ax: IconCrossedAx,
    ecological: IconEcological,
    environmental_choice: IconEnvironmentalChoice,
    eu_ecological: IconEuEcological,
    eu_flower: IconEuFlower,
    fairtrade: IconFairtrade,
    from_sweden: IconFromSweden,
    frozen: IconFrozen,
    glutenfree: IconGlutenfree,
    keyhole: IconKeyhole,
    krav: IconKrav,
    laktosfree: IconLaktosfree,
    meat_from_sweden: IconMeatFromSweden,
    medicine_label: IconMedicineLabel,
    msc_fish: IconMscFish,
    nicotine_medical: IconNicotineMedical,
    swan: IconSwan,
    swedish_bird: IconSwedishBird,
    swedish_flag: IconSwedishFlag,
    swedish_meat: IconSwedishMeat,
    fairtrade_cacao: IconFairtradeCacao,
    milk_from_sweden: IconMilkFromSweden,
    rainforest_alliance: IconRainforestAlliance,
    soil_association_organic: IconSoilAssociationOrganic,
    gots: IconGots,
  };
  return iconLookup;
};

export default useProductLabelIcons;
