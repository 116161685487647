import Heading from '@components/atoms/Heading';
import Icon from '@components/atoms/Icon';
import Paragraph from '@components/atoms/Paragraph';
import { MenuLink } from '@components/molecules/AccountMenuItem/AccountMenuItem';
import Panel from '@components/molecules/Panel';
import {
  AccountTakeoverMenuItem,
  HeaderSlotCloseButton,
  HeaderSlotContent,
  StyledHr,
} from '@components/organisms/Header/AccountTakeoverMenu/AccountTakeoverMenu.styles';
import IconClose from '@public/icons/regularIcons/icon-close.svg';
import useTranslation from 'next-translate/useTranslation';

interface AccountTakeoverMenuProps {
  links: MenuLink[];
  isOpen: boolean;
  onLinkClicked: () => void;
}
const AccountTakeoverMenu = ({ links, isOpen, onLinkClicked }: AccountTakeoverMenuProps) => {
  const { t } = useTranslation('common');
  const headerSlot = (
    <HeaderSlotContent>
      <Heading type="h2" size="small">
        {t('ariaLabels->myPages')}
      </Heading>
      <HeaderSlotCloseButton onClick={onLinkClicked} aria-label={t('defaultActions->close')}>
        <Icon icon={IconClose} /> <Paragraph weight="bold">{t('defaultActions->close')}</Paragraph>
      </HeaderSlotCloseButton>
    </HeaderSlotContent>
  );

  return (
    <Panel headerSlot={headerSlot} isOpen={isOpen} closePanel={onLinkClicked} size="small">
      {links.map((link: MenuLink, index: number) => (
        <div key={link.title}>
          {index === links.length - 1 ? <StyledHr /> : null}
          <AccountTakeoverMenuItem key={link.title} link={link} onLinkClicked={onLinkClicked} />
        </div>
      ))}
    </Panel>
  );
};

export default AccountTakeoverMenu;
