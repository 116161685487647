import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import { ProductSkeletonSection } from '@components/molecules/Product/ProductSkeleton/Product.skeleton';
import { IntersectionObservedCartConnectedProduct } from '@components/organisms/CartConnectedProduct';
import { RefObject } from 'react';
import useTrackedProducts from './useTrackedProducts';

export type Props = {
  products: Product[];
  eventListName: string;
  isLoading?: boolean;
  containerRef?: HTMLDivElement | null;
  disableMixAndMatch?: boolean;
  extendedEventListName?: string;
  skeletonCount?: number;
  loadMore?: () => void;
};

const ProductsWithTracking = ({
  products,
  eventListName,
  isLoading,
  containerRef,
  disableMixAndMatch = false,
  extendedEventListName,
  skeletonCount = 7,
}: Props) => {
  const { onProductShown, trackableRefs } = useTrackedProducts({ eventListName, extendedEventListName, products });

  if (isLoading) return <ProductSkeletonSection count={skeletonCount} />;

  return (
    <>
      {products.map((product, i) => (
        <IntersectionObservedCartConnectedProduct
          product={product}
          intersectionCallback={() => onProductShown(product, i + 1)}
          ref={trackableRefs.current[i] as unknown as RefObject<any>}
          key={`${product.code}-${product.name}-${i}`} // eslint-disable-line react/no-array-index-key
          eventListName={eventListName}
          listPos={i}
          containerRef={containerRef}
          disableMixAndMatch={disableMixAndMatch}
          extendedEventListName={extendedEventListName}
        />
      ))}
    </>
  );
};

export default ProductsWithTracking;
