import Icon from '@components/atoms/Icon';
import CartNotifications from '@components/organisms/Header/Cart/CartNotifications';
import LINKS from '@constants/links';
import { roundDecimals } from '@helpers/numberHelpers';
import { useAppSelector } from '@hooks/redux';
import CartIcon from '@public/icons/regularIcons/icon-cart.svg';
import { selectTotalProductAmount } from '@selectors/cart';
import useTranslation from 'next-translate/useTranslation';
import NotificationButton from '../../../../atoms/HeaderButton/NotificationButton';
import MiniCart from '../MiniCart';

const CartButton = ({ showTotalProductAmount }: { showTotalProductAmount: boolean }) => {
  const { t } = useTranslation('common');

  const totalProductAmountInCart = useAppSelector(selectTotalProductAmount);
  const totalProductAmount = roundDecimals(totalProductAmountInCart);

  if (showTotalProductAmount) {
    return <MiniCart totalProductAmount={totalProductAmount} />;
  }

  return (
    <>
      <NotificationButton
        aria-expanded={false}
        aria-label={t('ariaLabels->cart')}
        badgeValue={totalProductAmount}
        borderRadius={16}
        href={LINKS.CART}
      >
        <Icon color="white" icon={CartIcon} />
      </NotificationButton>
      <CartNotifications />
    </>
  );
};
export default CartButton;
