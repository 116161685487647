import { SortData } from '@api/generated/storefront';
import Dropdown from '@components/atoms/Dropdown';
import { Option } from '@components/atoms/Dropdown/Dropdown';
import useAppRouter from '@hooks/useAppRouter';
import { useEffect, useState } from 'react';
import { productFiltersTracker } from '../../../trackers/productFiltersTracker';

interface DropdownProps {
  sorts: SortData[];
  pageName: string;
}

const ProductSortingDropdown = ({ sorts, pageName }: DropdownProps) => {
  const router = useAppRouter();
  const { query, pathname, push } = router;
  const [activeItem, setActiveItem] = useState<any | null>(sorts[0]);

  const handleOptionClick = (sort: SortData) => {
    push(
      {
        pathname,
        query: { ...query, sort: sort.code },
      },
      undefined,
      { scroll: false }
    );
    productFiltersTracker.sortingTracker(pageName, sort.name);
  };

  const updateActiveItem = (option: Option) => {
    setActiveItem(option);
    handleOptionClick(option);
  };

  useEffect(() => {
    if (!!query.sort && !!sorts.length && activeItem?.code !== query.sort) {
      const selectedSortFromQuery = sorts.find((sortItem) => sortItem.code === query.sort);
      setActiveItem(selectedSortFromQuery || sorts[0]);
    }
  }, []);

  return (
    <Dropdown
      id="product-sorting-dropdown"
      options={sorts}
      selectedName={activeItem?.name}
      onOptionClick={updateActiveItem}
    />
  );
};

export default ProductSortingDropdown;
