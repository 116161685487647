import {
  CategoryHeroWrapper,
  CategoryHeroTextSection,
  CategoryHeroImageSection,
  ExpandableTextSection,
  ExpandSectionIconWrapper,
  StyledParagraph,
  StyledHeading,
} from './CategoryHero.styles';
import { useState } from 'react';
import DownIcon from '@public/icons/regularIcons/icon-chevron-right.svg';
import useAppRouter from '@hooks/useAppRouter';
import useCategory from '../CategoryProductsComponent/useCategory';
import { flattenString } from '@helpers/string';
import IconButton from '@components/molecules/IconButton';

const CategoryHero = ({ component }: { component: HemkopCategoryHeroComponent; categoryName?: string }) => {
  const [rotate, setRotate] = useState(false);
  const { backgroundColor, image, preambleText = '' } = component;
  const shouldRenderReadMoreIcon = preambleText.length > 100;
  const router = useAppRouter();
  const { query } = router;

  const { categoryInfo } = useCategory({
    categoryPath: flattenString(query?.categories),
  });

  const imageUrl = image ? image.desktop.url : null;
  const imageAltText = image ? image.desktop.altText : categoryInfo?.name;

  const toggleReadMore = () => {
    setRotate(!rotate);
  };

  return (
    <CategoryHeroWrapper>
      <CategoryHeroTextSection
        $backgroundColor={backgroundColor}
        data-testid="text-section"
        $hasShowMoreIcon={shouldRenderReadMoreIcon}
      >
        <StyledHeading size="large" type="h1">
          {categoryInfo?.name}
        </StyledHeading>
        {preambleText && (
          <>
            <ExpandableTextSection>
              <StyledParagraph $showMore={rotate}>{preambleText}</StyledParagraph>
            </ExpandableTextSection>
            {shouldRenderReadMoreIcon && (
              <ExpandSectionIconWrapper $rotate={rotate} data-testid="expand-icon">
                <IconButton icon={DownIcon} onClick={toggleReadMore} iconSize={16} />
              </ExpandSectionIconWrapper>
            )}
          </>
        )}
      </CategoryHeroTextSection>
      {imageUrl && (
        <CategoryHeroImageSection data-testid="hero-image" $image={imageUrl} role="img" aria-label={imageAltText} />
      )}
    </CategoryHeroWrapper>
  );
};

export default CategoryHero;
