import { isProductLabel } from '@constants/productLabels';
import filterUnSupportedProductLabels from '@helpers/filterUnSupportedProductLabels/filterUnSupportedProductLabels';
import useTranslation from 'next-translate/useTranslation';
import React from 'react';
import useProductLabelIcons from '../hooks/useProductLabelIcons';
import { ProductLabel, ProductLabelsContainer } from './ProductLabels.styles';

interface ProductLabelsProps {
  horizontal?: boolean;
  labels: string[];
  isDrugProduct: boolean;
  nicotineMedicalProduct: boolean;
  isAlwaysGoodPrice: boolean;
}

export const MAX_LABELS_TO_RENDER = 3;

const ProductLabels = ({
  labels,
  horizontal = false,
  isDrugProduct,
  nicotineMedicalProduct,
  isAlwaysGoodPrice,
}: ProductLabelsProps) => {
  const { t } = useTranslation('product');

  if (isDrugProduct && !nicotineMedicalProduct) {
    labels = ['medicine_label', ...labels];
  }

  const labelsToRender = filterUnSupportedProductLabels(labels).slice(0, MAX_LABELS_TO_RENDER);
  const iconLookup = useProductLabelIcons();

  return (
    <ProductLabelsContainer>
      {labelsToRender &&
        labelsToRender.map((label) => (
          <React.Fragment key={label}>
            {isProductLabel(label) && iconLookup[label] && (
              <ProductLabel
                icon={iconLookup[label]}
                color="none"
                mouseoverText={t(`product:label->${label}`)}
                data-testid={label}
                horizontal={horizontal}
                size={16}
              />
            )}
          </React.Fragment>
        ))}
    </ProductLabelsContainer>
  );
};

export default ProductLabels;
