function formatString(
  formatFunc: (s?: string, t?: string, p?: string) => string,
  streetAddress?: string,
  postalCode?: string,
  town?: string
) {
  return !!streetAddress && !!postalCode && !!town ? formatFunc(streetAddress, postalCode, town) : '';
}

const postalCodeFormatter = (postalCode?: string, town?: string) => `${postalCode} ${town}`;

const defaultFormatter = (streetAddress?: string, postalCode?: string, town?: string) =>
  `${streetAddress}, ${postalCodeFormatter(postalCode, town)}`;

const plainFormatter = (streetAddress?: string, postalCode?: string, town?: string) =>
  `${streetAddress} ${postalCodeFormatter(postalCode, town)}`;

const formatAddress = (streetAddress?: string, postalCode?: string, town?: string) => {
  return formatString(defaultFormatter, streetAddress, postalCode, town);
};

const formatAddressPlain = (streetAddress?: string, postalCode?: string, town?: string) => {
  return formatString(plainFormatter, streetAddress, postalCode, town);
};

const formatPostalCode = (postalCode?: string, town?: string) => {
  return !!postalCode && !!town ? postalCodeFormatter(postalCode, town) : '';
};

export { formatAddress, formatAddressPlain, formatPostalCode };
