import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

// TODO: globally defined?
const borderRadius = 24;

export const BannerListWrapper = styled.div`
  position: relative;
  border-radius: ${rem(borderRadius)};
  min-width: 0;
  height: ${rem(282)};
  ${media.tabletPortrait} {
    height: ${rem(378)};
  }
  &:hover {
    a {
      color: unset;
    }
  }
  background: ${({ theme }) => theme.colors.colorWhite};
  a {
    margin: 0;
  }
`;

interface ImageUrlProps {
  $imgUrl: string;
}

export const ItemImage = styled.div<ImageUrlProps>`
  width: 100%;
  border-radius: ${rem(borderRadius)};
  background-image: ${({ $imgUrl }) => `url(${$imgUrl})`};
  background-size: cover;
  height: ${rem(282)};
  ${media.tabletPortrait} {
    height: ${rem(378)};
  }
`;

export const ItemImageSmall = styled.div<ImageUrlProps>`
  border-top-left-radius: ${rem(borderRadius)};
  border-top-right-radius: ${rem(borderRadius)};
  background-image: ${({ $imgUrl }) => `url(${$imgUrl})`};
  background-size: cover;
  width: 100%;
  height: ${rem(133)};
  ${media.tabletPortrait} {
    height: ${rem(220)};
  }
`;

export const BannerLink = styled.div`
  padding: ${rem(15)} 0;
  text-decoration: underline;
  margin-top: ${rem(30)};
  ${media.tabletPortrait} {
    margin: 0;
  }
`;

interface ThemeBannerProps {
  $themeBanner?: boolean;
}

export const BannerTitle = styled.div<ThemeBannerProps>`
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  margin-bottom: ${rem(5)};
  ${({ $themeBanner }) =>
    !$themeBanner &&
    css`
      margin: 0;
      height: ${rem(34)};
      ${media.tabletPortrait} {
        height: ${rem(38)};
      }
    `}
`;

export const BannerThemeTextWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: ${rem(135)};
  ${media.tabletPortrait} {
    height: ${rem(199)};
  }
`;

export const BannerTextWrapper = styled.div`
  width: 100%;
  height: ${rem(156)};
  @media (hover: hover) {
    &:hover {
      ${BannerLink} {
        opacity: 0.5;
      }
      a {
        color: ${({ theme }) => theme.colors.colorTextPrimary};
      }
    }
  }
`;

export const BannerTextWrapperInner = styled.div<ThemeBannerProps>`
  padding: ${rem(5)} ${rem(15)} ${rem(15)};
  height: 100%;
  ${media.tabletPortrait} {
    padding: ${({ $themeBanner }) => ($themeBanner ? `${rem(55)} ${rem(15)} ${rem(15)}` : `${rem(15)}`)};
  }
`;

export const BannerDescription = styled.div`
  color: ${({ theme }) => theme.colors.colorWhite};
  margin: 0;
`;

export const RecipieBadgeWrapper = styled.div`
  position: absolute;
  width: ${rem(50)};
  height: ${rem(50)};
  top: ${rem(5)};
  right: ${rem(5)};

  ${media.tabletPortrait} {
    width: ${rem(65)};
    height: ${rem(65)};
    top: ${rem(10)};
    right: ${rem(10)};
  }

  svg {
    width: ${rem(50)};
    height: ${rem(50)};
    ${media.tabletPortrait} {
      width: ${rem(65)};
      height: ${rem(65)};
    }
    path {
      color: transparent;
    }
    .st2 path {
      color: ${({ theme }) => theme.colors.colorWhite};
    }
  }
`;
