export const splitEventListName = (str: string) => {
  const [splitListName] = str.split('|').slice(1);
  const splitListNameClean = splitListName.replace(/\s/, '');
  return splitListNameClean;
};

export const linkTrackingName = (path: string) => {
  if (path.startsWith('/')) {
    const internalPageDir = path.split('/')[1];
    switch (internalPageDir) {
      case 'artikel':
        return 'article';
      case 'recept':
        return 'recipe';
      case 'press':
        return 'press';
      default:
        return 'unknown page';
    }
  } else {
    return path;
  }
};
