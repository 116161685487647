import Pill from '@components/atoms/Pill';
import { Key, ReactNode } from 'react';
import { TablistWrapper } from './Tablist.styles';

export type Tab = {
  content: ReactNode;
  onClick: () => void;
  selected: boolean;
  key: Key;
};
interface Props {
  list: Tab[];
  children?: ReactNode;
}

const Tablist = ({ list, children = null }: Props) => {
  return (
    <TablistWrapper role="tablist">
      {list.map(({ selected, onClick, content, key }) => (
        <Pill key={key} selected={selected} onClick={onClick}>
          {content}
        </Pill>
      ))}
      {children}
    </TablistWrapper>
  );
};

export default Tablist;
