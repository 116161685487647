import Icon from '@components/atoms/Icon';
import LinkArea from '@components/atoms/__DEPRECATED__/LinkArea';
import media from '@constants/responsive';
import { getThemeColor } from '@styles/utils';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

interface BannerNoImageProps {
  $background?: string;
}

interface ExternalHtmlProps {
  $fontColor?: string;
  $hasImage: boolean;
}

interface ContentProps {
  $align: 'left' | 'center';
}

interface IBannerProps {
  $mobileHeight: number;
  $tabletHeight: number;
  $desktopHeight: number;
}

export const Banner = styled.div<IBannerProps>`
  flex: 0 0 100%;
  position: relative;
  width: 100%;
  height: ${({ $mobileHeight }) => rem($mobileHeight)};
  overflow: hidden;
  display: flex;
  align-items: center;
  border-radius: ${rem(24)};
  ${media.tabletPortrait} {
    height: ${({ $tabletHeight }) => rem($tabletHeight)};
    border-radius: ${rem(32)};
  }
  ${media.desktop} {
    height: ${({ $desktopHeight }) => rem($desktopHeight)};
  }
`;
export const IconWrapper = styled(Icon)`
  margin: 0 0 0 auto;
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
`;

export const BackgroundImage = styled.div`
  width: 100%;
  height: 100%;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center 0;
    ${media.tabletPortrait} {
      object-position: 0 0;
    }
  }
`;

export const Content = styled.div<ContentProps>`
  position: relative;
  z-index: 10;
  width: 100%;
  text-align: center;
  padding: ${rem(30)} ${rem(25)};

  a {
    display: inline-flex;
    margin: ${rem(20)} 0 0 0;
    border: none;
    height: ${rem(40)};
  }

  ${(props) =>
    props.$align === 'left'
      ? css`
          ${media.tabletPortrait} {
            text-align: left;
            padding: ${rem(30)} ${rem(25)};
            margin: ${rem(25.5)} 0;
          }

          ${media.desktop} {
            padding: ${rem(30)} ${rem(85)};
          }
        `
      : css``}

  ${(props) =>
    props.$align === 'center'
      ? css`
          display: flex;
          flex-direction: column;
          align-items: center;
        `
      : css``}
`;

export const ExternalHtml = styled.div<ExternalHtmlProps>`
  color: ${(props) => (props.$fontColor ? props.$fontColor : ({ theme }) => theme.colors.colorPrimary)};
  ${({ theme }) => theme.globalClasses.cmsContentText};

  ${media.tabletPortrait} {
    ${({ $hasImage }) =>
      $hasImage
        ? css`
            max-width: ${rem(560)};
          `
        : css``}
  }

  h1,
  h2,
  h3,
  h4,
  h6 {
    margin: 0;
  }

  h1 {
    font-size: ${rem(28)};
    line-height: ${rem(32)};

    ${media.tabletPortrait} {
      line-height: ${rem(60)};
      font-size: ${rem(55)};
    }
  }

  h5 {
    font-size: ${rem(10)};
    font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
    line-height: ${rem(14)};
    margin: ${rem(17)} 0;
  }

  p {
    line-height: 1.33;
    font-size: ${(props) => (props.$hasImage ? rem(18) : rem(12))};
    ${({ $hasImage }) =>
      $hasImage &&
      css`
        margin: ${rem(15)} 0 0 0;
      `}

    display:  ${({ $hasImage }) => ($hasImage ? `none` : `inline-block`)};

    ${media.tabletPortrait} {
      display: inline-block;
    }
  }
`;

export const BannerNoImage = styled.div<BannerNoImageProps>`
  padding: ${rem(10)};
  min-width: 100%;
  background-color: ${({ $background, theme }) =>
    $background ? getThemeColor($background, theme) : theme.colors.colorWhite};
  &,
  & p {
    width: 100%;
  }
`;

export const FullWidthLinkArea = styled(LinkArea)`
  min-width: 100%;
`;
