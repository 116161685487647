import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: fixed;
  z-index: 999;
  height: 100%;
  flex-direction: column;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.colorWhite};
  padding: 0;
  margin: 0;
`;
