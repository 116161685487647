import TagManager from 'react-gtm-module';

export const trackMyPagesMenuOpen = () => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'secondary_menu',
      action: 'menu_opened',
      label: '',
      value: 0,
    },
  });
};

export const removeWhenNextDefined = () => false;
