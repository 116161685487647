import { rem } from 'polished';
import styled, { css } from 'styled-components';
import LinkArea from '../LinkArea';
import { StyledLinkProps } from './Link';

const DisabledLink = css`
  display: inline;
  pointer-events: none;
  cursor: default;
  line-height: 1.2;
  opacity: 0.5;
  & > * {
    opacity: 0.5;
  }
`;

export const StyledLinkArea = styled(LinkArea)<StyledLinkProps>`
  display: inline;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.colorTextPrimary};
  align-items: center;
  &:visited {
    color: ${({ theme }) => theme.colors.colorTextPrimary};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.colorTextSecondary};
  }
  ${(props) => props.margin && `margin: 0 ${rem(5)};`}
  ${(props) => props.block && `display: block;`}
  ${(props) => (props.underlined ? `text-decoration: underline;` : `text-decoration: none;`)}
  
  ${(props) =>
    props.variant === 'light' &&
    `
    color: ${props.theme.colors.colorWhite};
    &:visited {
      color: ${props.theme.colors.colorWhite};
    }
    &:hover{
      opacity: 0.5;
      color: ${props.theme.colors.colorWhite};
    }
  `}

  ${(props) =>
    props.variant === 'primary' &&
    `
    color: ${props.theme.colors.colorPrimary};
    &:visited {
      color: ${props.theme.colors.colorPrimary};
    }
    &:hover{
      color: ${props.theme.colors.colorTextSecondary};
    }
  `}
  ${(props) => props.disabled && DisabledLink}
`;
