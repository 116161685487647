import CmsComponent from '@components/cmsComponents/CmsComponent';
import ColumnComponentContext from '@context/ColumnComponentContext';
import useCmsComponents from '@hooks/useCmsComponentGroup';
import { useMemo } from 'react';
import { ComponentWrapper, Container } from './MultiColumnContainerComponent.styles';
import MultiColumnSkeletons from './MultiColumnSkeletons';

interface ComponentProps {
  parsedIds: string;
  slotName?: string;
  slotPosition?: number;
  isGroupedComponent: boolean;
  cmsComponentTypeCodes: string;
}

const Component = ({ parsedIds, slotName, slotPosition, isGroupedComponent, cmsComponentTypeCodes }: ComponentProps) => {
  const { data: components = [], isLoading } = useCmsComponents(JSON.stringify(parsedIds));
  const firstComponent = components?.[0];

  const skeletonTemplates = useMemo(() => JSON.parse(cmsComponentTypeCodes), [cmsComponentTypeCodes]);

  if (isLoading) {
    return skeletonTemplates?.map((template: string, index: number) => (
      <MultiColumnSkeletons key={`${index}-${template}`} template={template} />
    ));
  }

  if (!firstComponent) {
    return null;
  }
  return (
    <CmsComponent
      component={firstComponent}
      slotName={slotName}
      slotPosition={slotPosition}
      excludeWrapper={isGroupedComponent}
    />
  );
};
interface ColumnComponentProps {
  column: ColumnContainerData;
  slotName?: string;
  slotPosition?: number;
}

export const ColumnComponent = ({ column, slotName, slotPosition }: ColumnComponentProps) => {
  const idArray: string[] = JSON.parse(column?.cmsComponents || '[]');
  const isMultiRowComponent = !!idArray?.length && idArray.length > 1 && column.widthPercent === '100';

  return (
    <ColumnComponentContext.Provider value={{ isMultiRowComponent }}>
      <ComponentWrapper
        $withPadding={isMultiRowComponent}
        $width={parseInt(column.widthPercent, 10)}
        data-testid="column-container"
      >
        {idArray.map((componentId) => {
          return (
            <Component
              key={componentId}
              parsedIds={componentId}
              slotName={slotName}
              slotPosition={slotPosition}
              isGroupedComponent={isMultiRowComponent}
              cmsComponentTypeCodes={column.cmsComponentTypeCodes}
            />
          );
        })}
      </ComponentWrapper>
    </ColumnComponentContext.Provider>
  );
};

interface CmsComponentProps {
  component: CmsComponent & MultiColumnContainerComponentType;
  slotName?: string;
  slotPosition?: number;
}

const MultiColumnContainerComponent = ({ component, slotName, slotPosition }: CmsComponentProps) => {
  const { columnContainerData } = component;
  const columns =
    !columnContainerData || Array.isArray(columnContainerData) ? columnContainerData : [columnContainerData];

  return (
    <Container data-testid="MultiColumnContainerComponent">
      {columns?.map((column) => (
        <ColumnComponent key={column.uuid} column={column} slotName={slotName} slotPosition={slotPosition} />
      ))}
    </Container>
  );
};

export default MultiColumnContainerComponent;
