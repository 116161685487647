import Link from '@components/atoms/__DEPRECATED__/Link';
import { rem } from 'polished';
import styled from 'styled-components';

export const ListItem = styled.li<{ $isActive: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.colorSilver};
  background: ${(props) => (props.$isActive ? props.theme.colors.colorHighlight : 'none')};
`;

interface ColorAndIndentationProps {
  $color: string;
  $indentation: number;
}

export const LinkButton = styled.div<ColorAndIndentationProps>`
  outline: none;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  align-items: center;
  justify-content: space-between;
  height: ${rem(50)};
  color: ${(props) => (props.$color ? props.$color : props.theme.colors.colorTextPrimary)};
  border: none;
  background: none;
  padding: ${(props) => `0 ${rem(5)} 0 ${rem(props.$indentation)}`};
`;

export const AccordionItemLink = styled(Link)<ColorAndIndentationProps>`
  display: flex;
  height: ${rem(50)};
  color: ${(props) => (props.$color ? props.$color : props.theme.colors.colorTextPrimary)};
  padding: ${(props) => `0 ${rem(5)} 0 ${rem(props.$indentation)}`};
`;

export const FlexBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LinkIcon = styled.img`
  width: ${rem(24)};
  height: ${rem(24)};
`;
