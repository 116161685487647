import Icon from '@components/atoms/Icon';
import IconArrowRight from '@public/icons/regularIcons/deprecated-icon-arrow-right.svg';
import React, { ForwardedRef, useState } from 'react';
import { AccordionText, HeaderWrapper, IconWrapper } from './ProductDetailsAccordion.styles';

export interface AccordionSectionHeaderProps {
  active: boolean;
  title: string;
  disabled?: boolean;
  lastClosedTab: boolean;
  'data-testid'?: string;
}

const AccordionSectionHeader = React.forwardRef(
  (
    { active, title, disabled = false, lastClosedTab, 'data-testid': dataTestId }: AccordionSectionHeaderProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const [isClosed, setIsClosed] = useState(false);
    return (
      <HeaderWrapper
        data-testid={dataTestId}
        $disabled={disabled}
        $active={active}
        ref={ref}
        onClick={() => {
          if (active) {
            setIsClosed(!isClosed);
          }
        }}
      >
        <AccordionText>{title}</AccordionText>
        <IconWrapper $active={active} $disabled={disabled} $isClosed={lastClosedTab}>
          <Icon color="black" icon={IconArrowRight} data-testid="icon-arrow-right" />
        </IconWrapper>
      </HeaderWrapper>
    );
  }
);
AccordionSectionHeader.displayName = 'AccordionSectionHeader';

export default AccordionSectionHeader;
