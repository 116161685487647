import { AxfoodProductDetailsViewModel, NutrientFactData } from '@api/generated/storefront';
import Table from '@components/atoms/Table';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import { capitalizeFirst } from '@helpers/string';
import Heading from 'components/atoms/__DEPRECATED__/Heading';
import useTranslation from 'next-translate/useTranslation';
import { InfoBlock, ProductInfoWrapper } from './ProductInformationListing.styles';

interface ProductInformationListingProps {
  product: AxfoodProductDetailsViewModel;
}

// mapped type with required typeCode field
type RequiredNutrientFactData = NutrientFactData & Required<Pick<NutrientFactData, 'typeCode'>>;

// type guard
const isRequiredNutrientFactData = (item: NutrientFactData): item is RequiredNutrientFactData => !!item.typeCode;

const ProductInformationListing = ({ product }: ProductInformationListingProps) => {
  const { t } = useTranslation('productDetails');
  const { animalData, nutritionsFactList } = product;

  const rowData = nutritionsFactList.length
    ? product.nutritionsFactList.filter(isRequiredNutrientFactData).map((item) => ({
        data: [
          { key: 'typeCode', value: item.typeCode },
          { key: 'value', value: `${item.measurementPrecisionCode ?? ''} ${item.value ?? ''} ${item.unitCode ?? ''}` },
        ],
      }))
    : [];

  return (
    <ProductInfoWrapper>
      {nutritionsFactList.length > 0 && !animalData && (
        <Table
          fullWidth
          rows={rowData}
          columns={[
            {
              id: 'nutritionDescription',
              label: `${t('productDetails->nutritionDescription')} ${product.nutritionDescription}`,
              colSpan: 2,
            },
          ]}
        />
      )}
      {animalData && (
        <>
          {animalData?.typeCode && animalData?.targetedConsumptionBy && (
            <Heading type="h4" color="red">
              {`${capitalizeFirst(animalData.typeCode)} för ${animalData.targetedConsumptionBy}`}
            </Heading>
          )}
          {animalData?.compositionStatement && (
            <InfoBlock>
              <Paragraph size="body-text" bold="bold">
                {t('productDetails->animalData->compositionStatement')}
              </Paragraph>
              <Paragraph size="body-text" data-testid="composition-statement">
                {animalData.compositionStatement}
              </Paragraph>
            </InfoBlock>
          )}
          {animalData?.constituentStatement && (
            <InfoBlock>
              <Paragraph size="body-text" bold="bold">
                {t('productDetails->animalData->constituentStatement')}
              </Paragraph>
              <Paragraph size="body-text" data-testid="constituent-statement">
                {animalData.constituentStatement}
              </Paragraph>
            </InfoBlock>
          )}
          {animalData?.additiveStatement && (
            <InfoBlock>
              <Paragraph size="body-text" bold="bold">
                {t('productDetails->animalData->additiveStatement')}
              </Paragraph>
              <Paragraph size="body-text" data-testid="additive-statement">
                {animalData.additiveStatement}
              </Paragraph>
            </InfoBlock>
          )}
        </>
      )}
    </ProductInfoWrapper>
  );
};

export default ProductInformationListing;
