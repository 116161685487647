import { BaseVectorContainer } from '@features/weeklyFlyer/styles';
import dynamic from 'next/dynamic';
import { memo } from 'react';

//dont include in main bundle, for performance
const DynamicFlyerVector = dynamic(() => import('@features/weeklyFlyer/components/FlyerBannerVector/FlyerVector'), {
  ssr: false,
});

interface Props {
  className?: string;
}
const FlyerBannerVector = ({ className }: Props) => (
  <BaseVectorContainer className={className}>
    <DynamicFlyerVector />
  </BaseVectorContainer>
);

export default memo(FlyerBannerVector);
