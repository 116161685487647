import styled from 'styled-components';
import { rem } from 'polished';

export const StyledTable = styled.table<{ $fullWidth?: boolean }>`
  table-layout: fixed;
  border-collapse: collapse;
  line-height: normal;
  ${({ $fullWidth }) => $fullWidth && `width: 100%;`}
`;

export const TableCell = styled.td`
  padding: ${rem(8)};
  border-bottom: 2px solid ${({ theme }) => theme.colors.colorPampas};
`;

export const TableHeadCell = styled.th.attrs((props) => ({
  colSpan: props.colSpan || 1,
}))`
  text-align: left;
  padding: ${rem(8)};
`;
