import { OrderDataV2Response } from '@api/generated/storefront';
import { isHomeDelivery, isPickUpInStore } from '@helpers/delivery';
import { validPriceOrFallback } from '@helpers/money';
import { OrderProps } from './OrderSummary';
import { reachedFreeServiceAmount } from './utils';

const mapOrderDataToOrderSummary = (orderData: OrderDataV2Response): OrderProps => {
  const {
    subtotalWithoutDiscounts,
    subTotalWithDiscountsAndVouchers,
    reservedAmount,
    totalDepositSum,
    orderReference,
    totalGeneralDiscount,
    loyaltyDiscount,
    appliedVouchersData,
    taxes,
    subTotalPrice,
    bufferedPercentage,
    bufferedAmount,
    pickAndPackCost,
    slotPickAndPackCost,
    slot,
    deliveryModeCode,
    deliveryOrPickUpOriginalFee,
    freeShippingVoucher,
    freeDeliveryOrPickupThresholdApplied,
    oldOrderPrice,
    priceDiff,
    paymentType,
    captured,
    frequentShopperPoints,
    employeeDiscountTotal,
    localAgreementDiscountTotal,
    totalPrice,
  } = orderData;

  return {
    subtotalWithoutDiscounts: validPriceOrFallback(subtotalWithoutDiscounts?.formattedValue),
    subtotalWithDiscountsAndVouchers: validPriceOrFallback(subTotalWithDiscountsAndVouchers?.formattedValue),
    reservedAmount: validPriceOrFallback(reservedAmount?.formattedValue),
    totalDeposit: validPriceOrFallback(totalDepositSum?.formattedValue),
    userIsChangingOrder: !!orderReference,
    totalGeneralDiscount: validPriceOrFallback(totalGeneralDiscount?.formattedValue),
    totalLoyaltyDiscount: validPriceOrFallback(loyaltyDiscount?.formattedValue),
    appliedVouchers: appliedVouchersData || [],
    taxes: taxes || undefined,
    subTotalPrice: validPriceOrFallback(subTotalPrice?.formattedValue),
    bufferedPercent: bufferedPercentage?.includes('%') ? bufferedPercentage : `${bufferedPercentage}%`,
    bufferedAmount: validPriceOrFallback(bufferedAmount?.formattedValue),
    pickAndPackCost: validPriceOrFallback(pickAndPackCost?.formattedValue),
    slotPickAndPackCost: validPriceOrFallback(slotPickAndPackCost?.formattedValue),
    slot,
    slotFreePickingThreshold: validPriceOrFallback(slot?.freePickingThreshold?.formattedValue),
    deliveryModeCode: deliveryModeCode || '',
    orginalPickupOrDeliveryFee: validPriceOrFallback(deliveryOrPickUpOriginalFee?.formattedValue, '0,00 kr'),
    freeShippingVoucher: freeShippingVoucher || undefined,
    orderReference: orderReference || '',
    oldOrderPrice: validPriceOrFallback(oldOrderPrice?.formattedValue),
    priceDiff,
    paymentType: paymentType || '',
    paymentCaptured: captured || false,
    frequentShopperPoints: validPriceOrFallback(frequentShopperPoints?.formattedValue),
    employeeDiscount: validPriceOrFallback(employeeDiscountTotal?.formattedValue),
    localAgreementDiscount: validPriceOrFallback(localAgreementDiscountTotal?.formattedValue),
    totalPrice: validPriceOrFallback(totalPrice?.formattedValue),
    reachedFreeServiceAmount: reachedFreeServiceAmount(subTotalPrice?.value, slot?.freePickingThreshold?.value),
    reachedFreeDeliveryAmount: freeDeliveryOrPickupThresholdApplied || false,
    isPickUpInStore: isPickUpInStore(deliveryModeCode),
    isHomeDelivery: isHomeDelivery(deliveryModeCode),
  };
};

export default mapOrderDataToOrderSummary;
