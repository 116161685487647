import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  left: 0;
  background: ${({ theme }) => theme.colors.colorDesertStorm};
  border: ${rem(1)} solid ${({ theme }) => theme.colors.colorPampas};
  border-bottom-left-radius: ${rem(16)};
  border-bottom-right-radius: ${rem(16)};
  max-height: ${rem(250)};
  overflow: auto;
  z-index: 5;
  width: 100%;
`;

export const List = styled.ul`
  padding: ${rem(8)} ${rem(12)} ${rem(16)} ${rem(12)};
  margin: 0;
  list-style: none;
`;

export const ListItemInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${rem(24)};
`;
export interface LinkItemProps {
  $highlight: boolean;
  $highlightOnHover: boolean;
  $disabled: boolean;
}
export const ListItem = styled.li<LinkItemProps>`
  padding: ${rem(12)} ${rem(8)};

  cursor: pointer;

  &:focus-visible {
    outline: none;
  }
  ${(props) =>
    props.$highlight &&
    css`
      background: ${props.theme.colors.colorLinen};
    `}

  ${(props) =>
    props.$disabled &&
    css`
      background: ${props.theme.colors.colorDesertStorm};
      color: ${props.theme.colors.colorSilver};

      ${ListItemInner} {
        opacity: 0.3;
      }
      &:hover {
        cursor: default;
      }
    `}

  ${(props) =>
    props.$highlightOnHover &&
    css`
      &:hover {
        background: ${props.theme.colors.colorPampas};
      }
    `}
`;

export const SuggestionLink = styled.span`
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  text-decoration: none;
`;

export const HighlightedText = styled.span``;

export const NoResultContainer = styled.div`
  padding: ${rem(15)};
`;
