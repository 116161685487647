import LINKS from '@constants/links';

const checkoutLinks = [LINKS.CHECKOUT, LINKS.CHECKOUT_CONFIRMATION, LINKS.ORDER_CONFIRMATION, LINKS.GUEST_ORDER];

/*
Check if an url pathname is a checkout route (one of the pages in the checkout user journeyu (checkoutLinks))
*/
const pathnameIsCheckoutRoute = (pathname: string) => {
  return checkoutLinks.some((link) => {
    return pathname.includes(link);
  });
};

export default pathnameIsCheckoutRoute;
