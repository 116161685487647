/* eslint-disable react/jsx-props-no-spreading */
import { useState } from 'react';
import { usePopper } from 'react-popper';
import AccountDropdownMenu from '../AccountDropdownMenu';
import { StyledPopperWrapper } from './AccountMenu.styles';
import useAccountMenuItems from './useAccountMenuItems';

export interface AccountMenuProps {
  onClose: () => void;
  onLinkClicked: () => void;
  popperReference: HTMLElement | null;
}

const AccountMenu = ({ onClose, onLinkClicked, popperReference }: AccountMenuProps) => {
  const [popperElement, setPopperElement] = useState(null);

  const { styles, attributes } = usePopper(popperReference, popperElement, {
    placement: 'bottom-start',
  });

  const links = useAccountMenuItems();

  const dropDownMenu = () => {
    return (
      <StyledPopperWrapper ref={setPopperElement as any} style={styles.popper} {...attributes.popper}>
        <AccountDropdownMenu links={links} onLinkClicked={onLinkClicked} />
      </StyledPopperWrapper>
    );
  };

  return <>{dropDownMenu()}</>;
};

export default AccountMenu;
