import { AxfoodCartViewModel } from '@occ/api-client';
import { CartStoreState } from '@reducers/cart';
import { Action, Dispatch } from '@reduxjs/toolkit';
import { RootState } from 'store';
import * as types from './types';

type SetCartHasQueuedUpdate = {
  type: string;
  status: number;
};

type SetCartTotalProductAmountActionType = {
  type: string;
  amount: number;
};
type SetCartActionType = {
  type: string;
  cart: AxfoodCartViewModel;
};

export const QUEUE_STATUSES = {
  IDLE: 0, // no ongoing updates
  QUEUED: 1, // a new update will soon be dispatched to the api
  UPDATING: 2, // an update has been dispatched and is awaiting the response
};

function setCartHasQueuedUpdate(status: number): SetCartHasQueuedUpdate {
  return {
    type: types.SET_CART_UPDATE_QUEUE_STATUS,
    status,
  };
}

function incrementOngoingCartUpdates(): Action {
  return {
    type: types.INCREMENT_ONGOING_CART_UPDATES,
  };
}

function decrementOngoingCartUpdates(): Action {
  return {
    type: types.DECREMENT_ONGOING_CART_UPDATES,
  };
}

function setCartTotalProductAmount(amount: number): SetCartTotalProductAmountActionType {
  return {
    type: types.SET_CART_TOTAL_PRODUCT_AMOUNT,
    amount,
  };
}
function setFixedTotalProductAmount(amount: number): SetCartTotalProductAmountActionType {
  return {
    type: types.SET_FIXED_CART_TOTAL_PRODUCT_AMOUNT,
    amount,
  };
}

function sendAppCartUpdateEventAndSetTotalCartAmount(amount: number): any {
  return (dispatch: Dispatch) => {
    dispatch(setCartTotalProductAmount(amount));
  };
}

function setFixedTotalCartAmount(amount: number): any {
  return (dispatch: Dispatch) => {
    dispatch(setFixedTotalProductAmount(amount));
  };
}

function setCart(cart: AxfoodCartViewModel): SetCartActionType {
  return {
    type: types.SET_CART,
    cart,
  };
}
// Helpers
const changeCartProductsCountBy = (amount: number) => {
  return (dispatch: Dispatch, getState: () => { cart: CartStoreState }) => {
    const { cart } = getState();
    const newCount = (cart.totalProductAmount || 0) + amount;
    dispatch(sendAppCartUpdateEventAndSetTotalCartAmount(newCount));
  };
};

export function reverseCartProducts(cart: AxfoodCartViewModel): AxfoodCartViewModel {
  const sortedCart = {
    ...cart,
  };
  if (cart.products) {
    sortedCart.products = cart.products.reverse();
  }
  return sortedCart;
}

function sortAndSetCart(cart: AxfoodCartViewModel): any {
  return (dispatch: Dispatch) => {
    dispatch(setCart(reverseCartProducts(cart)));
  };
}

/**
 * This thunk is part of the "update cart" chain, which is used when the user, for instance, adds a product to the cart.
 * To battle the problem of the cart/quantity input field updating multiple times within a short timeframe which
 * occurs when a user does multiple simultaneous updates, we do certain flow control checks to see if the
 * user has triggered multiple updates, and if that is the case, we do not update the cart until all
 * updates have finished.
 *
 * @param cart
 */
const setCartDataIfNoQueuedOrOngoingUpdates = (cart: AxfoodCartViewModel) => {
  return (dispatch: Dispatch, getState: () => RootState) => {
    const cartState = getState().cart;
    // will be true if we have more than one "update cart" api calls waiting for a response
    const hasMultipleActiveApiCalls = cartState.ongoingCartUpdates > 1;
    /*
     will be true if a new update has been queued when this function is called, scenario:
       product quantity changed/queuedUpdateStatus set to QUEUED
       -> api call #1 sent/queuedUpdateStatus set to UPDATING
       -> quantity changed/queuedUpdateStatus set to QUEUED
       -> api call #1 returns
       -> this function is called
    */
    const newUpdateIsQueued = cartState.queuedUpdateStatus === QUEUE_STATUSES.QUEUED;

    if (!newUpdateIsQueued && !hasMultipleActiveApiCalls) {
      // if we get here, no new updates have been queued or sent to the api
      dispatch(sendAppCartUpdateEventAndSetTotalCartAmount(cart.totalUnitCount));
      dispatch(setCartHasQueuedUpdate(QUEUE_STATUSES.IDLE));
      dispatch(sortAndSetCart(cart));
    }
    dispatch(decrementOngoingCartUpdates());
  };
};

const incrementOngoingCartUpdatesAndSetCartUpdating = () => {
  return (dispatch: Dispatch) => {
    dispatch(incrementOngoingCartUpdates());
    dispatch(setCartHasQueuedUpdate(QUEUE_STATUSES.UPDATING));
  };
};

const resetCartAfterFailedUpdate = (cartToRestore: AxfoodCartViewModel) => {
  return (dispatch: Dispatch) => {
    dispatch(setCartHasQueuedUpdate(QUEUE_STATUSES.IDLE));
    dispatch(decrementOngoingCartUpdates());
    dispatch(sortAndSetCart(cartToRestore));
    dispatch(sendAppCartUpdateEventAndSetTotalCartAmount(cartToRestore.totalUnitCount));
  };
};

const cartActions = {
  setCart,
  sortAndSetCart,
  setCartHasQueuedUpdate,
  setCartTotalProductAmount,
  changeCartProductsCountBy,
  setCartDataIfNoQueuedOrOngoingUpdates,
  reverseCartProducts,
  incrementOngoingCartUpdates,
  decrementOngoingCartUpdates,
  incrementOngoingCartUpdatesAndSetCartUpdating,
  resetCartAfterFailedUpdate,
  setFixedTotalCartAmount,
};

export default cartActions;
