import TagManager from 'react-gtm-module';

export const pageChange = (message: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: message,
    },
  });
};

export const showGlobalMessage = (message = '') => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'notification',
      action: 'global_notification_shown',
      label: message,
      value: 0,
    },
  });
};

export const closeGlobalMessage = (message = '') => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'notification',
      action: 'global_notification_closed',
      label: message,
      value: 0,
    },
  });
};

export const closeCookieBar = () => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'cookiebar',
      action: 'cookiebar_closed',
      nonInteraction: true,
      label: '',
      value: 0,
    },
  });
};
