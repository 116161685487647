import { CAROUSEL_MIN_PRODUCT_CARD_WIDTH } from '@components/atoms/ProductGrid/ProductGrid.styles';
import { RefObject, useCallback, useRef, useSyncExternalStore } from 'react';

const initialSlideButtonState = JSON.stringify({ slideAtStart: true, slideAtEnd: true });

const hasPropertiesChanged = (previous: any, current: any) => {
  const keys = Object.keys(current);

  if (keys.length !== Object.keys(previous).length) {
    return true;
  }

  return keys.filter((key) => current[key] !== previous[key]).length > 0;
};

const useSlideButtonState = (slideRef: RefObject<HTMLElement>) => {
  const previousRef = useRef<string>(initialSlideButtonState);

  const subscribe = useCallback(
    (callback: () => void) => {
      const slider = slideRef.current;

      slider && slider.addEventListener('scroll', callback);

      return () => {
        slider && slider.removeEventListener('scroll', callback);
      };
    },
    [slideRef]
  );

  const getSnapshot = () => {
    if (slideRef.current) {
      const maxScroll = slideRef.current.scrollWidth - slideRef.current.clientWidth;
      const scrollLeftPosition = slideRef.current.scrollLeft;
      const offset = CAROUSEL_MIN_PRODUCT_CARD_WIDTH;

      const slideAtStart = scrollLeftPosition < offset;
      const slideAtEnd = scrollLeftPosition >= maxScroll - offset;

      const current = { slideAtStart, slideAtEnd };

      if (hasPropertiesChanged(JSON.parse(previousRef.current), current)) {
        // eslint-disable-next-line fp/no-mutation
        previousRef.current = JSON.stringify(current);
      }

      return previousRef.current;
    }
  };
  const getServerSnapshot = () => {
    return initialSlideButtonState;
  };

  const data = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);

  return JSON.parse(data ? data : initialSlideButtonState);
};

const useSlidePagination = (
  singlePagination: boolean,
  containerRef: RefObject<HTMLElement>,
  sliderRef: RefObject<HTMLElement>
) => {
  const previousRef = useRef<number>(0);

  const subscribe = useCallback(
    (callback: () => void) => {
      const slider = containerRef.current;

      slider && slider.addEventListener('scroll', callback);

      return () => {
        slider && slider.removeEventListener('scroll', callback);
      };
    },
    [containerRef]
  );

  const getSnapshot = () => {
    if (containerRef.current) {
      const width = containerRef.current.getBoundingClientRect().width;
      const listItem = sliderRef.current?.firstElementChild;
      const slidePagination = singlePagination && !!listItem ? listItem.getBoundingClientRect().width : width || 0;

      if (previousRef.current !== slidePagination) {
        // eslint-disable-next-line fp/no-mutation
        previousRef.current = slidePagination;
      }

      return previousRef.current;
    }
  };

  const getServerSnapshot = () => {
    return 0;
  };

  const data = useSyncExternalStore(subscribe, getSnapshot, getServerSnapshot);

  return data || 0;
};

const useCarousel = (singlePagination: boolean) => {
  const sliderRef = useRef<HTMLUListElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { slideAtStart, slideAtEnd } = useSlideButtonState(sliderRef);
  const slidePagination = useSlidePagination(singlePagination, containerRef, sliderRef);

  const next = () => {
    if (sliderRef.current) {
      // eslint-disable-next-line fp/no-mutation
      sliderRef.current.scrollLeft += slidePagination;
    }
  };

  const prev = () => {
    if (sliderRef.current) {
      // eslint-disable-next-line fp/no-mutation
      sliderRef.current.scrollLeft -= slidePagination;
    }
  };

  return { slideAtStart, slideAtEnd, next, prev, containerRef, sliderRef };
};

export default useCarousel;
