import { conflictModalTracker } from '@trackers';
import * as types from './types';
import type { AxfoodCartEntryStatusViewModel } from '@occ/api-client';

const setItemsConflictingModal = (items: AxfoodCartEntryStatusViewModel[]) => ({
  type: types.SET_ITEMS_CONFLICTING_MODAL,
  items,
});

const openConflictingModal = (isOpen: boolean) => ({
  type: types.SHOW_CONFLICTING_MODAL,
  isOpen,
});

const setResolveConflictingModal = (
  resolved: ({ resolvedWithConflictingItems }: { resolvedWithConflictingItems: boolean }) => void
) => ({
  type: types.RESOLVE_CONFLICTING_MODAL,
  resolved,
});

const setRejectConflictingModal = (rejected: () => void) => ({
  type: types.REJECT_CONFLICTING_MODAL,
  rejected,
});

const setStateConflictingModal = (state: {
  open: boolean;
  items: Array<AxfoodCartEntryStatusViewModel>;
  resolved: boolean;
  rejected: boolean;
  storeId: string | undefined;
}) => ({
  type: types.RESET_STATE_CONFLICTING_MODAL,
  state,
});

const setConflictReasonTracker = (reasonTracker: conflictModalTracker.ReasonTrackerType) => ({
  type: types.SET_CONLFICT_REASON_TRACKER,
  reasonTracker,
});

const setConflictModalStoreId = (storeId?: string) => ({
  type: types.SET_CONFLICTING_MODAL_STORE_ID,
  storeId,
});

const conflictModalActions = {
  setItemsConflictingModal,
  openConflictingModal,
  setResolveConflictingModal,
  setRejectConflictingModal,
  setStateConflictingModal,
  setConflictReasonTracker,
  setConflictModalStoreId,
};

export default conflictModalActions;
