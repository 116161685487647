import styled, { css } from 'styled-components';
import { rem } from 'polished';
import { Variant } from './Tabs';

interface TabsProps {
  $variant: Variant;
}

export const TabsContainer = styled.div<TabsProps>`
  display: flex;
  justify-content: center;
  ${(props) =>
    props.$variant === 'paged' &&
    css`
      margin-top: ${rem(50)};
      width: 100%;
      border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.colorCararra};
      display: flex;
      justify-content: center;
    `}
`;

export const InnerTabsContainer = styled.div<TabsProps>`
  flex: 1;
  display: flex;
  justify-content: center;
  ${(props) =>
    props.$variant === 'default' &&
    css`
      flex: 1;
      display: flex;
      justify-content: center;
    `}
  ${(props) =>
    props.$variant === 'filled' &&
    css`
      align-items: center;
      align-content: center;
      justify-content: space-between;
      max-width: 100%;
    `}
  ${(props) =>
    props.$variant === 'paged' &&
    css`
      flex: 1;
      max-width: ${rem(650)};
      justify-content: space-between;
    `}
`;
