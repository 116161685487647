import { useContext } from 'react';
import { urlMatchesRouteByReplaceInNativeApp } from '@helpers/link';
import ApplicationContext from '@context/ApplicationContext';

/**
 * either instantiate with args to match, or instantiate without args to use returned function
 * returned function also responsive for match with given as arg
 */

const useRouteByReplaceInNativeApp = (hrefArg?: UrlType, dynamicUrlPrefix?: string, as?: string) => {
  const { isNativeApp } = useContext(ApplicationContext);
  const checkByArg = dynamicUrlPrefix || hrefArg;
  const routeByReplaceInNativeApp = !!checkByArg && isNativeApp && urlMatchesRouteByReplaceInNativeApp(checkByArg);

  const checkRouteByReplaceInNativeApp = (href: UrlType, asArg?: UrlType) =>
    isNativeApp && (urlMatchesRouteByReplaceInNativeApp(href) || urlMatchesRouteByReplaceInNativeApp(asArg || ''));

  return {
    routeByReplaceInNativeApp,
    checkRouteByReplaceInNativeApp,
  };
};

export default useRouteByReplaceInNativeApp;
