import Text from '@components/atoms/Text';
import Carousel from '@components/molecules/Carousel';
import CarouselProductsWithTracking from '@components/organisms/ProductsWithTracking/CarouselProductsWithTracking/CarouselProductsWithTracking';
import React, { ReactNode } from 'react';
import { Product } from '../Product/ProductDataMapper/types';
import {
  CarouselHeader,
  CarouselHeaderWrapper,
  CarouselWrapper,
  HeadingWrapper,
  TextContainer,
} from './ProductsBeamCarousel.styles';

type BaseProps = {
  carouselProducts: Product[];
  eventListName: string;
  children?: ReactNode | null;
  alwaysShowArrowButtons?: boolean;
  noHeaderSidePadding?: boolean;
  border?: boolean;
  isLoading?: boolean;
  headline?: string;
};
interface PropsWithoutHeader {
  title: string;
  headingTextType: 'paragraph' | 'heading';
  headingComponent?: React.ReactNode | undefined;
}

interface PropsWithHeader {
  title?: string;
  headingTextType?: 'paragraph' | 'heading';
  headingComponent: React.ReactNode;
}

declare type ProductsBeamCarouselProps = BaseProps & (PropsWithHeader | PropsWithoutHeader);

const ProductsBeamCarousel = ({
  title,
  carouselProducts,
  eventListName,
  children = null,
  headingTextType,
  noHeaderSidePadding = false,
  border = false,
  isLoading = false,
  headingComponent,
}: ProductsBeamCarouselProps) => {
  return (
    <>
      {!isLoading && (
        <CarouselHeaderWrapper $noHeaderSidePadding={noHeaderSidePadding} data-testid="product-beam-carousel">
          <CarouselHeader $border={border}>
            <TextContainer>
              {headingTextType === 'paragraph' && (
                <Text type="p" size="md" bold="bold">
                  {title}
                </Text>
              )}
              {headingTextType === 'heading' && <HeadingWrapper>{title}</HeadingWrapper>}
              {headingComponent && headingComponent}
            </TextContainer>
          </CarouselHeader>
          {children}
        </CarouselHeaderWrapper>
      )}
      <CarouselWrapper>
        <Carousel>
          <CarouselProductsWithTracking
            products={carouselProducts}
            eventListName={eventListName}
            isLoading={isLoading}
            disableMixAndMatch
          />
        </Carousel>
      </CarouselWrapper>
    </>
  );
};

export default ProductsBeamCarousel;
