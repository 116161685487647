import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 ${rem(8)};
  position: relative;
  display: grid;
  grid-template-columns: ${rem(24)} 1fr ${rem(24)};
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${rem(50)};
  background-color: ${({ theme }) => theme.colors.colorLinen};

  ${media.tabletPortrait} {
    padding: 0 ${rem(8)};
    display: flex;
  }
  ${media.desktop} {
    padding: 0 ${rem(64)};
  }
`;

export const Content = styled.div`
  grid-column: 2;

  ${media.tabletPortrait} {
    grid-column: unset;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${rem(16)};
  }

  ${media.tabletLandscape} {
    gap: ${rem(48)};
  }
`;

export const CloseButton = styled.button`
  appearance: none;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: transparent;
  grid-column: 3;
  cursor: pointer;
  ${media.tabletPortrait} {
    display: none;
  }
`;
