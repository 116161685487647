import { getFeatureFlags } from '@api';
import useSWRImmutable from 'swr/immutable';

export const key = 'axfood/rest/feature';
export const fetcher = () => getFeatureFlags();

const useFeatureFlags = () => {
  const { data: featureFlags, isLoading } = useSWRImmutable(key, fetcher);

  /* when flag 'use.new.mai002.23r2' is NOT enabled  - FE activate personal offers is enabled */
  const ACTIVATE_PERSONAL_OFFERS_ENABLED =
    featureFlags?.find((flag) => flag.name === 'use.new.mai002.23r2')?.enabled === false;

  return {
    ACTIVATE_PERSONAL_OFFERS_ENABLED,
    isLoading,
  };
};
export default useFeatureFlags;
