import { useState } from 'react';

const useTimeout = (onTimeoutSuccess: () => void, onTimeoutCancelled: () => void, ms: number) => {
  const [timer, setTimer] = useState<any>();

  function startTimeout() {
    setTimer(
      setTimeout(() => {
        onTimeoutSuccess();
      }, ms)
    );
  }

  function cancelTimeout() {
    if (timer !== undefined) {
      clearTimeout(timer);
      onTimeoutCancelled();
    }
  }

  return {
    startTimeout,
    cancelTimeout,
  };
};

export default useTimeout;
