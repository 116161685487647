import Skeleton from '@components/atoms/Skeleton';
import { rem } from 'polished';
import styled from 'styled-components';

export const List = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: ${rem(8)};
  text-transform: capitalize;
  padding: ${rem(6)} 0 ${rem(7)};
  margin: 0;
`;

export const SuggestionSkeleton = styled(Skeleton)`
  margin: 0;
`;
