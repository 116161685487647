import { ArrowButtonVariant } from '@components/molecules/ArrowLinkButton';
import { StyledContentComponent } from '@components/molecules/HeroComponent/HeroContentComponent/HeroContentComponent.styles';
import { Color, ContentComponentData, HeadingSize } from '@components/organisms/ContentComponent';
import { trackHeroBannerClick } from '@features/promotions/tracking';

type Props = {
  data: ContentComponentData;
  color: Color;
  headingSize?: HeadingSize;
  buttonVariant?: ArrowButtonVariant;
  isMobile: boolean;
};

const HeroContentComponent = ({ data, color, headingSize = 'xLarge', buttonVariant = 'primary', isMobile }: Props) => {
  const textSize = isMobile ? 'small' : 'large';

  return (
    <StyledContentComponent
      data={data}
      centerContent={isMobile}
      headingSize={headingSize}
      color={color}
      textSize={textSize}
      buttonVariant={buttonVariant}
      onClick={() => trackHeroBannerClick(data.buttonUrl as string, data.buttonText as string)}
    />
  );
};

export default HeroContentComponent;
