import { rem } from 'polished';
import styled from 'styled-components';

interface IPriceStyledProps {
  $position: 'right' | 'left' | 'center';
}

export const PriceStyled = styled.div<IPriceStyledProps>`
  text-align: ${({ $position }) => $position};
`;

export const PriceUnit = styled.span`
  font-family: ${({ theme }) => theme.typography.fontSerif};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  font-size: ${rem(14)};
  margin-left: ${rem(4)};
`;

export const PriceText = styled.p<{ $size: 'large' | 'small'; $hasPromotion: boolean }>`
  font-size: ${({ $size }) => ($size === 'small' ? rem(20) : rem(36))};
  color: ${({ $hasPromotion, theme }) => ($hasPromotion ? theme.colors.colorPrimary : theme.colors.colorTextPrimary)};
  font-family: ${({ theme }) => theme.typography.fontSerif};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  margin: 0;
`;
