import { useEffect, useState } from 'react';

// OneTrust Cookie consent app and script are loaded / injected by using Google Tag Manager.
// We have some data that need to be pushed to window.dataLayer after that OneTrust has loaded.
// Therefore we have this hook to let us know when it is loaded. The Window.OnetrustActiveGroups
// is set by OneTrust on load.

const useOnetrustActiveGroupHelper = () => {
  const [isOnetrustReady, setIsOnetrustReady] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.OnetrustActiveGroups) {
        clearInterval(interval);
        setIsOnetrustReady(true);
      }
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return isOnetrustReady;
};

export default useOnetrustActiveGroupHelper;
