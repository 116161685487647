const PRODUCT_LABELS = {
  age_restricted_15: 'age_restricted_15',
  age_restricted_18: 'age_restricted_18',
  allergy_approved: 'allergy_approved',
  asc_fish: 'asc_fish',
  crossed_ax: 'crossed_ax',
  ecological: 'ecological',
  environmental_choice: 'environmental_choice',
  eu_ecological: 'eu_ecological',
  eu_flower: 'eu_flower',
  fairtrade: 'fairtrade',
  from_sweden: 'from_sweden',
  frozen: 'frozen',
  glutenfree: 'glutenfree',
  keyhole: 'keyhole',
  krav: 'krav',
  laktosfree: 'laktosfree',
  meat_from_sweden: 'meat_from_sweden',
  medicine_label: 'medicine_label',
  msc_fish: 'msc_fish',
  nicotine_medical: 'nicotine_medical',
  swan: 'swan',
  swedish_bird: 'swedish_bird',
  swedish_flag: 'swedish_flag',
  swedish_meat: 'swedish_meat',
  fairtrade_cacao: 'fairtrade_cacao',
  milk_from_sweden: 'milk_from_sweden',
  rainforest_alliance: 'rainforest_alliance',
  soil_association_organic: 'soil_association_organic',
  gots: 'gots',
} as const;

export type ProductLabelsType = typeof PRODUCT_LABELS[keyof typeof PRODUCT_LABELS];

export const isProductLabel = (type: string): type is ProductLabelsType => {
  const labels = Object.values(PRODUCT_LABELS);
  return labels.includes(type as ProductLabelsType);
};

export default PRODUCT_LABELS;
