import Icon from '@components/atoms/Icon/Icon';
import Text from '@components/atoms/Text';
import FooterLink from '@components/cmsComponents/AxfoodFooterComponent/FooterLink';
import { PHARMACY_LINK } from '@components/cmsComponents/AxfoodFooterComponent/externalLinks';
import IconPharmacy from '@public/icons/colorIcons/icon-pharmacy.svg';
import useTranslation from 'next-translate/useTranslation';
import { Contents } from './FooterLegalInfo.styles';

const FooterLegalInfo = ({ companyAddress, rightJustified }: { companyAddress: string; rightJustified?: boolean }) => {
  const { t } = useTranslation('footer');
  return (
    <Contents $rightJustified={rightJustified}>
      <FooterLink linkId={PHARMACY_LINK} aria-label={t('footer->ariaLabels->verifyLegalDrugSales')}>
        <Icon icon={IconPharmacy} size={100} data-testid="icon-pharmacy" />
      </FooterLink>
      <Text type="p" size="md" data-testid="company-address">
        {companyAddress}
      </Text>
    </Contents>
  );
};

export default FooterLegalInfo;
