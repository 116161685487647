import { rem } from 'polished';
import themecolors from './colors';

const shadows = {
  default: `0 ${rem(4)} ${rem(16)} 0 ${themecolors.colorBoxShadow}`,
  dropdown: `0 ${rem(4)} ${rem(4)} 0 ${themecolors.colorBoxShadow};`,
};

export type ThemeShadow = typeof shadows;
export default shadows;
