const excludedKeys = ['filterItems'];

const logToNewRelic = (warning) => {
  try {
    if (warning.i18nKey && excludedKeys.some((str) => warning.i18nKey.includes(str))) {
      return;
    }
    if (typeof window !== 'undefined' && typeof window.newrelic !== 'undefined') {
      const { newrelic } = window;
      const message = `next-translate: missing key: ${JSON.stringify(warning)}`;
      newrelic.noticeError(message);
    }
  } catch (error) {
    // console.error('Failed to log to New Relic:', error);
  }
};

const baseConfig = {
  keySeparator: '->',
  defaultLocale: 'sv',
  locales: ['sv'],
  pages: {
    '*': [
      'addMissingCustomerInformation',
      'axfoodWeeklyFlyerComponent', // TODO: make dynamic namespace
      'bankid',
      'breadcrumbs',
      'common',
      'conflictModal',
      'delivery',
      'energydeclaration',
      'errorPages',
      'footer',
      'formValidation',
      'globalmessage',
      'header',
      'infopane',
      'listbannercomponent',
      'localagreement',
      'login',
      'loyaltynotification',
      'mergeCart',
      'minicart',
      'myaccountmenu',
      'mylists',
      'orderchangebanner',
      'product',
      'productDetails',
      'recipes',
      'removeaccount',
      'slots',
      'toolbar',
      'topmenu',
      'cartpage',
      'vouchers', // TODO: make dynamic namespace
      'sidenav',
      'promotions',
      'cancelOrder', // TODO: make dynamic namespace
    ],
    '/': ['promotions'], // start page
    '/artikel/[...slug]': ['customerservice'], // article page
    '/butik/[id]': ['store', 'oldstore'], // store page
    '/erbjudanden': ['promotions'], // promotions page
    'rgx:^/glomt-losenord/': ['onboarding'], // all forgotten password pages
    '/kassa': ['checkoutpage'], // checkout page
    '/kassa/bekraftelse/[orderId]': ['checkoutpage', 'delivery', 'orderconfirmationpage'], // order confirmation page
    '/mina-sidor/kop': ['orderspage'], // order history page
    // order detail pages
    '/min-order/detalj': ['orderdetailpage', 'checkoutpage'], // anonymous order detail page after rewrites
    '/mina-sidor/kop/detalj': ['orderdetailpage', 'checkoutpage'], // logged in order detail page after rewrites
    '/mina-sidor/mina-listor/[listId]': ['mylists'], // list detail page
    '/mina-sidor/mina-listor/index': ['mylists'], // list overview page
    '/mina-sidor/klubbinfo': ['oldclubinfo', 'mybenefits'], // old club info page
    '/mina-sidor/konto-uppgifter': ['checkoutpage', 'profilepage', 'profilepageb2b'], // b2b profile page
    '/mina-sidor/min-profil': ['profilepage'], // b2c profile page
    '/mina-sidor/mina-formaner': ['mybenefits', 'promotions', 'oldclubinfo'], // my benefits page
    '/mina-sidor/mitt-medlemskap': ['mymembership', 'mybenefits'], // my membership page (new klubbinfo)
    '/o/[deliveryId]': ['orderDeliveryStatus'], // follow the car page
    'rgx:^/registrera/': ['onboarding'], // all registration pages
    '/sortiment/[...categories]': ['category', 'categorypage', 'productFiltering'], // category page
    '/beta/sortiment/[...categories]': ['category', 'categorypage', 'productFiltering'], // beta category page
    '/app-faq': ['customerservice'], // faq page (only in app)
    '/app-kontakta-oss': ['customerservice'], // contact us page (only in app)
    '/butik-sok': ['store', 'oldstore'], // store search page
    '/faq': ['customerservice'], // faq page
    '/handla': ['promotions'], // e-commerce page
    '/sok': ['productFiltering', 'search', 'searchresultpage'], // search page
    '/veckans-erbjudanden': ['promotions'], // weekly offers page
  },
};

const config =
  process.env.NODE_ENV === 'production'
    ? {
        ...baseConfig,
        logBuild: false, // no log in production
        loggerEnvironment: 'browser', // dont run the logger ssr
        logger: logToNewRelic, // log missing keys to newrelic
      }
    : baseConfig;

module.exports = config;
