import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: auto 0 0;
`;

export const EnergyDeclarationArrowButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

export const EnergyDeclarationArrowImage = styled.img`
  height: ${rem(24)};
  width: auto;
`;

export const EnergyDeclarationImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${rem(85)} 0 0 0;
  align-items: flex-start;
  height: 100%;

  ${media.tabletPortrait} {
    padding: 0;
  }
`;
