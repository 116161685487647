import HeaderButton from '@components/atoms/HeaderButton';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const StyledButton = styled(HeaderButton)<{
  $mobile?: boolean;
  $deliveryModeSelected: string;
  $modalIsOpen: boolean;
}>`
  height: ${rem(48)};
  ${({ $mobile, $modalIsOpen, theme }) =>
    $mobile &&
    css`
      flex: 1 0 auto;
      border-radius: 0;
      padding: initial;
      height: ${rem(40)};
      border-bottom: 1px solid ${$modalIsOpen ? theme.colors.colorWhite : theme.colors.colorCararra};
    `}
  ${({ $deliveryModeSelected, $modalIsOpen, theme }) =>
    $deliveryModeSelected &&
    css`
      ${media.desktop} {
        padding: ${rem(10)} ${rem(16)};
        background-color: ${$modalIsOpen ? theme.colors.colorWhite : theme.colors.colorLinen};
      }
    `}
`;

export const Content = styled.div<{ $mobile?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
  max-width: ${rem(200)};
  ${({ $mobile }) =>
    !$mobile &&
    css`
      padding: 0 ${rem(16)} 0 ${rem(8)};
    `}
`;

export const IconWrapper = styled.div<{ $modalIsOpen: boolean }>`
  width: ${rem(40)};
  height: ${rem(40)};
  border-radius: ${rem(16)};
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    transition: 0.5s;
    transform: rotate(${(props) => (props.$modalIsOpen ? '-90' : '90')}deg);
  }
`;

export const DeliveryHeaderText = styled(Paragraph)`
  width: 100%;
`;

export const DeliveryIconWrapper = styled.div`
  margin-right: ${rem(4)};
`;
