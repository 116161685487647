import React, { ReactNode } from 'react';
import { Container, Slider } from './Carousel.styles';

interface Props {
  children: ReactNode;
  offset: number;
}

const Carousel = ({ children, offset }: Props) => {
  return (
    <Container>
      <Slider
        style={{
          transform: `translate3d(-${offset * 100}%,0,0)`,
        }}
        data-testid="carousel-slider"
      >
        {children}
      </Slider>
    </Container>
  );
};
export default Carousel;
