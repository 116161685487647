import { getCurrentDate, getCurrentTime } from '@helpers/getCurrentDateAndTime';
import TagManager from 'react-gtm-module';

const passwordResetRequested = (isB2b: boolean, isEmail: boolean) => {
  // GA3 - TO BE REMOVED - when GA3 is no longer active
  TagManager.dataLayer({
    dataLayer: {
      event: 'track',
      category: isB2b ? 'B2B' : 'B2C',
      action: 'pwd_reset_requested',
      label: isEmail ? 'email' : 'txt_msg',
    },
  });

  // GA4 Tracking pwd_request_sent
  TagManager.dataLayer({
    dataLayer: {
      event: 'pwd_request_sent',
      date: getCurrentDate(),
      sphere_name: 'identification',
      time: getCurrentTime(),
    },
  });
};

// GA3 - TO BE REMOVED - replace all four passwordResetRequeste functions with the GA4 new pwd_request_sent event
export const passwordResetRequestedB2bEmail = () => {
  return passwordResetRequested(true, true);
};

export const passwordResetRequestedB2bSms = () => {
  return passwordResetRequested(true, false);
};

export const passwordResetRequestedB2cEmail = () => {
  return passwordResetRequested(false, true);
};

export const passwordResetRequestedB2cSms = () => {
  return passwordResetRequested(false, false);
};

const passwordResetConfirmed = (isB2b: boolean) => {
  // GA3 - TO BE REMOVED - when GA3 is no longer active
  TagManager.dataLayer({
    dataLayer: {
      event: 'track',
      category: isB2b ? 'B2B' : 'B2C',
      action: 'pwd_reset_confirmed',
    },
  });

  // GA4 Tracking passwordResetConfirmed
  TagManager.dataLayer({
    dataLayer: {
      event: 'new_pwd_sent',
      date: getCurrentDate(),
      sphere_name: 'identification',
      time: getCurrentTime(),
    },
  });
};

export const passwordResetConfirmedB2c = () => {
  return passwordResetConfirmed(false);
};

export const passwordResetConfirmedB2b = () => {
  return passwordResetConfirmed(true);
};
