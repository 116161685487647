import Text from '@components/atoms/Text';

interface Created {
  text: string;
  'data-testid'?: string;
}

const FooterHeading = ({ text, 'data-testid': testId }: Created) => {
  return (
    <Text as="h3" type="p" size="lg" bold="bold" data-testid={testId}>
      {text}
    </Text>
  );
};

export default FooterHeading;
