import { FacetDataOfSearchStateData } from '@api/generated/storefront';
import FACET_CODES from '@constants/productListFilters';
import { Router } from 'next/router';

export const clearFiltersFromQuery = (routerQuery: Router['query']): Router['query'] => {
  const { q, ...other } = routerQuery;
  const searchKey = (q as string)?.split(':')[0].trim();
  return searchKey && searchKey.length ? { ...other, q: searchKey } : other;
};

export const getCurrentSearchKey = (routerQuery: Router['query']): string => {
  const { q } = routerQuery;
  return (q as string)?.split(':')[0].trim();
};

export const countActiveFilters = (facets: FacetDataOfSearchStateData[] | null) => {
  if (facets) {
    return facets.reduce((acc, cur) => {
      let count = 0;
      if (cur.values?.length) {
        const selected = cur.values.filter((i) => i.selected);
        count += selected.length;
      }
      return count > 0 ? acc + count : acc;
    }, 0);
  }
  return 0;
};

export const getCurrentAppliedFilters = (routerQuery: string): string[] => {
  const filterArray = routerQuery?.split(':').slice(1);
  return filterArray.filter((item) => ![FACET_CODES.LABELS, FACET_CODES.BRANDS, FACET_CODES.PROMOTION].includes(item));
};
