import { getArticles } from '@api';
import { ArticleContentPageData } from '@api/generated/storefront';
import { FORTY_MINUTES_IN_MS } from '@constants/timers';
import useCustomer from '@hooks/useCustomer';
import useSWR, { SWRConfiguration } from 'swr';

const fetcher = () => {
  return getArticles('press');
};

const options: SWRConfiguration = {
  revalidateIfStale: false,
  refreshInterval: FORTY_MINUTES_IN_MS,
};

const usePressArticles = () => {
  const { customerFetchIsRequired } = useCustomer();

  return useSWR<ArticleContentPageData[]>(customerFetchIsRequired ? 'press' : null, fetcher, options);
};

export default usePressArticles;
