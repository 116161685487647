import { getMarketingProducts } from '@api';
import { FORTY_MINUTES_IN_MS } from '@constants/timers';
import useCustomer from '@hooks/useCustomer';
import useSWR, { SWRConfiguration } from 'swr';

export const ENDPOINT = 'getProductRecommendationUsingGET';

type KeyType = {
  productCode: string;
  displayType: string;
};

const fetcher = ({ productCode, displayType }: KeyType) => {
  return getMarketingProducts({
    productCode,
    displayType,
  });
};

const options: SWRConfiguration = {
  refreshInterval: FORTY_MINUTES_IN_MS,
  keepPreviousData: true,
  dedupingInterval: 100,
};

const useRelatedProductsBeam = (productCode: string, displayType: string) => {
  const { customerFetchIsRequired } = useCustomer();
  const { data, isLoading } = useSWR(
    customerFetchIsRequired
      ? {
          endpoint: ENDPOINT,
          productCode,
          displayType,
        }
      : null,
    fetcher,

    options
  );
  return {
    products: data?.products || [],
    isLoading,
  };
};

export default useRelatedProductsBeam;
