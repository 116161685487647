import LinkArea from '@components/atoms/LinkArea';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import { PromotionSplashPosition, SplashBoxPosition } from '../ProductImageSection/ProductImageSection.styles';
import ProductEnergyDeclarationLabel from '../ProductInformation/ProductEnergyDeclarationLabel/ProductEnergyDeclarationLabel';
import PromotionSplash from '../PromotionSplash/PromotionSplash';
import { UrlObject } from 'url';
import SplashBox from './SplashBox';
import React from 'react';

interface Props {
  product: Product;
  disableLinks?: boolean;
  onProductLinkClicked: () => void;
  as: { pathname: string };
  href: UrlObject;
  showBargainSplash?: boolean;
  showNewsSplashProduct?: boolean;
  showEnergyDeclaration?: boolean;
  linkAriaLabel: string;
}

const SplashSection = ({
  product,
  disableLinks,
  onProductLinkClicked,
  as,
  href,
  showBargainSplash = false,
  showNewsSplashProduct = false,
  showEnergyDeclaration = false,
  linkAriaLabel,
}: Props) => {
  const { online, promotion, energyDeclaration, hasPromotion } = product;

  const isPromotionUsedOffline = promotion?.isPromotionUsed && !online;
  const disableSplashLinks = disableLinks || isPromotionUsedOffline;
  const showPromotionSplash = hasPromotion && !!disableSplashLinks;
  const showPromotionSplashWithLink = hasPromotion && !disableSplashLinks;

  return (
    <PromotionSplashPosition>
      {(showBargainSplash || showNewsSplashProduct) && (
        <SplashBoxPosition>
          <SplashBox showBargainSplash={showBargainSplash} showNewsSplash={showNewsSplashProduct} />
        </SplashBoxPosition>
      )}
      {showPromotionSplashWithLink && (
        <LinkArea
          aria-label={linkAriaLabel}
          href={href}
          nextLinkAs={as}
          scroll={false}
          shallow
          onClick={onProductLinkClicked}
        >
          <PromotionSplash product={product} />
        </LinkArea>
      )}
      {showPromotionSplash && <PromotionSplash product={product} />}

      {showEnergyDeclaration && !!energyDeclaration && (
        <ProductEnergyDeclarationLabel
          url={href}
          as={as}
          energyDeclaration={energyDeclaration}
          isOnlineProduct={online}
        />
      )}
    </PromotionSplashPosition>
  );
};

export default SplashSection;
