import TagManager from 'react-gtm-module';
import { linkClickTracker } from '../navigationClickTracker/navigationClickTracker';

const MENU_CATEGORY = 'Left menu';
export const openMenuOrProductEvent = (categories: string[] = [], active?: boolean) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: MENU_CATEGORY,
      action: active ? 'category_expanded' : 'category_minimized',
      label: categories.join(' | '),
      value: 0,
    },
  });
};
export const navigationEvent = (linkText: string) => {
  linkClickTracker(MENU_CATEGORY, linkText);
};

export const categoryFilterEvent = (filtertext: string, pageName: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: pageName,
      action: 'filtered_products',
      label: filtertext,
      value: 0,
    },
  });
};
export const searchFilterEvent = (filtertext: string, facetName: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'filter',
      no_results_ind: 0,
      filter_category: facetName === 'commercialName2' ? 'Varumärken' : 'Filter',
      filter_category2: filtertext === 'true' ? 'Erbjundanden' : filtertext,
      sphere_name: 'item',
    },
  });
};

export const openAssortmentMenuEvent = (linkText?: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: MENU_CATEGORY,
      action: 'menu_opened',
      label: '',
      value: 0,
    },
  });
};

export const closeAssortmentMenuEvent = (linkText?: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: MENU_CATEGORY,
      action: 'menu_closed',
      label: '',
      value: 0,
    },
  });
};

export default openMenuOrProductEvent;
