import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

const CONTAINER_WIDTH_SWITCH_BREAKPOINT = '500px';

export const QueryContainer = styled.div<{ $compact?: boolean }>`
  container-type: inline-size;
  container-name: promoPanel;
  height: ${({ $compact }) => ($compact ? rem(190) : rem(300))};
  width: 100%;

  ${media.tabletPortrait} {
    height: ${rem(300)};
  }
`;

export const NarrowContainer = styled.div`
  display: block;
  height: 100%;

  @container promoPanel (min-width: ${CONTAINER_WIDTH_SWITCH_BREAKPOINT}) {
    display: none;
  }
`;

export const WideContainer = styled.div`
  display: none;
  height: 100%;

  @container promoPanel (min-width: ${CONTAINER_WIDTH_SWITCH_BREAKPOINT}) {
    display: block;
  }
`;
