import {
  ProductContainer,
  ProductWrapper,
} from '@components/cmsComponents/AxfoodProductResponsiveBannerComponent/AxfoodProductResponsiveBannerComponent.styles';
import IntersectionWrapper from '@components/cmsComponents/AxfoodProductResponsiveBannerComponent/IntersectionProductWrapper/IntersectionWrapper';
import { Impression, Product } from '@components/molecules/Product/ProductDataMapper/types';
import CartConnectedProduct from '@components/organisms/CartConnectedProduct/CartConnectedProduct';
import { productTracker } from '@trackers';
import { useRef } from 'react';

export interface IntersectionProductWrapperProps {
  hasSplash: boolean;
  firstProduct?: Product;
  secondProduct?: Product;
}

const IntersectionProductWrapper = ({ hasSplash, firstProduct, secondProduct }: IntersectionProductWrapperProps) => {
  const EVENT_LIST_NAME = 'AxfoodProductResponsiveBannerComponent';
  const wrapperRef = useRef<unknown[]>([]);

  const trackIntersection = () => {
    const impressions: Impression[] = [firstProduct as Product, secondProduct as Product]
      .map((p) => p?.analytics?.impressionObject)
      .filter(Boolean);

    productTracker.trackProductImpressionsPushed(impressions, EVENT_LIST_NAME);
  };

  return (
    <IntersectionWrapper ref={wrapperRef} intersectionCallback={() => trackIntersection()}>
      <ProductWrapper $showSplash={hasSplash} data-testid="product-wrapper">
        {!!firstProduct && firstProduct.saleableOnline && (
          <ProductContainer>
            <CartConnectedProduct product={firstProduct} eventListName={EVENT_LIST_NAME} layout="vertical" />
          </ProductContainer>
        )}
        {!!secondProduct && secondProduct.saleableOnline && (
          <ProductContainer>
            <CartConnectedProduct product={secondProduct} eventListName={EVENT_LIST_NAME} layout="vertical" />
          </ProductContainer>
        )}
      </ProductWrapper>
    </IntersectionWrapper>
  );
};

export default IntersectionProductWrapper;
