import Button from '@components/atoms/Button';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const ButtonBase = styled(Button)`
  border-color: ${({ theme }) => theme.colors.colorAcadia};
  width: ${rem(24)};
  height: ${rem(24)};
  padding: ${rem(5)};
  position: absolute;
  z-index: 2;
  top: 36%;
  transform: translateY(-50%);

  ${media.tabletPortrait} {
    width: ${rem(32)};
    height: ${rem(32)};
    padding: ${rem(7)};
  }
`;

export const CarouselWrapper = styled.div`
  width: 100%;
  position: relative;
  container-type: inline-size;
  container-name: carousel;
`;

export const CarouselList = styled.ul`
  margin: 0;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  list-style-type: none;
  padding: ${rem(12)} 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const NextButton = styled(ButtonBase)`
  right: ${rem(-8)};
  transition: opacity 0.2s ease-in-out;

  &:disabled {
    opacity: 0.7;
    background-color: ${({ theme }) => theme.colors.colorWhite};
    border: ${rem(1)} solid ${({ theme }) => theme.colors.colorStack};
  }
`;
export const PrevButton = styled(ButtonBase)`
  left: ${rem(-8)};
  span {
    transform: rotate(180deg);
  }
`;
