import conflictModalActions from '@actions/conflictModal';
import { cancelValidateCanGoToCheckout } from '@actions/validators';
import Button from '@components/atoms/__DEPRECATED__/Button';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import Modal from '@components/molecules/Modal';
import { formatConflictProductPostData } from '@helpers/formatPostDataHelper';
import logError from '@helpers/logHelpers';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import useAddRemoveProducts from '@hooks/useAddRemoveProducts';
import type { AddToCartForm, AxfoodCartEntryStatusViewModel, AxfoodCartProductViewModel } from '@occ/api-client';
import { selectCartProducts } from '@selectors/cart';
import selectConflictModalState, {
  selectNonReplaceableProducts,
  selectReasonTracker,
  selectReplaceableProducts,
} from '@selectors/conflictModal';
import { conflictModalTracker } from '@trackers';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { CloseButton, ConflictModalWrapper, FlexEndButtons } from './ConflictModal.styles';
import NonReplaceableProducts from './NonReplaceableProducts';
import ReplaceableProducts from './ReplaceableProducts';
import useReplaceableItems from './useReplaceableItems';

export const getAvailableQuantity = (product: AxfoodCartEntryStatusViewModel) => {
  if (
    product.notAllowedB2b ||
    product.notAllowedAnonymous ||
    product.notAllowedExternalPickupLocation ||
    product.notAllowedBulkyProduct
  ) {
    return 0;
  }
  return product.availableQuantity ? product.availableQuantity : 0;
};

const ConflictModal = () => {
  const { t } = useTranslation('conflictModal');
  const conflictModal = useAppSelector(selectConflictModalState);
  const cartProducts = useAppSelector(selectCartProducts);
  const reasonTracker = useAppSelector(selectReasonTracker);
  const cartHelpers = useAddRemoveProducts();
  const replaceableItems = useAppSelector(selectReplaceableProducts);
  const nonReplaceableProducts = useAppSelector(selectNonReplaceableProducts);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const { replaceableItemsToUpdate, onUpdateSelectedProduct, onDontReplaceProductClick } = useReplaceableItems();

  useEffect(() => {
    conflictModalTracker.trackConflictModalOpen(
      reasonTracker,
      replaceableItems.length > 0,
      nonReplaceableProducts.length > 0
    );
    return () => {
      dispatch(
        conflictModalActions.setStateConflictingModal({
          open: false,
          items: [],
          resolved: false,
          rejected: false,
          storeId: undefined,
        })
      );
    };
  }, []);

  const onCloseHandler = () => {
    dispatch(cancelValidateCanGoToCheckout());
    conflictModal.rejected && conflictModal.rejected(new Error('Aborted handling conflicts'));
    dispatch(conflictModalActions.openConflictingModal(false));
    conflictModalTracker.closeModal();
  };

  const getAmountAlreadyInCart = (productCode: string) => {
    const product = cartProducts.find((item: AxfoodCartProductViewModel) => item.code === productCode);
    return product?.pickQuantity || 0;
  };

  const getItemsToBeRemovedFromCart = () => {
    return replaceableItems.filter((replaceableItemToRemove) => {
      return replaceableItemsToUpdate.filter((replaceableItemToUpdate) => {
        return replaceableItemToUpdate.productToReplaceCode === replaceableItemToRemove.entryProduct?.code;
      });
    });
  };

  const getSelectedReplacements = (itemsToBeRemoved: AxfoodCartEntryStatusViewModel[]) => {
    return itemsToBeRemoved.map((itemToBeRemoved) => {
      return replaceableItemsToUpdate.find(
        (replaceableItem) => replaceableItem.productToReplaceCode === itemToBeRemoved.entryProduct?.code
      );
    });
  };

  const filterAndFormatConflictProductsWithSelectedReplacement = (
    itemsToBeRemoved: AxfoodCartEntryStatusViewModel[]
  ) => {
    return itemsToBeRemoved.map((item) => {
      const selectedReplacement = replaceableItemsToUpdate.find(
        (replacementItem) => replacementItem.productToReplaceCode === item.entryProduct?.code
      );

      const isReplaceableProductPartial =
        item.partialOutOfStock && selectedReplacement && (selectedReplacement.qty ?? 0) <= (item.availableQuantity ?? 0);

      if (isReplaceableProductPartial) {
        return formatConflictProductPostData(item.entryProduct!, item.availableQuantity);
      }
      return formatConflictProductPostData(item.entryProduct!, 0);
    });
  };

  const formatAndGetAvailableQuantityForNonReplaceableProducts = () => {
    return nonReplaceableProducts.map((product) =>
      formatConflictProductPostData(product.entryProduct!, getAvailableQuantity(product))
    );
  };

  const formatAndGetAmountInCartForSelectedReplacementProducts = () => {
    return replaceableItemsToUpdate.map((item) =>
      formatConflictProductPostData(item.product, (item.qty ?? 0) + getAmountAlreadyInCart(item.code))
    );
  };

  const onConfirmCloseHandler = async () => {
    setIsLoading(true);
    try {
      const itemsToBeRemoved = getItemsToBeRemovedFromCart();
      const itemsToBeAddedToCart = getSelectedReplacements(itemsToBeRemoved);
      const replaceableItemsToRemove = filterAndFormatConflictProductsWithSelectedReplacement(itemsToBeRemoved);
      const formattedNonReplaceableProducts = formatAndGetAvailableQuantityForNonReplaceableProducts();
      const formattedProductsToUpdate = formatAndGetAmountInCartForSelectedReplacementProducts();

      const productsToBeAddedToCart = [
        ...formattedNonReplaceableProducts,
        ...replaceableItemsToRemove,
        ...formattedProductsToUpdate,
      ];

      const listToBeAddedToCart = productsToBeAddedToCart.filter((entry) => !!entry.qty);

      const productsToAdd =
        conflictModal.reasonTracker === 'add_list_to_cart' ? listToBeAddedToCart : productsToBeAddedToCart;

      !!productsToAdd.length &&
        (await cartHelpers.postCartUpdate(productsToAdd as AddToCartForm[], conflictModal.storeId));

      // track products that are being added to cart. (Products that are replacing others)
      itemsToBeAddedToCart.forEach((itemToBeAddedToCart: ProductUpdateType | undefined) => {
        if (itemToBeAddedToCart) {
          conflictModalTracker.addReplacementProductToCart(itemToBeAddedToCart);
        }
      });
      // track products that were replaceable and are now being removed from cart
      itemsToBeRemoved.forEach((itemToBeRemoved: AxfoodCartEntryStatusViewModel) => {
        conflictModalTracker.removeConflictingProduct(itemToBeRemoved, true);
      });
      // track products that could not be replaced
      nonReplaceableProducts.forEach((product) => {
        conflictModalTracker.removeConflictingProduct(product, false);
      });
      conflictModal.resolved && conflictModal.resolved({ resolvedWithConflictingItems: true });

      dispatch(conflictModalActions.openConflictingModal(false));
      setIsLoading(false);
    } catch (e) {
      logError('Could not settle conflicted products', e);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal isOpen zIndex={201} closeHandler={onCloseHandler} data-testid="conflict-modal">
        <ConflictModalWrapper>
          {!!replaceableItems.length && (
            <ReplaceableProducts
              replaceableItems={replaceableItems}
              onUpdateSelectedProduct={onUpdateSelectedProduct}
              onDontReplaceProduct={onDontReplaceProductClick}
              infoText={
                <Paragraph>
                  <Trans ns="conflictModal" i18nKey="conflictModal->text" components={{ br: <br /> }} />
                </Paragraph>
              }
            />
          )}
          {!!nonReplaceableProducts.length && <NonReplaceableProducts nonReplaceableProducts={nonReplaceableProducts} />}
          <FlexEndButtons>
            <CloseButton>
              <Button onClick={onCloseHandler} variant="secondary">
                {t('conflictModal->buttons->cancel')}
              </Button>
            </CloseButton>
            <Button data-testid="confirm" isLoading={isLoading} disabled={isLoading} onClick={onConfirmCloseHandler}>
              {t('conflictModal->buttons->confirm')}
            </Button>
          </FlexEndButtons>
        </ConflictModalWrapper>
      </Modal>
    </>
  );
};

export default ConflictModal;
