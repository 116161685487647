import { setCustomerContactForm } from '@api/CustomerServiceApi';
import FileUploadInputField from '@components/atoms/FileUploadInputField';
import Icon from '@components/atoms/Icon';
import InputField from '@components/atoms/InputField';
import TextArea from '@components/atoms/TextArea';
import Button from '@components/atoms/__DEPRECATED__/Button';
import Dropdown from '@components/atoms/__DEPRECATED__/Dropdown';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import InfoPane from '@components/molecules/InfoPane';
import MessageModal from '@components/molecules/MessageModal';
import {
  DATASKYDD,
  E_HANDEL,
  HEMKOP_BONUSKUND,
  REKLAMATION_KREDITERING,
  VART_SORTIMENT,
  VART_SORTIMENT2,
} from '@constants/customerService';
import { DEFAULT_REQUIRED, EMAIL_VALIDATION } from '@constants/formValidation';
import useCustomer from '@hooks/useCustomer';
import IconMessage from '@public/icons/regularIcons/icon-message.svg';
import { isAxiosError } from 'axios';
import Heading from 'components/atoms/__DEPRECATED__/Heading';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { useCallback, useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import {
  ButtonWrapper,
  Container,
  DropdownLabel,
  FormRow,
  InfoPaneWrapper,
  StyledParagraph,
  TextWrapper,
  Wrapper,
} from './AxfoodCustomerServiceContactComponent.styles';
import useCustomerServiceReasons from './useCustomerServiceReasons';

interface FormProps {
  name: string;
  email: string;
  store?: string;
  orderNo?: string;
  ssn?: string;
  mcn?: string;
  sapId?: string;
  message: string;
  file: File[];
}

const AxfoodCustomerServiceContactComponent = () => {
  const { t } = useTranslation('customerservice');
  const { data: reasons } = useCustomerServiceReasons();
  const [activeItem, setActiveItem] = useState<any | null>(null);

  const [reasonCode, setReasonCode] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [resetDropdown, setResetDropdown] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [filesInputDisabled, setFilesInputDisabled] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const {
    customerId,
    isLoggedIn,
    isAnonymous,
    isLocalB2BCustomer,
    isB2BCustomer,
    sapId,
    idInSourceSystem,
    email,
    name,
  } = useCustomer();

  const { register, control, handleSubmit, watch, formState, setValue, reset } = useForm<FormProps>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      store: '',
      orderNo: '',
      ssn: '',
      mcn: '',
      sapId: '',
      message: '',
      file: [],
    },
  });
  const { errors } = useFormState<FormProps>({ control });

  const onSubmit = async (data: FormProps) => {
    setIsLoading(true);
    try {
      await setCustomerContactForm({ ...data, reasonCode }, files);
      setOpenModal(true);
      setFiles([]);
    } catch (err) {
      setOpenModal(true);

      if (isAxiosError(err)) {
        setError(
          err.response?.data?.errorMessage?.match('contact.page.file.(size|format).error')
            ? err.response?.data?.errorMessage
            : 'default'
        );
      } else {
        setError('default');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const resetForm = useCallback(() => {
    reset();
    setResetDropdown(true);
    if (isLoggedIn) {
      if (isB2BCustomer) {
        setValue('sapId', sapId ? sapId : '');
      } else {
        setValue('mcn', idInSourceSystem && idInSourceSystem !== '0' ? idInSourceSystem : '');
      }
      setValue('email', email || '');
      setValue('name', name || '');
    }
    setFiles([]);
  }, [email, sapId, idInSourceSystem, name, isB2BCustomer, isLoggedIn, reset, setValue]);

  const closeModal = () => {
    !error && resetForm();
    setError('');
    setOpenModal(false);
  };

  const updateActiveItem = (item: any) => {
    setActiveItem(item);
    setReasonCode(item?.code || '');
  };

  useEffect(() => {
    resetForm();
  }, [customerId, resetForm]);

  const vartSortimentNotSelected = reasonCode && reasonCode !== VART_SORTIMENT;
  const vartSortimentSelected = reasonCode && reasonCode === VART_SORTIMENT;
  const showInfoPane = isLocalB2BCustomer && [REKLAMATION_KREDITERING, VART_SORTIMENT2].includes(reasonCode);
  const isLoggedInB2C = !isB2BCustomer && !isAnonymous && idInSourceSystem !== '0';
  const showOrderNrField = reasonCode === E_HANDEL;
  const showSapIdField =
    [E_HANDEL, HEMKOP_BONUSKUND, REKLAMATION_KREDITERING, VART_SORTIMENT2, DATASKYDD].includes(reasonCode) &&
    isB2BCustomer;
  const showMcnField =
    [E_HANDEL, HEMKOP_BONUSKUND, REKLAMATION_KREDITERING, VART_SORTIMENT2, DATASKYDD].includes(reasonCode) &&
    isLoggedInB2C &&
    !isLocalB2BCustomer;
  const showSsnField = HEMKOP_BONUSKUND === reasonCode && isAnonymous;

  return (
    <>
      <Container>
        <Heading type="h2">{t('customerservice:contactForm->header')}</Heading>
        <StyledParagraph>{t('customerservice:contactForm->info')}</StyledParagraph>
        <Wrapper>
          {reasons && (
            <>
              <DropdownLabel htmlFor="reasons">{t('customerservice:contactForm->form->dropdownLabel')}</DropdownLabel>
              <Dropdown
                list={reasons}
                placeholder={t('customerservice:contactForm->form->dropdownPlaceholder')}
                setReset={setResetDropdown}
                reset={resetDropdown}
                activeItem={activeItem}
                updateActiveItem={updateActiveItem}
              />
            </>
          )}
          {vartSortimentNotSelected && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormRow>
                <InputField
                  id="name"
                  name="name"
                  label={t('customerservice:contactForm->form->name')}
                  isActive={!!watch('name')}
                  register={register}
                  error={errors?.name?.message && t(`formValidation:${errors.name.message}`)}
                  rules={{ required: DEFAULT_REQUIRED }}
                />
              </FormRow>
              <FormRow>
                <InputField
                  id="email"
                  type="email"
                  name="email"
                  label={t('customerservice:contactForm->form->email')}
                  isActive={!!watch('email')}
                  register={register}
                  error={errors?.email?.message && t(`formValidation:${errors.email.message}`)}
                  rules={{ required: DEFAULT_REQUIRED, pattern: EMAIL_VALIDATION }}
                />
              </FormRow>
              {showOrderNrField && (
                <FormRow>
                  <InputField
                    id="orderNo"
                    name="orderNo"
                    label={t('customerservice:contactForm->form->orderNo')}
                    isActive={!!watch('orderNo')}
                    register={register}
                    error={errors?.orderNo?.message && t(`formValidation:${errors.orderNo.message}`)}
                  />
                </FormRow>
              )}
              {showSapIdField && (
                <FormRow>
                  <InputField
                    id="sapId"
                    name="sapId"
                    label={t('customerservice:contactForm->form->B2BUsername')}
                    disabled
                    isActive={!!watch('sapId')}
                    register={register}
                    error={errors?.sapId?.message && t(`formValidation:${errors.sapId.message}`)}
                    rules={{ required: DEFAULT_REQUIRED }}
                  />
                </FormRow>
              )}
              {showMcnField && (
                <FormRow>
                  <InputField
                    id="mcn"
                    name="mcn"
                    label={t('customerservice:contactForm->form->logedInB2CUsername')}
                    disabled
                    isActive={!!watch('mcn')}
                    register={register}
                    error={errors?.mcn?.message && t(`formValidation:${errors.mcn.message}`)}
                    rules={{ required: DEFAULT_REQUIRED }}
                  />
                </FormRow>
              )}
              {showSsnField && (
                <FormRow>
                  <InputField
                    id="ssn"
                    name="ssn"
                    label={t('customerservice:contactForm->form->logedOutB2CUsername')}
                    isActive={!!watch('ssn')}
                    register={register}
                    error={errors?.ssn?.message && t(`formValidation:${errors.ssn.message}`)}
                    rules={{ required: DEFAULT_REQUIRED }}
                  />
                </FormRow>
              )}
              <FormRow $marginBottom="0">
                <TextArea
                  id="message"
                  name="message"
                  register={register}
                  maxLength={2000}
                  label={
                    <>
                      <Icon margin="right" icon={IconMessage} data-testid="icon-message" />
                      {t('customerservice:contactForm->form->message')}
                    </>
                  }
                  placeholder={t('customerservice:contactForm->form->messagePlaceholder')}
                  error={errors.message?.message && t(`formValidation:${errors.message.message}`)}
                  rules={{ required: DEFAULT_REQUIRED }}
                />
              </FormRow>
              <FileUploadInputField
                acceptedFileTypes="image/png, image/jpeg, application/pdf, image/gif"
                maxTotalFileSizeInMB={5}
                setFiles={setFiles}
                files={files}
                setDisabled={setFilesInputDisabled}
              />
              <ButtonWrapper>
                <TextWrapper>
                  <Paragraph size="md" textColor="secondary">
                    * {t(`formValidation:required`)}
                  </Paragraph>
                </TextWrapper>
                <Button type="submit" disabled={!formState.isValid || filesInputDisabled} isLoading={isLoading}>
                  {t('common:defaultActions->send')}
                </Button>
              </ButtonWrapper>

              {showInfoPane && (
                <InfoPaneWrapper>
                  <InfoPane showQuestionsAboutOrderText={false} />
                </InfoPaneWrapper>
              )}
            </form>
          )}
        </Wrapper>

        {vartSortimentSelected && (
          <StyledParagraph data-testid="faq-linkoption">
            <Trans
              ns="customerservice"
              i18nKey="customerservice:contactForm->varaSortimentLink"
              components={{
                a: <a aria-label="ourProducts" href="https://www.axfood.se/sv/Kontakt/Konsumentkontakt/" />,
              }}
            />
          </StyledParagraph>
        )}
      </Container>
      {openModal && (
        <MessageModal
          closeHandler={() => closeModal()}
          onConfirm={() => closeModal()}
          title={error ? '' : t('customerservice:contactForm->modal->success->header')}
          text={
            error
              ? t(`customerservice:contactForm->modal->error->${error}`)
              : t('customerservice:contactForm->modal->success->text')
          }
        />
      )}
    </>
  );
};

export default AxfoodCustomerServiceContactComponent;
