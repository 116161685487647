import VideoFullWidth from './components/VideoFullWidth';
import VideoGrid from './components/VideoGrid';

export interface AxfoodVideoBannerComponentProps {
  component: AxfoodVideoBannerComponentType;
}

export const AxfoodVideoBannerComponent = ({ component }: AxfoodVideoBannerComponentProps) => {
  const {
    videoLink,
    videoPlayMode = 'CONTAINER',
    title,
    preamble,
    preambleTextColor,
    titleTextColor,
    textAlign = 'LEFT',
  } = component;

  if (!videoLink) return null;
  return (
    <>
      {title && preamble ? (
        <VideoGrid
          videoLink={videoLink}
          videoPlayMode={videoPlayMode}
          title={title}
          preamble={preamble}
          preambleTextColor={preambleTextColor}
          titleTextColor={titleTextColor}
          textAlign={textAlign}
        />
      ) : (
        <VideoFullWidth videoLink={videoLink} title={title} />
      )}
    </>
  );
};

export default AxfoodVideoBannerComponent;
