import Button from '@components/atoms/__DEPRECATED__/Button';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.colorAcadia};
  color: ${({ theme }) => theme.colors.colorWhite};
  align-items: center;
  justify-content: space-between;
  font-size: ${rem(12)};
  padding: ${rem(15)} ${rem(10)};

  ${media.tabletPortrait} {
    padding: ${rem(15)} ${rem(25)};
  }
`;

export const TextContent = styled.div`
  max-width: ${rem(640)};

  p {
    color: inherit;
    line-height: ${rem(14)};
    font-size: ${rem(12)};
    margin: ${rem(12)} 0;
  }

  a {
    color: inherit;
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
export const GlobalMessageButton = styled(Button)`
  font-size: ${rem(12)};
  height: ${rem(30)};
`;
