import axios, { CancelToken } from 'axios';

const setActiveStore = (data: HemkopCustomer, cancelToken: CancelToken) => {
  return axios.post<StoreType>('/axfood/rest/store/activate', undefined, {
    params: data,
    cancelToken,
  });
};

export default setActiveStore;
