import ColumnComponentContext from '@context/ColumnComponentContext';
import Image from 'next/image';
import { useContext } from 'react';
import { Container, ImageWrapper } from './CMSImageComponent.styles';

interface CMSImageComponentProps {
  component: CMSImageComponent;
}

const CMSImageComponent = ({ component }: CMSImageComponentProps) => {
  const { isMultiRowComponent = false } = useContext(ColumnComponentContext);
  return (
    <Container $withPadding={!isMultiRowComponent}>
      <ImageWrapper>
        <Image src={component.media.url} alt={component.media.altText} fill style={{ objectFit: 'contain' }} />
      </ImageWrapper>
    </Container>
  );
};

export default CMSImageComponent;
