import { TimeSlotData } from '@api/generated/storefront';
import { dateToHourAndMinutes } from '@helpers/date';

export const gtmDeliverySlots = (slots: TimeSlotData[]) => {
  return slots.map((item: TimeSlotData) => ({
    delivery_slot_tm_start: dateToHourAndMinutes(item.startTime),
    delivery_slot_tm_end: dateToHourAndMinutes(item.endTime),
    delivery_slot_status: item.available ? 'available' : 'full',
  }));
};

export default gtmDeliverySlots;
