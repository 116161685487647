import { getProduct } from '@api';
import { FORTY_MINUTES_IN_MS } from '@constants/timers';
import useCustomer from '@hooks/useCustomer';
import axios from 'axios';
import useSWR, { SWRConfiguration } from 'swr';

export const ENDPOINT = 'getProductUsingGET1';

type KeyType = {
  productCode: string;
};

const fetcher = async ({ productCode }: KeyType) => {
  const source = axios.CancelToken.source();
  const res = await getProduct(productCode, source.token);
  return res.data;
};

const options: SWRConfiguration = {
  refreshInterval: FORTY_MINUTES_IN_MS,
  keepPreviousData: true,
};

const useGetProductDetails = (productCode: string) => {
  const { customerFetchIsRequired } = useCustomer();

  const { data, isLoading, error, isValidating } = useSWR(
    !!productCode && customerFetchIsRequired
      ? {
          endpoint: ENDPOINT,
          productCode,
        }
      : null,
    fetcher,
    options
  );
  return {
    product: data,
    isLoading,
    error,
    isValidating,
  };
};

export default useGetProductDetails;
