import Icon from '@components/atoms/Icon';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const ProductLabel = styled(Icon)<{ horizontal?: boolean }>`
  svg {
    width: 100%;
    ${media.tabletPortrait} {
    }
  }
`;

export const ProductLabelsContainer = styled.div`
  width: ${rem(24)};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${rem(2)};

  ${media.tabletPortrait} {
    width: ${rem(24)};
  }
`;
