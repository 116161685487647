import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${rem(15)};
  background-color: ${({ theme }) => theme.colors.colorAcadia};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
