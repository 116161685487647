import { useEffect, useRef, useState } from 'react';

const useItemCount = (defaultItemCount: number) => {
  const widthRef = useRef<HTMLDivElement>(null);
  const itemWidthRef = useRef<HTMLDivElement>(null);

  const [itemCount, setItemCount] = useState(defaultItemCount);

  // We can't use useCallback() here since the refs will not update properly causing the wrong
  // itemCount to appear in certain situations.
  const handleResize = () => {
    let items: number;

    if (widthRef?.current?.offsetWidth && itemWidthRef?.current?.offsetWidth) {
      items = Math.floor(widthRef?.current?.offsetWidth / itemWidthRef?.current?.offsetWidth);
    } else {
      items = defaultItemCount;
    }

    setItemCount(items);
  };

  useEffect(() => {
    handleResize();
  }, [widthRef?.current?.offsetWidth, itemWidthRef?.current?.offsetWidth, handleResize]);

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { itemCount, widthRef, itemWidthRef };
};

export default useItemCount;
