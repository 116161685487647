import useLocalStorage from './useLocalStorage';

interface PromotionStoreLocalStorage {
  activelySelected?: boolean;
  promotionStoreId?: string;
}

export const PROMOTION_STORE = 'promotionStore';

export const usePromotionStore = (
  initialValue: PromotionStoreLocalStorage | null = {
    activelySelected: false,
    promotionStoreId: undefined,
  }
) => {
  const [promotionStore, setPromotionStore] = useLocalStorage(PROMOTION_STORE, initialValue);

  const updatePromotionStore = (data: PromotionStoreLocalStorage) => {
    const updatedData = {
      ...promotionStore,
      ...data,
    };
    setPromotionStore(updatedData);
  };

  const promotionStoreToReturn = promotionStore || {}; // guard against null
  return [promotionStoreToReturn, updatePromotionStore] as const;
};
