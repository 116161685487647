import { AxfoodPromotionCartViewModel, AxfoodPromotionViewModel } from '@api/generated/storefront';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import { Container } from './PromotionBanner.styles';

interface Props {
  promotion: AxfoodPromotionViewModel;
  promotionCartLabel?: string;
  appliedPromotions?: Array<AxfoodPromotionCartViewModel>;
}

const PromotionBanner = ({ promotionCartLabel = '', appliedPromotions = [], promotion }: Props) => {
  return (
    <Container>
      <Paragraph bold="bold" textColor="primary" data-testid="cart-label">
        {promotion.cartLabel}
      </Paragraph>
      {promotion.redeemLimitLabel && (
        <Paragraph size="sm" data-testid="redeem-limit">
          {promotion.redeemLimitLabel}
        </Paragraph>
      )}
      {promotionCartLabel && !appliedPromotions.length && (
        <Paragraph size="md" data-testid="label-info">
          {promotionCartLabel}
        </Paragraph>
      )}
    </Container>
  );
};

export default PromotionBanner;
