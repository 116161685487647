import OutOfStock from '@components/molecules/ProductQuantity/OutOfStock';
import Quantity from '@components/molecules/Quantity';
import { QuantityProps } from '@components/molecules/Quantity/Quantity';

export interface CartQuantityProps extends QuantityProps {
  outOfStock?: boolean;
}

const CartQuantity = (props: CartQuantityProps) => {
  // eslint-disable-next-line fp/no-rest-parameters
  const { outOfStock, ...quantityProps } = props;

  if (outOfStock) {
    return <OutOfStock />;
  }

  return <Quantity {...quantityProps} />;
};

export default CartQuantity;
