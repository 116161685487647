import { rem } from 'polished';
import styled from 'styled-components';

export const ProductCarouselWrapper = styled.div`
  padding: ${rem(16)} 0;
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  align-items: baseline;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: ${rem(10)};
`;

export const AmountOfProducts = styled.span`
  display: block;
  font-size: ${rem(16)};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  font-family: ${({ theme }) => theme.typography.fontSans};
  color: ${({ theme }) => theme.colors.colorTextSecondary};
`;
