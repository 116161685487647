export const OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL';
export const OPEN_LOGIN_MODAL_WITH_GUEST = 'OPEN_LOGIN_MODAL_WITH_GUEST';
export const CLOSE_LOGIN_MODAL = 'CLOSE_LOGIN_MODAL';
export const TOGGLE_CUSTOMER_TYPE = 'TOGGLE_CUSTOMER_TYPE';
export const SET_CURRENT_HINT_CODE = 'SET_CURRENT_HINT_CODE';
export const SET_CURRENT_STATUS = 'SET_CURRENT_STATUS';
export const SET_QR_DATA = 'SET_QR_DATA';
export const SET_BANK_ID_DEVICE_TYPE = 'SET_BANK_ID_DEVICE_TYPE';
export const SET_LOGIN_ALERT = 'SET_LOGIN_ALERT';
export const SET_SHOW_ONBOARDING_INFO = 'SET_SHOW_ONBOARDING_INFO';
