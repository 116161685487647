import Icon from '@components/atoms/Icon/Icon';
import PICK_UNIT from '@constants/pickUnit';
import IconMinus from '@public/icons/regularIcons/icon-minus.svg';
import IconPlus from '@public/icons/regularIcons/icon-plus.svg';
import useTranslation from 'next-translate/useTranslation';
import React, { useRef } from 'react';
import { BorderBox, Container, PlusButton, RoundButton } from './Quantity.styles';
import QuantityInputField from './QuantityInputField';

export const PORTIONS = 'portions';
const iconSize = 24;
export type QuantityUnit = typeof PICK_UNIT.KILOGRAM | typeof PICK_UNIT.PIECES | typeof PORTIONS;
export interface QuantityProps {
  value: number;
  decrement: () => void;
  increment: () => void;
  unit: QuantityUnit;
  incrementValue?: number;
  isActive: boolean;
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const MAX_QUANTITY_VALUE = 999;

const Quantity = ({
  value = 0,
  incrementValue = 1,
  decrement,
  increment,
  unit = PICK_UNIT.PIECES,
  isActive,
  disabled,
  minValue = 0,
  maxValue = MAX_QUANTITY_VALUE,
  onChange,
  onBlur,
  onFocus: onFieldFocus = () => undefined,
}: QuantityProps) => {
  const { t } = useTranslation('product');

  const ref = useRef<HTMLInputElement>(null);

  const onKeyUp = (key: React.KeyboardEvent<HTMLInputElement>) => {
    if (key.key === 'Enter' || key.key === 'Escape') {
      const input = ref?.current;
      input?.blur();
    }
  };

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const input = ref?.current;
    input?.select();
    onFieldFocus(event);
  };

  const minusDisabled = value <= minValue || disabled;
  const quantityValue: string = value?.toString() || minValue.toString();

  return (
    <BorderBox $isActive={isActive} data-testid="quantity-input-wrapper">
      <Container data-testid="quantity-container">
        <RoundButton
          aria-label={t('common:ariaLabels->decrement')}
          data-testid="minus-button"
          type="button"
          onClick={decrement}
          disabled={minusDisabled}
          className="minus-button"
        >
          <Icon size={iconSize} icon={IconMinus} color={minusDisabled ? 'white' : 'red'} data-testid="icon-left" />
        </RoundButton>
        <QuantityInputField
          ref={ref}
          quantityValue={quantityValue}
          onLabelClick={() => ref?.current?.focus()}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          unit={unit}
          minValue={minValue}
          maxValue={maxValue}
          step={incrementValue}
          disabled={disabled}
        />
        <PlusButton
          aria-label={t('common:ariaLabels->increment')}
          data-testid="plus-button"
          type="button"
          onClick={increment}
          disabled={value >= maxValue || disabled}
          className="plus-button"
        >
          <Icon size={iconSize} icon={IconPlus} color="white" data-testid="icon-right" />
        </PlusButton>
      </Container>
    </BorderBox>
  );
};

// eslint-disable-next-line fp/no-mutation
Quantity.displayName = 'Quantity';

export default Quantity;
