import { CSSObject } from 'styled-components';
import { SkeletonButton, SkeletonCircle, SkeletonHalfcircle, SkeletonRect, SkeletonRow } from './Skeleton.styles';

declare type Type = 'text' | 'rect' | 'circle' | 'halfcircle' | 'button';

interface Props {
  count?: number;
  width?: number | string;
  height?: number | string;
  type?: Type;
  animation?: boolean;
  className?: string;
  $style?: CSSObject;
  'data-testid'?: string;
}

const Skeleton = ({
  count = 1,
  width = '100%',
  height = '100%',
  type = 'text',
  animation = true,
  className = '',
  $style,
  'data-testid': testId = undefined,
}: Props) => {
  const skeletonLiteral = {
    circle: SkeletonCircle,
    rect: SkeletonRect,
    halfcircle: SkeletonHalfcircle,
    button: SkeletonButton,
    default: SkeletonRow,
  };

  const skeletons = Array.from({ length: count }, (_, i) => {
    const SkeletonComponent = skeletonLiteral[type as keyof typeof skeletonLiteral] || skeletonLiteral.default;
    return (
      <SkeletonComponent
        className={className}
        $width={width}
        $height={height}
        $animation={animation}
        key={i}
        $style={$style}
        data-testid={testId}
      />
    );
  });

  return <>{skeletons}</>;
};

export default Skeleton;
