import { EnergyDeclaration } from '@api/generated/storefront';
import EnergyDeclarationImage from '@components/atoms/EnergyDeclarationImage';
import Link from '@components/atoms/__DEPRECATED__/Link';
import LinkArea from '@components/atoms/__DEPRECATED__/LinkArea';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import Modal from '@components/molecules/Modal';
import getEnergyDeclarationArrowImageUrl from '@helpers/getEnergyDeclarationArrowImageUrl';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import {
  Container,
  EnergyDeclarationArrowButton,
  EnergyDeclarationArrowImage,
  EnergyDeclarationImageWrapper,
} from './ProductEnergyDeclarationLabel.styles';

// This component is a copy of the <EnergyDeclarationLabel>, with the difference that this component
// is intended to be used in the <Product> component, where as the other component is intended to be used
// in the <ProductDetails> component. The reason we use two instances is because of deviating
// requirements / demands of the component.

export interface ProductEnergyDeclarationLabelProps {
  url: UrlType;
  as: UrlType;
  energyDeclaration: EnergyDeclaration;
  isOnlineProduct?: boolean;
}

const ProductEnergyDeclarationLabel = ({
  url,
  as,
  energyDeclaration,
  isOnlineProduct = true,
}: ProductEnergyDeclarationLabelProps) => {
  const { t } = useTranslation('energydeclaration');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const energyDeclarationUrl =
    typeof url === 'string' || typeof url.query === 'string'
      ? undefined
      : { pathname: `/`, query: { ...url?.query, activeTab: 'misc' } };

  const imageUrl = getEnergyDeclarationArrowImageUrl(energyDeclaration.value);

  if (!imageUrl || !energyDeclarationUrl) {
    return null;
  }

  const handleOnClick = () => {
    setIsModalOpen(true);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Container data-testid="energy-declaration-label">
        {isOnlineProduct && (
          <LinkArea href={energyDeclarationUrl} nextLinkAs={as} shallow forceInternal>
            <EnergyDeclarationArrowImage src={imageUrl} alt={t('title')} />
          </LinkArea>
        )}
        {!isOnlineProduct && (
          <EnergyDeclarationArrowButton onClick={handleOnClick}>
            <EnergyDeclarationArrowImage src={imageUrl} alt={t('title')} />
          </EnergyDeclarationArrowButton>
        )}
        {energyDeclaration.link && (
          <Link href={energyDeclaration.link} target="_blank" external underlined={false} margin={false}>
            <Paragraph size="sm">{t('link')}</Paragraph>
          </Link>
        )}
      </Container>
      {isModalOpen && energyDeclaration && energyDeclaration.image && (
        <Modal isOpen closeHandler={handleModalClose} size="small" position="center">
          <EnergyDeclarationImageWrapper>
            <EnergyDeclarationImage src={energyDeclaration.image} alt={t('title')} />
          </EnergyDeclarationImageWrapper>
        </Modal>
      )}
    </>
  );
};

export default ProductEnergyDeclarationLabel;
