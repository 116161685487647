import Heading from '@components/atoms/Heading';
import Paragraph from '@components/atoms/Paragraph';
import VideoIframe from '@components/molecules/VideoModal/VideoIframe';
import VideoModal from '@components/molecules/VideoModal/VideoModal';
import { DescriptionColumn, Row, VideoColumn } from '../AxfoodVideoBannerComponent.styles';
import useVideoBanner from '../useVideoBanner';
import VideoThumbnail from './VideoThumbnail';
export interface GridVideoProps {
  videoLink: string;
  videoPlayMode?: string;
  title: string;
  preamble: string;
  preambleTextColor?: string;
  titleTextColor?: string;
  textAlign: string;
}

export const VideoGrid = ({ videoLink, videoPlayMode, title, preamble, textAlign }: GridVideoProps) => {
  const { showIframe, setShowIframe, thumbnail, onPlay } = useVideoBanner(title, videoLink);

  return (
    <Row $textAlign={textAlign} data-testid="video-grid-wrapper">
      <DescriptionColumn>
        <Heading type="h2">{title}</Heading>
        <Paragraph>{preamble}</Paragraph>
      </DescriptionColumn>
      <VideoColumn>
        {showIframe && videoPlayMode === 'OVERLAY' && (
          <VideoModal open={showIframe} onClose={() => setShowIframe(false)} videoLink={videoLink} />
        )}

        {showIframe && videoPlayMode !== 'OVERLAY' && <VideoIframe videoLink={videoLink} />}

        {!showIframe && <VideoThumbnail data-testid="video-grid-thumbnail" thumbnail={thumbnail} onPlay={onPlay} />}
      </VideoColumn>
    </Row>
  );
};

export default VideoGrid;
