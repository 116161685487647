import { openLoginModal } from '@actions/login';
import LINKS from '@constants/links';
import { useAppDispatch } from '@hooks/redux';
import useAppRouter from '@hooks/useAppRouter';

/**
 * @description use to route user to Login by push, since Link isn't well suited for such cases
 */
interface RouteToLoginArgs {
  b2b: boolean;
  isLoginConfirm: boolean;
  forceBackToStart?: boolean;
  redirectAfterLogin?: string;
}

const useRouteToLogin = () => {
  const dispatch = useAppDispatch();
  const { pathname, query, push } = useAppRouter();

  const routeToLogin = ({
    b2b = false,
    isLoginConfirm = false,
    forceBackToStart = false,
    redirectAfterLogin = '',
  }: RouteToLoginArgs) => {
    dispatch(openLoginModal({ b2b, isLoginConfirm }));
    const path = forceBackToStart ? '/' : pathname;
    const newQuery = redirectAfterLogin ? { ...query, redirectAfterLogin } : query;
    push({ pathname: path, query: newQuery }, LINKS.LOGIN, { shallow: true });
  };

  return {
    routeToLogin,
    currentPath: pathname,
  };
};

export default useRouteToLogin;
