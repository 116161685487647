import LinkArea from '@components/atoms/LinkArea';
import Heading from 'components/atoms/Heading';
import { ReactNode, memo } from 'react';
import { Content, Item, Pane } from './ArticleItem.styles';

interface Props {
  imageUrl: string;
  link: string;
  description: string;
  subtitle?: ReactNode;
  onClick?: () => void;
}

const ArticleItem = ({ imageUrl, link, description, subtitle, onClick }: Props) => (
  <Item $imgUrl={imageUrl} data-testid="article-item">
    <LinkArea href={link} onClick={onClick}>
      <Pane>
        <Content>
          {subtitle && subtitle}
          {description && (
            <Heading type="h3" color="white">
              {description}
            </Heading>
          )}
        </Content>
      </Pane>
    </LinkArea>
  </Item>
);

export default memo(ArticleItem);
