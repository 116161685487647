import { SearchSuggestionData } from '@api/generated/storefront';
import LinkButton from '@components/atoms/Button/LinkButton';
import Heading from '@components/atoms/Heading';
import Paragraph from '@components/atoms/Paragraph';
import useTranslation from 'next-translate/useTranslation';
import { SearchTextWrapper } from '../common.styles';
import { LinkButtonWrapper } from './CustomSuggestion.styles';
import { Stack } from '@components/atoms/Stack';

interface Props {
  customSuggestion: SearchSuggestionData;
  searchTerm: string;
}

const CustomSuggestion = ({ customSuggestion: { linkText, linkUrl, text }, searchTerm }: Props) => {
  const { t } = useTranslation('common');
  return (
    <SearchTextWrapper data-testid="custom-search-suggestion">
      <Heading type="h3" size="xSmall">
        {t('search->areYouLookingFor')} {searchTerm}
      </Heading>
      <Stack $space={'small'}>
        {text && <Paragraph>{text}</Paragraph>}
        {!!linkUrl && !!linkText && (
          <LinkButtonWrapper>
            <LinkButton variant="primaryOutlined" href={linkUrl} data-testid="custom-suggestion-link">
              {linkText}
            </LinkButton>
          </LinkButtonWrapper>
        )}
      </Stack>
    </SearchTextWrapper>
  );
};

export default CustomSuggestion;
