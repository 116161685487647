/* eslint-disable fp/no-mutation */
import { getCSRFToken } from '@api';
import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import https from 'https';
import { v4 as uuidv4 } from 'uuid';
import logger from './config/logger';

// if client-side
if (typeof window !== 'undefined') {
  let csrfToken: string;
  axios.interceptors.request.use((req) => {
    // add csrf token to all (POST, DELETE, PATCH etc) requests
    req.headers['X-CSRF-Token'] = csrfToken;
    req.headers['X-Request-Id'] = uuidv4();

    return req;
  });
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response, config } = error;
      if (response && response.status === 401 && response.data.error === 'csrf.badormissing') {
        try {
          const csrfResponse = await getCSRFToken();
          csrfToken = csrfResponse.data;
          return axios(config); // retry original request
        } catch (e) {
          logger.error('Refresh CSRF-token failed');
        }
      }
      return Promise.reject(error);
    }
  );
}

// this is serverside only
if (typeof window === 'undefined') {
  axios.defaults.baseURL = global.process.env.SERVER_API_HOST;
  axios.defaults.withCredentials = true;
  axios.defaults.httpsAgent = new https.Agent({ rejectUnauthorized: false });
  axios.defaults.headers.common['X-Api-Key'] = global.process.env.X_API_KEY || '';

  interface MetadataAxiosRequestConfig extends InternalAxiosRequestConfig {
    metadata?: {
      requestId: String;
      data: String;
      startTime: Date;
    };
  }

  axios.interceptors.request.use(
    (req: MetadataAxiosRequestConfig) => {
      const requestId = uuidv4();
      // NOTE: metadata is passed to logger for detailed request logging
      req.metadata = {
        requestId: requestId,
        data: req.data?.toString(),
        startTime: new Date(),
      };
      req.headers['Accept'] = 'application/json';
      req.headers['X-Request-Id'] = requestId;
      return req;
    },
    (error) => {
      // if request fails before sending
      logger.error(error.message || 'ERROR', { config: error.config });
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      logger.info('RESPONSE RECEIVED', { config: response.config, req: response.request, res: response });
      return response;
    },
    (error) => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        logger.error(error.message || 'ERROR', {
          config: error.config,
          req: error.response.request,
          res: error.response,
        });
      } else if (error.request) {
        // The request was made but no response was received
        logger.error(error.message || 'ERROR', { config: error.config, req: error.request });
      } else {
        // Something happened in setting up the request that triggered an Error
        logger.error(error.message || 'ERROR', { config: error.config });
      }
      return Promise.reject(error);
    }
  );
}
