import Icon from '@components/atoms/Icon';
import LINKS from '@constants/links';
import useResponsive from '@hooks/useResponsive';
import IconArrowLeft from '@public/icons/regularIcons/icon-arrow-left.svg';
import useTranslation from 'next-translate/useTranslation';
import AccountButton from './AccountButton';
import { ContinueShoppingButton, HeaderContentWrapper } from './Header.styles';
import Logo from './Logo';

const CheckoutHeader = ({ logotype }: { logotype: SimpleMedia }) => {
  const { t } = useTranslation('cartpage');
  const { isTabletPortraitOrGreater } = useResponsive();

  const iconLeft = <Icon icon={IconArrowLeft} />;
  const buttonText = isTabletPortraitOrGreater ? (
    <>
      {iconLeft} {t('continueShopping')}
    </>
  ) : (
    iconLeft
  );
  return (
    <HeaderContentWrapper>
      <ContinueShoppingButton
        variant={isTabletPortraitOrGreater ? 'transparent' : 'secondary'}
        borderRadius={16}
        href={LINKS.SHOP}
        aria-label={t('continueShopping')}
      >
        {buttonText}
      </ContinueShoppingButton>
      <Logo imageData={logotype} />
      <AccountButton showAccountButtonText={isTabletPortraitOrGreater} />
    </HeaderContentWrapper>
  );
};
export default CheckoutHeader;
