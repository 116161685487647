import { Container, FooterSection, LinksSection, TopContainer, BottomContainer } from './AxfoodFooterComponent.styles';
import FooterSocialMediaIcons from './FooterSocialMediaIcons';
import React from 'react';
import FooterColumnLinks from './FooterColumnLinks';
import FooterAccordionLinks from './FooterAccordionLinks';
import { getSocialLinks } from './socialLinks';
import FooterAppLinks from './FooterAppLinks';
import FooterLegalInfo from '@components/cmsComponents/AxfoodFooterComponent/FooterLegalInfo';
import useFooterResponsive from './useFooterResponsive';

interface AxfoodFooterComponentProps {
  component: AxfoodFooterComponentType;
}

const AxfoodFooterComponent = ({ component }: AxfoodFooterComponentProps) => {
  const { oneRow, twoRows, accordionMenu, columnMenu, horizontalLinks } = useFooterResponsive();
  const {
    notice: companyAddress = '',
    navigationNode: { children: sectionList },
    socialLinks: cmsSocialLinks,
  } = component;

  const socialLinks = getSocialLinks(cmsSocialLinks);

  return (
    <Container $withPadding={columnMenu}>
      <TopContainer>
        {columnMenu ? <FooterColumnLinks columns={sectionList} /> : <FooterAccordionLinks sections={sectionList} />}
        {oneRow && (
          <>
            <FooterSection>
              <LinksSection>
                {socialLinks && <FooterSocialMediaIcons socialLinks={socialLinks} />}
                <FooterAppLinks />
              </LinksSection>
            </FooterSection>
            <FooterSection>
              <FooterLegalInfo companyAddress={companyAddress} rightJustified={columnMenu} />
            </FooterSection>
          </>
        )}
      </TopContainer>
      {twoRows && (
        <BottomContainer $vertical={accordionMenu}>
          <LinksSection $horizontal={horizontalLinks}>
            {socialLinks && <FooterSocialMediaIcons socialLinks={socialLinks} />}
            <FooterAppLinks />
          </LinksSection>
          <FooterLegalInfo companyAddress={companyAddress} rightJustified={columnMenu} />
        </BottomContainer>
      )}
    </Container>
  );
};

export default AxfoodFooterComponent;
