import Icon from '@components/atoms/Icon';
import LinkArea from '@components/atoms/LinkArea';
import Paragraph from '@components/atoms/Paragraph';
import IconCheckRound from '@public/icons/regularIcons/icon-check-round.svg';
import { StyledUsp } from './ContentUspComponent.styles';

interface UspProps {
  message: string;
}

const Usp = ({ message }: UspProps) => (
  <StyledUsp>
    <Icon icon={IconCheckRound} data-testid="icon-check-round" />
    <Paragraph size="small" weight="bold">
      {message}
    </Paragraph>
  </StyledUsp>
);

export interface ContentUspComponentProps {
  component: ContentUspComponentType;
}

const ContentUspComponent = ({ component: { uspLinkUrl, uspLinkTarget, contentUspText } }: ContentUspComponentProps) =>
  uspLinkUrl ? (
    <LinkArea href={uspLinkUrl} target={uspLinkTarget === 'newWindow' ? '_blank' : undefined}>
      <Usp message={contentUspText} />
    </LinkArea>
  ) : (
    <Usp message={contentUspText} />
  );

export default ContentUspComponent;
