import Paragraph from '@components/atoms/Paragraph';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

interface AlignmentProps {
  $alignment: ImageAndParagraphAlignment;
  $media?: { desktop: { url: string } };
}

interface GapAndAlignmentProps extends AlignmentProps {
  $gap: number;
}

export const Wrapper = styled.section<GapAndAlignmentProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.colors.colorWhite};
  border-radius: ${rem(24)};
  padding: ${rem(8)};

  ${media.tabletLandscape} {
    flex-direction: ${({ $alignment }) => ($alignment === 'RIGHT' ? 'row-reverse' : 'row')};
    justify-content: space-between;
    border-radius: ${rem(32)};
    gap: ${rem(16)};
    padding: ${rem(16)};
  }
`;

export const MediaContainer = styled.div<AlignmentProps>`
  position: relative;
  width: 100%;
  min-height: ${rem(296)};
  border-radius: ${rem(24)};
  overflow: hidden;
  background-image: url('${({ $media }) => $media && $media.desktop.url}');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  ${media.tabletLandscape} {
    border-radius: ${rem(32)};
    height: auto;
    width: 50%;
  }
`;

export const Content = styled.div<AlignmentProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${rem(32)} ${rem(16)} ${rem(16)};
  gap: ${rem(24)};
  width: 100%;
  border-radius: ${rem(24)};

  ${media.tabletLandscape} {
    width: 50%;
    padding: ${rem(32)};
  }

  ${media.desktopXXL} {
    padding: ${rem(64)} ${rem(64)} ${rem(64)} ${rem(120)};
  }
`;

export const Caption = styled(Paragraph)`
  position: absolute;
  bottom: ${rem(8)};
  left: ${rem(16)};
  font-style: italic;
`;
