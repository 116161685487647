import Button from '@components/atoms/__DEPRECATED__/Button';
import LinkArea from '@components/atoms/__DEPRECATED__/LinkArea';
import LINKS from '@constants/links';
import useConfig from '@hooks/useConfig';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import { ReactNode } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1 0 auto;
  box-sizing: border-box;
  padding: ${rem('13px')} ${rem('20px')};
  display: flex;
  justify-content: space-between;
`;

interface Props {
  children: ReactNode;
  cartHasProducts: boolean;
  onToCartClick: (direction: string) => void;
}

const MiniCartPaneHeader = ({ children, cartHasProducts, onToCartClick }: Props) => {
  const { t } = useTranslation('minicart');
  const { config } = useConfig();
  const CART_URL = `${config?.PUBLIC_HOST}${LINKS.CART}`;
  const handleClick = () => {
    cartHasProducts && onToCartClick('top');
  };
  return (
    <Container>
      {children}
      <LinkArea href={CART_URL} disabled={!cartHasProducts}>
        <Button
          data-testid="to-cart-button-1"
          disabled={!cartHasProducts}
          variant={cartHasProducts ? 'primary' : 'secondary'}
          onClick={handleClick}
        >
          {t('minicart->buttons->toCartBtnText')}
        </Button>
      </LinkArea>
    </Container>
  );
};

export default MiniCartPaneHeader;
