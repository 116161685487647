import { useState } from 'react';

const useSlider = ({ numSlides = 0, loop = true }) => {
  const [offset, setOffset] = useState<number>(0);
  const lastSlide = numSlides - 1;

  // returns true if it was possible to change the slide.
  function incrementOffset() {
    if (offset === lastSlide) {
      return loop ? (setOffset(0), true) : false;
    }
    setOffset(offset + 1);
    return true;
  }

  // returns true if it was possible to change the slide.
  function decrementOffset() {
    if (offset === 0) {
      return loop ? (setOffset(lastSlide), true) : false;
    }
    setOffset(offset - 1);
    return true;
  }

  return { offset, incrementOffset, decrementOffset, setOffset };
};

export default useSlider;
