import { QUEUE_STATUSES } from '@actions/cart';
import { formatAddressPlain } from '@helpers/address';
import { getQuantity, priceToNumerical } from '@helpers/productHelper';
import sortCartProductsByCategory from '@helpers/sortCartProductsByCategory/sortCartProductsByCategory';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import getState from './getState';

const selectCartState = createSelector(getState, (state: RootState) => state.cart || {});

export const selectCartHasQueuedUpdate = createSelector(
  selectCartState,
  (state) => state.queuedUpdateStatus > QUEUE_STATUSES.IDLE
);

export const selectCart = createSelector(selectCartState, (state) => state.cart || {});
export const selectHasFetchedCart = createSelector(selectCartState, (state) => {
  return state.hasFetchedCart || false;
});
export const selectTotalProductAmount = createSelector(selectCartState, (state) => {
  return state.totalProductAmount || 0;
});

export const selectSubTotalPriceWithDiscountsAndVouchers = createSelector(
  selectCartState,
  (state) => state.cart.subTotalPriceWithDiscountsAndVouchers
);

export const selectCartDeliveryModeCode = createSelector(selectCart, (state) => state.deliveryModeCode || '');
export const selectCartPostalCode = createSelector(selectCart, (state) => state.postalCode || '');
export const selectCartDeliveryAddress = createSelector(selectCart, (state) => state.deliveryAddress || {});
export const selectCartDeliveryFullAddress = createSelector(selectCartDeliveryAddress, (state) => {
  if (!state) {
    return '';
  }
  const { line1 = '', postalCode = '', town = '' } = state;
  return formatAddressPlain(line1, postalCode, town);
});

export const selectCartHasProducts = createSelector(selectCartState, (state) => state.cart?.products?.length > 0);

export const selectCartHasRecipes = createSelector(selectCartState, (state) => state.cart?.recipes?.length > 0);
export const selectCartRecipes = createSelector(selectCart, (state) => state.recipes || []);
export const selectCartProducts = createSelector(selectCartState, (state) => state.cart?.products || []);
export const selectCartProductsByCategory = createSelector(selectCartState, (state) =>
  sortCartProductsByCategory(state.cart?.products)
);

export const selectCartTotalUnitCount = createSelector(selectCartState, (state) => state.cart?.totalUnitCount);
export const selectCartTotalItemsCount = createSelector(selectCartState, (state) => state.cart?.totalItems);

export const productIsInCart = (productId: string) =>
  createSelector(selectCartState, (state) => {
    return !!state.cart?.products?.find((prod) => prod.code === productId);
  });
export const selectCartHasNicotineMedical = createSelector(selectCart, (state) => state.hasNicotineMedical || false);
export const selectCartAgeRestricted18 = createSelector(selectCart, (state) => state.ageRestricted18 || false);
export const selectCartLoyaltySaveValue = createSelector(selectCart, (state) => state.loyaltySaveValue || null);
export const selectCartAgeRestricted15 = createSelector(selectCart, (state) => state.ageRestricted15 || false);
export const selectCartLoyaltyProductCount = createSelector(selectCart, (state) => state.loyaltyProductCount || 0);
export const selectCartAgeRestrictionNumber = createSelector(
  [selectCartAgeRestricted18, selectCartAgeRestricted15],
  (hasAge18Restriction, hasAge15Restriction) => {
    if (hasAge18Restriction) {
      return 18;
    }
    if (hasAge15Restriction) {
      return 15;
    }
    return undefined;
  }
);

export const selectCartHasConfirmedDeliverySlotCode = createSelector(
  selectCartState,
  (state) => !!state.cart?.slot?.code && !!state.cart?.deliverySlot
);

export const selectCartSlot = createSelector(selectCart, (state) => state?.slot);
export const selectCartHasSlot = createSelector(selectCart, (state) => !!state?.slot);
export const selectCartSlotIsB2B = createSelector(selectCartSlot, (state) => state?.b2b || false);
export const selectCartSlotPickupStoreId = createSelector(selectCartSlot, (state) => state?.pickupStoreId);
export const selectSlotCloseTimeFormatted = createSelector(selectCart, (state) => state.slotCloseTimeFormatted || '');
export const selectSlotFormattedDate = createSelector(selectCart, (state) => state.slotFormattedDate || '');

export const selectRecipeProducts = (recipeName?: string) =>
  createSelector(selectCartState, (state) => {
    const products = state.cart?.products?.filter(
      (p) => p.includedInRecipeList && p.includedInRecipeList[0] === recipeName
    );
    return products || [];
  });

export const makeProductQuantityInCartSelector = (productCode: string) =>
  createSelector(selectCartState, (state) => {
    const product = state.cart?.products?.find((p) => p.code === productCode);
    return product ? getQuantity(product) : undefined;
  });

export const makeProductTotalPriceInCartSelector = (productCode: string) =>
  createSelector(selectCartState, (state) => {
    const product = state.cart?.products?.find((p) => p.code === productCode);
    return product ? priceToNumerical(product.totalPrice) : 0;
  });

export const selectCartIsDoNotReplaceAll = createSelector(selectCartState, (state) =>
  state.cart.products.every((p) => p.doNotReplace)
);

export const selectCartIsEkoReplacement = createSelector(selectCart, (state) => state.ekoReplacement);

export const selectCartOrderReference = createSelector(selectCart, (state) => state.orderReference);
export const selectCartOrderReferenceSlotCloseTime = createSelector(
  selectCartState,
  (state) => state.cart.orderReferenceSlotCloseTime
);
export const selectCartPaymentType = createSelector(selectCart, (state) => state.paymentType);

export const selectCartUsersOrderReference = createSelector(selectCart, (state) => state?.usersOrderReference || '');
export const selectCartCustomerExtraInfo = createSelector(
  selectCart,
  ({ doorCode, usersOrderReference, customerComment }) => ({
    doorCode,
    usersOrderReference,
    customerComment,
  })
);

export const selectCartTotalPrice = createSelector(selectCart, (state) => state.totalPrice);

export const selectCartIsFranchise = createSelector(selectCart, (state) => state.franchiseStore);
export const selectSubTotalPriceWithoutDiscounts = createSelector(
  selectCart,
  (state) => state.subTotalPriceWithoutDiscountsNumber
);

export const selectRecipesIdsInCart = createSelector(
  selectCart,
  (state) => state.recipes?.map((recipe) => recipe.id) || []
);

export default selectCartState;
