/* eslint-disable no-nested-ternary */
import type { AxfoodCartProductViewModel, AxfoodProductDetailsViewModel, AxfoodReplacementProductViewModel } from '@occ/api-client';
import { URLPREFIXES } from '@constants/links';
import gtmTrack from '@helpers/gtmTrack';
import { splitEventListName } from '@helpers/gtmTrackEvents';
import { formatRecipeProducts, productToImpressionObject, productToImpressionObjectGa4 } from '@helpers/productHelper';
import { productIsCartProductType } from '@helpers/productTypeHelpers';
import { Impression } from '@components/molecules/Product/ProductDataMapper/types';

export interface ECommerceItem {
  item_id: string;
  item_name: string;
  item_brand: string;
  price: number;
  item_category: string;
  quantity?: number;
  item_list_name?: string;
}
export const addOrRemoveProduct = (
  newQuantity = 0,
  previousQuantity: number,
  product: AxfoodReplacementProductViewModel | ProductVariantType,
  eventListName: string
) => {
  const impression = productToImpressionObject(product);
  const quantity = newQuantity === 0 && previousQuantity === 0 ? product.pickQuantity : newQuantity;

  addOrRemoveProductWithImpression(quantity, previousQuantity, impression, eventListName);
};

export const mapImpressionToEcommerceItem = (impression: Impression): ECommerceItem => {
  return {
    item_id: impression.id,
    item_name: impression.name,
    item_brand: impression.brand,
    price: impression.price,
    item_category: impression.category,
  };
};

export const addOrRemoveProductWithImpression = (
  newQuantity: number,
  previousQuantity: number,
  impression: Impression,
  eventListName: string
) => {
  if (previousQuantity === newQuantity) {
    return; // no need for tracking when no change in quantity
  }

  const remove = previousQuantity > newQuantity;
  const parsedValue = +newQuantity.toFixed(1); // keep one decimal to properly track incrementValues < 1

  const quantityChange = previousQuantity ? Math.abs(previousQuantity - parsedValue) : parsedValue;

  const isPromotionPage = [
    'personal-campaigns-online',
    'personal-campaigns-offline',
    'generic-campaigns-online',
    'generic-campaigns-offline',
  ].includes(eventListName);

  const listArr = isPromotionPage ? eventListName.split('-') : [];
  const listName = isPromotionPage
    ? `offers_page | ${listArr[2] === 'online' ? 'ecommerce' : 'store'} | ${listArr[0]}`
    : eventListName;
  const isArticlePage = eventListName.startsWith(URLPREFIXES.ARTIKEL);
  const item_list_name = isArticlePage ? `article | ${splitEventListName(listName)}` : listName;
  const quantity = +quantityChange.toFixed(1);
  // Prefix the product SKU with 'offline-' if it's part of the offline offers
  const item_id = isPromotionPage && listArr[2] === 'offline' ? `offline-${impression.id}` : impression.id;

  const item: ECommerceItem = { ...mapImpressionToEcommerceItem(impression), item_id, quantity, item_list_name };

  trackECommerce([item], remove);
};

const trackECommerce = (items: ECommerceItem[], remove: boolean) => {
  gtmTrack({
    event: remove ? 'remove_from_cart' : 'add_to_cart',
    ecommerce: {
      currency: 'SEK',
      items,
    },
  });
};
const trackRemoveFromCart = (items: ECommerceItem[]) => {
  trackECommerce(items, true);
};

export const removeRecipeProducts = (products: Array<AxfoodCartProductViewModel>, eventListName: string) => {
  const ecommerce = {
    currencyCode: 'SEK',
    remove: {
      actionField: { list: eventListName },
      products: [...formatRecipeProducts(products)],
    },
  };
  gtmTrack({
    event: 'removeFromCart',
    ecommerce,
  });
};

const getItemListNameForRemove = (eventListName: string): string => {
  const isArticlePage = eventListName.startsWith(URLPREFIXES.ARTIKEL);
  return isArticlePage ? `article | ${splitEventListName(eventListName)}` : eventListName;
};

const mapProductToECommerceItem = (
  product: AxfoodCartProductViewModel | AxfoodProductDetailsViewModel | AxfoodReplacementProductViewModel,
  eventListName: string,
  quantity: number = 0
): ECommerceItem => {
  return {
    ...productToImpressionObjectGa4(product, getItemListNameForRemove(eventListName)),
    quantity,
  };
};

export const removeProductFromCart = (impression: Impression, currentQuantity: number, eventListName: string) => {
  const item: ECommerceItem = {
    ...mapImpressionToEcommerceItem(impression),
    quantity: currentQuantity,
    item_list_name: getItemListNameForRemove(eventListName),
  };
  trackRemoveFromCart([item]);
};
export const removeProduct = (
  product: AxfoodCartProductViewModel | AxfoodProductDetailsViewModel | AxfoodCartProductViewModel,
  currentQuantity: number,
  eventListName: string
) => {
  const quantity = productIsCartProductType(product) ? product.pickQuantity : currentQuantity;
  const item = mapProductToECommerceItem(product, eventListName, quantity);
  trackRemoveFromCart([item]);
};

export const removeAllProducts = (products: Array<AxfoodCartProductViewModel>, eventListName: string) => {
  const items = products.map((product) => {
    return mapProductToECommerceItem(product, eventListName, product.pickQuantity);
  });
  trackRemoveFromCart(items);
};
