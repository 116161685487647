import Overlay from '@components/atoms/Overlay';
import RoundIconButton from '@components/molecules/RoundIconButton';
import isFrontend from '@helpers/isFrontend';
import IconCloseBold from '@public/icons/regularIcons/icon-close-bold.svg';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode, useRef } from 'react';
import ReactDOM from 'react-dom';
import { CloseButtonWrapper, Container, Content } from './Modal.styles';
import useModal from './useModal';

export interface Props {
  size?: 'small' | 'medium' | 'large';
  position?: 'top' | 'center';
  children: ReactNode;
  isOpen: boolean;
  closeHandler?: () => void;
  zIndex?: number;
  scrollTo?: number;
  withCustomOverlay?: boolean;
  withPadding?: boolean;
  lockModalScroll?: boolean;
  lockViewportScroll?: boolean;
  withCloseButton?: boolean;
  'data-testid'?: string;
  'aria-label'?: string;
  hideBackground?: boolean;
  darkBgShadow?: boolean;
}

const Modal = ({
  size = 'large',
  position = 'center',
  scrollTo = 0,
  withCustomOverlay = false,
  children,
  closeHandler,
  isOpen,
  zIndex = 200,
  withPadding = true,
  lockModalScroll = false,
  lockViewportScroll = true,
  withCloseButton = true,
  'data-testid': dataTestId = 'modal',
  'aria-label': ariaLabel,
  hideBackground = false,
  darkBgShadow = false,
}: Props) => {
  const containerRef = useRef(null);
  const { t } = useTranslation('common');

  const handleClose = () => {
    if (closeHandler) closeHandler();
  };

  const modalContainer = isFrontend() ? document.getElementById('modal-container') : null;

  useModal(containerRef, scrollTo);

  if (!modalContainer) return null;

  return ReactDOM.createPortal(
    <>
      <Container
        $isOpen={isOpen}
        $size={size}
        $withPadding={withPadding}
        $position={position}
        $lockModalScroll={lockModalScroll}
        $zIndex={zIndex}
        role="dialog"
        aria-label={ariaLabel}
        ref={containerRef}
        data-testid={dataTestId}
        $hideBackground={hideBackground}
      >
        <Content $size={size}>
          {withCloseButton && (
            <CloseButtonWrapper>
              <RoundIconButton
                icon={IconCloseBold}
                iconSize={40}
                onClick={handleClose}
                buttonVariant="grey"
                color="white"
                aria-label={t('defaultActions->close')}
              />
            </CloseButtonWrapper>
          )}
          {children}
        </Content>
      </Container>
      {!withCustomOverlay && (
        <Overlay
          opacity={darkBgShadow ? 0.8 : 0.5}
          onClick={handleClose}
          zIndex={zIndex - 1}
          lockScroll={lockViewportScroll}
        />
      )}
    </>,
    modalContainer
  );
};

export default Modal;
