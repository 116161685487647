import { useCallback, useEffect, useSyncExternalStore } from 'react';

const dispatchStorageEvent = (key: string, newValue: string | null | undefined) => {
  window.dispatchEvent(new StorageEvent('storage', { key, newValue }));
};

const setLocalStorageItem = (key: string, value: unknown) => {
  const stringifiedValue = JSON.stringify(value);
  window.localStorage.setItem(key, stringifiedValue);
  dispatchStorageEvent(key, stringifiedValue);
};

const removeLocalStorageItem = (key: string) => {
  window.localStorage.removeItem(key);
  dispatchStorageEvent(key, null);
};

const getLocalStorageItem = (key: string) => {
  return window.localStorage.getItem(key);
};

const useLocalStorageSubscribe = (callback: EventListener) => {
  window.addEventListener('storage', callback);
  return () => window.removeEventListener('storage', callback);
};

const getLocalStorageServerSnapshot = (key: string, initialValue: any) => {
  // Return null on the server to indicate that no localStorage value exists
  return null;
};

type UseLocalStorageReturn<T> = [T, (value?: T | ((prevValue: T) => T)) => void];

// based on https://usehooks.com/uselocalstorage but converted to typescript and with server-side rendering support
export const useLocalStorage = <T>(key: string, initialValue: T): UseLocalStorageReturn<T> => {
  const getSnapshot = () => getLocalStorageItem(key);

  const store = useSyncExternalStore(useLocalStorageSubscribe, getSnapshot, () =>
    getLocalStorageServerSnapshot(key, initialValue)
  );

  const setState = useCallback(
    (v?: T | ((prevValue: T) => T)) => {
      try {
        const nextState = typeof v === 'function' ? (v as (prevValue: T) => T)(JSON.parse(store || 'null') as T) : v;
        if (nextState === undefined || nextState === null) {
          removeLocalStorageItem(key);
        } else {
          setLocalStorageItem(key, nextState);
        }
      } catch (e) {
        // do nothing
      }
    },
    [key, store]
  );

  useEffect(() => {
    if (getLocalStorageItem(key) === null && typeof initialValue !== 'undefined') {
      setLocalStorageItem(key, initialValue);
    }
  }, [key, initialValue]);

  const serverSideStore = store ? JSON.parse(store) : initialValue; // localStorage doest not exist on server, fallback to initialValue
  const clientSideStore = store ? JSON.parse(store) : store; // localStorage exists on client, use it
  const currentStore = typeof window === 'undefined' ? serverSideStore : clientSideStore;

  return [currentStore, setState];
};

export default useLocalStorage;
