import { THEME_ALWAYS_GOOD_PRICE } from '@constants/promotion';
import { AxfoodPromotionViewModel } from '@api/generated/storefront';

const trimArray = (arr: any) => {
  if (!arr) return arr;

  arr.forEach((i: number) => {
    if (arr[i]) {
      arr[i] = arr[i].trim();
    }
  });
  return arr;
};

export const getPromotionPriceFromRewardLabel = (label?: string) => {
  if (!label) {
    return {};
  }

  if (label.indexOf('%') !== -1) {
    // "20%"
    const priceArr = trimArray(/^([\d\s]+)(%)[\s]?(.*)?$/.exec(label));

    return Array.isArray(priceArr)
      ? {
          promotionPrice: priceArr[1],
          promotionSymbol: priceArr[2],
          promotionTail: priceArr[3],
        }
      : {};
  } else if (label.indexOf('/') !== -1) {
    // "69,00 /st", "69 /st", "69,00 /st +pant", "69,00 kr/kg"
    const priceArr = trimArray(/^([\d\s]+)[,.]?(\d+)?[\s]?(.*)?$/.exec(label));

    return Array.isArray(priceArr)
      ? {
          promotionPrice: priceArr[1],
          promotionDecimal: priceArr[2] || '00',
          promotionTail: priceArr[3],
          promotionCompactPrice: `${priceArr[1]?.trim()},${priceArr[2] || '00'}`,
        }
      : {};
  } else if (label.indexOf('+') !== -1 || label.indexOf('kr') !== -1) {
    // "69,00 +pant", "69 +pant", "69 kr +pant",
    const priceArr = trimArray(/^([\d\s]+)[,.]?(\d+)?[\s]?(.*)?$/.exec(label));

    return Array.isArray(priceArr)
      ? {
          promotionPrice: priceArr[1],
          promotionDecimal: priceArr[2] || '00',
          promotionTail: priceArr[3],
          promotionCompactPrice: `${priceArr[1].trim(' ')},${priceArr[2] || '00'}`,
        }
      : {};
  }

  // "69,00"
  const priceArr = /^([\d\s]+)[,.]?(\d+)?$/.exec(label);

  return Array.isArray(priceArr)
    ? {
        promotionPrice: priceArr[1],
        promotionDecimal: priceArr[2] || '00',
      }
    : {};
};

export const getPriceFromPromotion = (promotion: AxfoodPromotionViewModel) => {
  if (!promotion.price || !promotion.price.value) {
    return 0;
  }
  return promotion.price.value;
};

export const shouldShowPromotionPrice = ({
  conditionLabelFormatted,
  conditionLabel,
  threshold = 0,
  promotionTheme: { code } = {},
}: AxfoodPromotionViewModel) =>
  !conditionLabelFormatted && !conditionLabel && !(threshold && threshold > 0) && code !== THEME_ALWAYS_GOOD_PRICE;
