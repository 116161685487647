import { EnergyDeclaration } from '@api/generated/storefront';

const validGrade = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

const getEnergyDeclarationArrowImageUrl = (
  grade: EnergyDeclaration['value'],
  orientation: 'left' | 'right' = 'left'
) => {
  if (!grade || !orientation) {
    return null;
  }
  if (!validGrade.includes(grade.toUpperCase())) {
    return null;
  }
  return `/images/${grade.toUpperCase()}_${orientation.toUpperCase()}.png`;
};

export default getEnergyDeclarationArrowImageUrl;
