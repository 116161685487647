import Spinner from '@components/atoms/Spinner';
import IconArrowDown from '@public/icons/regularIcons/icon-arrow-down.svg';
import React from 'react';
import { ButtonDropdownContainer, StyledButton, StyledIcon } from './Button.styles';

declare type Size = 'medium' | 'large';

export declare type ButtonVariants = Theme | ColorType;
export interface IButtonProps {
  children?: React.ReactNode;
  type?: 'submit' | 'button';
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  size?: Size;
  variant?: ButtonVariants;
  title?: string;
  className?: string;
  spinnerColor?: ColorType;
  isLoading?: boolean;
  fullWidth?: boolean;
  form?: string;
  withIcon?: boolean;
  center?: boolean;
  'aria-label'?: string;
  'data-cy'?: string;
  readonly 'data-testid'?: string;
}

/**
 * @deprecated
 */
const Button = ({
  disabled = false,
  variant = 'primary',
  size,
  type = 'button',
  children,
  onClick,
  title,
  className,
  spinnerColor = 'grey',
  isLoading = false,
  fullWidth = false,
  form,
  withIcon = false,
  center = false,
  'aria-label': ariaLabel = undefined,
  'data-cy': dataCypressId = undefined,
  'data-testid': dataTestId = 'button',
}: IButtonProps) => {
  return (
    <StyledButton
      type={type}
      title={title}
      onClick={onClick}
      disabled={disabled || isLoading}
      form={form}
      className={className}
      aria-label={ariaLabel}
      data-cy={dataCypressId}
      $fullWidth={fullWidth}
      $size={size}
      $variant={variant}
      $center={center}
      $withIcon={withIcon}
      data-testid={dataTestId}
    >
      {isLoading && <Spinner color={spinnerColor} />}
      {!isLoading && children}
    </StyledButton>
  );
};

interface ButtonDropdownProps {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isExpanded: boolean;
  'data-testid'?: string;
}
export const ButtonDropdown = ({
  children,
  onClick,
  isExpanded,
  'data-testid': testId = 'dropdown-button',
}: ButtonDropdownProps) => {
  return (
    <ButtonDropdownContainer onClick={onClick} data-testid={testId} type="button">
      <StyledIcon isExpanded={isExpanded} color="black" icon={IconArrowDown} margin="right" />
      {children}
    </ButtonDropdownContainer>
  );
};

export default Button;
