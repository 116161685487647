import REGEXP from '@constants/regexp';

/**
 * react hook form validator rules with i18n keys
 */

export const DEFAULT_REQUIRED = 'required';

export const COMPANY_NAME_MAX_LENGTH = {
  value: 40,
  message: 'companyName',
};

export const VALIDATE_PHONE_NUMBER = { value: REGEXP.CELLPHONE_REGEX, message: 'phoneInvalid' };

export const EMAIL_VALIDATION = {
  value: REGEXP.EMAIL_REGEX,
  message: 'emailInvalid',
};

export const POSTAL_CODE_VALIDATION = { value: REGEXP.ZIP_CODE_REGEX, message: 'postalCodeInvalid' };

export const DOOR_CODE_REQUIRED = { value: REGEXP.DOOR_CODE_REGEX, message: 'doorCodeInvalid' };

export const VALIDATE_DOOR_CODE = (value: string) =>
  value && REGEXP.DOOR_CODE_REGEX.test(value) ? true : 'doorCodeInvalid';

export const OPTIONAL_DOOR_CODE = (value: string) => value === '' || VALIDATE_DOOR_CODE(value);

export const TWELVE_DIGIT_SSN_VALIDATION = { value: REGEXP.TWELVE_DIGIT_SSN, message: 'ssnInvalid' };

export const SSN_VALID = (ssn: string) =>
  ssn === '' || REGEXP.TEN_DIGIT_SSN_AND_OPTIONAL_DASH.test(ssn) || REGEXP.TWELVE_DIGIT_SSN_AND_OPTIONAL_DASH.test(ssn)
    ? true
    : 'ssnInvalid';

export const SSN_OR_MEMBERNUMBER_VALID = (str: string) =>
  str.length > 12 ||
  str === '' ||
  REGEXP.TEN_DIGIT_SSN_AND_OPTIONAL_DASH.test(str) ||
  REGEXP.TWELVE_DIGIT_SSN_AND_OPTIONAL_DASH.test(str)
    ? true
    : 'invalidUserID';

export const USER_NAME = { value: REGEXP.USER_NAME, message: 'userName' };

export const MESSAGE_TO_DRIVER = { value: REGEXP.MESSAGE_TO_DRIVER, message: 'messageToDriver' };

export const ALLOWED_DEFAULT_CHARS = { value: REGEXP.ALLOWED_DEFAULT_CHARACTERS, message: 'invalidCharacters' };

export const DEFAULT_SANITIZE = { value: REGEXP.DEFAULT_SANITIZER, message: 'defaultSanitize' };

export const USER_LOGIN_ID = { value: REGEXP.j_usernameb2c, message: 'invalidUserID' };

export const USER_LOGIN_ID_B2B = { value: REGEXP.j_usernameb2b, message: 'invalidUserIDB2B' };

export const PASSWORD = { value: REGEXP.PASSWORD, message: 'invalidPasswordOrUserName' };

export const PASSWORD_CREATE = { value: REGEXP.PASSWORD, message: 'invalidPassword' };

export const SMS_CODE = { value: REGEXP.SMS_CODE, message: 'invalidSmsCode' };

export const ANY_ORG_NUMBER = { value: REGEXP.ANY_ORG_NUMBER_PATTERN, message: 'invalidOrgNr' };

export const B2B_CUSTOMER_NUMBER_VALID = (str: string) =>
  str === '' || USER_LOGIN_ID_B2B.value.test(str) ? true : USER_LOGIN_ID_B2B.message;
