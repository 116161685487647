import Icon from '@components/atoms/Icon';
import IconArrowDown from '@public/icons/regularIcons/icon-arrow-down.svg';
import IconArrowUp from '@public/icons/regularIcons/icon-arrow-up.svg';
import { Wrapper } from './AccordionListHeader.styles';

export interface Props {
  children?: React.ReactNode;
  isActive?: boolean;
  hasArrow?: boolean;
}

export const activeArrow = 'icon-arrow-up';
export const inactiveArrow = 'icon-arrow-down';

/**
 * @deprecated
 */

const AccordionListHeader = ({ children, isActive = false, hasArrow = false }: Props) => (
  <Wrapper>
    {children}
    {hasArrow && (
      <Icon icon={isActive ? IconArrowUp : IconArrowDown} data-testid={isActive ? activeArrow : inactiveArrow} />
    )}
  </Wrapper>
);

export default AccordionListHeader;
