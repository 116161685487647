import SanitizedHtml from '@components/atoms/SanitizedHtml/SanitizedHtml';
import { StyledCmsHtml } from '../StyledCmsHtml.styles';
import { Container, Content } from './ArticleComponent.styles';

export interface ArticleComponentProps {
  component: ArticleComponent;
}
const ArticleComponent = ({ component }: ArticleComponentProps) => {
  if (!component || !component.content) {
    return null;
  }
  return (
    <Container>
      <Content>
        <StyledCmsHtml>
          <SanitizedHtml content={component.content} />
        </StyledCmsHtml>
      </Content>
    </Container>
  );
};
export default ArticleComponent;
