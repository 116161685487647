import {
  Color as ContentComponentColor,
  HeadingSize as ContentComponentHeadingSize,
} from '@components/organisms/ContentComponent';
import { trackPromoBannerClick } from '@features/promotions/tracking';
import colors from '@styles/colors';
import Image from 'next/image';
import { PromoContentComponent, PromoPanel } from './PromoComponent.styles';

export type PromoButtonTypes = 'white' | 'transparent' | undefined;

export interface ImageData {
  url: string;
  altText?: string;
}

export interface DeviceImages {
  mobile?: ImageData;
  desktop?: ImageData;
}

export interface Tracking {
  shouldTrackClick: boolean;
  link: string;
  name: string;
  slotPosition: string;
}

export interface PromoComponentData {
  textColor?: ContentComponentColor;
  header: {
    text?: string;
    size?: ContentComponentHeadingSize;
  };
  body: {
    text?: string;
  };
  button: {
    text?: string;
    color?: PromoButtonTypes;
    link?: string;
  };
  background: {
    color?: string;
    image: DeviceImages;
  };
  icon?: string; // TODO What icon is, is still yet to be decided, left as is for now
  tracking: Tracking;
}

export const Colors: { [key: string]: string } = {
  clubPink: colors.colorRemy,
  lightPink: colors.colorLinen,
  lightYellow: colors.colorOldLace,
  lightOrange: colors.colorLightOrange,
};

export interface PromoComponentProps {
  data: PromoComponentData;
  fixedButtonGap?: boolean;
}

export const promoBannerClicked = (tracking: Tracking) =>
  tracking.shouldTrackClick &&
  trackPromoBannerClick(tracking.link, tracking.name, tracking.slotPosition, 'promo_banner');

const PromoComponent = ({ data, fixedButtonGap = true }: PromoComponentProps) => {
  const { background, tracking } = data;

  const backgroundColor = background.color && Colors[background.color];
  const desktopImage = background.image.desktop;
  const buttonVariant = data.button.color === 'transparent' ? 'strokeBlack' : 'secondaryPlain';

  return (
    <PromoPanel $backgroundColor={backgroundColor}>
      {/* This image really need unoptimized, also ensures it looks right in storybook */}
      {desktopImage && (
        <Image
          src={desktopImage.url}
          alt={desktopImage.altText || 'Bild'}
          style={{ objectFit: 'none', objectPosition: 'left top' }}
          fill
          unoptimized
        />
      )}
      <PromoContentComponent
        data={{
          title: data.header.text,
          text: data.body.text,
          buttonText: data.button.text,
          buttonUrl: data.button.link,
        }}
        centerContent={false}
        fixedButtonGap={fixedButtonGap}
        headingSize={data.header.size || 'large'}
        buttonVariant={buttonVariant}
        color={data.textColor}
        icon={data.icon}
        onClick={() => promoBannerClicked(tracking)}
      />
    </PromoPanel>
  );
};

export default PromoComponent;
