/*
TODO:
Backend might return a KPL warehouse as active store (identfied by external: true).
A KPL warehouse should not be selected as an active store.
*/
const shouldSetActiveStore = (store?: StoreType): boolean => {
  if (store?.external === false) return true;

  return false;
};

export default shouldSetActiveStore;
