import * as types from '../actions/validators/types';

interface ValidatorsState {
  isValidatingCanGoToCheckout: boolean;
  isGuestCheckoutConfirmed: boolean;
}

const initialState: ValidatorsState = {
  isValidatingCanGoToCheckout: false,
  isGuestCheckoutConfirmed: false,
};

const validatorsReducer = (state = initialState, action: any): ValidatorsState => {
  switch (action.type) {
    case types.INIT_VALIDATE_CAN_GO_TO_CHECKOUT:
      return { ...state, isValidatingCanGoToCheckout: true };
    case types.CANCEL_VALIDATE_CAN_GO_TO_CHECKOUT:
      return { ...state, isValidatingCanGoToCheckout: false };
    case types.CONFIRM_GUEST_CHECKOUT:
      return { ...state, isGuestCheckoutConfirmed: true };
    default:
      return state;
  }
};

export default validatorsReducer;
