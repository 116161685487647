import useOnetrustActiveGroupHelper from '@helpers/oneTrustActiveGroupHelper';
import useIntersection from '@hooks/useIntersection';
import { useEffect, useState } from 'react';

const useTrackComponentShown = (trackingCallback: () => void) => {
  const [componentRef, intersectionObject] = useIntersection<HTMLDivElement>();
  const [trackingSent, setTrackingSent] = useState(false);
  const isOneTrustReady = useOnetrustActiveGroupHelper();

  const isReadyForTracking: boolean = !trackingSent && isOneTrustReady && !!componentRef.current;
  const isVisible: boolean = isReadyForTracking && !!intersectionObject?.isIntersecting;

  useEffect(() => {
    if (isVisible) {
      trackingCallback();
      setTrackingSent(true);
    }
  }, [isVisible, trackingCallback]);

  return { componentRef };
};

export default useTrackComponentShown;
