import Image from 'next/image';
import { ImageContainer } from './ProductImageSection.styles';

interface ProductImageProps {
  url: string | undefined;
  altText: string | undefined;
  isPreview: boolean;
}

export const IMAGE_FALLBACK_TEXT = 'Bild saknas, thumbnail saknas';
const fallbackImage = '/images/productFallbackImage.svg';

const ProductImage = ({ url, altText = '', isPreview }: ProductImageProps) => {
  if (isPreview && !url) {
    return (
      <ImageContainer>
        <Image src={fallbackImage} alt={IMAGE_FALLBACK_TEXT} fill />
      </ImageContainer>
    );
  }
  if (!url) {
    return null;
  }
  return (
    <ImageContainer>
      <Image loading="lazy" src={url} alt={altText} fill />
    </ImageContainer>
  );
};

export default ProductImage;
