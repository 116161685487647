import styled from 'styled-components';

export const SectionWrapper = styled.div``;

export const SectionHeaderSlotWrapper = styled.button`
  outline: none;
  border: none;
  text-align: left;
  background: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  padding: 0;
`;

export const SectionContentSlotWrapper = styled.div``;
