import { getQueryWithoutFilters } from '@helpers/string';
import useAppRouter from '@hooks/useAppRouter';
import { useState } from 'react';

const useSearchTerm = () => {
  const { query } = useAppRouter();
  const initialSearchTerm = getQueryWithoutFilters(query.q);
  return useState<string>(initialSearchTerm);
};

export default useSearchTerm;
