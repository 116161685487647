import Icon from '@components/atoms/Icon';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

const getVariant = ($variant: string) => {
  switch ($variant) {
    case 'orange':
      return css`
        background-image: url('/icons/colorIcons/icon-splash-orange-new.svg');
      `;
    case 'red':
      return css`
        background-image: url('/icons/colorIcons/icon-splash-red-new.svg');
      `;
    case 'yellow':
      return css`
        background-image: url('/icons/colorIcons/icon-splash-yellow-new.svg');
        color: ${({ theme }) => theme.colors.colorPrimary};
      `;
    case 'priceBomb':
      return css`
        background-image: url('/icons/colorIcons/icon-pricebomb-new.svg');
        height: ${rem(48)};
        width: ${rem(48)};
        font-size: ${rem(10)};
        padding-left: ${rem(3)};
        ${media.tabletLandscape} {
          font-size: ${rem(12)};
          width: ${rem(68)};
          height: ${rem(68)};
        }
      `;
    default:
      return '';
  }
};

export const PromotionSplashPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  ${media.tabletPortrait} {
    height: 100%;
  }
`;

export const PromotionSplashSavePrice = styled.div<{ $isPriceBomb?: boolean }>`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: ${rem(60)};
  min-width: ${rem(60)};
  ${media.tabletPortrait} {
    min-width: ${({ $isPriceBomb }) => ($isPriceBomb ? rem(100) : rem(88))};
    width: ${({ $isPriceBomb }) => ($isPriceBomb ? rem(100) : rem(88))};
  }
`;

export const PromotionSplashSavePriceTail = styled.span`
  margin-left: ${rem(3)};
`;

export const PromotionSplashPriceText = styled.span`
  flex-basis: 100%;
  width: 100%;
  text-align: center;
  margin: auto;
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
`;

export const SplashWrapper = styled.div<{ $color: string }>`
  background-repeat: no-repeat;
  background-size: cover;
  width: ${rem(48)};
  height: ${rem(40)};
  z-index: 2;
  font-family: ${({ theme }) => theme.typography.fontSerif};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  font-size: ${rem(12)};
  color: ${({ theme }) => theme.colors.colorWhite};

  ${media.tabletLandscape} {
    width: ${rem(58)};
    height: ${rem(48)};
    font-size: ${rem(14)};
  }
  ${({ $color }) => getVariant($color)};
`;
