import { formatCategoryProducts } from '@components/cmsComponents/CategoryProductsComponent/helpers';
import axios, { AxiosRequestConfig } from 'axios';

export type getCategoryParams = {
  q?: string;
  categoryPath: string;
  size?: number;
  page?: number;
  code?: string;
  sort?: string;
  config?: AxiosRequestConfig;
};

const getCategory = async ({ q, categoryPath, size = 30, page = 0, sort, config = {} }: getCategoryParams) => {
  const response = await axios.get(`/c/${categoryPath}`, {
    params: {
      size,
      page,
      q,
      sort,
    },
    ...config,
  });
  return formatCategoryProducts(response.data);
};
export default getCategory;
