import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const SuggestionsPanel = styled.div<{ $modal: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  border-radius: ${rem(16)};
  padding: ${rem(32)};
  word-break: break-word;
  text-align: left;
  gap: ${rem(16)};
  margin: 0;
  ${media.tabletLandscape} {
    flex-direction: row;
    text-align: center;
  }
`;

export const IconWrapper = styled.div`
  min-width: ${rem(80)};
`;

export const SearchHintList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
  list-style-position: inside;

  padding: 0 0 0 ${rem(16)};
  margin: 0;
`;

export const ListItem = styled.li`
  text-align: center;
  ${media.tabletPortrait} {
    text-align: left;
  }
`;
