import SwipeableWrapper from '@components/atoms/SwipeableWrapper';
import Carousel from '@components/molecules/__DEPRECATED__/Carousel';
import useResponsive from '@hooks/useResponsive';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { SwipeEventData } from 'react-swipeable';
import {
  ConflictedProductHeaderButtons,
  ConflictedProductReplaceWithWrapper,
  ConflictedProductWrapperHeader,
  ConflictedProductsWrapper,
  ReplacementProductsSlide,
} from './ConflictModal.styles';
import ReplaceableProductsWithTracking from './ReplaceableProductsWithTracking';
import SlideButtons from './SlideButtons';
import useConflictModalSlides from './useConflictModalSlides';
import type { AxfoodReplacementProductViewModel } from '@occ/api-client';

export const TABLET_LANDSCAPE_PRODUCTS_PER_SLIDE = 3;
export const TABLET_PRODUCTS_PER_SLIDE = 2;
export const MOBILE_PRODUCTS_PER_SLIDE = 1;

interface ReplaceableProductsProps {
  productToReplaceCode?: string;
  productToReplaceName?: string;
  replacementProductsForProduct: AxfoodReplacementProductViewModel[];
  updateSelectedProduct: (productToUpdate: ProductUpdateType) => void;
}

const ReplacementProducts = ({
  productToReplaceCode,
  replacementProductsForProduct,
  updateSelectedProduct,
  productToReplaceName,
}: ReplaceableProductsProps) => {
  const { t } = useTranslation('conflictModal');
  const [productToUpdate, setProductToUpdate] = useState<ProductUpdateType>({
    productToReplaceCode,
    productToReplaceName,
    code: replacementProductsForProduct[0]?.code,
    qty: replacementProductsForProduct[0]?.replacementQty,
    product: replacementProductsForProduct?.[0],
  });
  const { isTabletPortraitOrGreater } = useResponsive();
  const { productsForCarouselSlides, incrementOffset, decrementOffset, offset } = useConflictModalSlides({
    tabletSlides: TABLET_PRODUCTS_PER_SLIDE,
    tabletLandscapeSlides: TABLET_LANDSCAPE_PRODUCTS_PER_SLIDE,
    mobileSlides: MOBILE_PRODUCTS_PER_SLIDE,
    products: replacementProductsForProduct,
  });

  useEffect(() => {
    updateSelectedProduct(productToUpdate);
  }, [productToUpdate]);

  const touchSlideHandler = (e: SwipeEventData) => {
    e.dir === 'Left' && incrementOffset();
    e.dir === 'Right' && decrementOffset();
  };

  const handleQuantityChange = (item: AxfoodReplacementProductViewModel, quantity: number) => {
    setProductToUpdate({ productToReplaceCode, productToReplaceName, code: item.code, qty: quantity, product: item });
  };
  if (replacementProductsForProduct?.length === 0) return null;
  return (
    <ConflictedProductReplaceWithWrapper>
      <ConflictedProductWrapperHeader>
        {isTabletPortraitOrGreater && <span>{t('conflictModal->titles->replaceWith')}</span>}
        <ConflictedProductHeaderButtons>
          <SlideButtons
            decrementOffset={decrementOffset}
            offset={offset}
            incrementOffset={incrementOffset}
            amountOfProductsForCarousel={productsForCarouselSlides.length}
          />
        </ConflictedProductHeaderButtons>
      </ConflictedProductWrapperHeader>
      <ConflictedProductsWrapper>
        <SwipeableWrapper onSwiped={touchSlideHandler}>
          <Carousel offset={offset}>
            {(productsForCarouselSlides as Array<AxfoodReplacementProductViewModel[]>).map((slide, i) => {
              return (
                <ReplacementProductsSlide key={slide[0].code} data-testid="replacement-product-slide">
                  <ReplaceableProductsWithTracking
                    conflictProducts={slide}
                    productToUpdate={productToUpdate}
                    onQuantityChange={handleQuantityChange}
                    productToReplaceName={productToReplaceName}
                    productToReplaceCode={productToReplaceCode}
                    slideIndex={i + 1}
                    slideSize={productsForCarouselSlides[0].length}
                  />
                </ReplacementProductsSlide>
              );
            })}
          </Carousel>
        </SwipeableWrapper>
      </ConflictedProductsWrapper>
    </ConflictedProductReplaceWithWrapper>
  );
};

export default ReplacementProducts;
