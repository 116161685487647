import cart from '@reducers/cart';
import checkout from '@reducers/checkout';
import conflictModal from '@reducers/conflictModal';
import delivery from '@reducers/delivery';
import login from '@reducers/login';
import notifications from '@reducers/notifications';
import onboarding from '@reducers/onboarding';
import validators from '@reducers/validators';
import type { Action, ThunkAction } from '@reduxjs/toolkit';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import modals from 'redux/slices/modals';
import sideNav from 'redux/slices/sideNav';

// TODO: switch to combineSlices after upgrading to redux-toolkit > 2.0.0
const rootReducer = combineReducers({
  cart,
  checkout,
  conflictModal,
  delivery,
  login,
  notifications,
  validators,
  onboarding,
  sideNav,
  modals,
});

// TODO: update to export type RootState = ReturnType<AppStore['getState']> after upgrading to redux-toolkit > 2.0.0
// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>;

// `makeStore` encapsulates the store configuration to allow
// creating unique store instances, which is particularly important for
// server-side rendering (SSR) scenarios. In SSR, separate store instances
// are needed for each request to prevent cross-request state pollution.
export const makeStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

// Infer the type of makeStore
export type AppStore = ReturnType<typeof makeStore>;

// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore['dispatch'];
export type AppThunk<ThunkReturnType = void> = ThunkAction<ThunkReturnType, RootState, unknown, Action>;
