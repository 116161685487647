import HeroContentComponent from '@components/molecules/HeroComponent/HeroContentComponent';
import { Color } from '@components/organisms/ContentComponent';
import { trackHeroBannerView } from '@features/promotions/tracking';
import useOnetrustActiveGroupHelper from '@helpers/oneTrustActiveGroupHelper';
import Image from 'next/image';
import { useEffect } from 'react';
import { Container, NoPanel } from './HeroComponent.styles';
import HeroPanel, { HeroPanelData } from './HeroPanel';
import useHeroResponsive from './useHeroResponsive';

export interface HeroComponentData extends HeroPanelData {
  desktopImageUrl: string;
  desktopImageAltText?: string;
  withPanel?: boolean;
  textColor?: Color;
}

const HeroComponent = ({ data }: { data: HeroComponentData }) => {
  const isOneTrustReady = useOnetrustActiveGroupHelper();
  const { isMobile } = useHeroResponsive();
  const showPanel = isMobile || data.withPanel;

  useEffect(() => {
    if (isOneTrustReady) {
      trackHeroBannerView(data.buttonUrl as string, data.buttonText as string);
    }
  }, [isOneTrustReady]);

  return isMobile ? (
    <HeroPanel data={data} isMobile={isMobile} />
  ) : (
    <Container>
      {data.desktopImageUrl && (
        <Image
          src={data.desktopImageUrl}
          alt={data.desktopImageAltText || 'Hero image'}
          style={{ objectFit: 'cover', objectPosition: 'top' }}
          fill
        />
      )}
      {showPanel && <HeroPanel data={data} isMobile={isMobile} />}
      {!showPanel && (
        <NoPanel $isMobile={isMobile}>
          <HeroContentComponent data={data} color={data.textColor || 'black'} isMobile={isMobile} />
        </NoPanel>
      )}
    </Container>
  );
};

export default HeroComponent;
