import Quantity from '@components/molecules/Quantity';
import { QuantityProps } from '@components/molecules/Quantity/Quantity';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { Transition } from 'react-transition-group';
import Offline from './Offline';
import OutOfStock from './OutOfStock';
import { BuyButtonWrapper, FlexButton, QuantityWrapper, Wrapper } from './ProductQuantity.styles';

export interface ProductQuantityProps extends QuantityProps {
  outOfStock: boolean;
  offline?: boolean;
}

const ProductQuantity = (props: ProductQuantityProps) => {
  const { t } = useTranslation('common');
  // eslint-disable-next-line fp/no-rest-parameters
  const { outOfStock, offline, onBlur, decrement, incrementValue, ...quantityProps } = props;

  const [showBuyButton, setShowBuyButton] = useState(!props.value);

  useEffect(() => {
    if (props.value > 0 && showBuyButton) {
      setShowBuyButton(false);
    }
  }, [props.value, showBuyButton]);

  if (offline) {
    return <Offline />;
  }

  if (outOfStock) {
    return <OutOfStock />;
  }

  // quantity component needs to be mounted for animation to work
  // thats why it's disabled when showBuyButton is true
  const quantityDisabled = props.disabled || showBuyButton;
  const opacityDuration = 300;

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    onBlur && onBlur(event);
    if (event.currentTarget.valueAsNumber === 0) {
      setShowBuyButton(true);
    }
  };

  const handleDecrement = () => {
    decrement();
    if (props.value === incrementValue) {
      setShowBuyButton(true);
    }
  };
  return (
    <Wrapper>
      <Transition in={showBuyButton} timeout={opacityDuration} unmountOnExit>
        {(status) => (
          <BuyButtonWrapper $animationState={status}>
            <FlexButton
              onClick={() => {
                props.increment();
                setShowBuyButton(false);
              }}
            >
              {t('defaultActions->buy')}
            </FlexButton>
          </BuyButtonWrapper>
        )}
      </Transition>

      <Transition in={!showBuyButton} timeout={opacityDuration}>
        {(status) => (
          <QuantityWrapper $hasQuantity={!showBuyButton} $animationState={status}>
            <Quantity
              {...quantityProps}
              incrementValue={incrementValue}
              disabled={quantityDisabled}
              onBlur={handleBlur}
              decrement={handleDecrement}
            />
          </QuantityWrapper>
        )}
      </Transition>
    </Wrapper>
  );
};

export default ProductQuantity;
