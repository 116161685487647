import HorizontalProduct from '@components/molecules/Product/HorizontalProduct/HorizontalProduct';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import useIntersection from '@hooks/useIntersection';
import { useEffect, useRef } from 'react';

interface Props {
  position: number;
  onProductClick: (name: string, code: string) => void;
  product: Product;
  onProductShown: (product: Product, position: number) => void;
  eventListName: string;
}

const ProductWithDelayedTracking = ({ position, onProductClick, product, onProductShown, eventListName }: Props) => {
  const [ref, intersectionObject] = useIntersection<HTMLDivElement>();

  const timeoutHandler = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (timeoutHandler.current) {
      clearTimeout(timeoutHandler.current);
      timeoutHandler.current = null;
    }
    if (intersectionObject?.isIntersecting) {
      timeoutHandler.current = setTimeout(() => {
        onProductShown(product, position);
      }, 1000);
    }
    return () => {
      timeoutHandler.current && clearTimeout(timeoutHandler.current);
      timeoutHandler.current = null;
    };
  }, [intersectionObject?.isIntersecting]);
  return (
    <div ref={ref} onClick={() => onProductClick(product.name, product.code)} key={product.code}>
      <HorizontalProduct product={product} key={product.code} analyticsTracker={eventListName} />
    </div>
  );
};

export default ProductWithDelayedTracking;
