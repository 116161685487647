import Modal from '@components/molecules/Modal';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import useCustomer from '@hooks/useCustomer';
import { memo, useEffect } from 'react';
import {
  closeModal,
  CUSTOMER_REMINDER_MODAL,
  openCustomerReminderModal,
  selectIsCustomerInfoReminderModalOpen,
  selectIsMissingInfoReminderPending,
  setIsMissingInfoReminderPending,
} from 'redux/slices/modals';
import AddMissingCustomerInformation from './AddMissingCustomerInformation';

const CustomerInfoReminderModal = () => {
  const dispatch = useAppDispatch();
  const isModalVisible = useAppSelector(selectIsCustomerInfoReminderModalOpen);
  const { uid, email, defaultShippingAddress } = useCustomer();
  const customerIsMissingInfo = uid !== 'anonymous' && (!email || !defaultShippingAddress?.cellphone);
  const isMissingInfoReminderPending = useAppSelector(selectIsMissingInfoReminderPending);

  const handleClose = () => {
    dispatch(closeModal(CUSTOMER_REMINDER_MODAL));
    dispatch(setIsMissingInfoReminderPending(false));
  };

  useEffect(() => {
    if (customerIsMissingInfo && isMissingInfoReminderPending) {
      dispatch(openCustomerReminderModal());
    }
  }, [customerIsMissingInfo, isMissingInfoReminderPending, dispatch]);

  if (!isModalVisible) return null;

  return (
    <Modal isOpen={isModalVisible} closeHandler={handleClose} size="medium">
      <AddMissingCustomerInformation onClose={handleClose} />
    </Modal>
  );
};

export default memo(CustomerInfoReminderModal);
