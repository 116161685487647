import styled from 'styled-components';
import { rem } from 'polished';

export const Container = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  align-self: flex-end;
  display: flex;
`;
export const Indicator = styled.button<{ $active: boolean }>`
  border: none;
  padding: 0;
  cursor: pointer;
  width: ${rem(11)};
  height: ${rem(11)};
  background-color: ${({ theme }) => theme.colors.colorWhite};
  margin: 0 ${rem(4)};
  border-radius: ${rem(5)};

  &:focus {
    outline: none;
  }
  ${({ $active, theme }) => $active && `background-color: ${theme.colors.colorAlizarin};`}
`;
