import React from 'react';

interface SimpleBannerComponentProps {
  component: SimpleBannerComponentType;
}

const SimpleBannerComponent = ({ component }: SimpleBannerComponentProps) => {
  return <img src={component.media.url} alt={component.media.altText} />;
};

export default SimpleBannerComponent;
