import AccordionListHeader, { Props } from '@components/molecules/__DEPRECATED__/AccordionListHeader';
import { Wrapper } from './AccordionListItemBaseNewStyle.styles';

/**
 * @deprecated
 */
const AccordionListItemBaseNewStyle = ({ children, isActive = false, hasArrow = false }: Props) => {
  return (
    <Wrapper>
      <AccordionListHeader isActive={isActive} hasArrow={hasArrow}>
        {children}
      </AccordionListHeader>
    </Wrapper>
  );
};

export default AccordionListItemBaseNewStyle;
