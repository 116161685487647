import media from '@constants/responsive';
import { ArticleComponentPadding } from '@styles/cms';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

interface ContainterProps {
  $withPadding: boolean;
}

export const Container = styled.div<ContainterProps>`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  border-radius: ${rem(24)};
  display: flex;
  justify-content: center;

  ${({ $withPadding }) =>
    $withPadding &&
    css`
      ${ArticleComponentPadding}
    `}
  ${media.tabletPortrait} {
    border-radius: ${rem(32)};
  }
`;
