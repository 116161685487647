import { BonusInfoData } from '@api/generated/storefront';
import { limitValueWithinRange } from '@helpers/limitValue';

export interface BonusPoints {
  level: number;
  levelEndDate?: string;
  amountLeftToNextLevel?: string;
  amountToStayOnCurrentLevel?: string;
  totalPoints: string;
  daysLeft: string;
  pointsToVoucher: number;
  voucherAmount: number;
  maxLevelPercent: number;
}

export const maxPointsToVoucher = 2500;
export const pointsForMaxLevel = 15000;

const calculateMaxLevelPercent = (source: BonusInfoData) => {
  if (!source.totalPointsInCurrentPeriod) {
    return 0;
  }

  const points = parseInt(source.totalPointsInCurrentPeriod);
  if (!points) {
    return 0;
  }

  const percent = (points * 100) / pointsForMaxLevel;

  return limitValueWithinRange(percent, 0, 100);
};

export const mapMyBonusPoints = (source: BonusInfoData): BonusPoints => {
  const level = source.currentBonusLevelNumber || -1;

  return {
    level,
    levelEndDate: level > 1 ? source.currentBonusLevelEndDate : undefined,
    amountLeftToNextLevel:
      source.currentTierRegained && level < 4 ? source.currentBonusLevelAmountLeftToNextLevel : undefined,
    amountToStayOnCurrentLevel:
      !source.currentTierRegained && level > 1 ? source.bonusAmountNeededToStayOnCurrentLevel : undefined,
    totalPoints: source.totalPointsInCurrentPeriod || '',
    daysLeft: source.daysLeftOnCurrentPeriod?.toString() || '',
    pointsToVoucher: source.currentBonusVoucherPoints
      ? Math.ceil(maxPointsToVoucher - limitValueWithinRange(source.currentBonusVoucherPoints, 0, maxPointsToVoucher))
      : maxPointsToVoucher,
    voucherAmount: source.nextVoucherValue ? source.nextVoucherValue : 0,
    maxLevelPercent: calculateMaxLevelPercent(source),
  };
};
