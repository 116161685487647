import styled from 'styled-components';
import { rem } from 'polished';

interface BoxProps {
  $centerTextOnFreeThreshold?: boolean;
}

export const Box = styled.div<BoxProps>`
  display: grid;
  grid-gap: ${rem(8)};
  border: ${rem(2)} solid ${({ theme }) => theme.colors.colorPampas};
  justify-content: ${(props) => (props.$centerTextOnFreeThreshold ? 'center' : 'left')};
`;
