import { TimeSlotData } from '@api/generated/storefront';
import DELIVERY_METHODS from '@constants/deliveryMethods';
import { dateToHourAndMinutes, dateToShortNumeric } from '@helpers/date';
import { getCurrentDate, getCurrentTime } from '@helpers/getCurrentDateAndTime';
import { gtmDeliverySlots } from '@helpers/gtmDeliverySlots';
import TagManager from 'react-gtm-module';

export const getFormattedMethodName = (method: string) => {
  switch (method) {
    case DELIVERY_METHODS.HOME_CODE:
      return 'hem';
    case DELIVERY_METHODS.PICKUP_CODE:
      return 'c&c';
    default:
      return '';
  }
};

export const getFormattedSlotTimeForTrackers = (slot: TimeSlotData) => {
  const date = dateToShortNumeric(slot.startTime);
  const startTime = dateToHourAndMinutes(slot.startTime);
  const endTime = dateToHourAndMinutes(slot.endTime);

  return `${date} | ${startTime} - ${endTime}`;
};

export const slotsLoadedGA4 = (slots: TimeSlotData[], date: string) => {
  const viewedSlots = gtmDeliverySlots(slots);
  return TagManager.dataLayer({
    dataLayer: {
      event: 'delivery_slot_viewed',
      date: getCurrentDate(),
      delivery_slot_dt: date,
      delivery_slots: viewedSlots,
      sphere_name: 'delivery',
      time: getCurrentTime(),
    },
  });
};

// TOO BE REMOVED when GA3 is no longer active
export const slotsLoaded = (deliveryMethod: string, activeSlots: any[]) => {
  const mergedArrays = [].concat(...activeSlots);
  const numTotalSlots = mergedArrays.length;
  const numAvailableSlots = mergedArrays.filter((slot: any) => slot.available).length;
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: `delivery_modal | ${getFormattedMethodName(deliveryMethod)}`,
      action: `${numAvailableSlots}_of_${numTotalSlots}_slots_available`,
      label: `${numAvailableSlots}`,
      value: 0,
    },
  });
};

export const slotSelected = (deliveryMethod: string, slot: TimeSlotData) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: `delivery_modal | ${getFormattedMethodName(deliveryMethod)}`,
      action: `slot_selected`,
      label: getFormattedSlotTimeForTrackers(slot),
      value: 0,
    },
  });
  TagManager.dataLayer({
    dataLayer: {
      event: 'delivery_slot_chosen',
      date: getCurrentDate(),
      delivery_slot_dt: dateToShortNumeric(slot.startTime),
      delivery_slot_tm_start: dateToHourAndMinutes(slot.startTime),
      delivery_slot_tm_end: dateToHourAndMinutes(slot.endTime),
      sphere_name: 'delivery',
      time: getCurrentTime(),
    },
  });
};

export const setDeliveryMethod = (deliveryMethod: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'delivery_modal',
      action: 'delivery_method_set',
      label: getFormattedMethodName(deliveryMethod),
      value: 0,
    },
  });
};
export const changeDeliveryMethod = (deliveryMethod: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'delivery_modal',
      action: 'delivery_method_changed',
      label: getFormattedMethodName(deliveryMethod),
      value: 0,
    },
  });
};

export const setStore = (storeName: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'delivery_modal | c&c',
      action: 'store_chosen',
      label: storeName,
      value: 0,
    },
  });
};

export const changeStore = (storeName: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'delivery_modal | c&c',
      action: 'store_changed',
      label: storeName,
      value: 0,
    },
  });
};

export const postalCodeLookupInvalid = (postalCode: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'delivery_modal | hem',
      action: 'delivery_address_invalid',
      label: '',
      value: 0,
    },
  });
};
export const postalCodeSelected = (postalCode: string | undefined) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'delivery_modal | hem',
      action: 'delivery_address_selected',
      label: '',
      value: 0,
    },
  });
};

export const orderDeliveryTracker = (label: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'delivery_status_page',
      action: 'delivery_status_opened',
      label,
      value: 0,
    },
  });
};
