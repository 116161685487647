import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

interface ProductsBeamCarouselProps {
  $noHeaderSidePadding?: boolean;
  $border?: boolean;
}

export const Slide = styled.div`
  flex: 0 0 100%;
`;

export const CarouselWrapper = styled.div`
  display: flex;
`;

export const TextContainer = styled.div`
  order: 2;
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  text-align: center;

  ${media.tabletPortrait} {
    order: 1;
    justify-content: flex-start;
    text-align: left;
  }
`;
const CarouselAction = styled.div`
  display: flex;
  flex: 0 1 auto;
`;

export const IncrementSlideButton = styled(CarouselAction)`
  order: 3;
  > button {
    margin-left: 0;
  }
  ${media.tabletPortrait} {
    margin-right: ${rem(20)};
    > button {
      margin-left: 20px;
    }
  }
`;

export const DecrementSlideButton = styled(CarouselAction)`
  order: 1;
  > button {
    margin-left: 0;
  }
  ${media.tabletPortrait} {
    order: 2;
  }
`;
export const CarouselHeaderWrapper = styled.div<ProductsBeamCarouselProps>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 ${rem(5)} ${rem(15)};
  ${media.tabletPortrait} {
    font-size: ${rem(20)};
    padding: 0 0 ${rem(15)};
  }
  ${(props) =>
    props.$noHeaderSidePadding &&
    css`
      padding: ${rem(10)};
      ${media.tabletPortrait} {
        padding: ${rem(10)} 0 0;
      }
    `};
`;

export const CarouselHeader = styled.div<ProductsBeamCarouselProps>`
  order: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 1 auto;
  border: none;

  ${media.tabletPortrait} {
    order: 1;
  }

  ${(props) =>
    props.$border &&
    css`
      padding: 0;
      ${media.tabletPortrait} {
        border-right: 2px solid ${({ theme }) => theme.colors.colorIron};
      }
    `}
`;

export const HeadingWrapper = styled.h1`
  font-size: ${rem(26)};
  padding: 0;
  margin: 0;

  ${media.tabletPortrait} {
    font-size: ${rem(32)};
  }
`;
