import React from 'react';
import { Container, Indicator } from './DotPagination.styles';

export interface DotPaginationProps {
  activeIndex?: number;
  onClick: (idx: number) => void;
  numberOfDots: number;
}

const DotPagination = ({ activeIndex = 1, onClick, numberOfDots = 0 }: DotPaginationProps) => {
  if (!numberOfDots) return null;
  return (
    <Container data-testid="dot-pagination">
      {Array.from({ length: numberOfDots }, (x, idx) => (
        <li key={idx}>
          <Indicator type="button" onClick={() => onClick(idx)} $active={activeIndex === idx} data-testid="dot" />
        </li>
      ))}
    </Container>
  );
};

export default React.memo(DotPagination);
