import AccountMenuItem, { MenuLink } from '@components/molecules/AccountMenuItem/AccountMenuItem';
import LINKS from '@constants/links';
import { Fragment } from 'react';
import { Divider, List, ListItem, MenuContainer } from './AccountMenuPanel.styles';

interface AccountMenuPanelProps {
  links: MenuLink[];
  onLinkClicked?: () => void;
  'data-testid'?: string;
}

const AccountMenuPanel = ({ links, onLinkClicked, 'data-testid': dataTestId }: AccountMenuPanelProps) => {
  return (
    <MenuContainer>
      <List data-testid={dataTestId}>
        {links.map((link: MenuLink) => (
          <Fragment key={link.title}>
            <ListItem>
              {link.url === LINKS.LOGOUT && <Divider />}
              <AccountMenuItem link={link} onLinkClicked={onLinkClicked} />
            </ListItem>
          </Fragment>
        ))}
      </List>
    </MenuContainer>
  );
};

export default AccountMenuPanel;
