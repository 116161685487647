import Button from '@components/atoms/__DEPRECATED__/Button';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import media from '@constants/responsive';
import { getThemeColor } from '@styles/utils';
import Heading from 'components/atoms/__DEPRECATED__/Heading';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

/*
Padding applied to components on article pages and press-article pages
Can be applied to individual components or to multiple rows of
components stacked on top of eachother in a Cms ColumnComponent
*/
export const ArticleComponentPadding = css`
  padding: ${rem(30)} ${rem(10)};
  ${media.tabletPortrait} {
    padding: ${rem(40)} ${rem(85)};
  }
  ${media.desktop} {
    padding: ${rem(50)} ${rem(166)};
  }
`;

export const CmsComponentPadding = css`
  padding: 0 0 ${rem(10)} 0;
`;

/**
 * atoms with custom colors for cms components
 */

interface CustomColorProps {
  $customColor?: string;
  $customBackgroundColor?: string;
}

export const ButtonWithCustomColor = styled(Button)<CustomColorProps>`
  color: ${({ $customColor, theme }) => $customColor && getThemeColor($customColor, theme)};
  ${({ $customBackgroundColor, theme }) =>
    $customBackgroundColor &&
    css`
      background: ${getThemeColor($customBackgroundColor, theme)};
      &:hover {
        filter: brightness(90%);
        background: ${getThemeColor($customBackgroundColor, theme)};
      }
      &:focus {
        filter: brightness(80%);
        background: ${getThemeColor($customBackgroundColor, theme)};
      }
    `}
`;

export const ParagraphWithCustomColor = styled(Paragraph)<CustomColorProps>`
  color: ${({ $customColor, theme }) => $customColor && getThemeColor($customColor, theme)};
`;

export const HeadingWithCustomColor = styled(Heading)<CustomColorProps>`
  color: ${({ $customColor, theme }) => $customColor && getThemeColor($customColor, theme)};
`;

export const CmsPanel = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow: hidden;
  padding: 0;
  border-radius: ${rem(24)};

  ${media.tabletPortrait} {
    padding: ${rem(16)};
    border-radius: ${rem(32)};
  }

  ${media.desktopXXL} {
    padding: ${rem(32)};
  }
`;
