import Icon from '@components/atoms/Icon';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import IconNicotineMedical from '@public/icons/colorIcons/icon-nicotine-medical.svg';
import useTranslation from 'next-translate/useTranslation';
import { NicotineNotificationText, NicotineNotificationWrapper } from './CartNicotineNotification.styles';

const CartNicotineNotification = () => {
  const { t } = useTranslation('product');

  return (
    <NicotineNotificationWrapper data-testid="cart-nicotine-notification">
      <Icon size={34} icon={IconNicotineMedical} data-testid="icon-nicotine-medical" />
      <NicotineNotificationText>
        <Paragraph size="sm">{t('minicart:nicotineNotification->restriction')}</Paragraph>
      </NicotineNotificationText>
    </NicotineNotificationWrapper>
  );
};

export default CartNicotineNotification;
