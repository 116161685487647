import Price from '@components/atoms/Price';
import { PriceContainer, PriceWrapper } from './ProductPrice.styles';

export interface RelevantPriceProps {
  offline: boolean;
  priceValue: number; //product.price.unit
  hasPromotion: boolean;
  actualCustomerPrice: number; //product.price.actualCustomerPrice
  priceUnit: string;
  hasPrice: boolean; //price.hasPrice
  isMixAndMatchPercentage: boolean; //promotion.isMixAndMatch
  isPercentage: boolean; //promotion.isPercentage
  showPromotionPrice: boolean; //promotion.showPromotionPrice
}

interface ProductPriceProps {
  product: RelevantPriceProps;
  variant?: 'default' | 'detail' | 'horizontal';
  size?: 'large' | 'small';
  position?: 'left' | 'center' | 'right';
}

interface RenderPriceProps extends ProductPriceProps {
  hasPromotion: boolean;
}
const RenderPrice = ({ product, hasPromotion, variant = 'default', position, size }: RenderPriceProps) => (
  <PriceWrapper $variant={variant}>
    <Price
      position={position}
      price={product.actualCustomerPrice}
      hasPromotion={hasPromotion}
      unit={product.priceUnit}
      size={size}
    />
  </PriceWrapper>
);

const ProductPrice = ({ product, variant = 'default', size, position = 'right' }: ProductPriceProps) => {
  const { offline, isMixAndMatchPercentage, isPercentage, showPromotionPrice, priceValue, hasPrice, hasPromotion } =
    product;
  const excludedOfflinePromotions = offline && (isMixAndMatchPercentage || isPercentage);

  return (
    <PriceContainer $variant={variant}>
      {showPromotionPrice
        ? !!priceValue &&
          !excludedOfflinePromotions &&
          hasPrice && (
            <RenderPrice
              product={product}
              hasPromotion={hasPromotion}
              size={size}
              position={position}
              variant={variant}
            />
          )
        : !!priceValue && (
            <RenderPrice product={product} hasPromotion={false} size={size} position={position} variant={variant} />
          )}
    </PriceContainer>
  );
};

export default ProductPrice;
