import Icon from '@components/atoms/Icon';
import MessageModal from '@components/molecules/MessageModal';
import { WarningLabel } from '@components/molecules/Product/ProductDataMapper/types';
import IconAgeRestricted18NicotineSnuff from '@public/icons/colorIcons/icon-age-restricted-18-nicotine-snuff.svg';
import IconAgeRestricted18Tobacco from '@public/icons/colorIcons/icon-age-restricted-18-tobacco.svg';
import IconAlertCircle from '@public/icons/regularIcons/icon-alert-circle.svg';
import useTranslation from 'next-translate/useTranslation';

export interface ProductMessageModalProps {
  status: WarningLabel;
  setStatus: (setStatus: WarningLabel) => void;
}

const ProductMessageModal = ({ status, setStatus }: ProductMessageModalProps) => {
  const { t } = useTranslation('product');

  const title = (type?: WarningLabel) => {
    if (type === 'NOTSELLABLE' || type === 'GENERAL_ERROR') {
      return t('error->title');
    }
    return '';
  };

  const message = {
    NONE: 'NONE',
    NICOTINE: t('medicine->nicotine->restriction->info'),
    NOTSELLABLE: t('not_sellable'),
    GENERAL_ERROR: t('error->message'),
    TOBACCO: t('observeAgeRestriction18'),
    NICOTINE_SNUFF: t('observeAgeRestriction18'),
  };

  const icons = {
    NICOTINE: <Icon icon={IconAlertCircle} color="none" data-testid="icon-alert-circle" />,
    TOBACCO: <Icon icon={IconAgeRestricted18Tobacco} size={216} data-testid="icon-age-restricted-18-tobacco" />,
    NICOTINE_SNUFF: (
      <Icon icon={IconAgeRestricted18NicotineSnuff} size={216} data-testid="icon-age-restricted-18-nicotine-snuff" />
    ),
  } as Record<WarningLabel, JSX.Element>;

  const getIcon = (status: WarningLabel) => {
    return icons[status] || null;
  };

  return (
    <MessageModal
      icon={getIcon(status)}
      text={message[status]}
      title={title(status)}
      onConfirm={() => {
        setStatus('NONE');
      }}
      iconSize={status === 'TOBACCO' || status === 'NICOTINE_SNUFF' ? 216 : 34}
    />
  );
};

export default ProductMessageModal;
