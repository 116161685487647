export const reachedFreeServiceAmount = (
  subTotalPrice: number | null | undefined,
  slotFreePickingThresholdValue: number | null | undefined
) => {
  if (!slotFreePickingThresholdValue) {
    return false;
  }
  if (!subTotalPrice) {
    return false;
  }

  return subTotalPrice > slotFreePickingThresholdValue;
};

export const parseStringPriceValueToInt = (priceString: string) => {
  return parseInt(priceString.replace(/\s/g, ''), 10);
};
