import { CorrectedAxfoodCheckCustomerDTO } from '@api/PasswordResetApi';
import { LoginTabType } from '@features/login/constants';
import { OnboardingEntryPoint, PendingCompanyInfo } from '@features/onboarding/types';
import * as types from './types';

type SetPendingUsername = {
  type: typeof types.SET_PENDING_USERNAME;
  payload: string;
};

type SetPendingMaskedCustomer = {
  type: typeof types.SET_PENDING_MASKED_CUSTOMER;
  payload: CorrectedAxfoodCheckCustomerDTO;
};

type SetPendingCompanyInfo = {
  type: typeof types.SET_PENDING_COMPANY_INFO;
  payload: PendingCompanyInfo;
};

type ResetOnboardingState = {
  type: typeof types.RESET_ONBOARDING_STATE;
};

type SetPendingCreatePassword = {
  type: typeof types.SET_PENDING_COMPANY_CREATE_PASSWORD;
  payload: boolean;
};

type OnCreateB2BSuccess = {
  type: typeof types.ON_CREATE_B2B_SUCCESS;
  payload: boolean;
};

type SetOnboardingEntryPoint = {
  type: typeof types.SET_ONBOARDING_ENTRY_POINT;
  payload: OnboardingEntryPoint;
};

type SetOnboardingEntryIdMethod = {
  type: typeof types.SET_ONBOARDING_ENTRY_ID_METHOD;
  payload: LoginTabType;
};

export const setPendingUsername = (username: string): SetPendingUsername => ({
  type: types.SET_PENDING_USERNAME,
  payload: username,
});

export const setPendingMaskedCustomer = (
  maskedCustomerData: CorrectedAxfoodCheckCustomerDTO
): SetPendingMaskedCustomer => ({
  type: types.SET_PENDING_MASKED_CUSTOMER,
  payload: maskedCustomerData,
});

export const setPendingCompanyInfo = (companyInfo: PendingCompanyInfo): SetPendingCompanyInfo => ({
  type: types.SET_PENDING_COMPANY_INFO,
  payload: companyInfo,
});

export const resetOnboardingState = (): ResetOnboardingState => ({
  type: types.RESET_ONBOARDING_STATE,
});

export const setPendingCreatePassword = (create = false): SetPendingCreatePassword => ({
  type: types.SET_PENDING_COMPANY_CREATE_PASSWORD,
  payload: create,
});

export const onCreateB2BSuccess = (didApplyForCredit: boolean): OnCreateB2BSuccess => ({
  type: types.ON_CREATE_B2B_SUCCESS,
  payload: didApplyForCredit,
});

export const setOnboardingEntryPoint = (entryPoint: OnboardingEntryPoint): SetOnboardingEntryPoint => ({
  type: types.SET_ONBOARDING_ENTRY_POINT,
  payload: entryPoint,
});

export const setOnboardingEntryIdMethod = (method: LoginTabType): SetOnboardingEntryIdMethod => ({
  type: types.SET_ONBOARDING_ENTRY_ID_METHOD,
  payload: method,
});

export type OnboardingActionTypes =
  | SetPendingUsername
  | SetPendingMaskedCustomer
  | ResetOnboardingState
  | SetPendingCompanyInfo
  | SetPendingCreatePassword
  | OnCreateB2BSuccess
  | SetOnboardingEntryPoint
  | SetOnboardingEntryIdMethod;
