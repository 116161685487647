import { getCurrentDate, getCurrentTime } from '@helpers/getCurrentDateAndTime';
import TagManager from 'react-gtm-module';

export const linkClickTracker = (category: string, linkName: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category,
      action: 'navigation_clicked',
      label: linkName,
      value: 0,
    },
  });
};

export const horizontalBannerButtonTracker = (label: string, category: string, position?: number, url?: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'element_clicked',
      date: getCurrentDate(),
      element_type: 'hero_banner',
      slide_pstn: position,
      sphere_name: 'content',
      target: url,
      time: getCurrentTime(),
    },
  });
};

export const promoClickTracker = (label: string, typeCode: string, position: string, url?: string) => {
  // GA3 - NOT TO BE REMOVED
  TagManager.dataLayer({
    dataLayer: {
      event: 'promoClick',
      ecommerce: {
        promoClick: {
          promotions: [{ id: label, creative: typeCode, position }],
        },
      },
    },
  });
  // GA4 Tracking promoClickTracker
  const promoType = typeCode === 'hero_banner' ? typeCode : 'promo_banner';
  TagManager.dataLayer({
    dataLayer: {
      event: 'element_clicked',
      date: getCurrentDate(),
      element_type: promoType,
      slide_pstn: position === 'hero_banner' ? position : '',
      sphere_name: 'content',
      target: url,
      time: getCurrentTime(),
    },
  });
};

export const arrowClickTracker = (category: string, headline: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category,
      action: 'show_more_products',
      label: headline,
      value: 0,
    },
  });
};

export const identificationClickedTracker = (categoryName: string, label: string) => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: `${categoryName} | bar`,
      action: 'identification_clicked',
      label,
      value: 0,
    },
  });
};
