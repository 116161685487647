import { createContext, useContext, useMemo } from 'react';
import useAppRouter from './useAppRouter';

export const smartEditStorageKey = 'smartedit-sessions';

const toggleBodyClasses = (enable: boolean, uid: string, uuid: string, catalogVersion?: string) => {
  const componentIdRegex = /^[a-zA-Z0-9-_]+$/;
  const isValidId = componentIdRegex.test(uid);
  if (enable && isValidId) {
    document.body.classList.add(`smartedit-page-uid-${uid}`);
    document.body.classList.add(`smartedit-page-uuid-${uuid}`);
    document.body.classList.add(`smartedit-catalog-version-uuid-${catalogVersion}`);
  }
};

export const SmartEditContext = createContext<{ catalogVersionUuid?: string }>({});

const useSmartEdit = () => {
  const router = useAppRouter();
  const isSmartEditEnabled = useMemo(() => !!router.query.cmsTicketId, [router.query]);
  const catalogVersionUuid = useContext(SmartEditContext).catalogVersionUuid;

  return {
    catalogVersionUuid,
    isSmartEditEnabled,
    toggleBodyClasses,
  };
};

export default useSmartEdit;
