import getCategory from '@api/getCategory';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import { SIZE } from '@components/templates/__DEPRECATED__/CategoryPage/CategoryPage';
import { mapCategories } from '@helpers/productMapper';
import { useAppSelector } from '@hooks/redux';
import { selectAssortmentStoreId } from '@selectors/delivery';
import useSWRInfinite from 'swr/infinite';
import { FormattedCategoryData } from './types';

const getLastResult = (d: FormattedCategoryData[]) => (d && d.length > 0 ? d[d.length - 1] : undefined);

interface Key {
  fn: string;
  q?: string;
  categoryPath: string;
  page?: number;
  sort?: string;
  size: number;
  assortmentStoreId?: string;
}

const fetcher = (key: Key) => {
  const { q, categoryPath, page = 0, sort } = key;
  return getCategory({
    categoryPath: categoryPath,
    size: SIZE.DESKTOP,
    q,
    page,
    sort,
  });
};

interface useCategoryProps {
  categoryPath: string;
  q?: string;
  sort?: string;
}

const useCategory = ({ categoryPath, q, sort }: useCategoryProps) => {
  const assortmentStoreId = useAppSelector(selectAssortmentStoreId);

  const getKey = (page: number): Key => {
    return {
      fn: `/c/${categoryPath}`,
      categoryPath,
      size: SIZE.DESKTOP,
      page,
      q,
      sort,
      assortmentStoreId,
    };
  };

  const {
    data = [],
    setSize,
    mutate,
    isValidating,
    isLoading,
  } = useSWRInfinite(getKey, fetcher, {
    revalidateFirstPage: true,
  });

  const products: Product[] = data.filter((r) => r.formattedResults).flatMap((r) => r.formattedResults);

  const {
    pagination,
    facets,
    sorts,
    categoryInfo,
    superCategories,
    subCategories,
    restrictedProductTypes,
    breadcrumbs,
  } = getLastResult(data) || {};
  const {
    currentPage = 0,
    numberOfPages = 0,
    totalNumberOfResults = 0,
    allProductsInCategoriesCount,
  } = pagination || {};

  const hasMorePages = currentPage + 1 < numberOfPages;

  const loadMore = () => {
    if (hasMorePages) {
      try {
        setSize((s) => s + 1);
      } catch (e) {
        // do nothing
      }
    }
  };

  return {
    categoryInfo,
    products,
    facets,
    breadcrumbs,
    sorts,
    superCategories,
    subCategories: mapCategories(subCategories),
    restrictedProductTypes,
    hasMorePages,
    totalNumberOfResults,
    allProductsInCategoriesCount,
    loadMore,
    mutate,
    isValidating,
    isLoading,
  };
};

export default useCategory;
