import styled from 'styled-components';
import { rem } from 'polished';
import media from '@constants/responsive';

export const Container = styled.div``;

interface Props {
  $disablePadding?: boolean;
}

export const BeamsWrapper = styled.div<Props>`
  background-color: ${({ theme }) => theme.colors.colorCararra};
  padding: 0;
  ${({ $disablePadding }) =>
    !$disablePadding &&
    `
  padding: 0 ${rem(10)} ${rem(10)};
  ${media.tabletPortrait} {
    padding: ${rem(0)} ${rem(7)} ${rem(40)};
  }
  ${media.tabletLandscape} {
    padding: ${rem(0)} ${rem(54)} ${rem(40)};
  }
  ${media.desktop} {
    padding: ${rem(0)} ${rem(58)} ${rem(40)};
  }
  `}
`;

export const HeadingWrapper = styled.div`
  padding: ${rem(20)} ${rem(0)};
`;
