import IconButton from '@components/molecules/IconButton';
import IconArrowLeftFilled from '@public/icons/filledIcons/icon-arrow-left-filled.svg';
import IconArrowRightFilled from '@public/icons/filledIcons/icon-arrow-right-filled.svg';
import useTranslation from 'next-translate/useTranslation';
import { rem } from 'polished';
import styled from 'styled-components';

export const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: ${rem(15)};
  margin-bottom: ${rem(5)};

  svg {
    width: 32px;
    height: 32px;
  }
  button {
    padding: 0;
    margin: 0;
  }
`;

interface ReplaceableProductsProps {
  offset: number;
  decrementOffset: () => void;
  incrementOffset: () => void;
  amountOfProductsForCarousel: number;
}

const SlideButtons = ({
  decrementOffset,
  offset,
  incrementOffset,
  amountOfProductsForCarousel,
}: ReplaceableProductsProps) => {
  const { t } = useTranslation('common');
  if (amountOfProductsForCarousel > 1) {
    return (
      <ButtonGroup>
        <IconButton
          icon={IconArrowLeftFilled}
          iconSize={34}
          color="none"
          onClick={decrementOffset}
          disabled={offset === 0}
          data-testid="decrement-slide-button"
          aria-label={t('defaultActions->back')}
        />
        <IconButton
          icon={IconArrowRightFilled}
          iconSize={34}
          color="none"
          onClick={incrementOffset}
          disabled={offset + 1 >= amountOfProductsForCarousel}
          data-testid="increment-slide-button"
          aria-label={t('defaultActions->next')}
        />
      </ButtonGroup>
    );
  }
  return null;
};
export default SlideButtons;
