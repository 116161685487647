import { LoginModalActionTypes } from '@actions/login';
import {
  BankIdAuthStatusType,
  BankIdHintCodeType,
  LOGIN_OUTSTANDING_TRANSACTION,
  SELECT_SIGN_IN_METHOD,
} from '@constants/bankid';
import { BANK_ID_DEVICES, LoginAlertType } from '@features/login/constants';
import * as types from '../actions/login/types';

export interface LoginState {
  visible: boolean;
  b2b: boolean;
  withGuest: boolean;
  isLoginConfirm: boolean;
  currentStatus: BankIdAuthStatusType;
  currentHintCode: BankIdHintCodeType;
  qrData: string;
  bankIdDevice: BANK_ID_DEVICES;
  alert: LoginAlertType;
  showOnboardingInfo: boolean;
}

export const initialState: LoginState = {
  visible: false,
  b2b: false,
  withGuest: false,
  isLoginConfirm: false,
  showOnboardingInfo: false,
  currentStatus: SELECT_SIGN_IN_METHOD,
  currentHintCode: LOGIN_OUTSTANDING_TRANSACTION,
  qrData: '',
  bankIdDevice: '',
  alert: '',
};

const loginReducer = (state = initialState, action: LoginModalActionTypes): LoginState => {
  switch (action.type) {
    case types.OPEN_LOGIN_MODAL:
      return {
        ...state,
        visible: true,
        b2b: action.b2b,
        isLoginConfirm: action.isLoginConfirm,
      };
    case types.OPEN_LOGIN_MODAL_WITH_GUEST:
      return {
        ...state,
        visible: true,
        withGuest: true,
        b2b: action.b2b,
      };
    case types.CLOSE_LOGIN_MODAL:
      return initialState;

    case types.TOGGLE_CUSTOMER_TYPE:
      return {
        ...state,
        b2b: !state.b2b,
      };
    case types.SET_CURRENT_HINT_CODE:
      return {
        ...state,
        currentHintCode: action.payload,
      };
    case types.SET_CURRENT_STATUS:
      return {
        ...state,
        currentStatus: action.payload,
      };
    case types.SET_QR_DATA:
      return {
        ...state,
        qrData: action.payload,
      };
    case types.SET_BANK_ID_DEVICE_TYPE:
      return {
        ...state,
        bankIdDevice: action.payload,
      };

    case types.SET_LOGIN_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    case types.SET_SHOW_ONBOARDING_INFO:
      return {
        ...state,
        showOnboardingInfo: true,
      };
    default:
      return state;
  }
};

export default loginReducer;
