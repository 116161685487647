const SET_CART = 'UPDATE_CART';
const SET_CART_UPDATE_QUEUE_STATUS = 'SET_CART_UPDATE_QUEUE_STATUS';
const INCREMENT_ONGOING_CART_UPDATES = 'INCREMENT_ONGOING_CART_UPDATES';
const DECREMENT_ONGOING_CART_UPDATES = 'DECREMENT_ONGOING_CART_UPDATES';
const SET_CART_TOTAL_PRODUCT_AMOUNT = 'SET_CART_TOTAL_PRODUCTS_AMOUNT';
const SET_PERSISTED_CART = 'SET_PERSISTED_CART';
const SET_FIXED_CART_TOTAL_PRODUCT_AMOUNT = 'SET_FIXED_CART_TOTAL_PRODUCT_AMOUNT';

export {
  SET_CART,
  SET_CART_UPDATE_QUEUE_STATUS,
  SET_CART_TOTAL_PRODUCT_AMOUNT,
  SET_PERSISTED_CART,
  INCREMENT_ONGOING_CART_UPDATES,
  DECREMENT_ONGOING_CART_UPDATES,
  SET_FIXED_CART_TOTAL_PRODUCT_AMOUNT,
};
