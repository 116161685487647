import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import { Column } from '@components/cmsComponents/AxfoodFooterComponent/AxfoodFooterComponent.styles';
import { navigationClickTracker } from '@trackers';
import { ColumnHeader, List, ListItem, ListItemLink } from './FooterColumnLinks.styles';

interface FooterColumnLinksProps {
  columns: CmsNavigationNode[];
}

const FooterColumnLinks = ({ columns }: FooterColumnLinksProps) => {
  const getTarget = (isTargetOutside: boolean) => (isTargetOutside ? '_blank' : '_self');

  return (
    <>
      {columns.map((column) => (
        <Column key={column.uid}>
          <ColumnHeader text={column.title} data-testid="column-header" />
          <List>
            {column.links.map((link: CmsNavigationNodeLink) => (
              <ListItem key={link.url}>
                <Paragraph size="md">
                  <ListItemLink
                    variant="dark"
                    href={link.url}
                    external={link.external || getTarget(link.target) === '_blank'}
                    target={getTarget(link.target)}
                    underlined={false}
                    margin={false}
                    onClick={() => navigationClickTracker.linkClickTracker('footer', link.linkName[0]?.value)}
                  >
                    {link.linkName[0]?.value}
                  </ListItemLink>
                </Paragraph>
              </ListItem>
            ))}
          </List>
        </Column>
      ))}
    </>
  );
};

export default FooterColumnLinks;
