import { rem } from 'polished';
import styled, { DefaultTheme } from 'styled-components';
import Icon from '../Icon';

interface SpinnerProps {
  $scale?: number;
}

const handleColor = (color: ColorType, theme: DefaultTheme) => {
  switch (color) {
    case 'grey':
      return theme.colors.colorStack;
    case 'white':
      return theme.colors.colorLinen;
    case 'yellow':
      return theme.colors.colorAmber;
    case 'pink':
      return theme.colors.colorPrimaryPink;
    default:
      return theme.colors.colorAcadia;
  }
};

export const SvgWrapper = styled.div<SpinnerProps>`
  transform: ${(props) => (props.$scale ? `scale(${props.$scale})` : 'scale(1.0)')};
`;

export const StyledSpinner = styled(Icon)`
  height: ${rem(24)};
  svg {
    stroke: ${({ color, theme }) => handleColor(color as ColorType, theme)};
    ${({ theme }) => theme.animations.rotatorAnimation};
    & > *:last-child {
      ${({ theme }) => theme.animations.dashAnimation};
    }
  }
`;
