import { useCallback, useEffect, useState } from 'react';
import LinkButton from '@components/atoms/Button/LinkButton';
import Picture from '@components/atoms/Picture';
import SanitizedHtml from '@components/atoms/SanitizedHtml/SanitizedHtml';
import Link from '@components/atoms/__DEPRECATED__/Link';
import { trackPromoBannerClick, trackPromoBannerView } from '@features/promotions/tracking';
import useIntersection from '@hooks/useIntersection';
import IconArrowRight from '@public/icons/regularIcons/icon-arrow-right.svg';
import useTranslation from 'next-translate/useTranslation';
import { Background, Content, HeaderWrapper, IconWrapper, Section } from './AxfoodPromoResponsiveBannerComponent.styles';

export interface Props {
  component: AxfoodPromoResponsiveBannerComponentType;
  slotName?: string;
  slotPosition?: number;
}

const AxfoodPromoResponsiveBannerComponent = ({ component, slotName, slotPosition }: Props) => {
  const { t } = useTranslation('promotions');
  const {
    media,
    content,
    buttonEnabled,
    buttonText,
    urlLink = '',
    background = 'white',
    fontcolor,
    name = '',
    typeCode,
  } = component;
  const gtmPosition = slotName && typeof slotPosition === 'number' ? `${slotPosition + 1}` : '';
  const { desktop: desktopImage, tablet: tabletImage, mobile: mobileImage } = media || {};
  const defaultImage = [desktopImage || tabletImage || mobileImage].find(
    (imageObject) => imageObject && imageObject.url
  );
  const hasButton = buttonEnabled === 'true' && !!buttonText && !!urlLink;
  const isBannerClickable = !!urlLink && !hasButton;
  const [hasIntersected, setHasIntersected] = useState(false);

  const [containerRef, intersectionObject] = useIntersection<HTMLDivElement>();

  const promotionName = hasButton ? buttonText : name;

  const handleLinkClick = useCallback(() => {
    trackPromoBannerClick(urlLink, promotionName, gtmPosition, 'promo_banner');
  }, [name, typeCode, gtmPosition, urlLink]);

  const handleBannerIntersect = useCallback(() => {
    setHasIntersected(true);
    trackPromoBannerView(urlLink, promotionName, gtmPosition, 'promo_banner');
  }, [name, typeCode, gtmPosition]);

  useEffect(() => {
    !hasIntersected && intersectionObject?.isIntersecting && handleBannerIntersect();
  }, [containerRef, intersectionObject?.isIntersecting, handleBannerIntersect]);

  const shouldLoadEager = slotPosition !== undefined && slotPosition < 2;
  const buttonOnlyContent = !content;

  const banner = (
    <Section data-testid="AxfoodPromoResponsiveBannerComponent">
      <Background $backgroundColor={defaultImage ? 'transparent' : background} data-testid="background">
        {media && <Picture media={media} shouldLoadEager={shouldLoadEager} />}
      </Background>
      <Content $buttonOnlyContent={buttonOnlyContent}>
        {content && (
          <HeaderWrapper $fontcolor={fontcolor} data-testid="heading-wrapper">
            <SanitizedHtml data-testid="heading" content={content} />
          </HeaderWrapper>
        )}
        {hasButton && (
          <LinkButton onClick={handleLinkClick} href={urlLink} variant="secondary" aria-label={t('offers->goToOffer')}>
            {buttonText} <IconWrapper icon={IconArrowRight} color="black" />
          </LinkButton>
        )}
      </Content>
    </Section>
  );

  return (
    <div ref={containerRef}>
      {isBannerClickable ? (
        <Link
          href={urlLink as string}
          underlined={false}
          data-testid="container-link"
          aria-label={t('offers->goToOffer')}
          onClick={handleLinkClick}
        >
          {banner}
        </Link>
      ) : (
        banner
      )}
    </div>
  );
};

export default AxfoodPromoResponsiveBannerComponent;
