import cartActions, { QUEUE_STATUSES } from '@actions/cart';
import { Product, WarningLabel } from '@components/molecules/Product/ProductDataMapper/types';
import { MAX_QUANTITY_VALUE } from '@components/molecules/Quantity/Quantity';
import PICK_UNIT from '@constants/pickUnit';
import debounce from '@helpers/debounce';
import { clampQuantity } from '@helpers/quantity';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import useAddRemoveProducts from '@hooks/useAddRemoveProducts';
import useDebounce from '@hooks/useDebounce/useDebounce';
import { makeProductQuantityInCartSelector, productIsInCart } from '@selectors/cart';
import { selectActiveStoreId } from '@selectors/delivery';
import { cartTracker } from '@trackers';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

export const DEBOUNCE_TIME = 500;

export interface IuseProductQuantityWithCart {
  eventListName: string;
  usingRemoveProductModal?: boolean;
  product: Product;
}

const useProductQuantityWithCart = ({
  eventListName,
  usingRemoveProductModal = false,
  product,
}: IuseProductQuantityWithCart) => {
  const updatingStage = useRef(QUEUE_STATUSES.IDLE);
  const dispatch = useAppDispatch();
  const cartHelpers = useAddRemoveProducts();
  const [productQuantity, setProductQuantity] = useState<number | string>(0);
  const [lastSentProductValue, setLastSentProductValue] = useState(0);
  const [quantityFieldIsActive, setQuantityFieldIsActive] = useState(false);
  const activeStoreId = useAppSelector(selectActiveStoreId);
  const productQuantityInCart = useAppSelector(makeProductQuantityInCartSelector(product.code));
  const isProductInCart = useAppSelector(productIsInCart(product.code));
  const debouncedQuantity: number = useDebounce(+productQuantity, DEBOUNCE_TIME);
  const [modalState, setModalState] = useState<WarningLabel>('NONE');

  const onQuantityFieldBlur = () => {
    setQuantityFieldIsActive(false);
    const parsedQuantity = productQuantity === '' ? 0 : productQuantity;
    const valueWasChanged = parsedQuantity !== lastSentProductValue;
    // Sets the product quantity to zero if a non valid one was entered.
    if (!productQuantity) {
      setProductQuantity(0);
    }

    // Here we will disptach a redux quantity update if the following conditions are met:
    // - The parsedValue is not the same as the previous value,
    // - The component is not using the "removeProduct"-modal, or the parsedValue is not zero.
    //   (if the component is using the removeProductModal, then that will handle the update logic instead)
    const hasQuantity = typeof parsedQuantity === 'number' && parsedQuantity > 0;

    if ((!usingRemoveProductModal || hasQuantity) && valueWasChanged) {
      // eslint-disable-next-line fp/no-mutation
      updatingStage.current = QUEUE_STATUSES.QUEUED;
      dispatch(cartActions.setCartHasQueuedUpdate(QUEUE_STATUSES.QUEUED));
    }
  };

  const onQuantityFieldFocus = () => {
    setQuantityFieldIsActive(true);
    if (!productQuantity) {
      setProductQuantity('');
    }
  };

  const onQuantityFieldChange = (quantityField: ChangeEvent<HTMLInputElement>) => {
    const inputValue = quantityField.target.value;
    const newValue: string | number =
      inputValue === '' ? '' : clampQuantity(parseFloat(quantityField.target.value), product.incrementValue || 0);

    setProductQuantity(newValue);
  };

  const changeQuantityBy = (quantityChange: number) => {
    const newValue = clampQuantity(+productQuantity + quantityChange, product.incrementValue || 0);
    const isWeightBasedProduct =
      product.price.pickUnitName === PICK_UNIT.KILOGRAM && quantityChange < 1 && quantityChange > -1;

    if (newValue >= 0) {
      // eslint-disable-next-line fp/no-mutation
      updatingStage.current = QUEUE_STATUSES.QUEUED;
      dispatch(cartActions.setCartHasQueuedUpdate(QUEUE_STATUSES.QUEUED));
      if (isWeightBasedProduct) {
        if (!isProductInCart) {
          // when we add the weightbasedProduct to the cart we increment the cart product value by 1,
          // instead of using the "quantityChange" value, this is done so that we don't have the cart
          // display the cart count as a float (ex: 2.015)
          debounce(() => dispatch(cartActions.changeCartProductsCountBy(1)), DEBOUNCE_TIME);
        }
      } else {
        dispatch(cartActions.changeCartProductsCountBy(quantityChange));
      }
      setProductQuantity(newValue);
    }
  };

  useEffect(() => {
    const updateProductQuantity = async (quantity: number) => {
      const newQuantity = quantity ? Math.min(quantity, MAX_QUANTITY_VALUE) : 0;

      if (newQuantity <= 0 || !newQuantity) {
        setLastSentProductValue(0);
        // eslint-disable-next-line fp/no-mutation
        updatingStage.current = QUEUE_STATUSES.UPDATING;
        const { success } = await cartHelpers.postCartUpdate([
          {
            productCodePost: product.code,
            qty: 0,
            pickUnit: product.price.pickUnitName,
          },
        ]);
        // only report to GA on request success
        if (success) {
          cartTracker.removeProductFromCart(
            product.analytics.impressionObject,
            lastSentProductValue - newQuantity,
            eventListName
          );
        }
      } else if (newQuantity) {
        if (!isProductInCart) {
          setModalState(product.warningLabel);
        }

        // eslint-disable-next-line fp/no-mutation
        updatingStage.current = QUEUE_STATUSES.UPDATING;
        setLastSentProductValue(newQuantity);
        const { success, errorCode } = await cartHelpers.postCartUpdate([
          {
            productCodePost: product.code,
            qty: debouncedQuantity,
            pickUnit: product.price.pickUnitName,
          },
        ]);
        if (success) {
          cartTracker.addOrRemoveProductWithImpression(
            newQuantity,
            lastSentProductValue,
            product.analytics.impressionObject,
            eventListName
          );
        } else {
          if (errorCode === 'product.not.saleable.online') {
            setModalState('NOTSELLABLE');
            return;
          }
          setModalState('GENERAL_ERROR');
        }
      }
    };

    const updateFn = async () => {
      const quantityChangedAndInputNotActive = !quantityFieldIsActive && debouncedQuantity !== lastSentProductValue;
      const updatingStageIsNotIdle = updatingStage.current > QUEUE_STATUSES.IDLE;

      if (quantityChangedAndInputNotActive && updatingStageIsNotIdle) {
        await updateProductQuantity(debouncedQuantity);
        if (updatingStage.current !== QUEUE_STATUSES.QUEUED) {
          // if a new update has not been queued when the api call returns, set the updating status to idle
          // eslint-disable-next-line fp/no-mutation
          updatingStage.current = QUEUE_STATUSES.IDLE;
        }
      }
    };
    updateFn();
  }, [
    debouncedQuantity,
    quantityFieldIsActive,
    lastSentProductValue,
    product,
    eventListName,
    activeStoreId,
    productQuantityInCart,
  ]);

  useEffect(() => {
    if (updatingStage.current === QUEUE_STATUSES.IDLE && productQuantityInCart !== productQuantity) {
      if (!quantityFieldIsActive) {
        setProductQuantity(productQuantityInCart || 0);
        setLastSentProductValue(productQuantityInCart || 0);
      }
    }
  }, [productQuantityInCart, productQuantity, quantityFieldIsActive]);

  return {
    changeQuantityBy,
    onValueChange: onQuantityFieldChange,
    onFocus: onQuantityFieldFocus,
    onBlur: onQuantityFieldBlur,
    quantity: productQuantity as number,
    quantityFieldIsActive,
    modalState,
    setModalState,
  };
};

export default useProductQuantityWithCart;
