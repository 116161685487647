import styled, { css } from 'styled-components';
import { rem } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
`;

export const RectangleButton = styled.button`
  display: flex;
  color: white;
  flex: 1;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: ${rem('15px')};
  background: ${({ theme }) => theme.colors.colorPrimary};
  border: none;
  transition: background-color 0.5s ease;
  width: 100%;
  @media (hover: hover) {
    &:not(:disabled):hover {
      background-color: ${({ theme }) => theme.colors.colorCardinal};
      cursor: pointer;
    }
  }
  &:focus {
    background: ${({ theme }) => theme.colors.colorThunderbird};
  }
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;
      background-color: ${({ theme }) => theme.colors.colorCararra};
      color: black;
    `}
`;

export const CartNicotineNotificationWrapper = styled.div`
  padding: ${rem(24)} ${rem(70)};
`;
