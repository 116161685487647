import axios, { AxiosRequestConfig } from 'axios';

const addToShoppingList = (listId: string, productData: Array<ShoppingListProduct>, config?: AxiosRequestConfig) => {
  const entries = productData.map((p) => {
    const { entryType, pickUnit, productCode, promotionCode, freeTextProduct, quantity } = p;
    if (entryType === 'PROMOTION') {
      return { entryType, pickUnit, productCode, promotionCode, quantity };
    } else if (entryType === 'FREETEXT') {
      return { entryType, pickUnit, freeTextProduct, quantity };
    }
    return { entryType, pickUnit, productCode, quantity };
  });

  const data = {
    entries,
  };

  return axios.post(`/axfood/rest/user-wishlist/${listId}/add`, data, config);
};
export default addToShoppingList;
