import { rem } from 'polished';
import styled from 'styled-components';
import media from '@constants/responsive';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: ${rem(260)};
  overflow: hidden;
  display: flex;

  ${media.tabletPortrait} {
    height: ${rem(368)};
  }
`;

export const NavigationArrow = styled.div<{ $direction: 'left' | 'right' }>`
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  ${({ $direction }) => `${$direction}: ${rem(15)};`}
`;

export const IndicatorWrapper = styled.div`
  position: absolute;
  bottom: ${rem(10)};
  left: 50%;
  transform: translateX(-50%);

  ${media.tabletPortrait} {
    bottom: ${rem(40)};
  }
`;
