import { rem } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  height: ${rem(50)};
  background: none;
`;
