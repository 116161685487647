import { AxfoodCartProductViewModel, AxfoodProductDetailsViewModel } from '@api/generated/storefront';
import { ModalStatus } from '@hooks/__DEPRECATED__/useProductQuantityWithCart/useProductQuantityWithCart';

export const handleModalStatus = ({
  nicotineMedicalProduct,
  tobaccoProduct,
  tobaccoFreeNicotineProduct,
}: AxfoodCartProductViewModel | AxfoodProductDetailsViewModel): ModalStatus => {
  if (tobaccoFreeNicotineProduct) {
    return 'NICOTINE_SNUFF';
  }

  if (tobaccoProduct) {
    return 'TOBACCO';
  }

  if (nicotineMedicalProduct) {
    return 'NICOTINE';
  }

  return 'NONE';
};
