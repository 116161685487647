import mapHybrisProducts from '@components/molecules/Product/ProductDataMapper/productDataMapper';
import { CategoryApiData, FormattedCategoryData } from './types';

export const formatCategoryProducts = (data: CategoryApiData): FormattedCategoryData => {
  return {
    ...data,
    formattedResults: data.results ? mapHybrisProducts(data.results) : [],
    formattedRelatedResults: data.relatedResults ? mapHybrisProducts(data.relatedResults) : [],
  };
};
