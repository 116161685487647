import { AxfoodCustomerServiceContactForm, CustomerServiceApi } from '@api/generated/storefront';
import { CustomerServiceApi as UploadCustomerServiceApi } from '@api/generated/upload';

const customerServiceApi = new CustomerServiceApi(undefined, '');

export const getCustomerServiceReasons = async () => {
  const res = await customerServiceApi.getAllReasonsUsingGET();
  return res.data;
};

export const setCustomerContactForm = (model: AxfoodCustomerServiceContactForm, files: File[]) => {
  return new UploadCustomerServiceApi(undefined, '').handleIncomingRequestUsingPOST(model, files);
};
