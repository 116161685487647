import {
  getNonReplaceableProducts,
  getNotAllowedAnonymousProducts,
  getReplaceableProducts,
} from '@helpers/conflictingProducts';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import getState from './getState';

const selectConflictModalState = createSelector(getState, (state: RootState) => state.conflictModal || {});

export const selectConflictModalIsOpen = createSelector(selectConflictModalState, (state) => state.isOpen || false);

export const selectReplaceableProducts = createSelector(selectConflictModalState, (state) =>
  state.items ? getReplaceableProducts(state.items) : []
);

export const selectNonReplaceableProducts = createSelector(selectConflictModalState, (state) =>
  state.items ? getNonReplaceableProducts(state.items) : []
);
export const selectNotAllowedAnonymousProducts = createSelector(selectConflictModalState, (state) =>
  state.items ? getNotAllowedAnonymousProducts(state.items) : []
);

export const selectReasonTracker = createSelector(selectConflictModalState, (state) => state.reasonTracker);

export default selectConflictModalState;
