import { setOnboardingEntryPoint } from '@actions/onboarding';
import Link from '@components/atoms/__DEPRECATED__/Link';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import LINKS from '@constants/links';
import { ENTRY_POINT_CART, ENTRY_POINT_MINICART } from '@features/onboarding/constants';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import useRouteToLogin from '@hooks/useRouteToLogin';
import { selectCartLoyaltySaveValue } from '@selectors/cart';
import { navigationClickTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import React, { memo } from 'react';
import { Container, LoginLinks, LoyaltySaveValue } from './LoginOrSignupCallToAction.styles';

export interface LoginOrSignupCallToActionProps {
  trackingCategoryName: 'minicart' | 'cart';
  onLoginClick?: () => void;
}

const LoginOrSignupCallToAction: React.FC<LoginOrSignupCallToActionProps> = ({ trackingCategoryName, onLoginClick }) => {
  const { t } = useTranslation('minicart');
  const dispatch = useAppDispatch();
  const loyaltySaveValue = useAppSelector(selectCartLoyaltySaveValue);
  const { routeToLogin, currentPath } = useRouteToLogin();
  const loginCopy = t('minicart->links->login');
  const track = (e: LinkClickEvent) =>
    navigationClickTracker.identificationClickedTracker(trackingCategoryName, e.currentTarget?.innerText);

  const entryPoint = trackingCategoryName === ENTRY_POINT_MINICART ? ENTRY_POINT_MINICART : ENTRY_POINT_CART;
  const handleLoginClick = (e: LinkClickEvent) => {
    e.preventDefault(); // stop link route change
    !!onLoginClick && onLoginClick();
    dispatch(setOnboardingEntryPoint(entryPoint));
    routeToLogin({ b2b: false, isLoginConfirm: false });
    track(e);
  };

  const handleSignupClick = (e: LinkClickEvent) => {
    dispatch(setOnboardingEntryPoint(entryPoint));
    track(e);
  };

  return (
    <Container>
      {!!loyaltySaveValue && (
        <LoyaltySaveValue>
          <Paragraph size="xl">{t('minicart->loyaltySaveValue->save')}</Paragraph>
        </LoyaltySaveValue>
      )}
      <LoginLinks>
        <Paragraph size="md">
          <Link onClick={handleLoginClick} href={currentPath}>
            {loginCopy}
          </Link>
          {t('minicart->links->or')}
          <Link onClick={handleSignupClick} href={LINKS.REGISTER_B2C_DEPRECATED}>
            {t('minicart->links->becomeAMember')}
          </Link>
          {!!loyaltySaveValue && t('minicart->loyaltySaveValue->bonusOfferMessage')}
        </Paragraph>
      </LoginLinks>
    </Container>
  );
};

export default memo(LoginOrSignupCallToAction);
