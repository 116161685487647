/* tslint:disable */
/* eslint-disable */
/**
 * Commerce Webservices V2
 * Commerce Webservices Version 2
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ComponentAdaptedData } from '../cms-dto';
// @ts-ignore
import { ComponentIDListWsDTO } from '../cms-dto';
// @ts-ignore
import { ListAdaptedComponents } from '../cms-dto';
/**
 * ComponentApi - axios parameter creator
 * @export
 */
export const ComponentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Given a list of component identifiers, return cms component data.
         * @summary Find component data by id(s)
         * @param {string} baseSiteId Base site identifier
         * @param {Array<string>} [componentIds] List of Component identifiers
         * @param {string} [catalogCode] Catalog code
         * @param {string} [productCode] Product code
         * @param {string} [categoryCode] Category code
         * @param {string} [storeId] Store id
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {number} [currentPage] Optional pagination parameter. Default value 0.
         * @param {number} [pageSize] Optional pagination parameter. Default value 10.
         * @param {string} [sort] Optional sort criterion. No default value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findComponentsByIds: async (baseSiteId: string, componentIds?: Array<string>, catalogCode?: string, productCode?: string, categoryCode?: string, storeId?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', currentPage?: number, pageSize?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('findComponentsByIds', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/cms/components`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (componentIds) {
                localVarQueryParameter['componentIds'] = componentIds;
            }

            if (catalogCode !== undefined) {
                localVarQueryParameter['catalogCode'] = catalogCode;
            }

            if (productCode !== undefined) {
                localVarQueryParameter['productCode'] = productCode;
            }

            if (categoryCode !== undefined) {
                localVarQueryParameter['categoryCode'] = categoryCode;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Given a component identifier, return cms component data.
         * @summary Get component data
         * @param {string} componentId Component identifier
         * @param {string} baseSiteId Base site identifier
         * @param {string} [catalogCode] Catalog code
         * @param {string} [productCode] Product code
         * @param {string} [categoryCode] Category code
         * @param {string} [storeId] Store id
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentById: async (componentId: string, baseSiteId: string, catalogCode?: string, productCode?: string, categoryCode?: string, storeId?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'componentId' is not null or undefined
            assertParamExists('getComponentById', 'componentId', componentId)
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('getComponentById', 'baseSiteId', baseSiteId)
            const localVarPath = `/{baseSiteId}/cms/components/{componentId}`
                .replace(`{${"componentId"}}`, encodeURIComponent(String(componentId)))
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (catalogCode !== undefined) {
                localVarQueryParameter['catalogCode'] = catalogCode;
            }

            if (productCode !== undefined) {
                localVarQueryParameter['productCode'] = productCode;
            }

            if (categoryCode !== undefined) {
                localVarQueryParameter['categoryCode'] = categoryCode;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Given a list of component identifiers in body, return cms component data.
         * @summary Get components\' data by id given in body
         * @param {string} baseSiteId Base site identifier
         * @param {ComponentIDListWsDTO} componentIdList List of Component identifiers
         * @param {string} [catalogCode] Catalog code
         * @param {string} [productCode] Product code
         * @param {string} [categoryCode] Category code
         * @param {string} [storeId] Store id
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {number} [currentPage] Optional pagination parameter. Default value 0.
         * @param {number} [pageSize] Optional pagination parameter. Default value 10.
         * @param {string} [sort] Optional sort criterion. No default value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchComponentsByIds: async (baseSiteId: string, componentIdList: ComponentIDListWsDTO, catalogCode?: string, productCode?: string, categoryCode?: string, storeId?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', currentPage?: number, pageSize?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'baseSiteId' is not null or undefined
            assertParamExists('searchComponentsByIds', 'baseSiteId', baseSiteId)
            // verify required parameter 'componentIdList' is not null or undefined
            assertParamExists('searchComponentsByIds', 'componentIdList', componentIdList)
            const localVarPath = `/{baseSiteId}/cms/components`
                .replace(`{${"baseSiteId"}}`, encodeURIComponent(String(baseSiteId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_Password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_Password", [], configuration)

            // authentication oauth2_client_credentials required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_client_credentials", [], configuration)

            if (catalogCode !== undefined) {
                localVarQueryParameter['catalogCode'] = catalogCode;
            }

            if (productCode !== undefined) {
                localVarQueryParameter['productCode'] = productCode;
            }

            if (categoryCode !== undefined) {
                localVarQueryParameter['categoryCode'] = categoryCode;
            }

            if (storeId !== undefined) {
                localVarQueryParameter['storeId'] = storeId;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (currentPage !== undefined) {
                localVarQueryParameter['currentPage'] = currentPage;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(componentIdList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComponentApi - functional programming interface
 * @export
 */
export const ComponentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComponentApiAxiosParamCreator(configuration)
    return {
        /**
         * Given a list of component identifiers, return cms component data.
         * @summary Find component data by id(s)
         * @param {string} baseSiteId Base site identifier
         * @param {Array<string>} [componentIds] List of Component identifiers
         * @param {string} [catalogCode] Catalog code
         * @param {string} [productCode] Product code
         * @param {string} [categoryCode] Category code
         * @param {string} [storeId] Store id
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {number} [currentPage] Optional pagination parameter. Default value 0.
         * @param {number} [pageSize] Optional pagination parameter. Default value 10.
         * @param {string} [sort] Optional sort criterion. No default value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findComponentsByIds(baseSiteId: string, componentIds?: Array<string>, catalogCode?: string, productCode?: string, categoryCode?: string, storeId?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', currentPage?: number, pageSize?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAdaptedComponents>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findComponentsByIds(baseSiteId, componentIds, catalogCode, productCode, categoryCode, storeId, fields, currentPage, pageSize, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Given a component identifier, return cms component data.
         * @summary Get component data
         * @param {string} componentId Component identifier
         * @param {string} baseSiteId Base site identifier
         * @param {string} [catalogCode] Catalog code
         * @param {string} [productCode] Product code
         * @param {string} [categoryCode] Category code
         * @param {string} [storeId] Store id
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComponentById(componentId: string, baseSiteId: string, catalogCode?: string, productCode?: string, categoryCode?: string, storeId?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComponentAdaptedData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComponentById(componentId, baseSiteId, catalogCode, productCode, categoryCode, storeId, fields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Given a list of component identifiers in body, return cms component data.
         * @summary Get components\' data by id given in body
         * @param {string} baseSiteId Base site identifier
         * @param {ComponentIDListWsDTO} componentIdList List of Component identifiers
         * @param {string} [catalogCode] Catalog code
         * @param {string} [productCode] Product code
         * @param {string} [categoryCode] Category code
         * @param {string} [storeId] Store id
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {number} [currentPage] Optional pagination parameter. Default value 0.
         * @param {number} [pageSize] Optional pagination parameter. Default value 10.
         * @param {string} [sort] Optional sort criterion. No default value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchComponentsByIds(baseSiteId: string, componentIdList: ComponentIDListWsDTO, catalogCode?: string, productCode?: string, categoryCode?: string, storeId?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', currentPage?: number, pageSize?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListAdaptedComponents>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchComponentsByIds(baseSiteId, componentIdList, catalogCode, productCode, categoryCode, storeId, fields, currentPage, pageSize, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ComponentApi - factory interface
 * @export
 */
export const ComponentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComponentApiFp(configuration)
    return {
        /**
         * Given a list of component identifiers, return cms component data.
         * @summary Find component data by id(s)
         * @param {string} baseSiteId Base site identifier
         * @param {Array<string>} [componentIds] List of Component identifiers
         * @param {string} [catalogCode] Catalog code
         * @param {string} [productCode] Product code
         * @param {string} [categoryCode] Category code
         * @param {string} [storeId] Store id
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {number} [currentPage] Optional pagination parameter. Default value 0.
         * @param {number} [pageSize] Optional pagination parameter. Default value 10.
         * @param {string} [sort] Optional sort criterion. No default value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findComponentsByIds(baseSiteId: string, componentIds?: Array<string>, catalogCode?: string, productCode?: string, categoryCode?: string, storeId?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', currentPage?: number, pageSize?: number, sort?: string, options?: any): AxiosPromise<ListAdaptedComponents> {
            return localVarFp.findComponentsByIds(baseSiteId, componentIds, catalogCode, productCode, categoryCode, storeId, fields, currentPage, pageSize, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * Given a component identifier, return cms component data.
         * @summary Get component data
         * @param {string} componentId Component identifier
         * @param {string} baseSiteId Base site identifier
         * @param {string} [catalogCode] Catalog code
         * @param {string} [productCode] Product code
         * @param {string} [categoryCode] Category code
         * @param {string} [storeId] Store id
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComponentById(componentId: string, baseSiteId: string, catalogCode?: string, productCode?: string, categoryCode?: string, storeId?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: any): AxiosPromise<ComponentAdaptedData> {
            return localVarFp.getComponentById(componentId, baseSiteId, catalogCode, productCode, categoryCode, storeId, fields, options).then((request) => request(axios, basePath));
        },
        /**
         * Given a list of component identifiers in body, return cms component data.
         * @summary Get components\' data by id given in body
         * @param {string} baseSiteId Base site identifier
         * @param {ComponentIDListWsDTO} componentIdList List of Component identifiers
         * @param {string} [catalogCode] Catalog code
         * @param {string} [productCode] Product code
         * @param {string} [categoryCode] Category code
         * @param {string} [storeId] Store id
         * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
         * @param {number} [currentPage] Optional pagination parameter. Default value 0.
         * @param {number} [pageSize] Optional pagination parameter. Default value 10.
         * @param {string} [sort] Optional sort criterion. No default value.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchComponentsByIds(baseSiteId: string, componentIdList: ComponentIDListWsDTO, catalogCode?: string, productCode?: string, categoryCode?: string, storeId?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', currentPage?: number, pageSize?: number, sort?: string, options?: any): AxiosPromise<ListAdaptedComponents> {
            return localVarFp.searchComponentsByIds(baseSiteId, componentIdList, catalogCode, productCode, categoryCode, storeId, fields, currentPage, pageSize, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComponentApi - object-oriented interface
 * @export
 * @class ComponentApi
 * @extends {BaseAPI}
 */
export class ComponentApi extends BaseAPI {
    /**
     * Given a list of component identifiers, return cms component data.
     * @summary Find component data by id(s)
     * @param {string} baseSiteId Base site identifier
     * @param {Array<string>} [componentIds] List of Component identifiers
     * @param {string} [catalogCode] Catalog code
     * @param {string} [productCode] Product code
     * @param {string} [categoryCode] Category code
     * @param {string} [storeId] Store id
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
     * @param {number} [currentPage] Optional pagination parameter. Default value 0.
     * @param {number} [pageSize] Optional pagination parameter. Default value 10.
     * @param {string} [sort] Optional sort criterion. No default value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public findComponentsByIds(baseSiteId: string, componentIds?: Array<string>, catalogCode?: string, productCode?: string, categoryCode?: string, storeId?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', currentPage?: number, pageSize?: number, sort?: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).findComponentsByIds(baseSiteId, componentIds, catalogCode, productCode, categoryCode, storeId, fields, currentPage, pageSize, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Given a component identifier, return cms component data.
     * @summary Get component data
     * @param {string} componentId Component identifier
     * @param {string} baseSiteId Base site identifier
     * @param {string} [catalogCode] Catalog code
     * @param {string} [productCode] Product code
     * @param {string} [categoryCode] Category code
     * @param {string} [storeId] Store id
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public getComponentById(componentId: string, baseSiteId: string, catalogCode?: string, productCode?: string, categoryCode?: string, storeId?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).getComponentById(componentId, baseSiteId, catalogCode, productCode, categoryCode, storeId, fields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Given a list of component identifiers in body, return cms component data.
     * @summary Get components\' data by id given in body
     * @param {string} baseSiteId Base site identifier
     * @param {ComponentIDListWsDTO} componentIdList List of Component identifiers
     * @param {string} [catalogCode] Catalog code
     * @param {string} [productCode] Product code
     * @param {string} [categoryCode] Category code
     * @param {string} [storeId] Store id
     * @param {'BASIC' | 'DEFAULT' | 'FULL'} [fields] Response configuration (list of fields, which should be returned in response)
     * @param {number} [currentPage] Optional pagination parameter. Default value 0.
     * @param {number} [pageSize] Optional pagination parameter. Default value 10.
     * @param {string} [sort] Optional sort criterion. No default value.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentApi
     */
    public searchComponentsByIds(baseSiteId: string, componentIdList: ComponentIDListWsDTO, catalogCode?: string, productCode?: string, categoryCode?: string, storeId?: string, fields?: 'BASIC' | 'DEFAULT' | 'FULL', currentPage?: number, pageSize?: number, sort?: string, options?: AxiosRequestConfig) {
        return ComponentApiFp(this.configuration).searchComponentsByIds(baseSiteId, componentIdList, catalogCode, productCode, categoryCode, storeId, fields, currentPage, pageSize, sort, options).then((request) => request(this.axios, this.basePath));
    }
}
