import React from 'react';

export type PageContextType = {
  cmsPageId?: string;
};

/**
In this context we store stateless config specific to the page.
For stateful params, use redux
 */
const PageContext = React.createContext<PageContextType>({
  cmsPageId: undefined,
});

export default PageContext;
