import { setPaymentAuthStatus } from '@actions/checkout';
import { closeLoginModal } from '@actions/login';
import { PAYMENT_AUTH_STATUS_IS_SUCCESS } from '@features/checkout/constants';
import { useAppDispatch } from '@hooks/redux';
import useAppRouter from '@hooks/useAppRouter';
import { useCallback } from 'react';

const useHandleLoginClose = () => {
  const dispatch = useAppDispatch();
  const { push, pathname, query } = useAppRouter();

  const handleLoginClose = useCallback(
    async ({ loginConfirmSuccess }: { loginConfirmSuccess: boolean }) => {
      dispatch(closeLoginModal());
      if (loginConfirmSuccess) {
        dispatch(setPaymentAuthStatus(PAYMENT_AUTH_STATUS_IS_SUCCESS));
      }
      const { redirectAfterLogin, ...rest } = query;
      push({ pathname, query: rest }, undefined, { shallow: true, scroll: false });
    },
    [dispatch, push, pathname, query]
  );
  return handleLoginClose;
};

export default useHandleLoginClose;
