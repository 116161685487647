import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const productImageHeightMobile = rem(84);
export const productImageHeightDesktop = rem(104);

export const ProductImageSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${rem(24)} 1fr ${rem(24)};
  justify-items: center;
  height: ${productImageHeightMobile};
  margin-bottom: ${rem(6)};

  ${media.tabletPortrait} {
    height: ${productImageHeightDesktop};
    margin-bottom: ${rem(16)};
  }
`;

export const LabelsPosition = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PromotionSplashPosition = styled.div`
  position: absolute;
  z-index: 1;
  top: ${rem(16)};
  right: ${rem(16)};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${rem(4)};

  height: ${rem(110)};

  ${media.tabletPortrait} {
    height: ${rem(120)};
  }
`;

export const SplashBoxPosition = styled.div`
  z-index: 10;
`;

const imageHeight = css`
  width: ${productImageHeightMobile};
  height: ${productImageHeightMobile};

  ${media.tabletPortrait} {
    width: ${productImageHeightDesktop};
    height: ${productImageHeightDesktop};
  }
`;

export const ImageContainer = styled.div`
  object-fit: contain;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ${imageHeight}
`;
