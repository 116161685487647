import ProductsBeamCarousel from '@components/molecules/ProductsBeamCarousel';
import BeamsHeading from '../BeamsHeading';
import useRelatedProductsBeam from './useRelatedProductsBeam';

interface RelatedProductsBeamProps {
  eventListName: string;
  productCode: string;
  displayType: string;
  title: string;
}
const RelatedProductsBeam = ({ eventListName, productCode, displayType, title }: RelatedProductsBeamProps) => {
  const { products } = useRelatedProductsBeam(productCode, displayType);

  if (!products.length) {
    return null;
  }

  return (
    <ProductsBeamCarousel
      title={title}
      headline="headline"
      carouselProducts={products}
      eventListName={eventListName}
      noHeaderSidePadding
      isLoading={false}
      headingComponent={<BeamsHeading title={title} />}
    />
  );
};
export default RelatedProductsBeam;
