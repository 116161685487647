import { PageWithSlots } from '@api';
import LINKS, { URLPREFIXES } from '@constants/links';
import PageContext from '@context/PageContext';
import useAppRouter from '@hooks/useAppRouter';
import useCmsPage from '@hooks/useCmsPage';
import { useContext } from 'react';

const hasNavigationLeftContentSlot = (typeCode: string, cmsPage?: PageWithSlots): boolean => {
  const slotName = 'NavigationLeft';
  if (!cmsPage) return false;
  return cmsPage.slots.some(
    (slot) =>
      (slot.position === slotName || slot.name === slotName) &&
      slot.components?.component?.some((component) => component.typeCode === typeCode)
  );
};

const forceSideNavOnPaths = [LINKS.SEARCH];
const forceSideNavOnUrlPrefixes = [URLPREFIXES.CATEGORIES, `/beta${URLPREFIXES.CATEGORIES}`]; //TODO: Remove beta prefix when beta is removed

const useForceSideNav = () => {
  const { pathname } = useAppRouter();
  return (
    forceSideNavOnPaths.includes(pathname) || forceSideNavOnUrlPrefixes.some((prefix) => pathname.startsWith(prefix))
  );
};

const usePageHasSideNav = () => {
  const { cmsPageId } = useContext(PageContext);
  const { cmsPage } = useCmsPage(cmsPageId);
  const forceShowSideNav = useForceSideNav();
  const isCmsPageWithSideNav = hasNavigationLeftContentSlot('LeftMenuComponent', cmsPage);
  const isArticlePageWithSideNav = hasNavigationLeftContentSlot('ShowLeftNavComponent', cmsPage);

  return forceShowSideNav || isCmsPageWithSideNav || isArticlePageWithSideNav;
};

export default usePageHasSideNav;
