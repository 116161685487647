import AccountMenuItem from '@components/molecules/AccountMenuItem';
import { rem } from 'polished';
import styled from 'styled-components';

export const AccountTakeoverMenuItem = styled(AccountMenuItem)`
  height: ${rem(50)};
  padding: ${rem(4)} ${rem(16)};
`;

export const HeaderSlotContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  min-height: ${rem(60)};
`;

export const HeaderSlotCloseButton = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  cursor: pointer;
  display: flex;
  &:hover {
    background: transparent;
  }
`;

export const StyledHr = styled.hr`
  margin: ${rem(8)} ${rem(16)} ${rem(8)};
  border-color: ${({ theme }) => theme.colors.colorSilver};
  border-style: solid;
`;
