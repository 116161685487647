import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${rem(240)}, 1fr));
  grid-auto-rows: ${rem(282)};
  grid-gap: ${rem(9)};
  ${media.tabletPortrait} {
    grid-auto-rows: ${rem(378)};
  }
`;
