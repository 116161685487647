import Icon from '@components/atoms/Icon';
import Spinner from '@components/atoms/Spinner';
import Text from '@components/atoms/Text';
import IconButton from '@components/molecules/IconButton';
import useResponsive from '@hooks/useResponsive';
import IconCloseFilled from '@public/icons/filledIcons/icon-close-filled.svg';
import IconPlusFilled from '@public/icons/filledIcons/icon-plus-filled.svg';
import IconCheckCircle from '@public/icons/regularIcons/icon-check-circle.svg';
import IconCloseThin from '@public/icons/regularIcons/icon-close-thin.svg';
import LockScrollStyle from '@styles/lockScroll';
import useTranslation from 'next-translate/useTranslation';
import React, { useState } from 'react';
import {
  AddShoppingListButton,
  AddToShoppingListText,
  Body,
  CloseButton,
  CloseButtonWrapper,
  Footer,
  Header,
  Input,
  InputWrapper,
  Popover,
  ShoppingListItem,
  ShoppingLists,
  SpinnerWrapper,
} from './AddToShoppingList.styles';
import useShoppingLists from './useShoppingLists';

export interface Props {
  onClose: () => void;
  topPosition?: number;
  addToList: (listId: string) => Promise<void>;
  addToNewList: (listName: string) => Promise<void>;
}

// time in ms in which the popover will be visible after adding products to a list
export const CLOSE_DELAY = 500;

const AddToShoppingList = ({ onClose, topPosition = 0, addToList, addToNewList }: Props) => {
  const { lists, isRefreshingLists, refreshLists } = useShoppingLists();
  const { t } = useTranslation('mylists');
  const newListPlaceholder = t('mylists->newList');
  const [lastClickedList, setLastClickedList] = useState<string | undefined>(undefined);
  const [newListName, setNewListName] = useState<string>('');
  const { isTabletPortraitOrGreater } = useResponsive();

  const onClickClose = () => onClose();

  const handleItemClick = async (id: string | undefined): Promise<void> => {
    setLastClickedList(id);
    if (id !== undefined) {
      try {
        await addToList(id);
      } catch (e) {
        setLastClickedList(undefined);
      } finally {
        setTimeout(onClose, CLOSE_DELAY);
      }
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setNewListName(event.target.value);
  };

  const saveNewList = async (): Promise<void> => {
    if (newListName.length > 0) {
      try {
        await addToNewList(newListName);
        await refreshLists();
      } catch (e) {
        // no sideeffects in component
      } finally {
        setNewListName('');
        setTimeout(onClose, CLOSE_DELAY);
      }
    }
  };

  const handleOnKeyDown = async (event: React.KeyboardEvent): Promise<void> => {
    if (event.key === 'Enter') {
      await saveNewList();
    }
  };

  return (
    <Popover $topPosition={topPosition > 0 ? +topPosition : 0} data-testid="add-to-shopping-list-popover">
      {!isTabletPortraitOrGreater && <LockScrollStyle />}
      <Header>
        <AddToShoppingListText>{t('mylists->addToList')}</AddToShoppingListText>
        <CloseButtonWrapper>
          <CloseButton
            color={isTabletPortraitOrGreater ? 'black' : 'grey-light'}
            icon={isTabletPortraitOrGreater ? IconCloseThin : IconCloseFilled}
            iconSize={isTabletPortraitOrGreater ? 30 : 40}
            onClick={onClickClose}
            data-testid="add-to-list-close-menu-button"
            title="close"
          />
        </CloseButtonWrapper>
      </Header>
      <Body>
        {isRefreshingLists ? (
          <SpinnerWrapper>
            <Spinner color="red" />
          </SpinnerWrapper>
        ) : (
          <ShoppingLists>
            {lists?.map((i) => (
              <ShoppingListItem key={i.id}>
                <AddShoppingListButton
                  $selected={lastClickedList === i.id}
                  onClick={() => handleItemClick(i.id)}
                  disabled={lastClickedList !== undefined}
                  variant="transparent"
                  data-testid="add-list-button"
                >
                  <Text type="p" size="md">
                    {i.name}
                  </Text>
                  {lastClickedList === i.id ? <Icon icon={IconCheckCircle} data-testid="icon-check-circle" /> : ''}
                </AddShoppingListButton>
              </ShoppingListItem>
            ))}
          </ShoppingLists>
        )}
      </Body>
      <Footer>
        <InputWrapper>
          <Input
            type="text"
            id="list-search"
            name="list-search"
            placeholder={newListPlaceholder}
            value={newListName}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
          />
          <IconButton
            icon={IconPlusFilled}
            onClick={saveNewList}
            color={newListName.length === 0 ? 'grey' : 'red'}
            disabled={newListName.length === 0}
            title={t('mylists->saveList')}
            data-testid="icon-plus-filled"
            aria-label={t('mylists->saveList')}
          />
        </InputWrapper>
      </Footer>
    </Popover>
  );
};

export default AddToShoppingList;
