import { AxfoodPromotionViewModel } from '@api/generated/storefront';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import { getPromotionPriceFromRewardLabel } from '@helpers/promotions';
import {
  PromotionSplashPriceText,
  PromotionSplashPriceWrapper,
  PromotionSplashSavePrice,
  PromotionSplashSavePriceTail,
} from './PromotionSplash.styles';

interface Props<Type> {
  product: Product;
}

interface ProductViewModel {
  potentialPromotions: Array<AxfoodPromotionViewModel>;
  priceNoUnit?: string;
  priceUnit?: string;
}

interface xForYOfferProps {
  conditionLabel: string;
  hasSavePriceLabel: boolean;
  promotionPrice: string;
  price: string;
  promotionTail: string;
}
interface percentageOfferProps {
  promotionPrice: string;
  promotionSymbol: string;
  promotionTail: string;
}
interface generalLowerPriceOfferProps {
  promotionCompactPrice: string | undefined;
  unit: string;
}
interface giftOfferProps {
  rewardLabel: AxfoodPromotionViewModel['rewardLabel'];
}

const XForYOffer = ({ conditionLabel, hasSavePriceLabel, promotionPrice, price, promotionTail }: xForYOfferProps) => {
  return (
    <PromotionSplashSavePrice>
      <PromotionSplashPriceText>{conditionLabel}</PromotionSplashPriceText>
      <span data-testid="price">{hasSavePriceLabel ? promotionPrice : price}</span>
      <PromotionSplashSavePriceTail>{promotionTail}</PromotionSplashSavePriceTail>
    </PromotionSplashSavePrice>
  );
};
const PercentageOffer = ({ promotionPrice, promotionSymbol, promotionTail }: percentageOfferProps) => {
  return (
    <PromotionSplashSavePrice>
      <PromotionSplashPriceText>{`${promotionPrice} ${promotionSymbol}`}</PromotionSplashPriceText>
      <span data-testid="price-tail">{promotionTail}</span>
    </PromotionSplashSavePrice>
  );
};
const GeneralLowerPriceOffer = ({ promotionCompactPrice, unit }: generalLowerPriceOfferProps) => {
  return (
    <PromotionSplashSavePrice>
      <PromotionSplashPriceText>{promotionCompactPrice}</PromotionSplashPriceText>
      <span data-testid="price-unit">{`/${unit}`}</span>
    </PromotionSplashSavePrice>
  );
};
const GiftOffer = ({ rewardLabel }: giftOfferProps) => {
  return (
    <PromotionSplashSavePrice>
      <span>{rewardLabel}</span>
    </PromotionSplashSavePrice>
  );
};

const PromotionSplashPrice = <Type extends ProductViewModel>({ product }: Props<Type>) => {
  const {
    price: { priceNoUnit: price, unit },
    promotion,
    inactivePromotion,
  } = product;

  const { rewardLabel, conditionLabelFormatted, conditionLabel } = promotion || inactivePromotion || {};

  const hasSavePriceLabel = !!inactivePromotion?.conditionLabel?.length || !!promotion?.conditionLabel?.length;

  const { promotionPrice, promotionTail, promotionCompactPrice, promotionSymbol } =
    getPromotionPriceFromRewardLabel(rewardLabel);

  const isXForYOffer = hasSavePriceLabel;
  const isPercentageOffer = promotionSymbol === '%';
  const isGiftOffer = !hasSavePriceLabel && promotionSymbol !== '%' && !promotionPrice;
  const isGeneralLowerPriceOffer = !hasSavePriceLabel && promotionSymbol !== '%' && promotionPrice;
  const conditionLabelText = conditionLabelFormatted || conditionLabel;

  return (
    <PromotionSplashPriceWrapper>
      {isXForYOffer && !!conditionLabelText && (
        <XForYOffer
          conditionLabel={conditionLabelText}
          hasSavePriceLabel={hasSavePriceLabel}
          promotionPrice={promotionPrice}
          price={price || ''}
          promotionTail={promotionTail}
        />
      )}
      {isPercentageOffer && (
        <PercentageOffer
          promotionPrice={promotionPrice}
          promotionSymbol={promotionSymbol}
          promotionTail={promotionTail}
        />
      )}
      {isGeneralLowerPriceOffer && (
        <GeneralLowerPriceOffer promotionCompactPrice={promotionCompactPrice} unit={unit || ''} />
      )}
      {isGiftOffer && <GiftOffer rewardLabel={promotion?.rewardLabel || inactivePromotion?.rewardLabel} />}
    </PromotionSplashPriceWrapper>
  );
};
export default PromotionSplashPrice;
