import Heading from '@components/atoms/Heading';
import Paragraph from '@components/atoms/Paragraph';
import Skeleton from '@components/atoms/Skeleton';
import useCmsComponentProduct from '@hooks/useCmsComponentProduct';

import IntersectionProductWrapper from '@components/cmsComponents/AxfoodProductResponsiveBannerComponent/IntersectionProductWrapper';
import { Container, Content } from './AxfoodProductResponsiveBannerComponent.styles';

export interface AxfoodProductResponsiveBannerComponentProps {
  component: AxfoodProductResponsiveBannerComponentType;
}
const AxfoodProductResponsiveBannerComponent = ({ component }: AxfoodProductResponsiveBannerComponentProps) => {
  const { productCode, title, preamble, showSplash, backgroundColor = 'LIGHT_PINK', secondaryProductCode } = component;

  const { data: product, isValidating } = useCmsComponentProduct(productCode);
  const { data: secondaryProduct, isValidating: isSecondaryProductValidating } =
    useCmsComponentProduct(secondaryProductCode);

  if (isValidating || isSecondaryProductValidating) {
    return (
      <Skeleton
        type="rect"
        height={400}
        $style={{ margin: '0' }}
        data-testid="axfood-product-responsive-banner-skeleton"
      />
    );
  }
  const hasProduct = !!productCode && !!product?.saleableOnline;
  const hasSecondaryProduct = !!secondaryProductCode && !!secondaryProduct?.saleableOnline;
  const hasNoProducts = !hasProduct && !hasSecondaryProduct;

  if (hasNoProducts) {
    return null;
  }

  return (
    <Container data-testid="container" $bgColor={backgroundColor}>
      <Content data-testid="content">
        <Heading size="small" type="h2">
          {title}
        </Heading>
        <Paragraph size="large">{preamble}</Paragraph>
      </Content>
      <IntersectionProductWrapper
        hasSplash={showSplash === 'true'}
        firstProduct={product}
        secondProduct={secondaryProduct}
      />
    </Container>
  );
};

export default AxfoodProductResponsiveBannerComponent;
