export const HOME_CODE: DeliveryModeCodeType = 'homeDelivery';
export const PICKUP_CODE: DeliveryModeCodeType = 'pickUpInStore';

// maps against deliveryMethod
export const DELIVERY_METHOD_PICKUP: DeliveryMethodsType = 'delivery.select.pickup';
export const DELIVERY_METHOD_HOME: DeliveryMethodsType = 'delivery.select.home';

// maps against deliveryModeCode
const DELIVERY_METHODS: { HOME_CODE: typeof HOME_CODE; PICKUP_CODE: typeof PICKUP_CODE } = {
  HOME_CODE,
  PICKUP_CODE,
};
/** TODO: rename this -> DELIVERY_MODES */
export type DeliveryMethodType = typeof HOME_CODE | typeof PICKUP_CODE; // TODO: rename -> DeliveryModes
export default DELIVERY_METHODS;
