import { CustomerApi, RegisterB2bCustomerForm, RegisterCustomerForm } from '@api/generated/storefront';
import { mapMyBonusPoints } from '@features/mypages/MyMembership/components/MyBonusPointsSection/mapMyBonusPoints';
import { CompleteMembershipPayload } from '@features/onboarding/types';
import encrypt from '@helpers/encrypt';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

const customerApi = new CustomerApi(undefined, '');

/**
 * CustomerApi
 */

export const getCustomer = async (options?: AxiosRequestConfig) => {
  const { data: customer } = await customerApi.getSessionInformationUsingGET(options);

  const isAnonymous = customer?.uid === 'anonymous';
  const isAnyB2BCustomer = customer?.isB2BCustomer || customer?.isLocalB2BCustomer || false;
  const fullName =
    !!customer.firstName && !!customer.lastName ? `${customer.firstName} ${customer.lastName}` : undefined;

  const getB2bCustomerCreditLimit = () => {
    if (!customer?.isB2BCustomer || customer?.isLocalB2BCustomer) {
      return false;
    }
    return typeof customer.creditInformation?.creditLimit !== 'number' || customer.creditInformation?.creditLimit <= 1;
  };

  const hasLocalB2BDiscount =
    !!customer?.localCustomerAgreement?.percentage && customer.localCustomerAgreement?.percentage > 0;

  const axCRMFailure = customer.bonusInfo === null;

  const points = !!customer.bonusInfo ? mapMyBonusPoints(customer.bonusInfo) : undefined;

  return {
    ...customer,
    customerId: customer.uid || 'anonymous',
    bonusInfo: customer.bonusInfo || null,
    isAnonymous,
    isLoggedIn: !isAnonymous,
    isAnyB2BCustomer,
    isB2CCustomer: !isAnonymous && !isAnyB2BCustomer,
    hasDigitalReceiptEnabled: !!(customer.email && customer?.sendDigitalReceipt),
    fullName,
    b2bCustomerHasLowCreditLimit: getB2bCustomerCreditLimit(),
    hasInvoice: !!customer.isAllowedCreditPayment,
    hasLocalB2BDiscount,
    creditInformation: customer.creditInformation || undefined, // guard against null
    axCRMFailure,
    points,
  };
};

export const getBonusBenefitInfo = () => {
  return customerApi.getBonusBenefitInfoUsingGET();
};

export const deleteCustomerSavedCard = (id: string, options?: AxiosRequestConfig) => {
  return customerApi.deleteAgreementUsingDELETE(id, options);
};

export const verifyPhone = async (phone: string, user: string, options?: AxiosRequestConfig) => {
  const { key, str } = await encrypt(user);
  const payload = {
    encryptedUserName: str,
    userNameCipherKey: key,
    phone,
  };
  const { data } = await customerApi.verifyPhoneUsingPOST(payload, options);
  return data;
};

export const setCompleteMembership = (payload: CompleteMembershipPayload) => {
  return customerApi.completeMembershipUsingPOST(payload);
};

export const setRegisterCustomer = (payload: RegisterCustomerForm, autoLogin: boolean, options?: AxiosRequestConfig) => {
  return customerApi.registerCustomerUsingPOST(payload, autoLogin, options) as Promise<
    AxiosResponse<{ completed: boolean; customerName: string }>
  >;
};

export const lookUpB2b = (payload: { registrationNumber: string }, options?: AxiosRequestConfig) =>
  customerApi.lookupB2BCustomerUsingPOST(payload, options);

export const registerB2BCustomer = (form: RegisterB2bCustomerForm) =>
  customerApi.registerB2BCustomerUsingPOST(form) as Promise<AxiosResponse<{ completed: boolean; sapId: string }>>;

export const registerAdditionalSubAccount = (email: string, socialSecurityNumber: string) => {
  return customerApi.registerAdditionalUsingPOST(email, socialSecurityNumber) as Promise<
    // TODO: Update swagger with response type
    AxiosResponse<{
      completed: boolean;
      name: string;
      hasAccount?: boolean;
      'error-socialSecurityNumber'?: string;
    }>
  >;
};
