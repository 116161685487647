import { useEffect, useRef } from 'react';
import { ProductGridContainer } from './ProductGrid.styles';

export type Props = {
  children?: any;
  passRef?: (ref: any) => void;
  gap?: string;
};

const ProductGrid = ({ gap, children, passRef }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (passRef) passRef(ref.current);
  }, [passRef]);

  return (
    <ProductGridContainer $gap={gap} ref={ref} data-testid="grid-container">
      {children}
    </ProductGridContainer>
  );
};

export default ProductGrid;
