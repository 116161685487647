import IconAgeRestricted18NicotineSnuff from '@public/icons/colorIcons/icon-age-restricted-18-nicotine-snuff.svg';
import IconAgeRestricted18Tobacco from '@public/icons/colorIcons/icon-age-restricted-18-tobacco.svg';
import IconNonPrescriptionMedicine from '@public/icons/colorIcons/icon-medicine-label.svg';
import IconNicotineMedicine from '@public/icons/colorIcons/icon-nicotine-medical.svg';

const CATEGORY_INFO = {
  TOBACCO: {
    translationKey: 'ageRestrictionDisclaimer',
    icon: IconAgeRestricted18Tobacco,
  },
  NICOTINE_SNUFF: {
    translationKey: 'ageRestrictionDisclaimer',
    icon: IconAgeRestricted18NicotineSnuff,
  },
  MEDICINE: {
    translationKey: 'nonPrescriptionMedicineDisclaimer',
    icon: IconNonPrescriptionMedicine,
  },
  NICOTINE_MEDICINE: {
    translationKey: 'nicotineMedicineDisclaimer',
    icon: IconNicotineMedicine,
  },
};

export default CATEGORY_INFO;
