import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const arrowUpFilled = '/icons/colorIcons/icon-filled-arrow-up.svg';

export const PromotionUsedText = styled.div`
  background: ${({ theme }) => theme.colors.colorPampas};
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: ${rem(158)};
  opacity: 1;
  height: ${rem(40)};
  ${media.tabletPortrait} {
    height: ${rem(43)};
    top: ${rem(242)};
  }
`;

export const CardHeight = css`
  height: ${rem(322)};

  ${media.tabletPortrait} {
    height: ${rem(350)};
  }
`;

export const CardBase = css`
  ${CardHeight};
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 0;
  background: ${({ theme }) => theme.colors.colorWhite};
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  position: relative;
  border-radius: ${rem(16)};
  box-shadow: ${({ theme }) => theme.shadows.default};
  align-items: space-between;
`;

export const ProductWrapper = styled.div<{ $isPromotionUsed: boolean }>`
  ${CardBase};
  padding: ${rem(16)};
  ${({ $isPromotionUsed }) =>
    $isPromotionUsed &&
    css`
      > *:not(${PromotionUsedText}) {
        opacity: 0.4;
      }
    `};
`;

export const ProductQuantityWrapper = styled.div`
  height: 100%;
  justify-self: flex-end;
`;
