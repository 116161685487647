import { ContentSlotWsDTO, PageAdaptedData } from '@api/generated/cms/cms-dto';
import { ComponentApi as CmsComponentApi } from '@api/generated/cms/cms/component-api';
import { PageApi } from '@api/generated/cms/cms/page-api';
import {
  AddRecipeReplacementProducts,
  AddRecipeToCartRequestBody,
  AxfoodBasicProductViewModel,
  AxfoodOfflineProductViewModel,
  BankIdForm,
  CheckoutApi,
  ComponentApi,
  CustomerApi,
  DeliveryApi,
  FacetSearchPageData,
  FeatureApi,
  Loop54SearchWrapperData,
  OrderApi,
  PersonalElementsApi,
  PostalApi,
  ProductApi,
  PromotionApi,
  ReactTranslationData,
  RecipeApi,
  RemoveRecipeFromCartRequestBody,
  SessionApi,
  SiteApi,
  SlotApi,
  StoreApi,
  VoucherApi,
  WishListApi,
  WishlistForm,
} from '@api/generated/storefront';
import mapHybrisProducts from '@components/molecules/Product/ProductDataMapper/productDataMapper';
import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import { BANK_ID_SIGN_STATUS } from '@constants/bankid';
import { AddressFormValues } from '@features/checkout/components/DeliveryAddressSection/components/EditDeliveryAddress';
import { VALID_PAYMENT_MODE_TYPE } from '@features/checkout/constants';
import { OrderData, mapOrderData } from '@features/mypages/OrderDetailPage/helpers/mapOrderData';
import getSlotsForPage from '@helpers/cms/getSlotsForPage';
import encrypt from '@helpers/encrypt';
import { getMapBounderies, getStoreMarkers } from '@helpers/locationHelper';
import type { AxfoodCartEntryStatusViewModel } from '@occ/api-client';
import { AxiosRequestConfig, AxiosResponse, CancelToken } from 'axios';
import Config from 'config';

/**
 * featureApi
 */

const featureApi = new FeatureApi(undefined, '');

export const getFeatureFlags = async (options?: AxiosRequestConfig) => (await featureApi.featuresUsingGET(options)).data;

/**
 * PromotionApi
 */
const promotionApi = new PromotionApi(undefined, '');
export const getCampaigns = async (page = 0, size = 30, cancelToken: CancelToken) => {
  const { data } = await promotionApi.getPromotedProductsUsingGET(page, size, { cancelToken });
  return {
    ...data,
    results: data?.results?.length ? mapHybrisProducts(data.results) : [],
  };
};

export const getPromotedProducts = async (page?: number, size?: number, options?: AxiosRequestConfig) => {
  const promotions = (await promotionApi.getPromotedProductsUsingGET(page, size, options)).data;
  const formattedProducts = mapHybrisProducts(promotions.results) || [];
  return { ...promotions, formattedProducts };
};

export const getPromotionPreview = (
  q?: string,
  fields?: Array<string>,
  page?: number,
  size?: number,
  dateFrom?: number,
  dateTo?: number
) => promotionApi.getOfflineCampaignsPreviewUsingGET(q, fields, page, size, dateFrom, dateTo);

// TODO: remove typecasting
type FacetSearchDataWithoutResults = Omit<FacetSearchPageData, 'results'>;

export interface CorrectedFacetSearchPageData extends FacetSearchDataWithoutResults {
  results: (AxfoodBasicProductViewModel | AxfoodOfflineProductViewModel)[];
}

export const getMixedCampaigns = async (
  q: string,
  type?: string,
  size?: number,
  onlineSize?: number,
  offlineSize?: number,
  options?: AxiosRequestConfig
) => {
  const { data } = await (promotionApi.getMixedCampaignsUsingGET(
    q,
    type,
    size,
    onlineSize,
    offlineSize,
    options
  ) as Promise<AxiosResponse<CorrectedFacetSearchPageData>>);

  return {
    ...data,
    results: data.results.length ? mapHybrisProducts(data.results) : [],
  };
};

interface PromotionProductsParams {
  promotionCode: string;
  excludeProducts?: Array<string>;
  page?: number;
  pageSize?: number;
}

export const getPromotionProducts = async (params: PromotionProductsParams, options?: AxiosRequestConfig) => {
  const { promotionCode, excludeProducts, page, pageSize } = params;
  const result = await promotionApi.getPromotionProductsUsingGET(
    promotionCode,
    excludeProducts,
    page,
    pageSize,
    options
  );
  return { products: !!result?.data?.items?.length ? mapHybrisProducts(result.data.items) : [] };
};

/**
 * ProductApi
 */
const productApi = new ProductApi(undefined, '');
export const getProduct = (productCode: string, cancelToken?: CancelToken) =>
  productApi.getProductUsingGET1(productCode, { cancelToken });

export const searchProducts = async (query: string) => {
  const result = await productApi.getSearchResultsAsYouTypeUsingGET(query, undefined, 12);
  return result.data;
};

interface MarketingProductsParams {
  productCode: string;
  displayType: string;
  page?: number;
  pageSize?: number;
}

export const getMarketingProducts = async (params: MarketingProductsParams, options?: AxiosRequestConfig) => {
  const { productCode, displayType, page, pageSize } = params;
  const result = await productApi.getProductRecommendationUsingGET(productCode, displayType, page, pageSize, options);
  return { products: !!result?.data?.items?.length ? mapHybrisProducts(result.data.items) : [] };
};

export type MultiSearchResults = Loop54SearchWrapperData;

export const multiSearch = async (
  query: string,
  page: number,
  size: number,
  sort?: string,
  options?: AxiosRequestConfig
): Promise<MultiSearchResults> => {
  const result = await productApi.getMultiSearchResultsUsingGET(query, page, size, 'Page', sort, undefined, options);
  return result.data;
};

export const multiSearchAsYouType = async (query: string, page: number, size: number) => {
  const result = await productApi.getMultiSearchResultsAsYouTypeUsingGET(query, page, size);
  return result.data;
};

/**
 * PostalApi
 */
const postalApi = new PostalApi(undefined, '');

// TODO: Type assertions are reported in B2C-20479
export const validateAddress = (
  street?: string,
  postalcode?: string,
  locality?: string,
  suggestions?: string,
  options?: AxiosRequestConfig
) => {
  return postalApi.getValidatedAddressUsingGET(street, postalcode, locality, suggestions, options) as unknown as Promise<
    AxiosResponse<GepositAddressResponse>
  >;
};

export const isDeliveryAvailableForAddress = ({ street }: AddressFormValues) => {
  return postalApi.isDeliveryAvailableForAddressLineUsingGET(street);
};

/**
 * DeliveryApi
 */

const deliveryApi = new DeliveryApi(undefined, '');

export const postalCodeDeliverablity = (postalCode: string, isB2B?: boolean, options?: AxiosRequestConfig) => {
  return deliveryApi.getDeliverabilityUsingGET(postalCode, isB2B, options);
};

export const getOrderTrackingStatus = (trackingId: string, options?: AxiosRequestConfig) => {
  return deliveryApi.getOrderTrackingStatusUsingGET(trackingId, options);
};

export const getOrderTrackingStatusSSR = async (trackingId: string, api: DeliveryApi, options: AxiosRequestConfig) =>
  api.getOrderTrackingStatusUsingGET(trackingId, options);

/**
 * SlotApi
 */

const slotApi = new SlotApi(undefined, '');

export const currentSlotAvailableForPostalCode = (postalCode: string) => {
  return slotApi.isCurrentSlotAvailableForPostalCodeUsingGET(postalCode);
};

export const removeSlotFromCart = () => slotApi.removeSlotFromCartUsingDELETE();

export const getDeliveryHomeSlots = (
  fromDate: string,
  nrOfDays: number,
  postalCode?: string,
  isB2BCustomer?: boolean,
  cancelToken?: CancelToken
) => slotApi.getHomeDeliverySlotsForDayUsingGET(fromDate, nrOfDays, postalCode, isB2BCustomer, { cancelToken });

export const getDeliveryPickupSlots = (
  fromDate: string,
  nrOfDays: number,
  storeId: string,
  isB2BCustomer?: boolean,
  cancelToken?: CancelToken
) => slotApi.getPickInStoreSlotsForDayUsingGET(fromDate, nrOfDays, storeId, isB2BCustomer, { cancelToken });

/**
 * CheckoutApi
 */
const checkoutApi = new CheckoutApi(undefined, '');

export const getPaymentModes = (options?: AxiosRequestConfig) => {
  return checkoutApi.getValidPaymentModesForUserUsingGET(options) as Promise<AxiosResponse<VALID_PAYMENT_MODE_TYPE[]>>;
};

export const setPaymentMode = (mode: VALID_PAYMENT_MODE_TYPE, options?: AxiosRequestConfig) => {
  return checkoutApi.setPaymentModeUsingPOST({ mode }, options);
};

export const createKlarnaSession = (options?: AxiosRequestConfig) => {
  return checkoutApi.createSessionUsingGET(options) as Promise<AxiosResponse<KlarnaSession>>;
};

export const placeOrder = (data: PlaceOrderData, options?: AxiosRequestConfig) => {
  const { orderRef, saveCard, selectedCard, klarnaAuthorizationToken, userClickedContinue = false } = data;
  return checkoutApi.simplifiedPlaceOrderUsingPOST(
    saveCard,
    selectedCard,
    orderRef,
    klarnaAuthorizationToken,
    userClickedContinue,
    options
  );
};

export const bankIdSign = (data: BankIdForm, options?: AxiosRequestConfig) => {
  return checkoutApi.bankIdSignUsingPOST(data, options);
};

export const bankIdCollect = (orderRef: string, options?: AxiosRequestConfig) => {
  return checkoutApi.bankIdCollectUsingPOST({ orderRef }, options) as Promise<
    AxiosResponse<{ hintCode: string; ssn: string; status: BANK_ID_SIGN_STATUS }>
  >;
};

export const bankIdAuth = (data: BankIdForm, options?: AxiosRequestConfig) => {
  return checkoutApi.bankIdAuthUsingPOST(data, options);
};

export const bankIdLoginCollect = (orderRef: string, options?: AxiosRequestConfig) => {
  return checkoutApi.bankIdCollectLoginUsingPOST({ orderRef }, options);
};

export const bankIdQrRefresh = (options?: AxiosRequestConfig) => {
  return checkoutApi.bankIdQrDataUsingPOST(options);
};
export const bankIdCancel = (orderRef: string, options?: AxiosRequestConfig) =>
  checkoutApi.bankCancelUsingPOST({ orderRef }, options);

export const initializeCheckout = async (options?: AxiosRequestConfig) => {
  const response = await checkoutApi.initializeCheckoutUsingGET(options);
  return response.data as unknown as {
    emptyCart: boolean;
    reason?: string;
    cartStatus?: AxfoodCartEntryStatusViewModel[];
  };
};

/**
 * OrderApi
 */
const orderApi = new OrderApi(undefined, '');

export const getOrderDeprecated = (orderCode: string, options?: AxiosRequestConfig) => {
  return orderApi.getFullOrderDataUsingGET(orderCode, options);
};

export const getOrder = async (orderCode: string, options?: AxiosRequestConfig): Promise<OrderData> => {
  const { data: order } = await orderApi.getFullOrderDataUsingGET(orderCode, options);
  return mapOrderData(order);
};

export const getFullOrderStatusSSR = async (orderId: string, api: OrderApi, options: AxiosRequestConfig) =>
  api.getFullOrderDataUsingGET(orderId, options);

export const isOrderProcessFinished = (orderCode: string, options?: AxiosRequestConfig) => {
  return orderApi.isOrderProcessFinishedUsingGET(orderCode, options);
};

export const setCancelOrder = (orderCode: string, options?: AxiosRequestConfig) => {
  return orderApi.cancelOrderUsingPOST(orderCode, options);
};

export const validateOrder = async (orderCode: string) => {
  const res = await orderApi.validateOrderEntriesUsingGET(orderCode);
  return res.data;
};

/**
 * RecipeApi
 */
const recipeApi = new RecipeApi(undefined, '');

export const removeCartRecipe = (recipe: RemoveRecipeFromCartRequestBody) => {
  return recipeApi.removeRecipeFromCartUsingPOST(recipe);
};

export const getRecipe = (recipeId: string, options?: AxiosRequestConfig) => {
  return recipeApi.getRecipeUsingGET(recipeId, options);
};

export const getRecipeSSR = async (recipeId: string, api: RecipeApi, options?: AxiosRequestConfig) =>
  api.getRecipeUsingGET(recipeId, options);

export const getRecipePreview = (recipeId: string, options?: AxiosRequestConfig) => {
  return recipeApi.getRecipeUsingGET1(recipeId, options);
};

export const getRecipePreviewSSR = async (recipeId: string, api: RecipeApi, options?: AxiosRequestConfig) => {
  return api.getRecipeUsingGET1(recipeId, options);
};

export const searchRecipe = (
  q: string,
  sort: string,
  page?: number,
  size?: number,
  show?: 'Page' | 'All',
  options?: AxiosRequestConfig
) => recipeApi.getSearchResultsUsingGET(q, sort, page, size, show, options);

export const searchRecipeSSR = async (
  api: RecipeApi,
  q: string,
  sort: string,
  page?: number,
  size?: number,
  show?: 'Page' | 'All',
  options?: AxiosRequestConfig
) => api.getSearchResultsUsingGET(q, sort, page, size, show, options);

export const changePortions = async (portions: number, recipeId: string) => {
  const { data } = await recipeApi.changePortionsUsingPATCH({ portions, recipeId });
  return data;
};

export const removeEntryFromRecipeCart = async (productCode: string, recipeId: string) => {
  // TODO: Empty body data added since BE needs a body, do we need an API fix?
  const { data } = await recipeApi.removeEntryFromRecipeCartUsingPOST(productCode, recipeId, { data: {} });
  return data;
};

export const addEntryToRecipeCart = async (productCode: string, recipeId: string) => {
  // TODO: Empty body data added since BE needs a body, do we need an API fix?
  const { data } = await recipeApi.addEntryToRecipeCartUsingPOST(productCode, recipeId, { data: {} });
  return data;
};
export const addRecipeToCart = (recipe: AddRecipeToCartRequestBody, options?: AxiosRequestConfig) =>
  recipeApi.addRecipeToCartUsingPOST(recipe, options);

export const findRecipeReplacementProducts = async (
  recipeId: string,
  productCode: string,
  options?: AxiosRequestConfig
) => recipeApi.findRecipeReplacementProductsUsingGET(recipeId, productCode, options);

export const addReplacementProducts = (body: AddRecipeReplacementProducts, options?: AxiosRequestConfig) =>
  recipeApi.addReplacementProductUsingPOST(body, options);

/**
 * StoreApi
 */
const storeApi = new StoreApi(undefined, '');

export const searchStore = (
  q: string,
  sort: string,
  page?: number,
  size?: number,
  show?: 'Page' | 'All',
  searchHasClickAndCollect?: boolean,
  searchHasB2bClickAndCollect?: boolean,
  scanAndGo?: boolean,
  externalPickupLocation?: boolean
) =>
  storeApi.getStoreUsingGET(
    q,
    sort,
    page,
    size,
    show,
    searchHasClickAndCollect,
    searchHasB2bClickAndCollect,
    scanAndGo,
    externalPickupLocation
  );

export const getStoreInformation = (storeId: string) => storeApi.getStoreInformationUsingGET1(storeId);

export interface MapBounderies {
  maxLng: number;
  minLng: number;
  maxLat: number;
  minLat: number;
}
export const getAvailableStores = async (
  online = false,
  clickAndCollect?: boolean,
  b2bClickAndCollect?: boolean,
  options?: AxiosRequestConfig
) => {
  const response = await storeApi.getStoresUsingGET(online, clickAndCollect, b2bClickAndCollect, options);

  const storesWithFlyer = response.data?.filter((store) => !!store.flyerURL && !!store.name);
  const physicalStores = response.data?.filter((s) => !!s.name) || [];
  const storeMarkers = getStoreMarkers(physicalStores);
  const mapBounderies = getMapBounderies(storeMarkers);

  return { storesWithFlyer, mapBounderies, storeMarkers };
};
/**
 * ComponentApi
 */
const componentApi = new ComponentApi(undefined, '');

export const getArticles = async (type: string, page?: number, size?: number, options: AxiosRequestConfig = {}) => {
  const res = await componentApi.getArticlesUsingGET(type, page, size, options);
  return res.data;
};

const WEB_DEVICE_TYPE = 'OTHER';

export const getCategoryNavigationTree = async (storeId: string | undefined, options: AxiosRequestConfig = {}) => {
  const res = await componentApi.getCategoryNavigationTreeUsingGET(storeId, WEB_DEVICE_TYPE, options);
  return res.data;
};

interface ProductBannerParams {
  componentId: string;
  page?: string;
  size?: string;
  promotionStoreUid?: string;
  q?: string;
  sort?: string;
}

export declare type ProductBannerComponentPaginatedDataType = {
  items: AxfoodBasicProductViewModel[];
  page: number;
  pageSize: number;
  totalPages: number;
  links?: unknown;
};

export declare type ProductBannerComponentType = {
  title?: string;
  emptyProductListBody?: string;
  emptyProductListTitle?: string;
  paginationData?: ProductBannerComponentPaginatedDataType;
  componentNotFound?: string;
};

export interface ProductBannerReturnType extends ProductBannerComponentType {
  formattedProducts: Product[];
}
export const getProductsForProductBanner = async (
  params: ProductBannerParams,
  options?: AxiosRequestConfig
): Promise<ProductBannerReturnType> => {
  const { componentId, page, size = '999', promotionStoreUid, q, sort } = params;

  const res = await componentApi.getProductsForProductBannerUsingGET(
    componentId,
    page,
    size,
    promotionStoreUid,
    q,
    sort,
    options
  );
  const data = res.data as ProductBannerComponentType;
  const formattedProducts = data?.paginationData?.items?.length ? mapHybrisProducts(data?.paginationData?.items) : [];
  return {
    ...data,
    formattedProducts,
  };
};

/**
 * CustomerApi
 */
const customerApi = new CustomerApi(undefined, '');

export const verifyEmail = async (email: string, user: string, options: AxiosRequestConfig = {}) => {
  const { key, str } = await encrypt(user);
  const form = {
    encryptedUserName: str,
    userNameCipherKey: key,
    email,
  };
  const {
    data: { status },
  } = await customerApi.verifyEmailUsingPOST(form, options);
  return status;
};

/**
 * WishlistApi
 */
const wishlistApi = new WishListApi(undefined, '');

export const getWishlists = async (
  basic?: boolean,
  sorting?: 'LAST_UPDATED_DESC' | 'NAME_ASC' | 'NAME_DESC',
  options?: AxiosRequestConfig
) => {
  return wishlistApi.getUserWishListsUsingGET(basic, sorting, options);
};

export const createWishlist = async (wishlistForm: WishlistForm) => {
  const {
    data: { id },
  } = await wishlistApi.createWishlistUsingPOST(wishlistForm);
  return id;
};

export const updateWishList = async (id: string, wishListForm: WishlistForm, options?: AxiosRequestConfig) =>
  (await wishlistApi.updateWishlistUsingPOST(id, wishListForm, options)).data;

export const getUserWishListById = (listId: string, options?: AxiosRequestConfig) => {
  return wishlistApi.getUserWishlistUsingGET(listId, options);
};

export const deleteWishList = (id: string, options?: AxiosRequestConfig) =>
  wishlistApi.deleteUserWishListUsingDELETE(id, options);

export const updateWishListSorting = (id: string, wishListForm: WishlistForm, options?: AxiosRequestConfig) =>
  wishlistApi.updateWishlistSortingUsingPUT(id, wishListForm, options);

export const getListStatus = async (listId: string) => (await wishlistApi.validateOrderEntriesUsingGET1(listId)).data;

export const getWishListEntriesStatus = async (wishlistForm: WishlistForm, options?: AxiosRequestConfig) =>
  (await wishlistApi.validateWishlistEntriesUsingPOST(wishlistForm, options)).data;

export const wishListAddToCart = async (id: string, wishListForm: WishlistForm, options?: AxiosRequestConfig) =>
  (await wishlistApi.addProductsToCartUsingPOST(id, wishListForm, options)).data;
export const deleteProductFromShoppingList = async (id: string, productCode: string, options?: AxiosRequestConfig) =>
  (await wishlistApi.removeEntryFromUserWishlistUsingDELETE(id, productCode, options)).data;

export const checkEntriesInWishList = async (id: string, wishlistForm: WishlistForm) =>
  (await wishlistApi.checkEntriesInUserWishlistUsingPUT(id, wishlistForm)).data;
/**
 * PersonalElementsApi
 */
const personalElementsApi = new PersonalElementsApi(undefined, '');

/**
 * Replaces /axfood/rest/allexternalvouchers
 * Currently only used by Willys? B2C-22209
 */
export const getPersonalElements = () => personalElementsApi.getPersonalElementUsingGET();

/**
 * PageApi
 */
const pageApi = new PageApi(undefined, '/axfoodcommercewebservices/v2');

export interface PageWithSlots extends PageAdaptedData {
  slots: ContentSlotWsDTO[];
  catalogVersionUuid?: string;
}

export interface Params {
  label?: string;
  cmsTicketId?: string;
  pageType?: 'ContentPage' | 'ProductPage' | 'CategoryPage' | 'CatalogPage';
  code?: string;
  config?: AxiosRequestConfig;
}

export type PageType = 'ContentPage' | 'ProductPage' | 'CategoryPage' | 'CatalogPage';

const narrowPageType = (pageType: string) => {
  const validPageTypes = ['ContentPage', 'ProductPage', 'CategoryPage'];
  if (validPageTypes.includes(pageType)) {
    return pageType as PageType;
  } else {
    throw new Error('Invalid page type');
  }
};

export const getPage = async ({ label, cmsTicketId, pageType = 'ContentPage', code = '', config = {} }: Params) => {
  const response = await pageApi.getPage(
    Config.SITE_NAME,
    narrowPageType(pageType),
    label,
    code,
    'DEFAULT',
    cmsTicketId,
    config
  );
  const filteredSlots = getSlotsForPage(response.data);
  const partialData = response.data as Partial<PageAdaptedData>;
  return {
    ...partialData,
    breadcrumbs: response.data.breadcrumbs,
    contentSlots: response.data.contentSlots, // deprecated, use slots instead
    slots: filteredSlots,
  } as PageWithSlots;
};

export interface getPageSSRParams {
  api: PageApi;
  pageType: PageType;
  label?: string;
  code?: string;
  config?: AxiosRequestConfig;
}

export const getPageSSR = async ({ api, pageType, label, code, config }: getPageSSRParams) => {
  const response = await api.getPage(
    Config.SITE_NAME,
    narrowPageType(pageType),
    label,
    code,
    undefined,
    undefined,
    config
  );

  const filteredSlots = getSlotsForPage(response.data);
  const partialData = response.data as Partial<PageAdaptedData>;

  return {
    ...partialData,
    breadcrumbs: response.data.breadcrumbs,
    contentSlots: response.data.contentSlots, // deprecated, use slots instead
    slots: filteredSlots,
  } as PageWithSlots;
};

// Nested dto ComponentWsDTO will contain dynamic fields, see description in https://bitbucket.org/axfood/hybris-custom_occ/pull-requests/14120
export interface ExtendedPageAdaptedData extends Omit<PageAdaptedData, 'contentSlots'> {
  contentSlots: {
    contentSlot: Map<string, ContentSlotWsDTO>;
  };
}

/**
 * CmsComponentApi
 */
const cmsComponentApi = new CmsComponentApi(undefined, '/axfoodcommercewebservices/v2');

interface FindFirstComponentById {
  componentIds?: Array<string>;
  catalogCode?: string;
  productCode?: string;
  categoryCode?: string;
  storeId?: string;
  options?: AxiosRequestConfig;
}
export const findComponentsByIds = async ({
  componentIds,
  catalogCode,
  productCode,
  categoryCode,
  storeId,
  options,
}: FindFirstComponentById) => {
  const response = await cmsComponentApi.findComponentsByIds(
    Config.SITE_NAME,
    componentIds,
    catalogCode,
    productCode,
    categoryCode,
    storeId,
    undefined,
    undefined,
    undefined,
    undefined,
    options
  );
  return response.data;
};

export const findComponentsByIdSSR = async (
  api: CmsComponentApi,
  componentIds: Array<string>,
  storeId?: string,
  options?: AxiosRequestConfig
) => {
  const response = await api.findComponentsByIds(
    Config.SITE_NAME,
    componentIds,
    undefined,
    undefined,
    undefined,
    storeId,
    undefined,
    undefined,
    undefined,
    undefined,
    options
  );
  return response.data;
};

/**
 * Voucher API
 */
const voucherApi = new VoucherApi(undefined, '');

export const activateVoucher = async (voucherCode: string) =>
  await voucherApi.activateExternalVoucherUsingPUT(voucherCode);

export const getVoucherCount = async () =>
  (await voucherApi.getExternalVoucherCountUsingGET()) as unknown as Promise<AxiosResponse<{ useableVouchers: number }>>;

/**
 * Session API
 */

const sessionApi = new SessionApi(undefined, '');

export const getCSRFToken = (options?: AxiosRequestConfig) => sessionApi.getCSRFTokenUsingGET(options);

/**
 * Site API
 */

const siteApi = new SiteApi(undefined, '');

export const getTranslations = async () => {
  const response = await siteApi.getReactTranslationsUsingGET(Config.SITE_NAME);
  // Narrowing type to ReactTranslationData with namespace that is not undefined
  return response.data.filter((translation): translation is ReactTranslationData & { namespace: string } =>
    Boolean(translation.namespace)
  );
};
