import TagManager from 'react-gtm-module';

export const trackPromoView = (promotions: { id: string; creative: string; position: string }) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'promoView',
      promoView: {
        promotions: [promotions],
      },
    },
  });
};
