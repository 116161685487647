import LinkButton from '@components/atoms/Button/LinkButton';
import media from '@constants/responsive';
import theme from '@styles/theme';
import { rem } from 'polished';
import styled from 'styled-components';

const getVariant = (variant: string) => {
  switch (variant) {
    case 'primary':
      return `
        ${media.tabletLandscape} {
          align-self: start;
        }
        p {
          color: ${theme.colors.colorWhite}
        }
      `;
    default:
      return `
      margin-top: ${rem(16)};
      ${media.tabletLandscape} {
        align-self: end;
        margin-top: ${rem(32)};
      }
      `;
  }
};

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${rem(24)} 0;
  height: auto;
  ${media.tabletLandscape} {
    flex-direction: row;
    min-height: unset;
  }
`;

export const HeaderTitleWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: ${rem(8)};
  ${media.tabletLandscape} {
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
  }
`;

export const LinkContainer = styled.div<{ $variant: string }>`
  align-items: center;
  border-radius: ${rem(32)};
  ${({ $variant }) => $variant && getVariant($variant)};
`;

export const StyledLink = styled(LinkButton)`
  align-items: center;
`;
