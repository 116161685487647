import { HeadingSize, HeadingType } from '@components/atoms/__DEPRECATED__/Heading/types';
import React from 'react';
import { ParagraphBoldType, ParagraphSizeType } from '../__DEPRECATED__/Paragraph/types';
import { StyledText, TextWrapper } from './Text.styles';

declare type HeadingBoldType = '' | 'bold';

declare type AcceptedTypographyCombinations =
  | ({
      type: HeadingType;
      size?: HeadingSize;
      bold?: HeadingBoldType;
      color?: ColorType;
    } & React.HTMLAttributes<HTMLHeadingElement>)
  | ({
      type: 'p';
      size?: ParagraphSizeType;
      bold?: ParagraphBoldType;
      color?: '' | Theme;
    } & React.HTMLAttributes<HTMLParagraphElement>);

declare type TextProps = {
  children: React.ReactNode;
  className?: string;
  'data-testid'?: string;
  strikeThrough?: boolean;
  truncateText?: boolean;
  as?: string;
} & AcceptedTypographyCombinations;

/**
 *
We usually use the Heading and Paragraph components for texts. There are however edge cases where we want to use the styling(as specified in UI-design sketches) of a header och a paragraph but we dont want to use those elements. An example for this is for example when we want to avoid breaking heading hierarchy.
This component can emulate the styling of a heading or a paragraph but will always render as a <div/> element

Whenever possible Heading and Paragraph should be used over this component!

To match the Heading and Paragraph components as close as a possible this component only allows combinations of props as specified in types for those components.
 */
const Text = ({
  children,
  type,
  color = 'red',
  size = '',
  bold = '',
  strikeThrough = false,
  className,
  'data-testid': testId = undefined,
  truncateText = false,
  role,
  as,
}: TextProps) => {
  return (
    <TextWrapper $strikeThrough={strikeThrough} data-testid={testId}>
      <StyledText
        $color={color}
        $size={size}
        $type={type}
        $bold={bold}
        $truncateText={truncateText}
        className={className}
        role={role}
        as={as}
      >
        {children}
      </StyledText>
    </TextWrapper>
  );
};

export default Text;
