// TODO: add type narrowing functions for each type of product included in ProductVariantType

import { AxfoodCartProductViewModel } from '@api/generated/storefront';

export const productIsCartProductType = (product: ProductVariantType): product is AxfoodCartProductViewModel => {
  return 'pickUnit' in product && 'quantity' in product;
};

const productTypeHelpers = {
  productIsCartProductType,
};

export default productTypeHelpers;
