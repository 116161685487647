import Skeleton from '@components/atoms/Skeleton';
import { AxfoodContentUspCmsComponentSkeleton } from '../AxfoodContentUspCmsComponent/AxfoodContentUspCmsComponent.skeleton';

interface Props {
  template: string;
}
const MultiColumnSkeletonComponent = ({ template }: Props) => {
  const skeletonTemplates = {
    AxfoodContentUspCmsComponent: <AxfoodContentUspCmsComponentSkeleton />,
  };
  const defaultSkeleton = <Skeleton type="rect" height={250} $style={{ margin: '0' }} data-testid="skeleton" />;
  return skeletonTemplates[template as keyof typeof skeletonTemplates] || defaultSkeleton;
};

export default MultiColumnSkeletonComponent;
