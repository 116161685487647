import { getWishlists } from '@api';
import { AxfoodBasicWishListViewModel } from '@api/generated/storefront';
import useCustomer from '@hooks/useCustomer';
import useSWR from 'swr';

const fetcher = async () => {
  const res = await getWishlists();
  return res.data;
};

const useShoppingLists = () => {
  const { isLoggedIn } = useCustomer();
  const key = isLoggedIn
    ? {
        fn: 'getWishlists',
      }
    : null;

  const {
    data: lists,
    isValidating: isRefreshingLists,
    mutate: refreshLists,
  } = useSWR<AxfoodBasicWishListViewModel[]>(key, fetcher, { revalidateOnMount: true, revalidateOnFocus: true });

  return { lists, isRefreshingLists, refreshLists };
};

export default useShoppingLists;
