import Paragraph from '@components/atoms/Paragraph';
import { rem } from 'polished';
import styled from 'styled-components';

const SplashBoxBase = styled.div`
  border-radius: ${rem(4)};
  display: inline-flex;
`;

export const NewSplashBox = styled(SplashBoxBase)`
  background: ${({ theme }) => theme.colors.colorHighlight};
  padding: 0 ${rem(10)};
`;

export const BargainSplashBox = styled(SplashBoxBase)`
  background: ${({ theme }) => theme.colors.colorPrimary};
  padding: 0 ${rem(12)};
  font-style: italic;
`;

export const BargainText = styled(Paragraph)`
  font-family: ${({ theme }) => theme.typography.fontSansItalicBold};
`;
