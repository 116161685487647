import sortCategorizedProductListsByAlphabeticOrder from '@helpers/sortCategorizedProductListsByAlphabeticOrder/sortCategorizedProductListsByAlphabeticOrder';
import { AxfoodCartProductViewModel } from '@api/generated/storefront';

export type CategorizedProductList = { name: string; products: Array<AxfoodCartProductViewModel> };

const sortCartProductsByCategory = (products: Array<AxfoodCartProductViewModel>): CategorizedProductList[] => {
  const categoryObjectArray: CategorizedProductList[] = [];
  products.forEach((product) => {
    const matchIndex = categoryObjectArray.findIndex((categoryObject) => categoryObject.name === product.categoryName);
    if (matchIndex !== -1) {
      categoryObjectArray[matchIndex].products.push(product);
    } else {
      categoryObjectArray.push({ name: product.categoryName || '', products: [product] });
    }
  });

  return sortCategorizedProductListsByAlphabeticOrder(categoryObjectArray);
};

export default sortCartProductsByCategory;
