import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${rem(16)};
  ${media.tabletPortrait} {
    padding: ${rem(60)};
  }
`;

export const TextWrapper = styled.div`
  text-align: center;
  margin-top: ${rem(30)};
`;
export const IconWrapper = styled.div<{ size: number }>`
  svg {
    width: ${({ size }) => rem(size)};
    height: ${({ size }) => rem(size)};
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${rem(32)};
`;
