import * as types from './types';

export const initValidateCanGoToCheckout = () => ({
  type: types.INIT_VALIDATE_CAN_GO_TO_CHECKOUT,
});
export const cancelValidateCanGoToCheckout = () => ({
  type: types.CANCEL_VALIDATE_CAN_GO_TO_CHECKOUT,
});

export const confirmGuestCheckout = () => ({
  type: types.CONFIRM_GUEST_CHECKOUT,
});
