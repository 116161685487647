import { getVoucherCount } from '@api';
import useCustomer from '@hooks/useCustomer';
import { useEffect } from 'react';
import useSWR, { SWRConfiguration } from 'swr';

const options: SWRConfiguration = {
  revalidateIfStale: true,
  revalidateOnMount: false,
};

const fetcher = async () => {
  const res = await getVoucherCount();
  return res.data;
};

const useVoucherCount = () => {
  const { customerId } = useCustomer();

  const isLoggedIn = customerId !== 'anonymous';

  const { data, mutate } = useSWR(isLoggedIn ? { fn: 'getVoucherCount', customerId } : null, fetcher, options);
  //TODO: Is this still needed?
  // with revalidateOnMount: false, data won't be fetched if the cache is empty
  // https://github.com/vercel/swr/issues/943
  useEffect(() => {
    if (!data && isLoggedIn) mutate();
  }, [isLoggedIn, mutate, data]);

  const voucherCount = data?.useableVouchers;
  return { voucherCount };
};

export default useVoucherCount;
