import { getCurrentDate, getCurrentTime } from '@helpers/getCurrentDateAndTime';
import trimProductCode from '@helpers/trimProductCode';
import TagManager from 'react-gtm-module';

const cartElementClick = (category: string, action: string, label = '') => {
  // GA3 - TO BE REMOVED - when GA3 is no longer active
  TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category,
      action,
      label,
      value: 0,
    },
  });
};

const cartReplaceCheckboxClick = (checkboxText: string, itemId = '', itemName = '') => {
  // GA4 tracking cartReplaceCheckboxClick

  TagManager.dataLayer({
    dataLayer: {
      event: 'checkbox_ticked',
      checkbox_txt: checkboxText,
      date: getCurrentDate(),
      item_id: trimProductCode(itemId),
      item_name: itemName,
      sphere_name: 'cart',
      time: getCurrentTime(),
    },
  });
};

export default cartElementClick;
export { cartElementClick, cartReplaceCheckboxClick };
