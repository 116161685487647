import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import { pickUnitName, toShoppingListProducts } from '@helpers/productHelper';
import { useAppSelector } from '@hooks/redux';
import useAddToShoppingList from '@hooks/useAddToShoppingList';
import { selectCartProducts } from '@selectors/cart';
import { AddToShoppingListEvent } from 'trackers/addToShoppingList';

interface Props {
  eventCategory: AddToShoppingListEvent['category'];
  product?: AddToListProduct | Product | null;
}

const useAddCartProductsToList = ({ eventCategory, product }: Props) => {
  const cartProducts = useAppSelector(selectCartProducts);
  const { addToExistingShoppingList, addToNewShoppingList } = useAddToShoppingList();
  const listProductsInCart: ShoppingListProduct[] = cartProducts ? toShoppingListProducts(cartProducts) : [];

  const getListProductEntries = (): ShoppingListProduct[] => {
    if (product) {
      // check if product is already in cart.
      const pickUnit = product.price?.pickUnitName ? product.price.pickUnitName : pickUnitName(product);

      if (product.online) {
        const productInCart = listProductsInCart.find((cartProduct) => cartProduct.productCode === product.code);

        return productInCart
          ? [productInCart]
          : [
              {
                entryType: 'PRODUCT',
                pickUnit,
                productCode: product.code,
                quantity: product.incrementValue,
              },
            ];
      }
      const promotion = 'hasPromotion' in product ? product.promotion : product.potentialPromotions?.[0];
      const productCode = 'hasPromotion' in product ? promotion.productCode : promotion?.mainProductCode || '';
      const promotionCode = 'hasPromotion' in product ? promotion.promotionCode : promotion?.code || '';

      if (promotion) {
        return [
          {
            entryType: 'PROMOTION',
            pickUnit,
            productCode,
            promotionCode,
            quantity: product.incrementValue > 0 ? product.incrementValue : 1,
          },
        ];
      }
      return [];
    }
    return listProductsInCart;
  };

  const addCartProductsToExistingList = async (listName: string) => {
    const productsToAdd = getListProductEntries();
    await addToExistingShoppingList({ listName, eventCategory, productsToAdd });
  };

  const addCartProductsToNewList = async (listName: string) => {
    const productsToAdd = getListProductEntries();
    await addToNewShoppingList({ listName, eventCategory, productsToAdd });
  };
  return { addCartProductsToExistingList, addCartProductsToNewList };
};

export default useAddCartProductsToList;
