import { AxfoodOrderProductViewModel } from '@api/generated/storefront';
import { mapOrderRowFromCategoryProduct, OrderRow } from '../DetailOrderRow/mapOrderRow';

export type CategorizedOrderRows = { [key: string]: OrderRow[] };

export const mapCategorizedOrderRows = (
  categoryOrderedDeliveredProducts: {
    [key: string]: AxfoodOrderProductViewModel[];
  },
  isAnonymousOrder: boolean,
  paymentCaptured: boolean
): CategorizedOrderRows =>
  Object.entries(categoryOrderedDeliveredProducts || {})
    ?.map(([key, value]) => ({ key, value }))
    .reduce((acc, { key, value }) => {
      return {
        ...acc,
        [key]: value.map((product) => mapOrderRowFromCategoryProduct(product, isAnonymousOrder, paymentCaptured)),
      };
    }, {});
