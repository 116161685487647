export const THEME_ALWAYS_GOOD_PRICE = 'Z019';
export const THEME_CLUB_PRICE = 'LOYALTY';
export const THEME_PERSONAL_OFFER = 'SEGMENTED';

const PROMOTION_THEME = {
  PRICE_BOMB: 'Z061',
  ALWAYS_GOOD_PRICE: THEME_ALWAYS_GOOD_PRICE,
  CLUB_PRICE: THEME_CLUB_PRICE,
  PERSONAL_OFFER: THEME_PERSONAL_OFFER,
};

export const PROMOTION_TYPE = {
  MIX_MATCH_PERCENTAGE_PROMOTION: 'MixMatchPercentagePromotion',
  MIX_MATCH_PRICE_PROMOTION: 'MixMatchPricePromotion',
};

export default PROMOTION_THEME;
