import Icon, { IconType } from '@components/atoms/Icon/Icon';
import Text from '@components/atoms/Text';
import IconArrowRight from '@public/icons/regularIcons/deprecated-icon-arrow-right.svg';
import IconArrowDown from '@public/icons/regularIcons/icon-arrow-down.svg';
import { AccordionItemLink, FlexBlock, LinkButton, LinkIcon, ListItem } from './AccordionListItem.styles';

export interface AccordionListItemProps {
  title?: string;
  icon?: {
    svg?: IconType;
    color?: ColorType;
  };
  iconImageUrl?: string;
  indentation?: number;
  isBold?: boolean;
  isActive?: boolean;
  href?: string;
  hasArrow?: boolean;
  onClick?: () => void;
  wrapperElement: 'li' | 'div';
}

/**
 * @deprecated
 */

const AccordionListItem = ({
  title,
  icon,
  iconImageUrl,
  indentation = 23,
  isBold = false,
  isActive = false,
  href,
  hasArrow = false,
  onClick,
  wrapperElement,
}: AccordionListItemProps) => {
  const getBoldStatus = () => {
    return isBold ? 'bold' : '';
  };
  const color = 'black';
  const linkContent = () => {
    return (
      <>
        <FlexBlock>
          {iconImageUrl && <LinkIcon src={iconImageUrl} alt="icon" />}
          {icon && icon.svg && icon.color && <Icon icon={icon.svg} color={icon.color} margin="right" />}
          <Text type="p" size="body-text" bold={getBoldStatus()}>
            {title}
          </Text>
        </FlexBlock>
        <FlexBlock>
          {hasArrow && (
            <Icon
              color={isActive ? 'black' : 'grey'}
              icon={isActive ? IconArrowDown : IconArrowRight}
              data-testid={isActive ? 'icon-arrow-down' : 'icon-arrow-right'}
            />
          )}
        </FlexBlock>
      </>
    );
  };

  return (
    <ListItem as={wrapperElement} data-testid="accordion-list-item" $isActive={isActive}>
      {href ? (
        <AccordionItemLink
          href={href}
          $color={color}
          $indentation={indentation}
          underlined={false}
          margin={false}
          onClick={onClick}
        >
          {linkContent()}
        </AccordionItemLink>
      ) : (
        <LinkButton onClick={onClick} $color={color} $indentation={indentation}>
          {linkContent()}
        </LinkButton>
      )}
    </ListItem>
  );
};

export default AccordionListItem;
