import { useEffect, RefObject } from 'react';

function useModal(containerRef: RefObject<HTMLElement>, scrollTo: number) {
  useEffect(() => {
    if (containerRef.current) {
      const currentEl = containerRef.current;

      const firstFocusableElement = currentEl.querySelector(
        'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
      ) as HTMLElement | null;

      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
      currentEl.scrollTop = scrollTo;
    }
  }, [containerRef, scrollTo]);
}

export default useModal;
