import axios from 'axios';

/* Clientside error logging */
const logError = (customErrMsg = '', error: unknown) => {
  if (!axios.isCancel(error)) {
    console.error(error); // eslint-disable-line no-console
  }
};

export { logError as default };
