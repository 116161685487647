import styled from 'styled-components';
import { rem } from 'polished';
import media from '@constants/responsive';

export const GridHeading = styled.div`
  padding-bottom: ${rem(15)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${media.tabletPortrait} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const SubtitleWrapper = styled.div`
  margin: ${rem(4)} 0;
  ${media.tabletPortrait} {
    margin-left: ${rem(16)};
  }
`;

export const HeadingLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  ${media.tabletPortrait} {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
`;
