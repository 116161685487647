export const PAYEX = 'Payex';
export const PSP_PAYEX_ALL = 'PspPayexAll';
export const PAYEX_ALL = 'PayexAll';
export const KLARNA = 'Klarna';
export const INVOICE = 'Invoice';

export const DEBIT = 'debit';
export const CREDIT = 'credit';

export const NEW_CARD = 'new-card';

export type VALID_PAYMENT_MODE_TYPE =
  | typeof KLARNA
  | typeof PAYEX
  | typeof INVOICE
  | typeof PSP_PAYEX_ALL
  | typeof PAYEX_ALL
  | '';

export const PAYEX_OPTIONS: PaymentMethodCategoryType[] = [
  {
    identifier: DEBIT,
    name: 'Kontokort',
  },
  {
    identifier: CREDIT,
    name: 'Kreditkort',
  },
];

export const PlaceOrderErrorResponse = {
  INVOICE_BLOCKED: 'user.blocked.from.invoice.payment',
  ORDER_LIMIT: 'b2b.user.orderlimit.not.high.enough.for.invoice.payment',
  BANK_ID_SIGN_IN_PROMPT: 'user.must.have.valid.bankid.sign',
  POSSIBLY_WRONG_STORE: 'possible.wrong.store.on.order',
};

export const PAYMENT_AUTH_STATUS_IS_PENDING = 'PAYMENT_AUTH_STATUS_IS_PENDING';
export const PAYMENT_AUTH_STATUS_IS_SUCCESS = 'PAYMENT_AUTH_STATUS_IS_SUCCESS';
