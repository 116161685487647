import type { ComponentWsDTO, ContentSlotWsDTO } from '@occ/api-client';

export interface ContentSlotWsDTOWithComponent extends ContentSlotWsDTO {
  component: Array<ComponentWsDTO>;
}

interface MappedSlotData {
  [key: string]: ContentSlotWsDTOWithComponent;
}

/**
 * ContentSlotWsDTO[], Array<string> -> MappedSlotData
 * @param slots
 * @param slotArray
 */
const collectSlots = (slots: ContentSlotWsDTO[] = [], slotArray: Array<string>) => {
  const contentSlotsMap = new Map(slots?.map((s) => [s.position, s]));

  const initialValue: MappedSlotData = {};
  return slotArray.reduce((acc, slot) => {
    const contentSlot = contentSlotsMap.get(slot);
    const component = contentSlot?.components?.component || [];
    if (contentSlot) {
      return { ...acc, [slot]: { ...contentSlot, component } };
    }
    return acc;
  }, initialValue);
};

export default collectSlots;
