import TagManager from 'react-gtm-module';

export const SearchSphereName = {
  RECIPE: 'recipe',
  STORE: 'store',
  CATEGORY: 'kategori',
  TERM: 'term',
  PRODUCT: 'produkt',
} as const;

const SEARCH_EVENT = 'search';

export type SearchSphereName = typeof SearchSphereName[keyof typeof SearchSphereName];

export const trackSearch = (query: string, hasSearchResults: boolean, sphereName: SearchSphereName) => {
  if (query) {
    TagManager.dataLayer({
      dataLayer: {
        event: SEARCH_EVENT,
        no_results_ind: hasSearchResults ? 0 : 1, // 0 when there are search results, 1 when there is no result
        search_term: query,
        sphere_name: sphereName,
      },
    });
  }
};

export const trackSearchSuggestionsShown = (query: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: SEARCH_EVENT,
      search_action: 'query completed',
      search_term: query,
    },
  });
};

export const trackCategoryClick = (query: string, categoryName: string) => {
  trackSearchModal(query, SearchSphereName.CATEGORY, categoryName, 'kategorier');
};
export const trackSuggestionClick = (query: string, suggestion: string) => {
  trackSearchModal(query, SearchSphereName.TERM, suggestion, 'termer');
};

export const trackProductClick = (query: string, productName: string) => {
  trackSearchModal(query, SearchSphereName.PRODUCT, productName, 'produkter');
};

const trackSearchModal = (
  query: string,
  sphereName: SearchSphereName,
  clickedItem?: string,
  suggestionSection?: string
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: SEARCH_EVENT,
      search_action: 'search result clicked',
      sphere_name: sphereName,
      search_section: suggestionSection,
      search_suggestion_clicked: clickedItem,
      search_term: query.toLowerCase(),
    },
  });
};

export const trackRecipeSearch = (searchTerm: string, hasSearchResults: boolean) =>
  trackSearch(searchTerm, hasSearchResults, SearchSphereName.RECIPE);

export const trackStoreSearch = (searchTerm: string) => trackSearch(searchTerm, true, SearchSphereName.STORE);

const trackShowAll = (searchTerm: string, section: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: SEARCH_EVENT,
      search_action: 'Visa alla resultat',
      search_term: searchTerm.toLowerCase(),
      search_section: section,
    },
  });
};
export const trackShowAllProducts = (searchTerm: string) => {
  trackShowAll(searchTerm, 'produkter');
};
