import useConfig, { Config } from '@hooks/useConfig';
import { FeatureFlags } from './featureFlags';

const useFeature = (feature: FeatureFlags) => {
  const { config = {} as Config } = useConfig();

  const value = config[feature]?.toLowerCase();

  return value === 'enabled' || value === 'true';
};

export default useFeature;
