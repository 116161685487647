import { mapFromCms } from '@components/cmsComponents/AxfoodPromoBannerComponent/AxfoodPromoBannerComponent';
import { CardHeight } from '@components/molecules/Product/Product.styles';
import PromoComponent from '@components/molecules/PromoComponent';
import { HeadingSize } from '@components/organisms/ContentComponent';
import styled from 'styled-components';

const PromoPanelWrapper = styled.div`
  ${CardHeight};
`;

function mapPanelFromCms(component: AxfoodPromoBannerComponentData) {
  const mapped = mapFromCms(component, 0);
  return { ...mapped, header: { ...mapped.header, size: 'large' as HeadingSize } };
}

const PromoPanel = ({ component }: { component: AxfoodPromoBannerComponentData }) => {
  const componentData = mapPanelFromCms(component);
  return (
    <PromoPanelWrapper>
      <PromoComponent data={componentData} fixedButtonGap={false} />
    </PromoPanelWrapper>
  );
};

export default PromoPanel;
