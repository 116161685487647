import AccordionSection from '@components/atoms/__DEPRECATED__/AccordionSection';
import AccordionListItemBaseNewStyle from '@components/molecules/__DEPRECATED__/AccordionListItemBaseNewStyle';
import useAppRouter from '@hooks/useAppRouter';
import useConfig from '@hooks/useConfig';
import { navigationClickTracker } from '@trackers';
import { useState } from 'react';
import {
  AccordionContainer,
  AccordionItemBox,
  ItemBox,
  ItemList,
  ItemTextBox,
  MenuItemButton,
  TitleBox,
} from './FooterAccordionLinks.styles';

const AccordionTitle = ({ text, active }: { text: string; active: boolean }) => (
  <AccordionItemBox>
    <AccordionListItemBaseNewStyle hasArrow isActive={active}>
      <TitleBox>{text}</TitleBox>
    </AccordionListItemBaseNewStyle>
  </AccordionItemBox>
);

const AccordionItem = ({ text, onClick }: { text: string; onClick: () => void }) => {
  return (
    <ItemBox>
      <MenuItemButton variant={'transparent'} onClick={onClick}>
        <ItemTextBox>{text}</ItemTextBox>
      </MenuItemButton>
    </ItemBox>
  );
};

interface FooterAccordionLinksProps {
  sections: CmsNavigationNode[];
}

const FooterAccordionLinks = ({ sections }: FooterAccordionLinksProps) => {
  const { push } = useAppRouter();
  const { config } = useConfig();
  const [activeSection, setActiveSection] = useState('');

  const changeActiveList = (sectionUid: string) => {
    sectionUid === activeSection ? setActiveSection('') : setActiveSection(sectionUid);
  };

  const getActiveStatus = (node: CmsNavigationNode) => {
    return activeSection === node.uid;
  };

  return (
    <AccordionContainer data-testid="footer-accordion-links">
      {sections.map((section) => {
        const active = getActiveStatus(section);
        return (
          <AccordionSection
            key={section.uid}
            active={active}
            onClick={() => changeActiveList(section.uid)}
            headerSlot={<AccordionTitle text={section.title} active={active} />}
            contentSlot={
              <ItemList data-testid="content-slot">
                {section.links.map((link: CmsNavigationNodeLink) => {
                  const onClick = () => {
                    navigationClickTracker.linkClickTracker('footer', link.linkName[0]?.value);
                    const url = link.external ? link.url : `${config?.PUBLIC_HOST}${link.url}`;
                    push(url);
                  };
                  return <AccordionItem key={link.url} text={link.linkName[0]?.value} onClick={onClick} />;
                })}
              </ItemList>
            }
          />
        );
      })}
    </AccordionContainer>
  );
};

export default FooterAccordionLinks;
