import TagManager from 'react-gtm-module';

export const sortingTracker = (pageName: any, sortName: any) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: pageName,
      action: 'sorted_product',
      label: sortName,
      value: 0,
    },
  });
};

const productFiltersTracker = { sortingTracker };

export default productFiltersTracker;
