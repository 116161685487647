import DotPagination from '@components/atoms/DotPagination';
import Skeleton from '@components/atoms/Skeleton';
import SwipeableWrapper from '@components/atoms/SwipeableWrapper';
import IconButton from '@components/molecules/IconButton';
import Carousel from '@components/molecules/__DEPRECATED__/Carousel';
import useOnetrustActiveGroupHelper from '@helpers/oneTrustActiveGroupHelper';
import useCmsComponents from '@hooks/useCmsComponentGroup';
import useResponsive from '@hooks/useResponsive';
import useSlider from '@hooks/useSlider';
import IconCarouselLeft from '@public/icons/regularIcons/icon-carousel-left.svg';
import IconCarouselRight from '@public/icons/regularIcons/icon-carousel-right.svg';
import { navigationClickTracker, horizontalBannerCarouselComponentTracker as tracker } from '@trackers';
import { useCallback, useEffect, useState } from 'react';
import { SwipeEventData } from 'react-swipeable';
import HorizontalBannerComponent from '../HorizontalBannerComponent';
import { Container, IndicatorWrapper, NavigationArrow } from './HorizontalBannerCarouselComponent.styles';
import { trackPromoView } from './eCommerceTracker/eCommerceTracker';

export interface HorizontalBannerCarouselComponentProps {
  component: HorizontalBannerCarouselCmsComponentType;
  slotPosition?: number;
}

const HorizontalBannerCarouselComponent = ({
  component,
  slotPosition = undefined,
}: HorizontalBannerCarouselComponentProps) => {
  const { name = '', banners } = component;
  const { data: cmsComponentData = [], isLoading, isValidating } = useCmsComponents(banners);
  const { isTabletPortraitOrGreater } = useResponsive();
  const { offset, incrementOffset, decrementOffset, setOffset } = useSlider({
    numSlides: cmsComponentData.length,
    loop: true,
  });
  const hasMultipleBanners = cmsComponentData.length > 1;
  const isOneTrustReady = useOnetrustActiveGroupHelper();
  const [hasMounted, sethHasMounted] = useState(false);
  const [bannerId, setBannerId] = useState('');

  const onCarouselPreviousSlideNavigation = () => {
    // only fires off the tracker if the slide actully moved.
    decrementOffset() && tracker.carouselShiftedEvent();
  };

  const onCarouselNextSlideNavigation = () => {
    // only fires off the tracker if the slide actully moved.
    incrementOffset() && tracker.carouselShiftedEvent();
  };

  // Remember: slide left = next slide, slide right = previous slide.
  const touchSlideHandler = (e: SwipeEventData) => {
    e.dir === 'Left' && onCarouselNextSlideNavigation();
    e.dir === 'Right' && onCarouselPreviousSlideNavigation();
  };

  const navigateToSlide = (id: number) => {
    id !== offset && (setOffset(id), tracker.carouselShiftedEvent());
  };

  const handleSlideIntersect = useCallback(
    (id: string) => {
      if (bannerId) {
        trackPromoView({ id, creative: 'hero_banner', position: (offset + 1).toString() });
      }
    },
    [offset, bannerId]
  );

  useEffect(() => {
    if (isOneTrustReady && !hasMounted && bannerId) {
      sethHasMounted(true);
      handleSlideIntersect(bannerId);
    }
  }, [isOneTrustReady, hasMounted, bannerId]);

  const handleSlideButtonClick = useCallback(
    (id: string, url: string | undefined) => {
      navigationClickTracker.promoClickTracker(id, 'hero_banner', (offset + 1).toString(), url);
    },
    [offset]
  );
  if (!isValidating && !cmsComponentData.length) return null;

  return (
    <Container data-testid="HorizontalBannerCarouselComponent">
      {isLoading ? (
        <Skeleton type="rect" $style={{ margin: '0' }} data-testid="skeleton" />
      ) : (
        <>
          <SwipeableWrapper onSwiped={touchSlideHandler}>
            <Carousel offset={offset}>
              {cmsComponentData.map((banner) => (
                <HorizontalBannerComponent
                  key={banner.uid}
                  component={banner as CmsCarouselBanner}
                  trackingName={name}
                  onIntersect={(id: string) => {
                    handleSlideIntersect(id);
                    setBannerId(id);
                  }}
                  onClick={handleSlideButtonClick}
                  slotPosition={slotPosition}
                />
              ))}
            </Carousel>
          </SwipeableWrapper>
          {isTabletPortraitOrGreater && hasMultipleBanners && (
            <NavigationArrow $direction="left">
              <IconButton
                icon={IconCarouselLeft}
                iconSize={50}
                color="white"
                onClick={onCarouselPreviousSlideNavigation}
                data-testid="navigation-arrow-left"
              />
            </NavigationArrow>
          )}
          <IndicatorWrapper>
            <DotPagination numberOfDots={cmsComponentData.length} activeIndex={offset} onClick={navigateToSlide} />
          </IndicatorWrapper>
          {isTabletPortraitOrGreater && hasMultipleBanners && (
            <NavigationArrow $direction="right">
              <IconButton
                icon={IconCarouselRight}
                iconSize={50}
                color="white"
                onClick={onCarouselNextSlideNavigation}
                data-testid="navigation-arrow-right"
              />
            </NavigationArrow>
          )}
        </>
      )}
    </Container>
  );
};

export default HorizontalBannerCarouselComponent;
