import media from '@constants/responsive';
import { rem } from 'polished';
import React from 'react';
import styled, { DefaultTheme, css } from 'styled-components';
import { HeadingSize, HeadingType } from './types';

export interface IHeadingStyleProps {
  $color: ColorType;
  $size?: HeadingSize;
  $type: HeadingType;
  $bold: boolean;
  children: React.ReactNode;
  className?: string;
  $align?: 'left' | 'right' | 'center';
  $truncate?: boolean;
}

const mapHeadingSize = {
  h1: {
    sm: `
      font-size: ${rem(18)};
      ${media.tabletPortrait} {
        font-size: ${rem(24)};
      }
    `,
    md: `
      font-size: ${rem(32)};
      ${media.tabletPortrait} {
        font-size: ${rem(42)};
      }
    `,
    lg: ``,
  },
  h2: {
    sm: `
      font-size: ${rem(14)};
      ${media.tabletPortrait} {
        font-size: ${rem(20)};
      }
    `,
    md: ``,
    lg: `
      font-size: ${rem(32)};
      ${media.tabletPortrait} {
        font-size: ${rem(52)};
      }
    `,
  },
  h3: {
    sm: `
    font-size: ${rem(16)};
    ${media.tabletPortrait} {
      font-size: ${rem(16)};
    }
    `,
    md: ``,
    lg: `
      font-size: ${rem(36)};
      line-height: ${rem(36)};
      font-weight: 900;
      ${media.tabletPortrait} {
        font-size: ${rem(36)};
      }
    `,
  },
  h4: {
    sm: ``,
    md: ``,
    lg: ``,
  },
};

const getHeadingTextColor = (color: ColorType, theme: DefaultTheme) => {
  switch (color) {
    case 'black':
      return theme.colors.colorTextPrimary;
    case 'red':
      return theme.colors.colorPrimary;
    case 'grey':
      return theme.colors.colorTextSecondary;
    case 'white':
      return theme.colors.colorWhite;
    case 'yellow':
      return theme.colors.colorAmber;
    case 'pink':
      return theme.colors.colorHighlight;
    default:
      return theme.colors.colorTextPrimary;
  }
};

export const HeadingBase = styled.h1<IHeadingStyleProps>`
  margin: 0;
  padding: 0;
  line-height: 1.2;
  color: ${({ $color, theme }) =>
    $color &&
    theme &&
    css`
      ${getHeadingTextColor($color, theme)}
    `};
  ${({ $bold }) =>
    $bold &&
    css`
      font-weight: 900;
    `}
  ${({ $align }) =>
    $align &&
    css`
      text-align: ${$align};
    `}
    ${({ $truncate }) =>
    $truncate
      ? css`
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        `
      : css``};
`;

export const H1 = styled(HeadingBase)`
  font-size: ${rem(32)};
  ${media.tabletPortrait} {
    font-size: ${rem(52)};
  }
  ${({ $size }) => $size && mapHeadingSize.h1[$size]};
`;

export const H2 = styled(HeadingBase).attrs({ as: 'h2' })`
  font-size: ${rem(24)};
  ${media.tabletPortrait} {
    font-size: ${rem(32)};
  }
  ${({ $size }) => $size && mapHeadingSize.h2[$size]};
`;

export const H3 = styled(HeadingBase).attrs({ as: 'h3' })`
  font-size: ${rem(20)};
  ${media.tabletPortrait} {
    font-size: ${rem(26)};
  }
  ${({ $size }) => $size && mapHeadingSize.h3[$size]};
`;

export const H4 = styled(HeadingBase).attrs({ as: 'h4' })`
  font-size: ${rem(20)};
  ${media.tabletPortrait} {
    font-size: ${rem(24)};
  }
`;

/* eslint-disable react/jsx-props-no-spreading */
export const HeadingStyled = (props: IHeadingStyleProps) => {
  const { $type } = props;
  switch ($type) {
    case 'h1':
      return <H1 {...props} />;
    case 'h2':
      return <H2 {...props} />;
    case 'h3':
      return <H3 {...props} />;
    case 'h4':
      return <H4 {...props} />;
    default:
      return null;
  }
};
