import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

interface IProductWrapperProps {
  $isPromotionUsed?: boolean;
  $border?: boolean;
}

/* Use for top/right padding and placement for newsSplash */
const productPaddingMobile = rem(5);
const productPaddingTablet = rem(12);
const productPaddingDesktop = rem(14);

export const ProductWrapper = styled.div<IProductWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  height: ${rem(282)};
  background: ${({ theme }) => theme.colors.colorWhite};
  padding: ${productPaddingMobile};
  color: ${({ theme }) => theme.colors.colorTextPrimary};
  position: relative;

  border-radius: ${rem(16)};

  ${media.tabletPortrait} {
    padding: ${productPaddingTablet} ${productPaddingTablet} ${rem(6)};
    height: ${rem(378)};
  }
  ${media.desktop} {
    padding-top: ${productPaddingDesktop};
  }
  ${({ $isPromotionUsed }) =>
    $isPromotionUsed
      ? css`
          > * {
            opacity: 0.4;
          }
        `
      : css``}
  ${({ $border, theme }) =>
    $border
      ? css`
          border: 1px solid ${theme.colors.colorIron};
        `
      : css``}
`;

export const ProductFooterWrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const ProductQuantityWrapper = styled.div<{ $smallQuantity: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  min-width: ${rem(130)};
  max-width: ${rem(286)};

  ${media.tabletPortrait} {
    margin: ${rem(2)} auto;
  }

  ${({ $smallQuantity }) =>
    $smallQuantity
      ? css`
          width: ${rem(135)};
          input {
            width: calc(50% - 10px);
          }

          ${media.mobile} {
            width: ${rem(150)};
            input {
              width: calc(50% - 24px);
            }
          }

          ${media.tabletPortrait} {
            width: ${rem(176)};
          }
        `
      : ''}
`;

export const PromotionUsedText = styled.div`
  background: ${({ theme }) => theme.colors.colorPampas};
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: ${rem(158)};
  opacity: 1;
  height: ${rem(40)};
  ${media.tabletPortrait} {
    height: ${rem(43)};
    top: ${rem(242)};
  }
`;

export const ImageContainer = styled.div`
  object-fit: contain;
  position: relative;
  width: ${rem(92)};
  height: ${rem(92)};
  margin: auto;

  ${media.tabletPortrait} {
    width: ${rem(144)};
    height: ${rem(144)};
  }
`;

export const HorizontalContainer = styled.div`
  width: 100%;
  display: flex;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  align-items: center;
  min-height: ${rem(200)};
  justify-content: space-between;
`;

export const HorizontalLeft = styled.div`
  position: relative;
  width: 48%;
`;

export const HorizontalLeftLabelsWrapper = styled.div`
  position: absolute;
  top: ${rem('-10px')};
  left: ${rem('2px')};
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tabletPortrait} {
    left: ${rem('12px')};
  }
`;

export const HorizontalLeftPromotionSplashPosition = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  top: ${rem(-8)};
`;

export const HorizontalRight = styled.div`
  width: 48%;
`;

export const ImageFallback = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${rem(144)};
  text-align: center;
`;

export const PromotionSplashPosition = styled.div`
  position: absolute;
  z-index: 1;
  right: 0;
  top: ${rem(43)};
  ${media.tabletPortrait} {
    top: ${rem(80)};
  }
`;

export const LabelsPosition = styled.div`
  position: absolute;
  top: ${rem('4px')};
  left: ${rem('2px')};
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.tabletPortrait} {
    top: ${rem('12px')};
    left: ${rem('12px')};
  }
`;

export const ProductHeaderWrapper = styled.div``;

export const NewsSplashWrapper = styled.div`
  background: ${({ theme }) => theme.colors.colorHighlight};
  border-radius: ${rem(2)};
  text-align: center;
  position: absolute;
  width: ${rem(52)};
  height: ${rem(21)};
  padding-top: 0;
  z-index: 1;
  right: ${productPaddingMobile};
  top: ${productPaddingMobile};

  ${media.tabletPortrait} {
    right: ${productPaddingTablet};
    top: ${productPaddingTablet};
  }

  ${media.desktop} {
    top: ${productPaddingDesktop};
  }
`;
