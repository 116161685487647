import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const BreadcrumbList = styled.ol`
  padding: 0;
  margin: 0;
  list-style: none;
`;
//TODO: this should use a new typography component called label
export const Breadcrumb = styled.li<{ $sliceLastItem?: boolean; $isCurrentPageCrumb?: boolean }>`
  font-weight: 500;
  font-size: ${rem(12)};
  opacity: 0.8;
  ${(props) =>
    !!props.$isCurrentPageCrumb &&
    `
      font-weight: 700;
      opacity: 1;
      `}
  line-height: normal;
  display: inline-flex;
  align-items: center;
  a {
    margin-left: 0;
  }
  &:first-of-type {
    a {
      margin-left: 0;
      max-width: ${rem(95)};
    }
  }
  &:not(:last-child) {
    p {
      max-width: ${({ $sliceLastItem }) => ($sliceLastItem ? 'none' : rem(90))};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      ${media.desktop} {
        text-overflow: unset;
        max-width: none;
      }
    }
  }
`;

export const Seperator = styled.span`
  margin-right: ${rem(5)};
`;
