import useAppRouter from '@hooks/useAppRouter';

const useEventListName = (LIST_NAME: string) => {
  const { pathname: eventlistNamePrepend } = useAppRouter();
  const extendListName = ` | ${LIST_NAME}`;
  const EVENT_LIST_NAME = `${eventlistNamePrepend}${extendListName}`;
  return {
    EVENT_LIST_NAME,
  };
};

export default useEventListName;
