import { useAppSelector } from '@hooks/redux';
import { selectCartHasProducts, selectCartProducts } from '@selectors/cart';
import { checkoutStepTracker } from '@trackers';
import { useEffect, useState } from 'react';

const useTrackCheckoutStep = (step: 1 | 2 | 3) => {
  const cartHasProducts = useAppSelector(selectCartHasProducts);
  const cartProducts = useAppSelector(selectCartProducts);
  const [hasSentTrackingEvent, setHasSentTrackingEvent] = useState(false);
  useEffect(() => {
    if (cartHasProducts && !hasSentTrackingEvent) {
      checkoutStepTracker.trackCheckoutStep(step, cartProducts);
      setHasSentTrackingEvent(true);
    }
  }, [step, hasSentTrackingEvent, cartHasProducts, cartProducts]);
};

export default useTrackCheckoutStep;
