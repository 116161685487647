import { useEffect, useState } from 'react';
// import { trackFaqWidgetToggle } from '@helpers/analyticsHelpers/trackFaqWidget';
import Script from 'next/script';
import ImboxWidgetScript from './ImboxWidgetScript';
import { trackImboxWidget } from './tracking';

const amountOfRetries = 10;
const retryInterval = 100;

interface Props {
  hideWidget: boolean;
}

const FaqWidget = ({ hideWidget }: Props) => {
  const [imboxWidgetFetch, setImboxWidgetFetch] = useState(0);

  const onToggle = (data: { isOpen: boolean }) => {
    trackImboxWidget(data.isOpen);
  };

  useEffect(() => {
    if (hideWidget) return;
    let timer: NodeJS.Timeout | null = null;

    if (imboxWidgetFetch !== amountOfRetries) {
      const imboxElement = document?.getElementsByClassName('imbox-show');
      if (imboxElement.length > 0) {
        window?._imbox?.push(['onToggle', onToggle]);
      } else {
        timer = setTimeout(() => {
          setImboxWidgetFetch(imboxWidgetFetch + 1);
        }, retryInterval);
      }
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [imboxWidgetFetch, amountOfRetries, retryInterval, setImboxWidgetFetch, onToggle]);

  return (
    <Script
      id="imbox-script"
      data-testid="imbox-script"
      dangerouslySetInnerHTML={{
        __html: ImboxWidgetScript,
      }}
      strategy="afterInteractive"
    />
  );
};

export default FaqWidget;
