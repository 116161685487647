import { AccountApi } from '@api/generated/storefront';
import { SetEmailAndPhoneAndDigitalReceipt } from '@components/organisms/AddMissingCustomerInformation/AddMissingCustomerInformation';
import { mapPointsHistory } from '@features/mymembership/helpers/mapPointsHistory';
import encrypt from '@helpers/encrypt';
import { AxiosRequestConfig } from 'axios';

/**
 * Account
 */

const accountApi = new AccountApi(undefined, '');

export const setEmailAndPhoneAndDigitalReceipt = (
  data: SetEmailAndPhoneAndDigitalReceipt,
  options?: AxiosRequestConfig
) => {
  return accountApi.emailAndPhoneAndDigitalReceiptUsingPOST(data, options);
};

export const getUserInfo = () => accountApi.getAccountInfoUsingGET();

export const getOrderHistory = async () => accountApi.getOrderHistoryUsingGET();

export const getPagedOrderHistoryAndBonus = async (
  fromDate?: string,
  toDate?: string,
  currentPage?: number,
  pageSize?: number
) => accountApi.getPagedOrderHistoryAndBonusUsingGET(fromDate, toDate, currentPage, pageSize);

export const deleteLinkedCard = (cardId: string, options?: AxiosRequestConfig) =>
  accountApi.removeLinkedCardUsingPOST({ cardId }, options);

export const updateEmailAndPhone = (email?: string, checkEmail?: string, phone?: string, options?: AxiosRequestConfig) =>
  accountApi.updateEmailAndPhoneUsingPOST(email, checkEmail, phone, options);

export interface UpdatePasswordFormValues {
  currentPassword: string;
  password: string;
  repeatedPassword: string;
}
export const updateAccountPassword = async (formValues: UpdatePasswordFormValues) => {
  const { currentPassword, password, repeatedPassword } = formValues;

  const encryptedCurrentPassword = await encrypt(currentPassword);
  const encryptedNewPassword = await encrypt(password);
  const encryptedCheckNewPassword = await encrypt(repeatedPassword);

  return accountApi.updatePasswordUsingPOST(
    encryptedCurrentPassword.key,
    encryptedNewPassword.key,
    encryptedCheckNewPassword.key,
    encryptedCurrentPassword.str,
    encryptedNewPassword.str,
    encryptedCheckNewPassword.str
  );
};

export interface CommunicationFlags {
  postal: boolean;
  email: boolean;
  sms: boolean;
  digitalReceipts: boolean;
  digitalReceiptsByEmail: boolean;
}

export const updateCommunicationOptions = async (flags: CommunicationFlags) => {
  await accountApi.updateCommunicationPreferencesUsingPOST({
    postalOffer: flags.postal,
    emailNews: flags.email,
    smsNews: flags.sms,
    digitalReceipt: flags.digitalReceipts,
    digitalReceiptEmailCommChannel: flags.digitalReceiptsByEmail,
  });
};

export const updateOfferStatus = async (offerNumber: string) => await accountApi.updateOfferStatusUsingPOST(offerNumber);

export const setPromotionIsActivatedInCart = async (promotionCode: string) =>
  accountApi.isActivatedOfferInCartUsingPOST(promotionCode);

export const getBonusPointsHistory = async () => {
  const res = await accountApi.getBonusPointsHistoryUsingGET();

  return res.data.loyaltyTransactionsForCurrentPeriod
    ? mapPointsHistory(res.data.loyaltyTransactionsForCurrentPeriod)
    : [];
};
