import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import SplashSection from '@components/molecules/Product/SplashSection';
import dynamic from 'next/dynamic';
import { ReactNode } from 'react';
import { UrlObject } from 'url';
import ProductCardImage from '../ProductCardImage/ProductCardImage';
import ProductImage from './ProductImage';
import { LabelsPosition, ProductImageSectionWrapper } from './ProductImageSection.styles';

const DynamicProductLabels = dynamic(() => import('../ProductLabels/ProductLabels'));

interface Props {
  withAddToList: boolean;
  product: Product;
  isPreview?: boolean;
  disableLinks?: boolean;
  onProductLinkClicked: () => void;
  as: { pathname: string };
  href: UrlObject;
  children?: ReactNode;
  linkAriaLabel: string;
}
const ProductImageSection = ({
  product,
  isPreview = false,
  disableLinks,
  onProductLinkClicked,
  as,
  href,
  children,
  linkAriaLabel,
}: Props) => {
  const {
    productAltText,
    online,
    labels,
    isDrugProduct,
    nicotineMedicalProduct,
    image,
    promotion,
    isNewsSplashProduct,
    isBargainProduct,
  } = product;

  const allowLinkClick = online && !disableLinks;

  return (
    <ProductImageSectionWrapper>
      <LabelsPosition>
        {children}
        <DynamicProductLabels
          labels={labels}
          isDrugProduct={isDrugProduct}
          nicotineMedicalProduct={nicotineMedicalProduct}
          isAlwaysGoodPrice={promotion?.isAlwaysGoodPrice || false}
        />
      </LabelsPosition>

      <SplashSection
        product={product}
        onProductLinkClicked={onProductLinkClicked}
        as={as}
        href={href}
        disableLinks={!allowLinkClick}
        showBargainSplash={isBargainProduct}
        showNewsSplashProduct={isNewsSplashProduct}
        showEnergyDeclaration
        linkAriaLabel={linkAriaLabel}
      />

      <ProductCardImage
        title={productAltText}
        href={href}
        as={as}
        onClick={onProductLinkClicked}
        imageIsClickable={allowLinkClick}
        linkAriaLabel={linkAriaLabel}
      >
        <ProductImage url={image?.url} isPreview={isPreview} altText={productAltText} />
      </ProductCardImage>
    </ProductImageSectionWrapper>
  );
};

export default ProductImageSection;
