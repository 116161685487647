/* eslint-disable react/no-array-index-key */
import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';
import Tooltip from '@components/atoms/Tooltip';
import useResponsive from '@hooks/useResponsive';
import useTranslation from 'next-translate/useTranslation';
import BatteriesInformationListing from './BatteriesInformationListing';
import GHSListing from './GHSListing';
import InfoBoxListing from './InfoBoxListing';
import {
  CategoryGroupTextWrapper,
  DescriptionWrapper,
  InfoBlock,
  InfoBoxWrapper,
  MainDescription,
  StyledParagraph,
  TooltipTitleWrapper,
} from './ProductDescriptionListing.styles';

import SanitizedHtml from '@components/atoms/SanitizedHtml/SanitizedHtml';
import MedicineLinkParagraph from './MedicineLinkParagraph/MedicineLinkParagraph';

interface ProductDescriptionListingProps {
  product: AxfoodProductDetailsViewModel;
}
interface RenderInfoProps {
  title?: string;
  info: string[];
  testId?: string;
}

export const RenderInfo = ({ title, info, testId }: RenderInfoProps) => (
  <InfoBlock>
    <StyledParagraph weight="bold">{title}</StyledParagraph>
    {info.map((text, i) => (
      <StyledParagraph key={i} data-testid={testId}>
        {text}
      </StyledParagraph>
    ))}
  </InfoBlock>
);

const ProductDescriptionListing = ({ product }: ProductDescriptionListingProps) => {
  const { t } = useTranslation('productDetails');
  const { isTabletPortraitOrGreater } = useResponsive();
  const {
    description,
    tradeItemCountryOfOrigin,
    code,
    activeSubstance,
    medicineType,
    medicineLink,
    ean,
    categoryGroupText,
    extraInfoCode,
    consumerUsageInstructions,
    ingredients,
    areBatteriesIncluded,
    areBatteriesRequired,
    batteryInformation,
    dietTypeInformation,
    healthClaimDescription,
    nutritionalClaim,
    preparationDescription,
    countryOfOriginStatement,
    provinceStatement,
    otherCountries,
    regulatoryAct,
    compulsoryAdditiveLabelInformation,
    foodContactName,
    foodContactAddress,
  } = product;
  const isBatteryProduct = areBatteriesIncluded || areBatteriesRequired || !!batteryInformation?.length;

  const origin = provinceStatement || countryOfOriginStatement;
  return (
    <DescriptionWrapper>
      <MainDescription>
        {activeSubstance && (
          <InfoBlock>
            <StyledParagraph>{t('productDetails->activeSubstance')}</StyledParagraph>
            <StyledParagraph>{activeSubstance}</StyledParagraph>
          </InfoBlock>
        )}
        {medicineType && (
          <InfoBlock>
            <StyledParagraph>{t('productDetails->medicineType')}</StyledParagraph>
            <StyledParagraph>{medicineType}</StyledParagraph>
          </InfoBlock>
        )}
        {medicineLink && (
          <InfoBlock>
            <MedicineLinkParagraph url={medicineLink} />
          </InfoBlock>
        )}
        {description && <RenderInfo info={[description]} />}
        {medicineType && ean && <RenderInfo title={t('productDetails->ean')} info={[ean]} />}
        {categoryGroupText && (
          <CategoryGroupTextWrapper>
            <SanitizedHtml content={categoryGroupText} />
          </CategoryGroupTextWrapper>
        )}
        {extraInfoCode && extraInfoCode === 'FFM' && <RenderInfo info={[t('productDetails->extraInfoCode')]} />}
        {consumerUsageInstructions && (
          <RenderInfo title={t('productDetails->consumerUsageInstructions')} info={[consumerUsageInstructions]} />
        )}
        {ingredients && <RenderInfo title={t('productDetails->ingredients')} info={[ingredients]} />}
        {isBatteryProduct && (
          <BatteriesInformationListing
            areBatteriesIncluded={areBatteriesIncluded}
            areBatteriesRequired={areBatteriesRequired}
            batteryInformation={batteryInformation}
          />
        )}
        {dietTypeInformation && (
          <RenderInfo title={t('productDetails->dietTypeInformation')} info={[dietTypeInformation]} />
        )}
        {healthClaimDescription && (
          <RenderInfo title={t('productDetails->healthClaimDescription')} info={[healthClaimDescription]} />
        )}
        {nutritionalClaim && <RenderInfo title={t('productDetails->nutritionalClaim')} info={[nutritionalClaim]} />}
        {preparationDescription && (
          <RenderInfo title={t('productDetails->preparationDescription')} info={[preparationDescription]} />
        )}
        {origin && (
          <InfoBlock>
            <TooltipTitleWrapper>
              <StyledParagraph weight="bold">
                {t('productDetails->countryOfOriginStatement')}
                &nbsp;
              </StyledParagraph>
              <Tooltip
                showToolTipText={false}
                toolTipText={t('productDetails->countryOfOriginLabel')}
                placement={isTabletPortraitOrGreater ? 'right' : 'bottom'}
                offset={[8, 0]}
              >
                {t('productDetails->countryOfOriginStatementTooltip')}
              </Tooltip>
            </TooltipTitleWrapper>
            <StyledParagraph>{origin}</StyledParagraph>
          </InfoBlock>
        )}
        {!otherCountries.length && tradeItemCountryOfOrigin && (
          <InfoBlock>
            <TooltipTitleWrapper>
              <StyledParagraph weight="bold">
                {t('productDetails->tradeItemCountryOfOrigin')}
                &nbsp;
              </StyledParagraph>
              <Tooltip
                toolTipText={t('productDetails->tradeItemCountryOfOriginLabel')}
                showToolTipText={false}
                placement={isTabletPortraitOrGreater ? 'right' : 'bottom'}
                offset={[8, 0]}
              >
                {t('productDetails->tradeItemCountryOfOriginTooltip')}
              </Tooltip>
            </TooltipTitleWrapper>
            <StyledParagraph>{tradeItemCountryOfOrigin}</StyledParagraph>
          </InfoBlock>
        )}
        {regulatoryAct && <RenderInfo title={t('productDetails->regulatoryAct')} info={[regulatoryAct]} />}
        {compulsoryAdditiveLabelInformation && (
          <RenderInfo
            title={t('productDetails->compulsoryAdditiveLabelInformation')}
            info={[compulsoryAdditiveLabelInformation]}
          />
        )}
        {(foodContactName || foodContactAddress) && (
          <RenderInfo info={[`${foodContactName ? `${foodContactName},` : ''} ${foodContactAddress}`]} />
        )}
        {code && <RenderInfo title={t('productDetails->code')} info={[code]} />}
        {otherCountries?.length > 0 && (
          <>
            <RenderInfo title={t('productDetails->possibleReplacement')} info={[otherCountries.join(', ')]} />
            <RenderInfo info={[t('productDetails->otherCountriesInfo')]} />
          </>
        )}
        <RenderInfo info={[t('productDetails->disclaimer')]} />
      </MainDescription>
      <InfoBoxWrapper>
        <InfoBoxListing product={product} />
        <GHSListing product={product} />
      </InfoBoxWrapper>
    </DescriptionWrapper>
  );
};

export default ProductDescriptionListing;
