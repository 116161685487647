import { BreadcrumbDataOfSearchStateData, FacetDataOfSearchStateData, SortData } from '@api/generated/storefront';
import Button from '@components/atoms/Button';
import Heading from '@components/atoms/Heading';
import Icon from '@components/atoms/Icon';
import Panel from '@components/molecules/Panel';
import { ProductSortingDropdown } from '@components/molecules/ProductListFilterDropdowns';
import FACET_CODES from '@constants/productListFilters';
import { clearFiltersFromQuery, countActiveFilters, getCurrentSearchKey } from '@helpers/productFilterHelpers';
import useAppRouter from '@hooks/useAppRouter';
import IconClose from '@public/icons/regularIcons/icon-close.svg';
import IconFilter from '@public/icons/regularIcons/icon-filter.svg';
import { assortmentTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import FilterList from '../../molecules/FilterList/FilterList';
import {
  ButtonRow,
  Close,
  FilterButton,
  FooterWrapper,
  Header,
  ProductListFiltersWrapper,
} from './ProductListFilters.styles';
import SelectedFilters from './SelectedFilters';
import { getBrands, getPromotionsAndLabels } from './helpers';

export interface ProductListFiltersProps {
  facets: FacetDataOfSearchStateData[];
  sorts: SortData[];
  pageName: 'category_page' | 'search_page';
  amountOfProducts: number;
  selectedFilters: BreadcrumbDataOfSearchStateData[];
}

const ProductListFilters = ({ facets, sorts, pageName, amountOfProducts, selectedFilters }: ProductListFiltersProps) => {
  const router = useAppRouter();
  const { query } = router;
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentSearchKey, setCurrentSearchKey] = useState('');
  const [currentQuery, setCurrentQuery] = useState(query.q || '');
  const [currentCategory, setCurrentCategory] = useState(query.category || '');

  const { t } = useTranslation('productFiltering');

  const activeCount = countActiveFilters(facets);

  const clearFilterText = t('clearFilter');

  const handleClose = () => setIsExpanded(false);

  const clearFilters = () => {
    const nextQuery = clearFiltersFromQuery(query);
    router.push({ pathname: router.pathname, query: nextQuery }, undefined, { scroll: false });
    setIsExpanded(false);
  };

  const onSearchTermChange = (newKey: string) => {
    setCurrentSearchKey(newKey);
    if (currentSearchKey !== newKey) {
      handleClose();
    }
  };

  const onCategoryChange = (newCategory: string) => {
    setCurrentCategory(newCategory);
    if (newCategory !== currentCategory) handleClose();
  };

  const trackNewQueryFilterLabel = (newQuery: string, facetCode: string) => {
    const newQueryFilterLabel = newQuery ? newQuery.split(`:${facetCode}:`) : '';
    if (newQueryFilterLabel.length > 1 && pageName === 'category_page') {
      assortmentTracker.categoryFilterEvent(newQueryFilterLabel[1], pageName);
    } else {
      assortmentTracker.searchFilterEvent(newQueryFilterLabel[1], facetCode);
    }
  };

  const onFilterChanged = (filterQuery: string) => {
    const firstQuery = filterQuery.indexOf(currentQuery as string);
    const currentQueryLength = currentQuery ? currentQuery.length : 0;
    let newQuery;
    if (firstQuery === 0) {
      newQuery = filterQuery.substring(currentQueryLength);
    } else {
      newQuery = filterQuery.substring(firstQuery + currentQueryLength);
    }

    if (newQuery.includes(FACET_CODES.BRANDS)) {
      trackNewQueryFilterLabel(newQuery, FACET_CODES.BRANDS);
    }
    if (newQuery.includes(FACET_CODES.LABELS)) {
      trackNewQueryFilterLabel(newQuery, FACET_CODES.LABELS);
    }
    if (newQuery.includes(FACET_CODES.PROMOTION)) {
      trackNewQueryFilterLabel(newQuery, FACET_CODES.PROMOTION);
    }
  };

  useEffect(() => {
    query.q && setCurrentQuery(query.q);
    const newKey = getCurrentSearchKey(query);
    const { categories, q } = query;
    const newCategory = categories?.[categories.length - 1];

    if (newCategory) {
      onCategoryChange(newCategory);
    }
    if (newKey) {
      onSearchTermChange(newKey);
    }
    if (q && typeof q === 'string' && currentQuery !== q) {
      onFilterChanged(q);
    }
  }, [router.query]);

  const brands = getBrands(facets);
  const promotionsAndLabels = getPromotionsAndLabels(facets, t);
  const closePanel = () => setIsExpanded(false);
  const onFilterClick = (newQuery?: string) => {
    router.push({ pathname: router.pathname, query: { ...query, q: newQuery } }, undefined, { scroll: false });
  };

  const headerSlot = (
    <Header>
      <Heading type="h2" size="small">
        {t('toggleShowFilter')}
      </Heading>
      <Close onClick={closePanel} aria-label={t('productFiltering:closeFilters')}>
        <Icon icon={IconClose} /> {t('common:defaultActions->close')}
      </Close>
    </Header>
  );

  const footerSlot = (
    <FooterWrapper>
      <Button variant="secondary" disabled={!activeCount} onClick={clearFilters} aria-label={t('clearFilter')}>
        {clearFilterText}
      </Button>
      <Button onClick={closePanel} aria-label={t('showProducts', { count: amountOfProducts })}>
        {t('showProducts', { count: amountOfProducts })}
      </Button>
    </FooterWrapper>
  );

  return (
    <ProductListFiltersWrapper data-testid="product-list-filters-wrapper">
      <ButtonRow>
        <FilterButton variant="grey" size="sm" onClick={() => setIsExpanded(!isExpanded)}>
          <Icon icon={IconFilter} />
          {t('toggleShowFilter')}
          {activeCount > 0 && <span>({activeCount})</span>}
        </FilterButton>

        {!!sorts.length && <ProductSortingDropdown sorts={sorts} pageName={pageName} />}
      </ButtonRow>

      <SelectedFilters selectedFilters={selectedFilters} onFilterClick={onFilterClick} />

      <Panel headerSlot={headerSlot} footerSlot={footerSlot} isOpen={isExpanded} closePanel={closePanel}>
        <section data-testid="filter-panel-content">
          <FilterList title={t('labelsTitle')} onFilterClick={onFilterClick} facetList={promotionsAndLabels} />
          <FilterList title={t('brandsTitle')} onFilterClick={onFilterClick} facetList={brands} />
        </section>
      </Panel>
    </ProductListFiltersWrapper>
  );
};

export default ProductListFilters;
