import { GHS_SYMBOLS } from '@components/molecules/ProductDescriptionListing/GHSListing';

export const unSupportedLabels = [...GHS_SYMBOLS, 'frozen', 'glutenfree', 'warning', 'laktosfree', 'fairtrade_facet'];

const filterUnSupportedProductLabels = (labels: string[]): string[] => {
  const labelContainsFairtradeCocao = labels?.includes('fairtrade_cacao');

  return labels?.filter(
    (label: string) => !unSupportedLabels.includes(label) && (label !== 'fairtrade' || !labelContainsFairtradeCocao)
  );
};

export default filterUnSupportedProductLabels;
