import { ContentSlotWsDTOWithComponent } from '@api/interfaces/collectSlots';
import LINKS from '@constants/links';
import useCmsLayout from '@hooks/useCmsLayout';
import useResponsive from '@hooks/useResponsive';
import useTranslation from 'next-translate/useTranslation';

export interface MenuLinkData {
  id?: string;
  title?: string;
  url?: string;
  iconUrl?: string;
}

export const getMenuLinks = (
  cmsNavigationNodes: CmsNavigationNode[],
  hardcodedLinks: MenuLinkData[]
): MenuLinkData[] => {
  return cmsNavigationNodes
    ?.map((node: CmsNavigationNode) => {
      const link: CmsNavigationNodeLink = node.links[0] || {};
      const iconUrl = link?.otherProperties?.find((prop) => prop.key === 'imageUrl')?.value?.value;
      const title = link.linkName?.[0]?.value;
      return { id: node.uid, title, url: link.url, iconUrl };
    })
    .filter((node) => node.title && node.url)
    .map((o) => {
      return Object.entries(o).reduce((acc: any, [key, value]) => (value ? { ...acc, [key]: value } : acc), {});
    })
    .concat(hardcodedLinks);
};

const useTopBarData = () => {
  const { headerLeftSlot, headerCenterSlot } = useCmsLayout();
  const { isTabletPortraitOrGreater } = useResponsive();
  const { t } = useTranslation('header');

  const hardcodedLinks: MenuLinkData[] = [
    {
      id: 'customerService',
      title: t('header->sideLinks->customerService'),
      url: LINKS.FAQ,
    },
    {
      id: 'findStore',
      title: t('header->sideLinks->findStore'),
      url: LINKS.FIND_STORE,
    },
  ];
  const findSubComponent = (slot: ContentSlotWsDTOWithComponent | undefined, componentUid: string) => {
    return slot?.component?.find((component) => component?.uid === componentUid);
  };
  const SiteLogoComponent = findSubComponent(headerLeftSlot, 'SiteLogoComponent') as SimpleBannerComponentType;
  const logotype: SimpleMedia = SiteLogoComponent?.media;

  const topMenuLinksComponent = findSubComponent(
    headerCenterSlot,
    'HemkopTopmenuLinksComponent'
  ) as AxfoodTopmenuLinksComponentType;

  const menuLinks: MenuLinkData[] = getMenuLinks(
    topMenuLinksComponent?.navigationNode?.children,
    isTabletPortraitOrGreater ? [hardcodedLinks[1]] : hardcodedLinks
  );

  return { logotype, menuLinks };
};

export default useTopBarData;
