import { createGlobalStyle } from 'styled-components';
import media from '@constants/responsive';
import { rem } from 'polished';

interface ILockScrollStyleProps {
  scrollBarWidth?: number;
}
const LockScrollStyle = createGlobalStyle<ILockScrollStyleProps>`
  body {
    overflow: hidden;
    touch-action: none;
    ${media.tabletPortrait} {
      @media (hover: hover) {
        margin:${(props) => (props.scrollBarWidth ? `0 ${rem(props.scrollBarWidth)} 0 0` : 0)};
      }
    }
  }
`;

export default LockScrollStyle;
