import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const AddMissingCustomerInformationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: ${rem(72)};
  ${media.tabletLandscape} {
    padding-top: ${rem(36)};
  }
  ${media.desktop} {
    padding-top: 0;
  }
`;

export const ButtonWrapper = styled.div`
  margin: ${rem(16)};
  display: flex;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  margin-bottom: ${rem(20)};
`;

export const FormRow = styled.div<{ $isCheckbox?: boolean }>`
  display: flex;
  position: relative;

  margin: ${(props) => (props.$isCheckbox ? ` ${rem(10)} 0` : `${rem(40)} 0`)};
  justify-content: flex-start;
  text-align: left;
`;

export const FormWrapper = styled.form`
  width: 100%;
  max-width: 100%;
  margin: auto;

  ${media.tabletPortrait} {
    width: ${rem(440)};
  }
`;

export const InfoWrapper = styled.div`
  margin-left: ${rem(30)};
  text-align: left;
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${rem(8)} 0;
`;
