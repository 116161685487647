import { AxfoodStoreInfoViewModel } from '@api/generated/storefront';
import useListNavigationKeyHandler from '@hooks/useListNavigationKeyHandler';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { Container, Input, StoreList } from './StoreSearch.styles';

const NO_STORE_INDEX = -1;
interface Props {
  stores: AxfoodStoreInfoViewModel[];
  setStore: (store: AxfoodStoreInfoViewModel) => void;
  selectedStore?: AxfoodStoreInfoViewModel;
  onClose?: () => void;
}

const StoreSearch = ({ stores, setStore, selectedStore, onClose }: Props) => {
  const { t } = useTranslation('delivery');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const storeItems: Suggestion[] = stores.map((store) => ({
    term: store.name || '',
    storeId: store.storeId || '',
  }));
  const [filteredStores, setFilteredStores] = useState<Suggestion[]>(storeItems);
  const [activeStoreIndex, setActiveStoreIndex] = useState<number>(NO_STORE_INDEX);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    const filteredStores = storeItems.filter((item) => item.term.toLowerCase().includes(searchTerm.toLowerCase()));
    setFilteredStores(filteredStores);
  };

  const handleChangeStore = (term: string = '') => {
    const findStoreFromActiveStoreIndex = stores.find((store) => store.name === filteredStores[activeStoreIndex]?.term);

    const matchingStore =
      stores.find((store) => store.name === term) ||
      (activeStoreIndex !== NO_STORE_INDEX ? findStoreFromActiveStoreIndex : undefined);

    if (matchingStore) {
      setStore(matchingStore);
    }
  };

  const handleClose = () => {
    if (onClose) {
      return onClose();
    }
  };

  const { listNavigationKeyHandler } = useListNavigationKeyHandler({
    onKeyEnter: handleChangeStore,
    onKeyEscape: handleClose,
    currentIndex: activeStoreIndex,
    setCurrentIndex: setActiveStoreIndex,
    items: filteredStores,
    shouldPreventDefault: false,
  });

  return (
    <Container onKeyUp={listNavigationKeyHandler} data-testid="store-search-container">
      <Input
        autoFocus
        type="text"
        name="store-search"
        placeholder={t('delivery->pickup->placeholder')}
        value={searchTerm}
        onChange={handleSearch}
        data-testid="store-search"
        autoComplete="off"
      />
      {filteredStores.length > 0 && (
        <StoreList
          data-testid="store-list"
          onClick={handleChangeStore}
          searchTerm={searchTerm}
          suggestions={filteredStores}
          suggestionIndex={activeStoreIndex}
          scrollItem
        />
      )}
    </Container>
  );
};

export default StoreSearch;
