import { EnergyDeclaration } from '@api/generated/storefront';
import Link from '@components/atoms/__DEPRECATED__/Link';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import TAB_TYPES, { TabType } from '@constants/productDetailTabs';
import getEnergyDeclarationArrowImageUrl from '@helpers/getEnergyDeclarationArrowImageUrl';
import useTranslation from 'next-translate/useTranslation';
import { Container, EnergyDeclarationArrowButton, EnergyDeclarationArrowImage } from './EnergyDeclarationLabel.styles';

export interface Props {
  energyDeclaration: EnergyDeclaration;
  onClick: (tabId: TabType) => void;
}

const EnergyDeclarationLabel = ({ energyDeclaration, onClick }: Props) => {
  const { t } = useTranslation('energydeclaration');

  const handleOnClick = () => {
    onClick(TAB_TYPES.MISC);
  };
  const imageUrl = getEnergyDeclarationArrowImageUrl(energyDeclaration.value);
  if (!imageUrl) {
    return null;
  }
  return (
    <Container data-testid="energy-declaration-label">
      <EnergyDeclarationArrowButton onClick={handleOnClick}>
        <EnergyDeclarationArrowImage src={imageUrl} alt={t('title')} />
      </EnergyDeclarationArrowButton>
      {energyDeclaration.link && (
        <Link href={energyDeclaration.link} external underlined={false} margin={false} target="_blank">
          <Paragraph size="sm">{t('link')}</Paragraph>
        </Link>
      )}
    </Container>
  );
};

export default EnergyDeclarationLabel;
