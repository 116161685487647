import { LoginTabType } from '@features/login/constants';
import { OnboardingEntryPoint } from '@features/onboarding/types';
import { getCurrentDate, getCurrentTime } from '@helpers/getCurrentDateAndTime';
import TagManager from 'react-gtm-module';

export const formSent = () => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'Bli medlem',
      action: 'personal_info_sent',
      label: '',
    },
  });
};

export const confirmSMS = () => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'Bekräfta',
      action: 'account_verification_sent',
      label: '',
    },
  });
};

export const registerComplete = () => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: '',
      action: 'membership_registered',
      label: '',
    },
  });
};

export const welcomeMember = () => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'Välkommen förnamn',
      action: 'ecom_flow_entered',
      label: '',
    },
  });
};

export const trackAccountCreated = (
  entryPoint: OnboardingEntryPoint,
  entryIdMethod: '' | LoginTabType,
  customerType: 'B2B' | 'B2C'
) => {
  const formattedMethod = entryIdMethod === 'bankId' ? 'bankID' : entryIdMethod;

  // GA4
  if (customerType === 'B2C') {
    TagManager.dataLayer({
      dataLayer: {
        event: 'account_created',
        date: getCurrentDate(),
        identification_method: formattedMethod,
        identification_pnt: entryPoint,
        sphere_name: 'identification',
        time: getCurrentTime(),
      },
    });
  }

  // GA3
  TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      action: 'account_created',
      category: customerType,
      label: entryPoint,
    },
  });
};
