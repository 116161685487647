import * as types from '@actions/checkout/types';
import { CheckoutActionTypes } from '@actions/checkout';
import { CreditCardPaymentInfoData } from '@api/generated/storefront';
import {
  VALID_PAYMENT_MODE_TYPE,
  PAYMENT_AUTH_STATUS_IS_PENDING,
  PAYMENT_AUTH_STATUS_IS_SUCCESS,
} from '@features/checkout/constants';

export type KlarnaDataStateType = { token: string; identifier: IdentifierType | '' };

export type PaymentAuthStatusType = typeof PAYMENT_AUTH_STATUS_IS_PENDING | typeof PAYMENT_AUTH_STATUS_IS_SUCCESS;

export type CheckoutStateType = {
  paymentAuthStatus?: PaymentAuthStatusType;
  payment: {
    currentCard: CreditCardPaymentInfoData;
    shouldSaveNewCard: boolean;
    currentlyCheckedPayment: VALID_PAYMENT_MODE_TYPE; // initially synced with cart.paymentType, but also allows for being set to empty string
    currentlyCheckedIdentifier: IdentifierType | '';
    klarnaLoaded: boolean;
    klarnaData: KlarnaDataStateType;
  };
};

export const initialState: CheckoutStateType = {
  paymentAuthStatus: undefined,
  payment: {
    currentCard: { cardType: '', defaultCard: false, expireDate: '', expired: false, id: '', maskedNumber: '' },
    shouldSaveNewCard: false,
    currentlyCheckedPayment: '',
    currentlyCheckedIdentifier: '',
    klarnaLoaded: false,
    klarnaData: {
      token: '',
      identifier: '',
    },
  },
};

const checkout = (state: CheckoutStateType = initialState, action: CheckoutActionTypes): CheckoutStateType => {
  switch (action.type) {
    case types.TOGGLE_SHOULD_SAVE_NEW_CARD: {
      return {
        ...state,
        payment: {
          ...state.payment,
          shouldSaveNewCard: !state.payment.shouldSaveNewCard,
        },
      };
    }
    case types.SET_CURRENT_CARD:
      return {
        ...state,
        payment: {
          ...state.payment,
          currentCard: action.payload,
        },
      };
    case types.SET_CURRENTLY_CHECKED_PAYMENT:
      return {
        ...state,
        payment: {
          ...initialState.payment,
          currentlyCheckedPayment: action.payload,
        },
      };
    case types.SET_KLARNA_DATA:
      return {
        ...state,
        payment: {
          ...state.payment,
          klarnaData: action.payload,
        },
      };

    case types.REFRESH_KLARNA_TOKEN_AND_UNSET_IDENTIFIER:
      return {
        ...state,
        payment: {
          ...state.payment,
          currentlyCheckedIdentifier: '',
          klarnaData: {
            token: action.payload,
            identifier: '',
          },
        },
      };
    case types.UNSET_KLARNA_IDENTIFIER:
      return {
        ...state,
        payment: {
          ...state.payment,
          klarnaData: {
            ...state.payment.klarnaData,
            identifier: '',
          },
        },
      };

    case types.SET_CURRENTLY_CHECKED_IDENTIFIER:
      return {
        ...state,
        payment: {
          ...state.payment,
          currentlyCheckedIdentifier: action.payload,
        },
      };

    case types.SET_KLARNA_LOADED:
      return {
        ...state,
        payment: {
          ...state.payment,
          klarnaLoaded: action.payload,
        },
      };

    case types.RESET_PAYMENT_STATE:
      return initialState;
    case types.SET_PAYMENT_AUTH_STATUS:
      return { ...state, paymentAuthStatus: action.payload };
    default:
      return state;
  }
};

export default checkout;
