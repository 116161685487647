import Head from 'next/head';

interface CanonicalLinkProps {
  url?: string | null;
}

const CanonicalLink = ({ url }: CanonicalLinkProps) => {
  if (!url) return null; // If no URL is provided, don't render anything

  return (
    <Head>
      <link key="canonical" rel="canonical" href={url} />
    </Head>
  );
};

export default CanonicalLink;
