import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const ProductWrapper = styled.div`
  width: 100%;
  border-radius: ${rem(4)};
  margin-top: ${rem(8)};
  background-color: ${({ theme }) => theme.colors.colorWhite};
  border: ${rem(1)} solid ${({ theme }) => theme.colors.colorCararra};
  display: flex;
  align-items: center;
  padding: ${rem(10)} ${rem(16)};
  position: relative;
  justify-content: space-between;

  ${media.tabletLandscape} {
    border-radius: ${rem(8)};
  }
  > a {
    text-decoration: none;
    flex: 1;
  }
  > a:hover {
    font-weight: ${({ theme }) => theme.fonts.fontWeight.medium};
  }
`;

export const ProductInformationWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const ProductInformation = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  ${media.tabletLandscape} {
    flex-direction: row;
    align-items: center;
  }
`;

export const ProductTitleInformation = styled.div`
  max-width: ${rem(120)};
  ${media.desktop} {
    max-width: ${rem(220)};
  }
`;

export const ProductTitle = styled(Paragraph)`
  width: ${rem(120)};
  ${media.desktop} {
    width: ${rem(220)};
  }
`;

export const ProductManufacturerDisplayVolume = styled.div`
  display: flex;
`;

export const ProductImage = styled.div`
  width: ${rem(44)};
  max-height: ${rem(44)};
  overflow: hidden;
  margin-right: ${rem(4)};

  ${media.tabletLandscape} {
    width: ${rem(48)};
    max-height: ${rem(48)};
    margin-right: ${rem(16)};
  }
`;

export const ProductPriceWrapper = styled.div`
  text-align: left;
  ${media.tabletLandscape} {
    text-align: right;
    min-width: ${rem(130)};
  }
`;

export const SplashWrapper = styled.div`
  position: absolute;
  top: ${rem(4)};
  left: ${rem(4)};
  width: auto;
  display: flex;
  justify-content: center;
  ${media.tabletLandscape} {
    position: relative;
    top: 0;
    left: 0;
    max-width: ${rem(60)};
  }
`;
export const LabelsWrapper = styled.div`
  position: absolute;
  top: ${rem(4)};
  left: 0;
  width: auto;
  display: flex;
  justify-content: center;
  ${media.tabletLandscape} {
    position: relative;
    top: 0;
    min-width: 0;
    min-width: ${rem(60)};
  }
`;

export const ProductPrice = styled(Paragraph)`
  font-family: ${({ theme }) => theme.typography.fontSerif};
`;

export const ProductQuantityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: ${rem(130)};
  margin: 0 0 0 ${rem(8)};
  text-align: center;

  ${media.tabletLandscape} {
    min-width: 112px;
    margin: 0 0 0 ${rem(16)};
  }
  ${media.desktop} {
    min-width: ${rem(130)};
  }
`;
