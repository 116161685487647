import { useState } from 'react';
import type { AxfoodCartEntryStatusViewModel } from '@api/generated/storefront';

const useReplaceableItems = () => {
  const [replaceableItemsToUpdate, setReplaceableItemsToUpdate] = useState<ProductUpdateType[]>([]);

  const onDontReplaceProductClick = (doNotReplaceItem: AxfoodCartEntryStatusViewModel) => {
    const { entryProduct: { code } = {} } = doNotReplaceItem;
    const currentProductToUpdate = replaceableItemsToUpdate.find((item) => item.productToReplaceCode === code);
    if (currentProductToUpdate) {
      setReplaceableItemsToUpdate(
        replaceableItemsToUpdate.filter((filterProduct) => {
          return currentProductToUpdate.code !== filterProduct.code;
        })
      );
    }
  };

  const onUpdateSelectedProduct = (product: ProductUpdateType, code?: string, name?: string) => {
    const currentProductToUpdate = replaceableItemsToUpdate.find((item) => item.productToReplaceCode === code);
    if (!currentProductToUpdate) {
      const selectedProduct = {
        productToReplaceCode: code,
        productToReplaceName: name,
        product: product.product,
        code: product.code,
        qty: product.qty,
      };
      setReplaceableItemsToUpdate((prevProductsToUpdate: ProductUpdateType[]) => [
        ...prevProductsToUpdate,
        selectedProduct,
      ]);
    } else {
      setReplaceableItemsToUpdate(
        replaceableItemsToUpdate.map((item) => {
          return item.productToReplaceCode === code
            ? { ...item, qty: product.qty, code: product.code, product: product.product }
            : item;
        })
      );
    }
  };
  return {
    replaceableItemsToUpdate,
    onDontReplaceProductClick,
    onUpdateSelectedProduct,
  };
};

export default useReplaceableItems;
