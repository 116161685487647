import AccordionSection from '@components/atoms/__DEPRECATED__/AccordionSection';
import SanitizedHtml from '@components/atoms/SanitizedHtml';
import useUserAgent from '@hooks/useUserAgent';
import { customerServiceTracker } from '@trackers';
import { useRef, useState } from 'react';
import { FAQEntryDataWithId } from '../AxfoodFAQComponent';
import { AccordionWrapper } from '../AxfoodFAQComponent.styles';
import AccordionContent from './AccordionContent';
import AccordionHeader from './AccordionHeader';

interface AccordionProps {
  sections: FAQEntryDataWithId[];
}

const FAQAccordion = ({ sections }: AccordionProps) => {
  const [activeSection, setActiveSection] = useState('');
  const [lastClosedTab, setLastClosedTab] = useState('');
  const sectionRef = useRef<HTMLDivElement>(null);
  const { isReactNative } = useUserAgent();

  const onClickAccordionSection = (section: FAQEntryDataWithId) => {
    const { id: sectionId, title } = section;
    const sectionIsActive = activeSection === sectionId;
    setLastClosedTab(sectionId !== activeSection ? activeSection : sectionId);
    setActiveSection(sectionIsActive ? '' : sectionId);
    if (!sectionIsActive) {
      customerServiceTracker.trackFaqOpened(title);
    }
  };

  return (
    <AccordionWrapper>
      {sections.map((section) => (
        <AccordionSection
          key={section.id}
          onClick={() => onClickAccordionSection(section)}
          shouldAlwaysRenderContent
          headerSlot={
            <AccordionHeader
              active={activeSection === section.id}
              title={section.title}
              isLastClosedTab={lastClosedTab === section.id}
              ref={activeSection === section.id ? sectionRef : undefined}
            />
          }
          contentSlot={
            <AccordionContent active={activeSection === section.id}>
              <SanitizedHtml content={section.content} mode={isReactNative ? 'allowNone' : 'default'} />
            </AccordionContent>
          }
        />
      ))}
    </AccordionWrapper>
  );
};
export default FAQAccordion;
