import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const PromotionFooter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: ${rem(3)} auto 0;
  ${media.tabletPortrait} {
    margin: ${rem(5)} auto 0;
  }
`;

export const RedeemLimit = styled.div`
  text-align: center;
  width: auto;
`;

export const LabelMixAndMatch = styled.div`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.colorPrimary};
  text-decoration: underline;
  outline: none;
  text-align: center;
  width: auto;
`;

export const Divider = styled.span`
  font-size: ${rem(10)};
  margin: 0 ${rem(5)};
`;

export const ProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  box-sizing: border-box;
  position: relative;
  gap: ${rem(8)};

  ${media.tabletPortrait} {
    gap: ${rem(16)};
    min-width: ${rem(205)};
  }
`;
