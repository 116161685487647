import Icon from '@components/atoms/Icon';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const Section = styled.section`
  position: relative;
  min-height: ${rem(250)};
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.tabletLandscape} {
    justify-content: flex-end;
  }
`;

interface BackgroundProps {
  $backgroundColor: string;
}
export const Background = styled.div<BackgroundProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props: BackgroundProps) => props.$backgroundColor};
  border-radius: ${rem(24)};
  overflow: hidden;

  ${media.tabletPortrait} {
    border-radius: ${rem(32)};
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: 50% 50%;

    ${media.tabletPortrait} {
      object-position: 0 0;
    }
  }
`;

export const Content = styled.div<{ $buttonOnlyContent: boolean }>`
  min-height: ${rem(250)};
  z-index: 1;
  width: ${rem(330)};
  padding: ${rem(10)};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  ${media.tabletLandscape} {
    ${({ $buttonOnlyContent }) =>
      $buttonOnlyContent
        ? css`
            margin: 0 auto;
          `
        : css`
            margin: 0 ${rem(65)} 0 0;
          `}
  }
  a {
    border: none;
  }
`;
export const IconWrapper = styled(Icon)`
  margin: 0 0 0 auto;
`;

interface HeaderWrapperProps {
  $fontcolor?: string;
}
export const HeaderWrapper = styled.div<HeaderWrapperProps>`
  text-align: center;
  color: ${(props) => props.$fontcolor || props.theme.colors.colorTextPrimary};

  &:hover {
    color: ${(props) => props.$fontcolor || props.theme.colors.colorTextPrimary};
  }
`;
