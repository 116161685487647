import cartActions from '@actions/cart';
import { deleteCart } from '@api/CartApi';
import { useAppDispatch } from '@hooks/redux';
import useAbortController from '@hooks/useAbortController';
import { useState } from 'react';

interface Args {
  onSuccess?: () => void;
  onFailure?: () => void;
}
const useDeleteCartRequest = ({ onSuccess, onFailure }: Args) => {
  const dispatch = useAppDispatch();
  const getSignal = useAbortController();
  const [isLoading, setIsLoading] = useState(false);

  const deleteCartRequest = async () => {
    const signal = getSignal();
    setIsLoading(true);
    try {
      const response = await deleteCart(false, signal );
      dispatch(cartActions.setCartDataIfNoQueuedOrOngoingUpdates(response.data));
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      if (onFailure) {
        onFailure();
      }
    } finally {
      setIsLoading(false);
    }
  };

  const cancelEditOrder = async (shouldDispatch: boolean = true) => {
    const signal = getSignal();
    setIsLoading(true);
    try {
      const response = await deleteCart(true, signal);
      shouldDispatch && dispatch(cartActions.setCartDataIfNoQueuedOrOngoingUpdates(response.data));
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      if (onFailure) {
        onFailure();
      }
    } finally {
      setIsLoading(false);
    }
  };

  return { deleteCartRequest, cancelEditOrder, isLoading };
};

export default useDeleteCartRequest;
