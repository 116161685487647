import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

const inverted = css`
  background-color: ${({ theme }) => theme.colors.colorAcadia};
  color: ${({ theme }) => theme.colors.colorWhite};

  @media (hover: hover) {
    &:hover,
    &:hover svg {
      color: ${({ theme }) => theme.colors.colorCararra};
      background-color: ${({ theme }) => theme.colors.colorAcadia};
    }

    &:focus-visible {
      color: ${({ theme }) => theme.colors.colorCararra};
      background-color: ${({ theme }) => theme.colors.colorAcadia};
    }
  }
`;

export const StyledPill = styled.button`
  border-radius: ${rem(32)};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${rem(4)};
  text-decoration: none;
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  font-size: ${({ theme }) => theme.fonts.fontSize.normal};
  text-align: left;
  cursor: pointer;
  padding: ${rem(14)} ${rem(24)};
  height: ${rem(40)};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  appearance: none;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  border: ${rem(1)} solid ${({ theme }) => theme.colors.colorIron};

  font-size: ${({ theme }) => theme.fonts.fontSize.small};
  ${media.tabletPortrait} {
    font-size: ${({ theme }) => theme.fonts.fontSize.normal};
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.colorPampas};
    }

    &:focus-visible {
      background-color: ${({ theme }) => theme.colors.colorPampas};
    }
  }
  &[aria-selected='true'] {
    ${inverted}
    padding: ${rem(8)} ${rem(16)} ${rem(8)} ${rem(24)};
  }
`;
