import Icon from '@components/atoms/Icon';
import LinkArea from '@components/atoms/LinkArea';
import { rem } from 'polished';
import styled from 'styled-components';
export const arrowUpFilled = '/icons/colorIcons/icon-filled-arrow-up.svg';

export const MixAndMatchWrapper = styled.div`
  width: 100%;
`;

export const MixAndMatchButton = styled.div`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  width: 100%;
  height: ${rem(32)};
  border-radius: ${rem(16)};
  padding: ${rem(8)};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.default};
  position: relative;
`;

export const ArrowIcon = styled(Icon)`
  z-index: 1;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  margin: auto;
`;
export const StyledLinkArea = styled(LinkArea)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover ${MixAndMatchButton} {
    background-color: ${({ theme }) => theme.colors.colorLinen};
  }
  &:hover ${ArrowIcon} {
    color: ${({ theme }) => theme.colors.colorLinen};
  }
  &:focus-visible ${ArrowIcon} {
    color: ${({ theme }) => theme.colors.colorPrimaryPink};
  }
  &:focus-visible ${MixAndMatchButton} {
    background-color: ${({ theme }) => theme.colors.colorPrimaryPink};
  }
`;

export const ButtonIcon = styled(Icon)`
  margin-right: ${rem(6)};
`;
