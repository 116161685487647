import media, { BREAKPOINTS } from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

const mediaQuery1024to1082px = ` @media screen and (min-width: ${BREAKPOINTS.md}px) and (max-width: 1082px)`;

export const Ellipsis = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${rem(116)};
  white-space: nowrap;

  ${mediaQuery1024to1082px} {
    max-width: ${rem(50)};
  }
`;

export const AccountButtonWrapper = styled.div`
  margin: 0 ${rem(8)};
  ${media.mobile} {
    margin: 0 ${rem(16)};
  }
`;
