import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

const modalBorderRadius = css`
  border-radius: 0;
  ${media.tabletPortrait} {
    border-radius: ${rem(32)};
  }
`;

const defaultBorderRadius = css`
  border-radius: ${rem(32)};
`;

export const Iframe = styled.iframe<{ $isModal: boolean }>`
  width: 100%;
  min-height: ${rem(240)};
  border: none;
  overflow: hidden;
  aspect-ratio: 16/9;
  height: 100%;
  ${({ $isModal }) => ($isModal ? modalBorderRadius : defaultBorderRadius)}
`;
