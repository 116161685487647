import media from '@constants/responsive';
import { rem } from 'polished';
import { css } from 'styled-components';

export type FontSizeType = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

export const fontSizes = {
  xsmall: {
    mobile: 10,
    other: 12,
  },
  small: {
    mobile: 12,
    other: 14,
  },
  medium: {
    mobile: 14,
    other: 16,
  },
  large: {
    mobile: 16,
    other: 18,
  },
  xlarge: {
    mobile: 18,
    other: 20,
  },
};

export const sizeLookup = {
  xsmall: css`
    font-size: ${rem(fontSizes.xsmall.mobile)};
    ${media.tabletPortrait} {
      font-size: ${rem(fontSizes.xsmall.other)};
    }
  `,
  small: css`
    font-size: ${rem(fontSizes.small.mobile)};
    ${media.tabletPortrait} {
      font-size: ${rem(fontSizes.small.other)};
    }
  `,
  medium: css`
    font-size: ${rem(fontSizes.medium.mobile)};
    ${media.tabletPortrait} {
      font-size: ${rem(fontSizes.medium.other)};
    }
  `,
  large: css`
    font-size: ${rem(fontSizes.large.mobile)};
    ${media.tabletPortrait} {
      font-size: ${rem(fontSizes.large.other)};
    }
  `,
  xlarge: css`
    font-size: ${rem(fontSizes.xlarge.mobile)};
    ${media.tabletPortrait} {
      font-size: ${rem(fontSizes.xlarge.other)};
    }
  `,
};
