import useUserAgent from './useUserAgent';

const useHideCmsComponent = () => {
  const { isNativeApp, isReactNative } = useUserAgent();

  const hideCmsComponent = (component: CmsComponent) => {
    const { hideInApp = false, hideInWeb = false } = component;

    if (isNativeApp || isReactNative) {
      return hideInApp === true || hideInApp === 'true';
    }

    return hideInWeb === true || hideInWeb === 'true';
  };

  return hideCmsComponent;
};

export default useHideCmsComponent;
