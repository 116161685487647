import colors from '@styles/colors';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const handleFillColor = (color: string) => {
  switch (color) {
    case 'red':
      return colors.colorPrimary;
    case 'grey':
      return colors.colorTextSecondary;
    case 'grey-light':
      return colors.colorStack;
    case 'black':
      return colors.colorTextPrimary;
    case 'green':
      return colors.colorFruitSalad;
    case 'white':
      return colors.colorWhite;
    case 'pink':
      return colors.colorRemy;
    default:
      return colors.colorTextPrimary;
  }
};

interface IconProps {
  $margin?: 'top' | 'right' | 'bottom' | 'left';
  $color: string;
  $animation?: string;
  $size: number;
}

export const IconWrapper = styled.span<IconProps>`
  flex-shrink: 0;
  display: inline-flex;
  vertical-align: middle;
  ${({ $color }) => !!$color && `color: ${handleFillColor($color)}`};
  ${({ $margin }) => $margin && `margin-${$margin}: ${rem(10)}`};
  ${({ $size }) => css`
    font-size: ${rem($size)};
    max-width: ${rem($size)};
  `};

  ${(props) =>
    props.$animation === 'rotate' &&
    css`
    svg {
      ${props.theme.animations.rotatorAnimation}
      & > *:first-child {
        ${props.theme.animations.dashAnimation};
      }
  `}
  > svg {
    width: 100%;
    height: 100%;
    color: currentColor;
  }
`;
