import makeIntersectionObserverComponent, { IntersectionObserverComponentProps } from '@helpers/componentTracking';
import useBufferedAnalyticsEvent from '@hooks/useBufferedAnalyticsEvent/useBufferedAnalyticsEvent';
import useResponsive from '@hooks/useResponsive';
import { conflictModalTracker } from '@trackers';
import { RefObject, useState } from 'react';
import GeneralProduct from '../../GeneralProduct';
import { ConflictedProductStyled } from '../ConflictModal.styles';
import type { AxfoodReplacementProductViewModel } from '@occ/api-client';

interface ConflictedProductProps extends IntersectionObserverComponentProps {
  item: AxfoodReplacementProductViewModel;
  productToUpdate: ProductUpdateType;
  onQuantityChange: (item: AxfoodReplacementProductViewModel, qty: number) => void;
  intersectionRef?: RefObject<any>;
}
const ConflictProductContainer = ({
  item,
  productToUpdate,
  onQuantityChange,
  intersectionRef,
}: ConflictedProductProps) => {
  const { isTabletLandscapeOrGreater } = useResponsive();
  const [currentQuantity = 0, setCurrentQuantity] = useState(item.replacementQty);
  return (
    <ConflictedProductStyled
      key={item.code}
      onClick={() => {
        if (item.code !== productToUpdate.code) onQuantityChange(item, currentQuantity);
      }}
      data-testid={item.code === productToUpdate.code ? 'selectedProduct' : 'product'}
      $selected={item.code === productToUpdate.code}
      ref={intersectionRef}
    >
      <GeneralProduct
        product={item}
        disableLinks
        initialQuantity={item.replacementQty}
        disableQuantity={item.code !== productToUpdate.code}
        smallQuantity={!isTabletLandscapeOrGreater}
        onQuantityChange={(quantity) => {
          if (item.code !== productToUpdate.code || quantity !== productToUpdate.qty) {
            onQuantityChange(item, Number(quantity));
            setCurrentQuantity(Number(quantity));
          }
        }}
        withAddToList={false}
      />
    </ConflictedProductStyled>
  );
};

export const IntersectionObservedConflictProduct = makeIntersectionObserverComponent(ConflictProductContainer);

interface ReplaceableProductsWithTrackingProps {
  conflictProducts: AxfoodReplacementProductViewModel[];
  productToUpdate: ProductUpdateType;
  onQuantityChange: (item: AxfoodReplacementProductViewModel, qty: number) => void;
  productToReplaceName?: string;
  productToReplaceCode?: string;
  slideIndex: number;
  slideSize: number;
}

const ConflictProductsWithTracking = ({
  conflictProducts,
  productToUpdate,
  onQuantityChange,
  productToReplaceName,
  productToReplaceCode,
  slideIndex,
  slideSize,
}: ReplaceableProductsWithTrackingProps) => {
  const track = (impressionProducts: { product: AxfoodReplacementProductViewModel; position: number }[]) => {
    conflictModalTracker.trackReplaceableProductsOnViewportEnter(
      impressionProducts,
      productToReplaceName,
      productToReplaceCode
    );
  };
  const { debouncedTrack, queueTrackingOfItem, trackableRefs } = useBufferedAnalyticsEvent(conflictProducts, track);

  const onProductShown = (product: AxfoodReplacementProductViewModel, position: number) => {
    queueTrackingOfItem({ product, position });
    debouncedTrack();
  };

  const handleQuantityChange = (conflictProduct: AxfoodReplacementProductViewModel, quantity: number) => {
    if (conflictProduct.code !== productToUpdate.code || quantity !== productToUpdate.qty)
      onQuantityChange(conflictProduct, Number(quantity));
  };
  return (
    <>
      {conflictProducts.map((conflictProduct: AxfoodReplacementProductViewModel, i: number) => {
        return (
          <div key={`${conflictProduct.code}`}>
            <IntersectionObservedConflictProduct
              intersectionCallback={() => onProductShown(conflictProduct, slideSize * (slideIndex - 1) + (i + 1))}
              ref={trackableRefs.current[i] as unknown as RefObject<any>}
              item={conflictProduct}
              productToUpdate={productToUpdate}
              onQuantityChange={handleQuantityChange}
            />
          </div>
        );
      })}
    </>
  );
};

export default ConflictProductsWithTracking;
