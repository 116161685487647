import LINKS from '@constants/links';
import gtmTrack from '@helpers/gtmTrack';
import pathnameIsCheckoutRoute from '@helpers/pathnameIsCheckoutRoute';
import useAppRouter from '@hooks/useAppRouter';
import { useState } from 'react';

const useToggleMiniCart = () => {
  const [isPaneVisible, setIsPaneVisible] = useState<boolean>(false);
  const router = useAppRouter();

  const toggleMenu = () => {
    setIsPaneVisible(!isPaneVisible);
    gtmTrack({
      event: 'Track',
      category: 'minicart',
      action: isPaneVisible ? 'minicart_closed' : 'minicart_opened',
      label: '',
      value: 0,
    });
  };

  const handleClickMiniCartButton = () => {
    if (router.pathname === LINKS.CART) {
      // do nothing
    } else if (pathnameIsCheckoutRoute(router.pathname)) {
      router.push(LINKS.CART);
    } else {
      toggleMenu();
    }
  };
  return { isPaneVisible, handleClickMiniCartButton, toggleMenu };
};

export default useToggleMiniCart;
