import Heading from '@components/atoms/Heading';
import { HeadingColor } from '@components/atoms/Heading/headingTypes';
import Icon from '@components/atoms/Icon';
import Paragraph, { ParagraphColor } from '@components/atoms/Paragraph/Paragraph';
import ArrowLinkButton, { ArrowButtonVariant } from '@components/molecules/ArrowLinkButton';
import { ContentWrapper, TextPanel } from './ContentComponent.styles';

export type HeadingSize = 'medium' | 'large' | 'xLarge';
export type TextSize = 'small' | 'large';
export type Color = 'black' | 'white';

export interface ContentComponentData {
  title?: string;
  text?: string;
  buttonText?: string;
  buttonUrl?: string;
}

export interface ContentComponentProps {
  data: ContentComponentData;
  centerContent?: boolean;
  fixedButtonGap?: boolean;
  color?: Color;
  headingSize?: HeadingSize;
  textSize?: TextSize;
  buttonVariant?: ArrowButtonVariant;
  icon?: any;
  onClick?: () => void;
  className?: string;
}

const ContentComponent = ({
  data,
  centerContent = false,
  fixedButtonGap = true,
  color = 'black',
  headingSize = 'xLarge',
  textSize = 'large',
  buttonVariant = 'primary',
  icon,
  onClick = () => {},
  className,
}: ContentComponentProps) => {
  const hasHeader: boolean = !!data.title;
  const hasText: boolean = !!data.text;

  return (
    <ContentWrapper className={className} $centerContent={centerContent} $fixedGap={fixedButtonGap}>
      {(hasHeader || hasText) && (
        <TextPanel $onlyTitle={!hasText}>
          {icon && <Icon icon={icon} size={32} color={color} />}
          {hasHeader && (
            <Heading type="h2" size={headingSize} color={color as HeadingColor}>
              {data.title}
            </Heading>
          )}
          {hasText && (
            <Paragraph size={textSize} color={color as ParagraphColor}>
              {data.text}
            </Paragraph>
          )}
        </TextPanel>
      )}
      {data.buttonUrl && (
        <ArrowLinkButton variant={buttonVariant} url={data.buttonUrl} onClick={onClick}>
          {data.buttonText}
        </ArrowLinkButton>
      )}
    </ContentWrapper>
  );
};

export default ContentComponent;
