import Paragraph from '@components/atoms/Paragraph';
import {
  EntranceComponentWrapper,
  SectionContent,
  SectionHead,
  SectionIconWrapper,
  SectionLink,
  SectionWrapper,
} from './AxfoodEntranceComponent.styles';
import ShopIcon from '@public/icons/regularIcons/icon-entrance-shop.svg';
import RecipeIcon from '@public/icons/regularIcons/icon-entrance-recipe.svg';
import StoreIcon from '@public/icons/regularIcons/icon-entrance-store.svg';
import Icon from '@components/atoms/Icon';

export interface AxfoodEntranceComponentProps {
  component: AxfoodEntranceComponentData;
}

const AxfoodEntranceComponent = ({ component }: AxfoodEntranceComponentProps) => {
  return (
    <EntranceComponentWrapper>
      <SectionWrapper data-testid="section-wrapper">
        <SectionLink href={component.linkSection1}>
          <SectionIconWrapper>
            <Icon icon={ShopIcon} size={32} />
          </SectionIconWrapper>
          <SectionContent>
            <SectionHead>{component.headerTextSection1}</SectionHead>
            <Paragraph size="small" weight="medium">
              {component.bodyTextSection1}
            </Paragraph>
          </SectionContent>
        </SectionLink>
      </SectionWrapper>

      <SectionWrapper data-testid="section-wrapper">
        <SectionLink href={component.linkSection2}>
          <SectionIconWrapper>
            <Icon icon={RecipeIcon} size={32} />
          </SectionIconWrapper>
          <SectionContent>
            <SectionHead>{component.headerTextSection2}</SectionHead>
            <Paragraph size="small" weight="medium">
              {component.bodyTextSection2}
            </Paragraph>
          </SectionContent>
        </SectionLink>
      </SectionWrapper>

      <SectionWrapper data-testid="section-wrapper">
        <SectionLink href={component.linkSection3}>
          <SectionIconWrapper>
            <Icon icon={StoreIcon} size={32} />
          </SectionIconWrapper>
          <SectionContent>
            <SectionHead>{component.headerTextSection3}</SectionHead>
            <Paragraph size="small" weight="medium">
              {component.bodyTextSection3}
            </Paragraph>
          </SectionContent>
        </SectionLink>
      </SectionWrapper>
    </EntranceComponentWrapper>
  );
};

export default AxfoodEntranceComponent;
