import Skeleton from '@components/atoms/Skeleton';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

interface ProductSkeletonPartProps {
  readonly divider?: boolean;
  readonly title?: boolean;
  readonly button?: boolean;
  readonly image?: boolean;
}

interface ProductSkeletonSectionProps {
  count?: number;
}

const ProductSkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  min-width: 0;
  max-height: ${rem(282)};
  padding: ${rem(12)};
  border-radius: ${rem(16)};

  ${media.tabletPortrait} {
    height: ${rem(378)};
    max-height: ${rem(378)};
    max-width: ${rem(240)};
  }
`;

export const ProductSkeletonPart = styled(Skeleton)<ProductSkeletonPartProps>`
  ${(props) =>
    props.divider &&
    css`
      margin: ${rem(4)} 0 0 0;
      ${media.tabletPortrait} {
        margin: ${rem(18)} 0 0 0;
      }
    `}
  ${(props) =>
    props.title &&
    css`
      margin: ${rem(15)} 0 ${rem(5)} 0;
      ${media.tabletPortrait} {
        margin: ${rem(34)} 0 ${rem(5)} 0;
      }
    `}
  ${(props) =>
    props.button &&
    css`
      align-self: center;
      margin: ${rem(24)} 0 0 0;
      ${media.tabletPortrait} {
        margin: ${rem(32)} 0 0 0;
        width: ${rem(176)};
        height: ${rem(40)};
      }
    `}
  ${(props) =>
    props.image &&
    css`
      margin: 0;
      ${media.tabletPortrait} {
        min-height: ${rem(144)};
      }
    `}
`;

export const ProductSkeleton = () => (
  <ProductSkeletonWrapper data-testid="product-skeleton">
    <ProductSkeletonPart image type="rect" height={92} />
    <ProductSkeletonPart type="rect" title height={20} />
    <Skeleton type="rect" height={10} width="50%" $style={{ margin: `${rem(2)} 0` }} />
    <Skeleton type="rect" height={10} width="50%" $style={{ margin: `${rem(2)} 0` }} />
    <Skeleton type="rect" height={10} width="50%" $style={{ margin: `${rem(2)} 0` }} />
    <ProductSkeletonPart type="rect" height={2} divider />
    <ProductSkeletonPart type="button" button height={46} width={140} />
  </ProductSkeletonWrapper>
);

export const ProductSkeletonSection = ({ count = 1 }: ProductSkeletonSectionProps) => {
  const skeletons = Array.from({ length: count }, (_, i) => <ProductSkeleton key={i} />);
  return <>{skeletons}</>;
};
