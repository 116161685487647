import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const AgeRestrictionDisclaimer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: ${rem(16)};
  background-color: ${({ theme }) => theme.colors.colorWhite};
  white-space: pre-line;
  border-radius: ${rem(16)};
`;

export const AgeRestrictionImageWrapper = styled.div`
  margin-top: ${rem(32)};
  ${media.tabletLandscape} {
    margin-top: ${rem(42)};
  }
`;
