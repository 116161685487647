import Icon from '@components/atoms/Icon';
import { PICKUP_CODE } from '@constants/deliveryMethods';
import { useAppSelector } from '@hooks/redux';
import useCustomer from '@hooks/useCustomer';
import PickUpInStoreIcon from '@public/icons/regularIcons/icon-butik.svg';
import IconContinue from '@public/icons/regularIcons/icon-continue.svg';
import {
  default as ChooseDeliveryIcon,
  default as HomeDeliveryIcon,
} from '@public/icons/regularIcons/icon-delivery.svg';
import { selectCartDeliveryModeCode, selectCartSlot } from '@selectors/cart';
import useTranslation from 'next-translate/useTranslation';
import SecondaryHeaderButton, { RedIconWithTextContent } from '../SecondaryHeaderButton';
import { IconWrapper } from './DeliveryButton.styles';

type b2bDelivery = 'b2bDelivery';

type DeliveryCode = DeliveryModeCodeType | b2bDelivery | '';

const map: { [key in DeliveryCode]: { icon: any; textKey: string } } = {
  '': { icon: ChooseDeliveryIcon, textKey: 'delivery->selectDeliveryMode' },
  homeDelivery: { icon: HomeDeliveryIcon, textKey: 'delivery->homeDelivery' },
  pickUpInStore: { icon: PickUpInStoreIcon, textKey: 'delivery->pickUpInStore' },
  b2bDelivery: { icon: HomeDeliveryIcon, textKey: 'delivery->delivery' },
};

const adjustForB2b = (deliveryMode: DeliveryCode, isB2b: boolean) => {
  return isB2b && deliveryMode === 'homeDelivery' ? 'b2bDelivery' : deliveryMode;
};
const UseformatedDate = (deliveryTime: string) => {
  const trimmedDeliveryTime = deliveryTime.replace(/.*?(\d+ \D+) (\d+:\d+-\d+:\d+)/, '$1 kl $2');
  return trimmedDeliveryTime;
};

const FormatedDates = () => {
  const confirmedSlot = useAppSelector(selectCartSlot);
  const formatedDate = confirmedSlot && confirmedSlot.formattedTime ? UseformatedDate(confirmedSlot.formattedTime) : '';

  return formatedDate;
};

const ButtonContents = ({ mobile = false, modalIsOpen }: { mobile?: boolean; modalIsOpen: boolean }) => {
  const { t } = useTranslation('header');
  const deliveryMode = useAppSelector(selectCartDeliveryModeCode) as DeliveryCode;
  const { isB2BCustomer = false } = useCustomer();
  const data = map[adjustForB2b(deliveryMode, isB2BCustomer)];
  const formatedDate = FormatedDates();
  const deliveryTime = formatedDate ? formatedDate : t(data.textKey);
  const isDelivery = deliveryMode !== PICKUP_CODE;

  return (
    <>
      <RedIconWithTextContent
        mobile={mobile}
        icon={data.icon}
        text={deliveryTime}
        slotSelected={formatedDate}
        deliveryText={data.textKey}
        isDelivery={isDelivery}
        deliveryMode={deliveryMode}
      />
      {deliveryMode && (
        <IconWrapper $modalIsOpen={modalIsOpen}>
          <Icon icon={IconContinue} color={mobile ? 'red' : 'black'} size={mobile ? 20 : 24} />
        </IconWrapper>
      )}
    </>
  );
};

const DeliveryButton = ({
  onClick,
  mobile = false,
  modalIsOpen,
}: {
  onClick: () => void;
  mobile?: boolean;
  modalIsOpen: boolean;
}) => {
  const deliveryMode = useAppSelector(selectCartDeliveryModeCode) as DeliveryCode;
  return (
    <SecondaryHeaderButton
      mobile={mobile}
      onClick={onClick}
      deliveryModeSelected={deliveryMode}
      modalIsOpen={modalIsOpen}
      aria-label="select-delivery-option"
    >
      <ButtonContents modalIsOpen={modalIsOpen} mobile={mobile} />
    </SecondaryHeaderButton>
  );
};

export default DeliveryButton;
