import Icon from '@components/atoms/Icon';
import { CategoryApiData } from '@components/cmsComponents/CategoryProductsComponent/types';
import CATEGORY_INFO from '@constants/categoryInfo';
import Heading from 'components/atoms/Heading';
import useTranslation from 'next-translate/useTranslation';
import { AgeRestrictionDisclaimer, AgeRestrictionImageWrapper } from './CategoryInfo.styles';

interface Props {
  restrictedProductTypes: CategoryApiData['restrictedProductTypes'];
}
const CategoryInfo = ({ restrictedProductTypes }: Props) => {
  const { t } = useTranslation('categorypage');
  const icon = restrictedProductTypes && CATEGORY_INFO[restrictedProductTypes].icon;
  const disclaimerCopyKey = (restrictedProductTypes && CATEGORY_INFO[restrictedProductTypes].translationKey) || '';

  return icon ? (
    <AgeRestrictionDisclaimer title={t(disclaimerCopyKey)}>
      <Heading type="h2" color="black" size="xSmall">
        {t(disclaimerCopyKey)}
      </Heading>
      <AgeRestrictionImageWrapper>
        <Icon icon={icon} size={161} data-testid={restrictedProductTypes} />
      </AgeRestrictionImageWrapper>
    </AgeRestrictionDisclaimer>
  ) : null;
};

export default CategoryInfo;
