import styled from 'styled-components';
import { rem } from 'polished';

export const Contents = styled.div<{ $rightJustified?: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${rem(16)};
  justify-content: flex-end;

  ${({ $rightJustified }) =>
    $rightJustified &&
    `
      align-items: flex-end;
      text-align: end;
    `}
`;
