import { H1, IHeadingStyleProps } from '@components/atoms/__DEPRECATED__/Heading/Heading.styles';
import { HeadingSize, HeadingType } from '@components/atoms/__DEPRECATED__/Heading/types';
import { IParagraphStyleProps, ParagraphStyled } from '@components/atoms/__DEPRECATED__/Paragraph/Paragraph.styles';
import { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { ParagraphBoldType, ParagraphSizeType } from '../__DEPRECATED__/Paragraph/types';

interface ITextWrapperProps {
  $strikeThrough?: boolean;
}

interface IStyledTextProps extends React.HTMLAttributes<HTMLParagraphElement | HTMLHeadingElement> {
  $type: HeadingType | 'p';
  $color: ColorType | Theme | '';
  $size: HeadingSize | ParagraphSizeType;
  $bold: '' | 'bold' | ParagraphBoldType;
  className?: string;
  $truncateText?: boolean;
  children: ReactNode;
  as?: string;
}

export const TextWrapper = styled.div<ITextWrapperProps>`
  ${(props) => props.$strikeThrough && 'text-decoration: line-through;'}
`;

const EmulatedH1 = styled(H1)<IHeadingStyleProps>`
  font-family: ${({ theme }) => theme.typography.fontSerif};
`;

const EmulatedH2 = styled(EmulatedH1)<IHeadingStyleProps>``;

const EmulatedH3 = styled(EmulatedH1)<IHeadingStyleProps>``;

const EmulatedH4 = styled(EmulatedH1)<IHeadingStyleProps>``;

const EmulatedParagraph = styled(ParagraphStyled)<IParagraphStyleProps>`
  font-family: ${({ theme }) => theme.typography.fontSans};

  ${(props) =>
    !props.$textColor &&
    css`
      color: ${({ theme }) => theme.colors.colorTextPrimary};
    `}
  ${(props) =>
    props.$truncateText &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

/* eslint-disable react/jsx-props-no-spreading */
export const StyledText = ({
  $color,
  $size,
  $type,
  $bold,
  className,
  children,
  $truncateText,
  role,
  as = 'div',
}: IStyledTextProps) => {
  const level = $type.length > 1 ? parseInt($type.charAt($type.length - 1)) : undefined;

  const headingProps = {
    $bold: Boolean($bold),
    $type: $type as HeadingType,
    $size: $size as HeadingSize,
    $color: $color as ColorType,
    className,
    role: role || 'heading',
    'aria-level': level,
    as,
  };

  switch ($type) {
    case 'h1':
      return <EmulatedH1 {...headingProps}>{children}</EmulatedH1>;
    case 'h2':
      return <EmulatedH2 {...headingProps}>{children}</EmulatedH2>;
    case 'h3':
      return <EmulatedH3 {...headingProps}>{children}</EmulatedH3>;
    case 'h4':
      return <EmulatedH4 {...headingProps}>{children}</EmulatedH4>;
    case 'p':
      return (
        <EmulatedParagraph
          $bold={$bold}
          $size={$size as ParagraphSizeType}
          $textColor={$color as '' | Theme}
          className={className}
          $truncateText={$truncateText}
          role={role}
          as={as}
        >
          {children}
        </EmulatedParagraph>
      );
    default:
      return null;
  }
};
