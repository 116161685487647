import { PictureImgStyled } from '@components/atoms/Picture/Picture.styles';
import media from '@constants/responsive';
import { CmsComponentPadding } from '@styles/cms';
import { rem } from 'polished';
import styled from 'styled-components';

export const VerticalBannerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  border-radius: ${rem(12)};
  overflow: hidden;
  ${CmsComponentPadding}

  ${media.tabletPortrait} {
    border-radius: ${rem(16)};
    flex-direction: column;
  }
  ${PictureImgStyled} {
    object-fit: cover;
    border-bottom-left-radius: ${rem(12)};

    ${media.tabletPortrait} {
      border-bottom-left-radius: 0;
    }
  }
`;

export const VerticalBannerContent = styled.div`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 66%;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${rem(24)} ${rem(16)} ${rem(16)} ${rem(24)};
  border-top-right-radius: ${rem(12)};
  border-bottom-right-radius: ${rem(12)};

  ${media.tabletPortrait} {
    width: 100%;
    min-height: ${rem(170)};
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: ${rem(16)};
  }
`;

export const ButtonWrapper = styled.div`
  margin: 0;

  ${media.tabletPortrait} {
    margin: ${rem(16)} 0;
  }
`;

export const MinHeightWrapper = styled.div`
  min-height: ${rem(50)};
  margin: 0 0 auto 0;
`;

export const BannerImage = styled.div`
  width: 34%;
  flex-shrink: 0;
  ${media.tabletPortrait} {
    width: 100%;
  }
`;
