import { AxfoodPromotionViewModel, ImageData } from '@api/generated/storefront';
import Paragraph from '@components/atoms/Paragraph';
import { productTestId } from '@components/molecules/Product/constants';
import { Product as ProductType } from '@components/molecules/Product/ProductDataMapper/types';
import { URLPREFIXES } from '@constants/links';
import { getProductUrl } from '@helpers/productHelper';
import useAppRouter from '@hooks/useAppRouter';
import { productTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode } from 'react';
import { ProductWrapper, PromotionUsedText } from './Product.styles';
import ProductAddToList from './ProductAddToList';
import ProductImageSection from './ProductImageSection';
import ProductInformation from './ProductInformation/ProductInformation';
import ProductMixAndMatch from './ProductMixAndMatch/ProductMixAndMatch';

export interface ProductProps {
  product: ProductType;
  onClick?: () => unknown;
  disableLinks?: boolean;
  border?: boolean;
  children?: ReactNode | undefined;
  eventListName?: string;
  layout?: 'vertical' | 'horizontal';
  withAddToList?: boolean;
  isPreview?: boolean;
  disableMixAndMatch?: boolean;
}

export const urlPrefix = URLPREFIXES.PRODUCT_DETAIL;

export interface ProductViewModel {
  potentialPromotions: Array<AxfoodPromotionViewModel>;
  priceUnit?: string;
  online?: boolean;
  manufacturer?: string;
  name?: string;
  newsSplashProduct?: boolean;
  code?: string;
  image?: ImageData;
}

export const Product = ({
  product,
  onClick,
  disableLinks = false,
  children,
  eventListName,
  withAddToList = true,
  isPreview = false,
  disableMixAndMatch = false,
}: ProductProps) => {
  const router = useAppRouter();
  const { t } = useTranslation('product');
  const {
    name: productName,
    promotion,
    offline,
    previewUrl,
    productUrl,
    hasPromotion,
    price: { unit },
    analytics: { impressionObject },
  } = product;
  const { isPromotionUsed, productCode: mainProductCode } = (hasPromotion && promotion) || {};

  const isOfflinePromotionUsed: boolean = (offline && isPromotionUsed) || false;
  const as = isPreview ? `${urlPrefix}${encodeURI(previewUrl)}` : `${urlPrefix}${encodeURI(productUrl)}`;

  const isMixAndMatch = promotion?.isMixAndMatch;

  const query = isPreview
    ? {
        ...router.query,
        name: encodeURIComponent(previewUrl),
        productCode: mainProductCode,
      }
    : {
        ...router.query,
        name: encodeURIComponent(getProductUrl(product)),
        productCode: product.code,
        scrollToMixAndMatch: 'false',
      };
  const onProductLinkClicked = () => {
    if (eventListName) {
      productTracker.trackProductClickedImpression(impressionObject, eventListName);
    }
  };

  const detailsLinkAriaLabel = t('detailsLinkAriaLabel', { productName });

  return (
    <>
      <ProductWrapper onClick={onClick} data-testid={productTestId} $isPromotionUsed={isOfflinePromotionUsed}>
        <ProductImageSection
          product={product}
          withAddToList={withAddToList}
          disableLinks={disableLinks || isOfflinePromotionUsed}
          isPreview={isPreview}
          onProductLinkClicked={onProductLinkClicked}
          href={{ pathname: router.pathname, query }}
          as={{ pathname: as }}
          linkAriaLabel={detailsLinkAriaLabel}
        >
          {withAddToList && <ProductAddToList product={product} />}
        </ProductImageSection>
        <ProductInformation
          product={product}
          url={{ pathname: router.pathname, query }}
          as={{ pathname: as }}
          priceUnit={unit}
          disableLinks={disableLinks || isOfflinePromotionUsed}
          handleProductLinkClick={onProductLinkClicked}
          linkAriaLabel={detailsLinkAriaLabel}
        />
        {children}

        {isPromotionUsed && (
          <PromotionUsedText>
            <Paragraph data-testid="promotion-used">{t('product:isPromotionUsed')}</Paragraph>
          </PromotionUsedText>
        )}
      </ProductWrapper>
      {isMixAndMatch && !disableMixAndMatch && (
        <ProductMixAndMatch
          url={{ pathname: router.pathname, query: { ...query, scrollToMixAndMatch: 'true' } }}
          as={as}
        />
      )}
    </>
  );
};

export default Product;
