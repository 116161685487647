import { StyledButton } from '@components/atoms/Button/Button.styles';
import Link, { LinkProps } from 'next/link';
import { Props as ButtonProps } from './Button';

type Props = LinkProps & ButtonProps;

const LinkButton = ({
  children,
  variant = 'primary',
  'aria-label': ariaLabel,
  'aria-expanded': ariaExpanded,
  onClick,
  className,
  size = 'md',
  linkTarget,
  ...linkProps
}: Props) => {
  return (
    <Link legacyBehavior passHref {...linkProps}>
      <StyledButton
        as="a"
        aria-label={ariaLabel}
        $variant={variant}
        onClick={onClick}
        aria-expanded={ariaExpanded}
        className={className}
        $size={size}
        data-testid="link-button"
        target={linkTarget}
      >
        {children}
      </StyledButton>
    </Link>
  );
};

export default LinkButton;
