import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div<{ $isVisible: boolean }>`
  position: initial;
  right: 0;
  transition: opacity 1s;
  text-align: center;
  opacity: ${(props) => (props.$isVisible ? '1' : '0')};
`;

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: ${rem(10)};
  right: ${rem(16)};

  ${media.tabletPortrait} {
    right: 0;
  }
`;
