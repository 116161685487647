import styled from 'styled-components';
import { rem } from 'polished';
import media from '@constants/responsive';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.colorWhite};
  margin: 0 ${rem(5)};
  ${media.tabletPortrait} {
    margin: 0;
    padding: ${rem(30)} ${rem(20)};
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  padding: ${rem(20)} ${rem(10)};
  ${media.tabletPortrait} {
    padding: ${rem(30)} ${rem(20)};
  }
  ${media.desktop} {
    width: ${rem(655)};
  }
  ${media.desktopXL} {
    width: ${rem(825)};
  }
`;
