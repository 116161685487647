import * as types from '@actions/cart/types';
import { AxfoodCartViewModel } from '@occ/api-client';

// TODO: remove this when B2C-22729 is fixed
export type CorrectedCart = AxfoodCartViewModel & {
  subTotalPriceWithoutDiscountsNumber?: number;
};
export interface CartStoreState {
  hasFetchedCart: boolean;
  cart: CorrectedCart;
  totalProductAmount: number;
  ongoingCartUpdates: number;
  queuedUpdateStatus: number;
}

const initialState: CartStoreState = {
  hasFetchedCart: false,
  cart: {
    ageRestricted15: false,
    ageRestricted18: false,
    allergyApproved: false,
    anonymousOrder: false,
    appliedVouchers: [],
    ascFish: false,
    b2bCustomer: false,
    b2bSlot: false,
    customerNewProgramEnabled: false,
    deliverySlot: false,
    ecological: false,
    ekoReplacement: false,
    environmentalChoice: false,
    euEcological: false,
    euFlower: false,
    fairtrade: false,
    franchiseStore: false,
    freeDeliveryOrPickupThresholdStatus: {
      applied: false,
      eligible: false,
    },
    freeShipping: false,
    frozen: false,
    ghsSignalWordDanger: false,
    ghsSignalWordNotApplicable: false,
    ghsSignalWordWarning: false,
    ghsSymbolCorrosion: false,
    ghsSymbolEnvironment: false,
    ghsSymbolExcalmationMark: false,
    ghsSymbolExplodingBomb: false,
    ghsSymbolFlame: false,
    ghsSymbolFlameOverCircle: false,
    ghsSymbolGasCylinder: false,
    ghsSymbolHealthHazard: false,
    ghsSymbolNoPictogram: false,
    ghsSymbolSkullAndCrossbones: false,
    glutenfree: false,
    hasNicotineMedical: false,
    keyhole: false,
    krav: false,
    laktosfree: false,
    localB2bCustomer: false,
    mscFish: false,
    notEditableAfterOrder: false,
    oldVouchersNotApplied: false,
    products: [],
    recipes: [],
    slotCloseDay: 0,
    slotFormattedDate: '',
    slotStartDay: 0,
    storeChangeAllowed: false,
    subTotalPrice: '',
    subTotalPriceWithDiscountsAndVouchers: '',
    subTotalPriceWithoutDiscounts: '',
    subTotalPriceWithoutDiscountsNumber: 0,
    swan: false,
    swedishBird: false,
    swedishFlag: false,
    swedishMeat: false,
    taxes: {},
    timeUntilSlotCloseTime: 0,
    totalDepositSum: '',
    totalDiscount: '',
    totalDiscountValue: 0,
    totalGeneralDiscount: '',
    totalGeneralDiscountValue: 0,
    totalItems: 0,
    totalLoyaltyDiscount: '',
    totalPrice: '',
    totalPromotionDiscount: '',
    totalPromotionDiscountValue: 0,
    totalTax: '',
    totalUnitCount: 0,
    usersOrderReference: '',
    suggestedPickUpStoreUid: '',
  },
  // ongoingCartUpdates is the number of queued or active (waiting api calls) cart updates which currently exists
  ongoingCartUpdates: 0,
  // queuedUpdateStatus is one of the values of QUEUE_STATUSES, which represents which state the cart currently is in
  queuedUpdateStatus: 0,
  totalProductAmount: 0,
};

const cartReducer = (state: CartStoreState = initialState, action: any): CartStoreState => {
  switch (action.type) {
    case types.SET_CART:
      return { ...state, cart: action.cart, hasFetchedCart: true };
    case types.SET_CART_TOTAL_PRODUCT_AMOUNT:
    case types.SET_FIXED_CART_TOTAL_PRODUCT_AMOUNT:
      return { ...state, totalProductAmount: action.amount };
    case types.SET_CART_UPDATE_QUEUE_STATUS:
      if (state.queuedUpdateStatus !== action.status) {
        return { ...state, queuedUpdateStatus: action.status };
      }
      return state;
    case types.INCREMENT_ONGOING_CART_UPDATES:
      return { ...state, ongoingCartUpdates: state.ongoingCartUpdates + 1 };
    case types.DECREMENT_ONGOING_CART_UPDATES:
      return { ...state, ongoingCartUpdates: state.ongoingCartUpdates >= 1 ? state.ongoingCartUpdates - 1 : 0 };
    default:
      return state;
  }
};

export default cartReducer;
export { initialState };
