/* tslint:disable */
/* eslint-disable */
/**
 * axfoodstorefront API
 * axfoodstorefront Documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AxfoodCategorySearchResultViewModel } from '../dto';
// @ts-ignore
import { ProductCategorySearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModelAndCategoryData } from '../dto';
/**
 * CategoryApi - axios parameter creator
 * @export
 */
export const CategoryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get category
         * @param {string} [q] q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryUsingGET: async (q?: string, page?: number, size?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/**/c/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get products for category
         * @param {string} categoryCode categoryCode
         * @param {string} [q] q
         * @param {number} [page] page
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultsUsingGET: async (categoryCode: string, q?: string, page?: number, sort?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryCode' is not null or undefined
            assertParamExists('getResultsUsingGET', 'categoryCode', categoryCode)
            const localVarPath = `/**/c/**/results`
                .replace(`{${"categoryCode"}}`, encodeURIComponent(String(categoryCode)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2_password required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2_password", [], configuration)

            if (q !== undefined) {
                localVarQueryParameter['q'] = q;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CategoryApi - functional programming interface
 * @export
 */
export const CategoryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CategoryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get category
         * @param {string} [q] q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategoryUsingGET(q?: string, page?: number, size?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductCategorySearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModelAndCategoryData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategoryUsingGET(q, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get products for category
         * @param {string} categoryCode categoryCode
         * @param {string} [q] q
         * @param {number} [page] page
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getResultsUsingGET(categoryCode: string, q?: string, page?: number, sort?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AxfoodCategorySearchResultViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getResultsUsingGET(categoryCode, q, page, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CategoryApi - factory interface
 * @export
 */
export const CategoryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CategoryApiFp(configuration)
    return {
        /**
         * 
         * @summary Get category
         * @param {string} [q] q
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryUsingGET(q?: string, page?: number, size?: number, sort?: string, options?: any): AxiosPromise<ProductCategorySearchPageDataOfSearchStateDataAndAxfoodBasicProductViewModelAndCategoryData> {
            return localVarFp.getCategoryUsingGET(q, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get products for category
         * @param {string} categoryCode categoryCode
         * @param {string} [q] q
         * @param {number} [page] page
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResultsUsingGET(categoryCode: string, q?: string, page?: number, sort?: string, options?: any): AxiosPromise<AxfoodCategorySearchResultViewModel> {
            return localVarFp.getResultsUsingGET(categoryCode, q, page, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CategoryApi - object-oriented interface
 * @export
 * @class CategoryApi
 * @extends {BaseAPI}
 */
export class CategoryApi extends BaseAPI {
    /**
     * 
     * @summary Get category
     * @param {string} [q] q
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public getCategoryUsingGET(q?: string, page?: number, size?: number, sort?: string, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).getCategoryUsingGET(q, page, size, sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get products for category
     * @param {string} categoryCode categoryCode
     * @param {string} [q] q
     * @param {number} [page] page
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CategoryApi
     */
    public getResultsUsingGET(categoryCode: string, q?: string, page?: number, sort?: string, options?: AxiosRequestConfig) {
        return CategoryApiFp(this.configuration).getResultsUsingGET(categoryCode, q, page, sort, options).then((request) => request(this.axios, this.basePath));
    }
}
