import styled from 'styled-components';
import { rem } from 'polished';

export const NicotineNotificationWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const NicotineNotificationText = styled.div`
  margin-left: ${rem(12)};
`;
