import useAppRouter from '@hooks/useAppRouter';
import { useEffect } from 'react';

const newPageSymplify = () => {
  if (typeof window.symplify !== 'undefined' && typeof window.symplify.newPage === 'function') {
    window.symplify.newPage();
  }
};

export const handleSymplifyNewPage = () => {
  if (typeof window.symplify !== 'undefined') {
    newPageSymplify();
  } else {
    const symplifyLoadedHandler = () => {
      if (typeof window.symplify !== 'undefined') {
        newPageSymplify();
        document.removeEventListener('symplify-booted', symplifyLoadedHandler);
      }
    };
    // when using cookie consent, symplify-booted should be used instead of symplify-loaded
    // reference: https://conversion.symplify.com/hc/en-us/articles/4414689707282-Implementation-for-Single-Page-Applications-SPA
    document.addEventListener('symplify-booted', symplifyLoadedHandler);
  }
};

const useSymplifyNewPage = () => {
  const router = useAppRouter();

  //on route change
  useEffect(() => {
    router.events.on('routeChangeComplete', handleSymplifyNewPage);
    return () => {
      router.events.off('routeChangeComplete', handleSymplifyNewPage);
    };
  }, []);

  // on mount
  useEffect(() => {
    handleSymplifyNewPage();
  }, []);
};

const useSymplifyABTesting = () => {
  useSymplifyNewPage();
};

export default useSymplifyABTesting;
