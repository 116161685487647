import { AxfoodRecipeBasicInfoViewModel } from '@api/generated/storefront';
import gtmTrack from '@helpers/gtmTrack';

const sphereName = 'erbjudanden';
export const RECIPE_BANNER = 'recipe_banner';
export const RECEPTSOK = 'receptsok';
const HERO_BANNER = 'hero_banner';

export interface PromotionItem {
  promotion_id: string;
  promotion_name: string;
  creative_name: string;
  creative_slot: string;
}
export const trackSelectPromotionStore = (storeName?: string) => {
  if (storeName) {
    gtmTrack({
      event: 'select_store',
      store_name: storeName,
      sphere_name: sphereName,
    });
  }
};

export const trackRecipeClick = (recipe: AxfoodRecipeBasicInfoViewModel) => {
  const item = mapRecipeItem(recipe.id, recipe.name);
  trackEcommercePromotion([item], 'select_promotion');
};
export const trackRecipesView = (recipes: Array<AxfoodRecipeBasicInfoViewModel>) => {
  const items = recipes.map((recipe) => mapRecipeItem(recipe.id, recipe.name));
  trackEcommercePromotion(items, 'view_promotion');
};

export const trackHeroBannerClick = (link: string, buttonText: string, slotPosition: number = 1) => {
  const item = mapHeroItem(link, buttonText, slotPosition.toString());
  trackEcommercePromotion([item], 'select_promotion');
};

export const trackHeroBannerView = (link: string, buttonText: string, slotPosition: number = 1) => {
  const item = mapHeroItem(link, buttonText, slotPosition.toString());
  trackEcommercePromotion([item], 'view_promotion');
};

export const trackCarouselShifted = () => {
  gtmTrack({
    event: 'element_clicked',
    element_type: 'carousel_promo_banner',
  });
};

export type PromoItemCreativeName = 'promo_banner' | 'carousel_promo_banner';

export const trackPromoBannerView = (
  link: string,
  name: string,
  slotPosition: string,
  creativeName: PromoItemCreativeName
) => {
  const item = mapPromoBanner(link, name, slotPosition, creativeName);
  trackEcommercePromotion([item], 'view_promotion');
};

export const trackPromoBannerClick = (
  link: string,
  name: string,
  slotPosition: string,
  creativeName: PromoItemCreativeName
) => {
  const item = mapPromoBanner(link, name, slotPosition, creativeName);
  trackEcommercePromotion([item], 'select_promotion');
};

const mapRecipeItem = (id: string = '', name: string = ''): PromotionItem => {
  return {
    promotion_id: id,
    promotion_name: name,
    creative_name: RECIPE_BANNER,
    creative_slot: RECEPTSOK,
  };
};

const mapPromoBanner = (
  link: string,
  name: string,
  slotPosition: string,
  creativeName: PromoItemCreativeName
): PromotionItem => {
  return {
    promotion_id: link,
    promotion_name: name,
    creative_name: creativeName,
    creative_slot: slotPosition,
  };
};

const mapHeroItem = (link: string, buttonText: string, slotPosition: string): PromotionItem => {
  return {
    promotion_id: link,
    promotion_name: buttonText,
    creative_name: HERO_BANNER,
    creative_slot: slotPosition,
  };
};
export const trackEcommercePromotion = (items: PromotionItem[], event: 'select_promotion' | 'view_promotion') => {
  gtmTrack({
    event,
    ecommerce: {
      items,
    },
  });
};
