import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const LinkButtonWrapper = styled.div`
  min-width: ${rem(144)};
  max-width: max-content;
  margin: auto;

  ${media.tabletLandscape} {
    margin: 0;
  }
`;
