const debounce = (fn: (...args: any[]) => unknown, ms: number) => {
  let timer: any;

  return async (...args: any[]): Promise<any> => {
    return new Promise((resolve, reject) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        timer = undefined;
        try {
          resolve(fn(...args));
        } catch (e) {
          reject(e);
        }
      }, ms);
    });
  };
};

export default debounce;
