import { DEFAULT_SANITIZE } from '@constants/formValidation';
import React, { ReactNode } from 'react';
import { FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form';
import Tooltip from '../Tooltip';
import { ErrorLabel, InputContainer, InputLabel, InputStyled, TooltipWrapper } from './InputField.styles';

export type InputType = 'text' | 'email' | 'password' | 'tel';

export interface Props<FormData extends FieldValues> {
  type?: InputType;
  label: string | ReactNode;
  name: Path<FormData>;
  id: string;
  register: UseFormRegister<FormData>;
  isActive: boolean;
  // rules api: https://react-hook-form.com/api/useform/register#options
  rules?: RegisterOptions;
  error?: string;
  maxLength?: number;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  setValueAs?: (value: any) => void;
  disableAutoComplete?: boolean;
  tooltip?: { toolTipContent: string; tooltipText: string };
  disabled?: boolean;
  'data-testid'?: string;
  autoFocus?: boolean;
}

/**
 * @summary input field to be used with react-hook-form
 * FormData type passed to Props comes from the useForm in the parent component
 */

const InputField = <FormData extends FieldValues>({
  type = 'text',
  label,
  name,
  id,
  register,
  isActive,
  rules,
  error,
  onChange,
  onBlur,
  onFocus,
  maxLength,
  setValueAs,
  onKeyUp,
  disableAutoComplete = false,
  tooltip,
  disabled = false,
  'data-testid': dataTestId = 'input-field',
  autoFocus = false,
}: Props<FormData>) => {
  let options: RegisterOptions = rules ? rules : {};
  options.pattern = rules?.pattern ? rules.pattern : DEFAULT_SANITIZE;

  return (
    <InputContainer $disabled={disabled}>
      <label>
        <InputStyled
          autoFocus={autoFocus}
          disabled={disabled}
          data-testid={dataTestId}
          type={type}
          id={id}
          $isActive={isActive}
          aria-invalid={!!error}
          maxLength={maxLength}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
          required={!!rules?.required}
          autoComplete={disableAutoComplete ? 'new-password' : 'on'}
          {...register(name, { onChange, onBlur, setValueAs, ...options })}
        />
        <InputLabel>{label}</InputLabel>
      </label>
      {tooltip && (
        <TooltipWrapper>
          <Tooltip toolTipText={tooltip.tooltipText} showToolTipText={false}>
            {tooltip.toolTipContent}
          </Tooltip>
        </TooltipWrapper>
      )}
      {error && <ErrorLabel data-testid="inputfield-error">{error}</ErrorLabel>}
    </InputContainer>
  );
};

export default InputField;
