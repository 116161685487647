import { rem } from 'polished';
import { css } from 'styled-components';

const chatBg = '#dae1e8';
export default css`
  .cx-webchat-chat-button {
    display: none !important;
  }
  .cx-btn {
    border-radius: 1rem !important;
  }
  .cx-widget.cx-theme-light * {
    border-color: ${({ theme }) => theme.colors.colorPrimary} !important;
    color: ${({ theme }) => theme.colors.colorTextPrimary};
    border-radius: ${rem(2)};
    font-family: ${({ theme }) => theme.typography.fontSans};
  }
  .cx-widget.cx-theme-light .cx-side-button-group .cx-side-button {
    background-color: ${({ theme }) => theme.colors.colorPrimary} !important;
    color: ${({ theme }) => theme.colors.colorWhite};
  }
  .cx-widget.cx-theme-light .cx-svg-icon-tone1 {
    fill: ${({ theme }) => theme.colors.colorWhite} !important;
  }
  .cx-widget.cx-theme-light .cx-branding *,
  .cx-widget.cx-theme-light .cx-footer * {
    fill: ${({ theme }) => theme.colors.colorPrimary} !important;
  }
  .cx-widget.cx-theme-light .cx-btn-default {
    border-color: ${({ theme }) => theme.colors.colorCararra} !important;
    background: ${({ theme }) => theme.colors.colorCararra} !important;
    color: ${({ theme }) => theme.colors.colorTextPrimary} !important;
    border-radius: ${rem(16)};
  }
  .cx-widget.cx-theme-light .cx-btn-primary {
    border-color: ${({ theme }) => theme.colors.colorPrimary} !important;
    background: ${({ theme }) => theme.colors.colorPrimary} !important;
  }
  .cx-widget.cx-theme-light .cx-error-btn.cx-btn.cx-btn-primary {
    border-color: ${({ theme }) => theme.colors.colorPrimary} !important;
    background: ${({ theme }) => theme.colors.colorPrimary} !important;
  }
  .cx-widget.cx-theme-light .cx-end-confirm.cx-btn.cx-btn-primary {
    border-color: ${({ theme }) => theme.colors.colorPrimary} !important;
  }
  .cx-widget.cx-theme-light .cx-submit.cx-btn.cx-btn-primary {
    border-color: ${({ theme }) => theme.colors.colorPrimary} !important;
    border-radius: ${rem(16)};
  }
  .cx-widget.cx-theme-light .cx-form-control:focus {
    border-color: ${({ theme }) => theme.colors.colorPrimary} !important;
  }
  .cx-widget.cx-theme-light input[type='text'] {
    border-color: ${({ theme }) => theme.colors.colorPrimary} !important;
  }
  .cx-widget.cx-theme-light input[type='email'] {
    border-color: ${({ theme }) => theme.colors.colorPrimary} !important;
  }
  .cx-widget.cx-theme-light .cx-form-control:focus {
    border-color: ${({ theme }) => theme.colors.colorPrimary} !important;
  }
  .cx-widget.cx-theme-light .cx-chat-button-label {
    color: ${({ theme }) => theme.colors.colorWhite};
  }
  .cx-widget.cx-theme-light .cx-wrapper {
    margin-top: ${rem(10)};
  }
  .cx-widget.cx-theme-light .cx-button-group button:focus {
    border-color: ${({ theme }) => theme.colors.colorPrimary} !important;
  }
  .cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble-arrow svg * {
    fill: ${chatBg} !important;
  }
  .cx-widget.cx-theme-light .cx-message.cx-them .cx-bubble {
    background-color: ${chatBg} !important;
  }
  .cx-widget.cx-theme-light .cx-titlebar {
    background-color: ${({ theme }) => theme.colors.colorPrimary};
    color: ${({ theme }) => theme.colors.colorWhite};
  }
  .cx-widget.cx-theme-light .cx-title {
    font-size: ${rem(24)} !important;
    color: ${({ theme }) => theme.colors.colorWhite} !important;
    font-weight: bold;
    font-family: ${({ theme }) => theme.typography.fontSerif};
  }
  .cx-widget.cx-theme-light .cx-message.cx-date {
    color: ${({ theme }) => theme.colors.colorTextPrimary} !important;
  }
  .cx-widget.cx-theme-light .cx-common-container {
    color: ${({ theme }) => theme.colors.colorWhite};
  }
  .cx-widget.cx-theme-light .cx-button-container {
    background-color: ${({ theme }) => theme.colors.colorPrimary};
  }
  .cx-widget.cx-theme-light .cx-webchat .cx-form th .cx-control-label {
    border-color: ${({ theme }) => theme.colors.colorPrimary};
  }
  .cx-widget.cx-theme-light .cx-widget .cx-form-control {
    border-color: ${({ theme }) => theme.colors.colorPrimary};
  }
  .cx-widget.cx-theme-light .cx-sidebar {
    background-color: ${({ theme }) => theme.colors.colorPrimary};
    color: ${({ theme }) => theme.colors.colorWhite};
  }
`;
