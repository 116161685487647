import media, { BREAKPOINTS } from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const GRID_SLOTS = 12;
export const PAGE_MAX_WIDTH = BREAKPOINTS.xxl;

const Container = styled.div`
  width: 100%;
  position: relative;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(${GRID_SLOTS}, 1fr);
  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  gap: ${rem(16)};
`;

export const LayoutMargins = css`
  margin: auto ${rem(16)};

  ${media.tabletPortrait} {
    gap: ${rem(16)};
    margin: auto ${rem(32)};
  }

  ${media.desktopXL} {
    max-width: ${rem(PAGE_MAX_WIDTH)};
    margin: 0 auto;
    padding: 0 ${rem(64)};
    box-sizing: border-box;
  }
`;

export const LayoutContainer = styled.div`
  ${LayoutMargins}
`;

export default Container;
