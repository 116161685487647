import TagManager from 'react-gtm-module';

export const videoPlayedTracker = (headLine: string, youtubeId?: string) => {
  TagManager.dataLayer({
    dataLayer: {
      event: 'track',
      action: 'video_played',
      label: headLine,
      youtubeId,
      category: 'video',
    },
  });
};
