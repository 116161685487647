import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
export const HeaderWrapper = styled.div`
  padding: 0 0 ${rem(6)};
`;
