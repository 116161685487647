import Icon from '@components/atoms/Icon';
import LINKS from '@constants/links';
import useAppRouter from '@hooks/useAppRouter';
import useCmsComponents from '@hooks/useCmsComponentGroup';
import useResponsive from '@hooks/useResponsive';
import IconClose from '@public/icons/regularIcons/icon-close.svg';
import useTranslation from 'next-translate/useTranslation';
import { useState } from 'react';
import { AxfoodContentUspCmsComponentSkeleton } from './AxfoodContentUspCmsComponent.skeleton';
import { CloseButton, Container, Content } from './AxfoodContentUspCmsComponent.styles';
import ContentUspComponent from './ContentUspComponent';

const isCmsUspComponent = (component: CmsComponent): component is ContentUspComponentType => {
  return 'contentUspText' in component;
};

export interface AxfoodContentUspCmsComponentProps {
  component: AxfoodContentUspCmsComponentType;
}

const AxfoodContentUspCmsComponent = ({ component }: AxfoodContentUspCmsComponentProps) => {
  const { isTabletPortraitOrGreater } = useResponsive();
  const { pathname } = useAppRouter();
  const { data: cmsComponentData = [], isLoading } = useCmsComponents(component?.contentUsps);

  const numberOfUsps = isTabletPortraitOrGreater ? 3 : 1;
  const [isVisible, setIsVisible] = useState(true);

  const { t } = useTranslation('common');

  // TODO: B2C-27121 temporary fix until home page is not fetched on every page
  // right now, this component will be displayed on every page when added to the messagebox slot in homepage
  // should only be visible on handla
  const isShopPage = pathname === LINKS.SHOP;

  if (isLoading) {
    return <AxfoodContentUspCmsComponentSkeleton />;
  }

  if (!cmsComponentData.length || !isShopPage || !isVisible) {
    return null;
  }

  return (
    <Container>
      <Content>
        {cmsComponentData
          .slice(0, numberOfUsps)
          .map((uspComponent) =>
            isCmsUspComponent(uspComponent) ? (
              <ContentUspComponent key={uspComponent.uuid} component={uspComponent} />
            ) : null
          )}
      </Content>
      <CloseButton aria-label={t('defaultActions->close')} onClick={() => setIsVisible(false)}>
        <Icon icon={IconClose}></Icon>
      </CloseButton>
    </Container>
  );
};

export default AxfoodContentUspCmsComponent;
