// TODO: This business logic will moved to BE and should be removed from here once done and
// instead use api endpoint to fetch correct conflicting products.

import type { AxfoodCartEntryStatusViewModel } from "@occ/api-client";


export const getAllConflictingProducts = (
  isB2B: boolean,
  products?: AxfoodCartEntryStatusViewModel[]
) =>
  products?.filter(
    (item) =>
      item.priceAbscent ||
      item.outOfStock ||
      item.partialOutOfStock ||
      !item.salableOnline ||
      item.notAllowedAnonymous ||
      item.notAllowedExternalPickupLocation ||
      item.notAllowedBulkyProduct ||
      (isB2B ? item.notAllowedB2b : false)
  ) ?? [];

export const getAllConflictingProductsExcepNotAllowedAnonymous = (
  isB2B: boolean,
  products?: AxfoodCartEntryStatusViewModel[]
) =>
  products?.filter(
    (item) =>
      item.priceAbscent ||
      item.outOfStock ||
      item.partialOutOfStock ||
      !item.salableOnline ||
      item.notAllowedExternalPickupLocation ||
      item.notAllowedBulkyProduct ||
      (isB2B ? item.entryProduct?.notAllowedB2b : false)
  );

export const getAllConflictingProductsExceptPartialWithReplacementsAndNotAllowed = (
  isB2B: boolean,
  products?: AxfoodCartEntryStatusViewModel[]
) =>
  products?.filter(
    (item) =>
      item.priceAbscent ||
      item.outOfStock ||
      (item.partialOutOfStock && !item.replacementProductsForProduct?.length) ||
      !item.salableOnline ||
      item.notAllowedExternalPickupLocation ||
      item.notAllowedBulkyProduct ||
      (isB2B ? item.entryProduct?.notAllowedB2b : false)
  );

export const getReplaceableProducts = (products: AxfoodCartEntryStatusViewModel[]) =>
  products.filter(
    (product) =>
      !product.notAllowedB2b &&
      !product.notAllowedAnonymous &&
      !product.notAllowedExternalPickupLocation &&
      !product.notAllowedBulkyProduct &&
      product.replacementProductsForProduct?.length
  );

export const getNonReplaceableProducts = (products: AxfoodCartEntryStatusViewModel[]) =>
  products.filter(
    (product) =>
      product.notAllowedB2b ||
      product.notAllowedAnonymous ||
      product.notAllowedExternalPickupLocation ||
      product.notAllowedBulkyProduct ||
      !product.replacementProductsForProduct?.length
  );

export const getNotAllowedAnonymousProducts = (products: AxfoodCartEntryStatusViewModel[]) =>
  products.filter((product) => product.notAllowedAnonymous);

export const getNotAllowedB2bProducts = (products: AxfoodCartEntryStatusViewModel[]) =>
  products.filter((product) => product.notAllowedB2b);

export default getAllConflictingProducts;
