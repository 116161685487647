import React, { memo, useState, useEffect } from 'react';
import useAppRouter from '@hooks/useAppRouter';
import ProductDetails from '@components/organisms/ProductDetails';
import { URLPREFIXES } from '@constants/links';

const urlPrefix = URLPREFIXES.PRODUCT_DETAIL;

const ProductDetailsModal = () => {
  const { asPath } = useAppRouter();
  const [productDetailVisibility, setProductDetailVisibility] = useState(false);

  useEffect(() => {
    if (asPath.indexOf(urlPrefix) > -1) {
      setProductDetailVisibility(true);
    } else {
      setProductDetailVisibility(false);
    }
  }, [asPath]);

  if (productDetailVisibility) {
    return <ProductDetails />;
  }
  return null;
};

export default memo(ProductDetailsModal);
