import ContentComponent from '@components/organisms/ContentComponent';
import { TextPanel } from '@components/organisms/ContentComponent/ContentComponent.styles';
import { CmsPanel } from '@styles/cms';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const PromoPanel = styled(CmsPanel)<{ $backgroundColor: string | undefined }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${({ $backgroundColor, theme }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `};
`;

export const PromoContentComponent = styled(ContentComponent)`
  position: relative;
  height: 100%;
  padding: ${({ theme }) => theme.spacing.medium};
  max-width: ${rem(454)};
  align-items: flex-start;
  text-align: start;
  gap: ${rem(24)};

  ${TextPanel} {
    gap: ${rem(4)};
  }
`;
