import { ComparePrice, InactivePromotion } from '@components/molecules/Product/ProductDataMapper/types';
import CAMPAIGN_TYPE from '@constants/campaignType';
import PROMOTION_THEME from '@constants/promotion';

export const getThumbnail = (hybrisProduct: any): string => {
  const thumbnail = hybrisProduct.thumbnail;
  if (typeof thumbnail === 'string') {
    return thumbnail;
  }
  if (thumbnail && 'url' in thumbnail && typeof thumbnail.url === 'string') {
    return thumbnail.url;
  }
  return '';
};
export const mapComparePrice = (value?: string, priceUnit?: string): ComparePrice => {
  if (!value) {
    return { value: '', unit: priceUnit, valueWithUnit: '' };
  }

  const [valueWithoutUnit, unit] = value.includes('/') ? value.split('/') : [value, priceUnit];
  const valueWithUnit = !!unit ? `${valueWithoutUnit}/${unit}` : valueWithoutUnit;

  return { value: valueWithoutUnit, unit, valueWithUnit };
};

export const mapInactivePromotions = (hybrisProduct: any): InactivePromotion | undefined => {
  const inactivePromotions = hybrisProduct?.inactivePotentialPromotions || [];
  const firstInactivePromotion = inactivePromotions?.[0];
  if (!firstInactivePromotion) {
    return undefined;
  }

  const { qualifyingCount, textLabelGenerated, textLabel, code = '' } = firstInactivePromotion;
  const isAlwaysGoodPrice = firstInactivePromotion?.promotionTheme?.code === PROMOTION_THEME.ALWAYS_GOOD_PRICE;
  const hasLoyaltyPromotion = firstInactivePromotion.campaignType === CAMPAIGN_TYPE.LOYALTY;
  const hasSegmentedPromotion = firstInactivePromotion.campaignType === CAMPAIGN_TYPE.SEGMENTED;
  const isPriceBomb =
    !hasLoyaltyPromotion &&
    !hasSegmentedPromotion &&
    firstInactivePromotion.promotionTheme?.code === PROMOTION_THEME.PRICE_BOMB;
  const isLoyalty = firstInactivePromotion.campaignType === CAMPAIGN_TYPE.LOYALTY;

  const { rewardLabel, conditionLabelFormatted, conditionLabel } = firstInactivePromotion || {};

  return {
    hasLoyaltyPromotion,
    hasSegmentedPromotion,
    isPriceBomb,
    isAlwaysGoodPrice,
    isLoyalty,
    qualifyingCount,
    textLabelGenerated,
    textLabel,
    promotionCode: code,
    rewardLabel,
    conditionLabelFormatted,
    conditionLabel,
  };
};
