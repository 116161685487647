import { getCurrentDate, getCurrentTime } from '@helpers/getCurrentDateAndTime';
import TagManager from 'react-gtm-module';

export const clickBlurb = (category: string, linkName: string) => {
  // GA3 - TO BE REMOVED - when GA3 is no longer active
  TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category,
      action: 'blurb_clicked',
      label: linkName,
      value: 0,
    },
  });

  // GA4 Tracking article item click
  const isArticleListItem = category.includes('article');
  if (isArticleListItem) {
    const CategoryName = category.replace('article | ', '');
    TagManager.dataLayer({
      dataLayer: {
        event: 'editorial_clicked',
        date: getCurrentDate(),
        editorial_name: linkName,
        editorial_list_name: CategoryName,
        sphere_name: 'content',
        time: getCurrentTime(),
      },
    });
  }
};

export const shiftCarousel = (label = '') => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: '/',
      action: 'carousel_shifted',
      label,
      value: 0,
    },
  });
};
