import { MenuContainer } from '@components/organisms/Header/AccountMenuPanel/AccountMenuPanel.styles';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const LoyaltyLoginWrapper = styled.div`
  position: initial;
  max-width: ${rem(250)};
  width: ${rem(192)};
`;

export const NotificationHeader = styled.div`
  font-size: ${rem(14)};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  text-decoration: none;
`;

export const NotificationContent = styled.div`
  margin: ${rem(6)} 0;
  text-align: center;
`;

export const Divider = styled.hr`
  background-color: ${({ theme }) => theme.colors.colorPampas};
  height: ${rem(2)};
  border: none;
  margin-top: ${rem(20)};
`;
export const LoginSection = styled.div`
  font-size: ${rem(12)};
  display: flex;
  justify-content: center;
  line-height: ${rem(14)};
`;

export const LoyaltyLoginPop = styled(MenuContainer)`
  margin: ${rem(8)} ${rem(16)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: ${rem(189)};
  width: ${rem(341)};
  padding: ${rem(16)};

  ${media.tabletPortrait} {
    margin: ${rem(8)} 0;
    width: ${rem(295)};
  }
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const CloseButtonWrapper = styled.div`
  float: right;
  display: inline-block;
  padding: ${rem(10)};
`;
