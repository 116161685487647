import IconClose from '@public/icons/regularIcons/icon-close.svg';
import { ReactNode } from 'react';
import Icon from '../Icon';
import { StyledPill } from './Pill.styles';

interface Props {
  selected: boolean;
  onClick: () => void;
  children: ReactNode;
}
const Pill = ({ selected, onClick, children }: Props) => (
  <StyledPill onClick={onClick} role="tab" aria-selected={selected}>
    {children} {selected ? <Icon data-testid="close-icon" icon={IconClose} color="white" /> : null}
  </StyledPill>
);
export default Pill;
