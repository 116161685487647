import DotPagination from '@components/atoms/DotPagination';
import Skeleton from '@components/atoms/Skeleton';
import SwipeableWrapper from '@components/atoms/SwipeableWrapper';
import IconButton from '@components/molecules/IconButton';
import Carousel from '@components/molecules/__DEPRECATED__/Carousel';
import { trackCarouselShifted } from '@features/promotions/tracking';
import useCmsComponents from '@hooks/useCmsComponentGroup';
import useResponsive from '@hooks/useResponsive';
import useSlider from '@hooks/useSlider';
import IconCarouselLeft from '@public/icons/regularIcons/icon-carousel-left.svg';
import IconCarouselRight from '@public/icons/regularIcons/icon-carousel-right.svg';
import { SwipeEventData } from 'react-swipeable';
import AxfoodResponsiveBannerComponent from '../AxfoodResponsiveBannerComponent';
import { Container, IndicatorWrapper, NavigationArrow } from './AxfoodResponsiveBannerCarouselComponent.styles';

export interface AxfoodResponsiveBannerCarouselComponentProps {
  component: AxfoodResponsiveBannerCarouselComponentType;
  slotPosition?: number;
}

const AxfoodResponsiveBannerCarouselComponent = ({
  component,
  slotPosition = undefined,
}: AxfoodResponsiveBannerCarouselComponentProps) => {
  const { banners } = component;
  const { data: cmsComponentData = [], isValidating } = useCmsComponents(banners);
  const { isTabletPortraitOrGreater } = useResponsive();
  const { offset, incrementOffset, decrementOffset, setOffset } = useSlider({
    numSlides: cmsComponentData.length,
    loop: true,
  });
  const hasMultipleBanners = cmsComponentData.length > 1;

  const onCarouselPreviousSlideNavigation = () => {
    // only fires off the tracker if the slide actully moved.
    decrementOffset() && trackCarouselShifted();
  };

  const onCarouselNextSlideNavigation = () => {
    // only fires off the tracker if the slide actully moved.
    incrementOffset() && trackCarouselShifted();
  };

  // Remember: slide left = next slide, slide right = previous slide.
  const touchSlideHandler = (e: SwipeEventData) => {
    e.dir === 'Left' && onCarouselNextSlideNavigation();
    e.dir === 'Right' && onCarouselPreviousSlideNavigation();
  };

  const navigateToSlide = (id: number) => {
    id !== offset && (setOffset(id), trackCarouselShifted());
  };

  if (!isValidating && !cmsComponentData.length) return null;

  return (
    <Container>
      {isValidating ? (
        <Skeleton type="rect" $style={{ margin: '0' }} data-testid="skeleton" />
      ) : (
        <>
          <SwipeableWrapper onSwiped={touchSlideHandler}>
            <Carousel offset={offset}>
              {cmsComponentData.map((banner) => (
                <AxfoodResponsiveBannerComponent
                  key={banner.uid}
                  component={banner as AxfoodResponsiveBannerComponentType}
                  trackingName="carousel_promo_banner"
                  slotPosition={slotPosition}
                />
              ))}
            </Carousel>
          </SwipeableWrapper>
          {isTabletPortraitOrGreater && hasMultipleBanners && (
            <NavigationArrow $direction="left">
              <IconButton
                icon={IconCarouselLeft}
                iconSize={50}
                color="white"
                onClick={onCarouselPreviousSlideNavigation}
                data-testid="navigation-arrow-left"
              />
            </NavigationArrow>
          )}
          <IndicatorWrapper>
            <DotPagination numberOfDots={cmsComponentData.length} activeIndex={offset} onClick={navigateToSlide} />
          </IndicatorWrapper>
          {isTabletPortraitOrGreater && hasMultipleBanners && (
            <NavigationArrow $direction="right">
              <IconButton
                icon={IconCarouselRight}
                iconSize={50}
                color="white"
                onClick={onCarouselNextSlideNavigation}
                data-testid="navigation-arrow-right"
              />
            </NavigationArrow>
          )}
        </>
      )}
    </Container>
  );
};

export default AxfoodResponsiveBannerCarouselComponent;
