import ContentComponent from '@components/organisms/ContentComponent';
import { CmsPanel } from '@styles/cms';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const QueryContainer = styled.div`
  container-type: inline-size;
  container-name: promoPanel;
  height: 100%;
  width: 100%;
`;

export const PromoPanel = styled(CmsPanel)<{ $centerContent: boolean; $backgroundColor: string | undefined }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  ${({ $backgroundColor, theme }) =>
    $backgroundColor &&
    css`
      background-color: ${$backgroundColor};
    `};
`;

export const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

export const PromoContentComponent = styled(ContentComponent)`
  position: relative;
  height: initial;
  padding: ${rem(16)};
  max-width: ${rem(422)};
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  gap: ${rem(12)};
`;
