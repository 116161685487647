import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const ConfirmModalWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  ${media.tabletPortrait} {
    flex-direction: row;
  }
`;
export const ButtonWrapper = styled.div`
  margin: ${rem(16)};
`;

export const TextWrapper = styled.div`
  margin-bottom: ${rem(20)};
  text-align: center;
`;
