import Overlay from '@components/atoms/Overlay';
import isFrontend from '@helpers/isFrontend';
import { ReactNode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Transition, TransitionStatus } from 'react-transition-group';
import { Container, Content, FooterSlotWrapper, HeaderSlotWrapper } from './Panel.styles';

export type TransitionState = 'entering' | 'entered';
export type Position = 'left' | 'right';
export interface Props {
  position?: Position;
  children: ReactNode;
  headerSlot?: ReactNode;
  footerSlot?: ReactNode;
  closePanel: () => void;
  isOpen: boolean;
  size?: 'small' | 'medium' | 'large';
}

const Panel = ({ position = 'left', headerSlot, children, footerSlot, closePanel, isOpen, size = 'medium' }: Props) => {
  const modalContainer = isFrontend() ? document.getElementById('modal-container') : null;
  const containerRef = useRef<HTMLDivElement>(null);

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Escape') {
      closePanel();
    }
  };

  useEffect(() => {
    if (isOpen) {
      const firstFocusableElement = containerRef.current?.querySelectorAll(
        'a[href], button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
      )?.[0];
      if (firstFocusableElement instanceof HTMLElement) {
        firstFocusableElement.focus();
      }
    }
  }, [isOpen]);

  if (!modalContainer) return null;

  return ReactDOM.createPortal(
    <Transition in={isOpen} timeout={250} unmountOnExit>
      {(transitionState: TransitionStatus) => (
        <>
          <Container
            role="dialog"
            $position={position}
            $transition={transitionState}
            onKeyDown={onKeyDown}
            ref={containerRef}
            aria-modal={isOpen}
            data-testid="takeover-panel"
            $size={size}
          >
            {headerSlot && <HeaderSlotWrapper>{headerSlot}</HeaderSlotWrapper>}
            <Content>{children}</Content>
            {footerSlot && <FooterSlotWrapper>{footerSlot}</FooterSlotWrapper>}
          </Container>
          <Overlay onClick={closePanel} lockScroll zIndex={180} opacity={transitionState === 'entered' ? 0.5 : 0} />
        </>
      )}
    </Transition>,
    modalContainer
  );
};

export default Panel;
