import Icon from '@components/atoms/Icon';
import { rem } from 'polished';
import styled from 'styled-components';

export const TooltipContentWrapper = styled.div`
  visibility: hidden;
  padding: ${rem(24)} ${rem(32)};
  background-color: ${({ theme }) => theme.colors.colorWhite};
  border: ${rem(1)} solid ${({ theme }) => theme.colors.colorPampas};
  box-shadow: ${({ theme }) => theme.shadows.dropdown};
  width: ${rem(320)};
  border-radius: ${rem(32)};
  line-height: 1.5;
  font-weight: ${({ theme }) => theme.fonts.fontWeight.thin};
  height: 0;
  overflow: hidden;
  z-index: 90;
`;

export const TooltipReference = styled.div<{ $shouldShowContentOnHover: boolean }>`
  position: relative;
  ${({ $shouldShowContentOnHover }) =>
    $shouldShowContentOnHover &&
    `
    &:focus > ${TooltipContentWrapper},
    &:hover > ${TooltipContentWrapper} {
      visibility: visible;
      cursor: context-menu;
      overflow: visible;
      height: auto;
    }
  `}
`;

export const ToolTipButton = styled.div`
  display: flex;
  gap: ${rem(8)};
`;

export const ToolTipIcon = styled(Icon)`
  flex: none;
`;

export const ToolTipText = styled.span`
  p {
    line-height: 1.8;
  }
`;
