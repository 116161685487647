import styled from 'styled-components';
import { rem } from 'polished';
import media from '@constants/responsive';

export const AddToListPopper = styled.div`
  z-index: 60;
`;

export const AddToListWrapper = styled.div`
  position: relative;
  margin: ${rem(15)} 0 ${rem(5)} 0;
  z-index: 101;

  ${media.tabletPortrait} {
    z-index: unset;
  }
`;

export const BackgroundOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`;
