import React, { useRef, useEffect, useState, ReactNode } from 'react';
import { ContentSectionWrapper, ContentList } from './ProductDetailsAccordion.styles';

interface AccordionSectionContentProps {
  active: boolean;
  children: ReactNode;
  'data-testid'?: string;
}

const AccordionSectionContent = ({ active, children, 'data-testid': dataTestId }: AccordionSectionContentProps) => {
  const inputRef = useRef<HTMLDivElement>(null);
  const [childHeight, setChildHeight] = useState(0);

  useEffect(() => {
    if (inputRef.current) {
      setChildHeight(inputRef.current.clientHeight);
    }
  }, [active]);
  return (
    <ContentSectionWrapper $active={active} $childHeight={childHeight} data-testid={dataTestId}>
      <ContentList ref={inputRef}>{children}</ContentList>
    </ContentSectionWrapper>
  );
};

export default AccordionSectionContent;
