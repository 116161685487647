import Link from '@components/atoms/Link';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import { StyledParagraph } from '../ProductDescriptionListing.styles';

interface Props {
  url: string;
}
const MedicineLinkParagraph = ({ url }: Props) => {
  const { t } = useTranslation('productDetails');
  return (
    <StyledParagraph>
      <Trans
        i18nKey="productDetails:productDetails->medicineLink"
        components={{
          link: <Link href={url} underlined external aria-label={t('productDetails->medicalInformationLinkLabel')} />,
        }}
      />
    </StyledParagraph>
  );
};

export default MedicineLinkParagraph;
