import { AutocompleteSuggestionData, CategoryBreadCrumbsData } from '@api/generated/storefront';
import { URLPREFIXES } from '@constants/links';
import REGEXP from '@constants/regexp';

export interface MultiSearchLink {
  name: string;
  url: string;
}

const mapCategory = (category: CategoryBreadCrumbsData): MultiSearchLink => {
  const { name = '', url = '' } = category;
  return { name, url: `${URLPREFIXES.CATEGORIES}${url}` };
};

export const mapCategories = (categories?: CategoryBreadCrumbsData[]): MultiSearchLink[] => {
  return (categories && categories.filter((cat) => cat && cat.name && cat.url).map(mapCategory)) || [];
};

export const getSearchUrl = (term: string) =>
  `/sok?q=${encodeURIComponent(term.replace(REGEXP.ILLEGAL_SEARCH_CHARS, '').toLowerCase())}&sort=relevance`;

export const mapSuggestions = (terms?: AutocompleteSuggestionData[]): MultiSearchLink[] => {
  return (
    (terms &&
      terms.filter(({ term = '' }) => !!term).map(({ term = '' }) => ({ name: term, url: getSearchUrl(term) }))) ||
    []
  );
};
