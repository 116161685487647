import React, { FC, RefObject, useEffect } from 'react';
import { Subtract } from 'utility-types';

/**
 * Typing black magic inspired by https://medium.com/@jrwebdev/react-higher-order-component-patterns-in-typescript-42278f7590fb
 */

export interface InjectedIntersectionObserverProps {
  intersectionRef?: RefObject<any>;
}

interface WithIntersectionObserverProps {
  intersectionCallback(): void;
  onExitCallback?(): void;
  alwaysObserve?: boolean;
  threshold?: number;
  intersectionRef?: RefObject<any>;
}

const withIntersectionObserver =
  <P extends InjectedIntersectionObserverProps>(
    Component: FC<P>
  ): FC<Subtract<P, InjectedIntersectionObserverProps> & WithIntersectionObserverProps> =>
  // eslint-disable-next-line react/display-name
  ({
    intersectionRef,
    intersectionCallback,
    threshold = 0.1,
    onExitCallback = () => false,
    alwaysObserve = false,
    ...rest
  }: WithIntersectionObserverProps) => {
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            intersectionCallback();
            if (!alwaysObserve) {
              observer.unobserve(entry.target);
            }
          } else {
            onExitCallback();
          }
        },
        {
          root: null,
          rootMargin: '0px',
          threshold,
        }
      );
      if (intersectionRef?.current) {
        observer.observe(intersectionRef.current);
      }
      return () => observer.disconnect();
    }, [intersectionRef]);

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...(rest as P)} intersectionRef={intersectionRef} intersectionCallback={intersectionCallback} />;
  };

export default withIntersectionObserver;
