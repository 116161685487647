import { FontSizeType } from '@styles/fontSizes';
import { default as NextLink } from 'next/link';
import { ReactNode } from 'react';
import { UrlObject } from 'url';
import { StyledLink } from './Link.styles';
export interface LinkProps {
  onClick?: (e: LinkClickEvent) => void;
  href: string | UrlObject;
  children?: string | ReactNode;
  as?: UrlType;
  size?: FontSizeType;
  target?: '_blank' | '_self';
  underlined?: boolean;
  title?: string;
  'aria-label'?: string;
  external?: boolean;
}

/**
 * web 2.0 link
 */
const Link = ({
  title,
  onClick,
  href,
  children,
  as,
  size = 'medium',
  target,
  underlined = true,
  'aria-label': ariaLabel,
  external = false,
}: LinkProps) => {
  const linkTarget = external ? '_blank' : target || '_self';
  if (external) {
    return (
      <StyledLink
        href={typeof href === 'string' ? href : ''}
        $size={size}
        onClick={onClick}
        target={linkTarget}
        $underlined={underlined}
        title={title}
        aria-label={ariaLabel}
      >
        {children}
      </StyledLink>
    );
  }
  return (
    <NextLink legacyBehavior href={href} passHref as={as}>
      <StyledLink
        $size={size}
        onClick={onClick}
        target={linkTarget}
        $underlined={underlined}
        title={title}
        aria-label={ariaLabel}
      >
        {children}
      </StyledLink>
    </NextLink>
  );
};

export default Link;
