import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css, DefaultTheme } from 'styled-components';

interface Props {
  $backgroundColor?: ColorType;
  $marginTop?: boolean;
  $marginBottom?: boolean;
  $centered?: boolean;
}

const getBackgroundColor = (color: ColorType, theme: DefaultTheme) => {
  switch (color) {
    case 'red':
      return theme.colors.colorPrimary;
    case 'pink':
      return theme.colors.colorPrimaryPink;
    case 'yellow':
      return theme.colors.colorAmber;
    case 'grey':
      return theme.colors.colorCararra;
    default:
      return theme.colors.colorWhite;
  }
};

export const Section = styled.div<Props>`
  background: ${({ $backgroundColor = 'white', theme }) => getBackgroundColor($backgroundColor, theme)};
  padding: ${rem(15)};
  margin-bottom: ${({ $marginBottom = true }) => $marginBottom && rem(15)};
  margin-top: ${({ $marginTop = false }) => $marginTop && rem(15)};
  border-radius: ${rem(24)};
  ${({ $centered = false }) =>
    $centered &&
    css`
      display: flex;
      justify-content: center;
    `}

  ${media.tabletPortrait} {
    border-radius: ${rem(32)};
    padding: ${rem(30)};
  }
`;
