import Picture from '@components/atoms/Picture';
import SanitizedHtml from '@components/atoms/SanitizedHtml/SanitizedHtml';
import Link from '@components/atoms/__DEPRECATED__/Link';
import useIntersection from '@hooks/useIntersection';
import useResponsive from '@hooks/useResponsive';
import { ButtonWithCustomColor } from '@styles/cms';
import { useEffect } from 'react';
import {
  HorizontalBannerBackground,
  HorizontalBannerBackgroundImage,
  HorizontalBannerButtonWrapper,
  HorizontalBannerContent,
  HorizontalBannerContentInner,
  HorizontalBannerInner,
  HorizontalBannerPreamble,
  HorizontalBannerSection,
  HorizontalBannerTitle,
} from './HorizontalBannerComponent.styles';

export interface HorizontalBannerComponentProps {
  component: CmsCarouselBanner;
  trackingName: string;
  onIntersect?: (arg0: string) => void;
  onClick?: (arg0: string, url: string | undefined) => void;
  slotPosition?: number;
}

const HorizontalBannerComponent = ({
  component,
  trackingName,
  onIntersect,
  onClick,
  slotPosition,
}: HorizontalBannerComponentProps) => {
  const { isTabletPortraitOrGreater, isDesktopOrGreater } = useResponsive();
  const paddingTop: string = component?.paddingTop ? component?.paddingTop.split('_')[1] : '0';
  const paddingBottom: string = component?.paddingBottom ? component?.paddingBottom.split('_')[1] : '0';
  const paddingLeft: string = component?.paddingLeft ? component?.paddingLeft.split('_')[1] : '0';
  const paddingRight: string = component?.paddingRight ? component?.paddingRight.split('_')[1] : '0';

  const desktopImage = component?.media?.desktop?.url;
  const tabletImage = component?.media?.tablet?.url;
  const mobileImage = component?.media?.mobile?.url;

  const backgroundColor = component?.backgroundColor || 'none';
  const hasBackgroundImage = () => !!(desktopImage || tabletImage || mobileImage);
  const hideOverlay = component.hideOverlay === 'true';
  const hasTitle = component?.title || component?.titleTablet || component?.titleMobile;

  const getTitle = () => {
    if (!isTabletPortraitOrGreater && component?.titleMobile) return component?.titleMobile;
    if (isTabletPortraitOrGreater && !isDesktopOrGreater && component?.titleTablet) return component?.titleTablet;
    return component?.title || component?.titleTablet || component.titleMobile || '';
  };

  const trackBannerButtonClick = (url: string | undefined) => {
    !!onClick && onClick(getTitle(), url);
  };
  const [containerRef, intersectionObject] = useIntersection<HTMLElement>();

  useEffect(() => {
    if (onIntersect && intersectionObject?.isIntersecting) {
      onIntersect(getTitle());
    }
  }, [containerRef, intersectionObject?.isIntersecting]);

  const shouldLoadEager = slotPosition !== undefined && slotPosition < 2;

  return (
    <HorizontalBannerSection
      data-testid="horizontal-banner"
      $paddingTop={paddingTop}
      $paddingBottom={paddingBottom}
      $paddingLeft={paddingLeft}
      $paddingRight={paddingRight}
      ref={containerRef}
    >
      <HorizontalBannerInner>
        <HorizontalBannerBackground
          $backgroundColor={backgroundColor}
          $hideOverlay={hideOverlay}
          data-testid="banner-background"
        >
          {hasBackgroundImage() && component.media && (
            <HorizontalBannerBackgroundImage>
              <Picture media={component.media} shouldLoadEager={shouldLoadEager} />
            </HorizontalBannerBackgroundImage>
          )}
        </HorizontalBannerBackground>
        <HorizontalBannerContent>
          <HorizontalBannerContentInner>
            {hasTitle && (
              <div style={{ color: component?.titleTextColor }}>
                <HorizontalBannerTitle as="h2">
                  <SanitizedHtml content={getTitle()} />
                </HorizontalBannerTitle>
              </div>
            )}
            {component?.preamble && (
              <HorizontalBannerPreamble $color={component?.preambleTextColor ? component?.preambleTextColor : 'black'}>
                <SanitizedHtml content={component?.preamble} data-testid="preamble" />
              </HorizontalBannerPreamble>
            )}
            {component?.buttonEnabled === 'true' && component?.buttonText && component?.urlLink && (
              <HorizontalBannerButtonWrapper>
                <Link
                  href={component?.urlLink}
                  external={component?.urlLinkInNewWindow === 'true'}
                  target={component?.urlLinkInNewWindow === 'true' ? '_blank' : ''}
                  underlined={false}
                  data-testid="link"
                  aria-label={component?.buttonText}
                  margin={false}
                  onClick={() => trackBannerButtonClick(component?.urlLink)}
                >
                  <ButtonWithCustomColor
                    title={component?.buttonText}
                    variant={component?.buttonColor as ColorType}
                    $customColor={component?.buttonTextColor as ColorType}
                    data-testid="banner-button"
                  >
                    <SanitizedHtml content={component?.buttonText} />
                  </ButtonWithCustomColor>
                </Link>
              </HorizontalBannerButtonWrapper>
            )}
          </HorizontalBannerContentInner>
        </HorizontalBannerContent>
      </HorizontalBannerInner>
    </HorizontalBannerSection>
  );
};

export default HorizontalBannerComponent;
