import MessageModal from '@components/molecules/MessageModal';
import Modal from '@components/molecules/Modal';
import { SplashBox } from '@components/molecules/Product/SplashSection';
import ProductPrice from '@components/molecules/ProductPrice';
import { mapProductPriceProps } from '@components/molecules/__DEPRECATED__/Product/Product';
import PromotionSplash from '@components/molecules/__DEPRECATED__/PromotionSplash';
import { URLPREFIXES } from '@constants/links';
import { TabType } from '@constants/productDetailTabs';
import CanonicalLink from '@features/seo/CanonicalLink';
import useOnetrustActiveGroupHelper from '@helpers/oneTrustActiveGroupHelper';
import productDetailStructuredData from '@helpers/structuredDataHelper';
import useAppRouter from '@hooks/useAppRouter';
import useResponsive from '@hooks/useResponsive';
import { productDetailTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Image from 'next/image';
import { memo, useEffect, useState } from 'react';
import {
  DetailWrapper,
  Header,
  ImageSection,
  ImageWrapper,
  InformationWrapper,
  PromotionSplashPosition,
  SplashSection,
  StyledProductBreadcrumbs,
  Wrapper,
} from './ProductDetails.styles';
import ProductDetailsAddToList from './ProductDetailsAddToList/ProductDetailsAddToList';
import ProductDetailsInformation from './ProductDetailsInformation/ProductDetailsInformation';
import EnergyDeclarationLabel from './ProductDetailsLabels/EnergyDeclarationLabel';
import ProductDetailsQuantity from './ProductDetailsQuantity';
import ProductDetailsRelatedBeams from './ProductDetailsRelatedBeams';
import Skeleton from './ProductDetailsSkeleton';
import ProductDetailsTabSection from './ProductDetailsTabSection';
import useGetProductDetails from './useGetProductDetails';

const DynamicProductDetailsLabels = dynamic(
  () => import('./ProductDetailsLabels/ProductDetailsLabels' /* webpackChunkName: 'ProductDetailsLabels' */)
);

const ProductDetails = () => {
  const { query, push, pathname, asPath } = useAppRouter();
  const { isTabletPortraitOrGreater } = useResponsive();
  const [updatedSelectedTab, setUpdatedSelectedTab] = useState<TabType | ''>((query?.activeTab as TabType) || '');
  const { t } = useTranslation('productDetails');
  const [lockModalScroll, setLockModalScroll] = useState(false);
  const pricePosition = isTabletPortraitOrGreater ? 'left' : 'right';
  const [referenceElement, setReferenceElement] = useState(null);
  const isOneTrustReady = useOnetrustActiveGroupHelper();

  const closeModal = () => {
    // eslint-disable-next-line fp/no-rest-parameters
    const { name, productCode, activeTab, scrollToMixAndMatch, ...rest } = query;
    push({ pathname, query: rest }, undefined, { shallow: true, scroll: false });
  };

  const getProductCodeFromName = (name: string) => {
    const listOfName = name?.split('-');
    return listOfName[listOfName.length - 1].toUpperCase();
  };

  const productCode =
    typeof query.productCode === 'string'
      ? query.productCode
      : (query.name && getProductCodeFromName(query?.name as string)) || '';

  const { product, isLoading, error: hasError, isValidating } = useGetProductDetails(productCode);

  const productAltText = product?.manufacturer ? `${product.manufacturer} ${product?.name}` : product?.name;
  const promotion = product?.potentialPromotions[0];
  const hasPromotion = !!promotion;
  const isNew = !!product?.newsSplashProduct;
  const isBargain = !!product?.bargainProduct;

  useEffect(() => {
    if (product && isOneTrustReady) {
      productDetailTracker.enteredProductDetail(product);
    }
  }, [isOneTrustReady, product]);

  const structuredData = product ? productDetailStructuredData(product) : '';

  if (hasError) {
    return (
      <MessageModal
        text={t('productDetails->error->message')}
        title={t('productDetails->error->title')}
        onConfirm={closeModal}
        closeHandler={closeModal}
        withCloseButton
      />
    );
  }
  const energyDeclarationLabel = product?.energyDeclaration ? (
    <EnergyDeclarationLabel energyDeclaration={product?.energyDeclaration} onClick={setUpdatedSelectedTab} />
  ) : undefined;

  return (
    <Modal
      isOpen
      closeHandler={closeModal}
      withPadding={false}
      lockModalScroll={lockModalScroll}
      position="top"
      scrollTo={!isValidating ? 0 : undefined}
      aria-label={product?.name}
    >
      <Wrapper ref={setReferenceElement as any} data-testid="product-detail">
        {!product || isLoading ? (
          <Skeleton />
        ) : (
          <>
            <Head>
              <title>{t('productDetails->meta->title', { name: product.name })}</title>
              <meta
                name="description"
                key="description"
                content={t('productDetails->meta->description', { name: product.name })}
              />
            </Head>
            <CanonicalLink url={window.location.origin + asPath} />
            <script
              type="application/ld+json"
              dangerouslySetInnerHTML={{
                __html: JSON.stringify(structuredData),
              }}
            />
            <Header>
              <StyledProductBreadcrumbs
                sliceLastItem
                breadcrumbs={product?.breadCrumbs || []}
                urlPrefix={URLPREFIXES.CATEGORIES}
              />
              <DetailWrapper>
                <ImageSection>
                  <DynamicProductDetailsLabels
                    product={product}
                    isTabletPortraitOrGreater={isTabletPortraitOrGreater}
                    energyDeclarationLabel={energyDeclarationLabel}
                  />
                  {hasPromotion && (
                    <PromotionSplashPosition>
                      <PromotionSplash variant="detail" product={product} />
                    </PromotionSplashPosition>
                  )}
                  <ImageWrapper>
                    {product.image?.url && (
                      <Image
                        alt={productAltText || `${product.name} image`}
                        title={productAltText}
                        src={product.image.url}
                        fill
                      />
                    )}
                  </ImageWrapper>
                </ImageSection>
                <InformationWrapper>
                  {(isNew || isBargain) && (
                    <SplashSection>
                      <SplashBox showNewsSplash={isNew} showBargainSplash={isBargain} size={'medium'} />
                    </SplashSection>
                  )}
                  <ProductDetailsInformation product={product} energyDeclarationLabel={energyDeclarationLabel} />
                  <ProductPrice
                    product={mapProductPriceProps(product)}
                    variant="detail"
                    size="large"
                    position={pricePosition}
                  />
                  <ProductDetailsQuantity product={product} />
                  <ProductDetailsAddToList
                    modalElement={referenceElement}
                    product={product}
                    setLockModalScroll={(lockModal: boolean) => {
                      setLockModalScroll(lockModal);
                    }}
                  />
                </InformationWrapper>
              </DetailWrapper>
            </Header>
            <ProductDetailsTabSection
              product={product}
              isTabletPortraitOrGreater={isTabletPortraitOrGreater}
              updatedSelectedTab={updatedSelectedTab}
              setUpdatedSelectedTab={setUpdatedSelectedTab}
            />
            <ProductDetailsRelatedBeams product={product} />
          </>
        )}
      </Wrapper>
    </Modal>
  );
};

export default memo(ProductDetails);
