import FixedRowsProductGrid from '@components/organisms/FixedRowsProductGrid';
import LINKS from '@constants/links';
import useEventListName from '@hooks/useEventListName';
import useTranslation from 'next-translate/useTranslation';
import usePromotedProductsRequest from './usePromotedProductsRequest';

export interface PromotedProductsComponentProps {
  component: PromotedProductsComponent;
}

const LIST_NAME = 'Veckans erbjudanden';
const HREF = LINKS.THIS_WEEKS_PROMOTIONS;
export const ROWS_TO_RENDER = 2;

const PromotedProductsComponent = ({ component }: PromotedProductsComponentProps) => {
  const { t } = useTranslation('promotions');
  const { EVENT_LIST_NAME } = useEventListName(LIST_NAME);
  const { name = '' } = component;

  const { products, isLoading } = usePromotedProductsRequest();

  return (
    <FixedRowsProductGrid
      products={products}
      eventListName={EVENT_LIST_NAME}
      title={name}
      href={HREF}
      linkText={t('allOffers->showMore')}
      isLoading={isLoading}
      rows={ROWS_TO_RENDER}
    />
  );
};

export default PromotedProductsComponent;
