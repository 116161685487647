import { Product as ProductType } from '@components/molecules/Product/ProductDataMapper';
import { RelevantPriceProps } from './ProductPrice';

const mapProductPriceProps = (product: ProductType): RelevantPriceProps => {
  const promotion = product.hasPromotion ? product.promotion : undefined;
  return {
    offline: product.offline,
    priceValue: product.price.value,
    hasPromotion: product.hasPromotion,
    actualCustomerPrice: product.price.actualCustomerPrice,
    priceUnit: product.price.unit,
    hasPrice: product.price.hasPrice,
    isMixAndMatchPercentage: promotion?.isMixAndMatchPercentage || false,
    isPercentage: promotion?.isPercentage || false,
    showPromotionPrice: promotion?.showPromotionPrice || false,
  };
};

export default mapProductPriceProps;
