import styled from 'styled-components';
import { rem } from 'polished';
import media from '@constants/responsive';

const EnergyDeclarationImage = styled.img`
  min-height: ${rem(417)};
  max-width: ${rem(209)};
  ${media.tabletPortrait} {
    max-width: ${rem(340)};
  }
`;

export default EnergyDeclarationImage;
