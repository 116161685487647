/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactNode } from 'react';
import { SwipeableProps, SwipeEventData, useSwipeable } from 'react-swipeable';
import { Wrapper } from './SwipeableWrapper.styles';

const defaultConfig = {
  trackMouse: false, // change to true to allow swiping with the mouse on desktop (non-touch) devices
};

interface SwipeableWrapperProps {
  onSwiped: (eventData: SwipeEventData) => void;
  config?: SwipeableProps;
  children: ReactNode;
}

const SwipeableWrapper = ({ onSwiped, children, config = {} }: SwipeableWrapperProps) => {
  const handlers = useSwipeable({
    onSwiped,
    ...defaultConfig,
    ...config,
  });

  return <Wrapper {...handlers}>{children}</Wrapper>;
};

export default SwipeableWrapper;
