import GeneralProduct from '@components/organisms/GeneralProduct';
import makeIntersectionObserverComponent from '@helpers/componentTracking';
import useBufferedAnalyticsEvent from '@hooks/useBufferedAnalyticsEvent/useBufferedAnalyticsEvent';
import useResponsive from '@hooks/useResponsive';
import { conflictModalTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import { RefObject } from 'react';
import { ConflictedProductStyled } from '../ConflictModal.styles';
import type { AxfoodCartEntryStatusViewModel } from '@occ/api-client';

interface Props {
  product: AxfoodCartEntryStatusViewModel;
  notAllowedAnonymousProducts: AxfoodCartEntryStatusViewModel[];
  intersectionRef: RefObject<any>;
}
export const ConflictedProductContainer = ({ product, notAllowedAnonymousProducts, intersectionRef }: Props) => {
  const { isTabletLandscapeOrGreater } = useResponsive();
  const { t } = useTranslation('conflictModal');
  return (
    <ConflictedProductStyled
      key={product.entryProduct?.code}
      $disabled={notAllowedAnonymousProducts.includes(product) ? (t('conflictModal->requiresLogin') as string) : true}
      ref={intersectionRef}
    >
      <GeneralProduct
        product={product.entryProduct!}
        disableLinks
        disableQuantity
        initialQuantity={product.pickQuantity}
        smallQuantity={!isTabletLandscapeOrGreater}
        withAddToList={false}
      />
    </ConflictedProductStyled>
  );
};

export const IntersectionObservedConflictProduct = makeIntersectionObserverComponent(ConflictedProductContainer);

interface NonReplaceableProductsWithTrackingProps {
  conflictProducts: AxfoodCartEntryStatusViewModel[];
  slideIndex: number;
  slideSize: number;
  notAllowedAnonymousProducts: AxfoodCartEntryStatusViewModel[];
}

const NonReplaceableProductsWithTracking = ({
  conflictProducts,
  slideIndex,
  slideSize,
  notAllowedAnonymousProducts,
}: NonReplaceableProductsWithTrackingProps) => {
  const track = (impressionProducts: { product: AxfoodCartEntryStatusViewModel; position: number }[]) => {
    conflictModalTracker.trackNonReplaceableProducts(impressionProducts);
  };
  const { debouncedTrack, queueTrackingOfItem, trackableRefs } = useBufferedAnalyticsEvent(conflictProducts, track);

  const onProductShown = (product: AxfoodCartEntryStatusViewModel, position: number) => {
    queueTrackingOfItem({ product, position });
    debouncedTrack();
  };

  return (
    <>
      {conflictProducts.map((conflictProduct: AxfoodCartEntryStatusViewModel, i: number) => {
        return (
          <div key={`${conflictProduct.entryProduct?.code}`}>
            <IntersectionObservedConflictProduct
              intersectionCallback={() => onProductShown(conflictProduct, slideSize * (slideIndex - 1) + (i + 1))}
              ref={trackableRefs.current[i] as unknown as RefObject<any>}
              item={conflictProduct}
              notAllowedAnonymousProducts={notAllowedAnonymousProducts}
              product={conflictProduct}
            />
          </div>
        );
      })}
    </>
  );
};

export default NonReplaceableProductsWithTracking;
