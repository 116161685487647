import Icon from '@components/atoms/Icon';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

const getSquareWidthAndHeight = (val: number) => `
  width: ${rem(val)};
  height: ${rem(val)};
`;

const getVariant = ($variant: string) => {
  switch ($variant) {
    case 'card':
      return css`
        width: ${rem(60)};
        height: ${rem(50)};
        font-size: ${rem(12)};
        ${media.tabletPortrait} {
          width: ${rem(85)};
          height: ${rem(75)};
          font-size: ${rem(16)};
        }
      `;
    case 'detail':
      return css`
        width: ${rem(88)};
        height: ${rem(73)};
        ${media.tabletPortrait} {
          width: ${rem(110)};
          height: ${rem(91)};
          font-size: ${rem(20)};
        }
      `;
    case 'horizontal':
      return css`
        width: ${rem(38)};
        height: ${rem(40)};
        font-size: ${rem(9)};
        margin: auto;
        ${media.tabletPortrait} {
          font-size: ${rem(12)};
          width: ${rem(60)};
        }
      `;
    default:
      return '';
  }
};

const getIconStyle = ($variant: string) => {
  switch ($variant) {
    case 'card':
      return css`
        svg {
          width: ${rem(60)};
          height: ${rem(50)};
          ${media.tabletPortrait} {
            width: ${rem(88)};
            height: auto;
          }
        }
      `;
    case 'detail':
      return css`
        transform: scaleX(1);
        svg {
          width: ${rem(90)};
          height: auto;
        }
        ${media.tabletPortrait} {
          transform: scaleX(-1);
          svg {
            width: ${rem(112)};
            height: auto;
          }
        }
      `;
    case 'horizontal':
      return css`
        svg {
          width: ${rem(40)};
          ${media.tabletPortrait} {
            width: ${rem(60)};
            height: auto;
          }
        }
      `;
    default:
      return '';
  }
};

const getPriceBombStyle = ($variant: string) => {
  switch ($variant) {
    case 'card':
      return `
      ${getSquareWidthAndHeight(65)}
      svg { ${getSquareWidthAndHeight(65)} }
      ${media.tabletPortrait} {
        ${getSquareWidthAndHeight(100)}
        svg { ${getSquareWidthAndHeight(100)} }
      }
      `;
    case 'detail':
      return `
        ${getSquareWidthAndHeight(88)}
        svg { ${getSquareWidthAndHeight(88)} }
        ${media.tabletPortrait} {
          ${getSquareWidthAndHeight(112)}
          svg { ${getSquareWidthAndHeight(112)} }
        }
      `;
    case 'horizontal':
      return `
      font-size: ${rem(8)};
      ${media.tabletPortrait} {
        font-size: ${rem(10)};
      }
        ${getSquareWidthAndHeight(45)}
        svg { ${getSquareWidthAndHeight(45)} }
        ${media.tabletPortrait} {
          ${getSquareWidthAndHeight(60)}
          svg { ${getSquareWidthAndHeight(60)} }
        }
        `;
    default:
      return '';
  }
};

export const PromotionSplashWrapper = styled.div<{
  $redText?: boolean;
  $variant: string;
}>`
  display: flex;
  ${({ $variant }) => $variant && getVariant($variant)};
  color: ${({ theme }) => theme.colors.colorMenuBackground};
  font-family: ${({ theme }) => theme.typography.fontSerif};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  position: relative;
  color: ${(props) => (props.$redText ? props.theme.colors.colorPrimary : props.theme.colors.colorWhite)};
`;

export const PriceBombPromotionSplashWrapper = styled(PromotionSplashWrapper)<{ $variant: string }>`
  ${({ $variant }) => getPriceBombStyle($variant)};
  font-family: ${({ theme }) => theme.typography.fontSansItalicBold};
`;

export const PromotionSplashIconWrapper = styled.div<{ $variant: string; $forceReverseIcon: boolean }>`
  width: 100%;
  height: 100%;
  ${({ $variant }) => $variant && getIconStyle($variant)};

  ${({ $forceReverseIcon }) =>
    $forceReverseIcon &&
    css`
      transform: scaleX(-1);
      ${media.tabletPortrait} {
        transform: scaleX(-1);
      }
    `};
`;

export const PromotionPriceBombIconWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const PromotionSplashPriceWrapper = styled.div`
  margin-left: -100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  ${media.tabletPortrait} {
    height: 100%;
  }
`;

export const PromotionSplashSavePrice = styled.div<{ $isPriceBomb?: boolean }>`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: ${rem(60)};
  min-width: ${rem(60)};
  ${media.tabletPortrait} {
    min-width: ${({ $isPriceBomb }) => ($isPriceBomb ? rem(100) : rem(88))};
    width: ${({ $isPriceBomb }) => ($isPriceBomb ? rem(100) : rem(88))};
  }
`;

export const PromotionSplashSavePriceTail = styled.span`
  margin-left: ${rem(3)};
`;

export const PromotionSplashPriceText = styled.span`
  flex-basis: 100%;
  width: 100%;
  text-align: center;
  margin: auto;
`;

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
`;
