import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const ColumnPanel = styled.div<{ $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: ${rem(16)};

  ${({ $isMobile }) =>
    !$isMobile &&
    css`
      align-items: start;
      text-align: start;
      height: ${rem(368)};
    `};
`;

export const PanelWrapper = styled.div<{ $isMobile: boolean }>`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.colorWhite};
  position: relative;
  padding: ${rem(24)};
  border-bottom-left-radius: ${rem(24)};
  border-bottom-right-radius: ${rem(24)};

  ${({ $isMobile }) =>
    !$isMobile &&
    css`
      width: ${rem(428)};
      padding: ${rem(32)};
      border-radius: ${rem(24)};
    `};
`;

export const ImageWrapper = styled.div`
  border-top-left-radius: ${rem(24)};
  border-top-right-radius: ${rem(24)};
  position: relative;
  width: 100%;
  height: ${rem(256)};
  overflow: hidden;
`;
