import { AxfoodCartProductViewModel } from '@api/generated/storefront';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import PROMOTION_THEME from '@constants/promotion';
import { isXforYProduct } from '@helpers/productHelper';
import useCustomer from '@hooks/useCustomer';
import useTranslation from 'next-translate/useTranslation';

interface Props {
  product: AxfoodCartProductViewModel;
}
const ProductDiscounts = ({ product }: Props) => {
  const { t } = useTranslation('product');

  const { appliedPromotions = [], totalDiscount, potentialPromotions = [] } = product;
  const hasDiscount = !!appliedPromotions.length && totalDiscount !== '0,00 kr';

  const promotion = potentialPromotions[0];

  const hasLoyaltyPromotion = promotion?.campaignType === PROMOTION_THEME.CLUB_PRICE;
  const hasSegmentedPromotion = promotion?.campaignType === PROMOTION_THEME.PERSONAL_OFFER;

  const promotionTheme =
    !hasLoyaltyPromotion &&
    !hasSegmentedPromotion &&
    promotion?.promotionTheme?.code === PROMOTION_THEME.ALWAYS_GOOD_PRICE
      ? PROMOTION_THEME.ALWAYS_GOOD_PRICE
      : promotion?.campaignType;

  const { isAnonymous } = useCustomer();

  const isPromotionUsed = !!(
    promotion &&
    promotion.promotionRedeemLimit &&
    promotion.promotionRedeemLimit > 0 &&
    promotion.timesUsed &&
    promotion.promotionRedeemLimit <= promotion.timesUsed
  );

  const isXforY = isXforYProduct(product);

  const showDiscountText = promotion && !isPromotionUsed && hasDiscount;

  const getCopyKey = () => {
    switch (promotionTheme) {
      case PROMOTION_THEME.ALWAYS_GOOD_PRICE:
        return 'always_good_price';
      case PROMOTION_THEME.PERSONAL_OFFER:
        return 'segmented';
      case PROMOTION_THEME.CLUB_PRICE:
        return 'loyalty';
      default:
        return 'promotionOffer';
    }
  };
  if (isAnonymous && promotionTheme === PROMOTION_THEME.CLUB_PRICE) {
    return null;
  }

  if (isXforY) {
    return (
      <Paragraph size="sm" textColor="primary">
        {`- ${totalDiscount}`}
      </Paragraph>
    );
  }

  return (
    <>
      {showDiscountText && (
        <Paragraph size="sm" textColor="primary">
          {t(`promotionSplash->${getCopyKey()}`)}
        </Paragraph>
      )}
    </>
  );
};
export default ProductDiscounts;
