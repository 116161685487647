import { Product } from '@components/molecules/Product/ProductDataMapper/types';
import useTranslation from 'next-translate/useTranslation';
import { SplashWrapper } from './PromotionSplash.styles';
import PromotionSplashPrice from './PromotionSplashPrice';

interface Props {
  product: Product;
  forceReverseIcon?: boolean;
}

const PromotionSplash = ({ product }: Props) => {
  const { t } = useTranslation('product');
  const { promotion, inactivePromotion } = product || {};
  const { isAlwaysGoodPrice, hasLoyaltyPromotion, hasSegmentedPromotion, isPriceBomb } =
    promotion || inactivePromotion || {};
  const isAlwaysGoodPriceTheme = !hasLoyaltyPromotion && !hasSegmentedPromotion && isAlwaysGoodPrice;

  if (isPriceBomb) {
    return (
      <SplashWrapper $color="priceBomb" data-testid="pricebomb-splash" title={t('product:promotionSplash->general')}>
        <PromotionSplashPrice product={product} />
      </SplashWrapper>
    );
  }

  if (isAlwaysGoodPriceTheme) {
    return (
      <SplashWrapper $color="orange" data-testid="splash-orange" title={t('product:promotionSplash->always_good_price')}>
        <PromotionSplashPrice product={product} />
      </SplashWrapper>
    );
  }

  if (hasSegmentedPromotion) {
    return (
      <SplashWrapper $color="yellow" data-testid="splash-yellow" title={t('product:promotionSplash->segmented')}>
        <PromotionSplashPrice product={product} />
      </SplashWrapper>
    );
  }

  if (hasLoyaltyPromotion) {
    return (
      <SplashWrapper $color="yellow" data-testid="splash-yellow" title={t('product:promotionSplash->loyalty')}>
        <PromotionSplashPrice product={product} />
      </SplashWrapper>
    );
    SplashWrapper;
  }

  return (
    <SplashWrapper $color="red" data-testid="splash-red" title={t('product:promotionSplash->general')}>
      <PromotionSplashPrice product={product} />
    </SplashWrapper>
  );
};
export default PromotionSplash;
