import media, { Screen } from '@constants/responsive';
import themeSpacing, { ThemeSpacing } from '@styles/spacing';
import styled from 'styled-components';

type StackMedia = 'default' | Screen;

export interface StackProps {
  $space?: keyof ThemeSpacing | Partial<{ [key in StackMedia]: keyof ThemeSpacing }>;
}

export const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: column;

  ${({ $space }) => {
    if (typeof $space === 'string') {
      return `gap: ${themeSpacing[$space]};`;
    }

    if (typeof $space === 'object') {
      return Object.entries($space)
        .map(([key, value]) => {
          const gapDefinition = `gap: ${themeSpacing[value]};`;

          return key === 'default'
            ? gapDefinition
            : `
            ${media[key as Screen]} {
              ${gapDefinition}
            }
          `;
        })
        .join('');
    }

    // Default spacing
    return `gap: ${themeSpacing['medium']};`;
  }};
`;

export default Stack;
