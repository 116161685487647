import LinkButton from '@components/atoms/Button/LinkButton';
import Picture from '@components/atoms/Picture';
import { Content, ImageWrapper, StyledParagraph, Wrapper } from './ImageGridComponent.styles';

interface Props {
  component: ImageGridComponentType;
}
const ImageGridComponent = ({ component }: Props) => {
  const { imageLinkUrl, title, buttonText, image, imageTarget, enableButton } = component;
  const target = imageTarget === 'newWindow' ? '_blank' : undefined;
  const showButton = enableButton === 'true' && !!imageLinkUrl && !!buttonText;
  return (
    <Wrapper>
      <ImageWrapper>
        <Picture media={image} fallbackAlt={title} />
      </ImageWrapper>
      <Content>
        <StyledParagraph size="small" weight="bold" truncateRows={4}>
          {title}
        </StyledParagraph>
        {showButton ? (
          <LinkButton linkTarget={target} href={imageLinkUrl} variant="strokeBlack">
            {buttonText}
          </LinkButton>
        ) : null}
      </Content>
    </Wrapper>
  );
};

export default ImageGridComponent;
