import useResponsive from '@hooks/useResponsive';

const useFooterResposive = () => {
  const { isTabletPortraitOrGreater, isDesktopOrGreater } = useResponsive();

  const oneRow = isDesktopOrGreater;
  const twoRows = !oneRow;
  const columnMenu = isTabletPortraitOrGreater;
  const accordionMenu = !columnMenu;
  const horizontalLinks = twoRows && columnMenu;

  return { oneRow, twoRows, accordionMenu, columnMenu, horizontalLinks };
};

export default useFooterResposive;
