import { chunk } from '@helpers/array';
import useResponsive from '@hooks/useResponsive';
import useSlider from '@hooks/useSlider';
import { useCallback, useEffect, useState } from 'react';
import type { AxfoodCartEntryStatusViewModel, AxfoodReplacementProductViewModel } from '@occ/api-client';

interface useConflictModalSlidesProps {
  tabletSlides: number;
  tabletLandscapeSlides: number;
  mobileSlides: number;
  products?: AxfoodCartEntryStatusViewModel[];
}

const useConflictModalSlides = ({
  tabletSlides,
  tabletLandscapeSlides,
  mobileSlides,
  products,
}: useConflictModalSlidesProps) => {
  const { isTabletPortraitOrGreater, isTabletLandscapeOrGreater, isClient } = useResponsive();

  const [productsForCarouselSlides, setProductsForCarouselSlides] = useState<
    AxfoodReplacementProductViewModel[][] | AxfoodCartEntryStatusViewModel[][]
  >([]);
  const { offset, incrementOffset, decrementOffset, setOffset } = useSlider({
    numSlides: productsForCarouselSlides.length,
    loop: false,
  });

  const chunkAndSetNonReplaceableSlides = useCallback(
    (slideLength: number) => {
      const slides = chunk(products, slideLength);
      setProductsForCarouselSlides(slides);
      return slides;
    },
    [products]
  );
  /**
   * Set the products for each slide in the carousel with non-replaceable products,
   * based on the user's window size.
   */
  useEffect(() => {
    let slides: AxfoodReplacementProductViewModel[][];
    if (!isClient) {
      return;
    }
    if (isTabletLandscapeOrGreater) {
      slides = chunkAndSetNonReplaceableSlides(tabletLandscapeSlides);
    } else if (isTabletPortraitOrGreater) {
      slides = chunkAndSetNonReplaceableSlides(tabletSlides);
    } else {
      slides = chunkAndSetNonReplaceableSlides(mobileSlides);
    }
    if (offset + 1 > slides.length) {
      const newOffset = slides.length ? slides.length : 0;
      setOffset(newOffset);
    }
  }, [
    chunkAndSetNonReplaceableSlides,
    isTabletPortraitOrGreater,
    isTabletLandscapeOrGreater,
    offset,
    setOffset,
    tabletSlides,
    tabletLandscapeSlides,
    mobileSlides,
    isClient,
  ]);

  return { productsForCarouselSlides, offset, incrementOffset, decrementOffset };
};

export default useConflictModalSlides;
