import { AxfoodPromotionViewModel } from '@api/generated/storefront';
import { IntersectionObservedMiniCartProductRow } from '@components/molecules/MiniCartProductRow/CartConnectedMinicartProductRow';
import { MINICART_EVENT_LIST } from '@components/molecules/MiniCartProductRow/MiniCartProductRow';
import PromotionBanner from '@components/molecules/PromotionBanner';
import { getPrimaryPromotion } from '@helpers/productHelper';
import { useAppSelector } from '@hooks/redux';
import useBufferedAnalyticsEvent from '@hooks/useBufferedAnalyticsEvent/useBufferedAnalyticsEvent';
import { selectCartProducts } from '@selectors/cart';
import { productTracker } from '@trackers';
import React, { RefObject, memo } from 'react';

interface CartConnectedMinicartProductRowProps {
  setIsConfirmModalVisible: (isVisible: boolean) => void;
}

const MiniCartProductsWithTracking: React.FunctionComponent<CartConnectedMinicartProductRowProps> = ({
  setIsConfirmModalVisible,
}: CartConnectedMinicartProductRowProps) => {
  const cartProducts = useAppSelector(selectCartProducts);
  const trackProductImpression = (viewedProducts: ImpressionProduct[]) =>
    productTracker.trackProductImpressionsWithPosition(viewedProducts, MINICART_EVENT_LIST.varor);

  const { trackableRefs, debouncedTrack, queueTrackingOfItem } = useBufferedAnalyticsEvent(
    cartProducts,
    trackProductImpression
  );

  const onProductShown = (product: ProductVariantType, pos: number) => {
    const position = 'positionInList' in product ? product.positionInList : pos;
    queueTrackingOfItem({ product, position });
    debouncedTrack();
  };

  const promotionShouldRender = (promotion: AxfoodPromotionViewModel): boolean => {
    if (promotion) {
      const isNotUsed =
        promotion.timesUsed && promotion.promotionRedeemLimit
          ? promotion.timesUsed < promotion.promotionRedeemLimit
          : true;
      return Boolean(promotion.qualifyingCount && promotion.qualifyingCount >= 2 && isNotUsed && promotion.applied);
    }
    return false;
  };

  return (
    <>
      {cartProducts.map((product, i: number) => (
        <React.Fragment key={product.code}>
          <IntersectionObservedMiniCartProductRow
            product={product}
            setIsConfirmModalVisible={setIsConfirmModalVisible}
            intersectionCallback={() => onProductShown(product, i + 1)}
            ref={trackableRefs.current[i] as unknown as RefObject<any>}
          />
          {[getPrimaryPromotion(product)].map((promotion) => {
            if (promotion && promotionShouldRender(promotion)) {
              return (
                <PromotionBanner
                  key={`${product.code}_${promotion.code}`}
                  promotionCartLabel={product.promotionCartLabel}
                  promotion={promotion}
                  appliedPromotions={product.appliedPromotions}
                />
              );
            }
            return '';
          })}
        </React.Fragment>
      ))}
    </>
  );
};

export default memo(MiniCartProductsWithTracking);
