import { AxfoodPromotionViewModel, ImageData } from '@api/generated/storefront';
import Link from '@components/atoms/__DEPRECATED__/Link';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import ProductPrice, { RelevantPriceProps } from '@components/molecules/ProductPrice/ProductPrice';
import PromotionSplash from '@components/molecules/__DEPRECATED__/PromotionSplash';
import { URLPREFIXES } from '@constants/links';
import { PROMOTION_TYPE } from '@constants/promotion';
import { getActualCustomerPrice, getPreviewUrl, getProductUrl } from '@helpers/productHelper';
import { shouldShowPromotionPrice } from '@helpers/promotions';
import useAppRouter from '@hooks/useAppRouter';
import { productTracker } from '@trackers';
import useTranslation from 'next-translate/useTranslation';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { ReactNode } from 'react';
import ProductAddToList from '../../Product/ProductAddToList/ProductAddToList';
import {
  HorizontalContainer,
  HorizontalLeft,
  HorizontalLeftLabelsWrapper,
  HorizontalLeftPromotionSplashPosition,
  HorizontalRight,
  ImageContainer,
  ImageFallback,
  LabelsPosition,
  NewsSplashWrapper,
  ProductFooterWrapper,
  ProductHeaderWrapper,
  ProductQuantityWrapper,
  ProductWrapper,
  PromotionSplashPosition,
  PromotionUsedText,
} from './Product.styles';
import ProductInformation from './ProductInformation/ProductInformation';

const DynamicProductLabels = dynamic(() => import('./ProductLabels/ProductLabels'));

interface ProductImageProps {
  url: string | undefined;
  altText: string | undefined;
  isPreview: boolean;
}

export const IMAGE_FALLBACK_TEXT = 'Bild saknas, thumbnail saknas';

const ProductImage = ({ url, altText = '', isPreview }: ProductImageProps) => {
  if (isPreview && !url) {
    return (
      <ImageFallback>
        <Paragraph>{IMAGE_FALLBACK_TEXT}</Paragraph>
      </ImageFallback>
    );
  }
  if (!url) {
    return null;
  }
  return (
    <ImageContainer>
      <Image src={url} alt={altText} fill />
    </ImageContainer>
  );
};

interface ProductProps<Type> {
  product: Type;
  onClick?: () => unknown;
  disableLinks?: boolean;
  smallQuantity?: boolean;
  border?: boolean;
  children?: ReactNode | undefined;
  eventListName?: string;
  layout?: 'vertical' | 'horizontal';
  withAddToList?: boolean;
  isPreview?: boolean;
}

const urlPrefix = URLPREFIXES.PRODUCT_DETAIL;

export interface ProductViewModel {
  potentialPromotions: Array<AxfoodPromotionViewModel>;
  priceUnit?: string;
  online?: boolean;
  manufacturer?: string;
  name?: string;
  newsSplashProduct?: boolean;
  code?: string;
  image?: ImageData;
  priceValue?: number;
}

export const mapProductPriceProps = (product: ProductViewModel): RelevantPriceProps => {
  const promotion = product.potentialPromotions?.[0];
  const actualCustomerPrice = getActualCustomerPrice(product);
  return {
    offline: !product.online,
    priceValue: product.priceValue || 0,
    hasPromotion: !!promotion,
    actualCustomerPrice,
    priceUnit: product.priceUnit?.split('/')?.[1] || '',
    hasPrice: actualCustomerPrice >= 0,
    isMixAndMatchPercentage: promotion
      ? promotion.promotionType === PROMOTION_TYPE.MIX_MATCH_PERCENTAGE_PROMOTION
      : false,
    isPercentage: promotion && promotion.rewardLabel ? promotion.rewardLabel.includes('%') : false,
    showPromotionPrice: !!promotion && shouldShowPromotionPrice(promotion),
  };
};

export const Product = <Type extends ProductViewModel>({
  product,
  onClick,
  disableLinks,
  smallQuantity = false,
  border,
  children,
  eventListName,
  layout = 'vertical',
  withAddToList = true,
  isPreview = false,
}: ProductProps<Type>) => {
  const router = useAppRouter();
  const { t } = useTranslation('product');
  const promotion = product.potentialPromotions[0];
  const hasPromotion = !!promotion;
  const isPromotionUsed = !!(
    hasPromotion &&
    promotion.promotionRedeemLimit &&
    promotion.promotionRedeemLimit > 0 &&
    promotion.timesUsed &&
    promotion.promotionRedeemLimit <= promotion.timesUsed
  );
  const isPromotionUsedOffline = isPromotionUsed && !product.online;
  const as = isPreview
    ? `${urlPrefix}${encodeURI(getPreviewUrl(product, promotion))}`
    : `${urlPrefix}${encodeURI(getProductUrl(product))}`;
  const query = isPreview
    ? {
        ...router.query,
        name: encodeURIComponent(getPreviewUrl(product, promotion)),
        productCode: promotion.mainProductCode,
      }
    : { ...router.query, name: encodeURIComponent(getProductUrl(product)), productCode: product.code };
  const disableSplashLinks = disableLinks || isPromotionUsedOffline;
  const priceUnit = product.priceUnit && product.priceUnit.split('/')[1];
  const productAltText = product.manufacturer ? `${product.manufacturer} ${product.name}` : product.name;

  const onProductLinkClicked = () => {
    if (eventListName) {
      productTracker.trackProductClicked(product, eventListName);
    }
  };

  const imageIsClickable = !disableLinks && product.online;

  const productLabels = <DynamicProductLabels product={product} horizontal={layout === 'horizontal'} />;

  const productImageElement = <ProductImage url={product.image?.url} isPreview={isPreview} altText={productAltText} />;
  if (layout === 'horizontal') {
    return (
      <HorizontalContainer data-testid="horizontal-product-container">
        <HorizontalLeft>
          <HorizontalLeftLabelsWrapper>
            {withAddToList && <ProductAddToList product={product} />}
            {productLabels}
          </HorizontalLeftLabelsWrapper>
          {hasPromotion && (
            <HorizontalLeftPromotionSplashPosition>
              <PromotionSplash variant="card" product={product} forceReverseIcon />
            </HorizontalLeftPromotionSplashPosition>
          )}
          {!!product.newsSplashProduct && (
            <NewsSplashWrapper>
              <Paragraph bold="bold" size="sm" data-testid="news-splash">
                {t('product:new')}
              </Paragraph>
            </NewsSplashWrapper>
          )}
          {imageIsClickable ? (
            <Link
              href={{ pathname: router.pathname, query }}
              nextLinkAs={{ pathname: as }}
              underlined={false}
              title={productAltText}
              scroll={false}
              margin={false}
              shallow
              onClick={onProductLinkClicked}
              forceInternal
              dynamicUrlPrefix={urlPrefix}
              aria-label="click-product-link"
            >
              {productImageElement}
            </Link>
          ) : (
            productImageElement
          )}
        </HorizontalLeft>
        <HorizontalRight>
          <ProductInformation
            product={product}
            url={{ pathname: router.pathname, query }}
            as={{ pathname: as }}
            priceUnit={priceUnit || ''}
            disableLinks={Boolean(disableLinks || isPromotionUsedOffline)}
            handleProductLinkClick={onProductLinkClicked}
          />
          <ProductPrice product={mapProductPriceProps(product)} />
          <ProductQuantityWrapper $smallQuantity={smallQuantity}>{children}</ProductQuantityWrapper>
        </HorizontalRight>
      </HorizontalContainer>
    );
  }

  return (
    <ProductWrapper
      $isPromotionUsed={Boolean(isPromotionUsedOffline)}
      onClick={onClick}
      $border={border}
      data-testid="vertical-product-container"
    >
      <ProductHeaderWrapper>
        <LabelsPosition>
          {withAddToList && <ProductAddToList product={product} />}
          {productLabels}
        </LabelsPosition>
        {hasPromotion && !disableSplashLinks && (
          <Link
            href={{ pathname: router.pathname, query }}
            nextLinkAs={{ pathname: as }}
            underlined={false}
            scroll={false}
            margin={false}
            shallow
            onClick={onProductLinkClicked}
            forceInternal
            dynamicUrlPrefix={urlPrefix}
            aria-label="click-product-link"
          >
            <PromotionSplashPosition>
              <PromotionSplash variant="card" product={product} />
            </PromotionSplashPosition>
          </Link>
        )}
        {hasPromotion && !!disableSplashLinks && (
          <PromotionSplashPosition>
            <PromotionSplash variant="card" product={product} />
          </PromotionSplashPosition>
        )}
        {!!product.newsSplashProduct && (
          <NewsSplashWrapper>
            <Paragraph bold="bold" size="sm" data-testid="news-splash">
              {t('product:new')}
            </Paragraph>
          </NewsSplashWrapper>
        )}
        {imageIsClickable ? (
          <Link
            href={{ pathname: router.pathname, query }}
            nextLinkAs={{ pathname: as }}
            underlined={false}
            title={productAltText}
            scroll={false}
            margin={false}
            shallow
            onClick={onProductLinkClicked}
            forceInternal
            dynamicUrlPrefix={urlPrefix}
            aria-label="click-product-link"
          >
            {productImageElement}
          </Link>
        ) : (
          productImageElement
        )}

        <ProductInformation
          product={product}
          url={{ pathname: router.pathname, query }}
          as={{ pathname: as }}
          priceUnit={priceUnit || ''}
          disableLinks={Boolean(disableLinks || isPromotionUsedOffline)}
          handleProductLinkClick={onProductLinkClicked}
        />
        <ProductPrice product={mapProductPriceProps(product)} />
      </ProductHeaderWrapper>
      <ProductFooterWrapper>
        <ProductQuantityWrapper $smallQuantity={smallQuantity}>{children}</ProductQuantityWrapper>
      </ProductFooterWrapper>
      {isPromotionUsed && (
        <PromotionUsedText>
          <Paragraph data-testid="promotion-used">{t('product:isPromotionUsed')}</Paragraph>
        </PromotionUsedText>
      )}
    </ProductWrapper>
  );
};

export default Product;
