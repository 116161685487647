import Icon from '@components/atoms/Icon';
import IconArrowRight from '@public/icons/regularIcons/deprecated-icon-arrow-right.svg';
import React, { ForwardedRef, useState } from 'react';
import { AccordionHeader, AccordionHeaderWrapper, IconWrapper } from '../AxfoodFAQComponent.styles';

export interface AccordionSectionHeaderProps {
  active: boolean;
  title: string;
  isLastClosedTab: boolean;
}

const AccordionSectionHeader = React.forwardRef(
  ({ active, title, isLastClosedTab }: AccordionSectionHeaderProps, ref: ForwardedRef<HTMLDivElement>) => {
    const [isClosed, setIsClosed] = useState(false);

    const handleHeaderClick = () => {
      if (active) {
        setIsClosed(!isClosed);
      }
    };

    return (
      <AccordionHeaderWrapper $active={active}>
        <AccordionHeader ref={ref} onClick={handleHeaderClick} data-testid="faq-accordion-header">
          {title}
          <IconWrapper $active={active} $isClosed={isLastClosedTab}>
            <Icon color="black" icon={IconArrowRight} data-testid="icon-arrow-right" />
          </IconWrapper>
        </AccordionHeader>
      </AccordionHeaderWrapper>
    );
  }
);
AccordionSectionHeader.displayName = 'AccordionSectionHeader';

export default AccordionSectionHeader;
