import cartActions from '@actions/cart';
import { openDeliveryModal } from '@actions/delivery';
import { getCart, setCartProducts } from '@api/CartApi';
import { AddToCartForm } from '@api/generated/storefront';
import { useDeliveryModeReminderDone } from '@hooks/localStorage';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import { selectCartHasSlot, selectCartTotalItemsCount } from '@selectors/cart';
import { ApiError } from '@occ/api-client';

const useAddRemoveProducts = () => {
  const dispatch = useAppDispatch();
  const amountOfCartItems = useAppSelector(selectCartTotalItemsCount || 0);
  const cartHasSlot = useAppSelector(selectCartHasSlot);
  const [deliveryModeReminderDone, setDeliverymodeReminderDone] = useDeliveryModeReminderDone(false);
  const deliveryModalTriggerThreshold = 3;

  const shouldOpenDeliveryModal = (amountOfCurrentlyAddedProducts: number) => {
    const currentProductAmount = amountOfCartItems + amountOfCurrentlyAddedProducts;
    const productAmountThresholdReached = currentProductAmount >= deliveryModalTriggerThreshold;
    const deliveryReminderHasBeenShown = deliveryModeReminderDone;

    return productAmountThresholdReached && !deliveryReminderHasBeenShown;
  };

  const postCartUpdate = async (products: Array<AddToCartForm>, storeId?: string) => {
    let success;
    let errorCode;
    try {
      dispatch(cartActions.incrementOngoingCartUpdatesAndSetCartUpdating());
      const response = await setCartProducts(products, storeId);
      dispatch(cartActions.setCartDataIfNoQueuedOrOngoingUpdates(response.data));
      success = !!response.data;
    } catch (e) {
      if (e instanceof Response && 'error' in e && typeof e.error === 'object') {
        const apiError = e.error as ApiError;
        const lastCartRes = await getCart();
        dispatch(cartActions.resetCartAfterFailedUpdate(lastCartRes.data));
        success = false;
        errorCode = apiError.errorCode
      }
    }

    if (success && !cartHasSlot && shouldOpenDeliveryModal(products.length)) {
      setDeliverymodeReminderDone(true);
      dispatch(openDeliveryModal());
    }
    return { success, errorCode };
  };

  return {
    postCartUpdate,
  };
};

export default useAddRemoveProducts;
