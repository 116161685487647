import HeaderButton from '@components/atoms/HeaderButton';
import Overlay from '@components/atoms/Overlay';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const SearchInput = styled.input<{ $size?: 'sm' | 'md' }>`
  width: 100%;
  border-color: transparent;
  height: ${({ $size }) => ($size === 'md' ? rem(48) : rem(40))};
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;

  &::-webkit-search-cancel-button {
    appearance: none;
  }
  &:focus {
    outline: none;
  }
`;

export const SearchForm = styled.form`
  width: 100%;
  z-index: 50;
  ${media.tabletPortrait} {
    position: relative;
  }
`;

export const SearchField = styled.fieldset`
  display: flex;
  align-items: center;
  border: none;
  margin: 0;
  width: 100%;
  padding: 0;
  border-radius: ${rem(16)};
  padding: 0 ${rem(16)};
  background-color: ${({ theme }) => theme.colors.colorWhite};
`;

export const SearchIcon = styled.div`
  pointer-events: none;
  flex-shrink: 0;
`;

export const CancelButton = styled(HeaderButton)`
  margin-right: ${rem(16)};
`;

export const StyledOverlay = styled(Overlay)`
  display: none;

  ${media.tabletPortrait} {
    display: block;
  }
`;

export const ClearButtonWrapper = styled.div`
  flex-shrink: 0;
`;
