import { rem } from 'polished';
import styled from 'styled-components';

export const DropDownMenuContainer = styled.div`
  border-radius: ${rem(16)};
  width: ${rem(280)};
  box-shadow: ${({ theme }) => theme.shadows.dropdown};
  overflow: hidden;
`;

export const List = styled.ul`
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 99;
`;

export const ListItem = styled.li`
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.colors.colorCararra};
  &:last-child {
    border-bottom: none;
  }
`;
