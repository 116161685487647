import debounce from '@helpers/debounce';
import React, { useRef } from 'react';

const useBufferedAnalyticsEvent = (
  trackableItems: unknown[] = [],
  callback: (items: any[]) => void,
  bufferSize = 10,
  debounceTime: number = 2000
) => {
  const trackableRefs = useRef<unknown[]>([]);
  const buffer = useRef<unknown[]>([]);

  // keep the list of trackable item refs updated if more trackable items are loaded
  if (trackableRefs.current.length !== trackableItems.length) {
    trackableRefs.current = Array(trackableItems.length)
      .fill(undefined)
      .map((_, i) => trackableRefs.current[i] || React.createRef());
  }

  const track = () => {
    // eslint-disable-next-line fp/no-loops
    while (buffer.current.length > 0) {
      const itemsToTrack: unknown[] = buffer.current.splice(0, bufferSize);
      callback(itemsToTrack);
    }
    buffer.current = [];
  };

  const debouncedTrack = debounce(track, debounceTime);

  const queueTrackingOfItem = (item: unknown) => {
    buffer.current = [...buffer.current, item];
  };

  return {
    debouncedTrack,
    queueTrackingOfItem,
    trackableRefs,
  };
};

export default useBufferedAnalyticsEvent;
