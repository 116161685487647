import * as types from '@actions/conflictModal/types';
import { AxfoodCartEntryStatusViewModel } from '@occ/api-client';
import { AnyAction } from '@reduxjs/toolkit';
import { conflictModalTracker } from '@trackers';

export interface ConflictModalState {
  items: AxfoodCartEntryStatusViewModel[];
  isOpen: boolean;
  resolved?: ({ resolvedWithConflictingItems }: { resolvedWithConflictingItems: boolean }) => void;
  rejected?: (reason?: any) => void;
  reasonTracker: conflictModalTracker.ReasonTrackerType;
  storeId?: string;
}

const initialState: ConflictModalState = {
  items: [],
  isOpen: false,
  resolved: undefined,
  rejected: undefined,
  reasonTracker: conflictModalTracker.ADDRESS_CHANGED,
  storeId: undefined,
};

const cartReducer = (state = initialState, action: AnyAction): ConflictModalState => {
  switch (action.type) {
    case types.SET_ITEMS_CONFLICTING_MODAL:
      return { ...state, items: action.items };
    case types.SHOW_CONFLICTING_MODAL:
      return { ...state, isOpen: action.isOpen };
    case types.SET_CONFLICTING_MODAL_STORE_ID:
      return { ...state, storeId: action.storeId };
    case types.RESOLVE_CONFLICTING_MODAL:
      return { ...state, resolved: action.resolved };
    case types.REJECT_CONFLICTING_MODAL:
      return { ...state, rejected: action.rejected };
    case types.SET_CONLFICT_REASON_TRACKER:
      return { ...state, reasonTracker: action.reasonTracker };
    default:
      return state;
  }
};

export default cartReducer;
