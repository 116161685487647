/* eslint-disable react/no-array-index-key */
import { AxfoodProductDetailsViewModel } from '@api/generated/storefront';
import Icon, { IconType } from '@components/atoms/Icon/Icon';
import IconGhsCorrosion from '@public/icons/colorIcons/icon-ghs-corrosion.svg';
import IconGhsEnvironment from '@public/icons/colorIcons/icon-ghs-environment.svg';
import IconGhsExclamationMark from '@public/icons/colorIcons/icon-ghs-exclamation-mark.svg';
import IconGhsExplodingBomb from '@public/icons/colorIcons/icon-ghs-exploding-bomb.svg';
import IconGhsFlameOverCircle from '@public/icons/colorIcons/icon-ghs-flame-over-circle.svg';
import IconGhsFlame from '@public/icons/colorIcons/icon-ghs-flame.svg';
import IconGhsGasCylinder from '@public/icons/colorIcons/icon-ghs-gas-cylinder.svg';
import IconGhsHealthHazard from '@public/icons/colorIcons/icon-ghs-health-hazard.svg';
import IconGhsSkullAndCrossbones from '@public/icons/colorIcons/icon-ghs-skull-and-crossbones.svg';
import useTranslation from 'next-translate/useTranslation';
import {
  GHSIconWrapper,
  GHSTextWrapper,
  InformationBox,
  PrecautionsWrapper,
  StyledParagraph,
} from './ProductDescriptionListing.styles';

interface GHSListingProps {
  product: AxfoodProductDetailsViewModel;
}

export const GHS_SYMBOLS: string[] = [
  'corrosion',
  'environment',
  'exclamation_mark',
  'exploding_bomb',
  'flame',
  'flame_over_circle',
  'gas_cylinder',
  'health_hazard',
  'skull_and_crossbones',
];

export const GHS_SIGNAL_WORDS: string[] = ['not_applicable', 'danger', 'warning'];

export const ghsIconTestIdPrefix = 'ghs-icon';

const GHSListing = ({ product }: GHSListingProps) => {
  const { t } = useTranslation('productDetails');
  const { hazards, precautionaryStatements, labels } = product;
  const precautions = product.hazards?.length ? [...hazards, ...precautionaryStatements] : precautionaryStatements;
  const GHSSymbols = labels
    ? labels.reduce((acc: string[], label) => (GHS_SYMBOLS.includes(label) ? [...acc, label] : acc), [])
    : [];
  const GHSLabels = labels
    ? labels.reduce((acc: string[], label) => (GHS_SIGNAL_WORDS.includes(label) ? [...acc, label] : acc), [])
    : [];
  const hasGHS = !!GHSSymbols.length || !!GHSLabels.length || !!precautions.length;

  const iconLookup: { [key: string]: IconType } = {
    flame: IconGhsFlame,
    corrosion: IconGhsCorrosion,
    environment: IconGhsEnvironment,
    gas_cylinder: IconGhsGasCylinder,
    health_hazard: IconGhsHealthHazard,
    exploding_bomb: IconGhsExplodingBomb,
    exclamation_mark: IconGhsExclamationMark,
    flame_over_circle: IconGhsFlameOverCircle,
    skull_and_crossbones: IconGhsSkullAndCrossbones,
  };
  return hasGHS ? (
    <InformationBox>
      <GHSIconWrapper>
        {GHSSymbols.map((item) => {
          const icon = iconLookup[item];
          return <Icon key={item} size={65} icon={icon} data-testid={`${ghsIconTestIdPrefix}-${item}`} color="none" />;
        })}
      </GHSIconWrapper>
      <GHSTextWrapper>
        {GHSLabels.map((item) => (
          <StyledParagraph key={item} data-testid="ghs-warning">
            {t(`productDetails->${item}`)}
          </StyledParagraph>
        ))}
      </GHSTextWrapper>
      <PrecautionsWrapper>
        {precautions &&
          precautions.map((text) => (
            <StyledParagraph key={text} data-testid="precaution">
              &#8226; {text}
            </StyledParagraph>
          ))}
      </PrecautionsWrapper>
    </InformationBox>
  ) : null;
};

export default GHSListing;
