import { ParsedUrlQuery } from 'querystring';

const preserveNativeAppQueries = (url: UrlType, currentRouterQuery: ParsedUrlQuery) => {
  if (!currentRouterQuery.viewconfig) return url;

  const persistQuery = { viewconfig: currentRouterQuery.viewconfig };

  if (typeof url === 'object') {
    return { pathname: url.pathname, query: { ...(url.query as ParsedUrlQuery), ...persistQuery } };
  }

  const connector = url.indexOf('?') > -1 && url.indexOf('=') > -1 ? '&' : '?';
  return `${url}${connector}${new URLSearchParams(persistQuery as any).toString()}`;
};

export default preserveNativeAppQueries;
