import Paragraph from '@components/atoms/Paragraph';
import useTranslation from 'next-translate/useTranslation';
import { TextContainer } from './ProductQuantity.styles';

const Offline = () => {
  const { t } = useTranslation('product');

  return (
    <TextContainer data-testid="offline-label">
      <Paragraph>{t('offline')}</Paragraph>
    </TextContainer>
  );
};

export default Offline;
