/* eslint-disable fp/no-mutation */
/* eslint-disable fp/no-this */
/* eslint-disable fp/no-class */
import useAppRouter from '@hooks/useAppRouter';
import { NextRouter } from 'next/router';
import { Component, ErrorInfo, ReactNode } from 'react';

export interface ErrorBoundaryProps {
  children: ReactNode;
  errorHandler: (error: Error, errorInfo: ErrorInfo) => void;
  fallback?: ReactNode;
  routerEvents?: NextRouter['events'];
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { errorHandler } = this.props;
    errorHandler(error, errorInfo);
  }

  componentDidMount() {
    const { routerEvents } = this.props;
    if (routerEvents) {
      routerEvents.on('routeChangeComplete', this.resetError);
    }
  }

  componentWillUnmount() {
    const { routerEvents } = this.props;
    if (routerEvents) {
      routerEvents.off('routeChangeComplete', this.resetError);
    }
  }

  resetError = () => {
    if (this.state.hasError) {
      this.setState({ hasError: false });
    }
  };

  public render() {
    const { hasError } = this.state;
    const { children, fallback } = this.props;

    if (hasError) {
      return fallback || null;
    }

    return children;
  }
}

export default ErrorBoundary;

export const ErrorBoundaryWithRouter = (props: ErrorBoundaryProps) => {
  const { events } = useAppRouter();
  return <ErrorBoundary {...props} routerEvents={events} />;
};
