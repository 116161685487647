import { mediaTracker } from '@trackers';
import { useCallback, useState } from 'react';

export const getThumbnail = (videoLink: string) => `https://i.ytimg.com/vi_webp/${videoLink}/maxresdefault.webp`;

const useVideoBanner = (title: string, videoLink: string) => {
  const [showIframe, setShowIframe] = useState(false);

  const onPlay = useCallback(() => {
    setShowIframe(true);
    mediaTracker.videoPlayedTracker(title, videoLink);
  }, [setShowIframe, title, videoLink]);

  const thumbnail = getThumbnail(videoLink);

  return {
    thumbnail,
    onPlay,
    showIframe,
    setShowIframe,
  };
};

export default useVideoBanner;
