import { LoyaltyTransactionData } from '@api/generated/storefront';
import { dateToNumeric } from '@helpers/date';

export interface Transaction {
  date: string;
  id: string;
  transactionName: string;
  points: string;
  showPointsSuffix: boolean;
}
export const mapPointsHistory = (loyaltyTransactionsForCurrentPeriod: LoyaltyTransactionData[]): Transaction[] => {
  return loyaltyTransactionsForCurrentPeriod
    ?.map((transaction) => {
      const { bookingDate, id, product: transactionName, transactionSubType, points } = transaction;

      if (bookingDate && transactionName && points && id) {
        return {
          date: dateToNumeric(bookingDate),
          id,
          transactionName,
          points,
          showPointsSuffix: transactionSubType?.toLowerCase() === 'utbetalning',
        };
      }
    })
    .filter((t) => !!t);
};
