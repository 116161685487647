import { closeDeliveryModal } from '@actions/delivery';
import { closeLoginModal } from '@actions/login';
import LINKS, { URLPREFIXES } from '@constants/links';
import { pageview } from '@helpers/analytics';
import { useAppDispatch, useAppSelector } from '@hooks/redux';
import useAppRouter from '@hooks/useAppRouter';
import { selectDeliveryModalIsVisible } from '@selectors/delivery';
import { selectLoginModalIsVisible } from '@selectors/login';
import { useEffect } from 'react';

const useOnRouteChange = () => {
  const router = useAppRouter();
  const dispatch = useAppDispatch();

  const isDeliveryModalVisible = useAppSelector(selectDeliveryModalIsVisible);
  const isLoginModalVisible = useAppSelector(selectLoginModalIsVisible);

  useEffect(() => {
    router.events.on('routeChangeStart', pageview);
    return () => {
      router.events.off('routeChangeStart', pageview);
    };
  }, []);

  useEffect(() => {
    /* close modals rendered on own urls in redux  when user navigates by browser back/forward buttons */
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        const isDeliveryMethodInAsPath = as.indexOf(URLPREFIXES.DELIVERY_METHOD) > -1;
        const isLoginInAsPath = as.indexOf(LINKS.LOGIN) > -1;
        if (!isDeliveryMethodInAsPath && isDeliveryModalVisible) {
          dispatch(closeDeliveryModal());
          return true;
        }
        if (!isLoginInAsPath && isLoginModalVisible) {
          dispatch(closeLoginModal());
          return true;
        }
      }
      return true;
    });
  }, [router, dispatch, isDeliveryModalVisible, isLoginModalVisible]);

  return () => {
    router.beforePopState(() => true);
  };
};

export default useOnRouteChange;
