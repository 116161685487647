import ProductGrid from '@components/atoms/ProductGrid';
import Spinner from '@components/atoms/Spinner';
import { Stack } from '@components/atoms/Stack';
import useCategory from '@components/cmsComponents/CategoryProductsComponent/useCategory';
import CategoryInfo from '@components/molecules/CategoryInfo/CategoryInfo';
import NoResults from '@components/molecules/NoResults';
import { CategoryLinksWithShowMoreButton } from '@components/organisms/CategoryLinks';
import ProductListFilters from '@components/organisms/ProductListFilters';
import ProductsWithTracking from '@components/organisms/ProductsWithTracking';
import { URLPREFIXES } from '@constants/links';
import { getFormattedProductsCategoryAppliedFilters } from '@helpers/productHelper';
import { flattenString } from '@helpers/string';
import useAppRouter from '@hooks/useAppRouter';
import useInfiniteScroll from '@hooks/useInfiniteScroll';
import useUserAgent from '@hooks/useUserAgent';
import Heading from 'components/atoms/Heading';
import useTranslation from 'next-translate/useTranslation';
import { RefObject, useState } from 'react';
import { AmountOfProducts, Bottom, Container, HeadingWrapper, StyledProductBreadCrumbs } from './CategoryPage.styles';

export interface Props {
  name?: string;
}

export const SIZE = {
  MOBILE: 10,
  TABLET: 20,
  DESKTOP: 30,
};

const CategoryPage = ({ name }: Props) => {
  const router = useAppRouter();
  const { t } = useTranslation('categorypage');
  const { isNativeApp } = useUserAgent();

  const { query } = router;
  const {
    products,
    breadcrumbs,
    sorts,
    facets,
    superCategories,
    subCategories,
    restrictedProductTypes,
    isValidating: isFetching,
    isLoading,
    loadMore,
    totalNumberOfResults,
    allProductsInCategoriesCount,
  } = useCategory({
    categoryPath: flattenString(query?.categories),
    q: typeof query?.q === 'string' ? query?.q : undefined,
    sort: typeof query?.sort === 'string' ? query.sort : undefined,
  });

  const totalResultsCopy = t('category:totalResults', { totalResult: totalNumberOfResults || 0 });
  const infiniteScrollRef = useInfiniteScroll(loadMore);

  const [gridRef, setGridRef] = useState<HTMLDivElement | null>(null);
  const handleRef = (ref: HTMLDivElement) => setGridRef(ref);
  const amountOfProductsString =
    allProductsInCategoriesCount !== totalNumberOfResults
      ? t('category:totalResultsWithFilter', {
          filterResult: totalNumberOfResults,
          totalResult: allProductsInCategoriesCount,
        })
      : totalResultsCopy;

  const EXTENDED_EVENT_LIST_NAME = getFormattedProductsCategoryAppliedFilters(router.query);
  const EVENT_LIST_NAME = `/sortiment | ${name}`;
  const noProducts = !products || !products.length;

  return (
    <Container data-testid="category-page-wrapper">
      {!isNativeApp && (
        <StyledProductBreadCrumbs
          breadcrumbs={superCategories || []}
          urlPrefix={URLPREFIXES.CATEGORIES}
          currentPage={name}
        />
      )}
      <Stack $space="smallMedium">
        <HeadingWrapper>
          <Heading type="h1" size="large">
            {name}
          </Heading>
          {!isLoading && <AmountOfProducts role="status">({amountOfProductsString})</AmountOfProducts>}
        </HeadingWrapper>
        <CategoryLinksWithShowMoreButton categories={subCategories} showSkeletons={isLoading} buttonSize="md" />
        <ProductListFilters
          facets={facets || []}
          sorts={sorts || []}
          pageName="category_page"
          amountOfProducts={totalNumberOfResults}
          selectedFilters={breadcrumbs || []}
        />
        {!isLoading && noProducts ? (
          <NoResults noHitsCopy={t('noProductsToShow')} />
        ) : (
          <>
            <ProductGrid passRef={handleRef}>
              <CategoryInfo restrictedProductTypes={restrictedProductTypes} />
              <ProductsWithTracking
                products={products}
                eventListName={EVENT_LIST_NAME}
                extendedEventListName={EXTENDED_EVENT_LIST_NAME !== '' ? EXTENDED_EVENT_LIST_NAME : undefined}
                containerRef={gridRef}
                isLoading={isLoading}
              />
            </ProductGrid>
            <Bottom ref={infiniteScrollRef as RefObject<HTMLDivElement>}>{isFetching && <Spinner color="red" />}</Bottom>
          </>
        )}
      </Stack>
    </Container>
  );
};

export default CategoryPage;
