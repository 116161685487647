// Backend request validations see hybris/bin/custom/.../axfood/core/constants/AxfoodCoreConstants.
const DEFAULT_SANITIZE = /^(?!.*(UNION|DROP|TABLE|SELECT|DELETE|UPDATE|ALTER|CREATE|INSERT|INDEX))[^<>;]+$/;

const REGEXP = {
  ILLEGAL_SEARCH_CHARS: /([:#<>])+/g,
  j_usernameb2c: /^[0-9]{12,16}$/,
  j_usernameb2b: /^(\d+-?){5,15}$/,
  USER_NAME:
    /^(?!.*(UNION|DROP|TABLE|SELECT|DELETE|UPDATE|ALTER|CREATE|INSERT|INDEX))[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'_-]+$/,
  PASSWORD:
    /^(?=.*[a-z\u00E5\u00E4\u00F6])(?=.*[A-Z\u00C5\u00C4\u00D6])([a-z\u00E5\u00E4\u00F6A-Z\u00C5\u00C4\u00D60-9\w/\\//<>_~@$%^&*+=`|{}:;!.,?"[\]-]{6,30})$/,
  SLOT_TIME: '\\d{2}:\\d{2}-\\d{2}:\\d{2}',
  CELLPHONE_REGEX: /^(070|072|073|076|079)\d{7}$/,
  EMAIL_REGEX: /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z!#$%&'*+/=?^_`{|}~.-]{2,}/i,
  ZIP_CODE_REGEX: /^\d{3}\s*\d{2}$/,
  DOOR_CODE_REGEX: /^[a-zA-Z0-9%*#]+$/,
  MESSAGE_TO_DRIVER:
    /^(?!.*(UNION|DROP|TABLE|SELECT|DELETE|UPDATE|ALTER|CREATE|INSERT|INDEX))[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'":;?!_-]|[\n]+$/,
  TWELVE_DIGIT_SSN_AND_OPTIONAL_DASH:
    /^(19|20)[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]|6[0-9]|7[0-9]|8[0-9]|9[0-1])[- ]?[0-9]{4}$/,
  TEN_DIGIT_SSN_AND_OPTIONAL_DASH:
    /^[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]|6[0-9]|7[0-9]|8[0-9]|9[0-1])[- ]?[0-9]{4}$/,
  TWELVE_DIGIT_SSN: /^(19|20)[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]|6[0-9]|7[0-9]|8[0-9]|9[0-1])[0-9]{4}$/,
  ALLOWED_DEFAULT_CHARACTERS:
    /^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'_-]+$/,
  DEFAULT_SANITIZER: DEFAULT_SANITIZE,
  SMS_CODE: /^[0-9]{6}$/,
  ANY_ORG_NUMBER_PATTERN: /^([0-9]{1})(\d{5})-(\d{4})$/,
};

export default REGEXP;
