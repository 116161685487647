import React from 'react';
import { IconWrapper } from './Icon.styles';

export type IconType = React.FC<React.SVGProps<SVGSVGElement>>;
export interface Props {
  icon?: IconType;
  color?: ColorType;
  className?: string;
  animation?: 'rotate' | '';
  margin?: 'top' | 'right' | 'bottom' | 'left';
  'aria-label'?: string;
  'data-testid'?: string;
  size?: number;
  customUrl?: string | null;
  mouseoverText?: string;
}

export const ICON_TEST_ID = 'icon';

const Icon = ({
  icon: Svg,
  color = 'black',
  margin,
  animation = '',
  className = '',
  'aria-label': ariaLabel,
  'data-testid': dataTestId = ICON_TEST_ID,
  size = 24,
  customUrl,
  mouseoverText,
}: Props) => {
  if (customUrl) {
    // eslint-disable-next-line @next/next/no-img-element
    return <img src={customUrl} alt="custom-icon" />;
  }
  if (!Svg) {
    return null;
  }
  return (
    <IconWrapper
      className={className}
      $margin={margin}
      $color={color}
      $animation={animation}
      aria-label={ariaLabel}
      $size={size}
      data-testid={dataTestId}
      title={mouseoverText}
    >
      <Svg />
    </IconWrapper>
  );
};

export default React.memo(Icon);
