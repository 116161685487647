import { rem } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  font-size: ${rem(14)};
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.colorBackground};
  justify-content: center;
`;

export const LoyaltySaveValue = styled.div`
  display: flex;
  padding-top: ${rem(16)};
  justify-content: center;
  align-items: center;
  font-size: ${rem(20)};
`;

export const LoyaltySaveValuePrice = styled.span`
  font-family: ${({ theme }) => theme.typography.fontSerif};
  font-weight: ${({ theme }) => theme.fonts.fontWeight.bold};
  margin-left: ${rem(5)};
`;

export const LoginLinks = styled.div`
  box-sizing: border-box;
  line-height: normal;
  padding: ${rem(12)};
  display: inline-block;
  text-align: center;
`;
