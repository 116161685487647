import type { AxfoodBasicProductViewModel } from '@occ/api-client';
import Product from '@components/molecules/__DEPRECATED__/Product';
import { pickUnitName } from '@helpers/productHelper';
import useProductQuantity from '@hooks/useProductQuantity';
import { useEffect } from 'react';
import CartQuantity from '../Header/Cart/CartQuantity';

// This is the GeneralProductComponent, It does not have any
// connection to the cart, and changes made to the product quantity
// will have to be handled in the parent scope on a case-by-case basis.

interface GeneralProductProps {
  product: AxfoodBasicProductViewModel;
  initialQuantity?: number;
  onClick?: () => unknown;
  disableLinks?: boolean;
  disableQuantity?: boolean;
  smallQuantity?: boolean;
  onQuantityChange?: (quantity: number | string) => void;
  withAddToList?: boolean;
}

const GeneralProduct = ({
  initialQuantity = 0,
  product,
  onClick,
  disableLinks,
  disableQuantity,
  smallQuantity = false,
  onQuantityChange = () => false,
  withAddToList,
}: GeneralProductProps) => {
  const {
    changeQuantityValueBy,
    onQuantityFieldChange,
    onQuantityFieldFocus,
    onQuantityFieldBlur,
    quantity,

    quantityFieldIsActive,
  } = useProductQuantity({ initialQuantity, product });
  const unit = pickUnitName(product);
  const incrementValue = product.incrementValue || 0;

  useEffect(() => {
    if (quantity !== initialQuantity) onQuantityChange(quantity);
  }, [quantity]);

  return (
    <>
      <Product
        product={product}
        onClick={onClick}
        disableLinks={disableLinks}
        smallQuantity={smallQuantity}
        withAddToList={withAddToList}
      >
        {/** quantity will be hidden when rendered within RecipeReplacementModal */}
        <CartQuantity
          value={quantity || 0}
          unit={unit}
          outOfStock={product.outOfStock}
          decrement={() => changeQuantityValueBy(-incrementValue)}
          increment={() => changeQuantityValueBy(incrementValue)}
          isActive={quantityFieldIsActive}
          disabled={disableQuantity}
          onChange={onQuantityFieldChange}
          onFocus={onQuantityFieldFocus}
          onBlur={onQuantityFieldBlur}
        />
      </Product>
    </>
  );
};

export default GeneralProduct;
