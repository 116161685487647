/* eslint-disable react/no-array-index-key */
import { BatteryInformationData } from '@api/generated/storefront';
import useTranslation from 'next-translate/useTranslation';
import { InfoBlock, StyledParagraph } from './ProductDescriptionListing.styles';

interface BatteriesInformationListingProps {
  areBatteriesIncluded: boolean;
  areBatteriesRequired: boolean;
  batteryInformation: BatteryInformationData[];
}

const BatteriesInformationListing = ({
  areBatteriesIncluded,
  areBatteriesRequired,
  batteryInformation,
}: BatteriesInformationListingProps) => {
  const { t } = useTranslation('productDetails');

  return (
    <InfoBlock>
      <StyledParagraph weight="bold">{t('productDetails->batteryInformation')}</StyledParagraph>
      {areBatteriesIncluded !== null && (
        <StyledParagraph data-testid="areBatteriesIncluded">
          {t('productDetails->batteryIncluded')} {t(`common:${areBatteriesIncluded ? 'yes' : 'no'}`)}
        </StyledParagraph>
      )}
      {areBatteriesRequired !== null && (
        <StyledParagraph data-testid="areBatteriesRequired">
          {t('productDetails->batteryRequired')} {t(`common:${areBatteriesRequired ? 'yes' : 'no'}`)}
        </StyledParagraph>
      )}
      {batteryInformation.map((b, i) => (
        <StyledParagraph key={i} data-testid="batteryInformation">
          {`${b.batteriesQuantityRequired || ''}
          ${b.batteryType || ''} ${b.batteryTechnologyTypeCode || ''}
          ${b.batteryWeight || ''} ${b.batteryQuantityBuiltIn || ''}
          ${b.batteryQuantityBuiltIn ? t('productDetails->batteryQuantityBuiltIn') : ''}
          `}
        </StyledParagraph>
      ))}
    </InfoBlock>
  );
};

export default BatteriesInformationListing;
