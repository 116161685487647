import { Stack } from '@components/atoms/Stack';
import media from '@constants/responsive';
import styled from 'styled-components';

export const SearchTextWrapper = styled(Stack).attrs({ $space: 'nano' })`
  text-align: center;
  ${media.tabletLandscape} {
    text-align: left;
  }
`;
