import gtmTrack from '@helpers/gtmTrack';
import { getImpressionsObjectPrice } from '@helpers/productHelper';
import TagManager from 'react-gtm-module';
import { addOrRemoveProduct } from '../cartTracker/cartTracker';
import type { AxfoodCartEntryStatusViewModel, AxfoodReplacementProductViewModel } from '@occ/api-client';

export const SLOT_CHANGED = 'slot_changed';
export const STORE_CHANGED = 'store_changed';
export const DELIVERY_METHOD_CHANGED = 'delivery_method_changed';
export const ADDRESS_CHANGED = 'adress_changed';
export const INVENTORY_STOCK_CHANGED = 'inventory_stock_changed';
export const GO_TO_CHECKOUT = 'user_tried_proceeding_to_checkout';
export const CLICKED_PAYMENT_BUTTON = 'user_clicked_payment_button_in_checkout';
export const ADD_ORDER_TO_CART = 'add_order_to_cart';
export const ADD_LIST_TO_CART = 'add_list_to_cart';

export type ReasonTrackerType =
  | typeof SLOT_CHANGED
  | typeof STORE_CHANGED
  | typeof DELIVERY_METHOD_CHANGED
  | typeof ADDRESS_CHANGED
  | typeof INVENTORY_STOCK_CHANGED
  | typeof GO_TO_CHECKOUT
  | typeof CLICKED_PAYMENT_BUTTON
  | typeof ADD_ORDER_TO_CART
  | typeof ADD_LIST_TO_CART;

export const closeModal = () => {
  return TagManager.dataLayer({
    dataLayer: {
      event: 'Track',
      category: 'outofstock_modal',
      action: 'closed_without_replacement',
      label: '',
      value: 0,
    },
  });
};

export const trackConflictModalOpen = (
  reasonTracker: ReasonTrackerType,
  hasReplacableItems: boolean,
  hasNonReplacableItems: boolean
) => {
  const pushToDataLayer = (action: string) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'Track',
        category: 'outofstock_modal',
        action,
        label: reasonTracker,
        value: 0,
      },
    });
  };

  if (hasReplacableItems && hasNonReplacableItems) {
    return pushToDataLayer('shown_with_and_without_replacement_suggestions');
  } else if (hasReplacableItems && !hasNonReplacableItems) {
    return pushToDataLayer('shown_with_replacement_suggestions');
  }

  return pushToDataLayer('shown_without_replacement_suggestions');
};

export const trackNonReplaceableProducts = (
  impressionProducts: {
    product: AxfoodCartEntryStatusViewModel;
    position: number;
  }[]
) => {
  const impressions = impressionProducts.map((impressionProduct) => {
    return {
      list: `outofstock_modal | without_suggestions | ${impressionProduct.product.entryProduct?.name} | ${impressionProduct.product.entryProduct?.code}`,
      id: impressionProduct.product.entryProduct?.code,
      name: impressionProduct.product.entryProduct?.name,
      brand: impressionProduct?.product.entryProduct?.manufacturer
        ? impressionProduct.product.entryProduct.manufacturer.toLowerCase()
        : '',
      category: impressionProduct?.product.entryProduct?.googleAnalyticsCategory || '',
      price: getImpressionsObjectPrice(impressionProduct?.product.entryProduct),
      position: impressionProduct.position,
    };
  });
  return TagManager.dataLayer({
    dataLayer: {
      event: 'impressionsPushed',
      ecommerce: {
        currencyCode: 'SEK',
        impressions,
      },
    },
  });
};

export const trackReplaceableProductsOnViewportEnter = (
  impressionProducts: { product: AxfoodReplacementProductViewModel; position: number }[],
  productToReplaceCode?: string,
  productToReplaceName?: string
) => {
  const impressions = impressionProducts
    ? impressionProducts.map((impressionProduct: { product: AxfoodReplacementProductViewModel; position: number }) => {
        return {
          list: `outofstock_modal | with_suggestions | ${productToReplaceName} | ${productToReplaceCode}`,
          id: impressionProduct.product.code,
          brand: impressionProduct?.product.manufacturer ? impressionProduct?.product.manufacturer.toLowerCase() : '',
          category: impressionProduct?.product.googleAnalyticsCategory || '',
          name: impressionProduct.product.name,
          price: getImpressionsObjectPrice(impressionProduct?.product),
          position: impressionProduct.position,
        };
      })
    : '';
  return TagManager.dataLayer({
    dataLayer: {
      event: 'impressionsPushed',
      ecommerce: {
        currencyCode: 'SEK',
        impressions,
      },
    },
  });
};

export const removeConflictingProduct = (product: AxfoodCartEntryStatusViewModel, withSuggestions: boolean) => {
  const formattedProduct = {
    id: product.entryProduct?.code || '',
    name: product.entryProduct?.name || '',
    price: getImpressionsObjectPrice(product.entryProduct),
    quantity: product.pickQuantity || 0,
    brand: product.entryProduct?.manufacturer ? product.entryProduct.manufacturer.toLowerCase() : '',
    category: product.entryProduct?.googleAnalyticsCategory || '',
  };

  const suggestionStatus = withSuggestions ? 'with_suggestions' : 'without_suggestions';
  const eventListName = `outofstock_modal | ${suggestionStatus} | ${formattedProduct.name} | ${formattedProduct.id}`;
  const ecommerce = {
    currencyCode: 'SEK',
    remove: {
      actionField: { list: eventListName },
      products: [formattedProduct],
    },
  };
  gtmTrack({
    event: 'removeFromCart',
    ecommerce,
  });
};

export const addReplacementProductToCart = (replacementProduct: ProductUpdateType) => {
  const eventListName = `outofstock_modal | with_suggestions | ${replacementProduct.productToReplaceName} | ${replacementProduct.productToReplaceCode}`;

  addOrRemoveProduct(replacementProduct.qty, 0, replacementProduct.product, eventListName);
};
