import { format, lastDayOfMonth } from 'date-fns';
import { capitalizeFirst } from '@helpers/string';
// to prevent differences in date helpers, locale is set to 'sv-SE'
// update this if we should start supporting more languages
const LOCALE = 'sv-SE';

/**
 * @summary checks if a Date is valid
 * @param date
 * @returns boolean
 */
const isValidDate = (date: Date): boolean => {
  return !!date.getDate();
};

/**
 * @summary helper to replace moment.parse('xx-xx-xxxx')
 * Options cheatsheet: https://devhints.io/wip/intl-datetime
 * @param date
 * @param options
 */
const dateToLocale = (date: string | number | Date, options: Intl.DateTimeFormatOptions): string => {
  const asDate = new Date(date);
  const validDate = isValidDate(asDate);
  return validDate ? asDate.toLocaleDateString(LOCALE, options) : '';
};

const dateToNumericInternal = (date: string | number | Date): string => {
  return dateToLocale(date, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
};

/**
 * @summary helper to replace moment(date).format('YY-MM-DD')
 * @example '2020-11-28 17:00:00' => '20-11-28'
 * @param date
 */
export const dateToShortNumeric = (date?: number): string => {
  if (!date) return '';
  return dateToLocale(date, {
    year: '2-digit',
    month: 'numeric',
    day: 'numeric',
  });
};

/**
 * @summary helper to replace moment(date).format('YYYY-MM-DD')
 * @example '2020-11-28 17:00:00' => '2020-11-28'
 * @param date
 */
export const dateToNumeric = (date: number | Date): string => {
  return dateToNumericInternal(date);
};

/**
 * @summary helper to replace moment(date).format('LT')
 * @example '2020-11-28 17:00:00' => '17:00'
 * @param date
 */
export const dateToHourAndMinutes = (date?: number | string): string => {
  if (!date) return '';
  const asDate = new Date(date);
  const validDate = isValidDate(asDate);
  return validDate ? asDate.toLocaleString(LOCALE, { hour: 'numeric', minute: 'numeric' }) : '';
};

/**
 * @summary helper to replace moment(date).format('YYYY-MM-DD [kl.] HH:mm')
 * @example '2020-11-28 17:00:00' => '2020-11-28 kl. 17:00'
 * @param date
 * @returns string
 */
export const fullDateWithTime = (date?: string): string => {
  return (date && `${dateToNumericInternal(date)} kl. ${dateToHourAndMinutes(date)}`) || '';
};

/**
 * @summary helper function that formats a timestamp number into a date string
 * in the format "tisdag 20 jan. kl. 00:05".
 * @example: 1638316800 => "tisdag 20 jan. kl. 00:05"
 * @param timestamp
 * @returns string
 */
export const formatToLongDate = (timestamp: number): string => {
  const date = new Date(timestamp);
  const validDate = isValidDate(date);
  if (!validDate) return '';
  const formattedDate = dateToLocale(timestamp, {
    day: 'numeric',
    month: 'short',
    weekday: 'long',
  });

  return `${formattedDate.replace('.', '')} kl. ${dateToHourAndMinutes(timestamp)}`;
};

/**
 * @summary helper to replace moment(date).format('DD MMM')
 * @example '2020-11-28 17:00:00' => "28 nov"
 * @param date
 * @returns string
 */
export const dateToShortMonth = (date: string | number): string => {
  return dateToLocale(date, { day: 'numeric', month: 'short' }).replace('.', '');
};

/**
 * @summary checks if a date is today
 * @param date - Date
 * @returns boolean
 */
export const isToday = (date: Date): boolean => {
  const today = new Date();
  const validDate = isValidDate(date);

  return (
    validDate &&
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

/**
 * @summary checks if a date is tomorrow
 * @param date - Date
 * @returns boolean
 */
export const isTomorrow = (date: Date): boolean => {
  const tomorrow = new Date();
  const validDate = isValidDate(date);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return validDate && tomorrow.toDateString() === date.toDateString();
};

/**
 * @summary returns first date of month
 * @param date - Date
 * @returns string on format YYYY-MM-DD
 */
export const getFirstDateOfMonth = (date: Date) => {
  return format(date, 'yyyy-MM-01');
};

/**
 * @summary returns last date of month
 * @param date - Date
 * @returns string on format YYYY-MM-DD
 */
export const getLastDateOfMonth = (date: Date) => {
  return format(lastDayOfMonth(date), 'yyyy-MM-dd');
};

/**
 * @summary returns date formatted as YYYY-MM-DD
 * @param date
 * @returns string on format YYYY-MM-DD
 */
export const formatDateYYYYMMDD = (date: Date) => {
  return date.toISOString().split('T')[0];
};

export const formatDateYYYYMM = (date: Date) => {
  return formatDateYYYYMMDD(date).substring(0, 7);
};

export const dateToLongWeekday = (date: string): string => {
  return dateToLocale(date, { weekday: 'long' });
};

const formatCapitalizedMonthName = (date: Date) => capitalizeFirst(date.toLocaleString('sv-SE', { month: 'long' }));
export const formatFullMonthAndYear = (date: Date) => `${formatCapitalizedMonthName(date)} ${date.getFullYear()}`;
