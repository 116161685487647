import { CategoryBreadCrumbsData } from '@api/generated/storefront';
import Icon from '@components/atoms/Icon';
import Link from '@components/atoms/Link';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import RightIcon from '@public/icons/regularIcons/icon-chevron-right.svg';
import useTranslation from 'next-translate/useTranslation';
import { Breadcrumb, BreadcrumbList, Seperator } from './Breadcrumbs.styles';

interface IProductBreadcrumbsProps {
  breadcrumbs: Array<CategoryBreadCrumbsData>;
  sliceLastItem?: boolean | undefined;
  currentPage?: string;
  urlPrefix: string;
  className?: string;
}

const IconSeparator = () => (
  <Seperator>
    <Icon icon={RightIcon} size={16} />
  </Seperator>
);

type StructuredData = {
  '@context': string;
  '@type': string;
  name: string;
  itemListElement: Array<{
    '@type': string;
    position: number;
    name: string;
    item?: string;
  }>;
};

const ProductBreadcrumbs = ({
  breadcrumbs,
  sliceLastItem,
  urlPrefix,
  currentPage,
  className,
}: IProductBreadcrumbsProps) => {
  const { t } = useTranslation('breadcrumbs');
  const crumbsToRender = sliceLastItem === true ? breadcrumbs?.slice(1, -1) : breadcrumbs?.slice(1);

  const baseItems = [
    {
      '@type': 'ListItem',
      position: 1,
      name: t('home'),
      item: '/',
    },
    ...crumbsToRender?.map((child, i) => ({
      '@type': 'ListItem',
      position: i + 2,
      name: `${child.name}`,
      item: `${urlPrefix}${child.url}`,
    })),
  ];

  // https://developers.google.com/search/docs/appearance/structured-data/breadcrumb#year-genre%20example
  const itemListElement = currentPage
    ? [
        ...baseItems,
        {
          '@type': 'ListItem',
          position: crumbsToRender.length + 2,
          name: currentPage,
        },
      ]
    : baseItems;

  const structuredData: StructuredData = {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    name: 'Product Breadcrumbs',
    itemListElement,
  };

  return (
    <nav aria-label="breadcrumb" className={className}>
      <script
        type="application/ld+json"
        key="breadcrumbs"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData),
        }}
      />
      <BreadcrumbList>
        <Breadcrumb $sliceLastItem={sliceLastItem}>
          <Link title={`${t('to')} ${t('home')}`} underlined={false} href="/">
            <Paragraph>{t('home')}</Paragraph>
          </Link>
          {(crumbsToRender.length > 0 || currentPage) && <IconSeparator />}
        </Breadcrumb>
        {crumbsToRender.map((crumb, i) => (
          <Breadcrumb key={crumb.categoryCode || crumb.name} data-testid="breadcrumb" $sliceLastItem={sliceLastItem}>
            <Link title={`${t('to')} ${crumb.name}`} underlined={false} href={`${urlPrefix}${crumb.url}`}>
              <Paragraph>{crumb.name}</Paragraph>
            </Link>
            {(i < crumbsToRender.length - 1 || currentPage) && <IconSeparator />}
          </Breadcrumb>
        ))}
        {currentPage && (
          <Breadcrumb key={currentPage} data-testid="breadcrumb" $isCurrentPageCrumb aria-current="page">
            <Paragraph>{currentPage}</Paragraph>
          </Breadcrumb>
        )}
      </BreadcrumbList>
    </nav>
  );
};

export default ProductBreadcrumbs;
