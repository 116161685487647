import styled from 'styled-components';
import { rem } from 'polished';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.colorDesertStorm};
  border-top: rem(2) dashed ${({ theme }) => theme.colors.colorPampas};
  border-bottom: rem(1) solid ${({ theme }) => theme.colors.colorPampas};
  width: 100%;
  padding: ${rem(10)} 0 ${rem(10)} ${rem(100)};
`;
