import LinkArea from '@components/atoms/LinkArea';
import media from '@constants/responsive';
import { rem } from 'polished';
import styled from 'styled-components';

export const EntranceComponentWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: ${rem(8)};

  ${media.tabletPortrait} {
    gap: ${rem(16)};
    grid-template-columns: repeat(3, 1fr);
    margin-top: ${rem(40)};
  }
`;
export const SectionIconWrapper = styled.div`
  width: ${rem(64)};
  height: ${rem(64)};
  position: relative;
  background: ${({ theme }) => theme.colors.colorLinen};
  border-radius: ${rem(32)};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(32)};

  ${media.tabletPortrait} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: ${rem(-32)};
  }
`;

export const SectionWrapper = styled.div`
  position: relative;
  border-radius: ${rem(16)};
  background-color: ${({ theme }) => theme.colors.colorWhite};
  padding: ${rem(16)};
  text-align: center;
  display: flex;
  box-shadow: ${({ theme }) => theme.shadows.default};

  &:hover {
    background-color: ${({ theme }) => theme.colors.colorPampas};
    ${SectionIconWrapper} {
      background-color: ${({ theme }) => theme.colors.colorRemy};
    }
  }
  ${media.tabletPortrait} {
    display: block;
  }
`;

export const SectionContent = styled.div`
  text-align: left;
  margin-left: ${rem(16)};

  ${media.tabletPortrait} {
    padding-top: ${rem(16)};
    margin-left: 0;
    text-align: center;
  }
`;
export const SectionHead = styled.div`
  font-size: ${rem(32)};
  font-family: ${({ theme }) => theme.typography.fontCaveat};
  color: ${({ theme }) => theme.colors.colorPrimary};
`;

export const SectionLink = styled(LinkArea)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  ${media.tabletPortrait} {
    display: flex;
    flex-direction: column;
  }
`;
