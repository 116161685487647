import Button from '@components/atoms/__DEPRECATED__/Button';
import Paragraph from '@components/atoms/__DEPRECATED__/Paragraph';
import GeneralProduct from '@components/organisms/GeneralProduct';
import useResponsive from '@hooks/useResponsive';
import Heading from 'components/atoms/__DEPRECATED__/Heading';
import useTranslation from 'next-translate/useTranslation';
import { ReactNode, useState } from 'react';
import {
  ConflictModalReplaceWrapper,
  ConflictModalTextWrapper,
  ConflictedProductReplaceCancelButton,
  ConflictedProductStyled,
  ConflictedProductWrapper,
  ConflictedProductWrapperHeader,
  HeadingWrapper,
  PartialOutOfStockWrapper,
  TopTextWrapper,
  Triangle,
} from './ConflictModal.styles';
import ReplacementProducts from './ReplacementProducts';
import type { AxfoodCartEntryStatusViewModel } from '@api/generated/storefront';

interface Row {
  item: AxfoodCartEntryStatusViewModel;
  updateSelectedProduct: (productToUpdate: ProductUpdateType, code?: string) => void;
  onDoNotReplaceClick: (item: AxfoodCartEntryStatusViewModel) => void;
  children: ReactNode;
}

const ReplaceableProductRow = ({ item, updateSelectedProduct, children }: Row) => {
  const { t } = useTranslation('conflictModal');
  const { isTabletPortraitOrGreater, isTabletLandscapeOrGreater } = useResponsive();

  return (
    <ConflictModalReplaceWrapper key={item.entryProduct?.code}>
      <ConflictedProductWrapper>
        <ConflictedProductWrapperHeader>
          {isTabletPortraitOrGreater && <span>{t('conflictModal->titles->yourProduct')}</span>}
        </ConflictedProductWrapperHeader>
        <ConflictedProductStyled $disabled>
          <GeneralProduct
            product={item.entryProduct!}
            disableLinks
            disableQuantity
            initialQuantity={item.pickQuantity}
            smallQuantity={!isTabletLandscapeOrGreater}
            withAddToList={false}
          />
        </ConflictedProductStyled>
      </ConflictedProductWrapper>
      <Triangle data-testid="replaceable-product-triangle" />
      <ReplacementProducts
        productToReplaceCode={item.entryProduct?.code}
        productToReplaceName={item.entryProduct?.name}
        updateSelectedProduct={(product) => updateSelectedProduct(product, item.entryProduct?.code)}
        replacementProductsForProduct={item.replacementProductsForProduct!}
      />
      {!!item.partialOutOfStock && (
        <PartialOutOfStockWrapper>
          <Paragraph>
            {t('conflictModal->partialReplaceText', {
              pickQuantity: item.pickQuantity,

              quantity: (item.pickQuantity ?? 0) - (item.availableQuantity ?? 0),
              unit: item.entryProduct?.productBasketType?.code?.toLocaleLowerCase(),
            })}
          </Paragraph>
        </PartialOutOfStockWrapper>
      )}
      {children}
    </ConflictModalReplaceWrapper>
  );
};

export declare type ImpressionConflictingProduct = {
  product: AxfoodCartEntryStatusViewModel;
  position: number;
};

interface ReplaceableProductsProps {
  replaceableItems: AxfoodCartEntryStatusViewModel[];
  onUpdateSelectedProduct: (productToUpdate: ProductUpdateType, code?: string, name?: string) => void;
  onDontReplaceProduct: (code: AxfoodCartEntryStatusViewModel) => void;
  infoText: ReactNode;
  shouldRenderProductRow?: boolean;
  shouldRenderReplaceButton?: boolean;
}
const ReplaceableProducts = ({
  replaceableItems,
  onUpdateSelectedProduct,
  onDontReplaceProduct,
  infoText,
  shouldRenderProductRow = true,
  shouldRenderReplaceButton = true,
}: ReplaceableProductsProps) => {
  const { t } = useTranslation('conflictModal');
  const [itemsFiltered, setItemsFiltered] = useState(replaceableItems);

  const doNotReplaceClickHandler = (currentItem: AxfoodCartEntryStatusViewModel) => {
    const filtered = itemsFiltered.filter((product) => {
      return product.entryProduct?.code !== currentItem.entryProduct?.code;
    });
    setItemsFiltered(filtered);
    onDontReplaceProduct(currentItem);
  };

  return (
    <>
      <TopTextWrapper>
        <HeadingWrapper>
          <Heading type="h2">{t('conflictModal->titles->replacements')}</Heading>
        </HeadingWrapper>
        <ConflictModalTextWrapper>{infoText}</ConflictModalTextWrapper>
      </TopTextWrapper>
      {shouldRenderProductRow &&
        itemsFiltered.map((currentItem) => (
          <ReplaceableProductRow
            key={currentItem.entryProduct?.code}
            item={currentItem}
            updateSelectedProduct={
              (product: ProductUpdateType) =>
                onUpdateSelectedProduct(product, currentItem.entryProduct?.code, currentItem.entryProduct?.name)
              // eslint-disable-next-line react/jsx-curly-newline
            }
            onDoNotReplaceClick={doNotReplaceClickHandler}
          >
            {shouldRenderReplaceButton && (
              <ConflictedProductReplaceCancelButton>
                <Button variant="secondary" onClick={() => doNotReplaceClickHandler(currentItem)}>
                  {t('conflictModal->titles->doNotReplace')}
                </Button>
              </ConflictedProductReplaceCancelButton>
            )}
          </ReplaceableProductRow>
        ))}
    </>
  );
};

export default ReplaceableProducts;
