import IconButton from '@components/molecules/IconButton';
import { rem } from 'polished';
import styled from 'styled-components';

export const AddToListButton = styled(IconButton)`
  padding: 0;
  margin-bottom: 3px;
`;

export const AddToListWrapper = styled.div`
  position: relative;
  margin-bottom: ${rem(2)};
`;

/*
  z-index below ensures visiblilty when opened:
    - from within beam inside ProductDetail
    - opened from ConflictModal
    - over HeroCMsComponents
*/
export const AddToListPopper = styled.div`
  z-index: 201;
`;
